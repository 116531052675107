import React from "react";


export const mainThemeStyle ={
    mainbox:{
            position: 'absolute',
            width:'100%',
            height: '100vh',
            
            left: '0rem',top:'3.75rem'
    },

    mainTablebox:{
        position: 'absolute',
        width:'100%',
        top:'20rem',
        left: '0rem',
        display:'flex',
        flexDirection:'column',
        alignContent:'space-between',
        backgroundColor:"menubarprimary.main"
    },

    mainTableBelowbox:{
        position: 'absolute',
        width:'100%',
        top:'13.75rem',
        left: '0rem',
        display:'flex',
        flexDirection:'column',
        alignContent:'space-between',
        
    },

    mainTableInsidebox:{
        
        width:'100%',
        left: '0rem',
        display:'flex',
        flexDirection:'column',
        alignContent:'space-between',
       
    },
    normalBigButtonGray:{
           
           
        marginTop:'0rem',
        boxSizing: 'border-box',
        position: 'relative',
        background: '#54565E',
        border: '0.06rem solid #313439',
        boxShadow: 'inset 0rem 0.25rem 0.25rem rgba(0, 0, 0, 0.25)',
        borderRadius: '0.31rem 0.31rem 0.31rem 0.31rem',
        fontWeight: '700',
        fontSize: '0.93rem',
        
        alignItems: 'center',
        color: '#FFFFFF',
        fontFamily: "Lato",
        '&:focused':{
            background: '#54565E',
        border: '0.06rem solid #313439',
        boxShadow: 'inset 0rem -0.25rem 0.25rem rgba(0, 0, 0, 0.25)',
        borderRadius: '0.31rem 0.31rem 0.31rem 0.31rem',
        },
        '&:hover': {
            background: '#54565E',
        border: '0.06rem solid #313439',
        boxShadow: 'inset 0rem -0.25rem 0.25rem rgba(0, 0, 0, 0.25)',
        borderRadius: '0.31rem 0.31rem 0.31rem 0.31rem',
      },
      '&$active': {
        background: '#54565E',
        border: '0.06rem solid #313439',
        boxShadow: 'inset 0rem -0.25rem 0.25rem rgba(0, 0, 0, 0.25)',
        borderRadius: '0.31rem 0.31rem 0.31rem 0.31rem',
      },
     },
    box:{
            background: '#54565E',
            position: 'absolute',
            width:'100%',
            height: '3rem',
        },
        
    activityButton:{
            position: 'absolute',
            width: 'auto',
            height: '2.06rem',
            left: '2.375rem',
            fontWeight: '700',
            fontSize: '0.9375rem',
            lineHeight: '1.1rem',
            display: 'flex',
            top: '3.75rem',
            alignItems: 'center',
            color: '#FFFFFF',
            fontFamily: "Lato",
            borderRadius:'0.31rem',
           '&:hover': {
            backgroundColor: 'rgba(109, 195, 252, 1)',
      }
        },
        filterButton:{
            width: 'auto',
              height: '2.06rem',
              
              fontWeight: '700',
              fontSize: '0.93rem',
              lineHeight: '1.1rem',
              
             
              alignItems: 'center',
              color: '#FFFFFF',
              fontFamily: "Lato",
              borderRadius:'0.31rem',
             '&:hover': {
              backgroundColor: 'rgba(109, 195, 252, 1)',
             }
        },
        rainbowText :{ 
        /* 'text-shadow':'0 0 7px rgba(231,131,243,.9)', */
        'color':'#00AAFF',
        'marginTop': '2.06rem',
            'text-transform':'uppercase',
            'font-size':'1rem',
            'font-weight':'bold',
            'width':'auto',
            'height':'auto',
    },
        rainbowButton:{
            '--primary-neon':'rgba(0,119,255,.9)',
            '--light-neon':'rgba(0,119,200,.7)',
            '--pulse-neon':'rgba(0,119,175,.5)',
            '--bright-neon':'rgba(0,119,220,1)',
            '--off-neon':'#333',
            
            '--neon-shadow':'0 0 7px 4px var(--light-neon), 0 0 7px 4px var(--light-neon) inset',
            '--neon-shadow-pulse':'0 0 8px 5px var(--pulse-neon), 0 0 8px 5px var(--pulse-neon) inset',
            '--neon-shadow-bright':'0 0 8px 4px var(--bright-neon), 0 0 8px 4px var(--bright-neon) inset',
            '--neon-shadow-text':'0 0 7px var(--primary-neon)',
            backgroundColor: 'rgba(0, 0, 0, 0)',
            'width':'auto',
            'height':'2.06rem',
            'color':'var(--primary-neon)',
            'text-shadow':'var(--neon-shadow-text)',
            'border':'3px solid var(--primary-neon)',
            'border-radius':'5px',
            'display':'flex',
            'align-items':'center',
            'justify-content':'center',
            'text-transform':'uppercase',
            'font-size':'0.93rem',
            'font-weight':'bold',
            'box-shadow':'var(--neon-shadow)',
            '&:hover': {
                backgroundColor: 'rgba(0, 0, 0, 0)',
                'color':'var(--bright-neon)',
                'border':'3px solid var(--bright-neon)',
                'box-shadow':'var(--bright-neon)',
               }
        },
       
        filterButtonCenter:{
            width: 'auto',
              height: '2.06rem',
              
              fontWeight: '700',
              fontSize: '0.9375rem',
              lineHeight: '1.1rem',
              top:'-1rem',
             
              alignItems: 'center',
              color: '#FFFFFF',
              fontFamily: "Lato",
              borderRadius:'0.31rem',
             '&:hover': {
              backgroundColor: 'rgba(109, 195, 252, 1)',
             }
        },
        PinSelection:{
            width: 'auto',
              height: '2.06rem',
              
              fontWeight: '700',
              fontSize: '0.9375rem',
              lineHeight: '1.1rem',
              
              top:'-0.9375rem',
              alignItems: 'center',
              color: '#FFFFFF',
              fontFamily: "Lato",
              borderRadius:'0.31rem',
             '&:hover': {
              backgroundColor: 'rgba(109, 195, 252, 1)',
             }
        },
        roundLabel:{
            
            width: '2.18rem',
            height: '2.18rem',
            fontWeight: '500',
            fontSize: '0.75rem',
            alignItems: 'center',
            textAlign: 'center',
            marginRight:'0.625rem',
            lineHeight: '2.18rem',
            marginLeft:'0.625rem',
            alignItems: 'center',
        
            color: '#FFFFFF',
            
            background: '#171E29',
            borderRadius: '1.25rem'
        },
        ofLable:{
            fontFamily: 'Lato',

fontWeight: '500',
fontSize: '0.75rem',
marginRight:'0.625rem',
            marginLeft:'0.625rem',
display: 'flex',
alignItems: 'center',
textAlign: 'center',
color: '#878787'
           
        },
        viewLogButton:{
            width: 'auto',
            height: 'auto',
            marginTop:'-0.625rem',
            fontWeight: '500',
            fontSize: '0.75rem',
            lineHeight: '0.875rem',
            marginTop:'-0.625rem',
            
            color: '#FFFFFF',
            fontFamily: "Lato",
            borderRadius:'0.31rem',
           '&:hover': {
            backgroundColor: 'rgba(109, 195, 252, 1)',
      },
      
      '&:disabled': {
        backgroundColor:'gray',
  },
        },
        viewLogLoadingButton:{
            width: 'auto',
            height: 'auto',
            marginTop:'-0.625rem',
            fontWeight: '500',
            fontSize: '0.75rem',
            lineHeight: '0.875rem',
            marginTop:'-0.625rem',
            
            color: '#FFFFFF',
            fontFamily: "Lato",
            borderRadius:'0.31rem',
           '&:hover': {
            backgroundColor: 'rgba(109, 195, 252, 1)',
      }
        },
        viewLogButtonRed:{
            width: 'auto',
            height: 'auto',
            marginTop:'-0.625rem',
            fontWeight: '500',
            fontSize: '0.75rem',
            lineHeight: '0.875rem',
            position:'relative',
            backgroundColor:'#FF0000',
            color: '#FFFFFF',
            fontFamily: "Lato",
            borderRadius:'0.31rem',
           '&:hover': {
            backgroundColor: '#FF4400',
      },
      '&:disabled': {
        backgroundColor:'gray',
  },
        },
        downloadButton:{
            width: 'auto',
            height: 'auto',
            marginTop:'0rem',
            fontWeight: '500',
            fontSize: '0.93rem',
            lineHeight: '1.1rem',
            position:'relative',
           
            color: '#FFFFFF',
            fontFamily: "Lato",
            borderRadius:'0.31rem',
           '&:hover': {
            backgroundColor: 'rgba(109, 195, 252, 1)',
      },
        },
        filterResetButton:{
           
            width: 'auto',
            height: '2.06rem',
            
            fontWeight: '700',
            fontSize: '0.93rem',
            lineHeight: '1.1rem',
            
           top:'2.06rem',
            alignItems: 'center',
            color: '#FFFFFF',
            fontFamily: "Lato",
            borderRadius:'0.31rem',
           '&:hover': {
            backgroundColor: 'rgba(109, 195, 252, 1)',
           }
        },
        normalBigButton:{
           
           
            marginTop:'0rem',
            
            fontWeight: '700',
            fontSize: '0.93rem',
            
            
            alignItems: 'center',
            color: '#FFFFFF',
            fontFamily: "Lato",
            borderRadius:'0.31rem',
           '&:hover': {
            backgroundColor: 'rgba(109, 195, 252, 1)',
           }
        },
        normalBigButtonRed:{
           
           
            marginTop:'0rem',
            
            fontWeight: '700',
            fontSize: '0.93rem',
            
            backgroundColor:'#ff0000',
            alignItems: 'center',
            color: '#FFFFFF',
            fontFamily: "Lato",
            borderRadius:'0.31rem',
           '&:hover': {
            backgroundColor: '#990000',
           }
        },
        normalButton:{
           
            width: 'auto',
            height: '2.06rem',
            marginTop:'0rem',
            button: {
                textTransform: "none"
              },
            fontWeight: '700',
            fontSize: '0.93rem',
            
            
            alignItems: 'center',
            color: '#FFFFFF',
            fontFamily: "Lato",
            borderRadius:'0.31rem',
           '&:hover': {
            backgroundColor: 'rgba(109, 195, 252, 1)',
           }
        },
        normalButtonHeight:{
           
            width: 'auto',
            maxHeight: '2.5rem',
            marginTop:'0rem',
            button: {
                textTransform: "none"
              },
              fontWeight: '700',
              fontSize: '0.93rem',
            alignItems: 'center',
            color: '#FFFFFF',
            fontFamily: "Lato",
            borderRadius:'0.31rem',
           '&:hover': {
            backgroundColor: 'rgba(109, 195, 252, 1)',
           }
        },
        normalButtonRelative:{
           
            width: 'auto',
            height: 'auto',
            left:'0.31rem',
            
            position:'absolute',
            fontWeight: '700',
            fontSize: '0.93rem',
          
            
            alignItems: 'center',
            color: '#FFFFFF',
            fontFamily: "Lato",
            borderRadius:'0.31rem',
           '&:hover': {
            backgroundColor: 'rgba(109, 195, 252, 1)',
           }
        },
        normalButtonRelativeRelative:{
           
            width: 'auto',
            height: 'auto',
            left:'12.5rem',
            
            position:'absolute',
            fontWeight: '700',
            fontSize: '0.93rem',
            
            
            alignItems: 'center',
            color: '#FFFFFF',
            fontFamily: "Lato",
            borderRadius:'0.31rem',
           '&:hover': {
            backgroundColor: 'rgba(109, 195, 252, 1)',
           }
        },
        normalButtonDown:{
           
            width: 'auto',
            height: '2.06rem',
            top:'1rem',
            
            fontWeight: '700',
            fontSize: '0.93rem',
            
            
            alignItems: 'center',
            color: '#FFFFFF',
            fontFamily: "Lato",
            borderRadius:'0.31rem',
           '&:hover': {
            backgroundColor: 'rgba(109, 195, 252, 1)',
           }
        },
        saveButton:{
           
          
            fontWeight: '700',
            fontSize: '0.93rem',
            lineHeight: '1.1rem',
          
            alignItems: 'center',
            color: '#FFFFFF',
            fontFamily: "Lato",
            borderRadius:'0.31rem',
           '&:hover': {
            backgroundColor: 'rgba(109, 195, 252, 1)',
           }
        },
        popUpButton:{
           
          
            fontWeight: '700',
            fontSize: '0.93rem',
            lineHeight: '1.1rem',
            marginLeft:'0.62rem',
            alignItems: 'center',
            color: '#FFFFFF',
            fontFamily: "Lato",
            borderRadius:'0.31rem',
           '&:hover': {
            backgroundColor: 'rgba(109, 195, 252, 1)',
           }
        },
        filterResetButtonSmall:{
           
            width: 'auto',
            height: '2.06rem',
            
            fontWeight: '700',
            fontSize: '0.93rem',
            lineHeight: '1.1rem',
            marginRight:'1.25rem',
           top:'-0.62rem',
            alignItems: 'center',
            color: '#FFFFFF',
            fontFamily: "Lato",
            borderRadius:'0.31rem',
           '&:hover': {
            backgroundColor: 'rgba(109, 195, 252, 1)',
           }
        },
    lable:{
            position: 'absolute',
            width: 'auto',
            height: '1.62rem',
            left: '2.37rem',fontWeight: '700',
            fontSize:{ xs: "1.1rem", md: "1.37rem" },
            lineHeight:{ xs: "1.2rem", md: "1.6rem" },
            
            lineHeight: '1.6rem',
            display: 'flex',
            top: '0.68rem',
            alignItems: 'center',
            color: '#FFFFFF',
            fontFamily: "Lato"
        },
        lableActivityDown:{
            position: 'absolute',
            width: 'auto',
            height: '1.62rem',
            right: '2.37rem',
            fontWeight: '700',
            fontSize: '1.25rem',
            lineHeight: '1.35rem',
            display: 'flex',
            top: '3.12rem',
            alignItems: 'center',
            color: '#FFFFFF',
            fontFamily: "Lato",
        
          
        },
        lableActivityDownOne:{
            position: 'absolute',
            width: 'auto',
            height: '1.62rem',
            right: '2.37rem',
            fontWeight: '700',
            fontSize: '1.06rem',
            lineHeight: '1.25rem',
            display: 'flex',
            top: '5rem',
            alignItems: 'center',
            color: '#FFFFFF',
            fontFamily: "Lato",
        
          
        },
        lableActivityDownTwo:{
            position: 'absolute',
            width: 'auto',
            height: '1.625rem',
            right: '2.37rem',
            fontWeight: '700',
            fontSize: '1.06rem',
            lineHeight: '1.25rem',
            display: 'flex',
            top: '6.25rem',
            alignItems: 'center',
            color: '#FFFFFF',
            fontFamily: "Lato",
        
          
        },
        lableActivityDownThree:{
            position: 'absolute',
            width: 'auto',
            height: '1.62rem',
            right: '2.37rem',
            fontWeight: '700',
            fontSize: '1.06rem',
            lineHeight: '1.25rem',
            display: 'flex',
            top: '7.5rem',
            alignItems: 'center',
            color: '#FFFFFF',
            fontFamily: "Lato",
        
          
        },
    lableshortcut:{
            position: 'absolute',
            width: 'auto',
            height: '1.31rem',
            left: '3.12rem',fontWeight: '700',
            fontSize: '1.06rem',
            lineHeight: '1.25rem',
            display: 'flex',
            top: '12.81rem',
            alignItems: 'center',
            color: '#FFFFFF',
            fontFamily: "Lato"
        },
        boxTopHeader:{
            position: 'absolute',
            right: '2.37rem',
            height: '2.5rem',
            left: '2.37rem',
             display: { xs: "none", md: "flex" },
            top: '9.37rem',
            alignItems: 'right',
            background: '#000000',
            boxShadow: 'inset 0rem 0.81rem 0.43rem rgba(255, 255, 255, 0.15)',
        },
        buttonHeaderGroup:{
            width:'auto',
            height:'1.87rem',
            bottom: '-0.62rem',
            boxSizing: 'border-box',
            position: 'relative',
            background: '#24A0ED',
            border: '0.06rem solid #313439',
            boxShadow: 'inset 0rem -0.25rem 0.25rem rgba(0, 0, 0, 0.25)',
            borderRadius: '0.31rem 0.31rem 0rem 0rem',
            fontWeight: '700',
            fontSize: '0.93rem',
            
            lineHeight: '1.1rem',
            alignItems: 'center',
            color: '#FFFFFF',
            fontFamily: "Lato",
            '&:focused':{
                background: '#24A0ED',
            border: '0.06rem solid #313439',
            boxShadow: 'inset 0rem -0.25rem 0.25rem rgba(0, 0, 0, 0.25)',
            borderRadius: '0.31rem 0.31rem 0rem 0rem',
            },
            '&:hover': {
                background: '#24A0ED',
            border: '0.06rem solid #313439',
            boxShadow: 'inset 0rem -0.25rem 0.25rem rgba(0, 0, 0, 0.25)',
            borderRadius: '0.31rem 0.31rem 0rem 0rem',
          },
          '&$active': {
            background: '#24A0ED',
            border: '0.06rem solid #313439',
            boxShadow: 'inset 0rem -0.25rem 0.25rem rgba(0, 0, 0, 0.25)',
            borderRadius: '0.31rem 0.31rem 0rem 0rem',
          },
        },
        buttonHeaderGroupMobile:{
            width:'auto',
            height:'1.87rem',
           marginTop:'0rem',
            boxSizing: 'border-box',
            position: 'relative',
            background: '#24A0ED',
            border: '0.06rem solid #313439',
            boxShadow: 'inset 0rem -0.25rem 0.25rem rgba(0, 0, 0, 0.25)',
            borderRadius: '0.31rem 0.31rem 0.31rem 0.31rem',
            fontWeight: '700',
            fontSize: '0.83rem',
            
            lineHeight: '0.93rem',
            alignItems: 'center',
            color: '#FFFFFF',
            fontFamily: "Lato",
            '&:focused':{
                background: '#24A0ED',
                border: '0.06rem solid #313439',
                boxShadow: 'inset 0rem -0.25rem 0.25rem rgba(0, 0, 0, 0.25)',
            borderRadius: '0.31rem 0.31rem 0.31rem 0.31rem',
            },
            '&:hover': {
                background: '#24A0ED',
            border: '0.06rem solid #313439',
            boxShadow: 'inset 0rem -0.25rem 0.25rem rgba(0, 0, 0, 0.25)',
            borderRadius: '0.31rem 0.31rem 0.31rem 0.31rem',
          },
          '&$active': {
            background: '#24A0ED',
            border: '0.06rem solid #313439',
            boxShadow: 'inset 0rem -0.25rem 0.25rem rgba(0, 0, 0, 0.25)',
            borderRadius: '0.31rem 0.31rem 0.31rem 0.31rem',
          },
        },
        buttonHeaderGroupLeft:{
            

          width:'auto',
            height:'1.87rem',
            bottom: '0rem',
            boxSizing: 'border-box',
            right: '2%',
            position: 'absolute',
            background: '#24A0ED',
            border: '0.06rem solid #313439',
            boxShadow: 'inset 0rem -0.25rem 0.25rem rgba(0, 0, 0, 0.25)',
            borderRadius: '0.31rem 0.31rem 0rem 0rem',
            fontWeight: '700',
            fontSize: '0.93rem',
            
            lineHeight: '1.1rem',
            alignItems: 'center',
            color: '#FFFFFF',
            fontFamily: "Lato",
            '&:focused':{
                background: '#24A0ED',
            border: '0.06rem solid #313439',
            boxShadow: 'inset 0rem -0.25rem 0.25rem rgba(0, 0, 0, 0.25)',
            borderRadius: '0.31rem 0.31rem 0rem 0rem',
            },
            '&:hover': {
                background: '#24A0ED',
            border: '0.06rem solid #313439',
            boxShadow: 'inset 0rem -0.25rem 0.25rem rgba(0, 0, 0, 0.25)',
            borderRadius: '0.31rem 0.31rem 0rem 0rem',
          },
          '&$active': {
            background: '#24A0ED',
            border: '0.06rem solid #313439',
            boxShadow: 'inset 0rem -0.25rem 0.25rem rgba(0, 0, 0, 0.25)',
            borderRadius: '0.31rem 0.31rem 0rem 0rem',
          },
        },

        buttonHeaderGroupInactive:{
            width:'auto',
            height:'1.87rem',
            bottom: '-0.62rem',
            boxSizing: 'border-box',
            position: 'relative',
            background: '#2A2F3B',
            border: '0.06rem solid #313439',
            boxShadow: 'inset 0rem 0.25rem 0.25rem rgba(0, 0, 0, 0.25)',
            borderRadius: '0.31rem 0.31rem 0rem 0rem',
            fontWeight: '700',
            fontSize: '0.93rem',
            lineHeight: '1.1rem',
            
            alignItems: 'center',
            color: '#FFFFFF',
            fontFamily: "Lato",
            '&:focused':{
                background: '#24A0ED',
            border: '0.06rem solid #313439',
            boxShadow: 'inset 0rem -0.25rem 0.25rem rgba(0, 0, 0, 0.25)',
            borderRadius: '0.31rem 0.31rem 0rem 0rem',
            },
            '&:hover': {
                background: '#24A0ED',
            border: '0.06rem solid #313439',
            boxShadow: 'inset 0rem -0.25rem 0.25rem rgba(0, 0, 0, 0.25)',
            borderRadius: '0.31rem 0.31rem 0rem 0rem',
          },
          '&$active': {
            background: '#24A0ED',
            border: '0.06rem solid #313439',
            boxShadow: 'inset 0rem -0.25rem 0.25rem rgba(0, 0, 0, 0.25)',
            borderRadius: '0.31rem 0.31rem 0rem 0rem',
          },
        },
        buttonHeaderGroupInactiveMobile:{
            width:'auto',
            height:'1.87rem',
            marginTop:'0rem',
            boxSizing: 'border-box',
            position: 'relative',
            background: '#54565E',
            border: '0.06rem solid #313439',
            boxShadow: 'inset 0rem 0.25rem 0.25rem rgba(0, 0, 0, 0.25)',
            borderRadius: '0.31rem 0.31rem 0.31rem 0.31rem',
            fontWeight: '700',
            fontSize: '0.83rem',
            lineHeight: '0.93rem',
            
            alignItems: 'center',
            color: '#FFFFFF',
            fontFamily: "Lato",
            '&:focused':{
                background: '#54565E',
            border: '0.06rem solid #313439',
            boxShadow: 'inset 0rem -0.25rem 0.25rem rgba(0, 0, 0, 0.25)',
            borderRadius: '0.31rem 0.31rem 0.31rem 0.31rem',
            },
            '&:hover': {
                background: '#54565E',
            border: '0.06rem solid #313439',
            boxShadow: 'inset 0rem -0.25rem 0.25rem rgba(0, 0, 0, 0.25)',
            borderRadius: '0.31rem 0.31rem 0.31rem 0.31rem',
          },
          '&$active': {
            background: '#54565E',
            border: '0.06rem solid #313439',
            boxShadow: 'inset 0rem -0.25rem 0.25rem rgba(0, 0, 0, 0.25)',
            borderRadius: '0.31rem 0.31rem 0.31rem 0.31rem',
          },
        },
        boxHeaderBelow:{
            position: 'absolute',
            width: 'auto',
            height:'auto',
            right: '2.37rem',
            left: '2.37rem',
            top: '11.87rem',
           
            background: '#171E29',
        },
        lablefilter:{
            position: 'absolute',
            width: 'auto',
            height: '1.31rem',
            left: '3.12rem',fontWeight: '700',
            fontSize: '1.06rem',
            lineHeight: '1.37rem',
            display: 'flex',
            top: '9.37rem',
            alignItems: 'center',
            color: '#FFFFFF',
            fontFamily: "Lato"
        },lableContains:{
            fontWeight: '700',
            fontSize: '1.06rem',
            lineHeight:'6rem',
            top:'2.06rem',
           
            color: '#FFFFFF',
            fontFamily: "Lato"
        },
        lableContainsBig:{
            fontWeight: '700',
            fontSize: { xs: "1.1rem", md: '1.37rem' },
            lineHeight:{ xs: "4rem", md: '6rem' },
            top:'2.06rem',
           
            color: '#FFFFFF',
            fontFamily: "Lato"
        },
        lableContainsBigGreen:{
            fontWeight: '700',
            fontSize: { xs: "1.1rem", md: '1.37rem' },
            lineHeight:{ xs: "4rem", md: '6rem' },
            top:'2.06rem',
           
            color: '#00FF19',
            fontFamily: "Lato"
        },
        lableContainsBigRed:{
            fontWeight: '700',
            fontSize: { xs: "1.1rem", md: '1.37rem' },
            lineHeight:{ xs: "4rem", md: '6rem' },
            top:'2.06rem',
           
            color: '#ff0000',
            fontFamily: "Lato"
        },
        lableJump:{
            position: 'absolute',
            width: '6.87rem',
            height: '1.31rem',
            left: '3.12rem',
            top: '15.62rem',
            display: { xs: "none", md: "flex" },
            fontFamily: "Lato",
            fontWeight: '700',
            fontSize: '0.93rem',
            lineHeight: '1.1rem',
            
            color: '#FFFFFF',
        },
        cellHeader:{
           
            fontFamily: 'Lato',
            fontWeight: '500',
            fontSize: '0.75rem',
            lineHeight: '0.87rem',
           
            '&:focused':{
                color: '#878787',
            },
            '&:hover': {
                color: '#878787',
          },
          '&$active': {
            color: '#878787',
          },
            color: '#FFFFFF',
            
        },
        cellRow:{
           
            fontFamily: 'Lato',
            fontWeight: '500',
            fontSize: '0.75rem',
            lineHeight: '0.87rem',
           
           

            color: '#878787'
        },
        cellRowMedium:{
           
            fontFamily: 'Lato',
            fontWeight: '500',
            fontSize: '0.93rem',
            lineHeight: '0.93rem',
           
           

            color: '#999999'
        },
        cellRowMediumPink:{
           
            fontFamily: 'Lato',
            fontWeight: '500',
            fontSize: '0.93rem',
            lineHeight: '0.93rem',
           
           

            color: '#ffb6c1'
        },
        cellRowBig:{
           
            fontFamily: 'Lato',
            fontWeight: '500',
            fontSize: '0.93rem',
            lineHeight: '1.1rem',
           
           

            color: '#878787'
        },
        cellRowLight:{
           
            fontFamily: 'Lato',
            fontWeight: '500',
            fontSize: '0.75rem',
            lineHeight: '0.87rem',
           
           

            color: '#F7F7F7'
        },
        cellRowTypo:{
            cursor: 'pointer',
            fontFamily: 'Lato',
            fontWeight: '500',
            fontSize: '0.75rem',
            lineHeight: '0.87rem',
            '&:hover': {
                color: 'rgba(36, 160, 237, 1)',
          },
           

            color: '#878787'
        },
        cellRowTypoGreen:{
            cursor: 'pointer',
            fontFamily: 'Lato',
            fontWeight: '500',
            fontSize: '0.75rem',
            lineHeight: '0.87rem',
           
           

            color: '#00FF00'
        },
        cellRowBig:{
           
            fontFamily: 'Lato',
            fontWeight: '500',
            fontSize: '0.93rem',
            lineHeight: '0.93rem',
           
           

            color: '#878787'
        },
        popUpLable:{
            fontFamily: 'Lato',
            fontWeight: '500',
            fontSize: '1rem',
            lineHeight: '1.37rem',
            color:'#fff',
            
        },
        buttonLable:{
            fontFamily: 'Lato',
            fontWeight: '600',
            fontSize: '1.2rem',
            lineHeight: '1.47rem',
            color:'#fff',
            textAlign:'center',
            marginLeft:'5px',
            marginRight:'5px',
            marginBottom:'5px'
        },
        MarqueeLable:{
            fontFamily: 'Lato',
            fontWeight: '500',
            fontSize: '1.6rem',
            lineHeight: '2rem',
            color:'#fff',
            
        },
        popUpLableNewone:{
            height:'2.18rem',
            color:'#fff',
            
        },
        popUpWarner:{
            fontFamily: 'Lato',
            fontWeight: '500',
            fontSize: '1rem',
            lineHeight: '1.37rem',
            color:'#FF4400',
            marginLeft: '1.56rem',
           
        },
        popUpLableRedSmall:{
            fontFamily: 'Lato',
            fontWeight: '500',
            fontSize: '0.87rem',
            lineHeight: '1.25rem',
            color:'#ff0000',
            
        },
        formCheckBox:{
            color: 'white',
            '&.Mui-checked': {
              color: 'white',
            },
            '&.Mui-disabled': {
                color: 'gray',
              },
            height:'2.5rem',
            width:'1.56rem',
          },
          formCheckBoxLow:{
            color: 'white',
            '&.Mui-checked': {
              color: 'white',
            },
            '&.Mui-disabled': {
                color: 'gray',
              },
            height:'1.56rem',
            width:'1.56rem',
          },
        popUpLableAnswer:{
            fontFamily: 'Lato',
            fontWeight: '500',
            fontSize: '1rem',
            lineHeight: '1.37rem',
            color:'#878787',
        },

        popUpLableGreenClickable:{
           
            fontFamily: 'Lato',
            fontWeight: '500',
            fontSize: '1rem',
            lineHeight: '1.37rem',
            cursor:'pointer',
            

            color: '#00FF19'
        },
        
        popUpLableAnswerfixedWidth:{
            
            fontFamily: 'Lato',
            fontWeight: '500',
            fontSize: '0.75rem',
            lineHeight: '1.25rem',
            color:'#878787',
        },
        popUpLableAnswerGreen:{
            fontFamily: 'Lato',
            fontWeight: '500',
            fontSize: '1rem',
            lineHeight: '1.37rem',
            color:'#00FF19',
        },

        boxDown:{
            position: 'relative',
            width:'100%',
            top:{ xs: '-0.62rem', md: '0.62rem' },
            display: 'flex',
  justifyContent: 'flex-start',
  marginBottom:{ xs: '3rem', md: '1.56rem' } ,
        },

        boxDownExp:{
            position: 'relative',
            width:'100%',
            top:'1.56rem',
            display: 'flex',
  justifyContent: 'flex-start'
        },
        boxDownInner:{
            flex: '0 1 auto',
 
  position: 'absolute',
  /* new */
  left: '50%',
  transform: 'translateX(-50%)'
        },
        boxDonwLeft:{
            flex: '0 1 auto',
  
  marginLeft: 'auto',
  marginRight:'2.37rem',
   display: { xs: "none", md: "flex" }
        },

        cellGreen:{
           
            fontFamily: 'Lato',
            fontWeight: '500',
            fontSize: '0.75rem',
            lineHeight: '0.87rem',
           
           

            color: '#00FF19'
        },
        cellGreenBig:{
           
            fontFamily: 'Lato',
            fontWeight: '500',
            fontSize: '0.85rem',
            lineHeight: '0.97rem',
           
           

            color: '#00FF19'
        },
        cellGreenClickable:{
           
            fontFamily: 'Lato',
            fontWeight: '500',
            fontSize: '0.75rem',
            lineHeight: '0.87rem',
            cursor:'pointer',
           

            color: '#00FF19'
        },
        cellGreenClickableBig:{
           
            fontFamily: 'Lato',
            fontWeight: '500',
            fontSize: '0.85rem',
            lineHeight: '0.97rem',
            cursor:'pointer',
           

            color: '#00FF19'
        },
        cellWhite:{
           
            fontFamily: 'Lato',
            fontWeight: '500',
            fontSize: '0.75rem',
            lineHeight: '0.87rem',
           
           

            color: '#ffffff'
        },
        cellWhiteBig:{
           
            fontFamily: 'Lato',
            fontWeight: '500',
            fontSize: '0.85rem',
            lineHeight: '0.97rem',
           
           

            color: '#ffffff'
        },
        cellGreenButton:{
            marginTop:'-0.62rem',
            fontWeight: '500',
            fontSize: '0.75rem',
            lineHeight: '0.87rem',
            width:'auto',
            fontFamily: 'Lato',
           
            backgroundColor:'#00000000',
           
           
            color: '#00FF19'
        },
        popUpLableAnswerGreenButton:{
            marginTop:'-0.62rem',
           
            fontFamily: 'Lato',
            fontWeight: '500',
            width:'1.87rem',
           height:'3.12rem',
            fontSize: '1rem',
            lineHeight: '1.37rem',
            color:'#00FF19',
            backgroundColor:'#00000000',
            
        },
        cellYellow:{
           
            fontFamily: 'Lato',
            fontWeight: '500',
            fontSize: '0.75rem',
            lineHeight: '0.87rem',
           
           

            color: '#FFE600'
        },
        cellYellowButton:{
            marginTop:'-0.62rem',
            fontWeight: '500',
            fontSize: '0.75rem',
            lineHeight: '0.87rem',
            width:'auto',
            fontFamily: 'Lato',
           
            backgroundColor:'#00000000',
           
           
            color: '#FFE600'
        },
        popUpLableAnswerYellowButton:{
            marginTop:'-0.62rem',
           
            fontFamily: 'Lato',
            fontWeight: '500',
            width:'1.87rem',
           height:'3.12rem',
            fontSize: '1rem',
            lineHeight: '1.37rem',
            color:'#FFE600',
            backgroundColor:'#00000000',
            
        },
        cellRed:{
           
            fontFamily: 'Lato',
            fontWeight: '500',
            fontSize: '0.75rem',
            lineHeight: '0.87rem',
           
           

            color: '#FF0000'
        },
        cellBlue:{
           
            fontFamily: 'Lato',
            fontWeight: '500',
            fontSize: '0.85rem',
            lineHeight: '0.97rem',
           
           

            color: '#00AAFF'
        },

        
        fontSize: '0.85rem',
            lineHeight: '0.97rem',
        cellRedButton:{
            marginTop:'-0.62rem',
            fontWeight: '500',
            fontSize: '0.75rem',
            lineHeight: '0.87rem',
            width:'auto',
            fontFamily: 'Lato',
           
            backgroundColor:'#00000000',
           
           
            color: '#FF0000'
        },
        popUpLableAnswerRedButton:{
            marginTop:'-0.62rem',
           
            fontFamily: 'Lato',
            fontWeight: '500',
            width:'1.87rem',
           height:'3.12rem',
            fontSize: '1rem',
            lineHeight: '1.37rem',
            color:'#FF0000',
            backgroundColor:'#00000000',
            
        },
        ButtonJump:{
            
            width: '0.31rem',
            height: '0.62rem',
            fontFamily: "Lato",
            fontWeight: '700',
            fontSize: '0.62rem',
            lineHeight: '0.75rem',
           
            borderRadius: '0rem',
            backgroundColor: 'rgba(36, 160, 237, 0)',
                
            color: '#FFFFFF',
            '&:disabled': {
                color: ' rgba(255, 255, 255, 0.3)',
          },
        },
        InputFilter:{
            width: 'auto',
            fontWeight: '700',
            fontSize: '1.06rem',
            lineHeight: '1.37rem',
            top: '2.06rem',
            '& .MuiFilledInput-root': {
                border: '0.06rem solid rgba(31, 39, 54, 1)',
                '&:hover': {
                    color: 'rgba(255, 255, 255, 1)',
                    height:'2.06rem',
                    borderRadius: '0.31rem',
                    backgroundColor: '#171E29',
                    boxShadow: 'inset 0rem 0.25rem 0.25rem rgba(0, 0, 0, 0.25)',
                    padding: '0.31rem 0 0.31rem 0',
                    textIndent: '0.93rem !important',
                    border: `0.06rem solid rgba(31, 39, 54, 1)`,
                },
                '&.Mui-focused': {
                    color: 'rgba(255, 255, 255, 1)',
                                height:'2.06rem',
                                borderRadius: '0.31rem',
                                backgroundColor: '#171E29',
                                boxShadow: 'inset 0rem 0.25rem 0.25rem rgba(0, 0, 0, 0.25)',
                                padding: '0.31rem 0 0.31rem 0',
                                textIndent: '0.93rem !important',
                                border: `0.06rem solid rgba(31, 39, 54, 1)`,
                },
              },
            
        },
        InputFilterSmall:{
            maxWidth: '9.37rem',
            fontWeight: '700',
            fontSize: '1.06rem',
            lineHeight: '1.37rem',
            top: '2.06rem',
            
        },

        InputFilterPopup:{
            width: 'auto',
            fontWeight: '700',
            fontSize: '1.06rem',
            lineHeight: '1.37rem',
           
            top: '-3.12rem',
        },
       
        InputMenuItem:{
            width: 'auto',
            fontWeight: '700',
            fontSize: '1.06rem',
            height:'2.5rem',
            
            '& .MuiFilledInput-root': {
                border: '0.06rem solid rgba(31, 39, 54, 1)',
              /*  '&:hover': {
                    color:'rgba(255, 255, 255, 1)',
        height:'2.06rem',
        borderRadius: '0.31rem',
        backgroundColor: '#121212',
        boxShadow: 'inset 0rem 0.25rem 0.25rem rgba(0, 0, 0, 0.25)',
        padding: '0 0 0.93rem 0',
        textIndent: '0.93rem !important',
                },*/
                '&.Mui-focused': {
                    color:'rgba(255, 255, 255, 1)',
        height:'2.06rem',
        borderRadius: '0.31rem',
        backgroundColor: '#121212',
        boxShadow: 'inset 0rem 0.25rem 0.25rem rgba(0, 0, 0, 0.25)',
        padding: '0 0 0.93rem 0',
        textIndent: '0.93rem !important',
                },
              },
              
          
        },
    boxHome:{
                backgroundColor: '#171E29',
                height: '7.87rem',
                color: '#fff',
                borderRadius: 0,
                fontSize: '1.25rem',
                fontFamily: "Lato",
                fontWeight: 700
        },
    boxHomeMobile:{
                backgroundColor: '#171E29',
                height: '6.25rem',
                color: '#fff',
                borderRadius: 0,
                fontSize: '0.87rem',
                lineHeight:'1rem',
                fontFamily: "Lato",
                fontWeight: 700
    }
    
}