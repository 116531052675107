import * as React from "react";
import { Box } from "@mui/system";
import { Button,Checkbox, Switch,Grid, InputLabel,Select,MenuItem, List,Divider, FormControlLabel, Typography,ButtonGroup,Snackbar ,Alert ,IconButton, Icon,Dialog,DialogTitle,DialogContent,DialogActions,DialogContentText } from "@mui/material";
import LoadingButton from '@mui/lab/LoadingButton';
import {mainThemeStyle} from './components/MainTheme';
import { NestedButtonDropdown } from "./components/header/NestedButtonDropdown";
import  NestedButtonDropDownHeader  from "./components/header/NestedButtonDropDownHeader";
import TextField from '@mui/material/TextField';
import SaveIcon from '@mui/icons-material/Save';
import Tooltip from '@mui/material/Tooltip';
import InfoOutlinedIcon from '@mui/icons-material/InfoOutlined';
import Stack from '@mui/material/Stack';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Paper from '@mui/material/Paper';
import useInnerTable from "./components/useInnerTable";
import ListItemIcon from '@mui/material/ListItemIcon';
import ListItemText from '@mui/material/ListItemText';
import DoNotDisturbIcon from '@mui/icons-material/DoNotDisturb';
import QRCode from "qrcode";
import { faker } from '@faker-js/faker';
import { format } from 'date-fns';
import { useState,useRef,useEffect } from "react";
import useToken from "./useToken";
import useTable from "./components/useTable";
import SvgIcon from "@mui/material/SvgIcon";
import NoImage from './asset/noimage.png';
import Popup from "./components/Popup";
import _ from "lodash";
//import moment from "moment";
import moment from "moment-timezone";
import DeleteIcon from '@mui/icons-material/Delete';
import EditIcon from '@mui/icons-material/Edit';
import RestartAltIcon from '@mui/icons-material/RestartAlt';
import { styled } from '@mui/material/styles';
import { useLocation } from "react-router-dom";
import TimezoneSelect from 'react-timezone-select';
import Timezones from "./components/Timezones";
import ArrowDownIcon from "@mui/icons-material/ArrowDropDown";
import GlobalAlert from "./GlobalAlert";
import GlobalRoleAccess from "./GlobalRoleAccess";
import GlobalRoleAccessInner from "./GlobalRoleAccessInner";
import Autocomplete from '@mui/material/Autocomplete';
import { useAutocomplete } from '@mui/base/AutocompleteUnstyled';
import {decode as base64_decode, encode as base64_encode} from 'base-64';
import axios from "./axios";
import { DisabledByDefault, SettingsAccessibility } from "@mui/icons-material";
const IOSSwitch = styled((props) => (
  <Switch focusVisibleClassName=".Mui-focusVisible" disableRipple {...props} />
))(({ theme }) => ({
  width: 42,
  height: 26,
  padding: 0,
  '& .MuiSwitch-switchBase': {
    padding: 0,
    margin: 2,
    transitionDuration: '300ms',
    '&.Mui-checked': {
      transform: 'translateX(16px)',
      color: '#fff',
      '& + .MuiSwitch-track': {
        backgroundColor: theme.palette.mode === 'dark' ? '#2ECA45' : '#65C466',
        opacity: 1,
        border: 0,
      },
      '&.Mui-disabled + .MuiSwitch-track': {
        opacity: 0.5,
      },
    },
    '&.Mui-focusVisible .MuiSwitch-thumb': {
      color: '#33cf4d',
      border: '6px solid #fff',
    },
    '&.Mui-disabled .MuiSwitch-thumb': {
      color:
        theme.palette.mode === 'light'
          ? theme.palette.grey[100]
          : theme.palette.grey[600],
    },
    '&.Mui-disabled + .MuiSwitch-track': {
      opacity: theme.palette.mode === 'light' ? 0.7 : 0.3,
    },
  },
  '& .MuiSwitch-thumb': {
    boxSizing: 'border-box',
    width: 22,
    height: 22,
  },
  '& .MuiSwitch-track': {
    borderRadius: 26 / 2,
    backgroundColor: theme.palette.mode === 'light' ? '#E9E9EA' : '#39393D',
    opacity: 1,
    transition: theme.transitions.create(['background-color'], {
      duration: 500,
    }),
  },
}));


export default function EditLocation ( {idOflocation,RefreshToken,...props}){
  const location = useLocation();
  const {token} = useToken();
  const { getAlertTitle} = GlobalAlert();
  //console.log(moment.tz.countries());
  const TimeZoneList = moment.tz.zonesForCountry('US');
  let [dataOfLocation,setDataOfLocation] = useState(idOflocation);
  const myContainer = useRef(null);
  const [selectedTimezone, setSelectedTimezone] =useState(
    dataOfLocation.time_zone
  );
  const changeSelectedTimeZone = (event) => {
    dataOfLocation.time_zone = event.target.value;
    setSelectedTimezone(event.target.value);
    setLocationForData();
  }
  const [openSnack, setOpenSnack] = React.useState(false);
  const [openSnackData, setOpenSnackData] = React.useState("This is Success Data");
  const [severityData, setSeverityData] = React.useState("success");
  const [selectedImage, setSelectedImage] = useState(null);
  const [currentBridge, setCurrentBridge] = useState( "");
  const [currentCamera, setCurrentCamera] = useState("");
  const [currentBridgeId, setCurrentBridgeId] = useState( "");
  const [currentCameraId, setCurrentCameraId] = useState("");
  const [currentBridgeType, setCurrentBridgeType] = useState("Dedicated");
  const [currentCameraType, setCurrentCameraType] = useState("Entry");
  
  const [qr,setqr] = useState('');
  const [imageUrl, setImageUrl] = useState("https://mdvaccess.com/"+dataOfLocation.loc_photo);

  //const [iPhoneUser, setiPhoneUser] = useState('James Webb');
  const {getRoleAccessVal} = GlobalRoleAccess();
  const {getRoleAccessInnerVal} = GlobalRoleAccessInner();

/*   const {
    getRootProps,
    getInputLabelProps,
    getInputProps,
    getListboxProps,
    getOptionProps,
    groupedOptions,
  } = useAutocomplete({
    id: 'use-autocomplete-demo',
    options: iPhoneUser,
    getOptionLabel: (option) => option.label,
  }); */

  const getDisableValue = (val) => {
    if(token.role == 'superadmin' || token.name === 'SUPER ADMIN'){
      if(token.sub_type == "1"){
        let idData ;
        let valOfDisableData = getRoleAccessInnerVal("39");
     //   console.log("value is "+ JSON.stringify(valOfDisableData));
        for(let i = 0; i < valOfDisableData.length ; i ++){
          if(valOfDisableData[i].id == val ){
            idData = i;
            break;
          }
        }
     //   console.log("value is "+ JSON.stringify(valOfDisableData[idData]));
        let valOfDisable = valOfDisableData[idData].administrator_control;
        if(valOfDisable == "1"){
          return false;
        }
        else{
          return true;
        }
      }
      else{
        let idData ;
        let valOfDisableData = getRoleAccessInnerVal("39");
        for(let i = 0; i < valOfDisableData.length ; i ++){
          if(valOfDisableData[i].id == val ){
            idData = i;
            break;
          }
        }
        let valOfDisable = valOfDisableData[idData].administrator_sub_control;
        if(valOfDisable == "1"){
          return false;
        }
        else{
          return true;
        }
      }
    }
    else if(token.role == 'distributor' || token.name === 'SUPER DISTRIBUTOR'){
      if(token.sub_type == "1"){
        let idData ;
        let valOfDisableData = getRoleAccessInnerVal("39");
       // console.log("value is "+ JSON.stringify(valOfDisableData));
        for(let i = 0; i < valOfDisableData.length ; i ++){
          if(valOfDisableData[i].id == val ){
            idData = i;
            break;
          }
        }
       // console.log("value is "+ JSON.stringify(valOfDisableData[idData]));
        let valOfDisable = valOfDisableData[idData].distributor_control;
        if(valOfDisable == "1"){
          return false;
        }
        else{
          return true;
        }
      }
      else{
        let idData ;
        let valOfDisableData = getRoleAccessInnerVal("39");
        for(let i = 0; i < valOfDisableData.length ; i ++){
          if(valOfDisableData[i].id == val ){
            idData = i;
            break;
          }
        }
        let valOfDisable = valOfDisableData[idData].distributor_sub_control;
        if(valOfDisable == "1"){
          return false;
        }
        else{
          return true;
        }
      }
    }
    else if(token.role == 'dealer' || token.name === 'SUPER DEALER'){
      if(token.sub_type == "1"){
        let idData ;
        let valOfDisableData = getRoleAccessInnerVal("39");
       // console.log("value is "+ JSON.stringify(valOfDisableData));
        for(let i = 0; i < valOfDisableData.length ; i ++){
          if(valOfDisableData[i].id == val ){
            idData = i;
            break;
          }
        }
       // console.log("value is "+ JSON.stringify(valOfDisableData[idData]));
        let valOfDisable = valOfDisableData[idData].dealer_control;
        if(valOfDisable == "1"){
          return false;
        }
        else{
          return true;
        }
      }
      else{
        let idData ;
        let valOfDisableData = getRoleAccessInnerVal("39");
        for(let i = 0; i < valOfDisableData.length ; i ++){
          if(valOfDisableData[i].id == val ){
            idData = i;
            break;
          }
        }
        let valOfDisable = valOfDisableData[idData].dealer_sub_control;
        if(valOfDisable == "1"){
          return false;
        }
        else{
          return true;
        }
      }
    }
    else if(token.role == 'admin'){
      if(token.sub_type == "1"){
        let idData ;
        let valOfDisableData = getRoleAccessInnerVal("39");
       // console.log("value is "+ JSON.stringify(valOfDisableData));
        for(let i = 0; i < valOfDisableData.length ; i ++){
          if(valOfDisableData[i].id == val ){
            idData = i;
            break;
          }
        }
       // console.log("value is "+ JSON.stringify(valOfDisableData[idData]));
        let valOfDisable = valOfDisableData[idData].admin_control;
        if(valOfDisable == "1"){
          return false;
        }
        else{
          return true;
        }
      }
      else{
        let idData ;
        let valOfDisableData = getRoleAccessInnerVal("39");
        for(let i = 0; i < valOfDisableData.length ; i ++){
          if(valOfDisableData[i].id == val ){
            idData = i;
            break;
          }
        }
        let valOfDisable = valOfDisableData[idData].admin_sub_control;
        if(valOfDisable == "1"){
          return false;
        }
        else{
          return true;
        }
      }
    }
    else{
      return true;
    }
   
    
  }


  const getBridgeData = () => {	
    let bridgeArray = [];	
    	
    for(let i = 0; i < (dataOfLocation.bridge).length ; i++){	
      if((dataOfLocation.bridge)[i].location_id == dataOfLocation.loc_search_code){	
        bridgeArray.push((dataOfLocation.bridge)[i]);	
          	
      }	
    }	
    return bridgeArray;	
  }	
  const getBridgeAddArray = () => {	
    let bridgeArray = [];	
    	
    for(let i = 0; i < (dataOfLocation.bridge).length ; i++){	
      if((dataOfLocation.bridge)[i].location_id == "" || (dataOfLocation.bridge)[i].type == "1"){	
        bridgeArray.push((dataOfLocation.bridge)[i]);	
          	
      }	
    }	
    const strAscending = [...bridgeArray].sort((a, b) =>	
    a.name > b.name ? 1 : -1,	
  );	
    return strAscending;	
  }	
  const getCameraData = () => {	
    let cameraArray = [];	
   	
    for(let i = 0; i < (dataOfLocation.camera).length ; i++){	
      if((dataOfLocation.camera)[i].location_id == dataOfLocation.loc_search_code){	
        cameraArray.push((dataOfLocation.camera)[i]);	
          	
      }	
    }	
    return cameraArray;	
  }	
  const getCameraAddArray = () => {	
    let cameraArray = [];	
   //console.log("camera add array"+JSON.stringify(dataOfLocation));	
    for(let i = 0; i < (dataOfLocation.camera).length ; i++){	
      if((dataOfLocation.camera)[i].location_id == "" ){	
        cameraArray.push((dataOfLocation.camera)[i]);	
          	
      }	
    }	
   /* cameraArray =  cameraArray.sort(function(a, b) {	
      if(a.name.toLowerCase() < b.name.toLowerCase()) return -1;	
      if(a.name.toLowerCase() > b.name.toLowerCase()) return 1;	
      return 0;	
     });*/	
     const strAscending = [...cameraArray].sort((a, b) =>	
    a.name > b.name ? 1 : -1,	
  );	
  //console.log("sort array"+JSON.stringify(strAscending));	
    return strAscending;	
  }	
  const [recordsBridge, setRecordsBridge] = useState(getBridgeData());	
  const [recordsCamera, setRecordsCamera] = useState(getCameraData());	
  const [addBridge,setAddBridge] = useState(getBridgeAddArray());	
  const [addCamera,setAddCamera] = useState(getCameraAddArray());	
  const handleBridgeDelete = (val) =>{	
   	
    for(let i = 0; i < (dataOfLocation.bridge).length ; i++){	
      if((dataOfLocation.bridge)[i].id == val){	
        (dataOfLocation.bridge)[i].location_id = "";	
        (dataOfLocation.bridge)[i].type = "0";	
        console.log("dataOfLocation.type "+(dataOfLocation.bridge)[i].type);	
      }	
    }	
    setLocationForData();	
  }	
  const handleCameraDelete = (val,val2) =>{	
    for(let i = 0; i < (dataOfLocation.camera).length ; i++){	
      if((dataOfLocation.camera)[i].id == val){	
        (dataOfLocation.camera)[i].location_id = "";	
        (dataOfLocation.camera)[i].type = "0";	
        console.log("dataOfLocation.type "+(dataOfLocation.camera)[i].type);	
      }	
    }	
    setLocationForData();	
  }

 

  const getBrivoDoorArray = () => {	
    let brivoArray = [];	
    
    let brivoarr = dataOfLocation.brivo_array;

    brivoArray.push({ id: "None", name: "None", disabled: false });	
    brivoarr.forEach(element => {
      let notinArray = false;
      for (let j = 0 ; j < dataOfLocation.brivoDoor.length ; j++){
        let accessarray = JSON.parse(dataOfLocation.brivoDoor[j]);
        console.log('accessarray'+accessarray);
        if(element.id != accessarray.brivoprimeid ){
          for(let i = 0; i < 64 ; i++){
            if(element.id == accessarray.program[i] ){
              notinArray = true;
              break;
            }
          }
        
        }else{
          notinArray = true;
        }
      }
      if(element.id != dataOfLocation.brivo_access.brivoprimeid ){
        for(let i = 0; i < 64 ; i++){
          if(element.id == dataOfLocation.brivo_access.program[i] ){
            notinArray = true;
            break;
          }
        }
      
      }else{
        notinArray = true;
      }
      if(notinArray == false){
       // brivoArray.push(element);	
        brivoArray.push({ id: element.id, name: element.name, disabled: false });
      }
      else{
        brivoArray.push({ id: element.id, name: element.name, disabled: true });
      }
      
    });

  /*  const strAscending = [...brivoArray].sort((a, b) =>	
    a.name > b.name ? 1 : -1,	
  );	*/
    return brivoArray;
  }	

  const getOptions = () => {

    let brivoArray = [];	
    let brivoarr = dataOfLocation.brivo_array;
   // console.log("IN hry opyoop");

    brivoArray.push({ id: "None", value: "None", label: "None", disabled: false });	
    brivoarr.forEach(element => {
      let notinArray = false;
      
      for (let j = 0 ; j < dataOfLocation.brivoDoor.length ; j++){
        let accessarray = JSON.parse(dataOfLocation.brivoDoor[j]);
        //console.log('accessarray'+accessarray);
        if(element.id != accessarray.brivoprimeid ){
          for(let i = 0; i < 64 ; i++){
            if(element.id == accessarray.program[i] ){
              notinArray = true;
              break;
            }
          }
        
        }else{
          notinArray = true;
        }
      }
      if(element.id != dataOfLocation.brivo_access.brivoprimeid ){
        for(let i = 0; i < 64 ; i++){
          if(element.id == dataOfLocation.brivo_access.program[i] ){
           // console.log('in element '+i + "found ");
            notinArray = true;
            break;
          }
        }
      
      }else{
       // console.log('main element ' + "found ");
        notinArray = true;
      }
      if(notinArray == false){
       // brivoArray.push(element);	
        brivoArray.push({ id: element.id, value: element.name, label: element.name, disabled: false });
      }
      else{
        brivoArray.push({ id: element.id, value: element.name, label: element.name, disabled: true });
      }
      
    });

    /*let optionArray  = brivoarr?.map(menuItem => ({
      label: menuItem.name,
      value: menuItem.name,
      disabled: menuItem.disabled,
      id: menuItem.id
    }));*/
    return brivoArray;
  
  }
  const getPdkNodeArray = () => {	
    let pdkArray = [];	
    
    let pdkarr = dataOfLocation.pdk_cloudid_array;

    pdkArray.push({ id: "None", name: "None", disabled: false });	
    pdkarr.forEach(element => {
     
       pdkArray.push({ id: "None", name: element, disabled: false });
     
    });

    return pdkArray;
  }	
  
  const getPdkGroupArray = () => {	
    let pdkArray = [];	
    
    let pdkarr = dataOfLocation.pdk_group_array;

    pdkArray.push({ id: "None", name: "None", disabled: false });	
    pdkarr.forEach(element => {
     
       pdkArray.push({ id: "None", name: element, disabled: false });
     
    });

    return pdkArray;
  }	

  const getBrivoGroupArray = () => {	
    let briArr = [];	
    
    let pdkarr = dataOfLocation.brivo_group_array;

    briArr.push("None");	
    pdkarr.forEach(element => {
     
      briArr.push(element);
     
    });

    return briArr;
  }	


  
  const getPdkDeviceArray = () => {	
    let pdkArray = [];	
    
    let brivoarr = dataOfLocation.pdk_device_array;

    pdkArray.push({ id: "None", name: "None", disabled: false });	
    brivoarr.forEach(element => {
      let notinArray = false;
      
      
      if(element.id == dataOfLocation.pdk_access.primeid ){
       
        notinArray = true;
      }
      if(element.id == dataOfLocation.pdk_access.secondid ){
       
        notinArray = true;
      }

      if(notinArray == false){
       pdkArray.push({ id: element.id, name: element.name, disabled: false });
      }
      else{
        pdkArray.push({ id: element.id, name: element.name, disabled: true });
      }
      
    });

    return pdkArray;
  }	
  

  const getBrivoCustomArray = () => {	
    let brivoArray = [];	
    
    let brivoarr = dataOfLocation.brivo_array;

    brivoArray.push({ id: "None", name: "None", disabled: false });	
    brivoarr.forEach(element => {
      let notinArray = false;
      if(dataOfLocation.brivo_access.icrs != null){
          for(let i = 0; i < 3 ; i++){
            if(element.id == dataOfLocation.brivo_access.icrs[i].id ){
              notinArray = true;
              break;
            }
          }
        }
      
      if(element.id == dataOfLocation.brivo_access.brivoprimeid ){
       
        notinArray = true;
      }
      if(notinArray == false){
       // brivoArray.push(element);	
        brivoArray.push({ id: element.id, name: element.name, disabled: false });
      }
      else{
        brivoArray.push({ id: element.id, name: element.name, disabled: true });
      }
      
    });

  /*  const strAscending = [...brivoArray].sort((a, b) =>	
    a.name > b.name ? 1 : -1,	
  );	*/
    return brivoArray;
  }	
  
  const [brivoDoorArray,setBrivoDoorArray] = useState(getBrivoDoorArray());	


  const [options ,setOptions] = useState(getOptions());

  const [pdkNodeArray,setPdkNodeArray] = useState(getPdkNodeArray());	

  const [pdkGroupArray,setPdkGroupArray] = useState(getPdkGroupArray());	

  const [brivoGroupArray,setBrivoGroupArray] = useState(getBrivoGroupArray());	

  const [pdkDeviceArray,setPdkDeviceArray] = useState(getPdkDeviceArray());	

  const [brivoCustomArray,setBrivoCustomArray] = useState(getBrivoCustomArray());	
  const GenerateQRCode = () => {
   // let hexString = (dataOfLocation.loc_search_code).toString(16);
   let encoded = base64_encode(dataOfLocation.loc_search_code);
    let qr = '';
  //  let url = "https://mdvaccess.com/"+hexString;
    QRCode.toDataURL(
      "https://mdvaccess.com/virtual/"+encoded,
      {
        width : 800,
        margin : 2,
      },
      (err,url) => {
        if(err) return console.error(err);
        //console.log(url);
        setqr(url);
       
      }
    )
    //return qr;
  }

  
  useEffect(() =>{
    GenerateQRCode();
  }, []);
  useEffect(() => {
    //console.log("readr image calling");
    if (selectedImage) {
      setImageUrl(URL.createObjectURL(selectedImage));
    //  console.log("sle"+selectedImage.name);
      /*var reader = new FileReader();
      reader.readAsDataURL(URL.createObjectURL(selectedImage));
      console.log("readr image");
      reader.onload = () => {
        console.log("reader Image"+reader.result); //base64encoded string
      };
      reader.onerror = error => {
        console.log("Error: ", error);
      };*/
    }
  }, [selectedImage]);
  const handleCloseSnack= (event, reason) => {
    if (reason === 'clickaway') {
      return;
    }
    setOpenSnack(false);
  };
  
 // console.log("data of table"+idOflocation); 
  const [loading, setLoading] = React.useState(false);
  let holdeopnermode = 0;
  if(dataOfLocation.hold_open.indefinite == "1"){
    holdeopnermode = 0;
  }
  else if(dataOfLocation.hold_open.definite == "1"){
    holdeopnermode = 1;
  }
  else if(dataOfLocation.hold_open.finite == "1"){
    holdeopnermode = 2;
  }
  const [enableHoldOpen, setEnableHoldOpen] =useState(parseInt(dataOfLocation.hold_open.holdopen));
  
  
  const [enableElevatorControl, setEnableElevatorControl] =useState(0);
  const [currentTab, setCurrentTab] = useState(0);
  const rowsHoleOpen =['1','2','3','4','5','6','7'];
  const rowsElevatorControl =[0,1,2,3,4,5,6,7];
  const handleTabClick =(val) =>{
    setCurrentTab(val);
  }
  const [showUpdateButton,setshowUpdateButton] = useState(false);
  const [holdopenvar, setholdopenvar] = useState({holdopen:"0",indefinite:"1",definite:"0",finite:"0",expiry:"",time_on:"12:00 AM",time_off:"12:00 AM",relay1:"0",relay2:"0",table:[{"st":"12:00 AM","en":"12:00 AM","s":"0","m":"0","t":"0","w":"0","th":"0","f":"0","sa":"0","r1":"0","r2":"0"},{"st":"12:00 AM","en":"12:00 AM","s":"0","m":"0","t":"0","w":"0","th":"0","f":"0","sa":"0","r1":"0","r2":"0"},{"st":"12:00 AM","en":"12:00 AM","s":"0","m":"0","t":"0","w":"0","th":"0","f":"0","sa":"0","r1":"0","r2":"0"},{"st":"12:00 AM","en":"12:00 AM","s":"0","m":"0","t":"0","w":"0","th":"0","f":"0","sa":"0","r1":"0","r2":"0"},{"st":"12:00 AM","en":"12:00 AM","s":"0","m":"0","t":"0","w":"0","th":"0","f":"0","sa":"0","r1":"0","r2":"0"},{"st":"12:00 AM","en":"12:00 AM","s":"0","m":"0","t":"0","w":"0","th":"0","f":"0","sa":"0","r1":"0","r2":"0"},{"st":"12:00 AM","en":"12:00 AM","s":"0","m":"0","t":"0","w":"0","th":"0","f":"0","sa":"0","r1":"0","r2":"0"}]});
  const [elevatorvar, setelevatorvar] = useState({elevatorcontrol:"0",ipaddress:"",totalrelays:"",elduration:"",flduration:"",diselevator:"",disrelease:"",diselapp:"",program:[null,null,null,null,null,null,null,null,null,null,null,null,null,null,null,null,null,null,null,null,null,null,null,null,null,null,null,null,null,null,null,null,null,null,null,null,null,null,null,null,null,null,null,null,null,null,null,null,null,null,null,null,null,null,null,null,null,null,null,null,null,null,null,null]});
  const [brivovar, setbrivovar] = useState({brivoprimeid:"",brivoprimename:"",brivofloors:"",program:[null,null,null,null,null,null,null,null,null,null,null,null,null,null,null,null,null,null,null,null,null,null,null,null,null,null,null,null,null,null,null,null,null,null,null,null,null,null,null,null,null,null,null,null,null,null,null,null,null,null,null,null,null,null,null,null,null,null,null,null,null,null,null,null]});
  
 
  
  const handleChangeEventOfHoldOpen = (event) => {
    setEnableHoldOpen(event.target.checked);
    if(dataOfLocation.hold_open["holdopen"] === undefined){
    console.log("In not available");
    holdopenvar.holdopen = event.target.checked ? "1" : "0";
    setholdopenvar(holdopenvar);
    dataOfLocation.hold_open = holdopenvar;
    }else{
      console.log("In  available");
      console.log("In  available and event target"+ event.target.checked);
      dataOfLocation.hold_open.holdopen = event.target.checked ? "1" : "0";
    }
    //console.log(dataOfLocation);
    setLocationForData();
  };

  const handleIndefiniteRelay = (event) => {
    let ds = event.target.name;
    dataOfLocation.hold_open[ds] = event.target.checked ? "1" : "0" ;
    setLocationForData();
  }
  const handleOneTimeValue = (event) => {
    let ds = event.target.name;
    dataOfLocation.hold_open[ds] = event.target.value ;
    setLocationForData();
  }
  const handleChangeOpenStart=(event)=>{
    let ds = event.target.name;
    dataOfLocation.hold_open.table[parseInt(ds)].st = event.target.value;
    setLocationForData();
  }
  const handleChangEndStart=(event)=>{
    let ds = event.target.name;
    dataOfLocation.hold_open.table[parseInt(ds)].en = event.target.value;
    setLocationForData();
  }
  const handleSundayRelay = (event) => {
    let ds = event.target.name;
    dataOfLocation.hold_open.table[parseInt(ds)].s = event.target.checked ? "1" : "0" ;
    setLocationForData();
  }
  const handleMondayRelay = (event) => {
    let ds = event.target.name;
    dataOfLocation.hold_open.table[parseInt(ds)].m = event.target.checked ? "1" : "0" ;
    setLocationForData();
  }
  const handleTuesdayRelay = (event) => {
    let ds = event.target.name;
    dataOfLocation.hold_open.table[parseInt(ds)].t = event.target.checked ? "1" : "0" ;
    setLocationForData();
  }
  const handleWednesdayRelay = (event) => {
    let ds = event.target.name;
    dataOfLocation.hold_open.table[parseInt(ds)].w = event.target.checked ? "1" : "0" ;
    setLocationForData();
  }
  const handleThursdayRelay = (event) => {
    let ds = event.target.name;
    dataOfLocation.hold_open.table[parseInt(ds)].th = event.target.checked ? "1" : "0" ;
    setLocationForData();
  }
  const handleFridayRelay = (event) => {
    let ds = event.target.name;
    dataOfLocation.hold_open.table[parseInt(ds)].f = event.target.checked ? "1" : "0" ;
    setLocationForData();
  }
  const handleSaturdayRelay = (event) => {
    let ds = event.target.name;
    dataOfLocation.hold_open.table[parseInt(ds)].sa = event.target.checked ? "1" : "0" ;
    setLocationForData();
  }
  const handleHoldRelayOne = (event) => {
    let ds = event.target.name;
    dataOfLocation.hold_open.table[parseInt(ds)].r1 = event.target.checked ? "1" : "0" ;
    setLocationForData();
  }
  const handleHoldRelayTwo = (event) => {
    let ds = event.target.name;
    dataOfLocation.hold_open.table[parseInt(ds)].r2 = event.target.checked ? "1" : "0" ;
    setLocationForData();
  }
  const handleChangeValueOfHoldOpen = (event) => {
    let ds = event.target.name;
    if( ds == "indefinite"){
        dataOfLocation.hold_open.indefinite ="1" ;
        dataOfLocation.hold_open.definite = "0" ;
        dataOfLocation.hold_open.finite =  "0";
      }
    else if(ds == "definite"){
        dataOfLocation.hold_open.indefinite ="0" ;
        dataOfLocation.hold_open.definite = "1" ;
        dataOfLocation.hold_open.finite =  "0";
      }
    else if (ds == "finite"){
        dataOfLocation.hold_open.indefinite ="0" ;
        dataOfLocation.hold_open.definite = "0" ;
        dataOfLocation.hold_open.finite =  "1";
    }
    console.log(dataOfLocation);
    setLocationForData();
  };
  const handleChangeBrivoFloorValue = (event) => {

    if( event.target.value > (dataOfLocation.brivo_array.length)){
      event.target.value  =  dataOfLocation.brivo_array.length;
    }
    dataOfLocation.brivo_access.brivofloors = event.target.value;
    setLocationForData();
  }

  const handleChangeBrivoDoorValue = (event,newValue) => {
   /* if(dataOfLocation.brivo_access["brivoprimeid"] === undefined){
      //console.log("in not defined");
      let brivoarr = dataOfLocation.brivo_array;
      brivovar.brivofloors = brivoarr.length;
      brivoarr.forEach(element => {
        if(element.name == event.target.value){
          brivovar.brivoprimeid = element.id;
          brivovar.brivoprimename = element.name;
        }
      });
      setbrivovar(brivovar);
      dataOfLocation.brivo_access = brivovar;
      setLocationForData();
    }else{*/
      //console.log("in  defined");
      if(newValue){
      console.log("new value os "+JSON.stringify(newValue.value));
      let brivoarr = dataOfLocation.brivo_array;
      brivovar.brivofloors = brivoarr.length;
      //setbrivovar(brivovar);
      if(newValue == "None"){
        dataOfLocation.brivo_access.brivoprimeid = "";
        dataOfLocation.brivo_access.brivoprimename = "";
      }else{
      brivoarr.forEach(element => {
        if(element.name == newValue.value){
          //dataOfLocation.brivo_access.brivofloors = brivoarr.length;
          dataOfLocation.brivo_access.brivoprimeid = element.id;
          dataOfLocation.brivo_access.brivoprimename = element.name;
        }
      });
    }
      setLocationForData();
    }
   // }
  }

  const handleChangePDKMainValue = (event) => {
   
       let pdkarr = dataOfLocation.pdk_device_array;
      
       if(event.target.value == "None"){
         dataOfLocation.pdk_access.primeid = "";
         dataOfLocation.pdk_access.primename = "";
       }else{
        pdkarr.forEach(element => {
         if(element.name == event.target.value){
          
           dataOfLocation.pdk_access.primeid = element.id;
           dataOfLocation.pdk_access.primename = element.name;
         }
       });
     }
       setLocationForData();
    // }
   }

   const handleChangePDKNodeValue = (event) => {
   
    let pdkarr = pdkNodeArray;
   
    if(event.target.value == "None"){
      dataOfLocation.pdk_node = "";
     
    }else{
     pdkarr.forEach(element => {
      if(element.name == event.target.value){
       
        dataOfLocation.pdk_node = element.name;
       
      }
    });
  }
  console.log("value of event is "+ event.target.value);
    setLocationForData();
 
}
const handleChangePDKGroupValue = (event,newValue) => {
   
  console.log("call inside");
  let pdkarr = pdkGroupArray;
 
  if(newValue == "None"){
    dataOfLocation.pdk_group = "";
   
  }else{
   pdkarr.forEach(element => {
    if(element.name == newValue){
     
      dataOfLocation.pdk_group = element.name;
     
    }
  });
}
  setLocationForData();

}
const handleChangeBrivoGroupValue = (event,newValue) => {
   
  console.log("call inside");
  let pdkarr = brivoGroupArray;
 
  if(newValue == "None"){
    dataOfLocation.brivo_group_name = "";
   
  }else{
   pdkarr.forEach(element => {
    if(element == newValue){
     
      dataOfLocation.brivo_group_name = element;
     
    }
  });
}
  setLocationForData();

}



/* const options = brivoDoorArray?.map(menuItem => ({
  label: menuItem.name,
  value: menuItem.name,
  disabled: menuItem.disabled,
  id: menuItem.id
}));  */


   

   const handleChangePDKOptionalValue = (event) => {
   
    let pdkarr = dataOfLocation.pdk_device_array;
   
    if(event.target.value == "None"){
      dataOfLocation.pdk_access.secondid = "";
      dataOfLocation.pdk_access.secondname = "";
    }else{
     pdkarr.forEach(element => {
      if(element.name == event.target.value){
       
        dataOfLocation.pdk_access.secondid = element.id;
        dataOfLocation.pdk_access.secondname = element.name;
      }
    });
  }
    setLocationForData();
 // }
}

  const handleChangeCustomNameValue = (event) => {
      let ds = event.target.name;
      dataOfLocation.brivo_access.icrs[parseInt(ds)].name =  event.target.value;
      setLocationForData();
    
   }
   const handleChangeCustomDoorValue = (event) => {
       let brivoarr = dataOfLocation.brivo_array;
       let ds = event.target.name;
       if(event.target.value == "None"){
         dataOfLocation.brivo_access.icrs[parseInt(ds)].id = "";
         
       }else{
       brivoarr.forEach(element => {
         if(element.name == event.target.value){
           dataOfLocation.brivo_access.icrs[parseInt(ds)].id = element.id;
         }
       });
     }
       setLocationForData();
    // }
   }
  const handleChangeBrivoRelay = (name) => (event, newValue) => {
    //console.log("name is "+name+ "nd event is "+ event + " and new value is "+newValue);

    
    if(newValue){
    console.log("target name is "+ name);
    let ds =name;
    let brivoarr = dataOfLocation.brivo_array;
    if(newValue.value == "None"){
      dataOfLocation.brivo_access.program[parseInt(ds)] = "";
    }else{
      brivoarr.forEach(element => {
        if(element.name == newValue.value){
          dataOfLocation.brivo_access.program[parseInt(ds)] = element.id;
        }
      });}
    setLocationForData();
    }
  }

  const getPDKNodeValue = () => {
    let nameOfunit;
   
    if(dataOfLocation.pdk_node == "" || dataOfLocation.pdk_node == null){
      nameOfunit = "None";
    }
    else{
      pdkNodeArray.forEach(element => {
      if(element.name == dataOfLocation.pdk_node){
        nameOfunit = element.name;
      }

    });
    }
    //console.log("main value"+nameOfunit );
    return nameOfunit;
  }

  const getBrivoGroupValue = () => {
    let nameOfunit;
   
    if(dataOfLocation.brivo_group_name == "" || dataOfLocation.brivo_group_name == null){
      nameOfunit = "None";
    }
    else{
     brivoGroupArray.forEach(element => {
      if(element == dataOfLocation.brivo_group_name){
        nameOfunit = element;
      }

    });
    }
    //console.log("main value"+nameOfunit );
    return nameOfunit;
  }
  const getPDKGroupValue = () => { 
    let nameOfunit;
   
    if(dataOfLocation.pdk_group == "" || dataOfLocation.pdk_group == null){
      nameOfunit = "None";
    }
    else{
      pdkGroupArray.forEach(element => {
      if(element.name == dataOfLocation.pdk_group){
        nameOfunit = element.name;
      }

    });
    }
    //console.log("main value"+nameOfunit );
    return nameOfunit;
  }
  const getBrivoValueMain = () => {
    let nameOfunit;
    let brivoarr = dataOfLocation.brivo_array;
    if(dataOfLocation.brivo_access.brivoprimeid == "" || dataOfLocation.brivo_access.brivoprimeid == null){
      nameOfunit = "None";
    }
    else{
    brivoarr.forEach(element => {
      if(element.id == dataOfLocation.brivo_access.brivoprimeid){
        nameOfunit = element.name;
      }

    });
    }
   // console.log("main value"+nameOfunit );
    return nameOfunit;
  }

  /* const getBrivoValueMain = () => {
    let returnobject;
    let brivoarr = brivoDoorArray;
    if(dataOfLocation.brivo_access.brivoprimeid == "" || dataOfLocation.brivo_access.brivoprimeid == null){
      brivoarr.forEach(element => {
        if(element.id == "None"){
          returnobject = element;
        }
  
      });
    }
    else{
    brivoarr.forEach(element => {
      if(element.id == dataOfLocation.brivo_access.brivoprimeid){
        returnobject = element;
      }

    });
    }
    console.log("main value"+JSON.stringify(returnobject) );
    return returnobject;
  } */

  const getPDKValueMain = () => {
    let nameOfunit;
    let pdkarr = dataOfLocation.pdk_device_array;
    if(dataOfLocation.pdk_access.primeid == "" || dataOfLocation.pdk_access.primeid == null){
      nameOfunit = "None";
    }
    else{
      pdkarr.forEach(element => {
      if(element.id == dataOfLocation.pdk_access.primeid){
        nameOfunit = element.name;
      }

    });
    }
    //console.log("main value"+nameOfunit );
    return nameOfunit;
  }

  const getPDKValueOptional = () => {
    let nameOfunit;
    let pdkarr = dataOfLocation.pdk_device_array;
    if(dataOfLocation.pdk_access.secondid == "" || dataOfLocation.pdk_access.secondid == null){
      nameOfunit = "None";
    }
    else{
      pdkarr.forEach(element => {
      if(element.id == dataOfLocation.pdk_access.secondid){
        nameOfunit = element.name;
      }

    });
    }
    //console.log("main value"+nameOfunit );
    return nameOfunit;
  }

  const getCustomNameValue = (idOfunit) => {
    let nameOfunit;
   
   
    nameOfunit = dataOfLocation.brivo_access.icrs[idOfunit].name;
  
    return nameOfunit;

  }
  const getCustomDoorValue = (idOfunit) => {
    let nameOfunit;
    let brivoarr = dataOfLocation.brivo_array;
   // let ads = JSON.stringify(dataOfLocation.brivo_access);
   //console.log(dataOfLocation.brivo_access.program);
   if(dataOfLocation.brivo_access.icrs[idOfunit].id == "" || dataOfLocation.brivo_access.icrs[idOfunit].id == null){
    nameOfunit = "None";
   }else{
    brivoarr.forEach(element => {
      if(element.id == dataOfLocation.brivo_access.icrs[idOfunit].id){
        nameOfunit = element.name;
      }
    });
  }
    return nameOfunit;

  }
  const getBrivoValue = (idOfunit) => {
    let nameOfunit;
    let brivoarr = dataOfLocation.brivo_array;
   // let ads = JSON.stringify(dataOfLocation.brivo_access);
   //console.log(dataOfLocation.brivo_access.program);
   if(dataOfLocation.brivo_access.program[idOfunit] == "" || dataOfLocation.brivo_access.program[idOfunit] == null){
    nameOfunit = "None";
   }else{
    brivoarr.forEach(element => {
      if(element.id == dataOfLocation.brivo_access.program[idOfunit]){
        nameOfunit = element.name;
      }
    });
  }
    return nameOfunit;
  }
  const handleChangeEleInnerRelay = (event) => {
    let ds = event.target.name;
    dataOfLocation.elevator_access.program[parseInt(ds)] = event.target.value ;
    setLocationForData();
  }
  const handleChangeEleValue = (event) => {
    let ds = event.target.name;
    dataOfLocation.elevator_access[ds] = event.target.value;
    setLocationForData();
  };
  const handleChangeEleCheckValue = (event) => {
    let ds = event.target.name;
    dataOfLocation.elevator_access[ds] = event.target.checked ? "1" : "0";
    setLocationForData();
  };
  const handleChangeEventOfElevatorControl = (event) => {
    setEnableElevatorControl(event.target.checked);
    if(dataOfLocation.elevator_access["elevatorcontrol"] === undefined){
    console.log("In not available");
    elevatorvar.elevatorcontrol = event.target.checked ? "1" : "0";
    setelevatorvar(elevatorvar);
    dataOfLocation.elevator_access = elevatorvar;
    }else{
      console.log("In  available");
      console.log("In  available and event target"+ event.target.checked);
      dataOfLocation.elevator_access.elevatorcontrol = event.target.checked ? "1" : "0";
    }
    //console.log(dataOfLocation);
    setLocationForData();
  };

  const ScreenLayout = {
    id: "1",
    label: "Portrait",
    items: [
      {
        label: "Portrait",
        callback: () =>  {}
      },
      {
        label: "Landscape",
        callback: () =>  {}
      },
    ]
  }
  
  const GetValueOfLocationScreenLayout = (val) => {
    if(val == "1"){
      return "Portrait";
    }else{
      return  "Landscape";
    }
  }
  const ChangeScreenLayout = (event) => {
    if(event.target.value == "Portrait") {
      dataOfLocation.loc_screen_layout = "1";
      setLocationForData();
    }else{
      dataOfLocation.loc_screen_layout = '0';
      setLocationForData();
    }
  }
  const UnlockingModeQR = {
    id: "1",
    label: "Web Relay",
    items: [
      {
        label: "Web Relay",
        callback: () =>  {}
      },
      {
        label: "BRIVO",
        callback: () =>  {}
      },{
        label: "PDK",
        callback: () =>  {}
      }
    ]
  }
  const GetUnlockingModeQR = () =>{
    if(dataOfLocation.unlocking_mode == '1'){
      return  "BRIVO";
    }
    else  if(dataOfLocation.unlocking_mode == '4'){
      return  "PDK";
    }
    else{
      return  "Web Relay";
    }
  }
  const handleSetUnlockingModeQR = (event) => {
    if(event.target.value == 'BRIVO'){
      dataOfLocation.unlocking_mode = '1'
      setLocationForData();
    }else if(event.target.value == 'PDK'){
      dataOfLocation.unlocking_mode = '4'
      setLocationForData();
    }
    else{
      dataOfLocation.unlocking_mode = '0';
      dataOfLocation.brivo_access = {brivoprimeid:"",brivoprimename:"",brivofloors:"",icrt:0,icrs:[{name:"",id:""},{name:"",id:""},{name:"",id:""}],program:[null,null,null,null,null,null,null,null,null,null,null,null,null,null,null,null,null,null,null,null,null,null,null,null,null,null,null,null,null,null,null,null,null,null,null,null,null,null,null,null,null,null,null,null,null,null,null,null,null,null,null,null,null,null,null,null,null,null,null,null,null,null,null,null]};
     
      setLocationForData();
    }
  }
  const UnlcokingMode = {
    id: "1",
    label: "BRIVO",
    items: [
      {
        label: "BRIVO",
        callback: () =>  {}
      },
      {
        label: "USB",
        callback: () =>  {}
      },
      {
        label: "GPIO",
        callback: () =>  {}
      },
      {
        label: "ResidentVue",
        callback: () =>  {}
      },
      {
        label: "PDK",
        callback: () =>  {}
      }
    ]
  }
  // if(dataOfLocation.unlocking_mode == '1'){
  //   UnlcokingMode.label =  "GPIO";
  // }else  if(dataOfLocation.unlocking_mode == '2'){
  //   UnlcokingMode.label =  "ResidentVue";
  // }else  if(dataOfLocation.unlocking_mode == '3'){
  //   UnlcokingMode.label =  "BRIVO";
  // }else{
  //   UnlcokingMode.label =  "USB";
  // }
  const GetUnlockingMode = () =>{
    if(dataOfLocation.unlocking_mode == '1'){
      return  "GPIO";
    }else  if(dataOfLocation.unlocking_mode == '2'){
      return  "ResidentVue";
    }else  if(dataOfLocation.unlocking_mode == '3'){
      return  "BRIVO";
    }else  if(dataOfLocation.unlocking_mode == '4'){
      return  "PDK";
    }
    else{
      return  "USB";
    }
  }
  const handleSetUnlockingMode = (event) => {
    if(event.target.value == 'GPIO'){
      dataOfLocation.unlocking_mode = '1'
      dataOfLocation.brivo_access = {brivoprimeid:"",brivoprimename:"",brivofloors:"",icrt:0,icrs:[{name:"",id:""},{name:"",id:""},{name:"",id:""}],program:[null,null,null,null,null,null,null,null,null,null,null,null,null,null,null,null,null,null,null,null,null,null,null,null,null,null,null,null,null,null,null,null,null,null,null,null,null,null,null,null,null,null,null,null,null,null,null,null,null,null,null,null,null,null,null,null,null,null,null,null,null,null,null,null]};
    
      setLocationForData();
    }else  if(event.target.value == 'ResidentVue'){
   
      dataOfLocation.unlocking_mode = '2'
      dataOfLocation.brivo_access = {brivoprimeid:"",brivoprimename:"",brivofloors:"",icrt:0,icrs:[{name:"",id:""},{name:"",id:""},{name:"",id:""}],program:[null,null,null,null,null,null,null,null,null,null,null,null,null,null,null,null,null,null,null,null,null,null,null,null,null,null,null,null,null,null,null,null,null,null,null,null,null,null,null,null,null,null,null,null,null,null,null,null,null,null,null,null,null,null,null,null,null,null,null,null,null,null,null,null]};
    
      setLocationForData();
    }else  if(event.target.value == 'BRIVO'){
      dataOfLocation.unlocking_mode = '3'

      setLocationForData();
    }
    else if(event.target.value == 'PDK'){
      dataOfLocation.unlocking_mode = '4'
      dataOfLocation.brivo_access = {brivoprimeid:"",brivoprimename:"",brivofloors:"",icrt:0,icrs:[{name:"",id:""},{name:"",id:""},{name:"",id:""}],program:[null,null,null,null,null,null,null,null,null,null,null,null,null,null,null,null,null,null,null,null,null,null,null,null,null,null,null,null,null,null,null,null,null,null,null,null,null,null,null,null,null,null,null,null,null,null,null,null,null,null,null,null,null,null,null,null,null,null,null,null,null,null,null,null]};
      setLocationForData();
    }
    else{
      dataOfLocation.unlocking_mode = '0'
      dataOfLocation.brivo_access = {brivoprimeid:"",brivoprimename:"",brivofloors:"",icrt:0,icrs:[{name:"",id:""},{name:"",id:""},{name:"",id:""}],program:[null,null,null,null,null,null,null,null,null,null,null,null,null,null,null,null,null,null,null,null,null,null,null,null,null,null,null,null,null,null,null,null,null,null,null,null,null,null,null,null,null,null,null,null,null,null,null,null,null,null,null,null,null,null,null,null,null,null,null,null,null,null,null,null]};
    
      setLocationForData();
    }
  }
  const AppUserUnlockMode = {
    id: "1",
    label: "ResidentVue Push Notification",
    items: [
      {
        label: "ResidentVue Push Notification",
        callback: () =>  {}
      },
      {
        label: "Unattended Unlock",
        callback: () =>  {}
      },
      {
        label: "Disable User Swipe Unlock",
        callback: () =>  {}
      }
    ]
  }
  const AppUserUnlockModeCBW = {
    id: "1",
    label: "Unattended Unlock",
    items: [
      {
        label: "Unattended Unlock",
        callback: () =>  {}
      },
      {
        label: "Disable User Swipe Unlock",
        callback: () =>  {}
      }
    ]
  }
  const GetAppUnlockModeCBW = () => {
    if(dataOfLocation.app_unlock_mode == '2'){
      return  "Disable User Swipe Unlock";
    }else{
      return  "Unattended Unlock";
    }
  }
  const GetAppUnlockMode = () => {
    if(dataOfLocation.app_unlock_mode == '1'){
      return  "Unattended Unlock";
    }else  if(dataOfLocation.app_unlock_mode == '2'){
      return  "Disable User Swipe Unlock";
    }else{
      return  "ResidentVue Push Notification";
    }
  }
  const DisplayedICon = {
    id: "1",
    label: 'Message Alert',
    items: [
      {
        label: 'Message Alert',
        callback: () =>  {}
      },
      {
        label: 'Bell Alert',
        callback: () =>  {}
      },
      {
        label: 'Info Alert',
        callback: () =>  {}
      },
      {
        label: 'Alert Lights',
        callback: () =>  {}
      },
      {
        label: 'Stop',
        callback: () =>  {}
      },
      {
        label: 'Announcement',
        callback: () =>  {}
      },
      {
        label: 'Hello',
        callback: () =>  {}
      },
      {
        label: 'Festive',
        callback: () =>  {}
      }
      ,
      {
        label: 'Maintenance',
        callback: () =>  {}
      }
    ]
  }

  const handleSetDisplayLogo = (event) => {
    
    if(event.target.value == 'Message Alert'){
      dataOfLocation.wel_message_headerlogo = 'https://cdn-icons-png.flaticon.com/512/1028/1028741.png';
      setLocationForData();
    }else  if(event.target.value == 'Bell Alert'){
      dataOfLocation.wel_message_headerlogo = 'https://cdn-icons-png.flaticon.com/512/2058/2058148.png';
      setLocationForData();
    }else  if(event.target.value == 'Info Alert'){
      dataOfLocation.wel_message_headerlogo = 'https://cdn-icons-png.flaticon.com/512/2538/2538026.png';
      setLocationForData();
    }else  if(event.target.value == 'Alert Lights'){
      dataOfLocation.wel_message_headerlogo = 'https://cdn-icons-png.flaticon.com/512/9069/9069950.png';
      setLocationForData();
    }
    else  if(event.target.value == 'Stop'){
      dataOfLocation.wel_message_headerlogo = 'https://cdn-icons-png.flaticon.com/512/2698/2698545.png';
      setLocationForData();
    }
    else  if(event.target.value == 'Announcement'){
      dataOfLocation.wel_message_headerlogo = 'https://cdn-icons-png.flaticon.com/512/8303/8303231.png';
      setLocationForData();
    }
    else  if(event.target.value == 'Hello'){
      dataOfLocation.wel_message_headerlogo = 'https://cdn-icons-png.flaticon.com/512/5129/5129597.png';
      setLocationForData();
    }
    else if(event.target.value == 'Festive'){
      dataOfLocation.wel_message_headerlogo = 'https://cdn-icons-png.flaticon.com/512/864/864763.png';
      setLocationForData();
    }
    else if(event.target.value == 'Maintenance'){
      dataOfLocation.wel_message_headerlogo = 'https://cdn-icons-png.flaticon.com/512/8956/8956124.png';
      setLocationForData();
    }
  }
  const getDisplayedLogo = () => {
    if(dataOfLocation.wel_message_headerlogo == 'https://cdn-icons-png.flaticon.com/512/1028/1028741.png'){
      return 'Message Alert';
    }
    else if(dataOfLocation.wel_message_headerlogo == 'https://cdn-icons-png.flaticon.com/512/2058/2058148.png'){
      return 'Bell Alert';
    }
    else if(dataOfLocation.wel_message_headerlogo == 'https://cdn-icons-png.flaticon.com/512/2538/2538026.png'){
      return 'Info Alert';
    }
    else if(dataOfLocation.wel_message_headerlogo == 'https://cdn-icons-png.flaticon.com/512/9069/9069950.png'){
      return 'Alert Lights';
    }
    else if(dataOfLocation.wel_message_headerlogo == 'https://cdn-icons-png.flaticon.com/512/2698/2698545.png'){
      return 'Stop';
    }
    else if(dataOfLocation.wel_message_headerlogo == 'https://cdn-icons-png.flaticon.com/512/8303/8303231.png'){
      return 'Announcement';
    }
    else if(dataOfLocation.wel_message_headerlogo == 'https://cdn-icons-png.flaticon.com/512/5129/5129597.png'){
      return 'Hello';
    }
    else if(dataOfLocation.wel_message_headerlogo == 'https://cdn-icons-png.flaticon.com/512/864/864763.png'){
      return 'Festive';
    }
    else if(dataOfLocation.wel_message_headerlogo == 'https://cdn-icons-png.flaticon.com/512/8956/8956124.png'){
      return 'Maintenance';
    }
    else{
      return 'Message Alert';
    }
  }
  const handleSetAppUnlockMode = (event) => {
    console.log(dataOfLocation);
    console.log(selectedTimezone.value);
    if(event.target.value == 'Unattended Unlock'){
      dataOfLocation.app_unlock_mode = '1'
      setLocationForData();
    }else  if(event.target.value == 'Disable User Swipe Unlock'){
      dataOfLocation.app_unlock_mode = '2'
      setLocationForData();
    }else{
      dataOfLocation.app_unlock_mode = '0'
      setLocationForData();
    }
  }
  const handleSetAppUnlockModeCBW = (event) => {
    console.log(dataOfLocation);
    console.log(selectedTimezone.value);
    if(event.target.value == 'Unattended Unlock'){
      dataOfLocation.app_unlock_mode = '1'
      setLocationForData();
    }else  if(event.target.value == 'Disable User Swipe Unlock'){
      dataOfLocation.app_unlock_mode = '2'
      setLocationForData();
    }else{
      dataOfLocation.app_unlock_mode = '1'
      setLocationForData();
    }
  }
  const [uploadLocationData, setUploadLocationData] = useState('');
  const setLocationForData = () => {
    setshowUpdateButton(true);
    setDataOfLocation({
      id: dataOfLocation.id,
      loc_name: dataOfLocation.loc_name,
      loc_no: dataOfLocation.loc_no,
      loc_photo: dataOfLocation.loc_photo,
      loc_code: dataOfLocation.loc_code,
      loc_search_code: dataOfLocation.loc_search_code,
      cam_brand: dataOfLocation.cam_brand,
      cam_ip: dataOfLocation.cam_ip,
      cam_rtsp: dataOfLocation.cam_rtsp,
      cam_username: dataOfLocation.cam_username,
      cam_password: dataOfLocation.cam_password,
      userid: dataOfLocation.userid,
      licence_key: dataOfLocation.licence_key,
      expirydate:dataOfLocation.expirydate,
      added_by:dataOfLocation.added_by,
      status:dataOfLocation.status,
      date_purchased: dataOfLocation.date_purchased,
      subscription: dataOfLocation.subscription,
      sub_id: dataOfLocation.sub_id,
      pin_length: dataOfLocation.pin_length,
      delivery_pin: dataOfLocation.delivery_pin,
      warning_phone: dataOfLocation.warning_phone,
      loc_property_name: dataOfLocation.loc_property_name,
      unlocking_mode: dataOfLocation.unlocking_mode,
      vegax_duration: dataOfLocation.vegax_duration,
      vegax_second_duration: dataOfLocation.vegax_second_duration,
      relay_one_fname: dataOfLocation.relay_one_fname,
      relay_two_fname: dataOfLocation.relay_two_fname,
      tablet_name: dataOfLocation.tablet_name,
      loc_office_name: dataOfLocation.loc_office_name,
      loc_add_one: dataOfLocation.loc_add_one,
      loc_add_two: dataOfLocation.loc_add_two,
      loc_telephone_dial: dataOfLocation.loc_telephone_dial,
      wel_message: dataOfLocation.wel_message,
      wel_message_header: dataOfLocation.wel_message_header,
      wel_message_headerlogo: dataOfLocation.wel_message_headerlogo,
      wel_message_headercolor : dataOfLocation.wel_message_headercolor,
      loc_url_watermark: dataOfLocation.loc_url_watermark,
      loc_refresh_rate: dataOfLocation.loc_refresh_rate,
      loc_screen_layout: dataOfLocation.loc_screen_layout,
      setting_reftesh_rate: dataOfLocation.setting_reftesh_rate,
      web_status: dataOfLocation.web_status,
      loc_zip_code: dataOfLocation.loc_zip_code,
      r_pin_off: dataOfLocation.r_pin_off,
      g_pin_off: dataOfLocation.g_pin_off,
      lkey_reset: dataOfLocation.lkey_reset,
      pin_blaacklist: dataOfLocation.pin_blaacklist,
      time_zone: dataOfLocation.time_zone,
      pin_start_time: dataOfLocation.pin_start_time,
      pin_end_time:dataOfLocation.pin_end_time,
      card_start_time: dataOfLocation.card_start_time,
      card_end_time:dataOfLocation.card_end_time,
      device_id: dataOfLocation.device_id,
      rebootday: dataOfLocation.rebootday,
      reboottime: dataOfLocation.reboottime,
      rfid_enable: dataOfLocation.rfid_enable,
      app_unlock_mode: dataOfLocation.app_unlock_mode,
      lpr_enable:dataOfLocation.lpr_enable,
      lpr_cam1: dataOfLocation.lpr_cam1,
      lpr_cam2: dataOfLocation.lpr_cam2,
      hold_open: dataOfLocation.hold_open,
      elevator_access: dataOfLocation.elevator_access,
      brivo_access: dataOfLocation.brivo_access,
      dis_off: dataOfLocation.dis_off,
      dis_dir: dataOfLocation.dis_dir,
      dis_doorpin: dataOfLocation.dis_doorpin,
      dis_del: dataOfLocation.dis_del,
      enable_exe_weigand: dataOfLocation.enable_exe_weigand,
      default_vega_state: dataOfLocation.default_vega_state,
      default_vega_state_2: dataOfLocation.default_vega_state_2,
      pdk_enable: dataOfLocation.pdk_enable,
      brivo_enable: dataOfLocation.brivo_enable,
      lpr_accesss: dataOfLocation.lpr_accesss,
      pdk_node: dataOfLocation.pdk_node,
      pdk_group: dataOfLocation.pdk_group,
      brivo_group_name: dataOfLocation.brivo_group_name,
      elevator_accesss_control: dataOfLocation.elevator_accesss_control,
      brivo_array:dataOfLocation.brivo_array,
      brivo_group_array:dataOfLocation.brivo_group_array,
      pdk_access:dataOfLocation.pdk_access,
      pdk_cloudid_array:dataOfLocation.pdk_cloudid_array,
      pdk_group_array:dataOfLocation.pdk_group_array,
      pdk_device_array:dataOfLocation.pdk_device_array,
      image_upload_data:dataOfLocation.image_upload_data,
      dis_prox:dataOfLocation.dis_prox,
      latitude:dataOfLocation.latitude,
      longitude:dataOfLocation.longitude,
      bridge:dataOfLocation.bridge,	
      camera:dataOfLocation.camera,
      brivoDoor:dataOfLocation.brivoDoor,
      realy_hostname:dataOfLocation.realy_hostname,
      last_abre:dataOfLocation.last_abre,
      relay_fail_over:dataOfLocation.relay_fail_over,
      issub:token.issub,
      subId:token.subid
      });
      setUploadLocationData({
        id: dataOfLocation.id,
        loc_name: dataOfLocation.loc_name,
        loc_no: dataOfLocation.loc_no,
        loc_photo: dataOfLocation.loc_photo,
        loc_code: dataOfLocation.loc_code,
        loc_search_code: dataOfLocation.loc_search_code,
        cam_brand: dataOfLocation.cam_brand,
        cam_ip: dataOfLocation.cam_ip,
        cam_rtsp: dataOfLocation.cam_rtsp,
        cam_username: dataOfLocation.cam_username,
        cam_password: dataOfLocation.cam_password,
        userid: dataOfLocation.userid,
        licence_key: dataOfLocation.licence_key,
        expirydate:dataOfLocation.expirydate,
        added_by:dataOfLocation.added_by,
        status:dataOfLocation.status,
        date_purchased: dataOfLocation.date_purchased,
        subscription: dataOfLocation.subscription,
        sub_id: dataOfLocation.sub_id,
        pin_length: dataOfLocation.pin_length,
        delivery_pin: dataOfLocation.delivery_pin,
        warning_phone: dataOfLocation.warning_phone,
        loc_property_name: dataOfLocation.loc_property_name,
        unlocking_mode: dataOfLocation.unlocking_mode,
        vegax_duration: dataOfLocation.vegax_duration,
        vegax_second_duration: dataOfLocation.vegax_second_duration,
        relay_one_fname: dataOfLocation.relay_one_fname,
        relay_two_fname: dataOfLocation.relay_two_fname,
        tablet_name: dataOfLocation.tablet_name,
        loc_office_name: dataOfLocation.loc_office_name,
        loc_add_one: dataOfLocation.loc_add_one,
        loc_add_two: dataOfLocation.loc_add_two,
        loc_telephone_dial: dataOfLocation.loc_telephone_dial,
        wel_message: dataOfLocation.wel_message,
        wel_message_header: dataOfLocation.wel_message_header,
        wel_message_headerlogo: dataOfLocation.wel_message_headerlogo,
        wel_message_headercolor : dataOfLocation.wel_message_headercolor,
        loc_url_watermark: dataOfLocation.loc_url_watermark,
        loc_refresh_rate: dataOfLocation.loc_refresh_rate,
        loc_screen_layout: dataOfLocation.loc_screen_layout,
        setting_reftesh_rate: dataOfLocation.setting_reftesh_rate,
        web_status: dataOfLocation.web_status,
        loc_zip_code: dataOfLocation.loc_zip_code,
        r_pin_off: dataOfLocation.r_pin_off,
        g_pin_off: dataOfLocation.g_pin_off,
        lkey_reset: dataOfLocation.lkey_reset,
        pin_blaacklist: dataOfLocation.pin_blaacklist,
        time_zone: dataOfLocation.time_zone,
        pin_start_time: dataOfLocation.pin_start_time,
        pin_end_time:dataOfLocation.pin_end_time,
        card_start_time: dataOfLocation.card_start_time,
       card_end_time:dataOfLocation.card_end_time,
        device_id: dataOfLocation.device_id,
        rebootday: dataOfLocation.rebootday,
        reboottime: dataOfLocation.reboottime,
        rfid_enable: dataOfLocation.rfid_enable,
        app_unlock_mode: dataOfLocation.app_unlock_mode,
        lpr_enable:dataOfLocation.lpr_enable,
        lpr_cam1: dataOfLocation.lpr_cam1,
        lpr_cam2: dataOfLocation.lpr_cam2,
        bridge:dataOfLocation.bridge,	
        camera:dataOfLocation.camera,
        hold_open: JSON.stringify(dataOfLocation.hold_open),
        elevator_access: JSON.stringify(dataOfLocation.elevator_access),
        brivo_access: JSON.stringify(dataOfLocation.brivo_access),
        pdk_access: JSON.stringify(dataOfLocation.pdk_access),
        dis_off: dataOfLocation.dis_off,
        dis_dir: dataOfLocation.dis_dir,
        dis_doorpin: dataOfLocation.dis_doorpin,
        dis_del: dataOfLocation.dis_del,
        enable_exe_weigand: dataOfLocation.enable_exe_weigand,
        default_vega_state: dataOfLocation.default_vega_state,
        default_vega_state_2: dataOfLocation.default_vega_state_2,
        pdk_enable: dataOfLocation.pdk_enable,
        brivo_enable: dataOfLocation.brivo_enable,
        lpr_accesss: dataOfLocation.lpr_accesss,
        pdk_node: dataOfLocation.pdk_node,
        pdk_group: dataOfLocation.pdk_group,
        brivo_group_name: dataOfLocation.brivo_group_name,
        elevator_accesss_control: dataOfLocation.elevator_accesss_control,
        image_upload_data:dataOfLocation.image_upload_data,
        dis_prox:dataOfLocation.dis_prox,
        latitude:dataOfLocation.latitude,
        longitude:dataOfLocation.longitude,
        realy_hostname:dataOfLocation.realy_hostname,
        brivoDoor:dataOfLocation.brivoDoor,
        last_abre:dataOfLocation.last_abre,
        relay_fail_over:dataOfLocation.relay_fail_over,
        issub:token.issub,
        subId:token.subid
        });
        setRecordsBridge(getBridgeData());	
        setRecordsCamera(getCameraData());	
        setAddBridge(getBridgeAddArray());	
        setAddCamera(getCameraAddArray());	
        setBrivoDoorArray(getBrivoDoorArray());
        setBrivoCustomArray(getBrivoCustomArray());
        setPdkNodeArray(getPdkNodeArray());
        setPdkGroupArray(getPdkGroupArray());
        setBrivoGroupArray(getBrivoGroupArray());
        setPdkDeviceArray(getPdkDeviceArray());
        setOptions(getOptions());
      
        setCurrentBridge("");	
        setCurrentCamera("");
        console.log("data successfully changed");
  }
  const CameraBrand = {
    id: "1",
    label: "ResidentVue",
    items: [
      {
        label: "AXIS",
        callback: () =>  {
          dataOfLocation.cam_brand = "AXIS";
          setLocationForData();
        }
      },
      {
        label: "ACTi",
        callback: () =>  {
          dataOfLocation.cam_brand = "ACTi";
          setLocationForData();
        }
      },
      {
        label: "Hikvision",
        callback: () =>  {
          dataOfLocation.cam_brand = "Hikvision";
          setLocationForData();
        }
      },
      {
        label: "Nortek",
        callback: () =>  {
          dataOfLocation.cam_brand = "Nortek";
          setLocationForData();
        }
      },
      {
        label: "Sunell",
        callback: () =>  {
          dataOfLocation.cam_brand = "Sunell";
          setLocationForData();
        }
      },
      {
        label: "ResidentVue",
        callback: () =>  {
          dataOfLocation.cam_brand = "ResidentLink";
          setLocationForData();
        }
      },
      {
        label:"QR Entry",
        callback: () =>  {
          dataOfLocation.cam_brand = "Virtual Panel";
          setLocationForData();
        }
      }
    ]
  }
  
  const GetValueForCameraBrand = () => {
    if(dataOfLocation.cam_brand == "ResidentLink"){
      return "ResidentVue";
    }
    else if(dataOfLocation.cam_brand == "Virtual Panel"){
      return "QR Entry";
    }
    else if(dataOfLocation.cam_brand == "Sunell"){
      return "Sunell";
    }
    else if(dataOfLocation.cam_brand == "AXIS"){
      return "AXIS";
    }
    else if(dataOfLocation.cam_brand == "Nortek"){
      return "Nortek";
    }
    else if(dataOfLocation.cam_brand == "Hikvision"){
      return "Hikvision";
    }else{
      return dataOfLocation.cam_brand;
    }
  }
  const handleChangeCameraBrand = (event) => {
    if(event.target.value == "ResidentVue") {
      dataOfLocation.cam_brand = "ResidentLink";
      setLocationForData();
    }else if(event.target.value == "QR Entry") {
      dataOfLocation.cam_brand = "Virtual Panel";
      setLocationForData();
    }
    else{
      dataOfLocation.cam_brand = event.target.value;
      setLocationForData();
    }
  }
  const handleInputChange=(event) =>{
    console.log(dataOfLocation);
   // setshowUpdateButton(true);
    let ds = event.target.name;
    dataOfLocation[ds] = event.target.value;
    setLocationForData();
  }
  const handlecheckboxCheck=(event) =>{
    let ds = event.target.name;
    dataOfLocation[ds] = event.target.checked == true ? "1" : "0";
   // setshowUpdateButton(true);
   setLocationForData();
  }

  const handleBrivoInCallRelayTriggerCheck = (event) => {
    let ds = event.target.checked == true ? "1" : "0";
    let temparr = dataOfLocation.brivo_access;
    if(temparr.icrs == null){
      dataOfLocation.brivo_access = {brivoprimeid:temparr.brivoprimeid,brivoprimename:temparr.brivoprimename,brivofloors:temparr.brivofloors,icrt:ds,icrs:[{name:"",id:""},{name:"",id:""},{name:"",id:""}],program:temparr.program};
 
    }else{
      dataOfLocation.brivo_access = {brivoprimeid:temparr.brivoprimeid,brivoprimename:temparr.brivoprimename,brivofloors:temparr.brivofloors,icrt:ds,icrs:temparr.icrs,program:temparr.program};
 
    }
    setLocationForData();
    }
  const handlecheckboxCheckReverse=(event) =>{
    let ds = event.target.name;
    dataOfLocation[ds] = event.target.checked == true ? "0" : "1";
   // setshowUpdateButton(true);
   setLocationForData();
  }
  const handleRebootDevice = (event) => {
    setLoading(true);
    performEsperOperation('reboot');
  }

  const handlePingDevice = (event) => {
    setLoading(true);
    performEsperOperation('ping');
  }
  const handleRestartDevice = (event) => {
    setLoading(true);
    performHexOperation('restart');
  }
  const performEsperOperation = async (val) => {
    try {
      const res = await axios.patch("/admin/esper/"+val+"/"+idOflocation.id,uploadLocationData, {headers: { 'Content-Type':  'application/x-www-form-urlencoded; charset=UTF-8',
      'token' : token.token,
      'tokenid' : token.email }});
      console.log( res.data);
      if(val == 'reboot'){
        setOpenSnackData("Reboot attempted: If the device is without network connection or power this reboot attempt will not be effective, and may need to be rebooted on-site.");
      }else{
      //setOpenSnackData("Device Operation Successfully Completed.");
      setOpenSnackData("Ping attempted: If the device is without network connection or power this ping attempt will not be effective, and may need to be pinged on-site.");
      }
      setSeverityData("success");
      setOpenSnack(true);
      setLoading(false);
      setshowUpdateButton(false);
    } catch (error) {
      console.log(error.response.data);
      setLoading(false);
      setOpenSnackData("Device Operation Failed, Please Try Again Later");
      setSeverityData("error");
      setOpenSnack(true);
      if(error.response.data.message == 'UnAuthorized'){
        RefreshToken();
			}
    }
  }
  const performHexOperation = async (val) => {
    try {
      const res = await axios.patch("/admin/hex/"+val+"/"+idOflocation.id,uploadLocationData, {headers: { 'Content-Type':  'application/x-www-form-urlencoded; charset=UTF-8',
      'token' : token.token,
      'tokenid' : token.email }});
      console.log( res.data);
      setOpenSnackData("Device Operation Successfully Completed.");
      setSeverityData("success");
      setOpenSnack(true);
      setLoading(false);
      setshowUpdateButton(false);
    } catch (error) {
      console.log(error.response.data);
      setLoading(false);
      setOpenSnackData("Device Operation Failed, Please Try Again Later");
      setSeverityData("error");
      setOpenSnack(true);
      if(error.response.data.message == 'UnAuthorized'){
        RefreshToken();
			}
    }
  }
  const updateMyPostData = async () => {
    try {
      const res = await axios.patch("/admin/location/"+token.id+"/"+uploadLocationData.id,uploadLocationData, {headers: { 'Content-Type':  'application/x-www-form-urlencoded; charset=UTF-8' ,
      'token' : token.token,
      'tokenid' : token.email}});
      console.log( res.data);
      setOpenSnackData(getAlertTitle('ELLUS'));
      setSeverityData("success");
      setOpenSnack(true);
      setLoading(false);
      setshowUpdateButton(false);
    } catch (error) {
      console.log(error.response.data);
      setLoading(false);
      setOpenSnackData(getAlertTitle('ELLUF'));
      setSeverityData("error");
      setOpenSnack(true);
      if(error.response.data.message == 'UnAuthorized'){
        RefreshToken();
      }
    }
  };
  const handleUpdateLocation = () => {
    console.log(uploadLocationData);
    setLoading(true);
    if(dataOfLocation.loc_name == ""){
      setOpenSnackData(getAlertTitle('ELLNNE'));
      setSeverityData("error");
      setOpenSnack(true);
      setLoading(false);
      return;
    }
    if(dataOfLocation.cam_brand != "ResidentLink" && dataOfLocation.cam_brand != "Virtual Panel"){
    if(dataOfLocation.loc_code == ""){
      setOpenSnackData(getAlertTitle('ELLDNE'));
      setSeverityData("error");
      setOpenSnack(true);
      setLoading(false);
      return;
    }
    
    if(dataOfLocation.cam_ip == ""){
      setOpenSnackData(getAlertTitle('ELLCINE'));
      setSeverityData("error");
      setOpenSnack(true);
      setLoading(false);
      return;
    }
  
  
  }
  else {
    //console.log((dataOfLocation.delivery_pin).length + " is " );
    let delivery_pin = dataOfLocation.delivery_pin;
  delivery_pin = delivery_pin.replaceAll(" ","");
  if(delivery_pin == ""){
    console.log("delivery pin can't be null");
    setOpenSnackData(getAlertTitle('ALLDPNE'));
    setSeverityData("error");
    setOpenSnack(true);
    setLoading(false);
    return;
  }
    if((dataOfLocation.delivery_pin).length != parseInt(dataOfLocation.pin_length)){
      console.log('dataOfLocation.delivery_pin');
      if(parseInt(dataOfLocation.pin_length) == 6){
        setOpenSnackData(getAlertTitle('ELLDP6NV'));
        setSeverityData("error");
        setOpenSnack(true);
        setLoading(false);
        return;
      }else{
        setOpenSnackData(getAlertTitle('ELLDP4NV'));
        setSeverityData("error");
        setOpenSnack(true);
        setLoading(false);
        return;
      }
      
    }
    if(dataOfLocation.lpr_enable == "1"){	
      if(recordsCamera.length > 0 && recordsBridge.length <= 0){	
        setOpenSnackData('Bridge Is Required To Add Camera, Please Add Bridge And Try Again.');	
        setSeverityData("error");	
        setOpenSnack(true);	
        setLoading(false);	
        return;	
      }	
    }
    if(dataOfLocation.cam_brand == "Virtual Panel" && dataOfLocation.unlocking_mode != '1'  && dataOfLocation.unlocking_mode != '4'){
      let urls = dataOfLocation.realy_hostname;
      if(!urls.match("api.controlbyweb.cloud/DAT/")){
        setOpenSnackData('Relay Host Url Should Be Like This api.controlbyweb.cloud/DAT/api-key, Other Url Is Not Valid.');
        setSeverityData("error");
        setOpenSnack(true);
        setLoading(false);
        return;
      }
    }
  }
    updateMyPostData();
  }
  const MenuProps = {
    PaperProps: {
      style: {
        backgroundColor: "#1E1E1E",
          borderRadius: 0,
          maxHeight:'250px',
          borderRadius: 10,
          padding:0
      },
    },
  };
  const CamType = {	
    id: "1",	
    label: "Entry",	
    items: [	
      {	
        label: "Entry",	
        callback: () =>  {}	
      },	
      {	
        label: "Exit",	
        callback: () =>  {}	
      },	
    ]	
  }	
  const BriType = {	
    id: "1",	
    label: "Dedicated",	
    items: [	
      {	
        label: "Dedicated",	
        callback: () =>  {}	
      },	
      {	
        label: "Shared",	
        callback: () =>  {}	
      },	
    ]	
  }
  const BootDay = {
    id: "1",
    label: "Monday",
    items: [
      {
        label: "None",
        callback: () =>  {}
      },
      {
        label: "Monday",
        callback: () =>  {}
      },
      {
        label: "Tuesday",
        callback: () =>  {}
      },
      {
        label: "Wednesday",
        callback: () =>  {}
      },
      {
        label: "Thursday",
        callback: () =>  {}
      },
      {
        label: "Friday",
        callback: () =>  {}
      },
      {
        label: "Saturday",
        callback: () =>  {}
      },
      {
        label: "Sunday",
        callback: () =>  {}
      }
    ]
  }
  BootDay.label = dataOfLocation.rebootday;

  const BootTime = {
    id: "1",
    label: "00:00",
    items: [
      {
        label: "None",
        callback: () =>  {}
      },
      {
        label: "12:00 AM",
        callback: () =>  {}
      },
      {
        label: "12:30 AM",
        callback: () =>  {}
      },
      {
        label:  "01:00 AM",
        callback: () =>  {}
      },
      {
        label:  "01:30 AM",
        callback: () =>  {}
      },
      {
        label: "02:00 AM",
        callback: () =>  {}
      },
      {
        label: "02:30 AM",
        callback: () =>  {}
      },
      {
        label: "03:00 AM",
        callback: () =>  {}
      },
      {
        label: "03:30 AM",
        callback: () =>  {}
      },
      {
        label: "04:00 AM",
        callback: () =>  {}
      },
      {
        label: "04:30 AM",
        callback: () =>  {}
      },
      {
        label: "05:00 AM",
        callback: () =>  {}
      },
      {
        label: "05:30 AM",
        callback: () =>  {}
      },
      {
        label:  "06:00 AM",
        callback: () =>  {}
      },
      {
        label: "06:30 AM",
        callback: () =>  {}
      },
      {
        label: "07:00 AM",
        callback: () =>  {}
      },
      {
        label: "07:30 AM",
        callback: () =>  {}
      },
      {
        label: "08:00 AM",
        callback: () =>  {}
      },
      {
        label: "08:30 AM",
        callback: () =>  {}
      },
      {
        label: "09:00 AM",
        callback: () =>  {}
      },
      {
        label: "09:30 AM",
        callback: () =>  {}
      },
      {
        label: "10:00 AM",
        callback: () =>  {}
      },
      {
        label: "10:30 AM",
        callback: () =>  {}
      },
      {
        label: "11:00 AM",
        callback: () =>  {}
      },
      {
        label: "11:30 AM",
        callback: () =>  {}
      },
      {
        label: "12:00 PM",
        callback: () =>  {}
      },
      {
        label: "12:30 PM",
        callback: () =>  {}
      },
      {
        label: "01:00 PM",
        callback: () =>  {}
      },
      {
        label: "01:30 PM",
        callback: () =>  {}
      },
      {
        label: "02:00 PM",
        callback: () =>  {}
      },
      {
        label: "02:30 PM",
        callback: () =>  {}
      },
      {
        label: "03:00 PM",
        callback: () =>  {}
      },
      {
        label: "03:30 PM",
        callback: () =>  {}
      },
      {
        label: "04:00 PM",
        callback: () =>  {}
      },
      {
        label: "04:30 PM",
        callback: () =>  {}
      },
      {
        label: "05:00 PM",
        callback: () =>  {}
      },
      {
        label: "05:30 PM",
        callback: () =>  {}
      },
      {
        label: "06:00 PM",
        callback: () =>  {}
      },
      {
        label: "06:30 PM",
        callback: () =>  {}
      },
      {
        label: "07:00 PM",
        callback: () =>  {}
      },
      {
        label: "07:30 PM",
        callback: () =>  {}
      },
      {
        label: "08:00 PM",
        callback: () =>  {}
      },
      {
        label: "08:30 PM",
        callback: () =>  {}
      },
      {
        label: "09:00 PM",
        callback: () =>  {}
      },
      {
        label: "09:30 PM",
        callback: () =>  {}
      },
      {
        label: "10:00 PM",
        callback: () =>  {}
      },
      {
        label: "10:30 PM",
        callback: () =>  {}
      },
      {
        label: "11:00 PM",
        callback: () =>  {}
      },
      {
        label: "11:30 PM",
        callback: () =>  {}
      },
      {
        label: "11:59 PM",
        callback: () =>  {}
      }
    ]
  }
  BootTime.label = dataOfLocation.reboottime;
 
  const PinStartBootTime = {
    id: "1",
    label: "00:00",
    items: [
      {
        label: "None",
        callback: () =>  {}
      },
      {
        label: "12:00 AM",
        callback: () =>  {}
      },
      {
        label: "12:30 AM",
        callback: () =>  {}
      },
      {
        label:  "01:00 AM",
        callback: () =>  {}
      },
      {
        label:  "01:30 AM",
        callback: () =>  {}
      },
      {
        label: "02:00 AM",
        callback: () =>  {}
      },
      {
        label: "02:30 AM",
        callback: () =>  {}
      },
      {
        label: "03:00 AM",
        callback: () =>  {}
      },
      {
        label: "03:30 AM",
        callback: () =>  {}
      },
      {
        label: "04:00 AM",
        callback: () =>  {}
      },
      {
        label: "04:30 AM",
        callback: () =>  {}
      },
      {
        label: "05:00 AM",
        callback: () =>  {}
      },
      {
        label: "05:30 AM",
        callback: () =>  {}
      },
      {
        label:  "06:00 AM",
        callback: () =>  {}
      },
      {
        label: "06:30 AM",
        callback: () =>  {}
      },
      {
        label: "07:00 AM",
        callback: () =>  {}
      },
      {
        label: "07:30 AM",
        callback: () =>  {}
      },
      {
        label: "08:00 AM",
        callback: () =>  {}
      },
      {
        label: "08:30 AM",
        callback: () =>  {}
      },
      {
        label: "09:00 AM",
        callback: () =>  {}
      },
      {
        label: "09:30 AM",
        callback: () =>  {}
      },
      {
        label: "10:00 AM",
        callback: () =>  {}
      },
      {
        label: "10:30 AM",
        callback: () =>  {}
      },
      {
        label: "11:00 AM",
        callback: () =>  {}
      },
      {
        label: "11:30 AM",
        callback: () =>  {}
      },
      {
        label: "12:00 PM",
        callback: () =>  {}
      },
      {
        label: "12:30 PM",
        callback: () =>  {}
      },
      {
        label: "01:00 PM",
        callback: () =>  {}
      },
      {
        label: "01:30 PM",
        callback: () =>  {}
      },
      {
        label: "02:00 PM",
        callback: () =>  {}
      },
      {
        label: "02:30 PM",
        callback: () =>  {}
      },
      {
        label: "03:00 PM",
        callback: () =>  {}
      },
      {
        label: "03:30 PM",
        callback: () =>  {}
      },
      {
        label: "04:00 PM",
        callback: () =>  {}
      },
      {
        label: "04:30 PM",
        callback: () =>  {}
      },
      {
        label: "05:00 PM",
        callback: () =>  {}
      },
      {
        label: "05:30 PM",
        callback: () =>  {}
      },
      {
        label: "06:00 PM",
        callback: () =>  {}
      },
      {
        label: "06:30 PM",
        callback: () =>  {}
      },
      {
        label: "07:00 PM",
        callback: () =>  {}
      },
      {
        label: "07:30 PM",
        callback: () =>  {}
      },
      {
        label: "08:00 PM",
        callback: () =>  {}
      },
      {
        label: "08:30 PM",
        callback: () =>  {}
      },
      {
        label: "09:00 PM",
        callback: () =>  {}
      },
      {
        label: "09:30 PM",
        callback: () =>  {}
      },
      {
        label: "10:00 PM",
        callback: () =>  {}
      },
      {
        label: "10:30 PM",
        callback: () =>  {}
      },
      {
        label: "11:00 PM",
        callback: () =>  {}
      },
      {
        label: "11:30 PM",
        callback: () =>  {}
      },
      {
        label: "11:59 PM",
        callback: () =>  {}
      }
    ]
  }
  PinStartBootTime.label = dataOfLocation.pin_start_time;
  const PinEndBootTime = {
    id: "1",
    label: "00:00",
    items: [
      {
        label: "None",
        callback: () =>  {}
      },
      {
        label: "12:00 AM",
        callback: () =>  {}
      },
      {
        label: "12:30 AM",
        callback: () =>  {}
      },
      {
        label:  "01:00 AM",
        callback: () =>  {}
      },
      {
        label:  "01:30 AM",
        callback: () =>  {}
      },
      {
        label: "02:00 AM",
        callback: () =>  {}
      },
      {
        label: "02:30 AM",
        callback: () =>  {}
      },
      {
        label: "03:00 AM",
        callback: () =>  {}
      },
      {
        label: "03:30 AM",
        callback: () =>  {}
      },
      {
        label: "04:00 AM",
        callback: () =>  {}
      },
      {
        label: "04:30 AM",
        callback: () =>  {}
      },
      {
        label: "05:00 AM",
        callback: () =>  {}
      },
      {
        label: "05:30 AM",
        callback: () =>  {}
      },
      {
        label:  "06:00 AM",
        callback: () =>  {}
      },
      {
        label: "06:30 AM",
        callback: () =>  {}
      },
      {
        label: "07:00 AM",
        callback: () =>  {}
      },
      {
        label: "07:30 AM",
        callback: () =>  {}
      },
      {
        label: "08:00 AM",
        callback: () =>  {}
      },
      {
        label: "08:30 AM",
        callback: () =>  {}
      },
      {
        label: "09:00 AM",
        callback: () =>  {}
      },
      {
        label: "09:30 AM",
        callback: () =>  {}
      },
      {
        label: "10:00 AM",
        callback: () =>  {}
      },
      {
        label: "10:30 AM",
        callback: () =>  {}
      },
      {
        label: "11:00 AM",
        callback: () =>  {}
      },
      {
        label: "11:30 AM",
        callback: () =>  {}
      },
      {
        label: "12:00 PM",
        callback: () =>  {}
      },
      {
        label: "12:30 PM",
        callback: () =>  {}
      },
      {
        label: "01:00 PM",
        callback: () =>  {}
      },
      {
        label: "01:30 PM",
        callback: () =>  {}
      },
      {
        label: "02:00 PM",
        callback: () =>  {}
      },
      {
        label: "02:30 PM",
        callback: () =>  {}
      },
      {
        label: "03:00 PM",
        callback: () =>  {}
      },
      {
        label: "03:30 PM",
        callback: () =>  {}
      },
      {
        label: "04:00 PM",
        callback: () =>  {}
      },
      {
        label: "04:30 PM",
        callback: () =>  {}
      },
      {
        label: "05:00 PM",
        callback: () =>  {}
      },
      {
        label: "05:30 PM",
        callback: () =>  {}
      },
      {
        label: "06:00 PM",
        callback: () =>  {}
      },
      {
        label: "06:30 PM",
        callback: () =>  {}
      },
      {
        label: "07:00 PM",
        callback: () =>  {}
      },
      {
        label: "07:30 PM",
        callback: () =>  {}
      },
      {
        label: "08:00 PM",
        callback: () =>  {}
      },
      {
        label: "08:30 PM",
        callback: () =>  {}
      },
      {
        label: "09:00 PM",
        callback: () =>  {}
      },
      {
        label: "09:30 PM",
        callback: () =>  {}
      },
      {
        label: "10:00 PM",
        callback: () =>  {}
      },
      {
        label: "10:30 PM",
        callback: () =>  {}
      },
      {
        label: "11:00 PM",
        callback: () =>  {}
      },
      {
        label: "11:30 PM",
        callback: () =>  {}
      },
      {
        label: "11:59 PM",
        callback: () =>  {}
      }
    ]
  }
  PinEndBootTime.label = dataOfLocation.pin_end_time;
  const [age, setAge] = React.useState('');

  const headCellsBrige = [	
    { id: 'name', label: 'Friendly Bridge Name' },	
    { id: 'bridge_id', label: 'Bridge Type'},	
    { id: 'action', label: 'Action', disableSorting: true },	
  ];	
  const headCellsCamera = [	
    { id: 'name', label: 'Friendly Camera Name' },	
    { id: 'camera_id', label: 'Camera Type'},	
    { id: 'action', label: 'Action', disableSorting: true },	
  ];	
  const [filterFnBridge, setFilterFnBridge] = useState({ fn: items => { return items; } });	
  const [filterFnCamera, setFilterFnCamera] = useState({ fn: items => { return items; } });	
  const {	
    TblContainer : TblContainerBridge,	
    TblHead :  TblHeadBridge,	
    TblPagination : TblPaginationBridge,	
    BelowTblPagination : BelowTblPaginationBridge,	
    recordsAfterPagingAndSorting : recordsAfterPagingAndSortingBridge,	
    setPageToNewOne : setPageToNewOneBridge	
  } = useInnerTable(recordsBridge, headCellsBrige, filterFnBridge,5,350);	
  const {	
    TblContainer : TblContainerCamera,	
    TblHead :  TblHeadCamera,	
    TblPagination : TblPaginationCamera,	
    BelowTblPagination : BelowTblPaginationCamera,	
    recordsAfterPagingAndSorting : recordsAfterPagingAndSortingCamera,	
    setPageToNewOne : setPageToNewOneCamera	
  } = useInnerTable(recordsCamera, headCellsCamera, filterFnCamera,5,350);

  const handleChangeBoot = (event) => {
    let ds = event.target.name;
    dataOfLocation[ds] = event.target.value;
    setLocationForData();
  }

  const handleChangeBridge = (event) => {	
    setCurrentBridge( event.target.value);	
    let idOdunit ;	
    dataOfLocation.bridge.forEach(element => {	
      if(element.name == event.target.value){	
        idOdunit = element.id;	
      }	
    });	
    setCurrentBridgeId(idOdunit);	
  }	
  const handleChangeCameraType = (event) => {	
    setCurrentCameraType( event.target.value);	
  }	
  const handleChangeBridgeType = (event) => {	
    setCurrentBridgeType( event.target.value);	
  }	
  const handleAddBridge = () => {	
    if(currentBridge == ""){	
      setOpenSnackData("Please Select Bridge And Try Again.");	
      setSeverityData("error");	
      setOpenSnack(true);	
    }	
    else if(currentBridgeType == ""){	
      setOpenSnackData("Please Select Bridge Type And Try Again.");	
     setSeverityData("error");	
     setOpenSnack(true);	
    }	
    console.log("add before called" +currentBridge);	
    for(let i = 0; i < (dataOfLocation.bridge).length ; i++){	
      if((dataOfLocation.bridge)[i].id == currentBridgeId){	
        (dataOfLocation.bridge)[i].location_id = dataOfLocation.loc_search_code;	
        console.log("dataOfLocation.bridge "+(dataOfLocation.bridge)[i].location_id);	
        if((dataOfLocation.bridge)[i].type  != "1")	
        (dataOfLocation.bridge)[i].type = currentBridgeType == "Dedicated" ? "0" : "1";	
        console.log("dataOfLocation.type "+(dataOfLocation.bridge)[i].type);	
      }	
    }	
    console.log("add called");	
    setLocationForData();	
  }	
  const handleAddCamera = () => {	
    if(currentCamera == ""){	
      setOpenSnackData("Please Select Camera And Try Again.");	
      setSeverityData("error");	
      setOpenSnack(true);	
    }	
    else if(currentCameraType == ""){	
      setOpenSnackData("Please Select Camera Type And Try Again.");	
     setSeverityData("error");	
     setOpenSnack(true);	
    }	
    console.log("add before called" +currentBridge);	
    for(let i = 0; i < (dataOfLocation.camera).length ; i++){	
      if((dataOfLocation.camera)[i].id == currentCameraId){	
        (dataOfLocation.camera)[i].location_id = dataOfLocation.loc_search_code;	
        console.log("dataOfLocation.bridge "+(dataOfLocation.camera)[i].location_id);	
        (dataOfLocation.camera)[i].type = currentCameraType == "Entry" ? "0" : "1";	
        console.log("dataOfLocation.type "+(dataOfLocation.camera)[i].type);	
      }	
    }	
    console.log("add called");	
    setLocationForData();	
  }	
  	
  const handleChangeCamera = (event) => {	
    setCurrentCamera( event.target.value);	
    let idOdunit ;	
    dataOfLocation.camera.forEach(element => {	
      if(element.name == event.target.value){	
        idOdunit = element.id;	
      }	
    });	
    setCurrentCameraId(idOdunit);	
  }
  return <> 
  {showUpdateButton && <> <Box sx={{
           display: { xs: "none", md: "flex" }
           }}><LoadingButton
         
         sx={mainThemeStyle.activityButton}
         loading={loading}
         onClick={handleUpdateLocation}
         variant="outlined"
         startIcon={<SaveIcon />}
         loadingPosition="start"
       >
         Save Device Details
       </LoadingButton>
       </Box>
       <Box sx={{
           display: { xs: "flex", md: "none" }
           }}><LoadingButton
         
         sx={{ position: 'absolute',
         width: 'auto',
         height: '2.06rem',
         left: '2.375rem',
         fontWeight: '700',
         fontSize: '0.9375rem',
         lineHeight: '1.1rem',
         display: 'flex',
         top: '1.75rem',
         alignItems: 'center',
         color: '#FFFFFF',
         fontFamily: "Lato",
         borderRadius:'0.31rem',
        '&:hover': {
         backgroundColor: 'rgba(109, 195, 252, 1)',
   }}}
         loading={loading}
         onClick={handleUpdateLocation}
         variant="outlined"
         startIcon={<SaveIcon />}
         loadingPosition="start"
       >
         Save Device Details
       </LoadingButton>
       </Box>
       </>
    }
  <Box sx={mainThemeStyle.boxTopHeader}>
  
     {(currentTab == 0 && <Stack spacing={2} direction="row">
    <Typography></Typography>
    <Button sx={mainThemeStyle.buttonHeaderGroup} onClick={()=> {handleTabClick(0)}} active>General</Button>
    {dataOfLocation.cam_brand == "Virtual Panel" && 
    <Button sx={mainThemeStyle.buttonHeaderGroupInactive} onClick={()=> {handleTabClick(1)}}>QR Entry</Button>
    }
    {dataOfLocation.cam_brand == "Virtual Panel" && 
    <Button sx={mainThemeStyle.buttonHeaderGroupInactive} onClick={()=> {handleTabClick(2)}}>Relay Settings</Button>
    }
     {/* {dataOfLocation.cam_brand == "Virtual Panel" && 
    <Button sx={mainThemeStyle.buttonHeaderGroupInactive} onClick={()=> {handleTabClick(3)}}>Hold Open</Button>
    } */}
     {dataOfLocation.cam_brand == "Virtual Panel" &&  dataOfLocation.lpr_accesss == '1' &&	
    <Button sx={mainThemeStyle.buttonHeaderGroupInactive} onClick={()=> {handleTabClick(4)}}>LPR</Button>	
    }
    {dataOfLocation.cam_brand == "Virtual Panel" &&  token.issub === true && token.name === 'SUPER ADMIN'&&
    <Button sx={mainThemeStyle.buttonHeaderGroupInactive} onClick={()=> {handleTabClick(5)}}>QR Code</Button>
    } 
    {dataOfLocation.cam_brand == "ResidentLink" && 
    <Button sx={mainThemeStyle.buttonHeaderGroupInactive} onClick={()=> {handleTabClick(1)}}>Panel Settings</Button>
    }
    {dataOfLocation.cam_brand == "ResidentLink" && 
    <Button sx={mainThemeStyle.buttonHeaderGroupInactive} onClick={()=> {handleTabClick(2)}}>Relay Settings</Button>
    }
    {dataOfLocation.cam_brand == "ResidentLink" &&  dataOfLocation.lpr_accesss == '1' &&
    <Button sx={mainThemeStyle.buttonHeaderGroupInactive} onClick={()=> {handleTabClick(3)}}>LPR</Button>
    }
    {dataOfLocation.cam_brand == "ResidentLink" && dataOfLocation.unlocking_mode == '2' &&
    <Button sx={mainThemeStyle.buttonHeaderGroupInactive} onClick={()=> {handleTabClick(4)}}>Hold Open</Button>
    }
    {dataOfLocation.cam_brand == "ResidentLink" && dataOfLocation.unlocking_mode == '2' &&
    <Button sx={mainThemeStyle.buttonHeaderGroupInactive} onClick={()=> {handleTabClick(5)}}>Elevator Control</Button>
    }
    {dataOfLocation.cam_brand == "ResidentLink" && dataOfLocation.unlocking_mode == '3' &&
    <Button sx={mainThemeStyle.buttonHeaderGroupInactive} onClick={()=> {handleTabClick(6)}}>Brivo Control</Button>
    }
    {dataOfLocation.cam_brand == "ResidentLink"  &&
    <Button sx={mainThemeStyle.buttonHeaderGroupInactive} onClick={()=> {handleTabClick(7)}}>Panel Status</Button>
    }
  </Stack>)}
  {(currentTab == 1 &&  dataOfLocation.cam_brand == "Virtual Panel" && <Stack spacing={2} direction="row">
    <Typography></Typography>
    <Button sx={mainThemeStyle.buttonHeaderGroupInactive} onClick={()=> {handleTabClick(0)}}>General</Button>
    <Button sx={mainThemeStyle.buttonHeaderGroup} onClick={()=> {handleTabClick(1)}}>QR Entry</Button>
    <Button sx={mainThemeStyle.buttonHeaderGroupInactive} onClick={()=> {handleTabClick(2)}}>Relay Settings</Button>
   {/*  <Button sx={mainThemeStyle.buttonHeaderGroupInactive} onClick={()=> {handleTabClick(3)}}>Hold Open</Button> */}
    { dataOfLocation.lpr_accesss == '1' &&	
    <Button sx={mainThemeStyle.buttonHeaderGroupInactive} onClick={()=> {handleTabClick(4)}}>LPR</Button>}
     {token.issub === true && token.name === 'SUPER ADMIN' && <Button sx={mainThemeStyle.buttonHeaderGroupInactive} onClick={()=> {handleTabClick(5)}}>QR Code</Button> 
}
  </Stack>)}
  {(currentTab == 2 &&  dataOfLocation.cam_brand == "Virtual Panel" && <Stack spacing={2} direction="row">
    <Typography></Typography>
    <Button sx={mainThemeStyle.buttonHeaderGroupInactive} onClick={()=> {handleTabClick(0)}}>General</Button>
    <Button sx={mainThemeStyle.buttonHeaderGroupInactive} onClick={()=> {handleTabClick(1)}}>QR Entry</Button>
    <Button sx={mainThemeStyle.buttonHeaderGroup} onClick={()=> {handleTabClick(2)}}>Relay Settings</Button>
    {/* <Button sx={mainThemeStyle.buttonHeaderGroupInactive} onClick={()=> {handleTabClick(3)}}>Hold Open</Button> */}
    { dataOfLocation.lpr_accesss == '1' &&	
    <Button sx={mainThemeStyle.buttonHeaderGroupInactive} onClick={()=> {handleTabClick(4)}}>LPR</Button>}
   {token.issub === true && token.name === 'SUPER ADMIN'&&  <Button sx={mainThemeStyle.buttonHeaderGroupInactive} onClick={()=> {handleTabClick(5)}}>QR Code</Button>
}
   
  </Stack>)}
   {(currentTab == 3 &&  dataOfLocation.cam_brand == "Virtual Panel" && <Stack spacing={2} direction="row">
    <Typography></Typography>
    <Button sx={mainThemeStyle.buttonHeaderGroupInactive} onClick={()=> {handleTabClick(0)}}>General</Button>
    <Button sx={mainThemeStyle.buttonHeaderGroupInactive} onClick={()=> {handleTabClick(1)}}>QR Entry</Button>
    <Button sx={mainThemeStyle.buttonHeaderGroupInactive} onClick={()=> {handleTabClick(2)}}>Relay Settings</Button>
    {/* <Button sx={mainThemeStyle.buttonHeaderGroup} onClick={()=> {handleTabClick(3)}}>Hold Open</Button> */}
    { dataOfLocation.lpr_accesss == '1' &&	
    <Button sx={mainThemeStyle.buttonHeaderGroupInactive} onClick={()=> {handleTabClick(4)}}>LPR</Button>}
    {token.issub === true && token.name === 'SUPER ADMIN'&& <Button sx={mainThemeStyle.buttonHeaderGroupInactive} onClick={()=> {handleTabClick(5)}}>QR Code</Button>
}
  </Stack>)} 
  {(currentTab == 4 &&  dataOfLocation.cam_brand == "Virtual Panel" && <Stack spacing={2} direction="row">
    <Typography></Typography>
    <Button sx={mainThemeStyle.buttonHeaderGroupInactive} onClick={()=> {handleTabClick(0)}}>General</Button>
    <Button sx={mainThemeStyle.buttonHeaderGroupInactive} onClick={()=> {handleTabClick(1)}}>QR Entry</Button>
    <Button sx={mainThemeStyle.buttonHeaderGroupInactive} onClick={()=> {handleTabClick(2)}}>Relay Settings</Button>
   {/*  <Button sx={mainThemeStyle.buttonHeaderGroupInactive} onClick={()=> {handleTabClick(3)}}>Hold Open</Button> */}
    { dataOfLocation.lpr_accesss == '1' &&	
    <Button sx={mainThemeStyle.buttonHeaderGroup} onClick={()=> {handleTabClick(4)}}>LPR</Button>}
    {token.issub === true && token.name === 'SUPER ADMIN'&&<Button sx={mainThemeStyle.buttonHeaderGroupInactive} onClick={()=> {handleTabClick(5)}}>QR Code</Button>
}
  </Stack>)} 
  {(currentTab == 5 &&  dataOfLocation.cam_brand == "Virtual Panel" && <Stack spacing={2} direction="row">
    <Typography></Typography>
    <Button sx={mainThemeStyle.buttonHeaderGroupInactive} onClick={()=> {handleTabClick(0)}}>General</Button>
    <Button sx={mainThemeStyle.buttonHeaderGroupInactive} onClick={()=> {handleTabClick(1)}}>QR Entry</Button>
    <Button sx={mainThemeStyle.buttonHeaderGroupInactive} onClick={()=> {handleTabClick(2)}}>Relay Settings</Button>
    {/* <Button sx={mainThemeStyle.buttonHeaderGroupInactive} onClick={()=> {handleTabClick(3)}}>Hold Open</Button> */}
    { dataOfLocation.lpr_accesss == '1' &&	
    <Button sx={mainThemeStyle.buttonHeaderGroupInactive} onClick={()=> {handleTabClick(4)}}>LPR</Button>}
   {token.issub === true && token.name === 'SUPER ADMIN'&& <Button sx={mainThemeStyle.buttonHeaderGroup} onClick={()=> {handleTabClick(5)}}>QR Code</Button>
}
  </Stack>)} 
  {(currentTab == 1 &&  dataOfLocation.cam_brand == "ResidentLink" && <Stack spacing={2} direction="row">
    <Typography></Typography>
    <Button sx={mainThemeStyle.buttonHeaderGroupInactive} onClick={()=> {handleTabClick(0)}}>General</Button>
    <Button sx={mainThemeStyle.buttonHeaderGroup} onClick={()=> {handleTabClick(1)}}>Panel Settings</Button>
    <Button sx={mainThemeStyle.buttonHeaderGroupInactive} onClick={()=> {handleTabClick(2)}}>Relay Settings</Button>
    { dataOfLocation.lpr_accesss == '1' &&
    <Button sx={mainThemeStyle.buttonHeaderGroupInactive} onClick={()=> {handleTabClick(3)}}>LPR</Button>}
    {dataOfLocation.unlocking_mode == '2' &&
    <Button sx={mainThemeStyle.buttonHeaderGroupInactive} onClick={()=> {handleTabClick(4)}}>Hold Open</Button>}
    {dataOfLocation.unlocking_mode == '2' &&
    <Button sx={mainThemeStyle.buttonHeaderGroupInactive} onClick={()=> {handleTabClick(5)}}>Elevator Control</Button>}
    {dataOfLocation.unlocking_mode == '3' &&
    <Button sx={mainThemeStyle.buttonHeaderGroupInactive} onClick={()=> {handleTabClick(6)}}>Brivo Control</Button>}
    <Button sx={mainThemeStyle.buttonHeaderGroupInactive} onClick={()=> {handleTabClick(7)}}>Panel Status</Button>
 
  </Stack>)}
  {(currentTab == 2 &&  dataOfLocation.cam_brand == "ResidentLink" && <Stack spacing={2} direction="row">
    <Typography></Typography>
    <Button sx={mainThemeStyle.buttonHeaderGroupInactive} onClick={()=> {handleTabClick(0)}} >General</Button>
    <Button sx={mainThemeStyle.buttonHeaderGroupInactive} onClick={()=> {handleTabClick(1)}}>Panel Settings</Button>
    <Button sx={mainThemeStyle.buttonHeaderGroup} onClick={()=> {handleTabClick(2)}}>Relay Settings</Button>
    {dataOfLocation.lpr_accesss == '1' &&
    <Button sx={mainThemeStyle.buttonHeaderGroupInactive} onClick={()=> {handleTabClick(3)}}>LPR</Button>}
    {dataOfLocation.unlocking_mode == '2' &&
    <Button sx={mainThemeStyle.buttonHeaderGroupInactive} onClick={()=> {handleTabClick(4)}}>Hold Open</Button>}
    {dataOfLocation.unlocking_mode == '2' &&
    <Button sx={mainThemeStyle.buttonHeaderGroupInactive} onClick={()=> {handleTabClick(5)}}>Elevator Control</Button>}
    {dataOfLocation.unlocking_mode == '3' &&
    <Button sx={mainThemeStyle.buttonHeaderGroupInactive} onClick={()=> {handleTabClick(6)}}>Brivo Control</Button>}
    <Button sx={mainThemeStyle.buttonHeaderGroupInactive} onClick={()=> {handleTabClick(7)}}>Panel Status</Button>
 
  </Stack>)}
  {(currentTab == 3 && dataOfLocation.cam_brand == "ResidentLink" && <Stack spacing={2} direction="row">
    <Typography></Typography>
    <Button sx={mainThemeStyle.buttonHeaderGroupInactive} onClick={()=> {handleTabClick(0)}} active>General</Button>
    <Button sx={mainThemeStyle.buttonHeaderGroupInactive} onClick={()=> {handleTabClick(1)}}>Panel Settings</Button>
    <Button sx={mainThemeStyle.buttonHeaderGroupInactive} onClick={()=> {handleTabClick(2)}}>Relay Settings</Button>
    {dataOfLocation.lpr_accesss == '1' &&
    <Button sx={mainThemeStyle.buttonHeaderGroup} onClick={()=> {handleTabClick(3)}}>LPR</Button>}
    {dataOfLocation.unlocking_mode == '2' &&
    <Button sx={mainThemeStyle.buttonHeaderGroupInactive} onClick={()=> {handleTabClick(4)}}>Hold Open</Button>}
    {dataOfLocation.unlocking_mode == '2' &&
    <Button sx={mainThemeStyle.buttonHeaderGroupInactive} onClick={()=> {handleTabClick(5)}}>Elevator Control</Button>}
    {dataOfLocation.unlocking_mode == '3' &&
    <Button sx={mainThemeStyle.buttonHeaderGroupInactive} onClick={()=> {handleTabClick(6)}}>Brivo Control</Button>}
   <Button sx={mainThemeStyle.buttonHeaderGroupInactive} onClick={()=> {handleTabClick(7)}}>Panel Status</Button>
 
  </Stack>)}
  {(currentTab == 4 &&  dataOfLocation.cam_brand == "ResidentLink" && dataOfLocation.unlocking_mode == '2' && <Stack spacing={2} direction="row">
    <Typography></Typography>
    <Button sx={mainThemeStyle.buttonHeaderGroupInactive} onClick={()=> {handleTabClick(0)}} active>General</Button>
    <Button sx={mainThemeStyle.buttonHeaderGroupInactive} onClick={()=> {handleTabClick(1)}}>Panel Settings</Button>
    <Button sx={mainThemeStyle.buttonHeaderGroupInactive} onClick={()=> {handleTabClick(2)}}>Relay Settings</Button>
    {dataOfLocation.lpr_accesss == '1' &&
    <Button sx={mainThemeStyle.buttonHeaderGroupInactive} onClick={()=> {handleTabClick(3)}}>LPR</Button>}
    {dataOfLocation.unlocking_mode == '2' &&
    <Button sx={mainThemeStyle.buttonHeaderGroup} onClick={()=> {handleTabClick(4)}}>Hold Open</Button>}
    {dataOfLocation.unlocking_mode == '2' &&
    <Button sx={mainThemeStyle.buttonHeaderGroupInactive} onClick={()=> {handleTabClick(5)}}>Elevator Control</Button>}
    {dataOfLocation.unlocking_mode == '3' &&
    <Button sx={mainThemeStyle.buttonHeaderGroupInactive} onClick={()=> {handleTabClick(6)}}>Brivo Control</Button>}
   <Button sx={mainThemeStyle.buttonHeaderGroupInactive} onClick={()=> {handleTabClick(7)}}>Panel Status</Button>
 
  </Stack>)}
  {(currentTab == 5 && dataOfLocation.cam_brand == "ResidentLink" && dataOfLocation.unlocking_mode == '2' && <Stack spacing={2} direction="row">
    <Typography></Typography>
    <Button sx={mainThemeStyle.buttonHeaderGroupInactive} onClick={()=> {handleTabClick(0)}} active>General</Button>
    <Button sx={mainThemeStyle.buttonHeaderGroupInactive} onClick={()=> {handleTabClick(1)}}>Panel Settings</Button>
    <Button sx={mainThemeStyle.buttonHeaderGroupInactive} onClick={()=> {handleTabClick(2)}}>Relay Settings</Button>
    {dataOfLocation.lpr_accesss == '1' &&
    <Button sx={mainThemeStyle.buttonHeaderGroupInactive} onClick={()=> {handleTabClick(3)}}>LPR</Button>}
    {dataOfLocation.unlocking_mode == '2' &&
    <Button sx={mainThemeStyle.buttonHeaderGroupInactive} onClick={()=> {handleTabClick(4)}}>Hold Open</Button>}
    {dataOfLocation.unlocking_mode == '2' &&
    <Button sx={mainThemeStyle.buttonHeaderGroup} onClick={()=> {handleTabClick(5)}}>Elevator Control</Button>}
    {dataOfLocation.unlocking_mode == '3' &&
    <Button sx={mainThemeStyle.buttonHeaderGroupInactive} onClick={()=> {handleTabClick(6)}}>Brivo Control</Button>}
   <Button sx={mainThemeStyle.buttonHeaderGroupInactive} onClick={()=> {handleTabClick(7)}}>Panel Status</Button>
 
  </Stack>)}
  {(currentTab == 6 && dataOfLocation.cam_brand == "ResidentLink" && dataOfLocation.unlocking_mode == '3' && <Stack spacing={2} direction="row">
    <Typography></Typography>
    <Button sx={mainThemeStyle.buttonHeaderGroupInactive} onClick={()=> {handleTabClick(0)}} active>General</Button>
    <Button sx={mainThemeStyle.buttonHeaderGroupInactive} onClick={()=> {handleTabClick(1)}}>Panel Settings</Button>
    <Button sx={mainThemeStyle.buttonHeaderGroupInactive} onClick={()=> {handleTabClick(2)}}>Relay Settings</Button>
    {dataOfLocation.lpr_accesss == '1' &&
    <Button sx={mainThemeStyle.buttonHeaderGroupInactive} onClick={()=> {handleTabClick(3)}}>LPR</Button>}
    {dataOfLocation.unlocking_mode == '2' &&
    <Button sx={mainThemeStyle.buttonHeaderGroupInactive} onClick={()=> {handleTabClick(4)}}>Hold Open</Button>}
    {dataOfLocation.unlocking_mode == '2' &&
    <Button sx={mainThemeStyle.buttonHeaderGroupInactive} onClick={()=> {handleTabClick(5)}}>Elevator Control</Button>}
    {dataOfLocation.unlocking_mode == '3' &&
    <Button sx={mainThemeStyle.buttonHeaderGroup} onClick={()=> {handleTabClick(6)}}>Brivo Control</Button>}
   <Button sx={mainThemeStyle.buttonHeaderGroupInactive} onClick={()=> {handleTabClick(7)}}>Panel Status</Button>
 
  </Stack>)}
  {(currentTab == 7 && dataOfLocation.cam_brand == "ResidentLink"  && <Stack spacing={2} direction="row">
    <Typography></Typography>
    <Button sx={mainThemeStyle.buttonHeaderGroupInactive} onClick={()=> {handleTabClick(0)}} active>General</Button>
    <Button sx={mainThemeStyle.buttonHeaderGroupInactive} onClick={()=> {handleTabClick(1)}}>Panel Settings</Button>
    <Button sx={mainThemeStyle.buttonHeaderGroupInactive} onClick={()=> {handleTabClick(2)}}>Relay Settings</Button>
    {dataOfLocation.lpr_accesss == '1' &&
    <Button sx={mainThemeStyle.buttonHeaderGroupInactive} onClick={()=> {handleTabClick(3)}}>LPR</Button>}
    {dataOfLocation.unlocking_mode == '2' &&
    <Button sx={mainThemeStyle.buttonHeaderGroupInactive} onClick={()=> {handleTabClick(4)}}>Hold Open</Button>}
    {dataOfLocation.unlocking_mode == '2' &&
    <Button sx={mainThemeStyle.buttonHeaderGroupInactive} onClick={()=> {handleTabClick(5)}}>Elevator Control</Button>}
    {dataOfLocation.unlocking_mode == '3' &&
    <Button sx={mainThemeStyle.buttonHeaderGroupInactive} onClick={()=> {handleTabClick(6)}}>Brivo Control</Button>}
   <Button sx={mainThemeStyle.buttonHeaderGroup} onClick={()=> {handleTabClick(7)}}>Panel Status</Button>
 
  </Stack>)}
</Box>

<Box sx={{position: 'absolute',
           width:'auto',
          
           top:'100px',
           left: '5px',
           display: { xs: "flex", md: "none" },
           right: '5px',
           flexGrow: 1,
          
           }}>
  {(currentTab == 0 && <Grid container spacing={0.5} rowSpacing={0.5} justifyContent="center">
    <Grid item >
    <Button sx={mainThemeStyle.buttonHeaderGroupMobile} onClick={()=> {handleTabClick(0)}} active>General</Button></Grid>
    {dataOfLocation.cam_brand == "Virtual Panel" && <Grid item>
    <Button sx={mainThemeStyle.buttonHeaderGroupInactiveMobile} onClick={()=> {handleTabClick(1)}}>QR Entry</Button>
    </Grid>}
    {dataOfLocation.cam_brand == "Virtual Panel" && <Grid item>
    <Button sx={mainThemeStyle.buttonHeaderGroupInactiveMobile} onClick={()=> {handleTabClick(2)}}>Relay Settings</Button>
    </Grid>}
    {/* {dataOfLocation.cam_brand == "Virtual Panel" && <Grid item>
    <Button sx={mainThemeStyle.buttonHeaderGroupInactiveMobile} onClick={()=> {handleTabClick(3)}}>Hold Open</Button>
    </Grid>}  */}
    {dataOfLocation.cam_brand == "Virtual Panel" &&  dataOfLocation.lpr_accesss == '1' && <Grid item>
    <Button sx={mainThemeStyle.buttonHeaderGroupInactiveMobile} onClick={()=> {handleTabClick(4)}}>LPR</Button>
    </Grid>} 
    {dataOfLocation.cam_brand == "Virtual Panel" && token.issub === true && token.name === 'SUPER ADMIN' && <Grid item>
    <Button sx={mainThemeStyle.buttonHeaderGroupInactiveMobile} onClick={()=> {handleTabClick(5)}}>QR Code</Button>
    </Grid> } 
    {dataOfLocation.cam_brand == "ResidentLink" && <Grid item>
    <Button sx={mainThemeStyle.buttonHeaderGroupInactiveMobile} onClick={()=> {handleTabClick(1)}}>Panel Settings</Button>
    </Grid>}
    {dataOfLocation.cam_brand == "ResidentLink" && <Grid item>
    <Button sx={mainThemeStyle.buttonHeaderGroupInactiveMobile} onClick={()=> {handleTabClick(2)}}>Relay Settings</Button>
    </Grid>}
    {dataOfLocation.cam_brand == "ResidentLink" &&  dataOfLocation.lpr_accesss == '1' && <Grid item>
    <Button sx={mainThemeStyle.buttonHeaderGroupInactiveMobile} onClick={()=> {handleTabClick(3)}}>LPR</Button>
    </Grid>}
    {dataOfLocation.cam_brand == "ResidentLink" && dataOfLocation.unlocking_mode == '2' && <Grid item>
    <Button sx={mainThemeStyle.buttonHeaderGroupInactiveMobile} onClick={()=> {handleTabClick(4)}}>Hold Open</Button>
    </Grid>}
    {dataOfLocation.cam_brand == "ResidentLink" && dataOfLocation.unlocking_mode == '2' && <Grid item>
    <Button sx={mainThemeStyle.buttonHeaderGroupInactiveMobile} onClick={()=> {handleTabClick(5)}}>Elevator Control</Button>
    </Grid>}
    {dataOfLocation.cam_brand == "ResidentLink" && dataOfLocation.unlocking_mode == '3' && <Grid item>
    <Button sx={mainThemeStyle.buttonHeaderGroupInactiveMobile} onClick={()=> {handleTabClick(6)}}>Brivo Control</Button>
    </Grid>}
    {dataOfLocation.cam_brand == "ResidentLink"  &&<Grid item>
    <Button sx={mainThemeStyle.buttonHeaderGroupInactiveMobile} onClick={()=> {handleTabClick(7)}}>Panel Status</Button>
    </Grid>}
  </Grid>)}
  {(currentTab == 1 &&  dataOfLocation.cam_brand == "Virtual Panel" && <Grid container spacing={0.5} rowSpacing={0.5} justifyContent="center">
  <Grid item >
    <Button sx={mainThemeStyle.buttonHeaderGroupInactiveMobile} onClick={()=> {handleTabClick(0)}}>General</Button></Grid>
    <Grid item ><Button sx={mainThemeStyle.buttonHeaderGroupMobile} onClick={()=> {handleTabClick(1)}}>QR Entry</Button></Grid>
    <Grid item ><Button sx={mainThemeStyle.buttonHeaderGroupInactiveMobile} onClick={()=> {handleTabClick(2)}}>Relay Settings</Button></Grid>
    {/* <Grid item ><Button sx={mainThemeStyle.buttonHeaderGroupInactiveMobile} onClick={()=> {handleTabClick(3)}}>Hold Open</Button> </Grid>
    */} { dataOfLocation.lpr_accesss == '1' && <Grid item>	
    <Button sx={mainThemeStyle.buttonHeaderGroupInactiveMobile} onClick={()=> {handleTabClick(4)}}>LPR</Button></Grid>}
    {token.issub === true && token.name === 'SUPER ADMIN'&&  <Grid item ><Button sx={mainThemeStyle.buttonHeaderGroupInactiveMobile} onClick={()=> {handleTabClick(5)}}>QR Code</Button> </Grid> }
   
   </Grid>)}
  {(currentTab == 2 &&  dataOfLocation.cam_brand == "Virtual Panel" && <Grid container spacing={0.5} rowSpacing={0.5} justifyContent="center">
  <Grid item >
    <Button sx={mainThemeStyle.buttonHeaderGroupInactiveMobile} onClick={()=> {handleTabClick(0)}}>General</Button></Grid>
    <Grid item > <Button sx={mainThemeStyle.buttonHeaderGroupInactiveMobile} onClick={()=> {handleTabClick(1)}}>QR Entry</Button></Grid>
    <Grid item ><Button sx={mainThemeStyle.buttonHeaderGroupMobile} onClick={()=> {handleTabClick(2)}}>Relay Settings</Button></Grid>
    {/* <Grid item ><Button sx={mainThemeStyle.buttonHeaderGroupInactiveMobile} onClick={()=> {handleTabClick(3)}}>Hold Open</Button></Grid>
   */}  { dataOfLocation.lpr_accesss == '1' && <Grid item>	
    <Button sx={mainThemeStyle.buttonHeaderGroupInactiveMobile} onClick={()=> {handleTabClick(4)}}>LPR</Button></Grid>}
    {token.issub === true && token.name === 'SUPER ADMIN'&& <Grid item ><Button sx={mainThemeStyle.buttonHeaderGroupInactiveMobile} onClick={()=> {handleTabClick(5)}}>QR Code</Button> </Grid> }
   
     </Grid>)}
   {(currentTab == 3 &&  dataOfLocation.cam_brand == "Virtual Panel" && <Grid container spacing={0.5} rowSpacing={0.5} justifyContent="center">
   <Grid item >
    <Button sx={mainThemeStyle.buttonHeaderGroupInactiveMobile} onClick={()=> {handleTabClick(0)}}>General</Button></Grid>
    <Grid item ><Button sx={mainThemeStyle.buttonHeaderGroupInactiveMobile} onClick={()=> {handleTabClick(1)}}>QR Entry</Button></Grid>
    <Grid item ><Button sx={mainThemeStyle.buttonHeaderGroupInactiveMobile} onClick={()=> {handleTabClick(2)}}>Relay Settings</Button></Grid>
    {/* <Grid item ><Button sx={mainThemeStyle.buttonHeaderGroupMobile} onClick={()=> {handleTabClick(3)}}>Hold Open</Button></Grid> */}
    { dataOfLocation.lpr_accesss == '1' && <Grid item>	
    <Button sx={mainThemeStyle.buttonHeaderGroupInactiveMobile} onClick={()=> {handleTabClick(4)}}>LPR</Button></Grid>}
    {token.issub === true && token.name === 'SUPER ADMIN'&&<Grid item ><Button sx={mainThemeStyle.buttonHeaderGroupInactiveMobile} onClick={()=> {handleTabClick(5)}}>QR Code</Button> </Grid>}
   
    </Grid>)} 
    {(currentTab == 4 &&  dataOfLocation.cam_brand == "Virtual Panel" && <Grid container spacing={0.5} rowSpacing={0.5} justifyContent="center">
   <Grid item >
    <Button sx={mainThemeStyle.buttonHeaderGroupInactiveMobile} onClick={()=> {handleTabClick(0)}}>General</Button></Grid>
    <Grid item ><Button sx={mainThemeStyle.buttonHeaderGroupInactiveMobile} onClick={()=> {handleTabClick(1)}}>QR Entry</Button></Grid>
    <Grid item ><Button sx={mainThemeStyle.buttonHeaderGroupInactiveMobile} onClick={()=> {handleTabClick(2)}}>Relay Settings</Button></Grid>
   {/*  <Grid item ><Button sx={mainThemeStyle.buttonHeaderGroupInactiveMobile} onClick={()=> {handleTabClick(3)}}>Hold Open</Button></Grid>
    */} { dataOfLocation.lpr_accesss == '1' && <Grid item>	
    <Button sx={mainThemeStyle.buttonHeaderGroupMobile} onClick={()=> {handleTabClick(4)}}>LPR</Button></Grid>}	
   	
    {token.issub === true && token.name === 'SUPER ADMIN'&&<Grid item ><Button sx={mainThemeStyle.buttonHeaderGroupInactiveMobile} onClick={()=> {handleTabClick(4)}}>QR Code</Button> </Grid> }
   
    </Grid>)} 
    
    {(currentTab == 5 &&  dataOfLocation.cam_brand == "Virtual Panel" && <Grid container spacing={0.5} rowSpacing={0.5} justifyContent="center">	
   <Grid item >	
    <Button sx={mainThemeStyle.buttonHeaderGroupInactiveMobile} onClick={()=> {handleTabClick(0)}}>General</Button></Grid>	
    <Grid item ><Button sx={mainThemeStyle.buttonHeaderGroupInactiveMobile} onClick={()=> {handleTabClick(1)}}>QR Entry</Button></Grid>	
    <Grid item ><Button sx={mainThemeStyle.buttonHeaderGroupInactiveMobile} onClick={()=> {handleTabClick(2)}}>Relay Settings</Button></Grid>	
    {/* <Grid item ><Button sx={mainThemeStyle.buttonHeaderGroupInactiveMobile} onClick={()=> {handleTabClick(3)}}>Hold Open</Button></Grid>	
    */} { dataOfLocation.lpr_accesss == '1' && <Grid item>	
    <Button sx={mainThemeStyle.buttonHeaderGroupInactiveMobile} onClick={()=> {handleTabClick(4)}}>LPR</Button></Grid>}	
   	
    {token.issub === true && token.name === 'SUPER ADMIN'&&<Grid item ><Button sx={mainThemeStyle.buttonHeaderGroupMobile} onClick={()=> {handleTabClick(5)}}>QR Code</Button> </Grid>	}
   	
    </Grid>)} 
  {(currentTab == 1 &&  dataOfLocation.cam_brand == "ResidentLink" && <Grid container spacing={0.5} rowSpacing={0.5} justifyContent="center">
  <Grid item>
    <Button sx={mainThemeStyle.buttonHeaderGroupInactiveMobile} onClick={()=> {handleTabClick(0)}}>General</Button></Grid>
    <Grid item><Button sx={mainThemeStyle.buttonHeaderGroupMobile} onClick={()=> {handleTabClick(1)}}>Panel Settings</Button></Grid>
    <Grid item> <Button sx={mainThemeStyle.buttonHeaderGroupInactiveMobile} onClick={()=> {handleTabClick(2)}}>Relay Settings</Button></Grid>
    { dataOfLocation.lpr_accesss == '1' && <Grid item>
    <Button sx={mainThemeStyle.buttonHeaderGroupInactiveMobile} onClick={()=> {handleTabClick(3)}}>LPR</Button></Grid>}
     {dataOfLocation.unlocking_mode == '2' && <Grid item>
    <Button sx={mainThemeStyle.buttonHeaderGroupInactiveMobile} onClick={()=> {handleTabClick(4)}}>Hold Open</Button></Grid>}
     {dataOfLocation.unlocking_mode == '2' && <Grid item>
    <Button sx={mainThemeStyle.buttonHeaderGroupInactiveMobile} onClick={()=> {handleTabClick(5)}}>Elevator Control</Button></Grid>}
     {dataOfLocation.unlocking_mode == '3' && <Grid item>
    <Button sx={mainThemeStyle.buttonHeaderGroupInactiveMobile} onClick={()=> {handleTabClick(6)}}>Brivo Control</Button></Grid>}
    <Grid item>
    <Button sx={mainThemeStyle.buttonHeaderGroupInactiveMobile} onClick={()=> {handleTabClick(7)}}>Panel Status</Button>
    </Grid>
  </Grid>)}
  {(currentTab == 2 &&  dataOfLocation.cam_brand == "ResidentLink" && <Grid container spacing={0.5} rowSpacing={0.5} justifyContent="center">
  <Grid item> <Button sx={mainThemeStyle.buttonHeaderGroupInactiveMobile} onClick={()=> {handleTabClick(0)}} >General</Button></Grid>
   <Grid item><Button sx={mainThemeStyle.buttonHeaderGroupInactiveMobile} onClick={()=> {handleTabClick(1)}}>Panel Settings</Button>
  </Grid><Grid item><Button sx={mainThemeStyle.buttonHeaderGroupMobile} onClick={()=> {handleTabClick(2)}}>Relay Settings</Button>
  </Grid>  {dataOfLocation.lpr_accesss == '1' && <Grid item>
    <Button sx={mainThemeStyle.buttonHeaderGroupInactiveMobile} onClick={()=> {handleTabClick(3)}}>LPR</Button></Grid>}
     {dataOfLocation.unlocking_mode == '2' && <Grid item>
    <Button sx={mainThemeStyle.buttonHeaderGroupInactiveMobile} onClick={()=> {handleTabClick(4)}}>Hold Open</Button></Grid>}
     {dataOfLocation.unlocking_mode == '2' && <Grid item>
    <Button sx={mainThemeStyle.buttonHeaderGroupInactiveMobile} onClick={()=> {handleTabClick(5)}}>Elevator Control</Button> </Grid>}
     {dataOfLocation.unlocking_mode == '3' && <Grid item>
    <Button sx={mainThemeStyle.buttonHeaderGroupInactiveMobile} onClick={()=> {handleTabClick(6)}}>Brivo Control</Button> </Grid>}
    <Grid item>
    <Button sx={mainThemeStyle.buttonHeaderGroupInactiveMobile} onClick={()=> {handleTabClick(7)}}>Panel Status</Button>
    </Grid>
  </Grid>)}
  {(currentTab == 3 && dataOfLocation.cam_brand == "ResidentLink" && <Grid container spacing={0.5} rowSpacing={0.5} justifyContent="center">
    
  <Grid item><Button sx={mainThemeStyle.buttonHeaderGroupInactiveMobile} onClick={()=> {handleTabClick(0)}} active>General</Button>
  </Grid><Grid item><Button sx={mainThemeStyle.buttonHeaderGroupInactiveMobile} onClick={()=> {handleTabClick(1)}}>Panel Settings</Button>
  </Grid><Grid item><Button sx={mainThemeStyle.buttonHeaderGroupInactiveMobile} onClick={()=> {handleTabClick(2)}}>Relay Settings</Button>
  </Grid><Grid item>{dataOfLocation.lpr_accesss == '1' &&
    <Button sx={mainThemeStyle.buttonHeaderGroupMobile} onClick={()=> {handleTabClick(3)}}>LPR</Button>}
   </Grid><Grid item> {dataOfLocation.unlocking_mode == '2' &&
    <Button sx={mainThemeStyle.buttonHeaderGroupInactiveMobile} onClick={()=> {handleTabClick(4)}}>Hold Open</Button>}
    </Grid><Grid item>{dataOfLocation.unlocking_mode == '2' &&
    <Button sx={mainThemeStyle.buttonHeaderGroupInactiveMobile} onClick={()=> {handleTabClick(5)}}>Elevator Control</Button>}
   </Grid><Grid item> {dataOfLocation.unlocking_mode == '3' &&
    <Button sx={mainThemeStyle.buttonHeaderGroupInactiveMobile} onClick={()=> {handleTabClick(6)}}>Brivo Control</Button>}
  </Grid>
  <Grid item>
    <Button sx={mainThemeStyle.buttonHeaderGroupInactiveMobile} onClick={()=> {handleTabClick(7)}}>Panel Status</Button>
    </Grid>
  </Grid>)}
  {(currentTab == 4 &&  dataOfLocation.cam_brand == "ResidentLink" && dataOfLocation.unlocking_mode == '2' && <Grid  container spacing={0.5} rowSpacing={0.5} justifyContent="center">
  <Grid item>
    <Button sx={mainThemeStyle.buttonHeaderGroupInactiveMobile} onClick={()=> {handleTabClick(0)}} active>General</Button>
    </Grid><Grid item><Button sx={mainThemeStyle.buttonHeaderGroupInactiveMobile} onClick={()=> {handleTabClick(1)}}>Panel Settings</Button>
    </Grid><Grid item> <Button sx={mainThemeStyle.buttonHeaderGroupInactiveMobile} onClick={()=> {handleTabClick(2)}}>Relay Settings</Button>
    </Grid><Grid item> {dataOfLocation.lpr_accesss == '1' &&
    <Button sx={mainThemeStyle.buttonHeaderGroupInactiveMobile} onClick={()=> {handleTabClick(3)}}>LPR</Button>}
    </Grid><Grid item>{dataOfLocation.unlocking_mode == '2' &&
    <Button sx={mainThemeStyle.buttonHeaderGroupMobile} onClick={()=> {handleTabClick(4)}}>Hold Open</Button>}
    </Grid> <Grid item> {dataOfLocation.unlocking_mode == '2' &&
    <Button sx={mainThemeStyle.buttonHeaderGroupInactiveMobile} onClick={()=> {handleTabClick(5)}}>Elevator Control</Button>}
    </Grid><Grid item> {dataOfLocation.unlocking_mode == '3' &&
    <Button sx={mainThemeStyle.buttonHeaderGroupInactiveMobile} onClick={()=> {handleTabClick(6)}}>Brivo Control</Button>}
  </Grid>
  <Grid item>
    <Button sx={mainThemeStyle.buttonHeaderGroupInactiveMobile} onClick={()=> {handleTabClick(7)}}>Panel Status</Button>
    </Grid>
  </Grid>)}
  {(currentTab == 5 && dataOfLocation.cam_brand == "ResidentLink" && dataOfLocation.unlocking_mode == '2' && <Grid  container spacing={0.5} rowSpacing={0.5} justifyContent="center">
  <Grid item>
    <Button sx={mainThemeStyle.buttonHeaderGroupInactiveMobile} onClick={()=> {handleTabClick(0)}} active>General</Button>
    </Grid>
    <Grid item><Button sx={mainThemeStyle.buttonHeaderGroupInactiveMobile} onClick={()=> {handleTabClick(1)}}>Panel Settings</Button>
    </Grid>
    <Grid item><Button sx={mainThemeStyle.buttonHeaderGroupInactiveMobile} onClick={()=> {handleTabClick(2)}}>Relay Settings</Button>
    </Grid>
    <Grid item> {dataOfLocation.lpr_accesss == '1' &&
    <Button sx={mainThemeStyle.buttonHeaderGroupInactiveMobile} onClick={()=> {handleTabClick(3)}}>LPR</Button>}
    </Grid><Grid item>{dataOfLocation.unlocking_mode == '2' &&
    <Button sx={mainThemeStyle.buttonHeaderGroupInactiveMobile} onClick={()=> {handleTabClick(4)}}>Hold Open</Button>}
    </Grid><Grid item>{dataOfLocation.unlocking_mode == '2' &&
    <Button sx={mainThemeStyle.buttonHeaderGroupMobile} onClick={()=> {handleTabClick(5)}}>Elevator Control</Button>}
    </Grid><Grid item>{dataOfLocation.unlocking_mode == '3' &&
    <Button sx={mainThemeStyle.buttonHeaderGroupInactiveMobile} onClick={()=> {handleTabClick(6)}}>Brivo Control</Button>}
  </Grid>
  <Grid item>
    <Button sx={mainThemeStyle.buttonHeaderGroupInactiveMobile} onClick={()=> {handleTabClick(7)}}>Panel Status</Button>
    </Grid>
  </Grid>)}
  {(currentTab == 6 && dataOfLocation.cam_brand == "ResidentLink" && dataOfLocation.unlocking_mode == '3' && <Grid container spacing={0.5} rowSpacing={0.5} justifyContent="center">
  <Grid item>
    <Button sx={mainThemeStyle.buttonHeaderGroupInactiveMobile} onClick={()=> {handleTabClick(0)}} active>General</Button>
    </Grid><Grid item>  <Button sx={mainThemeStyle.buttonHeaderGroupInactiveMobile} onClick={()=> {handleTabClick(1)}}>Panel Settings</Button>
    </Grid><Grid item><Button sx={mainThemeStyle.buttonHeaderGroupInactiveMobile} onClick={()=> {handleTabClick(2)}}>Relay Settings</Button>
    </Grid><Grid item> {dataOfLocation.lpr_accesss == '1' &&
    <Button sx={mainThemeStyle.buttonHeaderGroupInactiveMobile} onClick={()=> {handleTabClick(3)}}>LPR</Button>}
    </Grid><Grid item>{dataOfLocation.unlocking_mode == '2' &&
    <Button sx={mainThemeStyle.buttonHeaderGroupInactiveMobile} onClick={()=> {handleTabClick(4)}}>Hold Open</Button>}
    </Grid><Grid item>{dataOfLocation.unlocking_mode == '2' &&
    <Button sx={mainThemeStyle.buttonHeaderGroupInactiveMobile} onClick={()=> {handleTabClick(5)}}>Elevator Control</Button>}
    </Grid><Grid item>{dataOfLocation.unlocking_mode == '3' &&
    <Button sx={mainThemeStyle.buttonHeaderGroupMobile} onClick={()=> {handleTabClick(6)}}>Brivo Control</Button>}</Grid>
  <Grid item>
    <Button sx={mainThemeStyle.buttonHeaderGroupInactiveMobile} onClick={()=> {handleTabClick(7)}}>Panel Status</Button>
    </Grid>
  </Grid>
  )}
   {(currentTab == 7 && dataOfLocation.cam_brand == "ResidentLink" && <Grid container spacing={0.5} rowSpacing={0.5} justifyContent="center">
  <Grid item>
    <Button sx={mainThemeStyle.buttonHeaderGroupInactiveMobile} onClick={()=> {handleTabClick(0)}} active>General</Button>
    </Grid><Grid item>  <Button sx={mainThemeStyle.buttonHeaderGroupInactiveMobile} onClick={()=> {handleTabClick(1)}}>Panel Settings</Button>
    </Grid><Grid item><Button sx={mainThemeStyle.buttonHeaderGroupInactiveMobile} onClick={()=> {handleTabClick(2)}}>Relay Settings</Button>
    </Grid><Grid item> {dataOfLocation.lpr_accesss == '1' &&
    <Button sx={mainThemeStyle.buttonHeaderGroupInactiveMobile} onClick={()=> {handleTabClick(3)}}>LPR</Button>}
    </Grid><Grid item>{dataOfLocation.unlocking_mode == '2' &&
    <Button sx={mainThemeStyle.buttonHeaderGroupInactiveMobile} onClick={()=> {handleTabClick(4)}}>Hold Open</Button>}
    </Grid><Grid item>{dataOfLocation.unlocking_mode == '2' &&
    <Button sx={mainThemeStyle.buttonHeaderGroupInactiveMobile} onClick={()=> {handleTabClick(5)}}>Elevator Control</Button>}
    </Grid><Grid item>{dataOfLocation.unlocking_mode == '3' &&
    <Button sx={mainThemeStyle.buttonHeaderGroupInactiveMobile} onClick={()=> {handleTabClick(6)}}>Brivo Control</Button>}</Grid>
  <Grid item>
    <Button sx={mainThemeStyle.buttonHeaderGroupMobile} onClick={()=> {handleTabClick(7)}}>Panel Status</Button>
    </Grid>
  </Grid>
  )}
        </Box> 
<Box sx={mainThemeStyle.boxHeaderBelow}>
{(currentTab == 0 && <>
<div  sx={{
       right: '54px' ,left: '54px',borderRadius:'0px'}}>
               <div style={{ display: 'flex' }}>
                   <Typography variant="h6" component="div" style={{  paddingLeft: '16px',paddingTop: '16px',flexGrow: 1,color:'#fff' }}>
                   Device Details
                   </Typography>
                   
               </div>
              
           </div>
           <Divider sx={{backgroundColor:'white'}} />
           <Box sx={{display: { xs: "none", md: "flex" }}}>
           <Grid container spacing={2} padding= '32px'> 
      <Grid item xs={2.5}>
        <Stack direction="column"  spacing={2}>
     {/*  <Typography sx={mainThemeStyle.popUpLable} height={40}> id </Typography>
      <Typography sx={mainThemeStyle.popUpLable} height={40}> User id </Typography> */}
      {/* <Typography sx={mainThemeStyle.popUpLable} height={40}> Subscription Type </Typography> */}
      <Typography  sx={mainThemeStyle.popUpLable} height={40}>Device Name </Typography>
      <Typography  sx={mainThemeStyle.popUpLable} height={40}>Device Telephone Number </Typography>
      <Typography  sx={mainThemeStyle.popUpLable} height={40}>Device Code </Typography>
      <Typography sx={mainThemeStyle.popUpLable} height={40}> License Key </Typography>
      <Typography  sx={mainThemeStyle.popUpLable} height={40}>Camera Type </Typography>
    
      {dataOfLocation.cam_brand == "ResidentLink" && 
      <Typography  sx={mainThemeStyle.popUpLable} height={40}>Weekly Boot Day </Typography>}
      {dataOfLocation.cam_brand == "ResidentLink" && 
      <Typography  sx={mainThemeStyle.popUpLable} height={40}>Weekly Boot Time </Typography>}
       {dataOfLocation.cam_brand != "ResidentLink" &&  dataOfLocation.cam_brand != "Virtual Panel" && 
     <Typography  sx={mainThemeStyle.popUpLable} height={40}>Device DTMF Code </Typography>}
     {/*  {dataOfLocation.cam_brand != "ResidentLink"  && 
      <Typography  sx={mainThemeStyle.popUpLable} height={40}>Location Code </Typography>} */}
        {dataOfLocation.cam_brand != "ResidentLink" &&  dataOfLocation.cam_brand != "Virtual Panel" && 
      <Typography  sx={mainThemeStyle.popUpLable} height={40}>Camera IP Address Or Hostname </Typography>}
       {dataOfLocation.cam_brand == "Virtual Panel" && 
     <Typography  sx={mainThemeStyle.popUpLable} height={40}>Device Latitude </Typography>}
     {dataOfLocation.cam_brand == "Virtual Panel" && 
     <Typography  sx={mainThemeStyle.popUpLable} height={40}>Device Longitude </Typography>}
      </Stack>
      </Grid>
      
      <Grid item xs={2.5}>
      <Stack direction="column"  spacing={2}>
      {/* <Typography sx={mainThemeStyle.popUpLableAnswer}height={40} > {dataOfLocation.id}</Typography>
      <Typography sx={mainThemeStyle.popUpLableAnswer}height={40}>  {dataOfLocation.userid} </Typography> */}
     {/*  <Typography sx={mainThemeStyle.popUpLableAnswer}height={40}>{dataOfLocation.subscription}</Typography> */}
      <TextField
                        id="input-location"
                        height={40}
                        type= 'text'
                        name="loc_name"
                        variant="filled"
                        value={dataOfLocation.loc_name}
                        onChange={handleInputChange}
                        sx={mainThemeStyle.InputMenuItem}
                        InputLabelProps={{ shrink: true }
                      }
                      disabled = {getDisableValue("138")}
                        InputProps={{
                           
                            sx: {
                                
                                color:'rgba(135, 135, 135, 0.5)',
                                height:'33px',
                                borderRadius: '5px',
                                backgroundColor: '#121212',
                                boxShadow: 'inset 0px 4px 4px rgba(0, 0, 0, 0.25)',
                                padding: '0 0 15px 0',
                                textIndent: '15px !important',
                                '&.Mui-disabled': {
             

                                  background: 'rgba(135, 135, 135, 0.5)',
                                },
                                
                                "& input.Mui-disabled": {
                                  color: "white"
                                }
                               
                                
                            },
                        }}
                       
                    />
        <Typography sx={mainThemeStyle.popUpLableAnswer}height={40}>{dataOfLocation.loc_no}</Typography>
        <Typography sx={mainThemeStyle.popUpLableAnswer}height={40}> {dataOfLocation.loc_search_code} </Typography>
        <Typography sx={mainThemeStyle.popUpLableAnswer}height={40}> {dataOfLocation.licence_key} </Typography>
          <Select
            sx={{ color: 'rgba(135, 135, 135, 0.5)',
            height:'33px',
            marginTop:'4px',
            marginBottom:'3px',
            borderRadius: '5px',
            backgroundColor: '#121212',
            boxShadow: 'inset 0px 4px 4px rgba(0, 0, 0, 0.25)',
            padding: '5px 0 5px 0',
            "& .MuiSvgIcon-root": {
              color: "rgba(135, 135, 135, 0.5)",
            },
            
            '&.Mui-disabled': {
             

              background: 'rgba(135, 135, 135, 0.5)',
            },
            "& input.Mui-disabled": {
              color: "white"
            }
            }}
            name = "cam_brand"
            value={GetValueForCameraBrand()}
          label="Age"
          onChange={handleChangeCameraBrand}
          variant="filled"
          disabled
          hiddenLabel
         
          MenuProps={MenuProps}>
            
            {CameraBrand.items.map(menuItem => {
                  
                  return (
                    <MenuItem  sx={
                      {
                      
                      color:"#fff",
                      backgroundColor: "#2F323A",
                      borderBottom: '.5px solid grey',
                      fontFamily: "Lato",fontSize:'12px',
                  }}
                   value={menuItem.label}>
                      {menuItem.label}
                    </MenuItem>
                  );
                })}
          </Select>
    
      
      {dataOfLocation.cam_brand == "ResidentLink" && <Select
            sx={{ color: 'rgba(135, 135, 135, 0.5)',
            height:'33px',
            marginTop:'4px',
            marginBottom:'3px',
            borderRadius: '5px',
            backgroundColor: '#121212',
            boxShadow: 'inset 0px 4px 4px rgba(0, 0, 0, 0.25)',
            padding: '5px 0 5px 0',
            "& .MuiSvgIcon-root": {
              color: "rgba(135, 135, 135, 0.5)",
            },
            '&.Mui-disabled': {
             

              background: 'rgba(135, 135, 135, 0.5)',
            },
            
            "& input.Mui-disabled": {
              color: "white"
            }
            }}
            name = "rebootday"
            value={dataOfLocation.rebootday}
          label="Age"
          onChange={handleChangeBoot}
          disabled = {getDisableValue("143")}
          variant="filled"
          hiddenLabel
          MenuProps={MenuProps}>
            {BootDay.items.map(menuItem => {
                  
                  return (
                    <MenuItem  sx={
                      {
                      
                      color:"#fff",
                      backgroundColor: "#2F323A",
                      borderBottom: '.5px solid grey',
                      fontFamily: "Lato",fontSize:'12px',
                  }}
                   value={menuItem.label}>
                      {menuItem.label}
                    </MenuItem>
                  );
                })}
          </Select>
         
          }
        {/*   {dataOfLocation.cam_brand == "ResidentLink" && <NestedButtonDropdown
            itemId={BootTime.id} // NOTE: itemId is required for track items
            variant="contained"
            sx={{ color: 'rgba(135, 135, 135, 0.5)',
            height:'34px',
            borderRadius: '5px',
            marginTop:'3px',
            marginBottom:'3px',
            backgroundColor: '#121212',
            boxShadow: 'inset 0px 4px 4px rgba(0, 0, 0, 0.25)',
            padding: '5px 0 5px 0',
            textIndent: '15px !important',}}
            data={BootTime}
          />} */}
            
            {dataOfLocation.cam_brand == "ResidentLink" && <Select
            
            
            sx={{ color: 'rgba(135, 135, 135, 0.5)',
            height:'33px',
            marginTop:'4px',
            marginBottom:'3px',
            borderRadius: '5px',
            backgroundColor: '#121212',
            boxShadow: 'inset 0px 4px 4px rgba(0, 0, 0, 0.25)',
            padding: '5px 0 5px 0',
            "& .MuiSvgIcon-root": {
              color: "rgba(135, 135, 135, 0.5)",
            },
            '&.Mui-disabled': {
             

              background: 'rgba(135, 135, 135, 0.5)',
            },
            
            "& input.Mui-disabled": {
              color: "white"
            }
            }}
            name = "reboottime"
            disabled = {getDisableValue("144")}
          value={dataOfLocation.reboottime}
          label="Age"
          onChange={handleChangeBoot}
          variant="filled"
          hiddenLabel
          MenuProps={MenuProps}
         
            >
              {BootTime.items.map(menuItem => {
                  
                  return (
                    <MenuItem  sx={
                      {
                      
                      color:"#fff",
                      backgroundColor: "#2F323A",
                      borderBottom: '.5px solid grey',
                      fontFamily: "Lato",fontSize:'12px',
                  }}
                   value={menuItem.label}>
                      {menuItem.label}
                    </MenuItem>
                  );
                })}
            </Select>
          }
          {dataOfLocation.cam_brand != "ResidentLink" &&  dataOfLocation.cam_brand != "Virtual Panel" &&
          <TextField
          id="input-dtmf-code"
          height={40}
          type= 'text'
          name="loc_code"
          value={dataOfLocation.loc_code}
          onChange={handleInputChange}
          sx={mainThemeStyle.InputMenuItem}
          InputLabelProps={{ shrink: true }
        }
        variant="filled"
        disabled = {getDisableValue("139")}
          InputProps={{
             
              sx: {
                  
                  color:'rgba(135, 135, 135, 0.5)',
                  height:'33px',
                  borderRadius: '5px',
                  backgroundColor: '#121212',
                  boxShadow: 'inset 0px 4px 4px rgba(0, 0, 0, 0.25)',
                  padding: '0 0 15px 0',
                  textIndent: '15px !important',
                  '&.Mui-disabled': {
             

                    background: 'rgba(135, 135, 135, 0.5)',
                  },
                  
                  "& input.Mui-disabled": {
                    color: "white"
                  }
                 
                  
              },
          }}
         
      />}
       {/* {dataOfLocation.cam_brand != "ResidentLink"  && 
       <Typography  sx={mainThemeStyle.popUpLableAnswer} height={40}>{dataOfLocation.loc_search_code} </Typography>} */}
       {dataOfLocation.cam_brand != "ResidentLink"  &&  dataOfLocation.cam_brand != "Virtual Panel" &&
          <TextField
          id="input-dtmf-code"
          height={40}
          type= 'text'
          name="cam_ip"
          value={dataOfLocation.cam_ip}
          onChange={handleInputChange}
          disabled = {getDisableValue("140")}
          sx={mainThemeStyle.InputMenuItem}
          InputLabelProps={{ shrink: true }
        }
        variant="filled"
          InputProps={{
             
              sx: {
                  
                  color:'rgba(135, 135, 135, 0.5)',
                  height:'33px',
                  borderRadius: '5px',
                  backgroundColor: '#121212',
                  boxShadow: 'inset 0px 4px 4px rgba(0, 0, 0, 0.25)',
                  padding: '0 0 15px 0',
                  textIndent: '15px !important',
                
                  '&.Mui-disabled': {
             

                    background: 'rgba(135, 135, 135, 0.5)',
                  },
                  
                  "& input.Mui-disabled": {
                    color: "white"
                  }
                  
              },
          }}
         
      />}
      { dataOfLocation.cam_brand == "Virtual Panel" &&
          <TextField
          id="latitude"
          height={40}
          type= 'text'
          name="latitude"
          value={dataOfLocation.latitude}
          onChange={handleInputChange}
          disabled = {getDisableValue("148")}
          sx={mainThemeStyle.InputMenuItem}
          InputLabelProps={{ shrink: true }
        }
        variant="filled"
          InputProps={{
             
              sx: {
               
                  color:'rgba(135, 135, 135, 0.5)',
                  height:'33px',
                  borderRadius: '5px',
                  backgroundColor: '#121212',
                  boxShadow: 'inset 0px 4px 4px rgba(0, 0, 0, 0.25)',
                  padding: '0 0 15px 0',
                  textIndent: '15px !important',
                
                  '&.Mui-disabled': {
             

                    background: 'rgba(135, 135, 135, 0.5)',
                  },
                  
                  "& input.Mui-disabled": {
                    color: "white"
                  }
                  
              },
          }}
         
      />}
      { dataOfLocation.cam_brand == "Virtual Panel" &&
          <TextField
          id="longitude"
          height={40}
          type= 'text'
          name="longitude"
          value={dataOfLocation.longitude}
          onChange={handleInputChange}
          disabled = {getDisableValue("149")}
          sx={mainThemeStyle.InputMenuItem}
          InputLabelProps={{ shrink: true }
        }
        variant="filled"
          InputProps={{
             
              sx: {
                
                  color:'rgba(135, 135, 135, 0.5)',
                  height:'33px',
                  borderRadius: '5px',
                  backgroundColor: '#121212',
                  boxShadow: 'inset 0px 4px 4px rgba(0, 0, 0, 0.25)',
                  padding: '0 0 15px 0',
                  textIndent: '15px !important',
                
                  '&.Mui-disabled': {
             

                    background: 'rgba(135, 135, 135, 0.5)',
                  },
                  
                  "& input.Mui-disabled": {
                    color: "white"
                  }
                  
              },
          }}
         
      />}
      </Stack>
      </Grid>
      <Grid item xs={1}>
    
      </Grid>
      <Grid item xs={2.5}>
        <Stack direction="column"  spacing={2}>
      <Typography sx={mainThemeStyle.popUpLable} height={200}> Thumbnail Photo </Typography>
      {(dataOfLocation.cam_brand == "ResidentLink" || dataOfLocation.cam_brand == "Virtual Panel") && 
      <Typography sx={mainThemeStyle.popUpLable} height={40}> Pin Length </Typography>}
       {(dataOfLocation.cam_brand == "ResidentLink" || dataOfLocation.cam_brand == "Virtual Panel") && 
       <Typography sx={mainThemeStyle.popUpLable} height={40}>Delivery PIN </Typography>}
        {(dataOfLocation.cam_brand == "ResidentLink" || dataOfLocation.cam_brand == "Virtual Panel") && dataOfLocation.brivo_enable == '1' &&
      <Typography  sx={mainThemeStyle.popUpLable} height={40}>Brivo Group Name </Typography>}
      {(dataOfLocation.cam_brand == "ResidentLink" || dataOfLocation.cam_brand == "Virtual Panel") && dataOfLocation.pdk_enable == '1' &&
      <Typography  sx={mainThemeStyle.popUpLable} height={40}>PDK CloudNode ID </Typography>}
      {(dataOfLocation.cam_brand == "ResidentLink" || dataOfLocation.cam_brand == "Virtual Panel") && dataOfLocation.pdk_enable == '1' &&
      <Typography  sx={mainThemeStyle.popUpLable} height={40}>PDK Group </Typography>}
       {dataOfLocation.cam_brand != "ResidentLink" && dataOfLocation.cam_brand != "Virtual Panel" && 
      <Typography sx={mainThemeStyle.popUpLable} height={40}> Camera UserName </Typography>}
       {dataOfLocation.cam_brand != "ResidentLink" && dataOfLocation.cam_brand != "Virtual Panel" && 
       <Typography sx={mainThemeStyle.popUpLable} height={40}>Camera Password </Typography>}

{dataOfLocation.cam_brand == "ResidentLink" &&
<Typography  sx={mainThemeStyle.popUpLable} height={40}>Select Time Zone</Typography>}
{dataOfLocation.cam_brand == "ResidentLink" &&
       <Typography  sx={mainThemeStyle.popUpLable} height={40}>Display Abbreviated Last Names</Typography>}
      </Stack>
      </Grid>
      <Grid item xs={2.5}>
        <Stack direction="column"  spacing={2}>
          <Stack direction="row" spacing={2}>
        <img  src={imageUrl} height={200} width={200}></img>
        <Button  sx={mainThemeStyle.normalButton} variant="contained" disabled = {getDisableValue("150")} component="label">Upload <input hidden accept="image/png, image/jpeg" multiple type="file" 
        onChange={ 
          e => {
            if (e.target.files[0].size > 2000000) {
              console.log("File too large");
              return;
            }
            
            var reader = new FileReader();
            reader.readAsDataURL(e.target.files[0]);
        
            reader.onload = () => {
              console.log(reader.result);
              setSelectedImage(e.target.files[0]);
              dataOfLocation.image_upload_data = reader.result;
              setLocationForData();
            };
            reader.onerror = error => {
              console.log("Error: ", error);
            };


          
          }
        }/></Button>
        </Stack>
        {(dataOfLocation.cam_brand == "ResidentLink" || dataOfLocation.cam_brand == "Virtual Panel") && 
        <Typography sx={mainThemeStyle.popUpLableAnswer} height={40}> {dataOfLocation.pin_length}</Typography>
        }
        {(dataOfLocation.cam_brand == "ResidentLink" || dataOfLocation.cam_brand == "Virtual Panel") &&
      <TextField
                        id="delivery-pin"
                        height={40}
                        type= 'number'
                        name="delivery_pin"
                        value={dataOfLocation.delivery_pin}
                        onChange={handleInputChange}
                        sx={mainThemeStyle.InputMenuItem}
                        InputLabelProps={{ shrink: true }
                      }
                      disabled = {getDisableValue("145")}
                      variant="filled"
                        InputProps={{
                           
                            sx: {
                                
                                color:'rgba(135, 135, 135, 0.5)',
                                height:'33px',
                                borderRadius: '5px',
                                backgroundColor: '#121212',
                                boxShadow: 'inset 0px 4px 4px rgba(0, 0, 0, 0.25)',
                                padding: '0 0 15px 0',
                                textIndent: '15px !important',
                                "& input::-webkit-outer-spin-button, & input::-webkit-inner-spin-button": {
                                  display: "none",
                                },
                                '&.Mui-disabled': {
             

                                  background: 'rgba(135, 135, 135, 0.5)',
                                },
                                
                                "& input.Mui-disabled": {
                                  color: "white"
                                }
                                
                            },
                        }}
                       
                    />}
                    {(dataOfLocation.cam_brand == "ResidentLink" || dataOfLocation.cam_brand == "Virtual Panel") &&  dataOfLocation.brivo_enable == '1' &&
                      
                      
                    //   <TextField
                    //     id="brivo-group-name"
                    //     height={40}
                    //     type= 'text'
                    //     name="brivo_group_name"
                    //    /*  disabled ={ (token.issub === true && token.name === 'SUPER ADMIN') ? false :true} */
                    //     value={dataOfLocation.brivo_group_name}
                    //     onChange={handleInputChange}
                    //     sx={mainThemeStyle.InputMenuItem}
                        
                    //     InputLabelProps={{ shrink: true }
                        
                    //   }
                    //   disabled = {getDisableValue("147")}
                    //   variant="filled"
                    //     InputProps={{
                           
                    //         sx: {
                                
                    //             color:'rgba(135, 135, 135, 0.5)',
                    //             height:'33px',
                    //             borderRadius: '5px',
                    //             backgroundColor: '#121212',
                    //             boxShadow: 'inset 0px 4px 4px rgba(0, 0, 0, 0.25)',
                    //             padding: '0 0 15px 0',
                    //             textIndent: '15px !important',
                              
                    //             '&.Mui-disabled': {
             

                    //               background: 'rgba(135, 135, 135, 0.5)',
                    //             },
                    //             "& input.Mui-disabled": {
                    //               color: "white"
                    //             }
                                
                    //         },
                    //     }}
                       
                    // />
                    
                    
                    <Autocomplete
                    style={{marginTop: 22,minWidth: 250}}
                     sx={{  "& .MuiAutocomplete-inputRoot": {
                      color: "rgba(170, 170, 170,1)" },"& .MuiSvgIcon-root": {
                        color: "rgba(170, 170, 170, 1)",
                      },}}
                    disablePortal
                    disabled = {getDisableValue("147")}
                    id="brivo-group-name"
                    name = 'brivo_group_name'
                    value={getBrivoGroupValue()}
                  onChange={handleChangeBrivoGroupValue}
                  variant="filled"
                  hiddenLabel
                  options={dataOfLocation.brivo_group_array}
                 
                  PaperComponent={({ children }) => (
                    <Paper style={{ backgroundColor: "#1E1E1E" ,color:"#AFAFAF", "&:hover":{backgroundColor: "#fff"}}}>{children}</Paper>
                  )}
                  renderInput={(params) => <TextField {...params}  variant="outlined" size="small" style={{ backgroundColor: "#121212",borderRadius: '5px' ,color:"#fff",
                
                    
                }} />}
                  ></Autocomplete>
                    
                    
                    
                    
                    
                    
                    
                    
                    
                    }
                    
                    
                   
                    {(dataOfLocation.cam_brand == "ResidentLink" || dataOfLocation.cam_brand == "Virtual Panel") &&  dataOfLocation.pdk_enable == '1' &&
                    
                    <Select
            sx={{ color: 'rgba(135, 135, 135, 0.5)',
            height:'33px',
            marginTop:'4px',
            marginBottom:'3px',
            borderRadius: '5px',
            backgroundColor: '#121212',
            boxShadow: 'inset 0px 4px 4px rgba(0, 0, 0, 0.25)',
            padding: '5px 0 5px 0',
            "& .MuiSvgIcon-root": {
              color: "rgba(135, 135, 135, 0.5)",
            },
            
            '&.Mui-disabled': {
             

              background: 'rgba(135, 135, 135, 0.5)',
            },
            "& input.Mui-disabled": {
              color: "white"
            }
            }}
            disabled = {getDisableValue("239")}
            id="pdk_node"
            name = 'pdk_node'
            value={getPDKNodeValue()}
          onChange={handleChangePDKNodeValue}
          variant="filled"
          hiddenLabel
          MenuProps={MenuProps}>
            {pdkNodeArray?.map(menuItem => {
                  
                  return (
                    <MenuItem  sx={
                      {
                      
                      color:"#fff",
                      backgroundColor: "#2F323A",
                      borderBottom: '.5px solid grey',
                      fontFamily: "Lato",fontSize:'12px',
                  }}
                  disabled={menuItem.disabled}
                   value={menuItem.name}>
                    <Stack direction="row" justifyContent="left"
  alignItems="center">
                  { menuItem.name == "None" && <ListItemIcon sx={{color: "red"}}>
            <DoNotDisturbIcon fontSize="small" />
          </ListItemIcon>} 
            {menuItem.name}</Stack>
                    </MenuItem>
                  );
                })}
          </Select>
                    
                    
                    
                    
                    }
                    {(dataOfLocation.cam_brand == "ResidentLink" || dataOfLocation.cam_brand == "Virtual Panel") &&  dataOfLocation.pdk_enable == '1' &&
                     <>
                  {/*   <Select
                    sx={{ color: 'rgba(135, 135, 135, 0.5)',
                    height:'33px',
                    marginTop:'4px',
                    marginBottom:'3px',
                    borderRadius: '5px',
                    backgroundColor: '#121212',
                    boxShadow: 'inset 0px 4px 4px rgba(0, 0, 0, 0.25)',
                    padding: '5px 0 5px 0',
                    "& .MuiSvgIcon-root": {
                      color: "rgba(135, 135, 135, 0.5)",
                    },
                    
                    '&.Mui-disabled': {
                     
        
                      background: 'rgba(135, 135, 135, 0.5)',
                    },
                    "& input.Mui-disabled": {
                      color: "white"
                    }
                    }}
                    disabled = {getDisableValue("239")}
                    id="pdk_group"
                    name = 'pdk_group'
                    value={getPDKGroupValue()}
                  onChange={handleChangePDKGroupValue}
                  variant="filled"
                  hiddenLabel
                  MenuProps={MenuProps}>
                    {pdkGroupArray?.map(menuItem => {
                          
                          return (
                            <MenuItem  sx={
                              {
                              
                              color:"#fff",
                              backgroundColor: "#2F323A",
                              borderBottom: '.5px solid grey',
                              fontFamily: "Lato",fontSize:'12px',
                          }}
                          disabled={menuItem.disabled}
                           value={menuItem.name}>
                            <Stack direction="row" justifyContent="left"
          alignItems="center">
                          { menuItem.name == "None" && <ListItemIcon sx={{color: "red"}}>
                    <DoNotDisturbIcon fontSize="small" />
                  </ListItemIcon>} 
                    {menuItem.name}</Stack>
                            </MenuItem>
                          );
                        })}
                  </Select>
                             */}
                          
                          
                          <Autocomplete
            style={{marginTop: 22,minWidth: 250}}
             sx={{  "& .MuiAutocomplete-inputRoot": {
              color: "rgba(170, 170, 170,1)" },"& .MuiSvgIcon-root": {
                color: "rgba(170, 170, 170, 1)",
              },}}
            disablePortal
            disabled = {getDisableValue("239")}
            id="pdk_group"
            name = 'pdk_group'
            value={getPDKGroupValue()}
          onChange={handleChangePDKGroupValue}
          variant="filled"
          hiddenLabel
          options={dataOfLocation.pdk_group_array}
         
          PaperComponent={({ children }) => (
            <Paper style={{ backgroundColor: "#1E1E1E" ,color:"#AFAFAF", "&:hover":{backgroundColor: "#fff"}}}>{children}</Paper>
          )}
          renderInput={(params) => <TextField {...params}  variant="outlined" size="small" style={{ backgroundColor: "#121212",borderRadius: '5px' ,color:"#fff",
        
            
        }} />}
          ></Autocomplete>
                          
                          
                          
                          
                          </>
                    }
                     {dataOfLocation.cam_brand != "ResidentLink"  && dataOfLocation.cam_brand != "Virtual Panel" &&
      <TextField
                        id="cam-username"
                        height={40}
                        type= 'text'
                        name="cam_username"
                        value={dataOfLocation.cam_username}
                        onChange={handleInputChange}
                        sx={mainThemeStyle.InputMenuItem}
                        InputLabelProps={{ shrink: true }
                      }
                      disabled = {getDisableValue("141")}
                      variant="filled"
                        InputProps={{
                           
                            sx: {
                                
                                color:'rgba(135, 135, 135, 0.5)',
                                height:'33px',
                                borderRadius: '5px',
                                backgroundColor: '#121212',
                                boxShadow: 'inset 0px 4px 4px rgba(0, 0, 0, 0.25)',
                                padding: '0 0 15px 0',
                                textIndent: '15px !important',
                              
                                '&.Mui-disabled': {
             

                                  background: 'rgba(135, 135, 135, 0.5)',
                                },
                                
                                "& input.Mui-disabled": {
                                  color: "white"
                                }
                                
                            },
                        }}
                       
                    />}
                    {dataOfLocation.cam_brand != "ResidentLink"  && dataOfLocation.cam_brand != "Virtual Panel" &&
                      <TextField
                        id="cam-password"
                        height={40}
                        type= 'text'
                        name="cam_password"
                        value={dataOfLocation.cam_password}
                        onChange={handleInputChange}
                        sx={mainThemeStyle.InputMenuItem}
                        InputLabelProps={{ shrink: true }
                      }
                      disabled = {getDisableValue("142")}
                      variant="filled"
                        InputProps={{
                           
                            sx: {
                                
                                color:'rgba(135, 135, 135, 0.5)',
                                height:'33px',
                                borderRadius: '5px',
                                backgroundColor: '#121212',
                                boxShadow: 'inset 0px 4px 4px rgba(0, 0, 0, 0.25)',
                                padding: '0 0 15px 0',
                                textIndent: '15px !important',
                                '&.Mui-disabled': {
             

                                  background: 'rgba(135, 135, 135, 0.5)',
                                },
                                
                                "& input.Mui-disabled": {
                                  color: "white"
                                }
                               
                                
                            },
                        }}
                       
                    />}
{dataOfLocation.cam_brand == "ResidentLink" &&
<Select
            sx={{ color: 'rgba(135, 135, 135, 0.5)',
            height:'33px',
            marginTop:'4px',
            marginBottom:'3px',
            borderRadius: '5px',
            backgroundColor: '#121212',
            boxShadow: 'inset 0px 4px 4px rgba(0, 0, 0, 0.25)',
            padding: '5px 0 5px 0',
            "& .MuiSvgIcon-root": {
              color: "rgba(135, 135, 135, 0.5)",
            },
            '&.Mui-disabled': {
             

              background: 'rgba(135, 135, 135, 0.5)',
            },
            
            "& input.Mui-disabled": {
              color: "white"
            }
            }}
            name = "time_zone"
            disabled = {getDisableValue("146")}
            value={dataOfLocation.time_zone}
          label="Age"
          onChange={handleChangeBoot}
          variant="filled"
          hiddenLabel
          MenuProps={MenuProps}>
            
            {TimeZoneList.map(menuItem => {
                  
                  return (
                    <MenuItem  sx={
                      {
                      
                      color:"#fff",
                      backgroundColor: "#2F323A",
                      borderBottom: '.5px solid grey',
                      fontFamily: "Lato",fontSize:'12px',
                  }}
                   value={menuItem}>
                      {menuItem}
                    </MenuItem>
                  );
                })}
          </Select>}
          {dataOfLocation.cam_brand == "ResidentLink" &&
          <FormControlLabel  sx={mainThemeStyle.popUpLable}
        control={<IOSSwitch sx={{ m: 1 }} name="last_abre" onChange={handlecheckboxCheck} disabled = {getDisableValue("146")} checked={dataOfLocation.last_abre == "1" ? true : false } />}
        label=""
      />}
      </Stack>
      </Grid>
     
      
      </Grid></Box>
      <Box sx={{display: { xs: "flex", md: "none" }}}>
           <Grid container spacing={2} padding= '10px' paddingBottom='32px' paddingTop='32px'> 
      <Grid item xs={5}>
        <Stack direction="column"  spacing={2}>
   
      <Typography  sx={mainThemeStyle.popUpLable} height={40}>Device Name </Typography>
      <Typography  sx={mainThemeStyle.popUpLable} height={40}>Device Phone Number </Typography>
      <Typography  sx={mainThemeStyle.popUpLable} height={40}>Device Code </Typography>
      <Typography sx={mainThemeStyle.popUpLable} height={40}> License Key </Typography>
      <Typography  sx={mainThemeStyle.popUpLable} height={40}>Camera Type </Typography>
    
      {dataOfLocation.cam_brand == "ResidentLink" && 
      <Typography  sx={mainThemeStyle.popUpLable} height={40}>Weekly Boot Day </Typography>}
      {dataOfLocation.cam_brand == "ResidentLink" && 
      <Typography  sx={mainThemeStyle.popUpLable} height={40}>Weekly Boot Time </Typography>}
       {dataOfLocation.cam_brand != "ResidentLink" &&  dataOfLocation.cam_brand != "Virtual Panel" && 
     <Typography  sx={mainThemeStyle.popUpLable} height={40}>Device DTMF Code </Typography>}
      {dataOfLocation.cam_brand != "ResidentLink" && 
      <Typography  sx={mainThemeStyle.popUpLable} height={40}>Device Code </Typography>}
        {dataOfLocation.cam_brand != "ResidentLink" &&  dataOfLocation.cam_brand != "Virtual Panel" && 
      <Typography  sx={mainThemeStyle.popUpLable} height={40}>Camera IP Address</Typography>}
       {dataOfLocation.cam_brand == "Virtual Panel" && 
     <Typography  sx={mainThemeStyle.popUpLable} height={40}>Device Latitude </Typography>}
     {dataOfLocation.cam_brand == "Virtual Panel" && 
     <Typography  sx={mainThemeStyle.popUpLable} height={40}>Device Longitude </Typography>}
      <Typography sx={mainThemeStyle.popUpLable} height={145}> Thumbnail Photo </Typography>
      {(dataOfLocation.cam_brand == "ResidentLink" || dataOfLocation.cam_brand == "Virtual Panel") && 
      <Typography sx={mainThemeStyle.popUpLable} height={40}> Pin Length </Typography>}
       {(dataOfLocation.cam_brand == "ResidentLink" || dataOfLocation.cam_brand == "Virtual Panel") && 
       <Typography sx={mainThemeStyle.popUpLable} height={40}>Delivery PIN </Typography>}
        {(dataOfLocation.cam_brand == "ResidentLink" || dataOfLocation.cam_brand == "Virtual Panel") && dataOfLocation.brivo_enable == '1' &&
      <Typography  sx={mainThemeStyle.popUpLable} height={40}>Brivo Group Name </Typography>}
      {(dataOfLocation.cam_brand == "ResidentLink" || dataOfLocation.cam_brand == "Virtual Panel") && dataOfLocation.pdk_enable == '1' &&
      <Typography  sx={mainThemeStyle.popUpLable} height={40}>PDK CloudNode ID </Typography>}
      {(dataOfLocation.cam_brand == "ResidentLink" || dataOfLocation.cam_brand == "Virtual Panel") && dataOfLocation.pdk_enable == '1' &&
     <Typography  sx={mainThemeStyle.popUpLable} height={40}>PDK Group </Typography>}
       {dataOfLocation.cam_brand != "ResidentLink" && dataOfLocation.cam_brand != "Virtual Panel" && 
      <Typography sx={mainThemeStyle.popUpLable} height={40}> Camera UserName </Typography>}
       {dataOfLocation.cam_brand != "ResidentLink"  && dataOfLocation.cam_brand != "Virtual Panel" && 
       <Typography sx={mainThemeStyle.popUpLable} height={40}>Camera Password </Typography>}

{dataOfLocation.cam_brand == "ResidentLink" &&
<Typography  sx={mainThemeStyle.popUpLable} height={40}>Select Time Zone</Typography>}
{dataOfLocation.cam_brand == "ResidentLink" &&
       <Typography  sx={mainThemeStyle.popUpLable} height={40}>Display Abbreviated Last Names</Typography>}
      </Stack>
      </Grid>
      
      <Grid item xs={7}>
      <Stack direction="column"  spacing={2}>
     
      <TextField
                        id="input-location"
                        height={40}
                        type= 'text'
                        name="loc_name"
                        variant="filled"
                        value={dataOfLocation.loc_name}
                        onChange={handleInputChange}
                        sx={mainThemeStyle.InputMenuItem}
                        InputLabelProps={{ shrink: true }
                      }
                      disabled = {getDisableValue("138")}
                        InputProps={{
                           
                            sx: {
                                
                                color:'rgba(135, 135, 135, 0.5)',
                                height:'33px',
                                borderRadius: '5px',
                                backgroundColor: '#121212',
                                boxShadow: 'inset 0px 4px 4px rgba(0, 0, 0, 0.25)',
                                padding: '0 0 15px 0',
                                textIndent: '15px !important',
                                '&.Mui-disabled': {
             

                                  background: 'rgba(135, 135, 135, 0.5)',
                                },
                                
                                "& input.Mui-disabled": {
                                  color: "white"
                                }
                               
                                
                            },
                        }}
                       
                    />
        <Typography sx={mainThemeStyle.popUpLableAnswer}height={40}>{dataOfLocation.loc_no}</Typography>
        <Typography sx={mainThemeStyle.popUpLableAnswer}height={40}> {dataOfLocation.loc_search_code} </Typography>
        <Typography sx={mainThemeStyle.popUpLableAnswer}height={40}> {dataOfLocation.licence_key} </Typography>
          <Select
            sx={{ color: 'rgba(135, 135, 135, 0.5)',
            height:'33px',
            marginTop:'4px',
            marginBottom:'3px',
            borderRadius: '5px',
            backgroundColor: '#121212',
            boxShadow: 'inset 0px 4px 4px rgba(0, 0, 0, 0.25)',
            padding: '5px 0 5px 0',
            "& .MuiSvgIcon-root": {
              color: "rgba(135, 135, 135, 0.5)",
            },
            
            '&.Mui-disabled': {
             

              background: 'rgba(135, 135, 135, 0.5)',
            },
            "& input.Mui-disabled": {
              color: "white"
            }
            }}
            name = "cam_brand"
            value={GetValueForCameraBrand()}
          label="Age"
          onChange={handleChangeCameraBrand}
          variant="filled"
          disabled
          hiddenLabel
         
          MenuProps={MenuProps}>
            
            {CameraBrand.items.map(menuItem => {
                  
                  return (
                    <MenuItem  sx={
                      {
                      
                      color:"#fff",
                      backgroundColor: "#2F323A",
                      borderBottom: '.5px solid grey',
                      fontFamily: "Lato",fontSize:'12px',
                  }}
                   value={menuItem.label}>
                      {menuItem.label}
                    </MenuItem>
                  );
                })}
          </Select>
    
      
      {dataOfLocation.cam_brand == "ResidentLink" && <Select
            sx={{ color: 'rgba(135, 135, 135, 0.5)',
            height:'33px',
            marginTop:'4px',
            marginBottom:'3px',
            borderRadius: '5px',
            backgroundColor: '#121212',
            boxShadow: 'inset 0px 4px 4px rgba(0, 0, 0, 0.25)',
            padding: '5px 0 5px 0',
            "& .MuiSvgIcon-root": {
              color: "rgba(135, 135, 135, 0.5)",
            },
            '&.Mui-disabled': {
             

              background: 'rgba(135, 135, 135, 0.5)',
            },
            
            "& input.Mui-disabled": {
              color: "white"
            }
            }}
            name = "rebootday"
            value={dataOfLocation.rebootday}
          label="Age"
          onChange={handleChangeBoot}
          variant="filled"
          hiddenLabel
          disabled = {getDisableValue("143")}
          MenuProps={MenuProps}>
            {BootDay.items.map(menuItem => {
                  
                  return (
                    <MenuItem  sx={
                      {
                      
                      color:"#fff",
                      backgroundColor: "#2F323A",
                      borderBottom: '.5px solid grey',
                      fontFamily: "Lato",fontSize:'12px',
                  }}
                   value={menuItem.label}>
                      {menuItem.label}
                    </MenuItem>
                  );
                })}
          </Select>
         
          }
        
            
            {dataOfLocation.cam_brand == "ResidentLink" && <Select
            
            
            sx={{ color: 'rgba(135, 135, 135, 0.5)',
            height:'33px',
            marginTop:'4px',
            marginBottom:'3px',
            borderRadius: '5px',
            backgroundColor: '#121212',
            boxShadow: 'inset 0px 4px 4px rgba(0, 0, 0, 0.25)',
            padding: '5px 0 5px 0',
            "& .MuiSvgIcon-root": {
              color: "rgba(135, 135, 135, 0.5)",
            },
            '&.Mui-disabled': {
             

              background: 'rgba(135, 135, 135, 0.5)',
            },
            
            "& input.Mui-disabled": {
              color: "white"
            }
            }}
            name = "reboottime"
            disabled = {getDisableValue("144")}
          value={dataOfLocation.reboottime}
          label="Age"
          onChange={handleChangeBoot}
          variant="filled"
          hiddenLabel
          MenuProps={MenuProps}
         
            >
              {BootTime.items.map(menuItem => {
                  
                  return (
                    <MenuItem  sx={
                      {
                      
                      color:"#fff",
                      backgroundColor: "#2F323A",
                      borderBottom: '.5px solid grey',
                      fontFamily: "Lato",fontSize:'12px',
                  }}
                   value={menuItem.label}>
                      {menuItem.label}
                    </MenuItem>
                  );
                })}
            </Select>
          }
          {dataOfLocation.cam_brand != "ResidentLink" &&  dataOfLocation.cam_brand != "Virtual Panel" &&
          <TextField
          id="input-dtmf-code"
          height={40}
          type= 'text'
          name="loc_code"
          value={dataOfLocation.loc_code}
          onChange={handleInputChange}
          sx={mainThemeStyle.InputMenuItem}
          InputLabelProps={{ shrink: true }
        }
        disabled = {getDisableValue("139")}
        variant="filled"
          InputProps={{
             
              sx: {
                  
                  color:'rgba(135, 135, 135, 0.5)',
                  height:'33px',
                  borderRadius: '5px',
                  backgroundColor: '#121212',
                  boxShadow: 'inset 0px 4px 4px rgba(0, 0, 0, 0.25)',
                  padding: '0 0 15px 0',
                  textIndent: '15px !important',
                  '&.Mui-disabled': {
             

                    background: 'rgba(135, 135, 135, 0.5)',
                  },
                  
                  "& input.Mui-disabled": {
                    color: "white"
                  }
                 
                  
              },
          }}
         
      />}
       {dataOfLocation.cam_brand != "ResidentLink" &&
       <Typography  sx={mainThemeStyle.popUpLableAnswer} height={40}>{dataOfLocation.loc_search_code} </Typography>}
       {dataOfLocation.cam_brand != "ResidentLink"  &&  dataOfLocation.cam_brand != "Virtual Panel" &&
          <TextField
          id="input-dtmf-code"
          height={40}
          type= 'text'
          name="cam_ip"
          disabled = {getDisableValue("140")}
          value={dataOfLocation.cam_ip}
          onChange={handleInputChange}
          sx={mainThemeStyle.InputMenuItem}
          InputLabelProps={{ shrink: true }
        }
        variant="filled"
          InputProps={{
             
              sx: {
                  
                  color:'rgba(135, 135, 135, 0.5)',
                  height:'33px',
                  borderRadius: '5px',
                  backgroundColor: '#121212',
                  boxShadow: 'inset 0px 4px 4px rgba(0, 0, 0, 0.25)',
                  padding: '0 0 15px 0',
                  textIndent: '15px !important',
                  '&.Mui-disabled': {
             

                    background: 'rgba(135, 135, 135, 0.5)',
                  },
                  
                  "& input.Mui-disabled": {
                    color: "white"
                  }
                 
                  
              },
          }}
         
      />}
      { dataOfLocation.cam_brand == "Virtual Panel" &&
          <TextField
          id="latitude"
          height={40}
          type= 'text'
          name="latitude"
          value={dataOfLocation.latitude}
          onChange={handleInputChange}
          sx={mainThemeStyle.InputMenuItem}
          InputLabelProps={{ shrink: true }
        }
        disabled = {getDisableValue("148")}
        variant="filled"
          InputProps={{
             
              sx: {
                top:'10px',
                  color:'rgba(135, 135, 135, 0.5)',
                  height:'33px',
                  borderRadius: '5px',
                  backgroundColor: '#121212',
                  boxShadow: 'inset 0px 4px 4px rgba(0, 0, 0, 0.25)',
                  padding: '0 0 15px 0',
                  textIndent: '15px !important',
                
                  '&.Mui-disabled': {
             

                    background: 'rgba(135, 135, 135, 0.5)',
                  },
                  
                  "& input.Mui-disabled": {
                    color: "white"
                  }
                  
              },
          }}
         
      />}
      { dataOfLocation.cam_brand == "Virtual Panel" &&
          <TextField
          id="longitude"
          height={40}
          type= 'text'
          name="longitude"
          value={dataOfLocation.longitude}
          onChange={handleInputChange}
          sx={mainThemeStyle.InputMenuItem}
          InputLabelProps={{ shrink: true }
        }
        disabled = {getDisableValue("149")}
        variant="filled"
          InputProps={{
             
              sx: {
                top:'10px',
                  color:'rgba(135, 135, 135, 0.5)',
                  height:'33px',
                  borderRadius: '5px',
                  backgroundColor: '#121212',
                  boxShadow: 'inset 0px 4px 4px rgba(0, 0, 0, 0.25)',
                  padding: '0 0 15px 0',
                  textIndent: '15px !important',
                  '&.Mui-disabled': {
             

                    background: 'rgba(135, 135, 135, 0.5)',
                  },
                  
                  "& input.Mui-disabled": {
                    color: "white"
                  }
                 
                  
              },
          }}
         
      />}
       <Typography></Typography>
      <Stack direction="column" spacing={2}>
        <img  src={imageUrl} height={100} width={100}></img>
        <Button  sx={mainThemeStyle.normalButton} variant="contained" disabled = {getDisableValue("150")} component="label">Upload <input hidden accept="image/png, image/jpeg" multiple type="file" 
        onChange={ 
          e => {
            if (e.target.files[0].size > 2000000) {
              console.log("File too large");
              return;
            }
            
            var reader = new FileReader();
            reader.readAsDataURL(e.target.files[0]);
        
            reader.onload = () => {
              console.log(reader.result);
              setSelectedImage(e.target.files[0]);
              dataOfLocation.image_upload_data = reader.result;
              setLocationForData();
            };
            reader.onerror = error => {
              console.log("Error: ", error);
            };


          
          }
        }/></Button>
        </Stack>
        {(dataOfLocation.cam_brand == "ResidentLink" || dataOfLocation.cam_brand == "Virtual Panel") && 
        <Typography sx={mainThemeStyle.popUpLableAnswer} height={40}> {dataOfLocation.pin_length}</Typography>
        }
        {(dataOfLocation.cam_brand == "ResidentLink" || dataOfLocation.cam_brand == "Virtual Panel") &&
      <TextField
                        id="delivery-pin"
                        height={40}
                        type= 'number'
                        name="delivery_pin"
                        value={dataOfLocation.delivery_pin}
                        onChange={handleInputChange}
                        sx={mainThemeStyle.InputMenuItem}
                        InputLabelProps={{ shrink: true }
                      }
                      disabled = {getDisableValue("145")}
                      variant="filled"
                        InputProps={{
                           
                            sx: {
                                
                                color:'rgba(135, 135, 135, 0.5)',
                                height:'33px',
                                borderRadius: '5px',
                                backgroundColor: '#121212',
                                boxShadow: 'inset 0px 4px 4px rgba(0, 0, 0, 0.25)',
                                padding: '0 0 15px 0',
                                textIndent: '15px !important',
                                "& input::-webkit-outer-spin-button, & input::-webkit-inner-spin-button": {
                                  display: "none",
                                },
                                '&.Mui-disabled': {
             

                                  background: 'rgba(135, 135, 135, 0.5)',
                                },
                                
                                "& input.Mui-disabled": {
                                  color: "white"
                                }
                                
                            },
                        }}
                       
                    />}
                    {(dataOfLocation.cam_brand == "ResidentLink" || dataOfLocation.cam_brand == "Virtual Panel") &&  dataOfLocation.brivo_enable == '1' &&
                    //   <TextField
                    //     id="brivo-group-name"
                    //     height={40}
                    //     type= 'text'
                    //     name="brivo_group_name"
                    //     /* disabled ={ (token.issub === true && token.name === 'SUPER ADMIN') ? false :true} */
                    //     value={dataOfLocation.brivo_group_name}
                    //     onChange={handleInputChange}
                    //     sx={mainThemeStyle.InputMenuItem}
                    //     InputLabelProps={{ shrink: true }
                        
                    //   }
                    //   disabled = {getDisableValue("147")}
                    //   variant="filled"
                    //     InputProps={{
                           
                    //         sx: {
                                
                    //             color:'rgba(135, 135, 135, 0.5)',
                    //             height:'33px',
                    //             borderRadius: '5px',
                    //             backgroundColor: '#121212',
                    //             boxShadow: 'inset 0px 4px 4px rgba(0, 0, 0, 0.25)',
                    //             padding: '0 0 15px 0',
                    //             textIndent: '15px !important',
                              
                    //             '&.Mui-disabled': {
             

                    //               background: 'rgba(135, 135, 135, 0.5)',
                    //             },
                    //             "& input.Mui-disabled": {
                    //               color: "white"
                    //             }
                                
                    //         },
                    //     }}
                       
                    // />
                    <Autocomplete
                    style={{marginTop: 22,minWidth: 250}}
                     sx={{  "& .MuiAutocomplete-inputRoot": {
                      color: "rgba(170, 170, 170,1)" },"& .MuiSvgIcon-root": {
                        color: "rgba(170, 170, 170, 1)",
                      },}}
                    disablePortal
                    disabled = {getDisableValue("147")}
                    id="brivo-group-name"
                    name = 'brivo_group_name'
                    value={getBrivoGroupValue()}
                  onChange={handleChangeBrivoGroupValue}
                  variant="filled"
                  hiddenLabel
                  options={dataOfLocation.brivo_group_array}
                 
                  PaperComponent={({ children }) => (
                    <Paper style={{ backgroundColor: "#1E1E1E" ,color:"#AFAFAF", "&:hover":{backgroundColor: "#fff"}}}>{children}</Paper>
                  )}
                  renderInput={(params) => <TextField {...params}  variant="outlined" size="small" style={{ backgroundColor: "#121212",borderRadius: '5px' ,color:"#fff",
                
                    
                }} />}
                  ></Autocomplete>
                  }
                      {(dataOfLocation.cam_brand == "ResidentLink" || dataOfLocation.cam_brand == "Virtual Panel") &&  dataOfLocation.pdk_enable == '1' &&
                    
                    <Select
            sx={{ color: 'rgba(135, 135, 135, 0.5)',
            height:'33px',
            marginTop:'4px',
            marginBottom:'3px',
            borderRadius: '5px',
            backgroundColor: '#121212',
            boxShadow: 'inset 0px 4px 4px rgba(0, 0, 0, 0.25)',
            padding: '5px 0 5px 0',
            "& .MuiSvgIcon-root": {
              color: "rgba(135, 135, 135, 0.5)",
            },
            
            '&.Mui-disabled': {
             

              background: 'rgba(135, 135, 135, 0.5)',
            },
            "& input.Mui-disabled": {
              color: "white"
            }
            }}
            disabled = {getDisableValue("239")}
            id="pdk_node"
            name = 'pdk_node'
            value={getPDKNodeValue()}
          onChange={handleChangePDKNodeValue}
          variant="filled"
          hiddenLabel
          MenuProps={MenuProps}>
            {pdkNodeArray?.map(menuItem => {
                  
                  return (
                    <MenuItem  sx={
                      {
                      
                      color:"#fff",
                      backgroundColor: "#2F323A",
                      borderBottom: '.5px solid grey',
                      fontFamily: "Lato",fontSize:'12px',
                  }}
                  disabled={menuItem.disabled}
                   value={menuItem.name}>
                    <Stack direction="row" justifyContent="left"
  alignItems="center">
                  { menuItem.name == "None" && <ListItemIcon sx={{color: "red"}}>
            <DoNotDisturbIcon fontSize="small" />
          </ListItemIcon>} 
            {menuItem.name}</Stack>
                    </MenuItem>
                  );
                })}
          </Select>
                    
                    
                    
                    
                    }
                    {(dataOfLocation.cam_brand == "ResidentLink" || dataOfLocation.cam_brand == "Virtual Panel") &&  dataOfLocation.pdk_enable == '1' &&
                     
                  /*   <Select
                    sx={{ color: 'rgba(135, 135, 135, 0.5)',
                    height:'33px',
                    marginTop:'4px',
                    marginBottom:'3px',
                    borderRadius: '5px',
                    backgroundColor: '#121212',
                    boxShadow: 'inset 0px 4px 4px rgba(0, 0, 0, 0.25)',
                    padding: '5px 0 5px 0',
                    "& .MuiSvgIcon-root": {
                      color: "rgba(135, 135, 135, 0.5)",
                    },
                    
                    '&.Mui-disabled': {
                     
        
                      background: 'rgba(135, 135, 135, 0.5)',
                    },
                    "& input.Mui-disabled": {
                      color: "white"
                    }
                    }}
                    disabled = {getDisableValue("239")}
                    id="pdk_group"
                    name = 'pdk_group'
                    value={getPDKGroupValue()}
                  onChange={handleChangePDKGroupValue}
                  variant="filled"
                  hiddenLabel
                  MenuProps={MenuProps}>
                    {pdkGroupArray?.map(menuItem => {
                          
                          return (
                            <MenuItem  sx={
                              {
                              
                              color:"#fff",
                              backgroundColor: "#2F323A",
                              borderBottom: '.5px solid grey',
                              fontFamily: "Lato",fontSize:'12px',
                          }}
                          disabled={menuItem.disabled}
                           value={menuItem.name}>
                            <Stack direction="row" justifyContent="left"
          alignItems="center">
                          { menuItem.name == "None" && <ListItemIcon sx={{color: "red"}}>
                    <DoNotDisturbIcon fontSize="small" />
                  </ListItemIcon>} 
                    {menuItem.name}</Stack>
                            </MenuItem>
                          );
                        })}
                  </Select> */
                            
                  
                 
                          
                          
                          <Autocomplete
            style={{marginTop: 22,minWidth: 250}}
             sx={{  "& .MuiAutocomplete-inputRoot": {
              color: "rgba(170, 170, 170,1)" },"& .MuiSvgIcon-root": {
                color: "rgba(170, 170, 170, 1)",
              },}}
            disablePortal
            disabled = {getDisableValue("239")}
            id="pdk_group"
            name = 'pdk_group'
            value={getPDKGroupValue()}
          onChange={handleChangePDKGroupValue}
          variant="filled"
          hiddenLabel
          options={dataOfLocation.pdk_group_array}
         
          PaperComponent={({ children }) => (
            <Paper style={{ backgroundColor: "#1E1E1E" ,color:"#AFAFAF", "&:hover":{backgroundColor: "#fff"}}}>{children}</Paper>
          )}
          renderInput={(params) => <TextField {...params}  variant="outlined" size="small" style={{ backgroundColor: "#121212",borderRadius: '5px' ,color:"#fff",
        
            
        }} />}
          ></Autocomplete>
                          
                          
                          
                          
                         
                    }
                     {dataOfLocation.cam_brand != "ResidentLink" && dataOfLocation.cam_brand != "Virtual Panel" &&
      <TextField
                        id="cam-username"
                        height={40}
                        type= 'text'
                        name="cam_username"
                        value={dataOfLocation.cam_username}
                        onChange={handleInputChange}
                        sx={mainThemeStyle.InputMenuItem}
                        InputLabelProps={{ shrink: true }
                      }
                      disabled = {getDisableValue("141")}
                      variant="filled"
                        InputProps={{
                           
                            sx: {
                                
                                color:'rgba(135, 135, 135, 0.5)',
                                height:'33px',
                                borderRadius: '5px',
                                backgroundColor: '#121212',
                                boxShadow: 'inset 0px 4px 4px rgba(0, 0, 0, 0.25)',
                                padding: '0 0 15px 0',
                                textIndent: '15px !important',
                              
                                '&.Mui-disabled': {
             

                                  background: 'rgba(135, 135, 135, 0.5)',
                                },
                                
                                "& input.Mui-disabled": {
                                  color: "white"
                                }
                                
                            },
                        }}
                       
                    />}
                    {dataOfLocation.cam_brand != "ResidentLink" && dataOfLocation.cam_brand != "Virtual Panel" &&
                      <TextField
                        id="cam-password"
                        height={40}
                        type= 'text'
                        name="cam_password"
                        value={dataOfLocation.cam_password}
                        onChange={handleInputChange}
                        sx={mainThemeStyle.InputMenuItem}
                        InputLabelProps={{ shrink: true }
                      }
                      disabled = {getDisableValue("142")}
                      variant="filled"
                        InputProps={{
                           
                            sx: {
                                
                                color:'rgba(135, 135, 135, 0.5)',
                                height:'33px',
                                borderRadius: '5px',
                                backgroundColor: '#121212',
                                boxShadow: 'inset 0px 4px 4px rgba(0, 0, 0, 0.25)',
                                padding: '0 0 15px 0',
                                textIndent: '15px !important',
                              
                               
                                '&.Mui-disabled': {
             

                                  background: 'rgba(135, 135, 135, 0.5)',
                                },
                                
                                "& input.Mui-disabled": {
                                  color: "white"
                                }
                            },
                        }}
                       
                    />}
{dataOfLocation.cam_brand == "ResidentLink" &&
<Select
            sx={{ color: 'rgba(135, 135, 135, 0.5)',
            height:'33px',
            marginTop:'4px',
            marginBottom:'3px',
            borderRadius: '5px',
            backgroundColor: '#121212',
            boxShadow: 'inset 0px 4px 4px rgba(0, 0, 0, 0.25)',
            padding: '5px 0 5px 0',
            "& .MuiSvgIcon-root": {
              color: "rgba(135, 135, 135, 0.5)",
            },
            '&.Mui-disabled': {
             

              background: 'rgba(135, 135, 135, 0.5)',
            },
            
            "& input.Mui-disabled": {
              color: "white"
            }
            
            }}
            name = "time_zone"
            
            value={dataOfLocation.time_zone}
          label="Age"
          onChange={handleChangeBoot}
          disabled = {getDisableValue("146")}
          variant="filled"
          hiddenLabel
          MenuProps={MenuProps}>
            {TimeZoneList.map(menuItem => {
                  
                  return (
                    <MenuItem  sx={
                      {
                      
                      color:"#fff",
                      backgroundColor: "#2F323A",
                      borderBottom: '.5px solid grey',
                      fontFamily: "Lato",fontSize:'12px',
                  }}
                   value={menuItem}>
                      {menuItem}
                    </MenuItem>
                  );
                })}
          </Select>}
          {dataOfLocation.cam_brand == "ResidentLink" &&
          <FormControlLabel  sx={mainThemeStyle.popUpLable}
        control={<IOSSwitch sx={{ m: 1 }} name="last_abre" onChange={handlecheckboxCheck} disabled = {getDisableValue("146")} checked={dataOfLocation.last_abre == "1" ? true : false } />}
        label=""
      />}
      </Stack>
      </Grid>
      
     
      
      </Grid></Box>
      </>
     
)}


{(currentTab == 1 && dataOfLocation.cam_brand == "ResidentLink" && <>
<div  sx={{
       right: '54px' ,left: '54px',borderRadius:'0px'}}>
               <div style={{ display: 'flex' }}>
                   <Typography variant="h6" component="div" style={{  paddingLeft: '16px',paddingTop: '16px',flexGrow: 1,color:'#fff' }}>
                       Panel Settings
                   </Typography>
                   
               </div>
              
           </div>
           <Divider sx={{backgroundColor:'white'}} />
           <Box sx={{display: { xs: "none", md: "flex" }}}>
           <Grid container spacing={2} padding= '32px'> 
      <Grid item xs={2.5}>
        <Stack direction="column"  spacing={2}>
      <Typography sx={mainThemeStyle.popUpLable} height={40}> Scrolling Marquee </Typography>
      
      {/* <Typography sx={mainThemeStyle.popUpLable} height={40}> Serial Number </Typography> */}
      <Typography sx={mainThemeStyle.popUpLable} height={40}> Account Name </Typography>
      <Typography  sx={mainThemeStyle.popUpLable} height={40}>Button Descriptor </Typography>
      <Typography  sx={mainThemeStyle.popUpLable} height={40}>Account Address </Typography>
      <Typography  sx={mainThemeStyle.popUpLable} height={40}>City, State, ZIP </Typography>
      <Typography  sx={mainThemeStyle.popUpLable} height={40}>Text Alert Phone # </Typography>
      <Typography  sx={mainThemeStyle.popUpLable} height={40}>Displayed Phone # </Typography>
      <Typography  sx={mainThemeStyle.popUpLable} height={40}>Unlocking Mode </Typography>
      <Typography  sx={mainThemeStyle.popUpLable} height={40}>App User Unlock Mode</Typography>
      </Stack>
      </Grid>
      
      <Grid item xs={2.5}>
      <Stack direction="column"  spacing={2}>
      <TextField
                        id="input-welcome"
                        height={40}
                        type= 'text'
                        name="wel_message"
                        value={dataOfLocation.wel_message}
                        onChange={handleInputChange}
                        sx={mainThemeStyle.InputMenuItem}
                        InputLabelProps={{ shrink: true }
                      }
                      disabled = {getDisableValue("151")}
                      variant="filled"
                        InputProps={{
                           
                            sx: {
                                
                                color:'rgba(135, 135, 135, 0.5)',
                                height:'33px',
                                borderRadius: '5px',
                                backgroundColor: '#121212',
                                boxShadow: 'inset 0px 4px 4px rgba(0, 0, 0, 0.25)',
                                padding: '0 0 15px 0',
                                textIndent: '15px !important',
                              
                               
                                '&.Mui-disabled': {
             

                                  background: 'rgba(135, 135, 135, 0.5)',
                                },
                                
                                "& input.Mui-disabled": {
                                  color: "white"
                                }
                            },
                        }}
                       
                    />
                    
                    {/* <TextField
                        id="input-tablet-name"
                        height={40}
                        type= 'text'
                        name="tablet_name"
                        value={dataOfLocation.tablet_name}
                        onChange={handleInputChange}
                        variant="filled"
                        sx={mainThemeStyle.InputMenuItem}
                        InputLabelProps={{ shrink: true }
                      }
                          
                        InputProps={{
                           
                            sx: {
                                
                                color:'rgba(135, 135, 135, 0.5)',
                                height:'33px',
                                borderRadius: '5px',
                                backgroundColor: '#121212',
                                boxShadow: 'inset 0px 4px 4px rgba(0, 0, 0, 0.25)',
                                padding: '0 0 15px 0',
                                textIndent: '15px !important',
                              
                               
                                
                            },
                        }}
                       
                    /> */}
                    <TextField
                        id="input-property-name"
                        height={40}
                        type= 'text'
                        name="loc_property_name"
                        value={dataOfLocation.loc_property_name}
                        onChange={handleInputChange}
                        sx={mainThemeStyle.InputMenuItem}
                        InputLabelProps={{ shrink: true }
                      }
                      disabled = {getDisableValue("152")}
                      variant="filled"
                        InputProps={{
                           
                            sx: {
                                
                                color:'rgba(135, 135, 135, 0.5)',
                                height:'33px',
                                borderRadius: '5px',
                                backgroundColor: '#121212',
                                boxShadow: 'inset 0px 4px 4px rgba(0, 0, 0, 0.25)',
                                padding: '0 0 15px 0',
                                textIndent: '15px !important',
                                '&.Mui-disabled': {
             

                                  background: 'rgba(135, 135, 135, 0.5)',
                                },
                                
                                "& input.Mui-disabled": {
                                  color: "white"
                                }
                               
                                
                            },
                        }}
                       
                    />
                    <TextField
                        id="input-office-name"
                        height={40}
                        type= 'text'
                        name="loc_office_name"
                        value={dataOfLocation.loc_office_name}
                        onChange={handleInputChange}
                        sx={mainThemeStyle.InputMenuItem}
                        InputLabelProps={{ shrink: true }
                      }
                      disabled = {getDisableValue("153")}
                      variant="filled"
                        InputProps={{
                           
                            sx: {
                                
                                color:'rgba(135, 135, 135, 0.5)',
                                height:'33px',
                                borderRadius: '5px',
                                backgroundColor: '#121212',
                                boxShadow: 'inset 0px 4px 4px rgba(0, 0, 0, 0.25)',
                                padding: '0 0 15px 0',
                                textIndent: '15px !important',
                                '&.Mui-disabled': {
             

                                  background: 'rgba(135, 135, 135, 0.5)',
                                },
                                
                                "& input.Mui-disabled": {
                                  color: "white"
                                }
                               
                                
                            },
                        }}
                       
                    />
                    <TextField
                        id="input-address-one"
                        height={40}
                        type= 'text'
                        name="loc_add_one"
                        value={dataOfLocation.loc_add_one}
                        onChange={handleInputChange}
                        sx={mainThemeStyle.InputMenuItem}
                        InputLabelProps={{ shrink: true }
                      }
                      disabled = {getDisableValue("154")}
                      variant="filled"
                        InputProps={{
                           
                            sx: {
                                
                                color:'rgba(135, 135, 135, 0.5)',
                                height:'33px',
                                borderRadius: '5px',
                                backgroundColor: '#121212',
                                boxShadow: 'inset 0px 4px 4px rgba(0, 0, 0, 0.25)',
                                padding: '0 0 15px 0',
                                textIndent: '15px !important',
                              
                                '&.Mui-disabled': {
             

                                  background: 'rgba(135, 135, 135, 0.5)',
                                },
                                
                                "& input.Mui-disabled": {
                                  color: "white"
                                }
                                
                            },
                        }}
                       
                    />
                    <TextField
                        id="input-address-two"
                        height={40}
                        type= 'text'
                        name="loc_add_two"
                        value={dataOfLocation.loc_add_two}
                        onChange={handleInputChange}
                        sx={mainThemeStyle.InputMenuItem}
                        InputLabelProps={{ shrink: true }
                      }
                      disabled = {getDisableValue("155")}
                      variant="filled"
                        InputProps={{
                           
                            sx: {
                                
                                color:'rgba(135, 135, 135, 0.5)',
                                height:'33px',
                                borderRadius: '5px',
                                backgroundColor: '#121212',
                                boxShadow: 'inset 0px 4px 4px rgba(0, 0, 0, 0.25)',
                                padding: '0 0 15px 0',
                                textIndent: '15px !important',
                              
                                '&.Mui-disabled': {
             

                                  background: 'rgba(135, 135, 135, 0.5)',
                                },
                                
                                "& input.Mui-disabled": {
                                  color: "white"
                                }
                                
                            },
                        }}
                       
                    />
                       <TextField
                        id="input-sms-alert"
                        height={40}
                        type= 'text'
                        name="warning_phone"
                        value={dataOfLocation.warning_phone}
                        onChange={handleInputChange}
                        sx={mainThemeStyle.InputMenuItem}
                        InputLabelProps={{ shrink: true }
                      }
                      disabled = {getDisableValue("156")}
                      variant="filled"
                        InputProps={{
                           
                            sx: {
                                
                                color:'rgba(135, 135, 135, 0.5)',
                                height:'33px',
                                borderRadius: '5px',
                                backgroundColor: '#121212',
                                boxShadow: 'inset 0px 4px 4px rgba(0, 0, 0, 0.25)',
                                padding: '0 0 15px 0',
                                textIndent: '15px !important',
                                '&.Mui-disabled': {
             

                                  background: 'rgba(135, 135, 135, 0.5)',
                                },
                                
                                "& input.Mui-disabled": {
                                  color: "white"
                                }
                               
                                
                            },
                        }}
                       
                    />
                     <TextField
                        id="input-panel-telephone"
                        height={40}
                        type= 'text'
                        name="loc_telephone_dial"
                        value={dataOfLocation.loc_telephone_dial}
                        onChange={handleInputChange}
                        sx={mainThemeStyle.InputMenuItem}
                        InputLabelProps={{ shrink: true }
                      }
                      disabled = {getDisableValue("157")}
                      variant="filled"
                        InputProps={{
                           
                            sx: {
                                
                                color:'rgba(135, 135, 135, 0.5)',
                                height:'33px',
                                borderRadius: '5px',
                                backgroundColor: '#121212',
                                boxShadow: 'inset 0px 4px 4px rgba(0, 0, 0, 0.25)',
                                padding: '0 0 15px 0',
                                textIndent: '15px !important',
                                '&.Mui-disabled': {
             

                                  background: 'rgba(135, 135, 135, 0.5)',
                                },
                                
                                "& input.Mui-disabled": {
                                  color: "white"
                                }
                               
                                
                            },
                        }}
                       
                    />
                     <Select
            sx={{ color: 'rgba(135, 135, 135, 0.5)',
            height:'33px',
            marginTop:'4px',
            marginBottom:'3px',
            borderRadius: '5px',
            backgroundColor: '#121212',
            boxShadow: 'inset 0px 4px 4px rgba(0, 0, 0, 0.25)',
            padding: '5px 0 5px 0',
            "& .MuiSvgIcon-root": {
              color: "rgba(135, 135, 135, 0.5)",
            },
            
            '&.Mui-disabled': {
             

              background: 'rgba(135, 135, 135, 0.5)',
            },
            "& input.Mui-disabled": {
              color: "white"
            }
            }}
            name = "unlocking_mode"
            disabled = {getDisableValue("158")}
            value={GetUnlockingMode()}
          label="Age"
          onChange={handleSetUnlockingMode}
          variant="filled"
          hiddenLabel
          MenuProps={MenuProps}>
            {UnlcokingMode.items.map(menuItem => {
                  
                  return (
                    <MenuItem  sx={
                      {
                      
                      color:"#fff",
                      backgroundColor: "#2F323A",
                      borderBottom: '.5px solid grey',
                      fontFamily: "Lato",fontSize:'12px',
                  }}
                   value={menuItem.label}>
                      {menuItem.label}
                    </MenuItem>
                  );
                })}
          </Select>
          <Select
            sx={{ color: 'rgba(135, 135, 135, 0.5)',
            height:'33px',
            marginTop:'4px',
            marginBottom:'3px',
            borderRadius: '5px',
            backgroundColor: '#121212',
            boxShadow: 'inset 0px 4px 4px rgba(0, 0, 0, 0.25)',
            padding: '5px 0 5px 0',
            "& .MuiSvgIcon-root": {
              color: "rgba(135, 135, 135, 0.5)",
            },
            
            '&.Mui-disabled': {
             

              background: 'rgba(135, 135, 135, 0.5)',
            },
            "& input.Mui-disabled": {
              color: "white"
            }
            }}
            name = "app_unlock_mode"
            disabled = {getDisableValue("159")}
            value={GetAppUnlockMode()}
          label="Age"
          onChange={handleSetAppUnlockMode}
          variant="filled"
          hiddenLabel
          MenuProps={MenuProps}>
            {AppUserUnlockMode.items.map(menuItem => {
                  
                  return (
                    <MenuItem  sx={
                      {
                      
                      color:"#fff",
                      backgroundColor: "#2F323A",
                      borderBottom: '.5px solid grey',
                      fontFamily: "Lato",fontSize:'12px',
                  }}
                   value={menuItem.label}>
                      {menuItem.label}
                    </MenuItem>
                  );
                })}
          </Select>
     
      </Stack>
      </Grid>
      <Grid item xs={1}>
    
      </Grid>
      <Grid item xs={2.5}>
        <Stack direction="column"  spacing={2}>
        {(token.issub === true && token.name === 'SUPER ADMIN') ?
      
      <Typography  sx={mainThemeStyle.popUpLable} height={40}>URL for Watermark Logo </Typography>
                       
                    : <></>}

       
      <Typography  sx={mainThemeStyle.popUpLable} height={40}>Refresh Rate(In minutes) </Typography>
      <Typography  sx={mainThemeStyle.popUpLable} height={40}>Setting Refresh Rate(In minutes) </Typography>
      <Typography  sx={mainThemeStyle.popUpLable} height={40}>Screen Layout </Typography>
      <Typography sx={mainThemeStyle.popUpLable} height={40}>Enable Office Button</Typography>
      <Typography  sx={mainThemeStyle.popUpLable} height={40}>Enable Directory Button </Typography>
      <Typography  sx={mainThemeStyle.popUpLable} height={40}>Enable Door PIN Button </Typography>
      <Typography  sx={mainThemeStyle.popUpLable} height={40}>Enable Delivery PIN Button </Typography>
      
      
      </Stack>
      </Grid>
      <Grid item xs={2.5}>
        <Stack direction="column"  spacing={2}>
         
       {(token.issub === true && token.name === 'SUPER ADMIN') ?
      
      <TextField
                        id="input-location"
                        height={40}
                        type= 'text'
                        name="loc_url_watermark"
                        value={dataOfLocation.loc_url_watermark}
                        onChange={handleInputChange}
                        sx={mainThemeStyle.InputMenuItem}
                        InputLabelProps={{ shrink: true }
                       
                      }
                      disabled = {getDisableValue("160")}
                      variant="filled"
                        InputProps={{
                           
                            sx: {
                                
                                color:'rgba(135, 135, 135, 0.5)',
                                height:'33px',
                                borderRadius: '5px',
                                backgroundColor: '#121212',
                                boxShadow: 'inset 0px 4px 4px rgba(0, 0, 0, 0.25)',
                                padding: '0 0 15px 0',
                                textIndent: '15px !important',
                                '&.Mui-disabled': {
             

                                  background: 'rgba(135, 135, 135, 0.5)',
                                },
                                
                                "& input.Mui-disabled": {
                                  color: "white"
                                }
                              
                               
                                
                            },
                        }}
                       
                    /> : <></>}
      <TextField
                        id="input-location"
                        height={40}
                        type= 'number'
                        name="loc_refresh_rate"
                        value={dataOfLocation.loc_refresh_rate}
                        onChange={handleInputChange}
                        sx={mainThemeStyle.InputMenuItem}
                        InputLabelProps={{ shrink: true }
                      }
                      disabled = {getDisableValue("161")}
                      variant="filled"
                        InputProps={{
                           
                            sx: {
                                
                                color:'rgba(135, 135, 135, 0.5)',
                                height:'33px',
                                borderRadius: '5px',
                                backgroundColor: '#121212',
                                boxShadow: 'inset 0px 4px 4px rgba(0, 0, 0, 0.25)',
                                padding: '0 0 15px 0',
                                textIndent: '15px !important',
                              
                                '&.Mui-disabled': {
             

                                  background: 'rgba(135, 135, 135, 0.5)',
                                },
                                
                                "& input.Mui-disabled": {
                                  color: "white"
                                }
                                
                            },
                        }}
                       
                    />
                    <TextField
                        id="input-location"
                        height={40}
                        type= 'number'
                        name="setting_reftesh_rate"
                        value={dataOfLocation.setting_reftesh_rate}
                        onChange={handleInputChange}
                        sx={mainThemeStyle.InputMenuItem}
                        InputLabelProps={{ shrink: true }
                      }
                      disabled = {getDisableValue("162")}
                      variant="filled"
                        InputProps={{
                           
                            sx: {
                                
                                color:'rgba(135, 135, 135, 0.5)',
                                height:'33px',
                                borderRadius: '5px',
                                backgroundColor: '#121212',
                                boxShadow: 'inset 0px 4px 4px rgba(0, 0, 0, 0.25)',
                                padding: '0 0 15px 0',
                                textIndent: '15px !important',
                                '&.Mui-disabled': {
             

                                  background: 'rgba(135, 135, 135, 0.5)',
                                },
                                
                                "& input.Mui-disabled": {
                                  color: "white"
                                }
                               
                                
                            },
                        }}
                       
                    />
                    <Select
            sx={{ color: 'rgba(135, 135, 135, 0.5)',
            height:'33px',
            marginTop:'4px',
            marginBottom:'3px',
            borderRadius: '5px',
            backgroundColor: '#121212',
            boxShadow: 'inset 0px 4px 4px rgba(0, 0, 0, 0.25)',
            padding: '5px 0 5px 0',
            "& .MuiSvgIcon-root": {
              color: "rgba(135, 135, 135, 0.5)",
            },
            
            '&.Mui-disabled': {
             

              background: 'rgba(135, 135, 135, 0.5)',
            },
            "& input.Mui-disabled": {
              color: "white"
            }
            }}
            name = "loc_screen_layout"
            disabled
            value={GetValueOfLocationScreenLayout(dataOfLocation.loc_screen_layout)}
          label="Age"
          onChange={ChangeScreenLayout}
          variant="filled"
          hiddenLabel
          MenuProps={MenuProps}>
            {ScreenLayout.items.map(menuItem => {
                  
                  return (
                    <MenuItem  sx={
                      {
                      
                      color:"#fff",
                      backgroundColor: "#2F323A",
                      borderBottom: '.5px solid grey',
                      fontFamily: "Lato",fontSize:'12px',
                  }}
                   value={menuItem.label}>
                      {menuItem.label}
                    </MenuItem>
                  );
                })}
          </Select>
          <FormControlLabel  sx={mainThemeStyle.popUpLable}
        control={<IOSSwitch sx={{ m: 1 }} name="dis_off" onChange={handlecheckboxCheckReverse} disabled = {getDisableValue("163")} checked={dataOfLocation.dis_off == "0" ? true : false } />}
        label=""
      />
      <FormControlLabel  sx={mainThemeStyle.popUpLable}
        control={<IOSSwitch sx={{ m: 1 }} name="dis_dir" onChange={handlecheckboxCheckReverse} disabled = {getDisableValue("164")} checked={dataOfLocation.dis_dir == "0" ? true : false } />}
        label=""
      />
      <FormControlLabel  sx={mainThemeStyle.popUpLable}
        control={<IOSSwitch sx={{ m: 1 }} name="dis_doorpin" onChange={handlecheckboxCheckReverse} disabled = {getDisableValue("165")} checked={dataOfLocation.dis_doorpin == "0" ? true : false } />}
        label=""
      />
      <FormControlLabel  sx={mainThemeStyle.popUpLable}
        control={<IOSSwitch sx={{ m: 1 }} name="dis_del" onChange={handlecheckboxCheckReverse} disabled = {getDisableValue("166")} checked={dataOfLocation.dis_del == "0" ? true : false } />}
        label=""
      />
                    {/* <Checkbox  sx={mainThemeStyle.formCheckBox} name="dis_off" onChange={handlecheckboxCheck} checked={dataOfLocation.dis_off == "1" ? true : false} />
                    <Checkbox sx={mainThemeStyle.formCheckBox} name="dis_dir" onChange={handlecheckboxCheck} checked={dataOfLocation.dis_dir  == "1" ? true : false}/>
                    <Checkbox sx={mainThemeStyle.formCheckBox} name="dis_doorpin" onChange={handlecheckboxCheck} checked={dataOfLocation.dis_doorpin  == "1" ? true : false}/>
                    <Checkbox sx={mainThemeStyle.formCheckBox} name="dis_del" onChange={handlecheckboxCheck} checked={dataOfLocation.dis_del  == "1" ? true : false}/>
                    */}
      </Stack>
      </Grid>
     
      
      </Grid></Box>
      <Box sx={{display: { xs: "flex", md: "none" }}}>
           <Grid container spacing={2} padding= '10px' paddingBottom='32px' paddingTop='32px'> 
      <Grid item xs={5}>
        <Stack direction="column"  spacing={2}>
      <Typography sx={mainThemeStyle.popUpLable} height={40}> Scrolling Marquee </Typography>
      <Typography sx={mainThemeStyle.popUpLable} height={40}> Account Name </Typography>
      <Typography  sx={mainThemeStyle.popUpLable} height={40}>Button Descriptor </Typography>
      <Typography  sx={mainThemeStyle.popUpLable} height={40}>Account Address </Typography>
      <Typography  sx={mainThemeStyle.popUpLable} height={40}>City, State, ZIP </Typography>
      <Typography  sx={mainThemeStyle.popUpLable} height={40}>Text Alert Phone # </Typography>
      <Typography  sx={mainThemeStyle.popUpLable} height={40}>Displayed Phone # </Typography>
      <Typography  sx={mainThemeStyle.popUpLable} height={40}>Unlocking Mode </Typography>
      <Typography  sx={mainThemeStyle.popUpLable} height={40}>App Unlock Mode</Typography>
     {/*  <Typography  sx={mainThemeStyle.popUpLable} height={40}>Watermark URL </Typography> */}
      {(token.issub === true && token.name === 'SUPER ADMIN') ?
      
      <Typography  sx={mainThemeStyle.popUpLable} height={40}>Watermark URL </Typography>
                       
                    : <></>}
      <Typography  sx={mainThemeStyle.popUpLable} height={40}>Refresh Rate</Typography>
      <Typography  sx={mainThemeStyle.popUpLable} height={40}>Setting Refresh Rate</Typography>
      <Typography  sx={mainThemeStyle.popUpLable} height={40}>Screen Layout </Typography>
      <Typography sx={mainThemeStyle.popUpLable} height={40}>Office Button</Typography>
      <Typography  sx={mainThemeStyle.popUpLable} height={40}>Directory Button </Typography>
      <Typography  sx={mainThemeStyle.popUpLable} height={40}>Door PIN Button </Typography>
      <Typography  sx={mainThemeStyle.popUpLable} height={40}>Dilevery PIN Button </Typography>
      </Stack>
      </Grid>
      
      <Grid item xs={7}>
      <Stack direction="column"  spacing={2}>
      <TextField
                        id="input-welcome"
                        height={40}
                        type= 'text'
                        name="wel_message"
                        value={dataOfLocation.wel_message}
                        onChange={handleInputChange}
                        sx={mainThemeStyle.InputMenuItem}
                        InputLabelProps={{ shrink: true }
                      }
                      disabled = {getDisableValue("151")}
                      variant="filled"
                        InputProps={{
                           
                            sx: {
                                
                                color:'rgba(135, 135, 135, 0.5)',
                                height:'33px',
                                borderRadius: '5px',
                                backgroundColor: '#121212',
                                boxShadow: 'inset 0px 4px 4px rgba(0, 0, 0, 0.25)',
                                padding: '0 0 15px 0',
                                textIndent: '15px !important',
                              
                                '&.Mui-disabled': {
             

                                  background: 'rgba(135, 135, 135, 0.5)',
                                },
                                
                                "& input.Mui-disabled": {
                                  color: "white"
                                }
                                
                            },
                        }}
                       
                    />
                    
                 
                    <TextField
                        id="input-property-name"
                        height={40}
                        type= 'text'
                        name="loc_property_name"
                        value={dataOfLocation.loc_property_name}
                        onChange={handleInputChange}
                        sx={mainThemeStyle.InputMenuItem}
                        InputLabelProps={{ shrink: true }
                      }
                      disabled = {getDisableValue("152")}
                      variant="filled"
                        InputProps={{
                           
                            sx: {
                                
                                color:'rgba(135, 135, 135, 0.5)',
                                height:'33px',
                                borderRadius: '5px',
                                backgroundColor: '#121212',
                                boxShadow: 'inset 0px 4px 4px rgba(0, 0, 0, 0.25)',
                                padding: '0 0 15px 0',
                                textIndent: '15px !important',
                                '&.Mui-disabled': {
             

                                  background: 'rgba(135, 135, 135, 0.5)',
                                },
                                
                                "& input.Mui-disabled": {
                                  color: "white"
                                }
                               
                                
                            },
                        }}
                       
                    />
                    <TextField
                        id="input-office-name"
                        height={40}
                        type= 'text'
                        name="loc_office_name"
                        value={dataOfLocation.loc_office_name}
                        onChange={handleInputChange}
                        sx={mainThemeStyle.InputMenuItem}
                        InputLabelProps={{ shrink: true }
                      }
                      disabled = {getDisableValue("153")}
                      variant="filled"
                        InputProps={{
                           
                            sx: {
                                
                                color:'rgba(135, 135, 135, 0.5)',
                                height:'33px',
                                borderRadius: '5px',
                                backgroundColor: '#121212',
                                boxShadow: 'inset 0px 4px 4px rgba(0, 0, 0, 0.25)',
                                padding: '0 0 15px 0',
                                textIndent: '15px !important',
                              
                               
                                '&.Mui-disabled': {
             

                                  background: 'rgba(135, 135, 135, 0.5)',
                                },
                                
                                "& input.Mui-disabled": {
                                  color: "white"
                                }
                            },
                        }}
                       
                    />
                    <TextField
                        id="input-address-one"
                        height={40}
                        type= 'text'
                        name="loc_add_one"
                        value={dataOfLocation.loc_add_one}
                        onChange={handleInputChange}
                        sx={mainThemeStyle.InputMenuItem}
                        InputLabelProps={{ shrink: true }
                      }
                      disabled = {getDisableValue("154")}
                      variant="filled"
                        InputProps={{
                           
                            sx: {
                                
                                color:'rgba(135, 135, 135, 0.5)',
                                height:'33px',
                                borderRadius: '5px',
                                backgroundColor: '#121212',
                                boxShadow: 'inset 0px 4px 4px rgba(0, 0, 0, 0.25)',
                                padding: '0 0 15px 0',
                                textIndent: '15px !important',
                              
                                '&.Mui-disabled': {
             

                                  background: 'rgba(135, 135, 135, 0.5)',
                                },
                                
                                "& input.Mui-disabled": {
                                  color: "white"
                                }
                                
                            },
                        }}
                       
                    />
                    <TextField
                        id="input-address-two"
                        height={40}
                        type= 'text'
                        name="loc_add_two"
                        value={dataOfLocation.loc_add_two}
                        onChange={handleInputChange}
                        sx={mainThemeStyle.InputMenuItem}
                        InputLabelProps={{ shrink: true }
                      }
                      disabled = {getDisableValue("155")}
                      variant="filled"
                        InputProps={{
                           
                            sx: {
                                
                                color:'rgba(135, 135, 135, 0.5)',
                                height:'33px',
                                borderRadius: '5px',
                                backgroundColor: '#121212',
                                boxShadow: 'inset 0px 4px 4px rgba(0, 0, 0, 0.25)',
                                padding: '0 0 15px 0',
                                textIndent: '15px !important',
                              
                                '&.Mui-disabled': {
             

                                  background: 'rgba(135, 135, 135, 0.5)',
                                },
                                
                                "& input.Mui-disabled": {
                                  color: "white"
                                }
                                
                            },
                        }}
                       
                    />
                       <TextField
                        id="input-sms-alert"
                        height={40}
                        type= 'text'
                        name="warning_phone"
                        value={dataOfLocation.warning_phone}
                        onChange={handleInputChange}
                        sx={mainThemeStyle.InputMenuItem}
                        InputLabelProps={{ shrink: true }
                      }
                      disabled = {getDisableValue("156")}
                      variant="filled"
                        InputProps={{
                           
                            sx: {
                                
                                color:'rgba(135, 135, 135, 0.5)',
                                height:'33px',
                                borderRadius: '5px',
                                backgroundColor: '#121212',
                                boxShadow: 'inset 0px 4px 4px rgba(0, 0, 0, 0.25)',
                                padding: '0 0 15px 0',
                                textIndent: '15px !important',
                                '&.Mui-disabled': {
             

                                  background: 'rgba(135, 135, 135, 0.5)',
                                },
                                
                                "& input.Mui-disabled": {
                                  color: "white"
                                }
                               
                                
                            },
                        }}
                       
                    />
                     <TextField
                        id="input-panel-telephone"
                        height={40}
                        type= 'text'
                        name="loc_telephone_dial"
                        value={dataOfLocation.loc_telephone_dial}
                        onChange={handleInputChange}
                        sx={mainThemeStyle.InputMenuItem}
                        InputLabelProps={{ shrink: true }
                      }
                      disabled = {getDisableValue("157")}
                      variant="filled"
                        InputProps={{
                           
                            sx: {
                                
                                color:'rgba(135, 135, 135, 0.5)',
                                height:'33px',
                                borderRadius: '5px',
                                backgroundColor: '#121212',
                                boxShadow: 'inset 0px 4px 4px rgba(0, 0, 0, 0.25)',
                                padding: '0 0 15px 0',
                                textIndent: '15px !important',
                                '&.Mui-disabled': {
             

                                  background: 'rgba(135, 135, 135, 0.5)',
                                },
                                
                                "& input.Mui-disabled": {
                                  color: "white"
                                }
                               
                                
                            },
                        }}
                       
                    />
                     <Select
            sx={{ color: 'rgba(135, 135, 135, 0.5)',
            height:'33px',
            marginTop:'4px',
            marginBottom:'13px',
            borderRadius: '5px',
            backgroundColor: '#121212',
            boxShadow: 'inset 0px 4px 4px rgba(0, 0, 0, 0.25)',
            padding: '5px 0 5px 0',
            "& .MuiSvgIcon-root": {
              color: "rgba(135, 135, 135, 0.5)",
            },
            
            '&.Mui-disabled': {
             

              background: 'rgba(135, 135, 135, 0.5)',
            },
            "& input.Mui-disabled": {
              color: "white"
            }
            }}
            // disabled = {getDisableValue("158")}
            name = "unlocking_mode"
            disabled
            value={GetUnlockingMode()}
          label="Age"
          onChange={handleSetUnlockingMode}
          variant="filled"
          hiddenLabel
          MenuProps={MenuProps}>
            {UnlcokingMode.items.map(menuItem => {
                  
                  return (
                    <MenuItem  sx={
                      {
                      
                      color:"#fff",
                      backgroundColor: "#2F323A",
                      borderBottom: '.5px solid grey',
                      fontFamily: "Lato",fontSize:'12px',
                  }}
                   value={menuItem.label}>
                      {menuItem.label}
                    </MenuItem>
                  );
                })}
          </Select>
          <Select
            sx={{ color: 'rgba(135, 135, 135, 0.5)',
            height:'33px',
            marginTop:'4px',
            marginBottom:'3px',
            borderRadius: '5px',
            backgroundColor: '#121212',
            boxShadow: 'inset 0px 4px 4px rgba(0, 0, 0, 0.25)',
            padding: '5px 0 5px 0',
            "& .MuiSvgIcon-root": {
              color: "rgba(135, 135, 135, 0.5)",
            },
            
            '&.Mui-disabled': {
             

              background: 'rgba(135, 135, 135, 0.5)',
            },
            "& input.Mui-disabled": {
              color: "white"
            }
            }}
            name = "app_unlock_mode"
            disabled = {getDisableValue("159")}
            value={GetAppUnlockMode()}
          label="Age"
          onChange={handleSetAppUnlockMode}
          variant="filled"
          hiddenLabel
          MenuProps={MenuProps}>
            {AppUserUnlockMode.items.map(menuItem => {
                  
                  return (
                    <MenuItem  sx={
                      {
                      
                      color:"#fff",
                      backgroundColor: "#2F323A",
                      borderBottom: '.5px solid grey',
                      fontFamily: "Lato",fontSize:'12px',
                  }}
                   value={menuItem.label}>
                      {menuItem.label}
                    </MenuItem>
                  );
                })}
          </Select>
          {/* <TextField
                        id="input-location"
                        height={40}
                        type= 'text'
                        name="loc_url_watermark"
                        value={dataOfLocation.loc_url_watermark}
                        onChange={handleInputChange}
                        sx={mainThemeStyle.InputMenuItem}
                        InputLabelProps={{ shrink: true }
                      }
                      variant="filled"
                        InputProps={{
                           
                            sx: {
                                marginTop:'14px',
                                color:'rgba(135, 135, 135, 0.5)',
                                height:'33px',
                                borderRadius: '5px',
                                backgroundColor: '#121212',
                                boxShadow: 'inset 0px 4px 4px rgba(0, 0, 0, 0.25)',
                                padding: '0 0 15px 0',
                                textIndent: '15px !important',
                              
                               
                                
                            },
                        }}
                       
                    /> */}
                      {(token.issub === true && token.name === 'SUPER ADMIN') ?
      
      <TextField
                        id="input-location"
                        height={40}
                        type= 'text'
                        name="loc_url_watermark"
                        value={dataOfLocation.loc_url_watermark}
                        onChange={handleInputChange}
                        sx={mainThemeStyle.InputMenuItem}
                        InputLabelProps={{ shrink: true }
                       
                      }
                      disabled = {getDisableValue("160")}
                      variant="filled"
                        InputProps={{
                           
                            sx: {
                                
                                color:'rgba(135, 135, 135, 0.5)',
                                height:'33px',
                                borderRadius: '5px',
                                backgroundColor: '#121212',
                                boxShadow: 'inset 0px 4px 4px rgba(0, 0, 0, 0.25)',
                                padding: '0 0 15px 0',
                                textIndent: '15px !important',
                                '&.Mui-disabled': {
             

                                  background: 'rgba(135, 135, 135, 0.5)',
                                },
                                
                                "& input.Mui-disabled": {
                                  color: "white"
                                }
                              
                               
                                
                            },
                        }}
                       
                    /> : <img  src={dataOfLocation.loc_url_watermark} height={40} style={{width:"100%",'object-fit': 'contain'}}></img>}

                    <Typography></Typography>
      <TextField
                        id="input-location"
                        height={40}
                        type= 'number'
                        name="loc_refresh_rate"
                        value={dataOfLocation.loc_refresh_rate}
                        onChange={handleInputChange}
                        sx={mainThemeStyle.InputMenuItem}
                        InputLabelProps={{ shrink: true }
                      }
                      disabled = {getDisableValue("161")}
                      variant="filled"
                        InputProps={{
                           
                            sx: {
                                
                                color:'rgba(135, 135, 135, 0.5)',
                                height:'33px',
                                borderRadius: '5px',
                                backgroundColor: '#121212',
                                boxShadow: 'inset 0px 4px 4px rgba(0, 0, 0, 0.25)',
                                padding: '0 0 15px 0',
                                textIndent: '15px !important',
                              
                                '&.Mui-disabled': {
             

                                  background: 'rgba(135, 135, 135, 0.5)',
                                },
                                
                                "& input.Mui-disabled": {
                                  color: "white"
                                }
                                
                            },
                        }}
                       
                    />
                    <TextField
                        id="input-location"
                        height={40}
                        type= 'number'
                        name="setting_reftesh_rate"
                        value={dataOfLocation.setting_reftesh_rate}
                        onChange={handleInputChange}
                        sx={mainThemeStyle.InputMenuItem}
                        InputLabelProps={{ shrink: true }
                      }
                      disabled = {getDisableValue("162")}
                      variant="filled"
                        InputProps={{
                           
                            sx: {
                                
                                color:'rgba(135, 135, 135, 0.5)',
                                height:'33px',
                                borderRadius: '5px',
                                backgroundColor: '#121212',
                                boxShadow: 'inset 0px 4px 4px rgba(0, 0, 0, 0.25)',
                                padding: '0 0 15px 0',
                                textIndent: '15px !important',
                                '&.Mui-disabled': {
             

                                  background: 'rgba(135, 135, 135, 0.5)',
                                },
                                
                                "& input.Mui-disabled": {
                                  color: "white"
                                }
                               
                                
                            },
                        }}
                       
                    />
                    <Select
            sx={{ color: 'rgba(135, 135, 135, 0.5)',
            height:'33px',
            marginTop:'4px',
            marginBottom:'3px',
            borderRadius: '5px',
            backgroundColor: '#121212',
            boxShadow: 'inset 0px 4px 4px rgba(0, 0, 0, 0.25)',
            padding: '5px 0 5px 0',
            "& .MuiSvgIcon-root": {
              color: "rgba(135, 135, 135, 0.5)",
            },
            
            '&.Mui-disabled': {
             

              background: 'rgba(135, 135, 135, 0.5)',
            },
            "& input.Mui-disabled": {
              color: "white"
            }
            }}
            name = "loc_screen_layout"
            disabled
            value={GetValueOfLocationScreenLayout(dataOfLocation.loc_screen_layout)}
          label="Age"
          onChange={ChangeScreenLayout}
          variant="filled"
          hiddenLabel
          MenuProps={MenuProps}>
            {ScreenLayout.items.map(menuItem => {
                  
                  return (
                    <MenuItem  sx={
                      {
                      
                      color:"#fff",
                      backgroundColor: "#2F323A",
                      borderBottom: '.5px solid grey',
                      fontFamily: "Lato",fontSize:'12px',
                  }}
                   value={menuItem.label}>
                      {menuItem.label}
                    </MenuItem>
                  );
                })}
          </Select>
          <FormControlLabel  sx={mainThemeStyle.popUpLable}
        control={<IOSSwitch sx={{ m: 1 }} name="dis_off" onChange={handlecheckboxCheckReverse}  disabled = {getDisableValue("163")} checked={dataOfLocation.dis_off == "0" ? true : false } />}
        label=""
      />
      <FormControlLabel  sx={mainThemeStyle.popUpLable}
        control={<IOSSwitch sx={{ m: 1 }} name="dis_dir" onChange={handlecheckboxCheckReverse} disabled = {getDisableValue("164")} checked={dataOfLocation.dis_dir == "0" ? true : false } />}
        label=""
      />
      <FormControlLabel  sx={mainThemeStyle.popUpLable}
        control={<IOSSwitch sx={{ m: 1 }} name="dis_doorpin" onChange={handlecheckboxCheckReverse} disabled = {getDisableValue("165")}  checked={dataOfLocation.dis_doorpin == "0" ? true : false } />}
        label=""
      />
      <FormControlLabel  sx={mainThemeStyle.popUpLable}
        control={<IOSSwitch sx={{ m: 1 }} name="dis_del" onChange={handlecheckboxCheckReverse} disabled = {getDisableValue("166")} checked={dataOfLocation.dis_del == "0" ? true : false } />}
        label=""
      />
      </Stack>
      </Grid>
     
     
      
     
      
      </Grid></Box>
</>)}

{(currentTab == 1 && dataOfLocation.cam_brand == "Virtual Panel" &&<>
<div  sx={{
       right: '54px' ,left: '54px',borderRadius:'0px'}}>
               <div style={{ display: 'flex' }}>
                   <Typography variant="h6" component="div" style={{  paddingLeft: '16px',paddingTop: '16px',flexGrow: 1,color:'#fff' }}>
                   QR Entry
                   </Typography>
                   
               </div>
              
           </div>
           <Divider sx={{backgroundColor:'white'}} />
           <Box sx={{display: { xs: "none", md: "flex" }}}>
           <Grid container spacing={2} padding= '32px'> 
      <Grid item xs={2.5}>
        <Stack direction="column"  spacing={2}>
        <Typography sx={mainThemeStyle.popUpLable} height={41}> Displayed Heading </Typography>
        <Typography  sx={mainThemeStyle.popUpLable} height={40}>Displayed Heading Custom Color </Typography>
        <Typography  sx={mainThemeStyle.popUpLable} height={40}>Displayed Icon </Typography>
      <Typography sx={mainThemeStyle.popUpLable} height={41}> Displayed Message </Typography>
     {/*  <Typography sx={mainThemeStyle.popUpLable} height={40}> Serial Number </Typography> */}
      <Typography sx={mainThemeStyle.popUpLable} height={41}> Account Name </Typography>
      <Typography  sx={mainThemeStyle.popUpLable} height={41}>Button Descriptor </Typography>
      <Typography  sx={mainThemeStyle.popUpLable} height={41}>Account Address </Typography>
      <Typography  sx={mainThemeStyle.popUpLable} height={41}>City, State, ZIP </Typography>
      
      <Typography  sx={mainThemeStyle.popUpLable} height={41}>Displayed Phone# </Typography>
      {/* <Typography  sx={mainThemeStyle.popUpLable} height={40}>App User Unlock Mode</Typography> */}
      {/* <Typography  sx={mainThemeStyle.popUpLable} height={40}>Unlocking Mode </Typography> */}
      <Typography  sx={mainThemeStyle.popUpLable} height={41}>App User Unlock Mode</Typography>
      </Stack>
      </Grid>
      
      <Grid item xs={2.5}>
      <Stack direction="column"  spacing={2}>
      <TextField
                        id="wel_message_header"
                        height={40}
                        type= 'text'
                        name="wel_message_header"
                        value={dataOfLocation.wel_message_header}
                        onChange={handleInputChange}
                        sx={mainThemeStyle.InputMenuItem}
                        InputLabelProps={{ shrink: true }
                      }
                      disabled = {getDisableValue("167")}
                      variant="filled"
                        InputProps={{
                           
                            sx: {
                                
                                color:'rgba(135, 135, 135, 0.5)',
                                height:'33px',
                                borderRadius: '5px',
                                backgroundColor: '#121212',
                                boxShadow: 'inset 0px 4px 4px rgba(0, 0, 0, 0.25)',
                                padding: '0 0 15px 0',
                                textIndent: '15px !important',
                              
                                '&.Mui-disabled': {
             

                                  background: 'rgba(135, 135, 135, 0.5)',
                                },
                                
                                "& input.Mui-disabled": {
                                  color: "white"
                                }
                                
                            },
                        }}
                       
                    />
                    <TextField
                        id="input-location"
                        height={40}
                        type= 'color'
                        name="wel_message_headercolor"
                        value={dataOfLocation.wel_message_headercolor}
                        onChange={handleInputChange}
                        sx={mainThemeStyle.InputMenuItem}
                        InputLabelProps={{ shrink: true }
                       
                      }
                      disabled = {getDisableValue("168")}
                      variant="filled"
                        InputProps={{
                           
                            sx: {
                                
                                color:'rgba(255, 255, 255, 0.5)',
                                height:'33px',
                                borderRadius: '5px',
                                backgroundColor: '#121212',
                                boxShadow: 'inset 0px 4px 4px rgba(0, 0, 0, 0.25)',
                                padding: '0 0 15px 0',
                                textIndent: '15px !important',
                              
                              
                                '&.Mui-disabled': {
             

                                  background: 'rgba(135, 135, 135, 0.5)',
                                },
                                
                                "& input.Mui-disabled": {
                                  color: "white"
                                }
                                
                            },
                        }}
                       
                    />
                    <Select
            sx={{ color: 'rgba(135, 135, 135, 0.5)',
            height:'33px',
            marginTop:'4px',
            marginBottom:'3px',
            borderRadius: '5px',
            backgroundColor: '#121212',
            boxShadow: 'inset 0px 4px 4px rgba(0, 0, 0, 0.25)',
            padding: '5px 0 5px 0',
            "& .MuiSvgIcon-root": {
              color: "rgba(135, 135, 135, 0.5)",
            },
            '&.Mui-disabled': {
             

              background: 'rgba(135, 135, 135, 0.5)',
            },
            
            "& input.Mui-disabled": {
              color: "white"
            }
            }}
            name = "wel_message_headerlogo"
            value={getDisplayedLogo(dataOfLocation.wel_message_headerlogo)}
          label="Age"
          onChange={handleSetDisplayLogo}
          variant="filled"
          hiddenLabel
          disabled = {getDisableValue("169")}
          MenuProps={MenuProps}>
            {DisplayedICon.items.map(menuItem => {
                  
                  return (
                    <MenuItem  sx={
                      {
                      
                      color:"#fff",
                      backgroundColor: "#2F323A",
                      borderBottom: '.5px solid grey',
                      fontFamily: "Lato",fontSize:'12px',
                  }}
                   value={menuItem.label}>
                      {menuItem.label}
                    </MenuItem>
                  );
                })}
          </Select>
      <TextField
                        id="input-welcome"
                        height={40}
                        type= 'text'
                        name="wel_message"
                        value={dataOfLocation.wel_message}
                        onChange={handleInputChange}
                        sx={mainThemeStyle.InputMenuItem}
                        InputLabelProps={{ shrink: true }
                      }
                      disabled = {getDisableValue("170")}
                      variant="filled"
                        InputProps={{
                           
                            sx: {
                                
                                color:'rgba(135, 135, 135, 0.5)',
                                height:'33px',
                                borderRadius: '5px',
                                backgroundColor: '#121212',
                                boxShadow: 'inset 0px 4px 4px rgba(0, 0, 0, 0.25)',
                                padding: '0 0 15px 0',
                                textIndent: '15px !important',
                                '&.Mui-disabled': {
             

                                  background: 'rgba(135, 135, 135, 0.5)',
                                },
                                
                                "& input.Mui-disabled": {
                                  color: "white"
                                }
                               
                                
                            },
                        }}
                       
                    />
                   
                    {/* <TextField
                        id="input-tablet-name"
                        height={40}
                        type= 'text'
                        name="tablet_name"
                        value={dataOfLocation.tablet_name}
                        onChange={handleInputChange}
                        variant="filled"
                        sx={mainThemeStyle.InputMenuItem}
                        InputLabelProps={{ shrink: true }
                      }
                          
                        InputProps={{
                           
                            sx: {
                                
                                color:'rgba(135, 135, 135, 0.5)',
                                height:'33px',
                                borderRadius: '5px',
                                backgroundColor: '#121212',
                                boxShadow: 'inset 0px 4px 4px rgba(0, 0, 0, 0.25)',
                                padding: '0 0 15px 0',
                                textIndent: '15px !important',
                              
                               
                                
                            },
                        }}
                       
                    /> */}
                    <TextField
                        id="input-property-name"
                        height={40}
                        type= 'text'
                        name="loc_property_name"
                        value={dataOfLocation.loc_property_name}
                        onChange={handleInputChange}
                        sx={mainThemeStyle.InputMenuItem}
                        InputLabelProps={{ shrink: true }
                      }
                      disabled = {getDisableValue("152")}
                      variant="filled"
                        InputProps={{
                           
                            sx: {
                                
                                color:'rgba(135, 135, 135, 0.5)',
                                height:'33px',
                                borderRadius: '5px',
                                backgroundColor: '#121212',
                                boxShadow: 'inset 0px 4px 4px rgba(0, 0, 0, 0.25)',
                                padding: '0 0 15px 0',
                                textIndent: '15px !important',
                                '&.Mui-disabled': {
             

                                  background: 'rgba(135, 135, 135, 0.5)',
                                },
                                
                                "& input.Mui-disabled": {
                                  color: "white"
                                }
                               
                                
                            },
                        }}
                       
                    />
                    <TextField
                        id="input-office-name"
                        height={40}
                        type= 'text'
                        name="loc_office_name"
                        value={dataOfLocation.loc_office_name}
                        onChange={handleInputChange}
                        sx={mainThemeStyle.InputMenuItem}
                        InputLabelProps={{ shrink: true }
                      }
                      disabled = {getDisableValue("153")}
                      variant="filled"
                        InputProps={{
                           
                            sx: {
                                
                                color:'rgba(135, 135, 135, 0.5)',
                                height:'33px',
                                borderRadius: '5px',
                                backgroundColor: '#121212',
                                boxShadow: 'inset 0px 4px 4px rgba(0, 0, 0, 0.25)',
                                padding: '0 0 15px 0',
                                textIndent: '15px !important',
                              
                                '&.Mui-disabled': {
             

                                  background: 'rgba(135, 135, 135, 0.5)',
                                },
                                
                                "& input.Mui-disabled": {
                                  color: "white"
                                }
                                
                            },
                        }}
                       
                    />
                    <TextField
                        id="input-address-one"
                        height={40}
                        type= 'text'
                        name="loc_add_one"
                        value={dataOfLocation.loc_add_one}
                        onChange={handleInputChange}
                        sx={mainThemeStyle.InputMenuItem}
                        InputLabelProps={{ shrink: true }
                      }
                      disabled = {getDisableValue("154")}
                      variant="filled"
                        InputProps={{
                           
                            sx: {
                                
                                color:'rgba(135, 135, 135, 0.5)',
                                height:'33px',
                                borderRadius: '5px',
                                backgroundColor: '#121212',
                                boxShadow: 'inset 0px 4px 4px rgba(0, 0, 0, 0.25)',
                                padding: '0 0 15px 0',
                                textIndent: '15px !important',
                                '&.Mui-disabled': {
             

                                  background: 'rgba(135, 135, 135, 0.5)',
                                },
                                
                                "& input.Mui-disabled": {
                                  color: "white"
                                }
                               
                                
                            },
                        }}
                       
                    />
                    <TextField
                        id="input-address-two"
                        height={40}
                        type= 'text'
                        name="loc_add_two"
                        value={dataOfLocation.loc_add_two}
                        onChange={handleInputChange}
                        sx={mainThemeStyle.InputMenuItem}
                        InputLabelProps={{ shrink: true }
                      }
                      disabled = {getDisableValue("155")}
                      variant="filled"
                        InputProps={{
                           
                            sx: {
                                
                                color:'rgba(135, 135, 135, 0.5)',
                                height:'33px',
                                borderRadius: '5px',
                                backgroundColor: '#121212',
                                boxShadow: 'inset 0px 4px 4px rgba(0, 0, 0, 0.25)',
                                padding: '0 0 15px 0',
                                textIndent: '15px !important',
                              
                               
                                '&.Mui-disabled': {
             

                                  background: 'rgba(135, 135, 135, 0.5)',
                                },
                                
                                "& input.Mui-disabled": {
                                  color: "white"
                                }
                            },
                        }}
                       
                    />
                      
                     <TextField
                        id="input-panel-telephone"
                        height={40}
                        type= 'text'
                        name="loc_telephone_dial"
                        value={dataOfLocation.loc_telephone_dial}
                        onChange={handleInputChange}
                        sx={mainThemeStyle.InputMenuItem}
                        InputLabelProps={{ shrink: true }
                      }
                      disabled = {getDisableValue("157")}
                      variant="filled"
                        InputProps={{
                           
                            sx: {
                                
                                color:'rgba(135, 135, 135, 0.5)',
                                height:'33px',
                                borderRadius: '5px',
                                backgroundColor: '#121212',
                                boxShadow: 'inset 0px 4px 4px rgba(0, 0, 0, 0.25)',
                                padding: '0 0 15px 0',
                                textIndent: '15px !important',
                                '&.Mui-disabled': {
             

                                  background: 'rgba(135, 135, 135, 0.5)',
                                },
                                
                                "& input.Mui-disabled": {
                                  color: "white"
                                }
                               
                                
                            },
                        }}
                       
                    />
                   
     
           <Select
            sx={{ color: 'rgba(135, 135, 135, 0.5)',
            height:'33px',
            marginTop:'4px',
            marginBottom:'3px',
            borderRadius: '5px',
            backgroundColor: '#121212',
            boxShadow: 'inset 0px 4px 4px rgba(0, 0, 0, 0.25)',
            padding: '5px 0 5px 0',
            "& .MuiSvgIcon-root": {
              color: "rgba(135, 135, 135, 0.5)",
            },
            
            '&.Mui-disabled': {
             

              background: 'rgba(135, 135, 135, 0.5)',
            },
            "& input.Mui-disabled": {
              color: "white"
            }
            }}
            name = "app_unlock_mode"
            
            value={GetAppUnlockModeCBW()}
          label="Age"
          onChange={handleSetAppUnlockModeCBW}
          variant="filled"
          hiddenLabel
          MenuProps={MenuProps}>
            {AppUserUnlockModeCBW.items.map(menuItem => {
                  
                  return (
                    <MenuItem  sx={
                      {
                      
                      color:"#fff",
                      backgroundColor: "#2F323A",
                      borderBottom: '.5px solid grey',
                      fontFamily: "Lato",fontSize:'12px',
                  }}
                   value={menuItem.label}>
                      {menuItem.label}
                    </MenuItem>
                  );
                })}
          </Select> 
     
      </Stack>
      </Grid>
      <Grid item xs={1}>
    
      </Grid>
      <Grid item xs={2.5}>
        <Stack direction="column"  spacing={2}>
        <Typography  sx={mainThemeStyle.popUpLable} height={41}>Text Alert Phone# </Typography>
        <Typography  sx={mainThemeStyle.popUpLable} height={40}>URL for Watermark Logo </Typography>
     {/*  <Typography  sx={mainThemeStyle.popUpLable} height={40}>Refresh Rate(In minutes) </Typography>
      <Typography  sx={mainThemeStyle.popUpLable} height={40}>Setting Refresh Rate(In minutes) </Typography> */}
     {/*  <Typography  sx={mainThemeStyle.popUpLable} height={40}>Screen Layout </Typography> */}
     {/* <Typography  sx={mainThemeStyle.popUpLable} height={40}>URL for Displayed Logo </Typography> */}
       
      <Typography sx={mainThemeStyle.popUpLable} height={41}>Enable Office Button</Typography>
      <Typography  sx={mainThemeStyle.popUpLable} height={41}>Enable Directory Button </Typography>
      <Typography  sx={mainThemeStyle.popUpLable} height={41}>Enable Door PIN Button </Typography>
      <Typography  sx={mainThemeStyle.popUpLable} height={42}>Enable Delivery PIN Button </Typography>
      <Typography sx={mainThemeStyle.popUpLable} height={41}>Require GPS Proximty to Launch</Typography>
      
      </Stack>
      
      </Grid>
      <Grid item xs={2.5}>
        <Stack direction="column"  spacing={2}>
        <TextField
                        id="input-sms-alert"
                        height={40}
                        type= 'text'
                        name="warning_phone"
                        value={dataOfLocation.warning_phone}
                        onChange={handleInputChange}
                        sx={mainThemeStyle.InputMenuItem}
                        InputLabelProps={{ shrink: true }
                      }
                      disabled = {getDisableValue("156")}
                      variant="filled"
                        InputProps={{
                           
                            sx: {
                                
                                color:'rgba(135, 135, 135, 0.5)',
                                height:'33px',
                                borderRadius: '5px',
                                backgroundColor: '#121212',
                                boxShadow: 'inset 0px 4px 4px rgba(0, 0, 0, 0.25)',
                                padding: '0 0 15px 0',
                                textIndent: '15px !important',
                              
                               
                                '&.Mui-disabled': {
             

                                  background: 'rgba(135, 135, 135, 0.5)',
                                },
                                
                                "& input.Mui-disabled": {
                                  color: "white"
                                }
                            },
                        }}
                       
                    />
        {(token.issub === true && token.name === 'SUPER ADMIN') ?
      
      <TextField
                        id="input-location"
                        height={30}
                        type= 'text'
                        name="loc_url_watermark"
                        value={dataOfLocation.loc_url_watermark}
                        onChange={handleInputChange}
                        sx={mainThemeStyle.InputMenuItem}
                        InputLabelProps={{ shrink: true }
                       
                      }
                      disabled = {getDisableValue("160")}
                      variant="filled"
                        InputProps={{
                           
                            sx: {
                                
                                color:'rgba(135, 135, 135, 0.5)',
                                height:'33px',
                                borderRadius: '5px',
                                backgroundColor: '#121212',
                                boxShadow: 'inset 0px 4px 4px rgba(0, 0, 0, 0.25)',
                                padding: '0 0 15px 0',
                                textIndent: '15px !important',
                              
                                '&.Mui-disabled': {
             

                                  background: 'rgba(135, 135, 135, 0.5)',
                                },
                                
                                "& input.Mui-disabled": {
                                  color: "white"
                                }
                               
                                
                            },
                        }}
                       
                    /> : <img  src={dataOfLocation.loc_url_watermark} height={30} style={{width:"100%",'object-fit': 'contain'}}></img>}
      
      
                   {/*  <Select
            sx={{ color: 'rgba(135, 135, 135, 0.5)',
            height:'33px',
            marginTop:'4px',
            marginBottom:'3px',
            borderRadius: '5px',
            backgroundColor: '#121212',
            boxShadow: 'inset 0px 4px 4px rgba(0, 0, 0, 0.25)',
            padding: '5px 0 5px 0',
            "& .MuiSvgIcon-root": {
              color: "rgba(135, 135, 135, 0.5)",
            },
            
            '&.Mui-disabled': {
             

              background: 'rgba(135, 135, 135, 0.5)',
            },
            "& input.Mui-disabled": {
              color: "white"
            }
            }}
            name = "loc_screen_layout"
            
            value={GetValueOfLocationScreenLayout(dataOfLocation.loc_screen_layout)}
          label="Age"
          onChange={ChangeScreenLayout}
          variant="filled"
          hiddenLabel
          MenuProps={MenuProps}>
            {ScreenLayout.items.map(menuItem => {
                  
                  return (
                    <MenuItem  sx={
                      {
                      
                      color:"#fff",
                      backgroundColor: "#2F323A",
                      borderBottom: '.5px solid grey',
                      fontFamily: "Lato",fontSize:'12px',
                  }}
                   value={menuItem.label}>
                      {menuItem.label}
                    </MenuItem>
                  );
                })}
          </Select> */}
           {/* <TextField
                        id="input-location"
                        height={30}
                        type= 'text'
                        name="wel_message_headerlogo"
                        value={dataOfLocation.wel_message_headerlogo}
                        onChange={handleInputChange}
                        sx={mainThemeStyle.InputMenuItem}
                        InputLabelProps={{ shrink: true }
                       
                      }
                      
                      variant="filled"
                        InputProps={{
                           
                            sx: {
                                
                                color:'rgba(135, 135, 135, 0.5)',
                                height:'33px',
                                borderRadius: '5px',
                                backgroundColor: '#121212',
                                boxShadow: 'inset 0px 4px 4px rgba(0, 0, 0, 0.25)',
                                padding: '0 0 15px 0',
                                textIndent: '15px !important',
                              
                              
                               
                                
                            },
                        }}
                       
                    /> */}
                    
          <FormControlLabel  sx={mainThemeStyle.popUpLable}
        control={<IOSSwitch sx={{ m: 1 }} name="dis_off" onChange={handlecheckboxCheckReverse}  disabled = {getDisableValue("163")} checked={dataOfLocation.dis_off == "0" ? true : false } />}
        label=""
      />
      <FormControlLabel  sx={mainThemeStyle.popUpLable}
        control={<IOSSwitch sx={{ m: 1 }} name="dis_dir" onChange={handlecheckboxCheckReverse}  disabled = {getDisableValue("164")} checked={dataOfLocation.dis_dir == "0" ? true : false } />}
        label=""
      />
      <FormControlLabel  sx={mainThemeStyle.popUpLable}
        control={<IOSSwitch sx={{ m: 1 }} name="dis_doorpin" onChange={handlecheckboxCheckReverse}  disabled = {getDisableValue("165")} checked={dataOfLocation.dis_doorpin == "0" ? true : false } />}
        label=""
      />
      <FormControlLabel  sx={mainThemeStyle.popUpLable}
        control={<IOSSwitch sx={{ m: 1 }} name="dis_del" onChange={handlecheckboxCheckReverse} disabled = {getDisableValue("166")} checked={dataOfLocation.dis_del == "0" ? true : false } />}
        label=""
      />
      <FormControlLabel  sx={mainThemeStyle.popUpLable}
        control={<IOSSwitch sx={{ m: 1 }} name="dis_prox" onChange={handlecheckboxCheckReverse}  disabled = {getDisableValue("238")} checked={dataOfLocation.dis_prox == "0" ? true : false } />}
        label=""
      />
                  {/*   <Checkbox  sx={mainThemeStyle.formCheckBox} name="dis_off" onChange={handlecheckboxCheck} checked={dataOfLocation.dis_off == "1" ? true : false} />
                    <Checkbox sx={mainThemeStyle.formCheckBox} name="dis_dir" onChange={handlecheckboxCheck} checked={dataOfLocation.dis_dir  == "1" ? true : false}/>
                    <Checkbox sx={mainThemeStyle.formCheckBox} name="dis_doorpin" onChange={handlecheckboxCheck} checked={dataOfLocation.dis_doorpin  == "1" ? true : false}/>
                    <Checkbox sx={mainThemeStyle.formCheckBox} name="dis_del" onChange={handlecheckboxCheck} checked={dataOfLocation.dis_del  == "1" ? true : false}/> */}
                   
      </Stack>
      </Grid>
     
      
      </Grid></Box>
      <Box sx={{display: { xs: "flex", md: "none" }}}>
           <Grid container spacing={2} padding= '10px' paddingBottom='32px' paddingTop='32px'> 
      <Grid item xs={5}>
        <Stack direction="column"  spacing={2}>
        <Typography sx={mainThemeStyle.popUpLable} height={41}> Displayed Heading </Typography>
        <Typography  sx={mainThemeStyle.popUpLable} height={40}>Displayed Head Color </Typography>
        <Typography  sx={mainThemeStyle.popUpLable} height={40}>Displayed Icon </Typography>
      <Typography sx={mainThemeStyle.popUpLable} height={41}> Displayed Message </Typography>
      <Typography sx={mainThemeStyle.popUpLable} height={40}> Account Name </Typography>
      <Typography  sx={mainThemeStyle.popUpLable} height={40}>Button Descriptor </Typography>
      <Typography  sx={mainThemeStyle.popUpLable} height={40}>Account Address </Typography>
      <Typography  sx={mainThemeStyle.popUpLable} height={40}>City, State, ZIP </Typography>
      <Typography  sx={mainThemeStyle.popUpLable} height={40}>Text Alert Phone# </Typography>
      <Typography  sx={mainThemeStyle.popUpLable} height={40}>Displayed Phone# </Typography>
      {/* <Typography  sx={mainThemeStyle.popUpLable} height={40}>Unlocking Mode </Typography> */}
       <Typography  sx={mainThemeStyle.popUpLable} height={40}>App User Unlock Mode</Typography> 
      <Typography  sx={mainThemeStyle.popUpLable} height={40}>Watermark URL </Typography>
      {/* <Typography  sx={mainThemeStyle.popUpLable} height={40}>Displayed Logo URL</Typography> */}
      
      {/* <Typography  sx={mainThemeStyle.popUpLable} height={40}>Refresh Rate </Typography>
      <Typography  sx={mainThemeStyle.popUpLable} height={40}>Setting Refresh Rate</Typography>
      <Typography  sx={mainThemeStyle.popUpLable} height={40}>Screen Layout </Typography> */}
      <Typography sx={mainThemeStyle.popUpLable} height={40}> Office Button</Typography>
      <Typography  sx={mainThemeStyle.popUpLable} height={40}> Directory Button </Typography>
      <Typography  sx={mainThemeStyle.popUpLable} height={40}> Door PIN Button </Typography>
      <Typography  sx={mainThemeStyle.popUpLable} height={40}> Dilevery PIN Button </Typography>
      <Typography sx={mainThemeStyle.popUpLable} height={40}>Require GPS Proximty to Launch</Typography>
      </Stack>
      </Grid>
      
      <Grid item xs={7}>
      <Stack direction="column"  spacing={2}>
      <TextField
                        id="wel_message_header"
                        height={40}
                        type= 'text'
                        name="wel_message_header"
                        value={dataOfLocation.wel_message_header}
                        onChange={handleInputChange}
                        sx={mainThemeStyle.InputMenuItem}
                        InputLabelProps={{ shrink: true }
                      }
                      disabled = {getDisableValue("167")}
                      variant="filled"
                        InputProps={{
                           
                            sx: {
                                
                                color:'rgba(135, 135, 135, 0.5)',
                                height:'33px',
                                borderRadius: '5px',
                                backgroundColor: '#121212',
                                boxShadow: 'inset 0px 4px 4px rgba(0, 0, 0, 0.25)',
                                padding: '0 0 15px 0',
                                textIndent: '15px !important',
                              
                                '&.Mui-disabled': {
             

                                  background: 'rgba(135, 135, 135, 0.5)',
                                },
                                
                                "& input.Mui-disabled": {
                                  color: "white"
                                }
                                
                            },
                        }}
                       
                    />
                    <TextField
                        id="input-location"
                        height={40}
                        type= 'color'
                        name="wel_message_headercolor"
                        value={dataOfLocation.wel_message_headercolor}
                        onChange={handleInputChange}
                        sx={mainThemeStyle.InputMenuItem}
                        InputLabelProps={{ shrink: true }
                       
                      }
                      disabled = {getDisableValue("168")}
                      variant="filled"
                        InputProps={{
                           
                            sx: {
                              marginTop:'15px',
                                color:'rgba(255, 255, 255, 0.5)',
                                height:'33px',
                                borderRadius: '5px',
                                backgroundColor: '#121212',
                                boxShadow: 'inset 0px 4px 4px rgba(0, 0, 0, 0.25)',
                                padding: '0 0 15px 0',
                                textIndent: '15px !important',
                              
                                '&.Mui-disabled': {
             

                                  background: 'rgba(135, 135, 135, 0.5)',
                                },
                                
                                "& input.Mui-disabled": {
                                  color: "white"
                                }
                               
                                
                            },
                        }}
                       
                    />
                     <Select
            sx={{ color: 'rgba(135, 135, 135, 0.5)',
            height:'33px',
            marginTop:'4px',
            marginBottom:'3px',
            borderRadius: '5px',
            backgroundColor: '#121212',
            boxShadow: 'inset 0px 4px 4px rgba(0, 0, 0, 0.25)',
            padding: '5px 0 5px 0',
            "& .MuiSvgIcon-root": {
              color: "rgba(135, 135, 135, 0.5)",
            },
            '&.Mui-disabled': {
             

              background: 'rgba(135, 135, 135, 0.5)',
            },
            
            "& input.Mui-disabled": {
              color: "white"
            }
            }}
            name = "wel_message_headerlogo"
            value={getDisplayedLogo(dataOfLocation.wel_message_headerlogo)}
          label="Age"
          onChange={handleSetDisplayLogo}
          disabled = {getDisableValue("169")}
          variant="filled"
          hiddenLabel
          MenuProps={MenuProps}>
            
            {DisplayedICon.items.map(menuItem => {
                  
                  return (
                    <MenuItem  sx={
                      {
                      
                      color:"#fff",
                      backgroundColor: "#2F323A",
                      borderBottom: '.5px solid grey',
                      fontFamily: "Lato",fontSize:'12px',
                  }}
                   value={menuItem.label}>
                      {menuItem.label}
                    </MenuItem>
                  );
                })}
          </Select>
      <TextField
                        id="input-welcome"
                        height={40}
                        type= 'text'
                        name="wel_message"
                        value={dataOfLocation.wel_message}
                        onChange={handleInputChange}
                        sx={mainThemeStyle.InputMenuItem}
                        InputLabelProps={{ shrink: true }
                      }
                      disabled = {getDisableValue("170")}
                      variant="filled"
                        InputProps={{
                           
                            sx: {
                                
                                color:'rgba(135, 135, 135, 0.5)',
                                height:'33px',
                                borderRadius: '5px',
                                backgroundColor: '#121212',
                                boxShadow: 'inset 0px 4px 4px rgba(0, 0, 0, 0.25)',
                                padding: '0 0 15px 0',
                                textIndent: '15px !important',
                              
                               
                                '&.Mui-disabled': {
             

                                  background: 'rgba(135, 135, 135, 0.5)',
                                },
                                
                                "& input.Mui-disabled": {
                                  color: "white"
                                }
                            },
                        }}
                       
                    />
                  
                    <TextField
                        id="input-property-name"
                        height={40}
                        type= 'text'
                        name="loc_property_name"
                        value={dataOfLocation.loc_property_name}
                        onChange={handleInputChange}
                        sx={mainThemeStyle.InputMenuItem}
                        InputLabelProps={{ shrink: true }
                      }
                      disabled = {getDisableValue("152")}
                      variant="filled"
                        InputProps={{
                           
                            sx: {
                                
                                color:'rgba(135, 135, 135, 0.5)',
                                height:'33px',
                                borderRadius: '5px',
                                backgroundColor: '#121212',
                                boxShadow: 'inset 0px 4px 4px rgba(0, 0, 0, 0.25)',
                                padding: '0 0 15px 0',
                                textIndent: '15px !important',
                                '&.Mui-disabled': {
             

                                  background: 'rgba(135, 135, 135, 0.5)',
                                },
                                
                                "& input.Mui-disabled": {
                                  color: "white"
                                }
                               
                                
                            },
                        }}
                       
                    />
                    <TextField
                        id="input-office-name"
                        height={40}
                        type= 'text'
                        name="loc_office_name"
                        value={dataOfLocation.loc_office_name}
                        onChange={handleInputChange}
                        sx={mainThemeStyle.InputMenuItem}
                        InputLabelProps={{ shrink: true }
                      }
                      disabled = {getDisableValue("153")}
                      variant="filled"
                        InputProps={{
                           
                            sx: {
                                
                                color:'rgba(135, 135, 135, 0.5)',
                                height:'33px',
                                borderRadius: '5px',
                                backgroundColor: '#121212',
                                boxShadow: 'inset 0px 4px 4px rgba(0, 0, 0, 0.25)',
                                padding: '0 0 15px 0',
                                textIndent: '15px !important',
                                '&.Mui-disabled': {
             

                                  background: 'rgba(135, 135, 135, 0.5)',
                                },
                                
                                "& input.Mui-disabled": {
                                  color: "white"
                                }
                               
                                
                            },
                        }}
                       
                    />
                    <TextField
                        id="input-address-one"
                        height={40}
                        type= 'text'
                        name="loc_add_one"
                        value={dataOfLocation.loc_add_one}
                        onChange={handleInputChange}
                        sx={mainThemeStyle.InputMenuItem}
                        InputLabelProps={{ shrink: true }
                      }
                      disabled = {getDisableValue("154")}
                      variant="filled"
                        InputProps={{
                           
                            sx: {
                                
                                color:'rgba(135, 135, 135, 0.5)',
                                height:'33px',
                                borderRadius: '5px',
                                backgroundColor: '#121212',
                                boxShadow: 'inset 0px 4px 4px rgba(0, 0, 0, 0.25)',
                                padding: '0 0 15px 0',
                                textIndent: '15px !important',
                                '&.Mui-disabled': {
             

                                  background: 'rgba(135, 135, 135, 0.5)',
                                },
                                
                                "& input.Mui-disabled": {
                                  color: "white"
                                }
                               
                                
                            },
                        }}
                       
                    />
                    <TextField
                        id="input-address-two"
                        height={40}
                        type= 'text'
                        name="loc_add_two"
                        value={dataOfLocation.loc_add_two}
                        onChange={handleInputChange}
                        sx={mainThemeStyle.InputMenuItem}
                        InputLabelProps={{ shrink: true }
                      }
                      disabled = {getDisableValue("155")}
                      variant="filled"
                        InputProps={{
                           
                            sx: {
                                
                                color:'rgba(135, 135, 135, 0.5)',
                                height:'33px',
                                borderRadius: '5px',
                                backgroundColor: '#121212',
                                boxShadow: 'inset 0px 4px 4px rgba(0, 0, 0, 0.25)',
                                padding: '0 0 15px 0',
                                textIndent: '15px !important',
                                '&.Mui-disabled': {
             

                                  background: 'rgba(135, 135, 135, 0.5)',
                                },
                                
                                "& input.Mui-disabled": {
                                  color: "white"
                                }
                               
                                
                            },
                        }}
                       
                    />
                       <TextField
                        id="input-sms-alert"
                        height={40}
                        type= 'text'
                        name="warning_phone"
                        value={dataOfLocation.warning_phone}
                        onChange={handleInputChange}
                        sx={mainThemeStyle.InputMenuItem}
                        InputLabelProps={{ shrink: true }
                      }
                      disabled = {getDisableValue("156")}
                      variant="filled"
                        InputProps={{
                           
                            sx: {
                                
                                color:'rgba(135, 135, 135, 0.5)',
                                height:'33px',
                                borderRadius: '5px',
                                backgroundColor: '#121212',
                                boxShadow: 'inset 0px 4px 4px rgba(0, 0, 0, 0.25)',
                                padding: '0 0 15px 0',
                                textIndent: '15px !important',
                              
                                '&.Mui-disabled': {
             

                                  background: 'rgba(135, 135, 135, 0.5)',
                                },
                                
                                "& input.Mui-disabled": {
                                  color: "white"
                                }
                                
                            },
                        }}
                       
                    />
                     <TextField
                        id="input-panel-telephone"
                        height={40}
                        type= 'text'
                        name="loc_telephone_dial"
                        value={dataOfLocation.loc_telephone_dial}
                        onChange={handleInputChange}
                        sx={mainThemeStyle.InputMenuItem}
                        InputLabelProps={{ shrink: true }
                      }
                      disabled = {getDisableValue("157")}
                      variant="filled"
                        InputProps={{
                           
                            sx: {
                                
                                color:'rgba(135, 135, 135, 0.5)',
                                height:'33px',
                                borderRadius: '5px',
                                backgroundColor: '#121212',
                                boxShadow: 'inset 0px 4px 4px rgba(0, 0, 0, 0.25)',
                                padding: '0 0 15px 0',
                                textIndent: '15px !important',
                                '&.Mui-disabled': {
             

                                  background: 'rgba(135, 135, 135, 0.5)',
                                },
                                
                                "& input.Mui-disabled": {
                                  color: "white"
                                }
                               
                                
                            },
                        }}
                       
                    />
                     
         <Select
            sx={{ color: 'rgba(135, 135, 135, 0.5)',
            height:'33px',
            marginTop:'4px',
            marginBottom:'3px',
            borderRadius: '5px',
            backgroundColor: '#121212',
            boxShadow: 'inset 0px 4px 4px rgba(0, 0, 0, 0.25)',
            padding: '5px 0 5px 0',
            "& .MuiSvgIcon-root": {
              color: "rgba(135, 135, 135, 0.5)",
            },
            
            '&.Mui-disabled': {
             

              background: 'rgba(135, 135, 135, 0.5)',
            },
            "& input.Mui-disabled": {
              color: "white"
            }
            }}
            name = "app_unlock_mode"
            
            value={GetAppUnlockModeCBW()}
          label="Age"
          onChange={handleSetAppUnlockModeCBW}
          variant="filled"
          hiddenLabel
          MenuProps={MenuProps}>
            {AppUserUnlockModeCBW.items.map(menuItem => {
                  
                  return (
                    <MenuItem  sx={
                      {
                      
                      color:"#fff",
                      backgroundColor: "#2F323A",
                      borderBottom: '.5px solid grey',
                      fontFamily: "Lato",fontSize:'12px',
                  }}
                   value={menuItem.label}>
                      {menuItem.label}
                    </MenuItem>
                  );
                })}
          </Select> 
          {(token.issub === true && token.name === 'SUPER ADMIN') ?
      
      <TextField
                        id="input-location"
                        height={40}
                        type= 'text'
                        name="loc_url_watermark"
                        value={dataOfLocation.loc_url_watermark}
                        onChange={handleInputChange}
                        sx={mainThemeStyle.InputMenuItem}
                        InputLabelProps={{ shrink: true }
                       
                      }
                      disabled = {getDisableValue("160")}
                      variant="filled"
                        InputProps={{
                           
                            sx: {
                                
                                color:'rgba(135, 135, 135, 0.5)',
                                height:'33px',
                                borderRadius: '5px',
                                backgroundColor: '#121212',
                                boxShadow: 'inset 0px 4px 4px rgba(0, 0, 0, 0.25)',
                                padding: '0 0 15px 0',
                                textIndent: '15px !important',
                                '&.Mui-disabled': {
             

                                  background: 'rgba(135, 135, 135, 0.5)',
                                },
                                
                                "& input.Mui-disabled": {
                                  color: "white"
                                }
                              
                               
                                
                            },
                        }}
                       
                    /> : <img  src={dataOfLocation.loc_url_watermark} height={30} style={{width:"100%",'object-fit': 'contain'}}></img>}
      {/* <TextField
                        id="input-location"
                        height={30}
                        type= 'text'
                        name="wel_message_headerlogo"
                        value={dataOfLocation.wel_message_headerlogo}
                        onChange={handleInputChange}
                        sx={mainThemeStyle.InputMenuItem}
                        InputLabelProps={{ shrink: true }
                       
                      }
                      
                      variant="filled"
                        InputProps={{
                           
                            sx: {
                              marginTop:'15px',
                                color:'rgba(135, 135, 135, 0.5)',
                                height:'33px',
                                borderRadius: '5px',
                                backgroundColor: '#121212',
                                boxShadow: 'inset 0px 4px 4px rgba(0, 0, 0, 0.25)',
                                padding: '0 0 15px 0',
                                textIndent: '15px !important',
                              
                              
                               
                                
                            },
                        }}
                       
                    /> */}
                    
          <FormControlLabel  sx={mainThemeStyle.popUpLable}
        control={<IOSSwitch sx={{ m: 1 }} name="dis_off"  disabled = {getDisableValue("163")} onChange={handlecheckboxCheckReverse} checked={dataOfLocation.dis_off == "0" ? true : false } />}
        label=""
      />
      <FormControlLabel  sx={mainThemeStyle.popUpLable}
        control={<IOSSwitch sx={{ m: 1 }} name="dis_dir" disabled = {getDisableValue("164")} onChange={handlecheckboxCheckReverse} checked={dataOfLocation.dis_dir == "0" ? true : false } />}
        label=""
      />
      <FormControlLabel  sx={mainThemeStyle.popUpLable}
        control={<IOSSwitch sx={{ m: 1 }} name="dis_doorpin" disabled = {getDisableValue("165")} onChange={handlecheckboxCheckReverse} checked={dataOfLocation.dis_doorpin == "0" ? true : false } />}
        label=""
      />
      <FormControlLabel  sx={mainThemeStyle.popUpLable}
        control={<IOSSwitch sx={{ m: 1 }} name="dis_del" disabled = {getDisableValue("166")} onChange={handlecheckboxCheckReverse} checked={dataOfLocation.dis_del == "0" ? true : false } />}
        label=""
      />
     <FormControlLabel  sx={mainThemeStyle.popUpLable}
        control={<IOSSwitch sx={{ m: 1 }} name="dis_prox" disabled = {getDisableValue("238")} onChange={handlecheckboxCheckReverse} checked={dataOfLocation.dis_prox == "0" ? true : false } />}
        label=""
      />
      </Stack>
      </Grid>
     
      
     
      
      </Grid></Box>

</>)}

{(currentTab == 2 && dataOfLocation.cam_brand == "ResidentLink" && <>
<div  sx={{
       right: '54px' ,left: '54px',borderRadius:'0px'}}>
               <div style={{ display: 'flex' }}>
                   <Typography variant="h6" component="div" style={{  paddingLeft: '16px',paddingTop: '16px',flexGrow: 1,color:'#fff' }}>
                       Relay Settings
                   </Typography>
                   
               </div>
              
           </div>
           <Divider sx={{backgroundColor:'white'}} />
           <Box sx={{display: { xs: "none", md: "flex" }}}>
           <Grid container spacing={2} padding= '32px'> 
      <Grid item xs={2.5}>
        <Stack direction="column"  spacing={2}>
      
        <Typography  sx={mainThemeStyle.popUpLable} height={40}>PIN Schedule Time</Typography>
        {dataOfLocation.unlocking_mode == '2' && <Typography  sx={mainThemeStyle.popUpLable} height={40}>Enable External Wiegand Input</Typography>}
        {dataOfLocation.unlocking_mode == '3'  &&
        <Typography  sx={mainThemeStyle.popUpLable} height={40}>Relay Failover</Typography>}
        {dataOfLocation.unlocking_mode == '2' && dataOfLocation.enable_exe_weigand == "1" &&
        <Typography  sx={mainThemeStyle.popUpLable} height={40}>RFID/Wiegand Schedule Time</Typography>}
        
      <Typography sx={mainThemeStyle.popUpLable} height={40}> Resident PIN </Typography>
      <Typography sx={mainThemeStyle.popUpLable} height={40}> Guest PIN </Typography>
    
      
      </Stack>
      </Grid>
      
      <Grid item xs={2.5}>
      <Stack direction="column"  spacing={2}>
      
      
<Stack direction="row" spacing={2} height={40}>

<Select
 sx={{ color: 'rgba(135, 135, 135, 0.5)',
 height:'33px',
 marginTop:'4px',
 marginBottom:'3px',
 borderRadius: '5px',
 backgroundColor: '#121212',
 boxShadow: 'inset 0px 4px 4px rgba(0, 0, 0, 0.25)',
 padding: '5px 0 5px 0',
 "& .MuiSvgIcon-root": {
   color: "rgba(135, 135, 135, 0.5)",
 },
 
 '&.Mui-disabled': {
  

   background: 'rgba(135, 135, 135, 0.5)',
 },
 "& input.Mui-disabled": {
   color: "white"
 }
 }}
 name = "pin_start_time"
 disabled = {getDisableValue("171")}
 value={dataOfLocation.pin_start_time}
label="Age"
onChange={handleChangeBoot}
variant="filled"
hiddenLabel
MenuProps={MenuProps}>
 {BootTime.items.map(menuItem => {
       
       return (
         <MenuItem  sx={
           {
           
           color:"#fff",
           backgroundColor: "#2F323A",
           borderBottom: '.5px solid grey',
           fontFamily: "Lato",fontSize:'12px',
       }}
        value={menuItem.label}>
           {menuItem.label}
         </MenuItem>
       );
     })}
</Select>
<Typography sx={mainThemeStyle.popUpLableAnswer} paddingTop="7px"> To </Typography>
<Box>
<Select
 sx={{ color: 'rgba(135, 135, 135, 0.5)',
 height:'33px',
 marginTop:'4px',
 marginBottom:'3px',
 borderRadius: '5px',
 backgroundColor: '#121212',
 boxShadow: 'inset 0px 4px 4px rgba(0, 0, 0, 0.25)',
 padding: '5px 0 5px 0',
 "& .MuiSvgIcon-root": {
   color: "rgba(135, 135, 135, 0.5)",
 },
 
 '&.Mui-disabled': {
  

   background: 'rgba(135, 135, 135, 0.5)',
 },
 "& input.Mui-disabled": {
   color: "white"
 }
 }}
 name = "pin_end_time"
 disabled = {getDisableValue("171")}
 value={dataOfLocation.pin_end_time}
label="Age"
onChange={handleChangeBoot}
variant="filled"
hiddenLabel
MenuProps={MenuProps}>
 {BootTime.items.map(menuItem => {
       
       return (
         <MenuItem  sx={
           {
           
           color:"#fff",
           backgroundColor: "#2F323A",
           borderBottom: '.5px solid grey',
           fontFamily: "Lato",fontSize:'12px',
       }}
        value={menuItem.label}>
           {menuItem.label}
         </MenuItem>
       );
     })}
</Select></Box>
</Stack>
{dataOfLocation.unlocking_mode == '2' &&     <FormControlLabel  sx={mainThemeStyle.popUpLable}
        control={<IOSSwitch sx={{ m: 1 }} name="enable_exe_weigand" onChange={handlecheckboxCheck}  disabled = {getDisableValue("174")} checked={dataOfLocation.enable_exe_weigand == "1" ? true : false } />}
        label={dataOfLocation.enable_exe_weigand == "1" ? "On" : "Off"}
      />} 
      {dataOfLocation.unlocking_mode == '3' &&     <FormControlLabel  sx={mainThemeStyle.popUpLable} disabled = {getDisableValue("174")}
        control={<IOSSwitch sx={{ m: 1 }} name="relay_fail_over" onChange={handlecheckboxCheck} checked={dataOfLocation.relay_fail_over == "1" ? true : false } />}
        label={dataOfLocation.relay_fail_over == "1" ? "On" : "Off"}
      />}
      {dataOfLocation.unlocking_mode == '2' && dataOfLocation.enable_exe_weigand == "1" &&
<Stack direction="row" spacing={2} height={40}>

<Select
 sx={{ color: 'rgba(135, 135, 135, 0.5)',
 height:'33px',
 marginTop:'4px',
 marginBottom:'3px',
 borderRadius: '5px',
 backgroundColor: '#121212',
 boxShadow: 'inset 0px 4px 4px rgba(0, 0, 0, 0.25)',
 padding: '5px 0 5px 0',
 "& .MuiSvgIcon-root": {
   color: "rgba(135, 135, 135, 0.5)",
 },
 
 '&.Mui-disabled': {
  

   background: 'rgba(135, 135, 135, 0.5)',
 },
 "& input.Mui-disabled": {
   color: "white"
 }
 }}
 name = "card_start_time"
 disabled = {getDisableValue("174")}
 value={dataOfLocation.card_start_time}
label="Age"
onChange={handleChangeBoot}
variant="filled"
hiddenLabel
MenuProps={MenuProps}>
 {BootTime.items.map(menuItem => {
       
       return (
         <MenuItem  sx={
           {
           
           color:"#fff",
           backgroundColor: "#2F323A",
           borderBottom: '.5px solid grey',
           fontFamily: "Lato",fontSize:'12px',
       }}
        value={menuItem.label}>
           {menuItem.label}
         </MenuItem>
       );
     })}
</Select>
<Typography sx={mainThemeStyle.popUpLableAnswer} paddingTop="7px"> To </Typography>
<Box>
<Select
 sx={{ color: 'rgba(135, 135, 135, 0.5)',
 height:'33px',
 marginTop:'4px',
 marginBottom:'3px',
 borderRadius: '5px',
 backgroundColor: '#121212',
 boxShadow: 'inset 0px 4px 4px rgba(0, 0, 0, 0.25)',
 padding: '5px 0 5px 0',
 "& .MuiSvgIcon-root": {
   color: "rgba(135, 135, 135, 0.5)",
 },
 
 '&.Mui-disabled': {
  

   background: 'rgba(135, 135, 135, 0.5)',
 },
 "& input.Mui-disabled": {
   color: "white"
 }
 }}
 name = "card_end_time"
 disabled = {getDisableValue("174")}
 value={dataOfLocation.card_end_time}
label="Age"
onChange={handleChangeBoot}
variant="filled"
hiddenLabel
MenuProps={MenuProps}>
 {BootTime.items.map(menuItem => {
       
       return (
         <MenuItem  sx={
           {
           
           color:"#fff",
           backgroundColor: "#2F323A",
           borderBottom: '.5px solid grey',
           fontFamily: "Lato",fontSize:'12px',
       }}
        value={menuItem.label}>
           {menuItem.label}
         </MenuItem>
       );
     })}
</Select></Box>
</Stack>}
          <FormControlLabel   sx={mainThemeStyle.popUpLableNewone}
        control={<IOSSwitch sx={{ m: 1 }} name="r_pin_off" onChange={handlecheckboxCheckReverse} disabled = {getDisableValue("172")}  checked={dataOfLocation.r_pin_off == "0" ? true : false} />}
        label={dataOfLocation.r_pin_off == "0" ? "Enabled" : "Disabled"}
      />
      <FormControlLabel   sx={mainThemeStyle.popUpLableNewone}
        control={<IOSSwitch sx={{ m: 1 }} name="g_pin_off" onChange={handlecheckboxCheckReverse}  disabled = {getDisableValue("173")} checked={dataOfLocation.g_pin_off == "0" ? true : false} />}
        label={dataOfLocation.g_pin_off == "0" ? "Enabled" : "Disabled"}
      />
            {/* <Checkbox sx={mainThemeStyle.formCheckBox} name="r_pin_off" onChange={handlecheckboxCheckReverse} checked={dataOfLocation.r_pin_off == "0" ? true : false}/>
                    <Checkbox sx={mainThemeStyle.formCheckBox} name="g_pin_off" onChange={handlecheckboxCheckReverse} checked={dataOfLocation.g_pin_off == "0" ? true : false}/> */}
                    
              
     
      {/* {dataOfLocation.unlocking_mode == '2' && <Checkbox sx={mainThemeStyle.formCheckBox}  name="enable_exe_weigand" onChange={handlecheckboxCheck} checked={dataOfLocation.enable_exe_weigand == "1" ? true : false}/>}
       */}
          
      </Stack>
      </Grid>
      <Grid item xs={1}>
    
      </Grid>
      <Grid item xs={2.5}>
        <Stack direction="column"  spacing={2}>
      
    
      
      
     
           {dataOfLocation.unlocking_mode == '2' && <Typography  sx={mainThemeStyle.popUpLable} height={40}>ResidentVue Relay 1 Friendly Name</Typography>}
      {dataOfLocation.unlocking_mode == '2' &&  <Typography  sx={mainThemeStyle.popUpLable} height={40}>Default {dataOfLocation.relay_one_fname != ""?dataOfLocation.relay_one_fname:' Relay 1 '} State</Typography>}
      {dataOfLocation.unlocking_mode == '2' && <Typography  sx={mainThemeStyle.popUpLable} height={43}>{ (dataOfLocation.relay_one_fname).length > 0 ? dataOfLocation.relay_one_fname + " Unlock Duration (Enter Between 0 to 99 Seconds)" : "Relay 1 Unlock Duration (Enter Between 0 to 99 Seconds)"}</Typography>}
      {dataOfLocation.unlocking_mode == '3' &&  <Typography  sx={mainThemeStyle.popUpLable} height={40}>Default Failover Relay 1 State</Typography>}
      {dataOfLocation.unlocking_mode == '3' && <Typography  sx={mainThemeStyle.popUpLable} height={43}> Failover Relay 1 Unlock Duration (Enter Between 0 to 99 Seconds)</Typography>}
    
      {(dataOfLocation.unlocking_mode == '2' ||   dataOfLocation.unlocking_mode == '3') && <> <Typography></Typography>
      <Divider sx={{backgroundColor:'white'}} />
      <Typography></Typography> </>}
      
      {dataOfLocation.unlocking_mode == '2' && <Typography  sx={mainThemeStyle.popUpLable} height={40}>ResidentVue Relay 2 Friendly Name</Typography>}
      {dataOfLocation.unlocking_mode == '2' && <Typography  sx={mainThemeStyle.popUpLable} height={40}>Default {dataOfLocation.relay_one_fname != ""?dataOfLocation.relay_two_fname:' Relay 2 '} State</Typography>}
     
        {dataOfLocation.unlocking_mode == '2' && <Typography  sx={mainThemeStyle.popUpLable} height={40}>{ (dataOfLocation.relay_two_fname).length > 0 ? dataOfLocation.relay_two_fname + " Unlock Duration (Enter Between 0 to 99 Seconds)" : "Relay 2 Unlock Duration (Enter Between 0 to 99 Seconds)"}</Typography>}
        {dataOfLocation.unlocking_mode == '3' &&  <Typography  sx={mainThemeStyle.popUpLable} height={40}>Default Failover Relay 2 State</Typography>}
      {dataOfLocation.unlocking_mode == '3' && <Typography  sx={mainThemeStyle.popUpLable} height={43}> Failover Relay 2 Unlock Duration (Enter Between 0 to 99 Seconds)</Typography>}
      {dataOfLocation.unlocking_mode == '4' &&<Typography  sx={mainThemeStyle.popUpLable} height={35}>PDK Primary Door ID</Typography>}
       {dataOfLocation.unlocking_mode == '4' && <Typography  sx={mainThemeStyle.popUpLable} height={35}>PDK Secondary Door ID</Typography>}

      </Stack>
      </Grid>
      <Grid item xs={2.5}>
        <Stack direction="column"  spacing={2}>
         
      
       {/* <TimezoneSelect
          value={selectedTimezone}
          onChange={setSelectedTimezone}
          sx={{ color: 'rgba(135, 135, 135, 0.5)',
          height:'34px',
          borderRadius: '5px',
          marginTop:'3px',
          marginBottom:'3px',
          backgroundColor: '#121212',
          boxShadow: 'inset 0px 4px 4px rgba(0, 0, 0, 0.25)',
          padding: '5px 0 5px 0',
          textIndent: '15px !important',}}
        />  */} 
                
               
         
          {dataOfLocation.unlocking_mode == '2' && <TextField
                        id="input-vegax-relay1"
                        height={40}
                        type= 'text'
                        name="relay_one_fname"
                        value={dataOfLocation.relay_one_fname}
                        onChange={handleInputChange}
                        sx={mainThemeStyle.InputMenuItem}
                        InputLabelProps={{ shrink: true }
                      }
                      disabled = {getDisableValue("175")}
                      variant="filled"
                        InputProps={{
                           
                            sx: {
                                
                                color:'rgba(135, 135, 135, 0.5)',
                                height:'33px',
                                borderRadius: '5px',
                                backgroundColor: '#121212',
                                boxShadow: 'inset 0px 4px 4px rgba(0, 0, 0, 0.25)',
                                padding: '0 0 15px 0',
                                textIndent: '15px !important',
                              
                               
                                '&.Mui-disabled': {
             

                                  background: 'rgba(135, 135, 135, 0.5)',
                                },
                                
                                "& input.Mui-disabled": {
                                  color: "white"
                                }
                            },
                        }}
                       
                    />}
                    {(dataOfLocation.unlocking_mode == '2' ||   dataOfLocation.unlocking_mode == '3') &&      <FormControlLabel  sx={mainThemeStyle.popUpLable}
        control={<IOSSwitch sx={{ m: 1 }} name="default_vega_state" onChange={handlecheckboxCheck} disabled = {getDisableValue("176")} checked={dataOfLocation.default_vega_state == "1" ? true : false} />}
        label={dataOfLocation.default_vega_state == "1" ? "Normally Open" : "Normally Closed"}
      />}
       {(dataOfLocation.unlocking_mode == '2' ||   dataOfLocation.unlocking_mode == '3') &&   <TextField
                        id="input-vegax-duration"
                        height={40}
                        type= 'number'
                        name="vegax_duration"
                        value={dataOfLocation.vegax_duration}
                        onChange={handleInputChange}
                        sx={mainThemeStyle.InputMenuItem}
                        InputLabelProps={{ shrink: true }
                      }
                      disabled = {getDisableValue("177")}
                      variant="filled"
                        InputProps={{
                          inputProps: { 
                            max: 99, min: 0 
                        },
                            sx: {
                                
                                color:'rgba(135, 135, 135, 0.5)',
                                height:'33px',
                                borderRadius: '5px',
                                backgroundColor: '#121212',
                                boxShadow: 'inset 0px 4px 4px rgba(0, 0, 0, 0.25)',
                                padding: '0 0 15px 0',
                                textIndent: '15px !important',
                                '&.Mui-disabled': {
             

                                  background: 'rgba(135, 135, 135, 0.5)',
                                },
                                
                                "& input.Mui-disabled": {
                                  color: "white"
                                }
                               
                                
                            },
                        }}
                       
                    />}
                    {(dataOfLocation.unlocking_mode == '2' ||   dataOfLocation.unlocking_mode == '3') && <>
                    <Typography></Typography>
                    <Divider sx={{backgroundColor:'white'}} />
                    <Typography></Typography></>}
                  {dataOfLocation.unlocking_mode == '2' &&   <TextField
                        id="input-vegax-relay2"
                        height={40}
                        type= 'text'
                        name="relay_two_fname"
                        value={dataOfLocation.relay_two_fname}
                        onChange={handleInputChange}
                        sx={mainThemeStyle.InputMenuItem}
                        InputLabelProps={{ shrink: true }
                      }
                      disabled = {getDisableValue("178")}
                      variant="filled"
                        InputProps={{
                           
                            sx: {
                                
                                color:'rgba(135, 135, 135, 0.5)',
                                height:'33px',
                                borderRadius: '5px',
                                backgroundColor: '#121212',
                                boxShadow: 'inset 0px 4px 4px rgba(0, 0, 0, 0.25)',
                                padding: '0 0 15px 0',
                                textIndent: '15px !important',
                                '&.Mui-disabled': {
             

                                  background: 'rgba(135, 135, 135, 0.5)',
                                },
                                
                                "& input.Mui-disabled": {
                                  color: "white"
                                }
                               
                                
                            },
                        }}
                       
                    />}
                     {(dataOfLocation.unlocking_mode == '2' ||   dataOfLocation.unlocking_mode == '3') &&     <FormControlLabel  sx={mainThemeStyle.popUpLable}
        control={<IOSSwitch sx={{ m: 1 }} name="default_vega_state_2" disabled = {getDisableValue("179")} onChange={handlecheckboxCheck} checked={dataOfLocation.default_vega_state_2 == "1" ? true : false } />}
        label={dataOfLocation.default_vega_state_2 == "1" ? "Normally Open" : "Normally Closed"}
      />}
                 
                     {(dataOfLocation.unlocking_mode == '2' ||   dataOfLocation.unlocking_mode == '3') &&<TextField
                        id="input-vegax2-duration"
                        height={40}
                        type= 'number'
                        name="vegax_second_duration"
                        value={dataOfLocation.vegax_second_duration}
                        onChange={handleInputChange}
                        sx={mainThemeStyle.InputMenuItem}
                        InputLabelProps={{ shrink: true }
                      }
                      disabled = {getDisableValue("180")}
                      variant="filled"
                        InputProps={{
                          inputProps: { 
                            max: 99, min: 0 
                        },
                            sx: {
                                
                                color:'rgba(135, 135, 135, 0.5)',
                                height:'33px',
                                borderRadius: '5px',
                                backgroundColor: '#121212',
                                boxShadow: 'inset 0px 4px 4px rgba(0, 0, 0, 0.25)',
                                padding: '0 0 15px 0',
                                textIndent: '15px !important',
                              
                                '&.Mui-disabled': {
             

                                  background: 'rgba(135, 135, 135, 0.5)',
                                },
                                
                                "& input.Mui-disabled": {
                                  color: "white"
                                }
                                
                            },
                        }}
                       
                    />}
                  {dataOfLocation.unlocking_mode == '4' &&<Select
            sx={{ color: 'rgba(135, 135, 135, 0.5)',
            height:'33px',
            marginTop:'4px',
            marginBottom:'3px',
            borderRadius: '5px',
            backgroundColor: '#121212',
            boxShadow: 'inset 0px 4px 4px rgba(0, 0, 0, 0.25)',
            padding: '5px 0 5px 0',
            "& .MuiSvgIcon-root": {
              color: "rgba(135, 135, 135, 0.5)",
            },
            
            '&.Mui-disabled': {
             

              background: 'rgba(135, 135, 135, 0.5)',
            },
            "& input.Mui-disabled": {
              color: "white"
            }
            }}
            disabled = {getDisableValue("239")}
            name = 'primeid'
            value={getPDKValueMain()}
          onChange={handleChangePDKMainValue}
          variant="filled"
          hiddenLabel
          MenuProps={MenuProps}>
            {pdkDeviceArray?.map(menuItem => {
                  
                  return (
                    <MenuItem  sx={
                      {
                      
                      color:"#fff",
                      backgroundColor: "#2F323A",
                      borderBottom: '.5px solid grey',
                      fontFamily: "Lato",fontSize:'12px',
                  }}
                  disabled={menuItem.disabled}
                   value={menuItem.name}>
                    <Stack direction="row" justifyContent="left"
  alignItems="center">
                  { menuItem.name == "None" && <ListItemIcon sx={{color: "red"}}>
            <DoNotDisturbIcon fontSize="small" />
          </ListItemIcon>} 
            {menuItem.name}</Stack>
                    </MenuItem>
                  );
                })}
          </Select>}
          {dataOfLocation.unlocking_mode == '4' &&<Select
            sx={{ color: 'rgba(135, 135, 135, 0.5)',
            height:'33px',
            marginTop:'4px',
            marginBottom:'3px',
            borderRadius: '5px',
            backgroundColor: '#121212',
            boxShadow: 'inset 0px 4px 4px rgba(0, 0, 0, 0.25)',
            padding: '5px 0 5px 0',
            "& .MuiSvgIcon-root": {
              color: "rgba(135, 135, 135, 0.5)",
            },
            
            '&.Mui-disabled': {
             

              background: 'rgba(135, 135, 135, 0.5)',
            },
            "& input.Mui-disabled": {
              color: "white"
            }
            }}
            disabled = {getDisableValue("239")}
            name = 'brivoprimeid'
            value={getPDKValueOptional()}
          onChange={handleChangePDKOptionalValue}
          variant="filled"
          hiddenLabel
          MenuProps={MenuProps}>
            {pdkDeviceArray?.map(menuItem => {
                  
                  return (
                    <MenuItem  sx={
                      {
                      
                      color:"#fff",
                      backgroundColor: "#2F323A",
                      borderBottom: '.5px solid grey',
                      fontFamily: "Lato",fontSize:'12px',
                  }}
                  disabled={menuItem.disabled}
                   value={menuItem.name}>
                    <Stack direction="row" justifyContent="left"
  alignItems="center">
                  { menuItem.name == "None" && <ListItemIcon sx={{color: "red"}}>
            <DoNotDisturbIcon fontSize="small" />
          </ListItemIcon>} 
            {menuItem.name}</Stack>
                    </MenuItem>
                  );
                })}
          </Select>}
      </Stack>
      </Grid>
     
      
      </Grid></Box>
      <Box sx={{display: { xs: "flex", md: "none" }}}>
           <Grid container spacing={2} padding= '10px' paddingBottom='32px' paddingTop='32px'> 
      <Grid item xs={5}>
        <Stack direction="column"  spacing={2}>
      
        <Typography  sx={mainThemeStyle.popUpLable} height={120}>PIN  Time</Typography>
        <Typography  sx={mainThemeStyle.popUpLable} height={120}>RFID/Wiegand  Time</Typography>
      <Typography sx={mainThemeStyle.popUpLable} height={40}> Resident PIN </Typography>
      <Typography sx={mainThemeStyle.popUpLable} height={40}> Guest PIN </Typography>
    
      {dataOfLocation.unlocking_mode == '2' && <Typography  sx={mainThemeStyle.popUpLable} height={40}>External Wiegand Input</Typography>}
      {dataOfLocation.unlocking_mode == '3'  &&
        <Typography  sx={mainThemeStyle.popUpLable} height={40}>Relay Failover</Typography>}
      {dataOfLocation.unlocking_mode == '2' && <Typography  sx={mainThemeStyle.popUpLable} height={40}>Relay 1 Name</Typography>}
      {dataOfLocation.unlocking_mode == '2' &&  <Typography  sx={mainThemeStyle.popUpLable} height={40}>Default {dataOfLocation.relay_one_fname != ""?dataOfLocation.relay_one_fname:' Relay 1 '} State</Typography>}
      {dataOfLocation.unlocking_mode == '3' &&  <Typography  sx={mainThemeStyle.popUpLable} height={40}>Default Failover Relay 1 State</Typography>}
   
      {dataOfLocation.unlocking_mode == '2' && <Typography  sx={mainThemeStyle.popUpLable} height={40}>Relay 2 Name</Typography>}
      {dataOfLocation.unlocking_mode == '2' && <Typography  sx={mainThemeStyle.popUpLable} height={40}>Default {dataOfLocation.relay_one_fname != ""?dataOfLocation.relay_two_fname:' Relay 2 '} State</Typography>}
      {dataOfLocation.unlocking_mode == '3' &&  <Typography  sx={mainThemeStyle.popUpLable} height={40}>Default Failover Relay 2 State</Typography>}
   
      {dataOfLocation.unlocking_mode == '2' && <Typography  sx={mainThemeStyle.popUpLable} height={40}>{ (dataOfLocation.relay_one_fname).length > 0 ? dataOfLocation.relay_one_fname + " Unlock Duration" : "Relay 1 Unlock Duration"}</Typography>}
      {dataOfLocation.unlocking_mode == '3' && <Typography  sx={mainThemeStyle.popUpLable} height={43}> Failover Relay 1 Unlock Duration (Enter Between 0 to 99 Seconds)</Typography>}
   
      {dataOfLocation.unlocking_mode == '2' && <Typography  sx={mainThemeStyle.popUpLable} height={40}>{ (dataOfLocation.relay_two_fname).length > 0 ? dataOfLocation.relay_two_fname + " Unlock Duration" : "Relay 2 Unlock Duration"}</Typography>}
      {dataOfLocation.unlocking_mode == '3' && <Typography  sx={mainThemeStyle.popUpLable} height={43}> Failover Relay 2 Unlock Duration (Enter Between 0 to 99 Seconds)</Typography>}
      {dataOfLocation.unlocking_mode == '4' &&<Typography  sx={mainThemeStyle.popUpLable} height={35}>PDK Primary Door ID</Typography>}
       {dataOfLocation.unlocking_mode == '4' && <Typography  sx={mainThemeStyle.popUpLable} height={35}>PDK Secondary Door ID</Typography>}

      
      </Stack>
      </Grid>
      
      <Grid item xs={7}>
      <Stack direction="column"  spacing={2}>
      
      
<Stack  direction="column" spacing={2} height={120}>

<Select
 sx={{ color: 'rgba(135, 135, 135, 0.5)',
 height:'33px',
 marginTop:'4px',
 marginBottom:'3px',
 borderRadius: '5px',
 backgroundColor: '#121212',
 boxShadow: 'inset 0px 4px 4px rgba(0, 0, 0, 0.25)',
 padding: '5px 0 5px 0',
 "& .MuiSvgIcon-root": {
   color: "rgba(135, 135, 135, 0.5)",
 },
 
 '&.Mui-disabled': {
  

   background: 'rgba(135, 135, 135, 0.5)',
 },
 "& input.Mui-disabled": {
   color: "white"
 }
 }}
 name = "pin_start_time"
 disabled = {getDisableValue("171")}
 value={dataOfLocation.pin_start_time}
label="Age"
onChange={handleChangeBoot}
variant="filled"
hiddenLabel
MenuProps={MenuProps}>
 {BootTime.items.map(menuItem => {
       
       return (
         <MenuItem  sx={
           {
           
           color:"#fff",
           backgroundColor: "#2F323A",
           borderBottom: '.5px solid grey',
           fontFamily: "Lato",fontSize:'12px',
       }}
        value={menuItem.label}>
           {menuItem.label}
         </MenuItem>
       );
     })}
</Select>
<Typography sx={mainThemeStyle.popUpLableAnswer} paddingTop="7px"> To </Typography>
<Box>
<Select
 sx={{ color: 'rgba(135, 135, 135, 0.5)',
 height:'33px',
 marginTop:'-10px',
 marginBottom:'3px',
 borderRadius: '5px',
 backgroundColor: '#121212',
 boxShadow: 'inset 0px 4px 4px rgba(0, 0, 0, 0.25)',
 padding: '5px 0 5px 0',
 "& .MuiSvgIcon-root": {
   color: "rgba(135, 135, 135, 0.5)",
 },
 
 '&.Mui-disabled': {
  

   background: 'rgba(135, 135, 135, 0.5)',
 },
 "& input.Mui-disabled": {
   color: "white"
 }
 }}
 name = "pin_end_time"
 disabled = {getDisableValue("171")}
 value={dataOfLocation.pin_end_time}
label="Age"
onChange={handleChangeBoot}
variant="filled"
hiddenLabel
MenuProps={MenuProps}>
 {BootTime.items.map(menuItem => {
       
       return (
         <MenuItem  sx={
           {
           
           color:"#fff",
           backgroundColor: "#2F323A",
           borderBottom: '.5px solid grey',
           fontFamily: "Lato",fontSize:'12px',
       }}
        value={menuItem.label}>
           {menuItem.label}
         </MenuItem>
       );
     })}
</Select></Box>
</Stack>
<Stack  direction="column" spacing={2} height={120}>

<Select
 sx={{ color: 'rgba(135, 135, 135, 0.5)',
 height:'33px',
 marginTop:'4px',
 marginBottom:'3px',
 borderRadius: '5px',
 backgroundColor: '#121212',
 boxShadow: 'inset 0px 4px 4px rgba(0, 0, 0, 0.25)',
 padding: '5px 0 5px 0',
 "& .MuiSvgIcon-root": {
   color: "rgba(135, 135, 135, 0.5)",
 },
 
 '&.Mui-disabled': {
  

   background: 'rgba(135, 135, 135, 0.5)',
 },
 "& input.Mui-disabled": {
   color: "white"
 }
 }}
 name = "card_start_time"
 disabled = {getDisableValue("174")}
 value={dataOfLocation.card_start_time}
label="Age"
onChange={handleChangeBoot}
variant="filled"
hiddenLabel
MenuProps={MenuProps}>
 {BootTime.items.map(menuItem => {
       
       return (
         <MenuItem  sx={
           {
           
           color:"#fff",
           backgroundColor: "#2F323A",
           borderBottom: '.5px solid grey',
           fontFamily: "Lato",fontSize:'12px',
       }}
        value={menuItem.label}>
           {menuItem.label}
         </MenuItem>
       );
     })}
</Select>
<Typography sx={mainThemeStyle.popUpLableAnswer} paddingTop="7px"> To </Typography>
<Box>
<Select
 sx={{ color: 'rgba(135, 135, 135, 0.5)',
 height:'33px',
 marginTop:'-10px',
 marginBottom:'3px',
 borderRadius: '5px',
 backgroundColor: '#121212',
 boxShadow: 'inset 0px 4px 4px rgba(0, 0, 0, 0.25)',
 padding: '5px 0 5px 0',
 "& .MuiSvgIcon-root": {
   color: "rgba(135, 135, 135, 0.5)",
 },
 
 '&.Mui-disabled': {
  

   background: 'rgba(135, 135, 135, 0.5)',
 },
 "& input.Mui-disabled": {
   color: "white"
 }
 }}
 name = "card_end_time"
 disabled = {getDisableValue("174")}
 value={dataOfLocation.card_end_time}
label="Age"
onChange={handleChangeBoot}
variant="filled"
hiddenLabel
MenuProps={MenuProps}>
 {BootTime.items.map(menuItem => {
       
       return (
         <MenuItem  sx={
           {
           
           color:"#fff",
           backgroundColor: "#2F323A",
           borderBottom: '.5px solid grey',
           fontFamily: "Lato",fontSize:'12px',
       }}
        value={menuItem.label}>
           {menuItem.label}
         </MenuItem>
       );
     })}
</Select></Box>
</Stack>
          <FormControlLabel   sx={mainThemeStyle.popUpLableNewone}
        control={<IOSSwitch sx={{ m: 1 }} name="r_pin_off" onChange={handlecheckboxCheckReverse}  disabled = {getDisableValue("172")} checked={dataOfLocation.r_pin_off == "0" ? true : false} />}
        label={dataOfLocation.r_pin_off == "0" ? "Enabled" : "Disabled"}
      />
      <FormControlLabel   sx={mainThemeStyle.popUpLableNewone}
        control={<IOSSwitch sx={{ m: 1 }} name="g_pin_off" onChange={handlecheckboxCheckReverse}  disabled = {getDisableValue("173")}  checked={dataOfLocation.g_pin_off == "0" ? true : false} />}
        label={dataOfLocation.g_pin_off == "0" ? "Enabled" : "Disabled"}
      />
            {dataOfLocation.unlocking_mode == '2' &&     <FormControlLabel  sx={mainThemeStyle.popUpLable}
        control={<IOSSwitch sx={{ m: 1 }} name="enable_exe_weigand" onChange={handlecheckboxCheck}  disabled = {getDisableValue("174")} checked={dataOfLocation.enable_exe_weigand == "1" ? true : false } />}
        label={dataOfLocation.enable_exe_weigand == "1" ? "On" : "Off"}
      />}
       {dataOfLocation.unlocking_mode == '3' &&     <FormControlLabel  sx={mainThemeStyle.popUpLable} disabled = {getDisableValue("174")}
        control={<IOSSwitch sx={{ m: 1 }} name="relay_fail_over" onChange={handlecheckboxCheck} checked={dataOfLocation.relay_fail_over == "1" ? true : false } />}
        label={dataOfLocation.relay_fail_over == "1" ? "On" : "Off"}
      />}
         
          {dataOfLocation.unlocking_mode == '2' && <TextField
                        id="input-vegax-relay1"
                        height={40}
                        type= 'text'
                        name="relay_one_fname"
                        value={dataOfLocation.relay_one_fname}
                        onChange={handleInputChange}
                        sx={mainThemeStyle.InputMenuItem}
                        InputLabelProps={{ shrink: true }
                      }
                      disabled = {getDisableValue("175")}
                      variant="filled"
                        InputProps={{
                           
                            sx: {
                                
                                color:'rgba(135, 135, 135, 0.5)',
                                height:'33px',
                                borderRadius: '5px',
                                backgroundColor: '#121212',
                                boxShadow: 'inset 0px 4px 4px rgba(0, 0, 0, 0.25)',
                                padding: '0 0 15px 0',
                                textIndent: '15px !important',
                              
                                '&.Mui-disabled': {
             

                                  background: 'rgba(135, 135, 135, 0.5)',
                                },
                                
                                "& input.Mui-disabled": {
                                  color: "white"
                                }
                                
                            },
                        }}
                       
                    />}
                    {(dataOfLocation.unlocking_mode == '2' ||   dataOfLocation.unlocking_mode == '3') &&      <FormControlLabel  sx={mainThemeStyle.popUpLable}
        control={<IOSSwitch sx={{ m: 1 }} name="default_vega_state"  disabled = {getDisableValue("176")} onChange={handlecheckboxCheck}  checked={dataOfLocation.default_vega_state == "1" ? true : false} />}
        label={dataOfLocation.default_vega_state == "1" ? "Normally Open" : "Normally Closed"}
      />}
                  {dataOfLocation.unlocking_mode == '2' &&   <TextField
                        id="input-vegax-relay2"
                        height={40}
                        type= 'text'
                        name="relay_two_fname"
                        value={dataOfLocation.relay_two_fname}
                        onChange={handleInputChange}
                        sx={mainThemeStyle.InputMenuItem}
                        InputLabelProps={{ shrink: true }
                      }
                      variant="filled"
                      disabled = {getDisableValue("178")}
                        InputProps={{
                           
                            sx: {
                                
                                color:'rgba(135, 135, 135, 0.5)',
                                height:'33px',
                                borderRadius: '5px',
                                backgroundColor: '#121212',
                                boxShadow: 'inset 0px 4px 4px rgba(0, 0, 0, 0.25)',
                                padding: '0 0 15px 0',
                                textIndent: '15px !important',
                                '&.Mui-disabled': {
             

                                  background: 'rgba(135, 135, 135, 0.5)',
                                },
                                
                                "& input.Mui-disabled": {
                                  color: "white"
                                }
                               
                                
                            },
                        }}
                       
                    />}
                     {(dataOfLocation.unlocking_mode == '2' ||   dataOfLocation.unlocking_mode == '3') &&     <FormControlLabel  sx={mainThemeStyle.popUpLable}
        control={<IOSSwitch sx={{ m: 1 }} name="default_vega_state_2"  disabled = {getDisableValue("179")} onChange={handlecheckboxCheck} checked={dataOfLocation.default_vega_state_2 == "1" ? true : false } />}
        label={dataOfLocation.default_vega_state_2 == "1" ? "Normally Open" : "Normally Closed"}
      />}
                  {(dataOfLocation.unlocking_mode == '2' ||   dataOfLocation.unlocking_mode == '3') &&   <TextField
                        id="input-vegax-duration"
                        height={40}
                        type= 'number'
                        name="vegax_duration"
                        value={dataOfLocation.vegax_duration}
                        onChange={handleInputChange}
                        sx={mainThemeStyle.InputMenuItem}
                        InputLabelProps={{ shrink: true }
                      }
                      disabled = {getDisableValue("177")}
                      variant="filled"
                        InputProps={{
                          inputProps: { 
                            max: 99, min: 0 
                        },
                            sx: {
                                
                                color:'rgba(135, 135, 135, 0.5)',
                                height:'33px',
                                borderRadius: '5px',
                                backgroundColor: '#121212',
                                boxShadow: 'inset 0px 4px 4px rgba(0, 0, 0, 0.25)',
                                padding: '0 0 15px 0',
                                textIndent: '15px !important',
                                '&.Mui-disabled': {
             

                                  background: 'rgba(135, 135, 135, 0.5)',
                                },
                                
                                "& input.Mui-disabled": {
                                  color: "white"
                                }
                               
                                
                            },
                        }}
                       
                    />}
                     {(dataOfLocation.unlocking_mode == '2' ||   dataOfLocation.unlocking_mode == '3') &&<TextField
                        id="input-vegax2-duration"
                        height={40}
                        type= 'number'
                        name="vegax_second_duration"
                        value={dataOfLocation.vegax_second_duration}
                        onChange={handleInputChange}
                        sx={mainThemeStyle.InputMenuItem}
                        InputLabelProps={{ shrink: true }
                      }
                      disabled = {getDisableValue("180")}
                      variant="filled"
                        InputProps={{
                          inputProps: { 
                            max: 99, min: 0 
                        },
                            sx: {
                                
                                color:'rgba(135, 135, 135, 0.5)',
                                height:'33px',
                                borderRadius: '5px',
                                backgroundColor: '#121212',
                                boxShadow: 'inset 0px 4px 4px rgba(0, 0, 0, 0.25)',
                                padding: '0 0 15px 0',
                                textIndent: '15px !important',
                              
                                '&.Mui-disabled': {
             

                                  background: 'rgba(135, 135, 135, 0.5)',
                                },
                                
                                "& input.Mui-disabled": {
                                  color: "white"
                                }
                                
                            },
                        }}
                       
                    />}
                   {dataOfLocation.unlocking_mode == '4' &&<Select
            sx={{ color: 'rgba(135, 135, 135, 0.5)',
            height:'33px',
            marginTop:'4px',
            marginBottom:'3px',
            borderRadius: '5px',
            backgroundColor: '#121212',
            boxShadow: 'inset 0px 4px 4px rgba(0, 0, 0, 0.25)',
            padding: '5px 0 5px 0',
            "& .MuiSvgIcon-root": {
              color: "rgba(135, 135, 135, 0.5)",
            },
            
            '&.Mui-disabled': {
             

              background: 'rgba(135, 135, 135, 0.5)',
            },
            "& input.Mui-disabled": {
              color: "white"
            }
            }}
            disabled = {getDisableValue("239")}
            name = 'primeid'
            value={getPDKValueMain()}
          onChange={handleChangePDKMainValue}
          variant="filled"
          hiddenLabel
          MenuProps={MenuProps}>
            {pdkDeviceArray?.map(menuItem => {
                  
                  return (
                    <MenuItem  sx={
                      {
                      
                      color:"#fff",
                      backgroundColor: "#2F323A",
                      borderBottom: '.5px solid grey',
                      fontFamily: "Lato",fontSize:'12px',
                  }}
                  disabled={menuItem.disabled}
                   value={menuItem.name}>
                    <Stack direction="row" justifyContent="left"
  alignItems="center">
                  { menuItem.name == "None" && <ListItemIcon sx={{color: "red"}}>
            <DoNotDisturbIcon fontSize="small" />
          </ListItemIcon>} 
            {menuItem.name}</Stack>
                    </MenuItem>
                  );
                })}
          </Select>}
          {dataOfLocation.unlocking_mode == '4' &&<Select
            sx={{ color: 'rgba(135, 135, 135, 0.5)',
            height:'33px',
            marginTop:'4px',
            marginBottom:'3px',
            borderRadius: '5px',
            backgroundColor: '#121212',
            boxShadow: 'inset 0px 4px 4px rgba(0, 0, 0, 0.25)',
            padding: '5px 0 5px 0',
            "& .MuiSvgIcon-root": {
              color: "rgba(135, 135, 135, 0.5)",
            },
            
            '&.Mui-disabled': {
             

              background: 'rgba(135, 135, 135, 0.5)',
            },
            "& input.Mui-disabled": {
              color: "white"
            }
            }}
            disabled = {getDisableValue("239")}
            name = 'brivoprimeid'
            value={getPDKValueOptional()}
          onChange={handleChangePDKOptionalValue}
          variant="filled"
          hiddenLabel
          MenuProps={MenuProps}>
            {pdkDeviceArray?.map(menuItem => {
                  
                  return (
                    <MenuItem  sx={
                      {
                      
                      color:"#fff",
                      backgroundColor: "#2F323A",
                      borderBottom: '.5px solid grey',
                      fontFamily: "Lato",fontSize:'12px',
                  }}
                  disabled={menuItem.disabled}
                   value={menuItem.name}>
                    <Stack direction="row" justifyContent="left"
  alignItems="center">
                  { menuItem.name == "None" && <ListItemIcon sx={{color: "red"}}>
            <DoNotDisturbIcon fontSize="small" />
          </ListItemIcon>} 
            {menuItem.name}</Stack>
                    </MenuItem>
                  );
                })}
          </Select>}
          
      </Stack>
      </Grid>
     
     
      
     
      
      </Grid></Box>

</>)}

{(currentTab == 2  &&  dataOfLocation.cam_brand == "Virtual Panel" && <>
<div  sx={{
       right: '54px' ,left: '54px',borderRadius:'0px'}}>
               <div style={{ display: 'flex' }}>
                   <Typography variant="h6" component="div" style={{  paddingLeft: '16px',paddingTop: '16px',flexGrow: 1,color:'#fff' }}>
                       Relay Settings
                   </Typography>
                   
               </div>
              
           </div>  <Divider sx={{backgroundColor:'white'}} />
           <Box sx={{display: { xs: "none", md: "block" }}}>
          
           <Grid container spacing={2} padding= '32px'> 
      <Grid item xs={2.5}>
        <Stack direction="column"  spacing={2}>
      
     {/* <Typography  sx={mainThemeStyle.popUpLable} height={40}>PIN Schedule Time</Typography>
     {dataOfLocation.unlocking_mode == '2' && <Typography  sx={mainThemeStyle.popUpLable} height={40}>Enable External Wiegand Input</Typography>}
        {dataOfLocation.unlocking_mode == '2' && dataOfLocation.enable_exe_weigand == "1" &&
        <Typography  sx={mainThemeStyle.popUpLable} height={40}>RFID/Wiegand Schedule Time</Typography>} */}
        <Typography  sx={mainThemeStyle.popUpLable} height={40}>Unlocking Mode </Typography>
       {dataOfLocation.unlocking_mode != '1'  && dataOfLocation.unlocking_mode != '4' && <Typography sx={mainThemeStyle.popUpLable} height={40}> Relay Hostname </Typography> }
       
       {dataOfLocation.unlocking_mode != '1' && dataOfLocation.unlocking_mode != '4' && <Typography  sx={mainThemeStyle.popUpLable} height={40}># of System Relays</Typography>}
      {dataOfLocation.unlocking_mode != '1'  && dataOfLocation.unlocking_mode != '4' && <Typography  sx={mainThemeStyle.popUpLable} height={40}>Primary Relay Duration</Typography>}
      {dataOfLocation.unlocking_mode != '1'  && dataOfLocation.unlocking_mode != '4' && <Typography  sx={mainThemeStyle.popUpLable} height={40}>Floor Relay Duration</Typography>}
       
       {dataOfLocation.unlocking_mode != '1'  && dataOfLocation.unlocking_mode != '4' && <Typography sx={mainThemeStyle.popUpLable} height={40}> Resident PIN </Typography> }
       {dataOfLocation.unlocking_mode != '1'  && dataOfLocation.unlocking_mode != '4' && <Typography sx={mainThemeStyle.popUpLable} height={40}> Guest PIN </Typography>}
       {dataOfLocation.unlocking_mode == '1' &&<Typography  sx={mainThemeStyle.popUpLable} height={35}>Brivo Primary Door Id</Typography>}
       {dataOfLocation.unlocking_mode == '1' && <Typography  sx={mainThemeStyle.popUpLable} height={35}># of Brivo Floors</Typography>}
       {dataOfLocation.unlocking_mode == '1' && <Typography  sx={mainThemeStyle.popUpLable} height={40}>In-Call Relay Triggers</Typography>}
     
       {dataOfLocation.unlocking_mode == '4' &&<Typography  sx={mainThemeStyle.popUpLable} height={35}>PDK Primary Door ID</Typography>}
       {dataOfLocation.unlocking_mode == '4' && <Typography  sx={mainThemeStyle.popUpLable} height={35}>PDK Secondary Door ID</Typography>}
      </Stack>
      </Grid>
      
      <Grid item xs={2.5}>
      <Stack direction="column"  spacing={2}>
      <Select
            sx={{ color: 'rgba(135, 135, 135, 0.5)',
            height:'33px',
           
            marginBottom:'3px',
            borderRadius: '5px',
            backgroundColor: '#121212',
            boxShadow: 'inset 0px 4px 4px rgba(0, 0, 0, 0.25)',
            padding: '5px 0 5px 0',
            "& .MuiSvgIcon-root": {
              color: "rgba(135, 135, 135, 0.5)",
            },
            
            '&.Mui-disabled': {
             

              background: 'rgba(135, 135, 135, 0.5)',
            },
            "& input.Mui-disabled": {
              color: "white"
            }
            }}
            name = "unlocking_mode"
            disabled = {getDisableValue("158")}
            value={GetUnlockingModeQR()}
          label="Age"
          onChange={handleSetUnlockingModeQR}
          variant="filled"
          hiddenLabel
          MenuProps={MenuProps}>
            {UnlockingModeQR.items.map(menuItem => {
                  
                  return (
                    <MenuItem  sx={
                      {
                      
                      color:"#fff",
                      backgroundColor: "#2F323A",
                      borderBottom: '.5px solid grey',
                      fontFamily: "Lato",fontSize:'12px',
                  }}
                   value={menuItem.label}>
                      {menuItem.label}
                    </MenuItem>
                  );
                })}
          </Select>
          {dataOfLocation.unlocking_mode != '1' && dataOfLocation.unlocking_mode != '4' && <TextField
                        id="input-relay-host"
                        height={80}
                        type= 'text'
                        name="realy_hostname"
                        value={dataOfLocation.realy_hostname}
                        onChange={handleInputChange}
                        placeholder='api.controlbyweb.cloud/DAT/api-key'
                        sx={mainThemeStyle.InputMenuItem}
                        InputLabelProps={{ shrink: true }
                      }
                      disabled = {getDisableValue("185")}
                      variant="filled"
                        InputProps={{
                          
                            sx: {
                                
                                color:'rgba(135, 135, 135, 0.5)',
                                height:'33px',
                                borderRadius: '5px',
                                backgroundColor: '#121212',
                                boxShadow: 'inset 0px 4px 4px rgba(0, 0, 0, 0.25)',
                                padding: '0 0 15px 0',
                                textIndent: '15px !important',
                                '&.Mui-disabled': {
             

                                  background: 'rgba(135, 135, 135, 0.5)',
                                },
                                
                                "& input.Mui-disabled": {
                                  color: "white"
                                }
                               
                                
                            },
                        }}
                       
                    />}


{dataOfLocation.unlocking_mode != '1'  && dataOfLocation.unlocking_mode != '4' && <TextField
        id="total-relays"
        type="number"
        value={dataOfLocation.elevator_access.totalrelays}
        sx={mainThemeStyle.InputMenuItem}
                        InputLabelProps={{ shrink: true }
                      }
                      disabled={getDisableValue("183")}
                      name="totalrelays"
                      onChange={handleChangeEleValue}
                      variant="filled"
                        InputProps={{
                          inputProps: { 
                            max: 66, min: 0 
                        },
                            sx: {
                              
                                color:'rgba(135, 135, 135, 0.5)',
                                height:'33px',
                                borderRadius: '5px',
                                backgroundColor: '#121212',
                                boxShadow: 'inset 0px 4px 4px rgba(0, 0, 0, 0.25)',
                                padding: '0 0 15px 0',
                                textIndent: '15px !important',
                                '&.Mui-disabled': {
             

                                  background: 'rgba(135, 135, 135, 0.5)',
                                },
                                
                                "& input.Mui-disabled": {
                                  color: "white"
                                }
                               
                                
                            },
                        }}
                       
      />}
      {dataOfLocation.unlocking_mode != '1'  && dataOfLocation.unlocking_mode != '4' && <TextField
                        id="input-vegax-duration"
                        height={40}
                        type= 'number'
                        name="vegax_duration"
                        value={dataOfLocation.vegax_duration}
                        onChange={handleInputChange}
                        sx={mainThemeStyle.InputMenuItem}
                        InputLabelProps={{ shrink: true }
                      }
                      disabled = {getDisableValue("177")}
                      variant="filled"
                        InputProps={{
                          inputProps: { 
                            max: 99, min: 0 
                        },
                            sx: {
                                
                                color:'rgba(135, 135, 135, 0.5)',
                                height:'33px',
                                borderRadius: '5px',
                                backgroundColor: '#121212',
                                boxShadow: 'inset 0px 4px 4px rgba(0, 0, 0, 0.25)',
                                padding: '0 0 15px 0',
                                textIndent: '15px !important',
                              
                                '&.Mui-disabled': {
             

                                  background: 'rgba(135, 135, 135, 0.5)',
                                },
                                
                                "& input.Mui-disabled": {
                                  color: "white"
                                }
                                
                            },
                        }}
                       
                    />}
      {dataOfLocation.unlocking_mode != '1'  && dataOfLocation.unlocking_mode != '4' && <TextField
                        id="input-vegax2-duration"
                        height={40}
                        type= 'number'
                        name="vegax_second_duration"
                        value={dataOfLocation.vegax_second_duration}
                        onChange={handleInputChange}
                        sx={mainThemeStyle.InputMenuItem}
                        InputLabelProps={{ shrink: true }
                      }
                      disabled = {getDisableValue("180")}
                      variant="filled"
                        InputProps={{
                          inputProps: { 
                            max: 99, min: 0 
                        },
                            sx: {
                                
                                color:'rgba(135, 135, 135, 0.5)',
                                height:'33px',
                                borderRadius: '5px',
                                backgroundColor: '#121212',
                                boxShadow: 'inset 0px 4px 4px rgba(0, 0, 0, 0.25)',
                                padding: '0 0 15px 0',
                                textIndent: '15px !important',
                                '&.Mui-disabled': {
             

                                  background: 'rgba(135, 135, 135, 0.5)',
                                },
                                
                                "& input.Mui-disabled": {
                                  color: "white"
                                }
                               
                                
                            },
                        }}
                       
                    />}
      {dataOfLocation.unlocking_mode != '1'  && dataOfLocation.unlocking_mode != '4' &&    <FormControlLabel   sx={mainThemeStyle.popUpLableNewone}
        control={<IOSSwitch sx={{ m: 1 }} name="r_pin_off"  disabled = {getDisableValue("172")} onChange={handlecheckboxCheckReverse}  checked={dataOfLocation.r_pin_off == "0" ? true : false} />}
        label={dataOfLocation.r_pin_off == "0" ? "Enabled" : "Disabled"}
      /> }
     {dataOfLocation.unlocking_mode != '1' && dataOfLocation.unlocking_mode != '4' && <FormControlLabel   sx={mainThemeStyle.popUpLableNewone}
        control={<IOSSwitch sx={{ m: 1 }} name="g_pin_off"  disabled = {getDisableValue("173")} onChange={handlecheckboxCheckReverse}  checked={dataOfLocation.g_pin_off == "0" ? true : false} />}
        label={dataOfLocation.g_pin_off == "0" ? "Enabled" : "Disabled"}
      /> }

    {dataOfLocation.unlocking_mode == '1' &&
    
    <Autocomplete
            style={{marginTop: 0,minWidth: 250}}
            sx={{  "& .MuiAutocomplete-inputRoot": {
              color: "rgba(170, 170, 170,1)" },"& .MuiSvgIcon-root": {
                color: "rgba(170, 170, 170, 1)",
              },
             "& .Mui-disabled": {
               color: 'rgba(135, 135, 135, 0.5) ',
               "& .MuiSvgIcon-root": {
                 color: "rgba(135, 135, 135, 0.5)"
               }
             },
             "& .MuiInputBase-root.Mui-disabled": {
               backgroundColor: 'rgba(135, 135, 135, 0.5)',
               color: 'white',
             },
             
             }}
            disablePortal
            disabled = {getDisableValue("184")}
            id="brivoprimeid"
            name = 'brivoprimeid'
            value={options.find(option => option.value === getBrivoValueMain())}
          onChange={handleChangeBrivoDoorValue}
          variant="filled"
          
          options={options}
          getOptionLabel={(option) => option.label}
          PaperComponent={({ children }) => (
            <Paper style={{ backgroundColor: "#1E1E1E" ,color:"#AFAFAF", "&:hover":{backgroundColor: "#fff"}}}>{children}</Paper>
          )}
          renderInput={(params) => <TextField {...params} 
           variant="outlined" size="small" style={{ backgroundColor: "#121212",borderRadius: '5px' ,color:"#fff",
        
            
        }} />}
        renderOption={(props, option) => (
          <MenuItem
            {...props}
            key={option.id}
            sx={{
              color: "#fff",
              backgroundColor: "#2F323A",
              borderBottom: '.5px solid grey',
              fontFamily: "Lato",
              fontSize: '12px',
            }}
            disabled={option.disabled}
          >
            <Stack direction="row" justifyContent="left" alignItems="center">
              {option.label === "None" && (
                <ListItemIcon sx={{ color: "red" }}>
                  <DoNotDisturbIcon fontSize="small" />
                </ListItemIcon>
              )}
              {option.label}
            </Stack>
          </MenuItem>
        )}
          ></Autocomplete> 
    
    /* <Select
            sx={{ color: 'rgba(135, 135, 135, 0.5)',
            height:'33px',
            marginTop:'4px',
            marginBottom:'3px',
            borderRadius: '5px',
            backgroundColor: '#121212',
            boxShadow: 'inset 0px 4px 4px rgba(0, 0, 0, 0.25)',
            padding: '5px 0 5px 0',
            "& .MuiSvgIcon-root": {
              color: "rgba(135, 135, 135, 0.5)",
            },
            
            '&.Mui-disabled': {
             

              background: 'rgba(135, 135, 135, 0.5)',
            },
            "& input.Mui-disabled": {
              color: "white"
            }
            }}
            disabled = {getDisableValue("184")}
            name = 'brivoprimeid'
            value={getBrivoValueMain()}
          onChange={handleChangeBrivoDoorValue}
          variant="filled"
          hiddenLabel
          MenuProps={MenuProps}>
            {brivoDoorArray?.map(menuItem => {
                  
                  return (
                    <MenuItem  sx={
                      {
                      
                      color:"#fff",
                      backgroundColor: "#2F323A",
                      borderBottom: '.5px solid grey',
                      fontFamily: "Lato",fontSize:'12px',
                  }}
                  disabled={menuItem.disabled}
                   value={menuItem.name}>
                    <Stack direction="row" justifyContent="left"
  alignItems="center">
                  { menuItem.name == "None" && <ListItemIcon sx={{color: "red"}}>
            <DoNotDisturbIcon fontSize="small" />
          </ListItemIcon>} 
            {menuItem.name}</Stack>
                    </MenuItem>
                  );
                })}
          </Select> */
          /*  <Autocomplete
           style={{marginTop: 15,minWidth: 250}}
            sx={{  "& .MuiAutocomplete-inputRoot": {
             color: "rgba(170, 170, 170,1)" },"& .MuiSvgIcon-root": {
               color: "rgba(170, 170, 170, 1)",
             },}}
           disablePortal
           disabled = {getDisableValue("184")}
           id="brivoprimeid"
           name = 'brivoprimeid'
           value={getBrivoValueMain()}
         onChange={handleChangeBrivoDoorValue}
         variant="filled"
         hiddenLabel
        
         //options={brivoDoorArray.map((option) => option.name)}
         
          options={brivoDoorArray}
           getOptionLabel={(option) => option.name}
           getOptionDisabled={(option) => !!option.disabled}
           isOptionEqualToValue={(option, value) => option.name === value.name}
         PaperComponent={({ children }) => (
           <Paper style={{ backgroundColor: "#1E1E1E" ,color:"#AFAFAF", "&:hover":{backgroundColor: "#fff"}}}>{children}</Paper>
         )}
         renderInput={(params) => <TextField {...params}  variant="outlined" size="small" style={{ backgroundColor: "#121212",borderRadius: '5px' ,color:"#fff",
       
           
       }} />}
         ></Autocomplete> */
          
          
          }
  {/*    {dataOfLocation.unlocking_mode == '1' &&
     <Typography
        height={40}
        
        sx={mainThemeStyle.popUpLableAnswer}
                       
        >{dataOfLocation.brivo_access.brivofloors}</Typography>} */}

{dataOfLocation.unlocking_mode == '1' &&<TextField
                        id="brivofloors"
                        height={40}
                        type= 'number'
                        name="brivofloors"
                        value={dataOfLocation.brivo_access.brivofloors}
                        onChange={handleChangeBrivoFloorValue}
                        sx={{
                          ...mainThemeStyle.InputMenuItem,
                          marginTop:'10px',
                         
                        }}
                        InputLabelProps={{ shrink: true }
                       
                      }
                      disabled = {getDisableValue("184")}
                      variant="filled"
                        InputProps={{
                          inputProps: { 
                            max:(dataOfLocation.brivo_array.length), min: 0 
                        },
                            sx: {
                                
                                color:'rgba(135, 135, 135, 0.5)',
                                height:'33px',
                                borderRadius: '5px',
                                backgroundColor: '#121212',
                                boxShadow: 'inset 0px 4px 4px rgba(0, 0, 0, 0.25)',
                                padding: '0 0 15px 0',
                                textIndent: '15px !important',
                                '&.Mui-disabled': {
             

                                  background: 'rgba(135, 135, 135, 0.5)',
                                },
                                
                                "& input.Mui-disabled": {
                                  color: "white"
                                }
                               
                                
                            },
                        }}
                       
                    />}
                     {dataOfLocation.unlocking_mode == '1' && <FormControlLabel   sx={mainThemeStyle.popUpLableNewone} height={40}
        control={<IOSSwitch sx={{ m: 1 }} name="icrt"  disabled = {getDisableValue("184")} onChange={handleBrivoInCallRelayTriggerCheck}  checked={dataOfLocation.brivo_access.icrt == null ? false : (dataOfLocation.brivo_access.icrt == "1" ? true : false)} />}
        label={dataOfLocation.brivo_access.icrt == null ? "Disabled" : (dataOfLocation.brivo_access.icrt == "1" ? "Enabled" : "Disabled")}
      /> }

{dataOfLocation.unlocking_mode == '4' &&<Select
            sx={{ color: 'rgba(135, 135, 135, 0.5)',
            height:'33px',
            marginTop:'4px',
            marginBottom:'3px',
            borderRadius: '5px',
            backgroundColor: '#121212',
            boxShadow: 'inset 0px 4px 4px rgba(0, 0, 0, 0.25)',
            padding: '5px 0 5px 0',
            "& .MuiSvgIcon-root": {
              color: "rgba(135, 135, 135, 0.5)",
            },
            
            '&.Mui-disabled': {
             

              background: 'rgba(135, 135, 135, 0.5)',
            },
            "& input.Mui-disabled": {
              color: "white"
            }
            }}
            disabled = {getDisableValue("239")}
            name = 'primeid'
            value={getPDKValueMain()}
          onChange={handleChangePDKMainValue}
          variant="filled"
          hiddenLabel
          MenuProps={MenuProps}>
            {pdkDeviceArray?.map(menuItem => {
                  
                  return (
                    <MenuItem  sx={
                      {
                      
                      color:"#fff",
                      backgroundColor: "#2F323A",
                      borderBottom: '.5px solid grey',
                      fontFamily: "Lato",fontSize:'12px',
                  }}
                  disabled={menuItem.disabled}
                   value={menuItem.name}>
                    <Stack direction="row" justifyContent="left"
  alignItems="center">
                  { menuItem.name == "None" && <ListItemIcon sx={{color: "red"}}>
            <DoNotDisturbIcon fontSize="small" />
          </ListItemIcon>} 
            {menuItem.name}</Stack>
                    </MenuItem>
                  );
                })}
          </Select>}
          {dataOfLocation.unlocking_mode == '4' &&<Select
            sx={{ color: 'rgba(135, 135, 135, 0.5)',
            height:'33px',
            marginTop:'4px',
            marginBottom:'3px',
            borderRadius: '5px',
            backgroundColor: '#121212',
            boxShadow: 'inset 0px 4px 4px rgba(0, 0, 0, 0.25)',
            padding: '5px 0 5px 0',
            "& .MuiSvgIcon-root": {
              color: "rgba(135, 135, 135, 0.5)",
            },
            
            '&.Mui-disabled': {
             

              background: 'rgba(135, 135, 135, 0.5)',
            },
            "& input.Mui-disabled": {
              color: "white"
            }
            }}
            disabled = {getDisableValue("239")}
            name = 'brivoprimeid'
            value={getPDKValueOptional()}
          onChange={handleChangePDKOptionalValue}
          variant="filled"
          hiddenLabel
          MenuProps={MenuProps}>
            {pdkDeviceArray?.map(menuItem => {
                  
                  return (
                    <MenuItem  sx={
                      {
                      
                      color:"#fff",
                      backgroundColor: "#2F323A",
                      borderBottom: '.5px solid grey',
                      fontFamily: "Lato",fontSize:'12px',
                  }}
                  disabled={menuItem.disabled}
                   value={menuItem.name}>
                    <Stack direction="row" justifyContent="left"
  alignItems="center">
                  { menuItem.name == "None" && <ListItemIcon sx={{color: "red"}}>
            <DoNotDisturbIcon fontSize="small" />
          </ListItemIcon>} 
            {menuItem.name}</Stack>
                    </MenuItem>
                  );
                })}
          </Select>}
      </Stack>
      </Grid>
      <Grid item xs={1}>
    
      </Grid>
      <Grid item xs={2.5}>
        <Stack direction="column"  spacing={2}>
        {/* {dataOfLocation.unlocking_mode != '1' && <>
      <Typography  sx={mainThemeStyle.popUpLable} height={40}>Relay 1 Friendly Name</Typography>
      <Typography  sx={mainThemeStyle.popUpLable} height={40}>{ (dataOfLocation.relay_one_fname).length > 0 ? dataOfLocation.relay_one_fname + " Unlock Duration (Enter Between 0 to 99 Seconds)" : "Relay 1 Unlock Duration (Enter Between 0 to 99 Seconds)"}</Typography>
      <Typography></Typography>
      <Divider sx={{backgroundColor:'white'}} />
      <Typography></Typography>
      <Typography  sx={mainThemeStyle.popUpLable} height={40}>Relay 2 Friendly Name</Typography>
      <Typography  sx={mainThemeStyle.popUpLable} height={40}>{ (dataOfLocation.relay_two_fname).length > 0 ? dataOfLocation.relay_two_fname + " Unlock Duration (Enter Between 0 to 99 Seconds)" : "Relay 2 Unlock Duration (Enter Between 0 to 99 Seconds)"}</Typography></>}
     */} {/*  {dataOfLocation.unlocking_mode == '1' && <>
      <Typography  sx={mainThemeStyle.popUpLable} height={40}>Brivo Door ID 1</Typography>
      <Typography  sx={mainThemeStyle.popUpLable} height={40}>Brivo Door ID 2</Typography>
      </>} */}
      
       {dataOfLocation.unlocking_mode != '1'   && dataOfLocation.unlocking_mode != '4' && <>
      <Typography  sx={mainThemeStyle.popUpLable} height={40}>Disable Primary Relay</Typography>
      <Typography  sx={mainThemeStyle.popUpLable} height={40}>Disable Floor Relay</Typography>
      <Typography  sx={mainThemeStyle.popUpLable} height={40}>Disable Floor Relay for App Unlock</Typography>
      <Typography  sx={mainThemeStyle.popUpLable} height={40}>Primary Relay Friendly Name</Typography>
      </>}
         {(dataOfLocation.unlocking_mode == '1' || dataOfLocation.unlocking_mode == '4') && <Typography sx={mainThemeStyle.popUpLable} height={40}> Resident PIN </Typography> }
       {(dataOfLocation.unlocking_mode == '1' || dataOfLocation.unlocking_mode == '4') && <Typography sx={mainThemeStyle.popUpLable} height={40}> Guest PIN </Typography>}

     
     
      </Stack>
      </Grid>
      <Grid item xs={2.5}>
        <Stack direction="column"  spacing={2}>
        {dataOfLocation.unlocking_mode != '1'  && dataOfLocation.unlocking_mode != '4' && <>
        <FormControlLabel   sx={{ marginLeft: '0px',  height:'2.18rem',
            color:'#fff',}}
        control={<IOSSwitch sx={{ m: 1 }} name="diselevator"  disabled={getDisableValue("183")} onChange={handleChangeEleCheckValue} checked={dataOfLocation.elevator_access.diselevator == "1" ? true : false} />}
        label=""
      />
       <FormControlLabel  sx={mainThemeStyle.popUpLable}
        control={<IOSSwitch sx={{ m: 1 }} name="disrelease" disabled={getDisableValue("183")} onChange={handleChangeEleCheckValue} checked={dataOfLocation.elevator_access.disrelease == "1" ? true : false} />}
        label=""
      />
       <FormControlLabel  sx={mainThemeStyle.popUpLable}
        control={<IOSSwitch sx={{ m: 1 }} name="diselapp" disabled={getDisableValue("183")} onChange={handleChangeEleCheckValue} checked={dataOfLocation.elevator_access.diselapp == "1" ? true : false} />}
        label=""
      />
       <TextField
                        id="input-vegax-relay1"
                        height={40}
                        type= 'text'
                        name="relay_one_fname"
                        value={dataOfLocation.relay_one_fname}
                        onChange={handleInputChange}
                        sx={mainThemeStyle.InputMenuItem}
                        InputLabelProps={{ shrink: true }
                      }
                      disabled = {getDisableValue("175")}
                      variant="filled"
                        InputProps={{
                           
                            sx: {
                                
                                color:'rgba(135, 135, 135, 0.5)',
                                height:'33px',
                                borderRadius: '5px',
                                backgroundColor: '#121212',
                                boxShadow: 'inset 0px 4px 4px rgba(0, 0, 0, 0.25)',
                                padding: '0 0 15px 0',
                                textIndent: '15px !important',
                              
                                '&.Mui-disabled': {
             

                                  background: 'rgba(135, 135, 135, 0.5)',
                                },
                                
                                "& input.Mui-disabled": {
                                  color: "white"
                                }
                                
                            },
                        }}
                       
                    />
      </>}
        {/* {dataOfLocation.unlocking_mode != '1' && <>
        
            <TextField
                        id="input-vegax-relay1"
                        height={40}
                        type= 'text'
                        name="relay_one_fname"
                        value={dataOfLocation.relay_one_fname}
                        onChange={handleInputChange}
                        sx={mainThemeStyle.InputMenuItem}
                        InputLabelProps={{ shrink: true }
                      }
                      disabled = {getDisableValue("175")}
                      variant="filled"
                        InputProps={{
                           
                            sx: {
                                
                                color:'rgba(135, 135, 135, 0.5)',
                                height:'33px',
                                borderRadius: '5px',
                                backgroundColor: '#121212',
                                boxShadow: 'inset 0px 4px 4px rgba(0, 0, 0, 0.25)',
                                padding: '0 0 15px 0',
                                textIndent: '15px !important',
                              
                                '&.Mui-disabled': {
             

                                  background: 'rgba(135, 135, 135, 0.5)',
                                },
                                
                                "& input.Mui-disabled": {
                                  color: "white"
                                }
                                
                            },
                        }}
                       
                    />
         <TextField
                        id="input-vegax-duration"
                        height={40}
                        type= 'number'
                        name="vegax_duration"
                        value={dataOfLocation.vegax_duration}
                        onChange={handleInputChange}
                        sx={mainThemeStyle.InputMenuItem}
                        InputLabelProps={{ shrink: true }
                      }
                      disabled = {getDisableValue("177")}
                      variant="filled"
                        InputProps={{
                          inputProps: { 
                            max: 99, min: 0 
                        },
                            sx: {
                                
                                color:'rgba(135, 135, 135, 0.5)',
                                height:'33px',
                                borderRadius: '5px',
                                backgroundColor: '#121212',
                                boxShadow: 'inset 0px 4px 4px rgba(0, 0, 0, 0.25)',
                                padding: '0 0 15px 0',
                                textIndent: '15px !important',
                              
                                '&.Mui-disabled': {
             

                                  background: 'rgba(135, 135, 135, 0.5)',
                                },
                                
                                "& input.Mui-disabled": {
                                  color: "white"
                                }
                                
                            },
                        }}
                       
                    />
                    <Typography></Typography>
                    <Divider sx={{backgroundColor:'white'}} />
                    <Typography></Typography>
                 <TextField
                        id="input-vegax-relay2"
                        height={40}
                        type= 'text'
                        name="relay_two_fname"
                        value={dataOfLocation.relay_two_fname}
                        onChange={handleInputChange}
                        sx={mainThemeStyle.InputMenuItem}
                        InputLabelProps={{ shrink: true }
                      }
                      disabled = {getDisableValue("178")}
                      variant="filled"
                        InputProps={{
                           
                            sx: {
                                
                                color:'rgba(135, 135, 135, 0.5)',
                                height:'33px',
                                borderRadius: '5px',
                                backgroundColor: '#121212',
                                boxShadow: 'inset 0px 4px 4px rgba(0, 0, 0, 0.25)',
                                padding: '0 0 15px 0',
                                textIndent: '15px !important',
                              
                                '&.Mui-disabled': {
             

                                  background: 'rgba(135, 135, 135, 0.5)',
                                },
                                
                                "& input.Mui-disabled": {
                                  color: "white"
                                }
                                
                            },
                        }}
                       
                    />
                     
                 
                    <TextField
                        id="input-vegax2-duration"
                        height={40}
                        type= 'number'
                        name="vegax_second_duration"
                        value={dataOfLocation.vegax_second_duration}
                        onChange={handleInputChange}
                        sx={mainThemeStyle.InputMenuItem}
                        InputLabelProps={{ shrink: true }
                      }
                      disabled = {getDisableValue("180")}
                      variant="filled"
                        InputProps={{
                          inputProps: { 
                            max: 99, min: 0 
                        },
                            sx: {
                                
                                color:'rgba(135, 135, 135, 0.5)',
                                height:'33px',
                                borderRadius: '5px',
                                backgroundColor: '#121212',
                                boxShadow: 'inset 0px 4px 4px rgba(0, 0, 0, 0.25)',
                                padding: '0 0 15px 0',
                                textIndent: '15px !important',
                                '&.Mui-disabled': {
             

                                  background: 'rgba(135, 135, 135, 0.5)',
                                },
                                
                                "& input.Mui-disabled": {
                                  color: "white"
                                }
                               
                                
                            },
                        }}
                       
                    /></>} */}
                     
                     {/* {dataOfLocation.unlocking_mode == '1' && <>
                     <Select
            sx={{ color: 'rgba(135, 135, 135, 0.5)',
            height:'33px',
            
            marginBottom:'3px',
            borderRadius: '5px',
            backgroundColor: '#121212',
            boxShadow: 'inset 0px 4px 4px rgba(0, 0, 0, 0.25)',
            padding: '5px 0 5px 0',
            "& .MuiSvgIcon-root": {
              color: "rgba(135, 135, 135, 0.5)",
            },
            
            '&.Mui-disabled': {
             

              background: 'rgba(135, 135, 135, 0.5)',
            },
            "& input.Mui-disabled": {
              color: "white"
            }
            }}
            name = 'brivoprimeid'
            value={getBrivoValueMain()}
          onChange={handleChangeBrivoDoorValue}
          variant="filled"
          hiddenLabel
          MenuProps={MenuProps}>
            {dataOfLocation.brivo_array.map(menuItem => {
                  
                  return (
                    <MenuItem  sx={
                      {
                      
                      color:"#fff",
                      backgroundColor: "#2F323A",
                      borderBottom: '.5px solid grey',
                      fontFamily: "Lato",fontSize:'12px',
                  }}
                   value={menuItem.name}>
                      {menuItem.name}
                    </MenuItem>
                  );
                })}
          </Select>
         
                   
          <Select
            sx={{ color: 'rgba(135, 135, 135, 0.5)',
            height:'33px',
            marginTop:'4px',
            marginBottom:'3px',
            borderRadius: '5px',
            backgroundColor: '#121212',
            boxShadow: 'inset 0px 4px 4px rgba(0, 0, 0, 0.25)',
            padding: '5px 0 5px 0',
            "& .MuiSvgIcon-root": {
              color: "rgba(135, 135, 135, 0.5)",
            },
            
            '&.Mui-disabled': {
             

              background: 'rgba(135, 135, 135, 0.5)',
            },
            "& input.Mui-disabled": {
              color: "white"
            }
            }}
            name = "0"
            value={getBrivoValue(0)}
          onChange={handleChangeBrivoRelay}
          variant="filled"
          
          hiddenLabel
          MenuProps={MenuProps}>
            {dataOfLocation.brivo_array.map(menuItem => {
                  
                  return (
                    <MenuItem  sx={
                      {
                      
                      color:"#fff",
                      backgroundColor: "#2F323A",
                      borderBottom: '.5px solid grey',
                      fontFamily: "Lato",fontSize:'12px',
                  }}
                   value={menuItem.name}>
                      {menuItem.name}
                    </MenuItem>
                  );
                })}
          </Select>
                     
                 
                  </>} */}
                  
                   {(dataOfLocation.unlocking_mode == '1' || dataOfLocation.unlocking_mode == '4' )&&   <FormControlLabel   sx={{ marginLeft: '0px',  height:'2.18rem',
            color:'#fff',}}
        control={<IOSSwitch sx={{ m: 1 }} name="r_pin_off"  onChange={handlecheckboxCheckReverse}  disabled = {getDisableValue("172")}  checked={dataOfLocation.r_pin_off == "0" ? true : false} />}
        label={dataOfLocation.r_pin_off == "0" ? "Enabled" : "Disabled"}
      /> }
     {(dataOfLocation.unlocking_mode == '1' || dataOfLocation.unlocking_mode == '4' ) && <FormControlLabel   sx={mainThemeStyle.popUpLableNewone}
        control={<IOSSwitch sx={{ m: 1 }} name="g_pin_off" onChange={handlecheckboxCheckReverse}  disabled = {getDisableValue("173")} checked={dataOfLocation.g_pin_off == "0" ? true : false} />}
        label={dataOfLocation.g_pin_off == "0" ? "Enabled" : "Disabled"}
      /> }
      </Stack>
      </Grid>
     
     
      
      </Grid>
      {dataOfLocation.unlocking_mode == '1'  && dataOfLocation.brivo_access.icrt == 1 && <Grid container spacing={2} padding= '32px'>
     
      <Grid item xs={5.5}>
      <TableContainer component={Paper} sx={{
        width:'auto',
        height: 'auto',
        marginLeft: '38px',
        marginRight:'38px',
        marginBottom:'38px',
        backgroundColor:'#171E29',
       
        }}>
      <Table sx={{ minWidth: 350 }} >
        
        <TableHead sx={{ borderBottom: "1.5px solid black",
        }}>
          <TableRow>
            
            <TableCell align="center" sx={mainThemeStyle.popUpLable} colSpan={16}>In-Call Relay Trigger Settings</TableCell>
            
          </TableRow>
         
        </TableHead>

        
        <TableHead sx={{ borderBottom: "1.5px solid black",
        }}>
          </TableHead>
        <TableBody>
       
            <TableRow sx={{ borderBottom: "1.5px solid black",
          }}>
              <TableCell  sx={mainThemeStyle.cellHeader}>
              Custom Name 1 
              </TableCell>
             
                <TableCell align="right">
                <TextField
                        id="0"
                       
                        type= 'text'
                        name="0"
                        value={getCustomNameValue(0)}
                        onChange={handleChangeCustomNameValue}
                       
                        sx={{
                          ...mainThemeStyle.InputMenuItem,
                          marginTop:'10px',
                        }}
                        InputLabelProps={{ shrink: true }
                      }
                      disabled = {getDisableValue("184")}
                      variant="filled"
                        InputProps={{
                          
                            sx: {
                                
                                color:'rgba(135, 135, 135, 0.5)',
                                height:'33px',
                                borderRadius: '5px',
                                backgroundColor: '#121212',
                                boxShadow: 'inset 0px 4px 4px rgba(0, 0, 0, 0.25)',
                                padding: '0 0 15px 0',
                                textIndent: '15px !important',
                                '&.Mui-disabled': {
             

                                  background: 'rgba(135, 135, 135, 0.5)',
                                },
                                
                                "& input.Mui-disabled": {
                                  color: "white"
                                }
                               
                                
                            },
                        }}
                       
                    />
                    
                </TableCell>
                <TableCell align="right">
                
                <Select
              sx={{ color: 'rgba(135, 135, 135, 0.5)',
              height:'33px',
              marginTop:'8px',
              marginBottom:'3px',
              borderRadius: '5px',
              backgroundColor: '#121212',
              boxShadow: 'inset 0px 4px 4px rgba(0, 0, 0, 0.25)',
              padding: '5px 0 5px 0',
              "& .MuiSvgIcon-root": {
                color: "rgba(135, 135, 135, 0.5)",
              },
              
              '&.Mui-disabled': {
               
  
                background: 'rgba(135, 135, 135, 0.5)',
              },
              "& input.Mui-disabled": {
                color: "white"
              }
              }}
              name = "0"
              value={getCustomDoorValue(0)}
            onChange={handleChangeCustomDoorValue}
            variant="filled"
            disabled={getDisableValue("184")}
            hiddenLabel
            MenuProps={MenuProps}>
              {brivoCustomArray?.map(menuItem => {
                    
                    return (
                      <MenuItem  sx={
                        {
                        
                        color:"#fff",
                        backgroundColor: "#2F323A",
                        borderBottom: '.5px solid grey',
                        fontFamily: "Lato",fontSize:'12px',
                    }}
                    disabled={menuItem.disabled}
                     value={menuItem.name}>
                         <Stack direction="row" justifyContent="left"
    alignItems="center">
                    { menuItem.name == "None" && <ListItemIcon sx={{color: "red"}}>
              <DoNotDisturbIcon fontSize="small" />
            </ListItemIcon>} 
              {menuItem.name}</Stack>
                      </MenuItem>
                    );
                  })}
            </Select>
                  </TableCell>
            </TableRow>
            <TableRow sx={{ borderBottom: "1.5px solid black",
          }}>
              <TableCell  sx={mainThemeStyle.cellHeader}>
              Custom Name 2 
              </TableCell>
             
                <TableCell align="right">
                <TextField
                        id="1"
                       
                        type= 'text'
                        name="1"
                        value={getCustomNameValue(1)}
                        onChange={handleChangeCustomNameValue}
                       
                        sx={{
                          ...mainThemeStyle.InputMenuItem,
                          marginTop:'10px',
                        }}
                        InputLabelProps={{ shrink: true }
                      }
                      disabled = {getDisableValue("184")}
                      variant="filled"
                        InputProps={{
                          
                            sx: {
                                
                                color:'rgba(135, 135, 135, 0.5)',
                                height:'33px',
                                borderRadius: '5px',
                                backgroundColor: '#121212',
                                boxShadow: 'inset 0px 4px 4px rgba(0, 0, 0, 0.25)',
                                padding: '0 0 15px 0',
                                textIndent: '15px !important',
                                '&.Mui-disabled': {
             

                                  background: 'rgba(135, 135, 135, 0.5)',
                                },
                                
                                "& input.Mui-disabled": {
                                  color: "white"
                                }
                               
                                
                            },
                        }}
                       
                    />
                    
                </TableCell>
                <TableCell align="right">
                
                <Select
              sx={{ color: 'rgba(135, 135, 135, 0.5)',
              height:'33px',
              marginTop:'8px',
              marginBottom:'3px',
              borderRadius: '5px',
              backgroundColor: '#121212',
              boxShadow: 'inset 0px 4px 4px rgba(0, 0, 0, 0.25)',
              padding: '5px 0 5px 0',
              "& .MuiSvgIcon-root": {
                color: "rgba(135, 135, 135, 0.5)",
              },
              
              '&.Mui-disabled': {
               
  
                background: 'rgba(135, 135, 135, 0.5)',
              },
              "& input.Mui-disabled": {
                color: "white"
              }
              }}
              name = "1"
              value={getCustomDoorValue(1)}
            onChange={handleChangeCustomDoorValue}
            variant="filled"
            disabled={getDisableValue("184")}
            hiddenLabel
            MenuProps={MenuProps}>
              {brivoCustomArray?.map(menuItem => {
                    
                    return (
                      <MenuItem  sx={
                        {
                        
                        color:"#fff",
                        backgroundColor: "#2F323A",
                        borderBottom: '.5px solid grey',
                        fontFamily: "Lato",fontSize:'12px',
                    }}
                    disabled={menuItem.disabled}
                     value={menuItem.name}>
                         <Stack direction="row" justifyContent="left"
    alignItems="center">
                    { menuItem.name == "None" && <ListItemIcon sx={{color: "red"}}>
              <DoNotDisturbIcon fontSize="small" />
            </ListItemIcon>} 
              {menuItem.name}</Stack>
                      </MenuItem>
                    );
                  })}
            </Select>
                  </TableCell>
            </TableRow>
            <TableRow sx={{ borderBottom: "1.5px solid black",
          }}>
              <TableCell  sx={mainThemeStyle.cellHeader}>
              Custom Name 3 
              </TableCell>
             
                <TableCell align="right">
                <TextField
                        id="2"
                       
                        type= 'text'
                        name="2"
                        value={getCustomNameValue(2)}
                        onChange={handleChangeCustomNameValue}
                       
                        sx={{
                          ...mainThemeStyle.InputMenuItem,
                          marginTop:'10px',
                        }}
                        InputLabelProps={{ shrink: true }
                      }
                      disabled = {getDisableValue("184")}
                      variant="filled"
                        InputProps={{
                          
                            sx: {
                                
                                color:'rgba(135, 135, 135, 0.5)',
                                height:'33px',
                                borderRadius: '5px',
                                backgroundColor: '#121212',
                                boxShadow: 'inset 0px 4px 4px rgba(0, 0, 0, 0.25)',
                                padding: '0 0 15px 0',
                                textIndent: '15px !important',
                                '&.Mui-disabled': {
             

                                  background: 'rgba(135, 135, 135, 0.5)',
                                },
                                
                                "& input.Mui-disabled": {
                                  color: "white"
                                }
                               
                                
                            },
                        }}
                       
                    />
                    
                </TableCell>
                <TableCell align="right">
                
                <Select
              sx={{ color: 'rgba(135, 135, 135, 0.5)',
              height:'33px',
              marginTop:'8px',
              marginBottom:'3px',
              borderRadius: '5px',
              backgroundColor: '#121212',
              boxShadow: 'inset 0px 4px 4px rgba(0, 0, 0, 0.25)',
              padding: '5px 0 5px 0',
              "& .MuiSvgIcon-root": {
                color: "rgba(135, 135, 135, 0.5)",
              },
              
              '&.Mui-disabled': {
               
  
                background: 'rgba(135, 135, 135, 0.5)',
              },
              "& input.Mui-disabled": {
                color: "white"
              }
              }}
              name = "2"
              value={getCustomDoorValue(2)}
            onChange={handleChangeCustomDoorValue}
            variant="filled"
            disabled={getDisableValue("184")}
            hiddenLabel
            MenuProps={MenuProps}>
              {brivoCustomArray?.map(menuItem => {
                    
                    return (
                      <MenuItem  sx={
                        {
                        
                        color:"#fff",
                        backgroundColor: "#2F323A",
                        borderBottom: '.5px solid grey',
                        fontFamily: "Lato",fontSize:'12px',
                    }}
                    disabled={menuItem.disabled}
                     value={menuItem.name}>
                         <Stack direction="row" justifyContent="left"
    alignItems="center">
                    { menuItem.name == "None" && <ListItemIcon sx={{color: "red"}}>
              <DoNotDisturbIcon fontSize="small" />
            </ListItemIcon>} 
              {menuItem.name}</Stack>
                      </MenuItem>
                    );
                  })}
            </Select>
                  </TableCell>
            </TableRow>
        </TableBody>
      </Table>
    </TableContainer></Grid>
    </Grid>}

    
      {dataOfLocation.unlocking_mode == '1' &&
      <TableContainer component={Paper} sx={{
        width:'auto',
        height: 'auto',
        marginLeft: '38px',
        marginRight:'38px',
        marginBottom:'38px',
        backgroundColor:'#171E29',
       
        }}>
      <Table sx={{ minWidth: 650 }} >
        
        <TableHead sx={{ borderBottom: "1.5px solid black",
        }}>
          <TableRow>
            
            <TableCell align="center" sx={mainThemeStyle.popUpLable} colSpan={16}>Brivo Relays</TableCell>
            
          </TableRow>
         
        </TableHead>

        
        <TableHead sx={{ borderBottom: "1.5px solid black",
        }}>
          <TableRow>
          <TableCell align="right" sx={mainThemeStyle.cellHeader}> Floor</TableCell>
            <TableCell align="right" sx={mainThemeStyle.cellHeader}>	Door Id</TableCell>
            <TableCell align="right" sx={mainThemeStyle.cellHeader}> Floor</TableCell>
            <TableCell align="right" sx={mainThemeStyle.cellHeader}>Door Id</TableCell>
            <TableCell align="right" sx={mainThemeStyle.cellHeader}> Floor</TableCell>
            <TableCell align="right" sx={mainThemeStyle.cellHeader}>Door Id</TableCell>
            <TableCell align="right" sx={mainThemeStyle.cellHeader}> Floor</TableCell>
            <TableCell align="right" sx={mainThemeStyle.cellHeader}>Door Id</TableCell>
            <TableCell align="right" sx={mainThemeStyle.cellHeader}> Floor</TableCell>
            <TableCell align="right" sx={mainThemeStyle.cellHeader}>Door Id</TableCell>
            <TableCell align="right" sx={mainThemeStyle.cellHeader}> Floor</TableCell>
            <TableCell align="right" sx={mainThemeStyle.cellHeader}>Door Id</TableCell>
            <TableCell align="right" sx={mainThemeStyle.cellHeader}> Floor</TableCell>
            <TableCell align="right" sx={mainThemeStyle.cellHeader}>Door Id</TableCell>
            <TableCell align="right" sx={mainThemeStyle.cellHeader}> Floor</TableCell>
            <TableCell align="right" sx={mainThemeStyle.cellHeader}>Door Id</TableCell>
          </TableRow></TableHead>
        <TableBody>
         {rowsElevatorControl.map((row) => (
            <TableRow sx={{ borderBottom: "1.5px solid black",
          }}>
              <TableCell sx={mainThemeStyle.cellRow}>
              {parseInt(row*8)+1}
              </TableCell>
              <TableCell align="right">
              <Autocomplete
       style={{marginTop: 0,minWidth: 150}}
        sx={{  "& .MuiAutocomplete-inputRoot": {
         color: "rgba(170, 170, 170,1)" },"& .MuiSvgIcon-root": {
           color: "rgba(170, 170, 170, 1)",
         },
         "& .Mui-disabled": {
          color: 'rgba(135, 135, 135, 0.5) ',
          "& .MuiSvgIcon-root": {
           color: "rgba(135, 135, 135, 0.5)"
          }
        },
        "& .MuiInputBase-root.Mui-disabled": {
          backgroundColor: 'rgba(135, 135, 135, 0.5)',
          color: 'white',
        },
        
        }}
       disablePortal
       disabled = {getDisableValue("184") == false ? (dataOfLocation.brivo_access.brivofloors < (parseInt(row*8)+1) ? true : false) : true}
       name = {parseInt(row*8)}
       value={options.find(option => option.value === getBrivoValue(parseInt(row*8)))}
     onChange={handleChangeBrivoRelay(parseInt(row*8))}
     variant="filled"
     
     options={options}
     getOptionLabel={(option) => option.label}
     PaperComponent={({ children }) => (
       <Paper style={{ backgroundColor: "#1E1E1E" ,color:"#AFAFAF", "&:hover":{backgroundColor: "#fff"}}}>{children}</Paper>
     )}
     renderInput={(params) => <TextField {...params} 
      variant="outlined" size="small" style={{ backgroundColor: "#121212",borderRadius: '5px' ,color:"#fff",
   
       
   }} />}
   renderOption={(props, option) => (
     <MenuItem
       {...props}
       key={option.id}
       sx={{
         color: "#fff",
         backgroundColor: "#2F323A",
         borderBottom: '.5px solid grey',
         fontFamily: "Lato",
         fontSize: '12px',
       }}
       disabled={option.disabled}
     >
       <Stack direction="row" justifyContent="left" alignItems="center">
         {option.label === "None" && (
           <ListItemIcon sx={{ color: "red" }}>
             <DoNotDisturbIcon fontSize="small" />
           </ListItemIcon>
         )}
         {option.label}
       </Stack>
     </MenuItem>
   )}
     ></Autocomplete>
             {/*  <Select
            sx={{ color: 'rgba(135, 135, 135, 0.5)',
            height:'33px',
            marginTop:'4px',
            marginBottom:'3px',
            borderRadius: '5px',
            backgroundColor: '#121212',
            boxShadow: 'inset 0px 4px 4px rgba(0, 0, 0, 0.25)',
            padding: '5px 0 5px 0',
            "& .MuiSvgIcon-root": {
              color: "rgba(135, 135, 135, 0.5)",
            },
            
            '&.Mui-disabled': {
             

              background: 'rgba(135, 135, 135, 0.5)',
            },
            "& input.Mui-disabled": {
              color: "white"
            }
            }}
            name = {parseInt(row*8)}
            value={getBrivoValue(parseInt(row*8))}
          onChange={handleChangeBrivoRelay}
          variant="filled"
          disabled={getDisableValue("184") == false ? (dataOfLocation.brivo_access.brivofloors < (parseInt(row*8)+1) ? true : false) :true}
          hiddenLabel
          MenuProps={MenuProps}>
            {brivoDoorArray?.map(menuItem => {
                  
                  return (
                    <MenuItem  sx={
                      {
                      
                      color:"#fff",
                      backgroundColor: "#2F323A",
                      borderBottom: '.5px solid grey',
                      fontFamily: "Lato",fontSize:'12px',
                  }}
                  disabled={menuItem.disabled}
                   value={menuItem.name}>
                       <Stack direction="row" justifyContent="left"
  alignItems="center">
                  { menuItem.name == "None" && <ListItemIcon sx={{color: "red"}}>
            <DoNotDisturbIcon fontSize="small" />
          </ListItemIcon>} 
            {menuItem.name}</Stack>
                    </MenuItem>
                  );
                })}
          </Select> */}
                </TableCell>
              <TableCell sx={mainThemeStyle.cellRow}>
              {parseInt(row*8)+2}
              </TableCell>
              <TableCell align="right">
              <Autocomplete
       style={{marginTop: 0,minWidth: 150}}
        sx={{  "& .MuiAutocomplete-inputRoot": {
         color: "rgba(170, 170, 170,1)" },"& .MuiSvgIcon-root": {
           color: "rgba(170, 170, 170, 1)",
         },
         "& .Mui-disabled": {
          color: 'rgba(135, 135, 135, 0.5) ',
          "& .MuiSvgIcon-root": {
           color: "rgba(135, 135, 135, 0.5)"
          }
        },
        "& .MuiInputBase-root.Mui-disabled": {
          backgroundColor: 'rgba(135, 135, 135, 0.5)',
          color: 'white',
        },
        
        }}
       disablePortal
       disabled = {getDisableValue("184") == false ? (dataOfLocation.brivo_access.brivofloors < (parseInt(row*8)+2) ? true : false) : true}
       name = {parseInt(row*8)+1}
       value={options.find(option => option.value === getBrivoValue(parseInt(row*8)+1))}
     onChange={handleChangeBrivoRelay(parseInt(row*8)+1)}
     variant="filled"
     
     options={options}
     getOptionLabel={(option) => option.label}
     PaperComponent={({ children }) => (
       <Paper style={{ backgroundColor: "#1E1E1E" ,color:"#AFAFAF", "&:hover":{backgroundColor: "#fff"}}}>{children}</Paper>
     )}
     renderInput={(params) => <TextField {...params} 
      variant="outlined" size="small" style={{ backgroundColor: "#121212",borderRadius: '5px' ,color:"#fff",
   
       
   }} />}
   renderOption={(props, option) => (
     <MenuItem
       {...props}
       key={option.id}
       sx={{
         color: "#fff",
         backgroundColor: "#2F323A",
         borderBottom: '.5px solid grey',
         fontFamily: "Lato",
         fontSize: '12px',
       }}
       disabled={option.disabled}
     >
       <Stack direction="row" justifyContent="left" alignItems="center">
         {option.label === "None" && (
           <ListItemIcon sx={{ color: "red" }}>
             <DoNotDisturbIcon fontSize="small" />
           </ListItemIcon>
         )}
         {option.label}
       </Stack>
     </MenuItem>
   )}
     ></Autocomplete>
              {/* <Select
            sx={{ color: 'rgba(135, 135, 135, 0.5)',
            height:'33px',
            marginTop:'4px',
            marginBottom:'3px',
            borderRadius: '5px',
            backgroundColor: '#121212',
            boxShadow: 'inset 0px 4px 4px rgba(0, 0, 0, 0.25)',
            padding: '5px 0 5px 0',
            "& .MuiSvgIcon-root": {
              color: "rgba(135, 135, 135, 0.5)",
            },
            
            '&.Mui-disabled': {
             

              background: 'rgba(135, 135, 135, 0.5)',
            },
            "& input.Mui-disabled": {
              color: "white"
            }
            }}
            name = {(row*8)+1}
            value={getBrivoValue(parseInt(row*8)+1)}
          onChange={handleChangeBrivoRelay}
          variant="filled"
          disabled={getDisableValue("184") == false ? (dataOfLocation.brivo_access.brivofloors < (parseInt(row*8)+2) ? true : false) :true}
          hiddenLabel
          MenuProps={MenuProps}>
            {brivoDoorArray?.map(menuItem => {
                  
                  return (
                    <MenuItem  sx={
                      {
                      
                      color:"#fff",
                      backgroundColor: "#2F323A",
                      borderBottom: '.5px solid grey',
                      fontFamily: "Lato",fontSize:'12px',
                  }}
                  disabled={menuItem.disabled}
                   value={menuItem.name}>
                       <Stack direction="row" justifyContent="left"
  alignItems="center">
                  { menuItem.name == "None" && <ListItemIcon sx={{color: "red"}}>
            <DoNotDisturbIcon fontSize="small" />
          </ListItemIcon>} 
            {menuItem.name}</Stack>
                    </MenuItem>
                  );
                })}
          </Select> */}
                
                
               </TableCell>
      <TableCell sx={mainThemeStyle.cellRow}>
              {parseInt(row*8)+3}
              </TableCell>
              <TableCell align="right"> 
              <Autocomplete
       style={{marginTop: 0,minWidth: 150}}
        sx={{  "& .MuiAutocomplete-inputRoot": {
         color: "rgba(170, 170, 170,1)" },"& .MuiSvgIcon-root": {
           color: "rgba(170, 170, 170, 1)",
         },
         "& .Mui-disabled": {
          color: 'rgba(135, 135, 135, 0.5) ',
          "& .MuiSvgIcon-root": {
           color: "rgba(135, 135, 135, 0.5)"
          }
        },
        "& .MuiInputBase-root.Mui-disabled": {
          backgroundColor: 'rgba(135, 135, 135, 0.5)',
          color: 'white',
        },
        
        }}
       disablePortal
       disabled = {getDisableValue("184") == false ? (dataOfLocation.brivo_access.brivofloors < (parseInt(row*8)+3) ? true : false) : true}
       name = {parseInt(row*8)+2}
       value={options.find(option => option.value === getBrivoValue(parseInt(row*8)+2))}
     onChange={handleChangeBrivoRelay(parseInt(row*8)+2)}
     variant="filled"
     
     options={options}
     getOptionLabel={(option) => option.label}
     PaperComponent={({ children }) => (
       <Paper style={{ backgroundColor: "#1E1E1E" ,color:"#AFAFAF", "&:hover":{backgroundColor: "#fff"}}}>{children}</Paper>
     )}
     renderInput={(params) => <TextField {...params} 
      variant="outlined" size="small" style={{ backgroundColor: "#121212",borderRadius: '5px' ,color:"#fff",
   
       
   }} />}
   renderOption={(props, option) => (
     <MenuItem
       {...props}
       key={option.id}
       sx={{
         color: "#fff",
         backgroundColor: "#2F323A",
         borderBottom: '.5px solid grey',
         fontFamily: "Lato",
         fontSize: '12px',
       }}
       disabled={option.disabled}
     >
       <Stack direction="row" justifyContent="left" alignItems="center">
         {option.label === "None" && (
           <ListItemIcon sx={{ color: "red" }}>
             <DoNotDisturbIcon fontSize="small" />
           </ListItemIcon>
         )}
         {option.label}
       </Stack>
     </MenuItem>
   )}
     ></Autocomplete>
              {/* <Select
            sx={{ color: 'rgba(135, 135, 135, 0.5)',
            height:'33px',
            marginTop:'4px',
            marginBottom:'3px',
            borderRadius: '5px',
            backgroundColor: '#121212',
            boxShadow: 'inset 0px 4px 4px rgba(0, 0, 0, 0.25)',
            padding: '5px 0 5px 0',
            "& .MuiSvgIcon-root": {
              color: "rgba(135, 135, 135, 0.5)",
            },
            
            '&.Mui-disabled': {
             

              background: 'rgba(135, 135, 135, 0.5)',
            },
            "& input.Mui-disabled": {
              color: "white"
            }
            }}
            name = {(row*8)+2}
            value={getBrivoValue((row*8)+2)}
          onChange={handleChangeBrivoRelay}
          variant="filled"
          disabled={getDisableValue("184") == false ? (dataOfLocation.brivo_access.brivofloors < (parseInt(row*8)+3) ? true : false) :true}
          hiddenLabel
          MenuProps={MenuProps}>
            {brivoDoorArray?.map(menuItem => {
                  
                  return (
                    <MenuItem  sx={
                      {
                      
                      color:"#fff",
                      backgroundColor: "#2F323A",
                      borderBottom: '.5px solid grey',
                      fontFamily: "Lato",fontSize:'12px',
                  }}
                  disabled={menuItem.disabled}
                   value={menuItem.name}>
                       <Stack direction="row" justifyContent="left"
  alignItems="center">
                  { menuItem.name == "None" && <ListItemIcon sx={{color: "red"}}>
            <DoNotDisturbIcon fontSize="small" />
          </ListItemIcon>} 
            {menuItem.name}</Stack>
                    </MenuItem>
                  );
                })}
          </Select> */}
              
              </TableCell>
      <TableCell sx={mainThemeStyle.cellRow}>
              {(row*8)+4}
              </TableCell>
              <TableCell align="right"> 
              <Autocomplete
       style={{marginTop: 0,minWidth: 150}}
        sx={{  "& .MuiAutocomplete-inputRoot": {
         color: "rgba(170, 170, 170,1)" },"& .MuiSvgIcon-root": {
           color: "rgba(170, 170, 170, 1)",
         },
         "& .Mui-disabled": {
          color: 'rgba(135, 135, 135, 0.5) ',
          "& .MuiSvgIcon-root": {
           color: "rgba(135, 135, 135, 0.5)"
          }
        },
        "& .MuiInputBase-root.Mui-disabled": {
          backgroundColor: 'rgba(135, 135, 135, 0.5)',
          color: 'white',
        },
        
        }}
       disablePortal
       disabled = {getDisableValue("184") == false ? (dataOfLocation.brivo_access.brivofloors < (parseInt(row*8)+4) ? true : false) : true}
       name = {parseInt(row*8)+3}
       value={options.find(option => option.value === getBrivoValue(parseInt(row*8)+3))}
     onChange={handleChangeBrivoRelay(parseInt(row*8)+3)}
     variant="filled"
     
     options={options}
     getOptionLabel={(option) => option.label}
     PaperComponent={({ children }) => (
       <Paper style={{ backgroundColor: "#1E1E1E" ,color:"#AFAFAF", "&:hover":{backgroundColor: "#fff"}}}>{children}</Paper>
     )}
     renderInput={(params) => <TextField {...params} 
      variant="outlined" size="small" style={{ backgroundColor: "#121212",borderRadius: '5px' ,color:"#fff",
   
       
   }} />}
   renderOption={(props, option) => (
     <MenuItem
       {...props}
       key={option.id}
       sx={{
         color: "#fff",
         backgroundColor: "#2F323A",
         borderBottom: '.5px solid grey',
         fontFamily: "Lato",
         fontSize: '12px',
       }}
       disabled={option.disabled}
     >
       <Stack direction="row" justifyContent="left" alignItems="center">
         {option.label === "None" && (
           <ListItemIcon sx={{ color: "red" }}>
             <DoNotDisturbIcon fontSize="small" />
           </ListItemIcon>
         )}
         {option.label}
       </Stack>
     </MenuItem>
   )}
     ></Autocomplete>
             {/*  <Select
            sx={{ color: 'rgba(135, 135, 135, 0.5)',
            height:'33px',
            marginTop:'4px',
            marginBottom:'3px',
            borderRadius: '5px',
            backgroundColor: '#121212',
            boxShadow: 'inset 0px 4px 4px rgba(0, 0, 0, 0.25)',
            padding: '5px 0 5px 0',
            "& .MuiSvgIcon-root": {
              color: "rgba(135, 135, 135, 0.5)",
            },
            
            '&.Mui-disabled': {
             

              background: 'rgba(135, 135, 135, 0.5)',
            },
            "& input.Mui-disabled": {
              color: "white"
            }
            }}
            name = {(row*8)+3}
            value={getBrivoValue((row*8)+3)}
          onChange={handleChangeBrivoRelay}
          variant="filled"
          disabled={getDisableValue("184") == false ? (dataOfLocation.brivo_access.brivofloors < (parseInt(row*8)+4) ? true : false) :true}
          hiddenLabel
          MenuProps={MenuProps}>
            {brivoDoorArray?.map(menuItem => {
                  
                  return (
                    <MenuItem  sx={
                      {
                      
                      color:"#fff",
                      backgroundColor: "#2F323A",
                      borderBottom: '.5px solid grey',
                      fontFamily: "Lato",fontSize:'12px',
                  }}
                  disabled={menuItem.disabled}
                   value={menuItem.name}>
                       <Stack direction="row" justifyContent="left"
  alignItems="center">
                  { menuItem.name == "None" && <ListItemIcon sx={{color: "red"}}>
            <DoNotDisturbIcon fontSize="small" />
          </ListItemIcon>} 
            {menuItem.name}</Stack>
                    </MenuItem>
                  );
                })}
          </Select> */}
              </TableCell>
      <TableCell sx={mainThemeStyle.cellRow}>
              {(row*8)+5}
              </TableCell>
              <TableCell align="right">
              <Autocomplete
       style={{marginTop: 0,minWidth: 150}}
        sx={{  "& .MuiAutocomplete-inputRoot": {
         color: "rgba(170, 170, 170,1)" },"& .MuiSvgIcon-root": {
           color: "rgba(170, 170, 170, 1)",
         },
         "& .Mui-disabled": {
          color: 'rgba(135, 135, 135, 0.5) ',
          "& .MuiSvgIcon-root": {
           color: "rgba(135, 135, 135, 0.5)"
          }
        },
        "& .MuiInputBase-root.Mui-disabled": {
          backgroundColor: 'rgba(135, 135, 135, 0.5)',
          color: 'white',
        },
        
        }}
       disablePortal
       disabled = {getDisableValue("184") == false ? (dataOfLocation.brivo_access.brivofloors < (parseInt(row*8)+5) ? true : false) : true}
       name = {parseInt(row*8)+4}
       value={options.find(option => option.value === getBrivoValue(parseInt(row*8)+4))}
     onChange={handleChangeBrivoRelay(parseInt(row*8)+4)}
     variant="filled"
     
     options={options}
     getOptionLabel={(option) => option.label}
     PaperComponent={({ children }) => (
       <Paper style={{ backgroundColor: "#1E1E1E" ,color:"#AFAFAF", "&:hover":{backgroundColor: "#fff"}}}>{children}</Paper>
     )}
     renderInput={(params) => <TextField {...params} 
      variant="outlined" size="small" style={{ backgroundColor: "#121212",borderRadius: '5px' ,color:"#fff",
   
       
   }} />}
   renderOption={(props, option) => (
     <MenuItem
       {...props}
       key={option.id}
       sx={{
         color: "#fff",
         backgroundColor: "#2F323A",
         borderBottom: '.5px solid grey',
         fontFamily: "Lato",
         fontSize: '12px',
       }}
       disabled={option.disabled}
     >
       <Stack direction="row" justifyContent="left" alignItems="center">
         {option.label === "None" && (
           <ListItemIcon sx={{ color: "red" }}>
             <DoNotDisturbIcon fontSize="small" />
           </ListItemIcon>
         )}
         {option.label}
       </Stack>
     </MenuItem>
   )}
     ></Autocomplete>
                      {/* <Select
            sx={{ color: 'rgba(135, 135, 135, 0.5)',
            height:'33px',
            marginTop:'4px',
            marginBottom:'3px',
            borderRadius: '5px',
            backgroundColor: '#121212',
            boxShadow: 'inset 0px 4px 4px rgba(0, 0, 0, 0.25)',
            padding: '5px 0 5px 0',
            "& .MuiSvgIcon-root": {
              color: "rgba(135, 135, 135, 0.5)",
            },
            
            '&.Mui-disabled': {
             

              background: 'rgba(135, 135, 135, 0.5)',
            },
            "& input.Mui-disabled": {
              color: "white"
            }
            }}
            name = {(row*8)+4}
            value={getBrivoValue((row*8)+4)}
          onChange={handleChangeBrivoRelay}
          variant="filled"
          disabled={getDisableValue("184") == false ? (dataOfLocation.brivo_access.brivofloors < (parseInt(row*8)+5) ? true : false) :true}
          hiddenLabel
          MenuProps={MenuProps}>
            {brivoDoorArray?.map(menuItem => {
                  
                  return (
                    <MenuItem  sx={
                      {
                      
                      color:"#fff",
                      backgroundColor: "#2F323A",
                      borderBottom: '.5px solid grey',
                      fontFamily: "Lato",fontSize:'12px',
                  }}
                  disabled={menuItem.disabled}
                   value={menuItem.name}>
                        <Stack direction="row" justifyContent="left"
  alignItems="center">
                  { menuItem.name == "None" && <ListItemIcon sx={{color: "red"}}>
            <DoNotDisturbIcon fontSize="small" />
          </ListItemIcon>} 
            {menuItem.name}</Stack>
                    </MenuItem>
                  );
                })}
          </Select>  */}
                </TableCell>
      <TableCell sx={mainThemeStyle.cellRow}>
              {(row*8)+6}
              </TableCell>
              <TableCell align="right"> 
              <Autocomplete
       style={{marginTop: 0,minWidth: 150}}
        sx={{  "& .MuiAutocomplete-inputRoot": {
         color: "rgba(170, 170, 170,1)" },"& .MuiSvgIcon-root": {
           color: "rgba(170, 170, 170, 1)",
         },
         "& .Mui-disabled": {
          color: 'rgba(135, 135, 135, 0.5) ',
          "& .MuiSvgIcon-root": {
           color: "rgba(135, 135, 135, 0.5)"
          }
        },
        "& .MuiInputBase-root.Mui-disabled": {
          backgroundColor: 'rgba(135, 135, 135, 0.5)',
          color: 'white',
        },
        
        }}
       disablePortal
       disabled = {getDisableValue("184") == false ? (dataOfLocation.brivo_access.brivofloors < (parseInt(row*8)+6) ? true : false) : true}
       name = {parseInt(row*8)+5}
       value={options.find(option => option.value === getBrivoValue(parseInt(row*8)+5))}
     onChange={handleChangeBrivoRelay(parseInt(row*8)+5)}
     variant="filled"
     
     options={options}
     getOptionLabel={(option) => option.label}
     PaperComponent={({ children }) => (
       <Paper style={{ backgroundColor: "#1E1E1E" ,color:"#AFAFAF", "&:hover":{backgroundColor: "#fff"}}}>{children}</Paper>
     )}
     renderInput={(params) => <TextField {...params} 
      variant="outlined" size="small" style={{ backgroundColor: "#121212",borderRadius: '5px' ,color:"#fff",
   
       
   }} />}
   renderOption={(props, option) => (
     <MenuItem
       {...props}
       key={option.id}
       sx={{
         color: "#fff",
         backgroundColor: "#2F323A",
         borderBottom: '.5px solid grey',
         fontFamily: "Lato",
         fontSize: '12px',
       }}
       disabled={option.disabled}
     >
       <Stack direction="row" justifyContent="left" alignItems="center">
         {option.label === "None" && (
           <ListItemIcon sx={{ color: "red" }}>
             <DoNotDisturbIcon fontSize="small" />
           </ListItemIcon>
         )}
         {option.label}
       </Stack>
     </MenuItem>
   )}
     ></Autocomplete>
              {/* <Select
            sx={{ color: 'rgba(135, 135, 135, 0.5)',
            height:'33px',
            marginTop:'4px',
            marginBottom:'3px',
            borderRadius: '5px',
            backgroundColor: '#121212',
            boxShadow: 'inset 0px 4px 4px rgba(0, 0, 0, 0.25)',
            padding: '5px 0 5px 0',
            "& .MuiSvgIcon-root": {
              color: "rgba(135, 135, 135, 0.5)",
            },
            
            '&.Mui-disabled': {
             

              background: 'rgba(135, 135, 135, 0.5)',
            },
            "& input.Mui-disabled": {
              color: "white"
            }
            }}
            name = {(row*8)+5}
            value={getBrivoValue((row*8)+5)}
          onChange={handleChangeBrivoRelay}
          variant="filled"
          disabled={getDisableValue("184") == false ? (dataOfLocation.brivo_access.brivofloors < (parseInt(row*8)+6) ? true : false) :true}
          hiddenLabel
          MenuProps={MenuProps}>
            {brivoDoorArray?.map(menuItem => {
                  
                  return (
                    <MenuItem  sx={
                      {
                      
                      color:"#fff",
                      backgroundColor: "#2F323A",
                      borderBottom: '.5px solid grey',
                      fontFamily: "Lato",fontSize:'12px',
                  }}
                  disabled={menuItem.disabled}
                   value={menuItem.name}>
                        <Stack direction="row" justifyContent="left"
  alignItems="center">
                  { menuItem.name == "None" && <ListItemIcon sx={{color: "red"}}>
            <DoNotDisturbIcon fontSize="small" />
          </ListItemIcon>} 
            {menuItem.name}</Stack>
                    </MenuItem>
                  );
                })}
          </Select>  */}
              </TableCell>
      <TableCell sx={mainThemeStyle.cellRow}>
              {(row*8)+7}
              </TableCell>
              <TableCell align="right"> 
              <Autocomplete
       style={{marginTop: 0,minWidth: 150}}
        sx={{  "& .MuiAutocomplete-inputRoot": {
         color: "rgba(170, 170, 170,1)" },"& .MuiSvgIcon-root": {
           color: "rgba(170, 170, 170, 1)",
         },
         "& .Mui-disabled": {
          color: 'rgba(135, 135, 135, 0.5) ',
          "& .MuiSvgIcon-root": {
           color: "rgba(135, 135, 135, 0.5)"
          }
        },
        "& .MuiInputBase-root.Mui-disabled": {
          backgroundColor: 'rgba(135, 135, 135, 0.5)',
          color: 'white',
        },
        
        }}
       disablePortal
       disabled = {getDisableValue("184") == false ? (dataOfLocation.brivo_access.brivofloors < (parseInt(row*8)+7) ? true : false) : true}
       name = {parseInt(row*8)+6}
       value={options.find(option => option.value === getBrivoValue(parseInt(row*8)+6))}
     onChange={handleChangeBrivoRelay(parseInt(row*8)+6)}
     variant="filled"
     
     options={options}
     getOptionLabel={(option) => option.label}
     PaperComponent={({ children }) => (
       <Paper style={{ backgroundColor: "#1E1E1E" ,color:"#AFAFAF", "&:hover":{backgroundColor: "#fff"}}}>{children}</Paper>
     )}
     renderInput={(params) => <TextField {...params} 
      variant="outlined" size="small" style={{ backgroundColor: "#121212",borderRadius: '5px' ,color:"#fff",
   
       
   }} />}
   renderOption={(props, option) => (
     <MenuItem
       {...props}
       key={option.id}
       sx={{
         color: "#fff",
         backgroundColor: "#2F323A",
         borderBottom: '.5px solid grey',
         fontFamily: "Lato",
         fontSize: '12px',
       }}
       disabled={option.disabled}
     >
       <Stack direction="row" justifyContent="left" alignItems="center">
         {option.label === "None" && (
           <ListItemIcon sx={{ color: "red" }}>
             <DoNotDisturbIcon fontSize="small" />
           </ListItemIcon>
         )}
         {option.label}
       </Stack>
     </MenuItem>
   )}
     ></Autocomplete>
              {/* <Select
            sx={{ color: 'rgba(135, 135, 135, 0.5)',
            height:'33px',
            marginTop:'4px',
            marginBottom:'3px',
            borderRadius: '5px',
            backgroundColor: '#121212',
            boxShadow: 'inset 0px 4px 4px rgba(0, 0, 0, 0.25)',
            padding: '5px 0 5px 0',
            "& .MuiSvgIcon-root": {
              color: "rgba(135, 135, 135, 0.5)",
            },
            
            '&.Mui-disabled': {
             

              background: 'rgba(135, 135, 135, 0.5)',
            },
            "& input.Mui-disabled": {
              color: "white"
            }
            }}
            name = {(row*8)+6}
            value={getBrivoValue((row*8)+6)}
          onChange={handleChangeBrivoRelay}
          variant="filled"
          disabled={getDisableValue("184") == false ? (dataOfLocation.brivo_access.brivofloors < (parseInt(row*8)+7) ? true : false) :true}
          hiddenLabel
          MenuProps={MenuProps}>
            {brivoDoorArray?.map(menuItem => {
                  
                  return (
                    <MenuItem  sx={
                      {
                      
                      color:"#fff",
                      backgroundColor: "#2F323A",
                      borderBottom: '.5px solid grey',
                      fontFamily: "Lato",fontSize:'12px',
                  }}
                  disabled={menuItem.disabled}
                   value={menuItem.name}>
                        <Stack direction="row" justifyContent="left"
  alignItems="center">
                  { menuItem.name == "None" && <ListItemIcon sx={{color: "red"}}>
            <DoNotDisturbIcon fontSize="small" />
          </ListItemIcon>} 
            {menuItem.name}</Stack>
                    </MenuItem>
                  );
                })}
          </Select>  */}
              </TableCell>
      <TableCell sx={mainThemeStyle.cellRow}>
              {(row*8)+8}
              </TableCell>
              <TableCell align="right"> 
              <Autocomplete
       style={{marginTop: 0,minWidth: 150}}
        sx={{  "& .MuiAutocomplete-inputRoot": {
         color: "rgba(170, 170, 170,1)" },"& .MuiSvgIcon-root": {
           color: "rgba(170, 170, 170, 1)",
         },
         "& .Mui-disabled": {
          color: 'rgba(135, 135, 135, 0.5) ',
          "& .MuiSvgIcon-root": {
           color: "rgba(135, 135, 135, 0.5)"
          }
        },
        "& .MuiInputBase-root.Mui-disabled": {
          backgroundColor: 'rgba(135, 135, 135, 0.5)',
          color: 'white',
        },
        
        }}
       disablePortal
       disabled = {getDisableValue("184") == false ? (dataOfLocation.brivo_access.brivofloors < (parseInt(row*8)+8) ? true : false) : true}
       name = {parseInt(row*8)+7}
       value={options.find(option => option.value === getBrivoValue(parseInt(row*8)+7))}
     onChange={handleChangeBrivoRelay(parseInt(row*8)+7)}
     variant="filled"
     
     options={options}
     getOptionLabel={(option) => option.label}
     PaperComponent={({ children }) => (
       <Paper style={{ backgroundColor: "#1E1E1E" ,color:"#AFAFAF", "&:hover":{backgroundColor: "#fff"}}}>{children}</Paper>
     )}
     renderInput={(params) => <TextField {...params} 
      variant="outlined" size="small" style={{ backgroundColor: "#121212",borderRadius: '5px' ,color:"#fff",
   
       
   }} />}
   renderOption={(props, option) => (
     <MenuItem
       {...props}
       key={option.id}
       sx={{
         color: "#fff",
         backgroundColor: "#2F323A",
         borderBottom: '.5px solid grey',
         fontFamily: "Lato",
         fontSize: '12px',
       }}
       disabled={option.disabled}
     >
       <Stack direction="row" justifyContent="left" alignItems="center">
         {option.label === "None" && (
           <ListItemIcon sx={{ color: "red" }}>
             <DoNotDisturbIcon fontSize="small" />
           </ListItemIcon>
         )}
         {option.label}
       </Stack>
     </MenuItem>
   )}
     ></Autocomplete>
              {/* <Select
            sx={{ color: 'rgba(135, 135, 135, 0.5)',
            height:'33px',
            marginTop:'4px',
            marginBottom:'3px',
            borderRadius: '5px',
            backgroundColor: '#121212',
            boxShadow: 'inset 0px 4px 4px rgba(0, 0, 0, 0.25)',
            padding: '5px 0 5px 0',
            "& .MuiSvgIcon-root": {
              color: "rgba(135, 135, 135, 0.5)",
            },
            
            '&.Mui-disabled': {
             

              background: 'rgba(135, 135, 135, 0.5)',
            },
            "& input.Mui-disabled": {
              color: "white"
            }
            }}
            name = {(row*8)+7}
            value={getBrivoValue((row*8)+7)}
          onChange={handleChangeBrivoRelay}
          variant="filled"
          disabled={getDisableValue("184") == false ? (dataOfLocation.brivo_access.brivofloors < (parseInt(row*8)+8) ? true : false) :true}
          hiddenLabel
          MenuProps={MenuProps}>
            {brivoDoorArray?.map(menuItem => {
                  
                  return (
                    <MenuItem  sx={
                      {
                      
                      color:"#fff",
                      backgroundColor: "#2F323A",
                      borderBottom: '.5px solid grey',
                      fontFamily: "Lato",fontSize:'12px',
                  }}
                  disabled={menuItem.disabled}
                   value={menuItem.name}>
                        <Stack direction="row" justifyContent="left"
  alignItems="center">
                  { menuItem.name == "None" && <ListItemIcon sx={{color: "red"}}>
            <DoNotDisturbIcon fontSize="small" />
          </ListItemIcon>} 
            {menuItem.name}</Stack>
                    </MenuItem>
                  );
                })}
          </Select>  */}</TableCell>
            </TableRow>
          ))}
        </TableBody>
      </Table>
    </TableContainer>}

    
      {dataOfLocation.unlocking_mode != '1' &&  dataOfLocation.unlocking_mode != '4' && 
      <TableContainer component={Paper} sx={{
        width:'auto',
        height: 'auto',
        marginLeft: '38px',
        marginRight:'38px',
        marginBottom:'38px',
        backgroundColor:'#171E29',
       
        }}>
      <Table sx={{ minWidth: 650 }} >
        
        <TableHead sx={{ borderBottom: "1.5px solid black",
        }}>
          <TableRow>
            
            <TableCell align="center" sx={mainThemeStyle.popUpLable} colSpan={16}>Web Relay Programming
            <Tooltip title="Add a letter 'P' to designate a Primary Relay, and add a numeral 1-64 to designate a Floor Relay">
      <IconButton  size="small" color="primary" >
        <InfoOutlinedIcon />
      </IconButton>
    </Tooltip></TableCell>
            
          </TableRow>
         
        </TableHead>

        
        <TableHead sx={{ borderBottom: "1.5px solid black",
        }}>
          <TableRow>
          <TableCell align="right" sx={mainThemeStyle.cellHeader}> Relay</TableCell>
            <TableCell align="right" sx={mainThemeStyle.cellHeader}>Floor</TableCell>
            <TableCell align="right" sx={mainThemeStyle.cellHeader}> Relay</TableCell>
            <TableCell align="right" sx={mainThemeStyle.cellHeader}>Floor</TableCell>
            <TableCell align="right" sx={mainThemeStyle.cellHeader}> Relay</TableCell>
            <TableCell align="right" sx={mainThemeStyle.cellHeader}>Floor</TableCell>
            <TableCell align="right" sx={mainThemeStyle.cellHeader}> Relay</TableCell>
            <TableCell align="right" sx={mainThemeStyle.cellHeader}>Floor</TableCell>
            <TableCell align="right" sx={mainThemeStyle.cellHeader}> Relay</TableCell>
            <TableCell align="right" sx={mainThemeStyle.cellHeader}>Floor</TableCell>
            <TableCell align="right" sx={mainThemeStyle.cellHeader}> Relay</TableCell>
            <TableCell align="right" sx={mainThemeStyle.cellHeader}>Floor</TableCell>
            <TableCell align="right" sx={mainThemeStyle.cellHeader}> Relay</TableCell>
            <TableCell align="right" sx={mainThemeStyle.cellHeader}>Floor</TableCell>
            <TableCell align="right" sx={mainThemeStyle.cellHeader}> Relay</TableCell>
            <TableCell align="right" sx={mainThemeStyle.cellHeader}>Floor</TableCell>
          </TableRow></TableHead>
        <TableBody>
         {rowsElevatorControl.map((row) => (
            <TableRow sx={{ borderBottom: "1.5px solid black",
          }}>
              <TableCell sx={mainThemeStyle.cellRow}>
              {parseInt(row*8)+1}
              </TableCell>
              <TableCell align="right"> <TextField
        id="text"
        type="text"
        value={dataOfLocation.elevator_access.program[parseInt(row*8)]}
        name={parseInt(row*8)}
        onChange={handleChangeEleInnerRelay}
       
        disabled={getDisableValue("183") == false ? (dataOfLocation.elevator_access.totalrelays < (parseInt(row*8)+1) ? true : false) : true}
                        InputLabelProps={{ shrink: true }
                      }
                      variant="filled"   
                        InputProps={{
                          inputProps: { 
                            maxLength:2,
                        },
                            sx: {
                                
                                color:'rgba(135, 135, 135, 0.5)',
                                height:'25px',
                                borderRadius: '5px',
                                backgroundColor: '#121212',
                                boxShadow: 'inset 0px 4px 4px rgba(0, 0, 0, 0.25)',
                                padding: '0 0 15px 0',
                                textIndent: '15px !important',
                                '&.Mui-disabled': {
             

                                  background: 'rgba(135, 135, 135, 0.5)',
                                },
                                
                                "& input.Mui-disabled": {
                                  color: "white"
                                }
                               
                                
                            },
                        }}
                       
      /></TableCell>
              <TableCell sx={mainThemeStyle.cellRow}>
              {parseInt(row*8)+2}
              </TableCell>
              <TableCell align="right"> <TextField
        id="text"
        type="text"
        value={dataOfLocation.elevator_access.program[(row*8)+1]}
        name={(row*8)+1}
        onChange={handleChangeEleInnerRelay}
        disabled={getDisableValue("183") == false ? (dataOfLocation.elevator_access.totalrelays < (parseInt(row*8)+2) ? true : false) : true}
        variant="filled"   
                        InputLabelProps={{ shrink: true }
                      }
                          
                        InputProps={{
                          inputProps: { 
                            maxLength:2,
                        },
                            sx: {
                                
                                color:'rgba(135, 135, 135, 0.5)',
                                height:'25px',
                                borderRadius: '5px',
                                backgroundColor: '#121212',
                                boxShadow: 'inset 0px 4px 4px rgba(0, 0, 0, 0.25)',
                                padding: '0 0 15px 0',
                                textIndent: '15px !important',
                              
                                '&.Mui-disabled': {
             

                                  background: 'rgba(135, 135, 135, 0.5)',
                                },
                                
                                "& input.Mui-disabled": {
                                  color: "white"
                                }
                                
                            },
                        }}
                       
      /></TableCell>
      <TableCell sx={mainThemeStyle.cellRow}>
              {parseInt(row*8)+3}
              </TableCell>
              <TableCell align="right"> <TextField
        id="text"
        type="text"
        variant="filled"   
        value={dataOfLocation.elevator_access.program[(row*8)+2]}
        name={(row*8)+2}
        onChange={handleChangeEleInnerRelay}
        disabled={getDisableValue("183") == false ? (dataOfLocation.elevator_access.totalrelays < (parseInt(row*8)+3) ? true : false) : true}
                        InputLabelProps={{ shrink: true }
                      }
                          
                        InputProps={{
                          inputProps: { 
                            maxLength:2,
                        },
                            sx: {
                                
                                color:'rgba(135, 135, 135, 0.5)',
                                height:'25px',
                                borderRadius: '5px',
                                backgroundColor: '#121212',
                                boxShadow: 'inset 0px 4px 4px rgba(0, 0, 0, 0.25)',
                                padding: '0 0 15px 0',
                                textIndent: '15px !important',
                              
                               
                                '&.Mui-disabled': {
             

                                  background: 'rgba(135, 135, 135, 0.5)',
                                },
                                
                                "& input.Mui-disabled": {
                                  color: "white"
                                }
                            },
                        }}
                       
      /></TableCell>
      <TableCell sx={mainThemeStyle.cellRow}>
              {(row*8)+4}
              </TableCell>
              <TableCell align="right"> <TextField
        id="text"
        type="text"
        variant="filled"   
        value={dataOfLocation.elevator_access.program[(row*8)+3]}
        name={(row*8)+3}
        onChange={handleChangeEleInnerRelay}                
        disabled={getDisableValue("183") == false ? (dataOfLocation.elevator_access.totalrelays < (parseInt(row*8)+4) ? true : false) : true}
        InputLabelProps={{ shrink: true }
                      }
                          
                        InputProps={{
                          inputProps: { 
                            maxLength:2,
                        },
                            sx: {
                                
                                color:'rgba(135, 135, 135, 0.5)',
                                height:'25px',
                                borderRadius: '5px',
                                backgroundColor: '#121212',
                                boxShadow: 'inset 0px 4px 4px rgba(0, 0, 0, 0.25)',
                                padding: '0 0 15px 0',
                                textIndent: '15px !important',
                              
                                '&.Mui-disabled': {
             

                                  background: 'rgba(135, 135, 135, 0.5)',
                                },
                                
                                "& input.Mui-disabled": {
                                  color: "white"
                                }
                                
                            },
                        }}
                       
      /></TableCell>
      <TableCell sx={mainThemeStyle.cellRow}>
              {(row*8)+5}
              </TableCell>
              <TableCell align="right"> <TextField
        id="text"
        type="text"
        variant="filled"   
        value={dataOfLocation.elevator_access.program[(row*8)+4]}
        name={(row*8)+4}
        disabled={getDisableValue("183") == false ? (dataOfLocation.elevator_access.totalrelays < (parseInt(row*8)+5) ? true : false) : true}
        onChange={handleChangeEleInnerRelay}   
                        InputLabelProps={{ shrink: true }
                      }
                          
                        InputProps={{
                          inputProps: { 
                            maxLength:2,
                        },
                            sx: {
                                
                                color:'rgba(135, 135, 135, 0.5)',
                                height:'25px',
                                borderRadius: '5px',
                                backgroundColor: '#121212',
                                boxShadow: 'inset 0px 4px 4px rgba(0, 0, 0, 0.25)',
                                padding: '0 0 15px 0',
                                textIndent: '15px !important',
                              
                                '&.Mui-disabled': {
             

                                  background: 'rgba(135, 135, 135, 0.5)',
                                },
                                
                                "& input.Mui-disabled": {
                                  color: "white"
                                }
                                
                            },
                        }}
                       
      /></TableCell>
      <TableCell sx={mainThemeStyle.cellRow}>
              {(row*8)+6}
              </TableCell>
              <TableCell align="right"> <TextField
        id="text"
        type="text"
        value={dataOfLocation.elevator_access.program[(row*8)+5]}
        name={(row*8)+5}
        disabled={getDisableValue("183") == false ? (dataOfLocation.elevator_access.totalrelays < (parseInt(row*8)+6) ? true : false) : true}
        onChange={handleChangeEleInnerRelay}   
        variant="filled" 
                        InputLabelProps={{ shrink: true }
                      }
                          
                        InputProps={{
                          inputProps: { 
                            maxLength:2,
                        },
                            sx: {
                                
                                color:'rgba(135, 135, 135, 0.5)',
                                height:'25px',
                                borderRadius: '5px',
                                backgroundColor: '#121212',
                                boxShadow: 'inset 0px 4px 4px rgba(0, 0, 0, 0.25)',
                                padding: '0 0 15px 0',
                                textIndent: '15px !important',
                                '&.Mui-disabled': {
             

                                  background: 'rgba(135, 135, 135, 0.5)',
                                },
                                
                                "& input.Mui-disabled": {
                                  color: "white"
                                }
                               
                                
                            },
                        }}
                       
      /></TableCell>
      <TableCell sx={mainThemeStyle.cellRow}>
              {(row*8)+7}
              </TableCell>
              <TableCell align="right"> <TextField
        id="text"
        type="text"
        variant="filled" 
        value={ dataOfLocation.elevator_access.program[(row*8)+6]}
        name={(row*8)+6}
        disabled={getDisableValue("183") == false ? (dataOfLocation.elevator_access.totalrelays < (parseInt(row*8)+7) ? true : false) : true}
        onChange={handleChangeEleInnerRelay}  
                        InputLabelProps={{ shrink: true }
                      }
                          
                        InputProps={{
                          inputProps: { 
                            maxLength:2,
                        },
                            sx: {
                                
                                color:'rgba(135, 135, 135, 0.5)',
                                height:'25px',
                                borderRadius: '5px',
                                backgroundColor: '#121212',
                                boxShadow: 'inset 0px 4px 4px rgba(0, 0, 0, 0.25)',
                                padding: '0 0 15px 0',
                                textIndent: '15px !important',
                                '&.Mui-disabled': {
             

                                  background: 'rgba(135, 135, 135, 0.5)',
                                },
                                
                                "& input.Mui-disabled": {
                                  color: "white"
                                }
                               
                                
                            },
                        }}
                       
      /></TableCell>
      <TableCell sx={mainThemeStyle.cellRow}>
              {(row*8)+8}
              </TableCell>
              <TableCell align="right"> <TextField
        id="text"
        type="text"
        variant="filled" 
        value={dataOfLocation.elevator_access.program[(row*8)+7]}
        name={(row*8)+7}
        onChange={handleChangeEleInnerRelay}  
        disabled={getDisableValue("183") == false ? (dataOfLocation.elevator_access.totalrelays < (parseInt(row*8)+8) ? true : false) : true}
                        InputLabelProps={{ shrink: true }
                      }
                          
                        InputProps={{
                          inputProps: { 
                            maxLength:2,
                        },
                         
                            sx: {
                                
                                color:'rgba(135, 135, 135, 0.5)',
                                height:'25px',
                                borderRadius: '5px',
                                backgroundColor: '#121212',
                                boxShadow: 'inset 0px 4px 4px rgba(0, 0, 0, 0.25)',
                                padding: '0 0 15px 0',
                                textIndent: '15px !important',
                                '&.Mui-disabled': {
             

                                  background: 'rgba(135, 135, 135, 0.5)',
                                },
                                
                                "& input.Mui-disabled": {
                                  color: "white"
                                }
                               
                                
                            },
                        }}
                       
      /></TableCell>
            </TableRow>
          ))}
        </TableBody>
      </Table>
    </TableContainer>}
      </Box>
      <Box sx={{display: { xs: "block", md: "none" }}}>
           <Divider sx={{backgroundColor:'white'}} />
           <Grid container  spacing={2} padding= '10px' paddingBottom='32px' paddingTop='32px'> 
      <Grid item xs={5}>
        <Stack direction="column"  spacing={2}>
      
    <Typography sx={mainThemeStyle.popUpLable} height={40}> Resident PIN </Typography>
    <Typography sx={mainThemeStyle.popUpLable} height={35}> Guest PIN </Typography>
    <Typography  sx={mainThemeStyle.popUpLable} height={35}>Unlocking Mode </Typography>
    {/* {dataOfLocation.unlocking_mode != '1' && <>
    <Typography sx={mainThemeStyle.popUpLable} height={40}> Relay Hostname </Typography>
    <Typography  sx={mainThemeStyle.popUpLable} height={40}>Relay 1 Name</Typography>
    <Typography  sx={mainThemeStyle.popUpLable} height={40}>{ (dataOfLocation.relay_one_fname).length > 0 ? dataOfLocation.relay_one_fname + " Unlock Duration" : "Relay 1 Unlock Duration"}</Typography>
    <Typography  sx={mainThemeStyle.popUpLable} height={40}>Relay 2 Name</Typography>
   <Typography  sx={mainThemeStyle.popUpLable} height={40}>{ (dataOfLocation.relay_two_fname).length > 0 ? dataOfLocation.relay_two_fname + " Unlock Duration" : "Relay 2 Unlock Duration"}</Typography>
   </>} */}

  
   {dataOfLocation.unlocking_mode != '1' && dataOfLocation.unlocking_mode != '4'  && <>
   <Typography sx={mainThemeStyle.popUpLable} height={40}> Relay Hostname </Typography> 
       <Typography  sx={mainThemeStyle.popUpLable} height={40}># of System Relays</Typography>
      <Typography  sx={mainThemeStyle.popUpLable} height={40}>Primary Relay Duration</Typography>
      <Typography  sx={mainThemeStyle.popUpLable} height={40}>Floor Relay Duration</Typography>
      <Typography  sx={mainThemeStyle.popUpLable} height={40}>Disable Primary Relay</Typography>
      <Typography  sx={mainThemeStyle.popUpLable} height={40}>Disable Floor Relay</Typography>
      <Typography  sx={mainThemeStyle.popUpLable} height={40}>Disable Floor Relay for App Unlock</Typography>
      <Typography  sx={mainThemeStyle.popUpLable} height={40}>Primary Relay Friendly Name</Typography>
     

   </>}
   {dataOfLocation.unlocking_mode == '1' && <>
     {/*  <Typography  sx={mainThemeStyle.popUpLable} height={35}>Brivo Door ID 1</Typography>
      <Typography  sx={mainThemeStyle.popUpLable} height={35}>Brivo Door ID 2</Typography> */}
       <Typography  sx={mainThemeStyle.popUpLable} height={35}>Brivo Primary Door Id</Typography>
       <Typography  sx={mainThemeStyle.popUpLable} height={35}># of Brivo Floors</Typography>
      <Typography  sx={mainThemeStyle.popUpLable} height={40}>In-Call Relay Triggers</Typography>
     
      </>}
      {dataOfLocation.unlocking_mode == '4' && <>
    
       <Typography  sx={mainThemeStyle.popUpLable} height={35}>PDK Primary Door ID</Typography>
       <Typography  sx={mainThemeStyle.popUpLable} height={35}>PDK Secondary Door ID</Typography>
     
     
      </>}
    </Stack>
      </Grid>
      
      <Grid item xs={7}>
      <Stack direction="column"  spacing={2}>
        
      

          <FormControlLabel   sx={mainThemeStyle.popUpLableNewone}
        control={<IOSSwitch sx={{ m: 1 }} name="r_pin_off" onChange={handlecheckboxCheckReverse}  disabled ={getDisableValue("172")} checked={dataOfLocation.r_pin_off == "0" ? true : false} />}
        label={dataOfLocation.r_pin_off == "0" ? "Enabled" : "Disabled"}
      />
      <FormControlLabel   sx={mainThemeStyle.popUpLableNewone}
        control={<IOSSwitch sx={{ m: 1 }} name="g_pin_off" onChange={handlecheckboxCheckReverse}  disabled ={getDisableValue("173")} checked={dataOfLocation.g_pin_off == "0" ? true : false} />}
        label={dataOfLocation.g_pin_off == "0" ? "Enabled" : "Disabled"}
      />
       <Select 
            sx={{ color: 'rgba(135, 135, 135, 0.5)',
            height:'33px',
            marginTop:'4px',
            marginBottom:'3px',
            borderRadius: '5px',
            backgroundColor: '#121212',
            boxShadow: 'inset 0px 4px 4px rgba(0, 0, 0, 0.25)',
            padding: '5px 0 5px 0',
            "& .MuiSvgIcon-root": {
              color: "rgba(135, 135, 135, 0.5)",
            },
            
            '&.Mui-disabled': {
             

              background: 'rgba(135, 135, 135, 0.5)',
            },
            "& input.Mui-disabled": {
              color: "white"
            }
            }}
            name = "unlocking_mode"
            disabled ={getDisableValue("158")}
            value={GetUnlockingModeQR()}
          label="Age"
          onChange={handleSetUnlockingModeQR}
          variant="filled"
          hiddenLabel
          MenuProps={MenuProps}>
            {UnlockingModeQR.items.map(menuItem => {
                  
                  return (
                    <MenuItem  sx={
                      {
                      
                      color:"#fff",
                      backgroundColor: "#2F323A",
                      borderBottom: '.5px solid grey',
                      fontFamily: "Lato",fontSize:'12px',
                  }}
                   value={menuItem.label}>
                      {menuItem.label}
                    </MenuItem>
                  );
                })}
          </Select>
            {dataOfLocation.unlocking_mode != '1'  &&  dataOfLocation.unlocking_mode != '4' && <> 
            <TextField
                        id="input-relay-host"
                        height={80}
                        type= 'text'
                        name="realy_hostname"
                        value={dataOfLocation.realy_hostname}
                        onChange={handleInputChange}
                        placeholder='api.controlbyweb.cloud/DAT/api-key'
                        sx={mainThemeStyle.InputMenuItem}
                        InputLabelProps={{ shrink: true }
                      }
                      disabled = {getDisableValue("185")}
                      variant="filled"
                        InputProps={{
                          
                            sx: {
                                
                                color:'rgba(135, 135, 135, 0.5)',
                                height:'33px',
                                borderRadius: '5px',
                                backgroundColor: '#121212',
                                boxShadow: 'inset 0px 4px 4px rgba(0, 0, 0, 0.25)',
                                padding: '0 0 15px 0',
                                textIndent: '15px !important',
                                '&.Mui-disabled': {
             

                                  background: 'rgba(135, 135, 135, 0.5)',
                                },
                                
                                "& input.Mui-disabled": {
                                  color: "white"
                                }
                               
                                
                            },
                        }}
                       
                    />


 <TextField
        id="total-relays"
        type="number"
        value={dataOfLocation.elevator_access.totalrelays}
        sx={mainThemeStyle.InputMenuItem}
                        InputLabelProps={{ shrink: true }
                      }
                      disabled={getDisableValue("183")}
                      name="totalrelays"
                      onChange={handleChangeEleValue}
                      variant="filled"
                        InputProps={{
                          inputProps: { 
                            max: 66, min: 0 
                        },
                            sx: {
                              
                                color:'rgba(135, 135, 135, 0.5)',
                                height:'33px',
                                borderRadius: '5px',
                                backgroundColor: '#121212',
                                boxShadow: 'inset 0px 4px 4px rgba(0, 0, 0, 0.25)',
                                padding: '0 0 15px 0',
                                textIndent: '15px !important',
                                '&.Mui-disabled': {
             

                                  background: 'rgba(135, 135, 135, 0.5)',
                                },
                                
                                "& input.Mui-disabled": {
                                  color: "white"
                                }
                               
                                
                            },
                        }}
                       
      />
      <TextField
                        id="input-vegax-duration"
                        height={40}
                        type= 'number'
                        name="vegax_duration"
                        value={dataOfLocation.vegax_duration}
                        onChange={handleInputChange}
                        sx={mainThemeStyle.InputMenuItem}
                        InputLabelProps={{ shrink: true }
                      }
                      disabled = {getDisableValue("177")}
                      variant="filled"
                        InputProps={{
                          inputProps: { 
                            max: 99, min: 0 
                        },
                            sx: {
                                
                                color:'rgba(135, 135, 135, 0.5)',
                                height:'33px',
                                borderRadius: '5px',
                                backgroundColor: '#121212',
                                boxShadow: 'inset 0px 4px 4px rgba(0, 0, 0, 0.25)',
                                padding: '0 0 15px 0',
                                textIndent: '15px !important',
                              
                                '&.Mui-disabled': {
             

                                  background: 'rgba(135, 135, 135, 0.5)',
                                },
                                
                                "& input.Mui-disabled": {
                                  color: "white"
                                }
                                
                            },
                        }}
                       
                    />
      <TextField
                        id="input-vegax2-duration"
                        height={40}
                        type= 'number'
                        name="vegax_second_duration"
                        value={dataOfLocation.vegax_second_duration}
                        onChange={handleInputChange}
                        sx={mainThemeStyle.InputMenuItem}
                        InputLabelProps={{ shrink: true }
                      }
                      disabled = {getDisableValue("180")}
                      variant="filled"
                        InputProps={{
                          inputProps: { 
                            max: 99, min: 0 
                        },
                            sx: {
                                
                                color:'rgba(135, 135, 135, 0.5)',
                                height:'33px',
                                borderRadius: '5px',
                                backgroundColor: '#121212',
                                boxShadow: 'inset 0px 4px 4px rgba(0, 0, 0, 0.25)',
                                padding: '0 0 15px 0',
                                textIndent: '15px !important',
                                '&.Mui-disabled': {
             

                                  background: 'rgba(135, 135, 135, 0.5)',
                                },
                                
                                "& input.Mui-disabled": {
                                  color: "white"
                                }
                               
                                
                            },
                        }}
                       
                    />
                    <FormControlLabel   sx={{ marginLeft: '0px',  height:'2.18rem',
            color:'#fff',}}
        control={<IOSSwitch sx={{ m: 1 }} name="diselevator"  disabled={getDisableValue("183")} onChange={handleChangeEleCheckValue} checked={dataOfLocation.elevator_access.diselevator == "1" ? true : false} />}
        label=""
      />
       <FormControlLabel  sx={mainThemeStyle.popUpLable}
        control={<IOSSwitch sx={{ m: 1 }} name="disrelease" disabled={getDisableValue("183")} onChange={handleChangeEleCheckValue} checked={dataOfLocation.elevator_access.disrelease == "1" ? true : false} />}
        label=""
      />
       <FormControlLabel  sx={mainThemeStyle.popUpLable}
        control={<IOSSwitch sx={{ m: 1 }} name="diselapp" disabled={getDisableValue("183")} onChange={handleChangeEleCheckValue} checked={dataOfLocation.elevator_access.diselapp == "1" ? true : false} />}
        label=""
      />
       <TextField
                        id="input-vegax-relay1"
                        height={40}
                        type= 'text'
                        name="relay_one_fname"
                        value={dataOfLocation.relay_one_fname}
                        onChange={handleInputChange}
                        sx={mainThemeStyle.InputMenuItem}
                        InputLabelProps={{ shrink: true }
                      }
                      disabled = {getDisableValue("175")}
                      variant="filled"
                        InputProps={{
                           
                            sx: {
                                
                                color:'rgba(135, 135, 135, 0.5)',
                                height:'33px',
                                borderRadius: '5px',
                                backgroundColor: '#121212',
                                boxShadow: 'inset 0px 4px 4px rgba(0, 0, 0, 0.25)',
                                padding: '0 0 15px 0',
                                textIndent: '15px !important',
                              
                                '&.Mui-disabled': {
             

                                  background: 'rgba(135, 135, 135, 0.5)',
                                },
                                
                                "& input.Mui-disabled": {
                                  color: "white"
                                }
                                
                            },
                        }}
                       
                    />
            </>}
          {/* {dataOfLocation.unlocking_mode != '1' && <>  <TextField
                        id="input-relay-host"
                        height={40}
                        type= 'text'
                        name="realy_hostname"
                        value={dataOfLocation.realy_hostname}
                        onChange={handleInputChange}
                        placeholder='api.controlbyweb.cloud/DAT/api-key'
                        sx={mainThemeStyle.InputMenuItem}
                        InputLabelProps={{ shrink: true }
                      }
                      disabled ={getDisableValue("185")}
                      variant="filled"
                        InputProps={{
                         
                            sx: {
                                
                                color:'rgba(135, 135, 135, 0.5)',
                                height:'33px',
                                borderRadius: '5px',
                                backgroundColor: '#121212',
                                boxShadow: 'inset 0px 4px 4px rgba(0, 0, 0, 0.25)',
                                padding: '0 0 15px 0',
                                textIndent: '15px !important',
                                '&.Mui-disabled': {
             

                                  background: 'rgba(135, 135, 135, 0.5)',
                                },
                                
                                "& input.Mui-disabled": {
                                  color: "white"
                                }
                               
                                
                            },
                        }}
                       
                    />
         
           <TextField
                        id="input-vegax-relay1"
                        height={40}
                        type= 'text'
                        name="relay_one_fname"
                        value={dataOfLocation.relay_one_fname}
                        onChange={handleInputChange}
                        sx={mainThemeStyle.InputMenuItem}
                        InputLabelProps={{ shrink: true }
                      }
                      disabled ={getDisableValue("175")}
                      variant="filled"
                        InputProps={{
                           
                            sx: {
                                
                                color:'rgba(135, 135, 135, 0.5)',
                                height:'33px',
                                borderRadius: '5px',
                                backgroundColor: '#121212',
                                boxShadow: 'inset 0px 4px 4px rgba(0, 0, 0, 0.25)',
                                padding: '0 0 15px 0',
                                textIndent: '15px !important',
                                '&.Mui-disabled': {
             

                                  background: 'rgba(135, 135, 135, 0.5)',
                                },
                                
                                "& input.Mui-disabled": {
                                  color: "white"
                                }
                               
                                
                            },
                        }}
                       
                    />
                   <TextField
                        id="input-vegax-duration"
                        height={40}
                        type= 'number'
                        name="vegax_duration"
                        value={dataOfLocation.vegax_duration}
                        onChange={handleInputChange}
                        sx={mainThemeStyle.InputMenuItem}
                        InputLabelProps={{ shrink: true }
                      }
                      disabled ={getDisableValue("177")}
                      variant="filled"
                        InputProps={{
                          inputProps: { 
                            max: 99, min: 0 
                        },
                            sx: {
                                
                                color:'rgba(135, 135, 135, 0.5)',
                                height:'33px',
                                borderRadius: '5px',
                                backgroundColor: '#121212',
                                boxShadow: 'inset 0px 4px 4px rgba(0, 0, 0, 0.25)',
                                padding: '0 0 15px 0',
                                textIndent: '15px !important',
                              
                               
                                '&.Mui-disabled': {
             

                                  background: 'rgba(135, 135, 135, 0.5)',
                                },
                                
                                "& input.Mui-disabled": {
                                  color: "white"
                                }
                            },
                        }}
                       
                    />
                   <TextField
                        id="input-vegax-relay2"
                        height={40}
                        type= 'text'
                        name="relay_two_fname"
                        value={dataOfLocation.relay_two_fname}
                        onChange={handleInputChange}
                        sx={mainThemeStyle.InputMenuItem}
                        InputLabelProps={{ shrink: true }
                      }
                      disabled ={getDisableValue("178")}
                      variant="filled"
                        InputProps={{
                           
                            sx: {
                                
                                color:'rgba(135, 135, 135, 0.5)',
                                height:'33px',
                                borderRadius: '5px',
                                backgroundColor: '#121212',
                                boxShadow: 'inset 0px 4px 4px rgba(0, 0, 0, 0.25)',
                                padding: '0 0 15px 0',
                                textIndent: '15px !important',
                              
                                '&.Mui-disabled': {
             

                                  background: 'rgba(135, 135, 135, 0.5)',
                                },
                                
                                "& input.Mui-disabled": {
                                  color: "white"
                                }
                                
                            },
                        }}
                       
                    />
                    
                     
                      <TextField
                        id="input-vegax2-duration"
                        height={40}
                        type= 'number'
                        name="vegax_second_duration"
                        value={dataOfLocation.vegax_second_duration}
                        onChange={handleInputChange}
                        sx={mainThemeStyle.InputMenuItem}
                        InputLabelProps={{ shrink: true }
                      }
                      disabled ={getDisableValue("180")}
                      variant="filled"
                        InputProps={{
                          inputProps: { 
                            max: 99, min: 0 
                        },
                            sx: {
                                
                                color:'rgba(135, 135, 135, 0.5)',
                                height:'33px',
                                borderRadius: '5px',
                                backgroundColor: '#121212',
                                boxShadow: 'inset 0px 4px 4px rgba(0, 0, 0, 0.25)',
                                padding: '0 0 15px 0',
                                textIndent: '15px !important',
                                '&.Mui-disabled': {
             

                                  background: 'rgba(135, 135, 135, 0.5)',
                                },
                                
                                "& input.Mui-disabled": {
                                  color: "white"
                                }
                               
                                
                            },
                        }}
                       
                    /></> } */}
           {/*   {dataOfLocation.unlocking_mode == '1' && <>
                     <Select
            sx={{ color: 'rgba(135, 135, 135, 0.5)',
            height:'33px',
            
            marginBottom:'3px',
            borderRadius: '5px',
            backgroundColor: '#121212',
            boxShadow: 'inset 0px 4px 4px rgba(0, 0, 0, 0.25)',
            padding: '5px 0 5px 0',
            "& .MuiSvgIcon-root": {
              color: "rgba(135, 135, 135, 0.5)",
            },
            
            '&.Mui-disabled': {
             

              background: 'rgba(135, 135, 135, 0.5)',
            },
            "& input.Mui-disabled": {
              color: "white"
            }
            }}
            name = 'brivoprimeid'
            value={getBrivoValueMain()}
          onChange={handleChangeBrivoDoorValue}
          variant="filled"
          hiddenLabel
          MenuProps={MenuProps}>
            {dataOfLocation.brivo_array.map(menuItem => {
                  
                  return (
                    <MenuItem  sx={
                      {
                      
                      color:"#fff",
                      backgroundColor: "#2F323A",
                      borderBottom: '.5px solid grey',
                      fontFamily: "Lato",fontSize:'12px',
                  }}
                   value={menuItem.name}>
                      {menuItem.name}
                    </MenuItem>
                  );
                })}
          </Select>
         
                   
          <Select
            sx={{ color: 'rgba(135, 135, 135, 0.5)',
            height:'33px',
            marginTop:'4px',
            marginBottom:'3px',
            borderRadius: '5px',
            backgroundColor: '#121212',
            boxShadow: 'inset 0px 4px 4px rgba(0, 0, 0, 0.25)',
            padding: '5px 0 5px 0',
            "& .MuiSvgIcon-root": {
              color: "rgba(135, 135, 135, 0.5)",
            },
            
            '&.Mui-disabled': {
             

              background: 'rgba(135, 135, 135, 0.5)',
            },
            "& input.Mui-disabled": {
              color: "white"
            }
            }}
            name = "0"
            value={getBrivoValue(0)}
          onChange={handleChangeBrivoRelay}
          variant="filled"
          
          hiddenLabel
          MenuProps={MenuProps}>
            {dataOfLocation.brivo_array.map(menuItem => {
                  
                  return (
                    <MenuItem  sx={
                      {
                      
                      color:"#fff",
                      backgroundColor: "#2F323A",
                      borderBottom: '.5px solid grey',
                      fontFamily: "Lato",fontSize:'12px',
                  }}
                   value={menuItem.name}>
                      {menuItem.name}
                    </MenuItem>
                  );
                })}
          </Select>
                     
                 
                  </>} */}
       {dataOfLocation.unlocking_mode == '1' &&



       <Autocomplete
       style={{marginTop: 0,minWidth: 250}}
        sx={{  "& .MuiAutocomplete-inputRoot": {
         color: "rgba(170, 170, 170,1)" },"& .MuiSvgIcon-root": {
           color: "rgba(170, 170, 170, 1)",
         },
        "& .Mui-disabled": {
          color: 'rgba(135, 135, 135, 0.5) ',
          "& .MuiSvgIcon-root": {
            color: "rgba(135, 135, 135, 0.5)"
          }
        },
        "& .MuiInputBase-root.Mui-disabled": {
          backgroundColor: 'rgba(135, 135, 135, 0.5)',
          color: 'white',
        },
        
        }}
       disablePortal
       disabled = {getDisableValue("184")}
       id="brivoprimeid"
       name = 'brivoprimeid'
       value={options.find(option => option.value === getBrivoValueMain())}
     onChange={handleChangeBrivoDoorValue}
     variant="filled"
     
     options={options}
     getOptionLabel={(option) => option.label}
     PaperComponent={({ children }) => (
       <Paper style={{ backgroundColor: "#1E1E1E" ,color:"#AFAFAF", "&:hover":{backgroundColor: "#fff"}}}>{children}</Paper>
     )}
     renderInput={(params) => <TextField {...params} 
      variant="outlined" size="small" style={{ backgroundColor: "#121212",borderRadius: '5px' ,color:"#fff",
   
       
   }} />}
   renderOption={(props, option) => (
     <MenuItem
       {...props}
       key={option.id}
       sx={{
         color: "#fff",
         backgroundColor: "#2F323A",
         borderBottom: '.5px solid grey',
         fontFamily: "Lato",
         fontSize: '12px',
       }}
       disabled={option.disabled}
     >
       <Stack direction="row" justifyContent="left" alignItems="center">
         {option.label === "None" && (
           <ListItemIcon sx={{ color: "red" }}>
             <DoNotDisturbIcon fontSize="small" />
           </ListItemIcon>
         )}
         {option.label}
       </Stack>
     </MenuItem>
   )}
     ></Autocomplete> 
      /* <Select
            sx={{ color: 'rgba(135, 135, 135, 0.5)',
            height:'33px',
            marginTop:'4px',
            marginBottom:'3px',
            borderRadius: '5px',
            backgroundColor: '#121212',
            boxShadow: 'inset 0px 4px 4px rgba(0, 0, 0, 0.25)',
            padding: '5px 0 5px 0',
            "& .MuiSvgIcon-root": {
              color: "rgba(135, 135, 135, 0.5)",
            },
            
            '&.Mui-disabled': {
             

              background: 'rgba(135, 135, 135, 0.5)',
            },
            "& input.Mui-disabled": {
              color: "white"
            }
            }}
            disabled ={getDisableValue("184")}
            name = 'brivoprimeid'
            value={getBrivoValueMain()}
          onChange={handleChangeBrivoDoorValue}
          variant="filled"
          hiddenLabel
          MenuProps={MenuProps}>
            {brivoDoorArray?.map(menuItem => {
                  
                  return (
                    <MenuItem  sx={
                      {
                      
                      color:"#fff",
                      backgroundColor: "#2F323A",
                      borderBottom: '.5px solid grey',
                      fontFamily: "Lato",fontSize:'12px',
                  }}
                  disabled={menuItem.disabled}
                   value={menuItem.name}>
                        <Stack direction="row" justifyContent="left"
  alignItems="center">
                  { menuItem.name == "None" && <ListItemIcon sx={{color: "red"}}>
            <DoNotDisturbIcon fontSize="small" />
          </ListItemIcon>} 
            {menuItem.name}</Stack>
                    </MenuItem>
                  );
                })}
          </Select>*/}
     {dataOfLocation.unlocking_mode == '1' &&
     <TextField
     id="brivofloors"
     height={40}
     type= 'number'
     name="brivofloors"
     value={dataOfLocation.brivo_access.brivofloors}
     onChange={handleChangeBrivoFloorValue}
     sx={mainThemeStyle.InputMenuItem}
     InputLabelProps={{ shrink: true }
   }
   disabled ={getDisableValue("184")}
   variant="filled"
     InputProps={{
       inputProps: { 
         max: (dataOfLocation.brivo_array.length), min: 0 
     },
         sx: {
             
             color:'rgba(135, 135, 135, 0.5)',
             height:'33px',
             borderRadius: '5px',
             backgroundColor: '#121212',
             boxShadow: 'inset 0px 4px 4px rgba(0, 0, 0, 0.25)',
             padding: '0 0 15px 0',
             textIndent: '15px !important',
             '&.Mui-disabled': {
             

              background: 'rgba(135, 135, 135, 0.5)',
            },
            
            "& input.Mui-disabled": {
              color: "white"
            }
            
             
         },
     }}

     
    
 />}
 {dataOfLocation.unlocking_mode == '1' && <FormControlLabel   sx={mainThemeStyle.popUpLableNewone} height={40}
     control={<IOSSwitch sx={{ m: 1 }} name="icrt"  disabled = {getDisableValue("184")} onChange={handleBrivoInCallRelayTriggerCheck}  checked={dataOfLocation.brivo_access.icrt == null ? false : (dataOfLocation.brivo_access.icrt == "1" ? true : false)} />}
     label={dataOfLocation.brivo_access.icrt == null ? "Disabled" : (dataOfLocation.brivo_access.icrt == "1" ? "Enabled" : "Disabled")}
   /> }
   {dataOfLocation.unlocking_mode == '4' &&<Select
            sx={{ color: 'rgba(135, 135, 135, 0.5)',
            height:'33px',
            marginTop:'4px',
            marginBottom:'3px',
            borderRadius: '5px',
            backgroundColor: '#121212',
            boxShadow: 'inset 0px 4px 4px rgba(0, 0, 0, 0.25)',
            padding: '5px 0 5px 0',
            "& .MuiSvgIcon-root": {
              color: "rgba(135, 135, 135, 0.5)",
            },
            
            '&.Mui-disabled': {
             

              background: 'rgba(135, 135, 135, 0.5)',
            },
            "& input.Mui-disabled": {
              color: "white"
            }
            }}
            disabled = {getDisableValue("239")}
            name = 'primeid'
            value={getPDKValueMain()}
          onChange={handleChangePDKMainValue}
          variant="filled"
          hiddenLabel
          MenuProps={MenuProps}>
            {pdkDeviceArray?.map(menuItem => {
                  
                  return (
                    <MenuItem  sx={
                      {
                      
                      color:"#fff",
                      backgroundColor: "#2F323A",
                      borderBottom: '.5px solid grey',
                      fontFamily: "Lato",fontSize:'12px',
                  }}
                  disabled={menuItem.disabled}
                   value={menuItem.name}>
                    <Stack direction="row" justifyContent="left"
  alignItems="center">
                  { menuItem.name == "None" && <ListItemIcon sx={{color: "red"}}>
            <DoNotDisturbIcon fontSize="small" />
          </ListItemIcon>} 
            {menuItem.name}</Stack>
                    </MenuItem>
                  );
                })}
          </Select>}
          {dataOfLocation.unlocking_mode == '4' &&<Select
            sx={{ color: 'rgba(135, 135, 135, 0.5)',
            height:'33px',
            marginTop:'4px',
            marginBottom:'3px',
            borderRadius: '5px',
            backgroundColor: '#121212',
            boxShadow: 'inset 0px 4px 4px rgba(0, 0, 0, 0.25)',
            padding: '5px 0 5px 0',
            "& .MuiSvgIcon-root": {
              color: "rgba(135, 135, 135, 0.5)",
            },
            
            '&.Mui-disabled': {
             

              background: 'rgba(135, 135, 135, 0.5)',
            },
            "& input.Mui-disabled": {
              color: "white"
            }
            }}
            disabled = {getDisableValue("239")}
            name = 'brivoprimeid'
            value={getPDKValueOptional()}
          onChange={handleChangePDKOptionalValue}
          variant="filled"
          hiddenLabel
          MenuProps={MenuProps}>
            {pdkDeviceArray?.map(menuItem => {
                  
                  return (
                    <MenuItem  sx={
                      {
                      
                      color:"#fff",
                      backgroundColor: "#2F323A",
                      borderBottom: '.5px solid grey',
                      fontFamily: "Lato",fontSize:'12px',
                  }}
                  disabled={menuItem.disabled}
                   value={menuItem.name}>
                    <Stack direction="row" justifyContent="left"
  alignItems="center">
                  { menuItem.name == "None" && <ListItemIcon sx={{color: "red"}}>
            <DoNotDisturbIcon fontSize="small" />
          </ListItemIcon>} 
            {menuItem.name}</Stack>
                    </MenuItem>
                  );
                })}
          </Select>}
      </Stack>
      </Grid>
      
     
      
     
      
      </Grid>
      {dataOfLocation.unlocking_mode == '1'  && dataOfLocation.brivo_access.icrt == 1 && <Grid container spacing={2} padding= '32px'>
     
     <Grid item >
     <TableContainer component={Paper} sx={{
       width:'auto',
       height: 'auto',
       marginLeft: '38px',
       marginRight:'38px',
       marginBottom:'38px',
       backgroundColor:'#171E29',
      
       }}>
     <Table sx={{ minWidth: 350 }} >
       
       <TableHead sx={{ borderBottom: "1.5px solid black",
       }}>
         <TableRow>
           
           <TableCell align="center" sx={mainThemeStyle.popUpLable} colSpan={16}>In-Call Relay Trigger Settings</TableCell>
           
         </TableRow>
        
       </TableHead>

       
       <TableHead sx={{ borderBottom: "1.5px solid black",
       }}>
         </TableHead>
       <TableBody>
      
           <TableRow sx={{ borderBottom: "1.5px solid black",
         }}>
             <TableCell  sx={mainThemeStyle.cellHeader}>
             Custom Name 1 
             </TableCell>
            
               <TableCell align="right">
               <TextField
                       id="0"
                      
                       type= 'text'
                       name="0"
                       value={getCustomNameValue(0)}
                       onChange={handleChangeCustomNameValue}
                      
                       sx={{
                         ...mainThemeStyle.InputMenuItem,
                         marginTop:'10px',
                       }}
                       InputLabelProps={{ shrink: true }
                     }
                     disabled = {getDisableValue("184")}
                     variant="filled"
                       InputProps={{
                         
                           sx: {
                               
                               color:'rgba(135, 135, 135, 0.5)',
                               height:'33px',
                               borderRadius: '5px',
                               backgroundColor: '#121212',
                               boxShadow: 'inset 0px 4px 4px rgba(0, 0, 0, 0.25)',
                               padding: '0 0 15px 0',
                               textIndent: '15px !important',
                               '&.Mui-disabled': {
            

                                 background: 'rgba(135, 135, 135, 0.5)',
                               },
                               
                               "& input.Mui-disabled": {
                                 color: "white"
                               }
                              
                               
                           },
                       }}
                      
                   />
                   
               </TableCell>
               <TableCell align="right">
               
               <Select
             sx={{ color: 'rgba(135, 135, 135, 0.5)',
             height:'33px',
             marginTop:'8px',
             marginBottom:'3px',
             borderRadius: '5px',
             backgroundColor: '#121212',
             boxShadow: 'inset 0px 4px 4px rgba(0, 0, 0, 0.25)',
             padding: '5px 0 5px 0',
             "& .MuiSvgIcon-root": {
               color: "rgba(135, 135, 135, 0.5)",
             },
             
             '&.Mui-disabled': {
              
 
               background: 'rgba(135, 135, 135, 0.5)',
             },
             "& input.Mui-disabled": {
               color: "white"
             }
             }}
             name = "0"
             value={getCustomDoorValue(0)}
           onChange={handleChangeCustomDoorValue}
           variant="filled"
           disabled={getDisableValue("184")}
           hiddenLabel
           MenuProps={MenuProps}>
             {brivoCustomArray?.map(menuItem => {
                   
                   return (
                     <MenuItem  sx={
                       {
                       
                       color:"#fff",
                       backgroundColor: "#2F323A",
                       borderBottom: '.5px solid grey',
                       fontFamily: "Lato",fontSize:'12px',
                   }}
                   disabled={menuItem.disabled}
                    value={menuItem.name}>
                        <Stack direction="row" justifyContent="left"
   alignItems="center">
                   { menuItem.name == "None" && <ListItemIcon sx={{color: "red"}}>
             <DoNotDisturbIcon fontSize="small" />
           </ListItemIcon>} 
             {menuItem.name}</Stack>
                     </MenuItem>
                   );
                 })}
           </Select>
                 </TableCell>
           </TableRow>
           <TableRow sx={{ borderBottom: "1.5px solid black",
         }}>
             <TableCell  sx={mainThemeStyle.cellHeader}>
             Custom Name 2 
             </TableCell>
            
               <TableCell align="right">
               <TextField
                       id="1"
                      
                       type= 'text'
                       name="1"
                       value={getCustomNameValue(1)}
                       onChange={handleChangeCustomNameValue}
                      
                       sx={{
                         ...mainThemeStyle.InputMenuItem,
                         marginTop:'10px',
                       }}
                       InputLabelProps={{ shrink: true }
                     }
                     disabled = {getDisableValue("184")}
                     variant="filled"
                       InputProps={{
                         
                           sx: {
                               
                               color:'rgba(135, 135, 135, 0.5)',
                               height:'33px',
                               borderRadius: '5px',
                               backgroundColor: '#121212',
                               boxShadow: 'inset 0px 4px 4px rgba(0, 0, 0, 0.25)',
                               padding: '0 0 15px 0',
                               textIndent: '15px !important',
                               '&.Mui-disabled': {
            

                                 background: 'rgba(135, 135, 135, 0.5)',
                               },
                               
                               "& input.Mui-disabled": {
                                 color: "white"
                               }
                              
                               
                           },
                       }}
                      
                   />
                   
               </TableCell>
               <TableCell align="right">
               
               <Select
             sx={{ color: 'rgba(135, 135, 135, 0.5)',
             height:'33px',
             marginTop:'8px',
             marginBottom:'3px',
             borderRadius: '5px',
             backgroundColor: '#121212',
             boxShadow: 'inset 0px 4px 4px rgba(0, 0, 0, 0.25)',
             padding: '5px 0 5px 0',
             "& .MuiSvgIcon-root": {
               color: "rgba(135, 135, 135, 0.5)",
             },
             
             '&.Mui-disabled': {
              
 
               background: 'rgba(135, 135, 135, 0.5)',
             },
             "& input.Mui-disabled": {
               color: "white"
             }
             }}
             name = "1"
             value={getCustomDoorValue(1)}
           onChange={handleChangeCustomDoorValue}
           variant="filled"
           disabled={getDisableValue("184")}
           hiddenLabel
           MenuProps={MenuProps}>
             {brivoCustomArray?.map(menuItem => {
                   
                   return (
                     <MenuItem  sx={
                       {
                       
                       color:"#fff",
                       backgroundColor: "#2F323A",
                       borderBottom: '.5px solid grey',
                       fontFamily: "Lato",fontSize:'12px',
                   }}
                   disabled={menuItem.disabled}
                    value={menuItem.name}>
                        <Stack direction="row" justifyContent="left"
   alignItems="center">
                   { menuItem.name == "None" && <ListItemIcon sx={{color: "red"}}>
             <DoNotDisturbIcon fontSize="small" />
           </ListItemIcon>} 
             {menuItem.name}</Stack>
                     </MenuItem>
                   );
                 })}
           </Select>
                 </TableCell>
           </TableRow>
           <TableRow sx={{ borderBottom: "1.5px solid black",
         }}>
             <TableCell  sx={mainThemeStyle.cellHeader}>
             Custom Name 3 
             </TableCell>
            
               <TableCell align="right">
               <TextField
                       id="2"
                      
                       type= 'text'
                       name="2"
                       value={getCustomNameValue(2)}
                       onChange={handleChangeCustomNameValue}
                      
                       sx={{
                         ...mainThemeStyle.InputMenuItem,
                         marginTop:'10px',
                       }}
                       InputLabelProps={{ shrink: true }
                     }
                     disabled = {getDisableValue("184")}
                     variant="filled"
                       InputProps={{
                         
                           sx: {
                               
                               color:'rgba(135, 135, 135, 0.5)',
                               height:'33px',
                               borderRadius: '5px',
                               backgroundColor: '#121212',
                               boxShadow: 'inset 0px 4px 4px rgba(0, 0, 0, 0.25)',
                               padding: '0 0 15px 0',
                               textIndent: '15px !important',
                               '&.Mui-disabled': {
            

                                 background: 'rgba(135, 135, 135, 0.5)',
                               },
                               
                               "& input.Mui-disabled": {
                                 color: "white"
                               }
                              
                               
                           },
                       }}
                      
                   />
                   
               </TableCell>
               <TableCell align="right">
               
               <Select
             sx={{ color: 'rgba(135, 135, 135, 0.5)',
             height:'33px',
             marginTop:'8px',
             marginBottom:'3px',
             borderRadius: '5px',
             backgroundColor: '#121212',
             boxShadow: 'inset 0px 4px 4px rgba(0, 0, 0, 0.25)',
             padding: '5px 0 5px 0',
             "& .MuiSvgIcon-root": {
               color: "rgba(135, 135, 135, 0.5)",
             },
             
             '&.Mui-disabled': {
              
 
               background: 'rgba(135, 135, 135, 0.5)',
             },
             "& input.Mui-disabled": {
               color: "white"
             }
             }}
             name = "2"
             value={getCustomDoorValue(2)}
           onChange={handleChangeCustomDoorValue}
           variant="filled"
           disabled={getDisableValue("184")}
           hiddenLabel
           MenuProps={MenuProps}>
             {brivoCustomArray?.map(menuItem => {
                   
                   return (
                     <MenuItem  sx={
                       {
                       
                       color:"#fff",
                       backgroundColor: "#2F323A",
                       borderBottom: '.5px solid grey',
                       fontFamily: "Lato",fontSize:'12px',
                   }}
                   disabled={menuItem.disabled}
                    value={menuItem.name}>
                        <Stack direction="row" justifyContent="left"
   alignItems="center">
                   { menuItem.name == "None" && <ListItemIcon sx={{color: "red"}}>
             <DoNotDisturbIcon fontSize="small" />
           </ListItemIcon>} 
             {menuItem.name}</Stack>
                     </MenuItem>
                   );
                 })}
           </Select>
                 </TableCell>
           </TableRow>
       </TableBody>
     </Table>
   </TableContainer></Grid>
   </Grid>}
      {dataOfLocation.unlocking_mode == '1' &&
      <TableContainer component={Paper} sx={{
        width:'auto',
        height: 'auto',
        marginLeft: '38px',
        marginRight:'38px',
        marginBottom:'38px',
        backgroundColor:'#171E29',
       
        }}>
      <Table sx={{ minWidth: 650 }} >
        
        <TableHead sx={{ borderBottom: "1.5px solid black",
        }}>
          <TableRow>
            
            <TableCell align="center" sx={mainThemeStyle.popUpLable} colSpan={16}>Brivo Relays</TableCell>
            
          </TableRow>
         
        </TableHead>

        
        <TableHead sx={{ borderBottom: "1.5px solid black",
        }}>
          <TableRow>
          <TableCell align="right" sx={mainThemeStyle.cellHeader}> Floor</TableCell>
            <TableCell align="right" sx={mainThemeStyle.cellHeader}>	Door Id</TableCell>
            <TableCell align="right" sx={mainThemeStyle.cellHeader}> Floor</TableCell>
            <TableCell align="right" sx={mainThemeStyle.cellHeader}>Door Id</TableCell>
            <TableCell align="right" sx={mainThemeStyle.cellHeader}> Floor</TableCell>
            <TableCell align="right" sx={mainThemeStyle.cellHeader}>Door Id</TableCell>
            <TableCell align="right" sx={mainThemeStyle.cellHeader}> Floor</TableCell>
            <TableCell align="right" sx={mainThemeStyle.cellHeader}>Door Id</TableCell>
            <TableCell align="right" sx={mainThemeStyle.cellHeader}> Floor</TableCell>
            <TableCell align="right" sx={mainThemeStyle.cellHeader}>Door Id</TableCell>
            <TableCell align="right" sx={mainThemeStyle.cellHeader}> Floor</TableCell>
            <TableCell align="right" sx={mainThemeStyle.cellHeader}>Door Id</TableCell>
            <TableCell align="right" sx={mainThemeStyle.cellHeader}> Floor</TableCell>
            <TableCell align="right" sx={mainThemeStyle.cellHeader}>Door Id</TableCell>
            <TableCell align="right" sx={mainThemeStyle.cellHeader}> Floor</TableCell>
            <TableCell align="right" sx={mainThemeStyle.cellHeader}>Door Id</TableCell>
          </TableRow></TableHead>
        <TableBody>
         {rowsElevatorControl.map((row) => (
            <TableRow sx={{ borderBottom: "1.5px solid black",
          }}>
              <TableCell sx={mainThemeStyle.cellRow}>
              {parseInt(row*8)+1}
              </TableCell>
              <TableCell align="right">
                
              <Autocomplete
       style={{marginTop: 0,minWidth: 150}}
        sx={{  "& .MuiAutocomplete-inputRoot": {
         color: "rgba(170, 170, 170,1)" },"& .MuiSvgIcon-root": {
           color: "rgba(170, 170, 170, 1)",
         },
         "& .Mui-disabled": {
          color: 'rgba(135, 135, 135, 0.5) ',
          "& .MuiSvgIcon-root": {
           color: "rgba(135, 135, 135, 0.5)"
          }
        },
        "& .MuiInputBase-root.Mui-disabled": {
          backgroundColor: 'rgba(135, 135, 135, 0.5)',
          color: 'white',
        },
        
        }}
       disablePortal
       disabled = {getDisableValue("184") == false ? (dataOfLocation.brivo_access.brivofloors < (parseInt(row*8)+1) ? true : false) : true}
       name = {parseInt(row*8)}
       value={options.find(option => option.value === getBrivoValue(parseInt(row*8)))}
     onChange={handleChangeBrivoRelay(parseInt(row*8))}
     variant="filled"
     
     options={options}
     getOptionLabel={(option) => option.label}
     PaperComponent={({ children }) => (
       <Paper style={{ backgroundColor: "#1E1E1E" ,color:"#AFAFAF", "&:hover":{backgroundColor: "#fff"}}}>{children}</Paper>
     )}
     renderInput={(params) => <TextField {...params} 
      variant="outlined" size="small" style={{ backgroundColor: "#121212",borderRadius: '5px' ,color:"#fff",
   
       
   }} />}
   renderOption={(props, option) => (
     <MenuItem
       {...props}
       key={option.id}
       sx={{
         color: "#fff",
         backgroundColor: "#2F323A",
         borderBottom: '.5px solid grey',
         fontFamily: "Lato",
         fontSize: '12px',
       }}
       disabled={option.disabled}
     >
       <Stack direction="row" justifyContent="left" alignItems="center">
         {option.label === "None" && (
           <ListItemIcon sx={{ color: "red" }}>
             <DoNotDisturbIcon fontSize="small" />
           </ListItemIcon>
         )}
         {option.label}
       </Stack>
     </MenuItem>
   )}
     ></Autocomplete>
             {/*  <Select
            sx={{ color: 'rgba(135, 135, 135, 0.5)',
            height:'33px',
            marginTop:'4px',
            marginBottom:'3px',
            borderRadius: '5px',
            backgroundColor: '#121212',
            boxShadow: 'inset 0px 4px 4px rgba(0, 0, 0, 0.25)',
            padding: '5px 0 5px 0',
            "& .MuiSvgIcon-root": {
              color: "rgba(135, 135, 135, 0.5)",
            },
            
            '&.Mui-disabled': {
             

              background: 'rgba(135, 135, 135, 0.5)',
            },
            "& input.Mui-disabled": {
              color: "white"
            }
            }}
            name = {parseInt(row*8)}
            value={getBrivoValue(parseInt(row*8))}
          onChange={handleChangeBrivoRelay}
          variant="filled"
          disabled={getDisableValue("184") == false ? (dataOfLocation.brivo_access.brivofloors < (parseInt(row*8)+1) ? true : false) : true}
          hiddenLabel
          MenuProps={MenuProps}>
            {brivoDoorArray?.map(menuItem => {
                  
                  return (
                    <MenuItem  sx={
                      {
                      
                      color:"#fff",
                      backgroundColor: "#2F323A",
                      borderBottom: '.5px solid grey',
                      fontFamily: "Lato",fontSize:'12px',
                  }}
                  disabled={menuItem.disabled}
                   value={menuItem.name}>
                        <Stack direction="row" justifyContent="left"
  alignItems="center">
                  { menuItem.name == "None" && <ListItemIcon sx={{color: "red"}}>
            <DoNotDisturbIcon fontSize="small" />
          </ListItemIcon>} 
            {menuItem.name}</Stack>
                    </MenuItem>
                  );
                })}
          </Select> */}
                </TableCell>
              <TableCell sx={mainThemeStyle.cellRow}>
              {parseInt(row*8)+2}
              </TableCell>
              <TableCell align="right">
              <Autocomplete
       style={{marginTop: 0,minWidth: 150}}
        sx={{  "& .MuiAutocomplete-inputRoot": {
         color: "rgba(170, 170, 170,1)" },"& .MuiSvgIcon-root": {
           color: "rgba(170, 170, 170, 1)",
         },
         "& .Mui-disabled": {
          color: 'rgba(135, 135, 135, 0.5) ',
          "& .MuiSvgIcon-root": {
           color: "rgba(135, 135, 135, 0.5)"
          }
        },
        "& .MuiInputBase-root.Mui-disabled": {
          backgroundColor: 'rgba(135, 135, 135, 0.5)',
          color: 'white',
        },
        
        }}
       disablePortal
       disabled = {getDisableValue("184") == false ? (dataOfLocation.brivo_access.brivofloors < (parseInt(row*8)+2) ? true : false) : true}
       name = {parseInt(row*8)+1}
       value={options.find(option => option.value === getBrivoValue(parseInt(row*8)+1))}
     onChange={handleChangeBrivoRelay(parseInt(row*8)+1)}
     variant="filled"
     
     options={options}
     getOptionLabel={(option) => option.label}
     PaperComponent={({ children }) => (
       <Paper style={{ backgroundColor: "#1E1E1E" ,color:"#AFAFAF", "&:hover":{backgroundColor: "#fff"}}}>{children}</Paper>
     )}
     renderInput={(params) => <TextField {...params} 
      variant="outlined" size="small" style={{ backgroundColor: "#121212",borderRadius: '5px' ,color:"#fff",
   
       
   }} />}
   renderOption={(props, option) => (
     <MenuItem
       {...props}
       key={option.id}
       sx={{
         color: "#fff",
         backgroundColor: "#2F323A",
         borderBottom: '.5px solid grey',
         fontFamily: "Lato",
         fontSize: '12px',
       }}
       disabled={option.disabled}
     >
       <Stack direction="row" justifyContent="left" alignItems="center">
         {option.label === "None" && (
           <ListItemIcon sx={{ color: "red" }}>
             <DoNotDisturbIcon fontSize="small" />
           </ListItemIcon>
         )}
         {option.label}
       </Stack>
     </MenuItem>
   )}
     ></Autocomplete>
             {/*  <Select
            sx={{ color: 'rgba(135, 135, 135, 0.5)',
            height:'33px',
            marginTop:'4px',
            marginBottom:'3px',
            borderRadius: '5px',
            backgroundColor: '#121212',
            boxShadow: 'inset 0px 4px 4px rgba(0, 0, 0, 0.25)',
            padding: '5px 0 5px 0',
            "& .MuiSvgIcon-root": {
              color: "rgba(135, 135, 135, 0.5)",
            },
            
            '&.Mui-disabled': {
             

              background: 'rgba(135, 135, 135, 0.5)',
            },
            "& input.Mui-disabled": {
              color: "white"
            }
            }}
            name = {parseInt(row*8)+1}
            value={getBrivoValue(parseInt(row*8)+1)}
          onChange={handleChangeBrivoRelay(parseInt(row*8)+1)}
          variant="filled"
          disabled={getDisableValue("184") == false ? (dataOfLocation.brivo_access.brivofloors < (parseInt(row*8)+2) ? true : false) : true}
          hiddenLabel
          MenuProps={MenuProps}>
            {brivoDoorArray?.map(menuItem => {
                  
                  return (
                    <MenuItem  sx={
                      {
                      
                      color:"#fff",
                      backgroundColor: "#2F323A",
                      borderBottom: '.5px solid grey',
                      fontFamily: "Lato",fontSize:'12px',
                  }}
                  disabled={menuItem.disabled}
                   value={menuItem.name}>
                       <Stack direction="row" justifyContent="left"
  alignItems="center">
                  { menuItem.name == "None" && <ListItemIcon sx={{color: "red"}}>
            <DoNotDisturbIcon fontSize="small" />
          </ListItemIcon>} 
            {menuItem.name}</Stack>
                    </MenuItem>
                  );
                })}
          </Select> */}
                
                
               </TableCell>
      <TableCell sx={mainThemeStyle.cellRow}>
              {parseInt(row*8)+3}
              </TableCell>
              <TableCell align="right"> 
              <Autocomplete
       style={{marginTop: 0,minWidth: 150}}
        sx={{  "& .MuiAutocomplete-inputRoot": {
         color: "rgba(170, 170, 170,1)" },"& .MuiSvgIcon-root": {
           color: "rgba(170, 170, 170, 1)",
         },
         "& .Mui-disabled": {
          color: 'rgba(135, 135, 135, 0.5) ',
          "& .MuiSvgIcon-root": {
           color: "rgba(135, 135, 135, 0.5)"
          }
        },
        "& .MuiInputBase-root.Mui-disabled": {
          backgroundColor: 'rgba(135, 135, 135, 0.5)',
          color: 'white',
        },
        
        }}
       disablePortal
       disabled = {getDisableValue("184") == false ? (dataOfLocation.brivo_access.brivofloors < (parseInt(row*8)+3) ? true : false) : true}
       name = {parseInt(row*8)+2}
       value={options.find(option => option.value === getBrivoValue(parseInt(row*8)+2))}
     onChange={handleChangeBrivoRelay(parseInt(row*8)+2)}
     variant="filled"
     
     options={options}
     getOptionLabel={(option) => option.label}
     PaperComponent={({ children }) => (
       <Paper style={{ backgroundColor: "#1E1E1E" ,color:"#AFAFAF", "&:hover":{backgroundColor: "#fff"}}}>{children}</Paper>
     )}
     renderInput={(params) => <TextField {...params} 
      variant="outlined" size="small" style={{ backgroundColor: "#121212",borderRadius: '5px' ,color:"#fff",
   
       
   }} />}
   renderOption={(props, option) => (
     <MenuItem
       {...props}
       key={option.id}
       sx={{
         color: "#fff",
         backgroundColor: "#2F323A",
         borderBottom: '.5px solid grey',
         fontFamily: "Lato",
         fontSize: '12px',
       }}
       disabled={option.disabled}
     >
       <Stack direction="row" justifyContent="left" alignItems="center">
         {option.label === "None" && (
           <ListItemIcon sx={{ color: "red" }}>
             <DoNotDisturbIcon fontSize="small" />
           </ListItemIcon>
         )}
         {option.label}
       </Stack>
     </MenuItem>
   )}
     ></Autocomplete>
              {/* <Select
            sx={{ color: 'rgba(135, 135, 135, 0.5)',
            height:'33px',
            marginTop:'4px',
            marginBottom:'3px',
            borderRadius: '5px',
            backgroundColor: '#121212',
            boxShadow: 'inset 0px 4px 4px rgba(0, 0, 0, 0.25)',
            padding: '5px 0 5px 0',
            "& .MuiSvgIcon-root": {
              color: "rgba(135, 135, 135, 0.5)",
            },
            
            '&.Mui-disabled': {
             

              background: 'rgba(135, 135, 135, 0.5)',
            },
            "& input.Mui-disabled": {
              color: "white"
            }
            }}
            name = {parseInt(row*8)+2}
            value={getBrivoValue(parseInt(row*8)+2)}
          onChange={handleChangeBrivoRelay(parseInt(row*8)+2)}
          variant="filled"
          disabled={getDisableValue("184") == false ? (dataOfLocation.brivo_access.brivofloors < (parseInt(row*8)+3) ? true : false) : true}
          hiddenLabel
          MenuProps={MenuProps}>
            {brivoDoorArray?.map(menuItem => {
                  
                  return (
                    <MenuItem  sx={
                      {
                      
                      color:"#fff",
                      backgroundColor: "#2F323A",
                      borderBottom: '.5px solid grey',
                      fontFamily: "Lato",fontSize:'12px',
                  }}
                  disabled={menuItem.disabled}
                   value={menuItem.name}>
                       <Stack direction="row" justifyContent="left"
  alignItems="center">
                  { menuItem.name == "None" && <ListItemIcon sx={{color: "red"}}>
            <DoNotDisturbIcon fontSize="small" />
          </ListItemIcon>} 
            {menuItem.name}</Stack>
                    </MenuItem>
                  );
                })}
          </Select> */}
              
              </TableCell>
      <TableCell sx={mainThemeStyle.cellRow}>
              {(row*8)+4}
              </TableCell>
              <TableCell align="right"> 
              <Autocomplete
       style={{marginTop: 0,minWidth: 150}}
        sx={{  "& .MuiAutocomplete-inputRoot": {
         color: "rgba(170, 170, 170,1)" },"& .MuiSvgIcon-root": {
           color: "rgba(170, 170, 170, 1)",
         },
         "& .Mui-disabled": {
          color: 'rgba(135, 135, 135, 0.5) ',
          "& .MuiSvgIcon-root": {
           color: "rgba(135, 135, 135, 0.5)"
          }
        },
        "& .MuiInputBase-root.Mui-disabled": {
          backgroundColor: 'rgba(135, 135, 135, 0.5)',
          color: 'white',
        },
        
        }}
       disablePortal
       disabled = {getDisableValue("184") == false ? (dataOfLocation.brivo_access.brivofloors < (parseInt(row*8)+4) ? true : false) : true}
       name = {parseInt(row*8)+3}
       value={options.find(option => option.value === getBrivoValue(parseInt(row*8)+3))}
     onChange={handleChangeBrivoRelay(parseInt(row*8)+3)}
     variant="filled"
     
     options={options}
     getOptionLabel={(option) => option.label}
     PaperComponent={({ children }) => (
       <Paper style={{ backgroundColor: "#1E1E1E" ,color:"#AFAFAF", "&:hover":{backgroundColor: "#fff"}}}>{children}</Paper>
     )}
     renderInput={(params) => <TextField {...params} 
      variant="outlined" size="small" style={{ backgroundColor: "#121212",borderRadius: '5px' ,color:"#fff",
   
       
   }} />}
   renderOption={(props, option) => (
     <MenuItem
       {...props}
       key={option.id}
       sx={{
         color: "#fff",
         backgroundColor: "#2F323A",
         borderBottom: '.5px solid grey',
         fontFamily: "Lato",
         fontSize: '12px',
       }}
       disabled={option.disabled}
     >
       <Stack direction="row" justifyContent="left" alignItems="center">
         {option.label === "None" && (
           <ListItemIcon sx={{ color: "red" }}>
             <DoNotDisturbIcon fontSize="small" />
           </ListItemIcon>
         )}
         {option.label}
       </Stack>
     </MenuItem>
   )}
     ></Autocomplete>
             {/*  <Select
            sx={{ color: 'rgba(135, 135, 135, 0.5)',
            height:'33px',
            marginTop:'4px',
            marginBottom:'3px',
            borderRadius: '5px',
            backgroundColor: '#121212',
            boxShadow: 'inset 0px 4px 4px rgba(0, 0, 0, 0.25)',
            padding: '5px 0 5px 0',
            "& .MuiSvgIcon-root": {
              color: "rgba(135, 135, 135, 0.5)",
            },
            
            '&.Mui-disabled': {
             

              background: 'rgba(135, 135, 135, 0.5)',
            },
            "& input.Mui-disabled": {
              color: "white"
            }
            }}
            name = {(row*8)+3}
            value={getBrivoValue((row*8)+3)}
          onChange={handleChangeBrivoRelay(parseInt(row*8)+3)}
          variant="filled"
          disabled={getDisableValue("184") == false ? (dataOfLocation.brivo_access.brivofloors < (parseInt(row*8)+4) ? true : false) : true}
          hiddenLabel
          MenuProps={MenuProps}>
            {brivoDoorArray?.map(menuItem => {
                  
                  return (
                    <MenuItem  sx={
                      {
                      
                      color:"#fff",
                      backgroundColor: "#2F323A",
                      borderBottom: '.5px solid grey',
                      fontFamily: "Lato",fontSize:'12px',
                  }}
                  disabled={menuItem.disabled}
                   value={menuItem.name}>
                       <Stack direction="row" justifyContent="left"
  alignItems="center">
                  { menuItem.name == "None" && <ListItemIcon sx={{color: "red"}}>
            <DoNotDisturbIcon fontSize="small" />
          </ListItemIcon>} 
            {menuItem.name}</Stack>
                    </MenuItem>
                  );
                })}
          </Select> */}
              </TableCell>
      <TableCell sx={mainThemeStyle.cellRow}>
              {(row*8)+5}
              </TableCell>
              <TableCell align="right">
              <Autocomplete
       style={{marginTop: 0,minWidth: 150}}
        sx={{  "& .MuiAutocomplete-inputRoot": {
         color: "rgba(170, 170, 170,1)" },"& .MuiSvgIcon-root": {
           color: "rgba(170, 170, 170, 1)",
         },
         "& .Mui-disabled": {
          color: 'rgba(135, 135, 135, 0.5) ',
          "& .MuiSvgIcon-root": {
           color: "rgba(135, 135, 135, 0.5)"
          }
        },
        "& .MuiInputBase-root.Mui-disabled": {
          backgroundColor: 'rgba(135, 135, 135, 0.5)',
          color: 'white',
        },
        
        }}
       disablePortal
       disabled = {getDisableValue("184") == false ? (dataOfLocation.brivo_access.brivofloors < (parseInt(row*8)+5) ? true : false) : true}
       name = {parseInt(row*8)+4}
       value={options.find(option => option.value === getBrivoValue(parseInt(row*8)+4))}
     onChange={handleChangeBrivoRelay(parseInt(row*8)+4)}
     variant="filled"
     
     options={options}
     getOptionLabel={(option) => option.label}
     PaperComponent={({ children }) => (
       <Paper style={{ backgroundColor: "#1E1E1E" ,color:"#AFAFAF", "&:hover":{backgroundColor: "#fff"}}}>{children}</Paper>
     )}
     renderInput={(params) => <TextField {...params} 
      variant="outlined" size="small" style={{ backgroundColor: "#121212",borderRadius: '5px' ,color:"#fff",
   
       
   }} />}
   renderOption={(props, option) => (
     <MenuItem
       {...props}
       key={option.id}
       sx={{
         color: "#fff",
         backgroundColor: "#2F323A",
         borderBottom: '.5px solid grey',
         fontFamily: "Lato",
         fontSize: '12px',
       }}
       disabled={option.disabled}
     >
       <Stack direction="row" justifyContent="left" alignItems="center">
         {option.label === "None" && (
           <ListItemIcon sx={{ color: "red" }}>
             <DoNotDisturbIcon fontSize="small" />
           </ListItemIcon>
         )}
         {option.label}
       </Stack>
     </MenuItem>
   )}
     ></Autocomplete>
                      {/* <Select
            sx={{ color: 'rgba(135, 135, 135, 0.5)',
            height:'33px',
            marginTop:'4px',
            marginBottom:'3px',
            borderRadius: '5px',
            backgroundColor: '#121212',
            boxShadow: 'inset 0px 4px 4px rgba(0, 0, 0, 0.25)',
            padding: '5px 0 5px 0',
            "& .MuiSvgIcon-root": {
              color: "rgba(135, 135, 135, 0.5)",
            },
            
            '&.Mui-disabled': {
             

              background: 'rgba(135, 135, 135, 0.5)',
            },
            "& input.Mui-disabled": {
              color: "white"
            }
            }}
            name = {(row*8)+4}
            value={getBrivoValue(parseInt(row*8)+4)}
          onChange={handleChangeBrivoRelay(parseInt(row*8)+4)}
          variant="filled"
          disabled={getDisableValue("184") == false ? (dataOfLocation.brivo_access.brivofloors < (parseInt(row*8)+5) ? true : false) : true}
          hiddenLabel
          MenuProps={MenuProps}>
            {brivoDoorArray?.map(menuItem => {
                  
                  return (
                    <MenuItem  sx={
                      {
                      
                      color:"#fff",
                      backgroundColor: "#2F323A",
                      borderBottom: '.5px solid grey',
                      fontFamily: "Lato",fontSize:'12px',
                  }}
                  disabled={menuItem.disabled}
                   value={menuItem.name}>
                        <Stack direction="row" justifyContent="left"
  alignItems="center">
                  { menuItem.name == "None" && <ListItemIcon sx={{color: "red"}}>
            <DoNotDisturbIcon fontSize="small" />
          </ListItemIcon>} 
            {menuItem.name}</Stack>
                    </MenuItem>
                  );
                })}
          </Select>  */}
                </TableCell>
      <TableCell sx={mainThemeStyle.cellRow}>
              {(row*8)+6}
              </TableCell>
              <TableCell align="right"> 
              <Autocomplete
       style={{marginTop: 0,minWidth: 150}}
        sx={{  "& .MuiAutocomplete-inputRoot": {
         color: "rgba(170, 170, 170,1)" },"& .MuiSvgIcon-root": {
           color: "rgba(170, 170, 170, 1)",
         },
         "& .Mui-disabled": {
          color: 'rgba(135, 135, 135, 0.5) ',
          "& .MuiSvgIcon-root": {
           color: "rgba(135, 135, 135, 0.5)"
          }
        },
        "& .MuiInputBase-root.Mui-disabled": {
          backgroundColor: 'rgba(135, 135, 135, 0.5)',
          color: 'white',
        },
        
        }}
       disablePortal
       disabled = {getDisableValue("184") == false ? (dataOfLocation.brivo_access.brivofloors < (parseInt(row*8)+6) ? true : false) : true}
       name = {parseInt(row*8)+5}
       value={options.find(option => option.value === getBrivoValue(parseInt(row*8)+5))}
     onChange={handleChangeBrivoRelay(parseInt(row*8)+5)}
     variant="filled"
     
     options={options}
     getOptionLabel={(option) => option.label}
     PaperComponent={({ children }) => (
       <Paper style={{ backgroundColor: "#1E1E1E" ,color:"#AFAFAF", "&:hover":{backgroundColor: "#fff"}}}>{children}</Paper>
     )}
     renderInput={(params) => <TextField {...params} 
      variant="outlined" size="small" style={{ backgroundColor: "#121212",borderRadius: '5px' ,color:"#fff",
   
       
   }} />}
   renderOption={(props, option) => (
     <MenuItem
       {...props}
       key={option.id}
       sx={{
         color: "#fff",
         backgroundColor: "#2F323A",
         borderBottom: '.5px solid grey',
         fontFamily: "Lato",
         fontSize: '12px',
       }}
       disabled={option.disabled}
     >
       <Stack direction="row" justifyContent="left" alignItems="center">
         {option.label === "None" && (
           <ListItemIcon sx={{ color: "red" }}>
             <DoNotDisturbIcon fontSize="small" />
           </ListItemIcon>
         )}
         {option.label}
       </Stack>
     </MenuItem>
   )}
     ></Autocomplete>
             {/*  <Select
            sx={{ color: 'rgba(135, 135, 135, 0.5)',
            height:'33px',
            marginTop:'4px',
            marginBottom:'3px',
            borderRadius: '5px',
            backgroundColor: '#121212',
            boxShadow: 'inset 0px 4px 4px rgba(0, 0, 0, 0.25)',
            padding: '5px 0 5px 0',
            "& .MuiSvgIcon-root": {
              color: "rgba(135, 135, 135, 0.5)",
            },
            
            '&.Mui-disabled': {
             

              background: 'rgba(135, 135, 135, 0.5)',
            },
            "& input.Mui-disabled": {
              color: "white"
            }
            }}
            name = {(row*8)+5}
            value={getBrivoValue(parseInt(row*8)+5)}
          onChange={handleChangeBrivoRelay(parseInt(row*8)+5)}
          variant="filled"
          disabled={getDisableValue("184") == false ? (dataOfLocation.brivo_access.brivofloors < (parseInt(row*8)+6) ? true : false) : true}
          hiddenLabel
          MenuProps={MenuProps}>
            {brivoDoorArray?.map(menuItem => {
                  
                  return (
                    <MenuItem  sx={
                      {
                      
                      color:"#fff",
                      backgroundColor: "#2F323A",
                      borderBottom: '.5px solid grey',
                      fontFamily: "Lato",fontSize:'12px',
                  }}
                  disabled={menuItem.disabled}
                   value={menuItem.name}>
                        <Stack direction="row" justifyContent="left"
  alignItems="center">
                  { menuItem.name == "None" && <ListItemIcon sx={{color: "red"}}>
            <DoNotDisturbIcon fontSize="small" />
          </ListItemIcon>} 
            {menuItem.name}</Stack>
                    </MenuItem>
                  );
                })}
          </Select>  */}
              </TableCell>
      <TableCell sx={mainThemeStyle.cellRow}>
              {(row*8)+7}
              </TableCell>
              <TableCell align="right"> 
              <Autocomplete
       style={{marginTop: 0,minWidth: 150}}
        sx={{  "& .MuiAutocomplete-inputRoot": {
         color: "rgba(170, 170, 170,1)" },"& .MuiSvgIcon-root": {
           color: "rgba(170, 170, 170, 1)",
         },
         "& .Mui-disabled": {
          color: 'rgba(135, 135, 135, 0.5) ',
          "& .MuiSvgIcon-root": {
           color: "rgba(135, 135, 135, 0.5)"
          }
        },
        "& .MuiInputBase-root.Mui-disabled": {
          backgroundColor: 'rgba(135, 135, 135, 0.5)',
          color: 'white',
        },
        
        }}
       disablePortal
       disabled = {getDisableValue("184") == false ? (dataOfLocation.brivo_access.brivofloors < (parseInt(row*8)+7) ? true : false) : true}
       name = {parseInt(row*8)+6}
       value={options.find(option => option.value === getBrivoValue(parseInt(row*8)+6))}
     onChange={handleChangeBrivoRelay(parseInt(row*8)+6)}
     variant="filled"
     
     options={options}
     getOptionLabel={(option) => option.label}
     PaperComponent={({ children }) => (
       <Paper style={{ backgroundColor: "#1E1E1E" ,color:"#AFAFAF", "&:hover":{backgroundColor: "#fff"}}}>{children}</Paper>
     )}
     renderInput={(params) => <TextField {...params} 
      variant="outlined" size="small" style={{ backgroundColor: "#121212",borderRadius: '5px' ,color:"#fff",
   
       
   }} />}
   renderOption={(props, option) => (
     <MenuItem
       {...props}
       key={option.id}
       sx={{
         color: "#fff",
         backgroundColor: "#2F323A",
         borderBottom: '.5px solid grey',
         fontFamily: "Lato",
         fontSize: '12px',
       }}
       disabled={option.disabled}
     >
       <Stack direction="row" justifyContent="left" alignItems="center">
         {option.label === "None" && (
           <ListItemIcon sx={{ color: "red" }}>
             <DoNotDisturbIcon fontSize="small" />
           </ListItemIcon>
         )}
         {option.label}
       </Stack>
     </MenuItem>
   )}
     ></Autocomplete>
             {/*  <Select
            sx={{ color: 'rgba(135, 135, 135, 0.5)',
            height:'33px',
            marginTop:'4px',
            marginBottom:'3px',
            borderRadius: '5px',
            backgroundColor: '#121212',
            boxShadow: 'inset 0px 4px 4px rgba(0, 0, 0, 0.25)',
            padding: '5px 0 5px 0',
            "& .MuiSvgIcon-root": {
              color: "rgba(135, 135, 135, 0.5)",
            },
            
            '&.Mui-disabled': {
             

              background: 'rgba(135, 135, 135, 0.5)',
            },
            "& input.Mui-disabled": {
              color: "white"
            }
            }}
            name = {(row*8)+6}
            value={getBrivoValue(parseInt(row*8)+6)}
          onChange={handleChangeBrivoRelay(parseInt(row*8)+6)}
          variant="filled"
          disabled={getDisableValue("184") == false ? (dataOfLocation.brivo_access.brivofloors < (parseInt(row*8)+7) ? true : false) : true}
          hiddenLabel
          MenuProps={MenuProps}>
            {brivoDoorArray?.map(menuItem => {
                  
                  return (
                    <MenuItem  sx={
                      {
                      
                      color:"#fff",
                      backgroundColor: "#2F323A",
                      borderBottom: '.5px solid grey',
                      fontFamily: "Lato",fontSize:'12px',
                  }}
                  disabled={menuItem.disabled}
                   value={menuItem.name}>
                        <Stack direction="row" justifyContent="left"
  alignItems="center">
                  { menuItem.name == "None" && <ListItemIcon sx={{color: "red"}}>
            <DoNotDisturbIcon fontSize="small" />
          </ListItemIcon>} 
            {menuItem.name}</Stack>
                    </MenuItem>
                  );
                })}
          </Select>  */}
              </TableCell>
      <TableCell sx={mainThemeStyle.cellRow}>
              {(row*8)+8}
              </TableCell>
              <TableCell align="right"> 
              <Autocomplete
       style={{marginTop: 0,minWidth: 150}}
        sx={{  "& .MuiAutocomplete-inputRoot": {
         color: "rgba(170, 170, 170,1)" },"& .MuiSvgIcon-root": {
           color: "rgba(170, 170, 170, 1)",
         },
         "& .Mui-disabled": {
          color: 'rgba(135, 135, 135, 0.5) ',
          "& .MuiSvgIcon-root": {
           color: "rgba(135, 135, 135, 0.5)"
          }
        },
        "& .MuiInputBase-root.Mui-disabled": {
          backgroundColor: 'rgba(135, 135, 135, 0.5)',
          color: 'white',
        },
        
        }}
       disablePortal
       disabled = {getDisableValue("184") == false ? (dataOfLocation.brivo_access.brivofloors < (parseInt(row*8)+8) ? true : false) : true}
       name = {parseInt(row*8)+7}
       value={options.find(option => option.value === getBrivoValue(parseInt(row*8)+7))}
     onChange={handleChangeBrivoRelay(parseInt(row*8)+7)}
     variant="filled"
     
     options={options}
     getOptionLabel={(option) => option.label}
     PaperComponent={({ children }) => (
       <Paper style={{ backgroundColor: "#1E1E1E" ,color:"#AFAFAF", "&:hover":{backgroundColor: "#fff"}}}>{children}</Paper>
     )}
     renderInput={(params) => <TextField {...params} 
      variant="outlined" size="small" style={{ backgroundColor: "#121212",borderRadius: '5px' ,color:"#fff",
   
       
   }} />}
   renderOption={(props, option) => (
     <MenuItem
       {...props}
       key={option.id}
       sx={{
         color: "#fff",
         backgroundColor: "#2F323A",
         borderBottom: '.5px solid grey',
         fontFamily: "Lato",
         fontSize: '12px',
       }}
       disabled={option.disabled}
     >
       <Stack direction="row" justifyContent="left" alignItems="center">
         {option.label === "None" && (
           <ListItemIcon sx={{ color: "red" }}>
             <DoNotDisturbIcon fontSize="small" />
           </ListItemIcon>
         )}
         {option.label}
       </Stack>
     </MenuItem>
   )}
     ></Autocomplete>
              {/* <Select
            sx={{ color: 'rgba(135, 135, 135, 0.5)',
            height:'33px',
            marginTop:'4px',
            marginBottom:'3px',
            borderRadius: '5px',
            backgroundColor: '#121212',
            boxShadow: 'inset 0px 4px 4px rgba(0, 0, 0, 0.25)',
            padding: '5px 0 5px 0',
            "& .MuiSvgIcon-root": {
              color: "rgba(135, 135, 135, 0.5)",
            },
            
            '&.Mui-disabled': {
             

              background: 'rgba(135, 135, 135, 0.5)',
            },
            "& input.Mui-disabled": {
              color: "white"
            }
            }}
            name = {(row*8)+7}
            value={getBrivoValue(parseInt(row*8)+7)}
          onChange={handleChangeBrivoRelay(parseInt(row*8)+7)}
          variant="filled"
          disabled={getDisableValue("184") == false ? (dataOfLocation.brivo_access.brivofloors < (parseInt(row*8)+8) ? true : false) : true}
          hiddenLabel
          MenuProps={MenuProps}>
            {brivoDoorArray?.map(menuItem => {
                  
                  return (
                    <MenuItem  sx={
                      {
                      
                      color:"#fff",
                      backgroundColor: "#2F323A",
                      borderBottom: '.5px solid grey',
                      fontFamily: "Lato",fontSize:'12px',
                  }}
                  disabled={menuItem.disabled}
                   value={menuItem.name}>
                        <Stack direction="row" justifyContent="left"
  alignItems="center">
                  { menuItem.name == "None" && <ListItemIcon sx={{color: "red"}}>
            <DoNotDisturbIcon fontSize="small" />
          </ListItemIcon>} 
            {menuItem.name}</Stack>
                    </MenuItem>
                  );
                })}
          </Select> */} </TableCell>
            </TableRow>
          ))}
        </TableBody>
      </Table>
    </TableContainer>}

    {dataOfLocation.unlocking_mode != '1' && dataOfLocation.unlocking_mode != '4' && 
      <TableContainer component={Paper} sx={{
        width:'auto',
        height: 'auto',
        marginLeft: '38px',
        marginRight:'38px',
        marginBottom:'38px',
        backgroundColor:'#171E29',
       
        }}>
      <Table sx={{ minWidth: 650 }} >
        
        <TableHead sx={{ borderBottom: "1.5px solid black",
        }}>
          <TableRow>
            
            <TableCell align="center" sx={mainThemeStyle.popUpLable} colSpan={16}>Web Relay Programming  <Tooltip title="Add a letter 'P' to designate a Primary Relay, and add a numeral 1-64 to designate a Floor Relay">
      <IconButton  size="small" color="primary" >
        <InfoOutlinedIcon />
      </IconButton>
    </Tooltip></TableCell>
            
          </TableRow>
         
        </TableHead>

        
        <TableHead sx={{ borderBottom: "1.5px solid black",
        }}>
          <TableRow>
          <TableCell align="right" sx={mainThemeStyle.cellHeader}> Relay</TableCell>
            <TableCell align="right" sx={mainThemeStyle.cellHeader}>Floor</TableCell>
            <TableCell align="right" sx={mainThemeStyle.cellHeader}> Relay</TableCell>
            <TableCell align="right" sx={mainThemeStyle.cellHeader}>Floor</TableCell>
            <TableCell align="right" sx={mainThemeStyle.cellHeader}> Relay</TableCell>
            <TableCell align="right" sx={mainThemeStyle.cellHeader}>Floor</TableCell>
            <TableCell align="right" sx={mainThemeStyle.cellHeader}> Relay</TableCell>
            <TableCell align="right" sx={mainThemeStyle.cellHeader}>Floor</TableCell>
            <TableCell align="right" sx={mainThemeStyle.cellHeader}> Relay</TableCell>
            <TableCell align="right" sx={mainThemeStyle.cellHeader}>Floor</TableCell>
            <TableCell align="right" sx={mainThemeStyle.cellHeader}> Relay</TableCell>
            <TableCell align="right" sx={mainThemeStyle.cellHeader}>Floor</TableCell>
            <TableCell align="right" sx={mainThemeStyle.cellHeader}> Relay</TableCell>
            <TableCell align="right" sx={mainThemeStyle.cellHeader}>Floor</TableCell>
            <TableCell align="right" sx={mainThemeStyle.cellHeader}> Relay</TableCell>
            <TableCell align="right" sx={mainThemeStyle.cellHeader}>Floor</TableCell>
          </TableRow></TableHead>
        <TableBody>
         {rowsElevatorControl.map((row) => (
            <TableRow sx={{ borderBottom: "1.5px solid black",
          }}>
              <TableCell sx={mainThemeStyle.cellRow}>
              {parseInt(row*8)+1}
              </TableCell>
              <TableCell align="right"> <TextField
        id="text"
        type="text"
        value={dataOfLocation.elevator_access.program[parseInt(row*8)]}
        name={parseInt(row*8)}
        onChange={handleChangeEleInnerRelay}
       
        disabled={getDisableValue("183") == false ? (dataOfLocation.elevator_access.totalrelays < (parseInt(row*8)+1) ? true : false) : true}
                        InputLabelProps={{ shrink: true }
                      }
                      variant="filled"   
                        InputProps={{
                          inputProps: { 
                            maxLength:2,
                        },
                            sx: {
                                
                                color:'rgba(135, 135, 135, 0.5)',
                                height:'25px',
                                borderRadius: '5px',
                                backgroundColor: '#121212',
                                boxShadow: 'inset 0px 4px 4px rgba(0, 0, 0, 0.25)',
                                padding: '0 0 15px 0',
                                textIndent: '15px !important',
                                '&.Mui-disabled': {
             

                                  background: 'rgba(135, 135, 135, 0.5)',
                                },
                                
                                "& input.Mui-disabled": {
                                  color: "white"
                                }
                               
                                
                            },
                        }}
                       
      /></TableCell>
              <TableCell sx={mainThemeStyle.cellRow}>
              {parseInt(row*8)+2}
              </TableCell>
              <TableCell align="right"> <TextField
        id="text"
        type="text"
        value={dataOfLocation.elevator_access.program[(row*8)+1]}
        name={(row*8)+1}
        onChange={handleChangeEleInnerRelay}
        disabled={getDisableValue("183") == false ? (dataOfLocation.elevator_access.totalrelays < (parseInt(row*8)+2) ? true : false) : true}
        variant="filled"   
                        InputLabelProps={{ shrink: true }
                      }
                          
                        InputProps={{
                          inputProps: { 
                            maxLength:2,
                        },
                            sx: {
                                
                                color:'rgba(135, 135, 135, 0.5)',
                                height:'25px',
                                borderRadius: '5px',
                                backgroundColor: '#121212',
                                boxShadow: 'inset 0px 4px 4px rgba(0, 0, 0, 0.25)',
                                padding: '0 0 15px 0',
                                textIndent: '15px !important',
                              
                                '&.Mui-disabled': {
             

                                  background: 'rgba(135, 135, 135, 0.5)',
                                },
                                
                                "& input.Mui-disabled": {
                                  color: "white"
                                }
                                
                            },
                        }}
                       
      /></TableCell>
      <TableCell sx={mainThemeStyle.cellRow}>
              {parseInt(row*8)+3}
              </TableCell>
              <TableCell align="right"> <TextField
        id="text"
        type="text"
        variant="filled"   
        value={dataOfLocation.elevator_access.program[(row*8)+2]}
        name={(row*8)+2}
        onChange={handleChangeEleInnerRelay}
        disabled={getDisableValue("183") == false ? (dataOfLocation.elevator_access.totalrelays < (parseInt(row*8)+3) ? true : false) : true}
                        InputLabelProps={{ shrink: true }
                      }
                          
                        InputProps={{
                          inputProps: { 
                            maxLength:2,
                        },
                            sx: {
                                
                                color:'rgba(135, 135, 135, 0.5)',
                                height:'25px',
                                borderRadius: '5px',
                                backgroundColor: '#121212',
                                boxShadow: 'inset 0px 4px 4px rgba(0, 0, 0, 0.25)',
                                padding: '0 0 15px 0',
                                textIndent: '15px !important',
                              
                               
                                '&.Mui-disabled': {
             

                                  background: 'rgba(135, 135, 135, 0.5)',
                                },
                                
                                "& input.Mui-disabled": {
                                  color: "white"
                                }
                            },
                        }}
                       
      /></TableCell>
      <TableCell sx={mainThemeStyle.cellRow}>
              {(row*8)+4}
              </TableCell>
              <TableCell align="right"> <TextField
        id="text"
        type="text"
        variant="filled"   
        value={dataOfLocation.elevator_access.program[(row*8)+3]}
        name={(row*8)+3}
        onChange={handleChangeEleInnerRelay}                
        disabled={getDisableValue("183") == false ? (dataOfLocation.elevator_access.totalrelays < (parseInt(row*8)+4) ? true : false) : true}
        InputLabelProps={{ shrink: true }
                      }
                          
                        InputProps={{
                          inputProps: { 
                            maxLength:2,
                        },
                            sx: {
                                
                                color:'rgba(135, 135, 135, 0.5)',
                                height:'25px',
                                borderRadius: '5px',
                                backgroundColor: '#121212',
                                boxShadow: 'inset 0px 4px 4px rgba(0, 0, 0, 0.25)',
                                padding: '0 0 15px 0',
                                textIndent: '15px !important',
                              
                                '&.Mui-disabled': {
             

                                  background: 'rgba(135, 135, 135, 0.5)',
                                },
                                
                                "& input.Mui-disabled": {
                                  color: "white"
                                }
                                
                            },
                        }}
                       
      /></TableCell>
      <TableCell sx={mainThemeStyle.cellRow}>
              {(row*8)+5}
              </TableCell>
              <TableCell align="right"> <TextField
        id="text"
        type="text"
        variant="filled"   
        value={dataOfLocation.elevator_access.program[(row*8)+4]}
        name={(row*8)+4}
        disabled={getDisableValue("183") == false ? (dataOfLocation.elevator_access.totalrelays < (parseInt(row*8)+5) ? true : false) : true}
        onChange={handleChangeEleInnerRelay}   
                        InputLabelProps={{ shrink: true }
                      }
                          
                        InputProps={{
                          inputProps: { 
                            maxLength:2,
                        },
                            sx: {
                                
                                color:'rgba(135, 135, 135, 0.5)',
                                height:'25px',
                                borderRadius: '5px',
                                backgroundColor: '#121212',
                                boxShadow: 'inset 0px 4px 4px rgba(0, 0, 0, 0.25)',
                                padding: '0 0 15px 0',
                                textIndent: '15px !important',
                              
                                '&.Mui-disabled': {
             

                                  background: 'rgba(135, 135, 135, 0.5)',
                                },
                                
                                "& input.Mui-disabled": {
                                  color: "white"
                                }
                                
                            },
                        }}
                       
      /></TableCell>
      <TableCell sx={mainThemeStyle.cellRow}>
              {(row*8)+6}
              </TableCell>
              <TableCell align="right"> <TextField
        id="text"
        type="text"
        value={dataOfLocation.elevator_access.program[(row*8)+5]}
        name={(row*8)+5}
        disabled={getDisableValue("183") == false ? (dataOfLocation.elevator_access.totalrelays < (parseInt(row*8)+6) ? true : false) : true}
        onChange={handleChangeEleInnerRelay}   
        variant="filled" 
                        InputLabelProps={{ shrink: true }
                      }
                          
                        InputProps={{
                          inputProps: { 
                            maxLength:2,
                        },
                            sx: {
                                
                                color:'rgba(135, 135, 135, 0.5)',
                                height:'25px',
                                borderRadius: '5px',
                                backgroundColor: '#121212',
                                boxShadow: 'inset 0px 4px 4px rgba(0, 0, 0, 0.25)',
                                padding: '0 0 15px 0',
                                textIndent: '15px !important',
                                '&.Mui-disabled': {
             

                                  background: 'rgba(135, 135, 135, 0.5)',
                                },
                                
                                "& input.Mui-disabled": {
                                  color: "white"
                                }
                               
                                
                            },
                        }}
                       
      /></TableCell>
      <TableCell sx={mainThemeStyle.cellRow}>
              {(row*8)+7}
              </TableCell>
              <TableCell align="right"> <TextField
        id="text"
        type="text"
        variant="filled" 
        value={ dataOfLocation.elevator_access.program[(row*8)+6]}
        name={(row*8)+6}
        disabled={getDisableValue("183") == false ? (dataOfLocation.elevator_access.totalrelays < (parseInt(row*8)+7) ? true : false) : true}
        onChange={handleChangeEleInnerRelay}  
                        InputLabelProps={{ shrink: true }
                      }
                          
                        InputProps={{
                          inputProps: { 
                            maxLength:2,
                        },
                            sx: {
                                
                                color:'rgba(135, 135, 135, 0.5)',
                                height:'25px',
                                borderRadius: '5px',
                                backgroundColor: '#121212',
                                boxShadow: 'inset 0px 4px 4px rgba(0, 0, 0, 0.25)',
                                padding: '0 0 15px 0',
                                textIndent: '15px !important',
                                '&.Mui-disabled': {
             

                                  background: 'rgba(135, 135, 135, 0.5)',
                                },
                                
                                "& input.Mui-disabled": {
                                  color: "white"
                                }
                               
                                
                            },
                        }}
                       
      /></TableCell>
      <TableCell sx={mainThemeStyle.cellRow}>
              {(row*8)+8}
              </TableCell>
              <TableCell align="right"> <TextField
        id="text"
        type="text"
        variant="filled" 
        value={dataOfLocation.elevator_access.program[(row*8)+7]}
        name={(row*8)+7}
        onChange={handleChangeEleInnerRelay}  
        disabled={getDisableValue("183") == false ? (dataOfLocation.elevator_access.totalrelays < (parseInt(row*8)+8) ? true : false) : true}
                        InputLabelProps={{ shrink: true }
                      }
                          
                        InputProps={{
                          inputProps: { 
                            maxLength:2,
                        },
                         
                            sx: {
                                
                                color:'rgba(135, 135, 135, 0.5)',
                                height:'25px',
                                borderRadius: '5px',
                                backgroundColor: '#121212',
                                boxShadow: 'inset 0px 4px 4px rgba(0, 0, 0, 0.25)',
                                padding: '0 0 15px 0',
                                textIndent: '15px !important',
                                '&.Mui-disabled': {
             

                                  background: 'rgba(135, 135, 135, 0.5)',
                                },
                                
                                "& input.Mui-disabled": {
                                  color: "white"
                                }
                               
                                
                            },
                        }}
                       
      /></TableCell>
            </TableRow>
          ))}
        </TableBody>
      </Table>
    </TableContainer>}
      
      
      </Box>

</>)}
{/* {(currentTab == 3  &&  dataOfLocation.cam_brand == "Virtual Panel" && <>
<div  sx={{
       right: '54px' ,left: '54px',borderRadius:'0px'}}>
               <div style={{ display: 'flex' }}>
                   <Typography variant="h6" component="div" style={{  paddingLeft: '16px',paddingTop: '16px',flexGrow: 1,color:'#fff' }}>
                       Hold Open Settings
                   </Typography>
                   
               </div>
              
           </div>
           <Divider sx={{backgroundColor:'white'}} />
           <Box sx={{display: { xs: "none", md: "block" }}}>
      <Grid container spacing={2} padding= '32px'> 
      <Grid item xs={2.5}>
      <Stack direction="column"  spacing={1}>
      <Typography  variant="h6" component="div" style={{ flexGrow: 1,color:'#fff' }}>Enable Hold Open </Typography>
      </Stack>
      </Grid>
      <Grid item xs={2.5}>
      <Stack direction="column"  spacing={2}>
      <FormControlLabel  sx={mainThemeStyle.popUpLable}
        control={<IOSSwitch sx={{ m: 1 }}   onChange={handleChangeEventOfHoldOpen} disabled={getDisableValue("182")}  checked={dataOfLocation.hold_open.holdopen == "1" ? true : false }/>}
        
      />
      </Stack>
      </Grid>
      </Grid>
       {dataOfLocation.hold_open.holdopen == "1" && <Grid container spacing={2} padding= '32px'> 
      <Grid item xs={10}>
      <Stack direction="row"  spacing={4}>
      <Typography  sx={mainThemeStyle.popUpLable} height={40}>Indefinite Mode </Typography>
      
      <Checkbox sx={mainThemeStyle.formCheckBoxLow} name="indefinite" onChange={handleChangeValueOfHoldOpen} disabled={getDisableValue("182")} checked={dataOfLocation.hold_open.indefinite == "1" ? true : false}/>
      
      </Stack>
      </Grid>
      </Grid>}
      
      {dataOfLocation.hold_open.holdopen == "1" && dataOfLocation.hold_open.indefinite == "1" &&
      <Grid container spacing={2} padding= '32px'> 
      <Grid item xs={1}>
      <Stack direction="column"  spacing={2}>
      <Typography  sx={{fontFamily: 'Lato',
            fontWeight: '500',
            fontSize: '1rem',
            lineHeight: '1.37rem',
            color:'#fff',
            marginTop:'8px',
            }} height={40} >Relay 1 </Typography>
      <Typography  sx={mainThemeStyle.popUpLable} height={40}>Relay 2 </Typography>
      </Stack>
      </Grid>
      <Grid item xs={1}>
      <Stack direction="column"  spacing={2}>
      <Checkbox sx={mainThemeStyle.formCheckBox} name="relay1" disabled={getDisableValue("182")}  onChange={handleIndefiniteRelay} checked={dataOfLocation.hold_open.relay1 == "1" ? true : false }/>
      <Checkbox sx={mainThemeStyle.formCheckBox} name="relay2" disabled={getDisableValue("182")} onChange={handleIndefiniteRelay} checked={dataOfLocation.hold_open.relay2 == "1" ? true : false }/>
      </Stack>
      </Grid>
      </Grid>}
      
      

    
      </Box>

      <Box sx={{display: { xs: "block", md: "none" }}}>
      <Grid container spacing={2} padding= '10px' paddingBottom='32px' paddingTop='32px'> 
      <Grid item xs={8}>
      <Stack direction="column"  spacing={1}>
      <Typography  variant="h6" component="div" style={{ flexGrow: 1,color:'#fff' }}>Enable Hold Open </Typography>
      </Stack>
      </Grid>
      <Grid item xs={2}>
      <Stack direction="column"  spacing={2}>
      <FormControlLabel  sx={mainThemeStyle.popUpLable}
        control={<IOSSwitch sx={{ m: 1 }}   onChange={handleChangeEventOfHoldOpen} disabled={getDisableValue("182")}  checked={dataOfLocation.hold_open.holdopen == "1" ? true : false }/>}
        
      />
      </Stack>
      </Grid>
      </Grid>
       {dataOfLocation.hold_open.holdopen == "1" && <Grid container spacing={2} padding= '27px' paddingBottom='32px' paddingTop='32px'> 
      <Stack direction="column"  spacing={2}>
      <Stack direction="row"  spacing={2}>
      <Typography  sx={mainThemeStyle.popUpLable} height={40}>Indefinite Mode </Typography>
      
      <Checkbox sx={mainThemeStyle.formCheckBoxLow} name="indefinite" onChange={handleChangeValueOfHoldOpen} disabled={getDisableValue("182")} checked={dataOfLocation.hold_open.indefinite == "1" ? true : false}/>
      </Stack>
      
      </Stack>
      </Grid>}
      
      {dataOfLocation.hold_open.holdopen == "1" && dataOfLocation.hold_open.indefinite == "1" &&
      <Grid container spacing={2} padding= '10px' paddingBottom='32px' paddingTop='10px'> 
      <Grid item xs={7}>
      <Stack direction="column"  spacing={2}>
      <Typography  sx={mainThemeStyle.popUpLable} height={40}>Relay 1 </Typography>
      <Typography  sx={mainThemeStyle.popUpLable} height={40}>Relay 2 </Typography>
      </Stack>
      </Grid>
      <Grid item xs={5}>
      <Stack direction="column"  spacing={2}>
      <Checkbox sx={mainThemeStyle.formCheckBox} name="relay1" onChange={handleIndefiniteRelay} disabled={getDisableValue("182")} checked={dataOfLocation.hold_open.relay1 == "1" ? true : false }/>
      <Checkbox sx={mainThemeStyle.formCheckBox} name="relay2" onChange={handleIndefiniteRelay}  disabled={getDisableValue("182")} checked={dataOfLocation.hold_open.relay2 == "1" ? true : false }/>
      </Stack>
      </Grid>
      </Grid>}
      
      </Box>
</>)} */}

{(currentTab == 3 && dataOfLocation.cam_brand == "ResidentLink" && <>
<div  sx={{
       right: '54px' ,left: '54px',borderRadius:'0px'}}>
               <div style={{ display: 'flex' }}>
                   <Typography variant="h6" component="div" style={{  paddingLeft: '16px',paddingTop: '16px',flexGrow: 1,color:'#fff' }}>
                       LPR Settings
                   </Typography>
                   
               </div>
              
           </div>
           <Divider sx={{backgroundColor:'white'}} />
           <Box sx={{display: { xs: "none", md: "block" }}}>
           <Grid container spacing={2} padding= '32px'> 
      <Grid item xs={2}>
        <Stack direction="column"  spacing={2}>
      
      <Typography variant="h6" component="div" style={{ flexGrow: 1,color:'#fff' }}>LPR Device </Typography>
     {/*  <Typography sx={mainThemeStyle.popUpLable} height={40}>LPR Camera 1 Name </Typography>
      <Typography sx={mainThemeStyle.popUpLable} height={40}>LPR Camera 2 Name </Typography> */}
      
      </Stack>
      </Grid>
      
      <Grid item xs={2}>
      <Stack direction="column"  spacing={2}>
      
      
      <FormControlLabel  sx={mainThemeStyle.popUpLable}
        control={<IOSSwitch sx={{ m: 1 }} name="lpr_enable" onChange={handlecheckboxCheck}  disabled={getDisableValue("181")} checked={dataOfLocation.lpr_enable == "1" ? true : false } />}
        label=""
      />
            {/* <Checkbox sx={mainThemeStyle.formCheckBox} name="lpr_enable" onChange={handlecheckboxCheck} checked={dataOfLocation.lpr_enable == "1" ? true : false}/> */}
           {/*  <TextField
                        id="input-lpr-camera1"
                        height={40}
                        type= 'text'
                        name="lpr_cam1"
                        value={dataOfLocation.lpr_cam1}
                        onChange={handleInputChange}
                        sx={mainThemeStyle.InputMenuItem}
                        InputLabelProps={{ shrink: true }
                      }
                      variant="filled"
                        InputProps={{
                           
                            sx: {
                                
                                color:'rgba(135, 135, 135, 0.5)',
                                height:'33px',
                                borderRadius: '5px',
                                backgroundColor: '#121212',
                                boxShadow: 'inset 0px 4px 4px rgba(0, 0, 0, 0.25)',
                                padding: '0 0 15px 0',
                                textIndent: '15px !important',
                              
                               
                                
                            },
                        }}
                       
                    />
                    <TextField
                        id="input-location"
                        height={40}
                        type= 'text'
                        name="lpr_cam2"
                        value={dataOfLocation.lpr_cam2}
                        onChange={handleInputChange}
                        sx={mainThemeStyle.InputMenuItem}
                        InputLabelProps={{ shrink: true }
                      }
                      variant="filled"
                        InputProps={{
                           
                            sx: {
                                
                                color:'rgba(135, 135, 135, 0.5)',
                                height:'33px',
                                borderRadius: '5px',
                                backgroundColor: '#121212',
                                boxShadow: 'inset 0px 4px 4px rgba(0, 0, 0, 0.25)',
                                padding: '0 0 15px 0',
                                textIndent: '15px !important',
                              
                               
                                
                            },
                        }}
                       
                    /> */}
     
          
      </Stack>
      </Grid>
     
      
      
      </Grid>
      
      
      </Box>
      {dataOfLocation.lpr_enable == "1" && <Box sx={{display: { xs: "none", md: "block" }}}>
        <Grid container spacing={2} padding= '32px'> 
        <Grid item xs={0.3}></Grid>
      <Grid item xs={5.2}>
        <Stack direction="row"  spacing={2}>
      <Typography sx={mainThemeStyle.popUpLable} height={40} > Add Bridge </Typography>
      
      <Select
      sx={{ color: 'rgba(135, 135, 135, 0.5)',
      height:'33px',
      marginTop:'4px',
      marginBottom:'3px',
      borderRadius: '5px',
      backgroundColor: '#121212',
      boxShadow: 'inset 0px 4px 4px rgba(0, 0, 0, 0.25)',
      padding: '5px 0 5px 0',
      "& .MuiSvgIcon-root": {
        color: "rgba(135, 135, 135, 0.5)",
      },
      
      '&.Mui-disabled': {
        

        background: 'rgba(135, 135, 135, 0.5)',
      },
      "& input.Mui-disabled": {
        color: "white"
      }
      }}
      name = "bridge_select"
      disabled={getDisableValue("181")}
      value={currentBridge}
      label="Age"
      onChange={handleChangeBridge}
      variant="filled"
      hiddenLabel
      MenuProps={MenuProps}>
      {addBridge?.map(menuItem => {
            
            return (
              <MenuItem  sx={
                {
                
                color:"#fff",
                backgroundColor: "#2F323A",
                borderBottom: '.5px solid grey',
                fontFamily: "Lato",fontSize:'12px',
            }}
              value={menuItem.name}>
                {menuItem.name}
              </MenuItem>
            );
          })}
      </Select>
      <Select
      sx={{ color: 'rgba(135, 135, 135, 0.5)',
      height:'33px',
      marginTop:'4px',
      marginBottom:'3px',
      borderRadius: '5px',
      backgroundColor: '#121212',
      boxShadow: 'inset 0px 4px 4px rgba(0, 0, 0, 0.25)',
      padding: '5px 0 5px 0',
      "& .MuiSvgIcon-root": {
        color: "rgba(135, 135, 135, 0.5)",
      },
      
      '&.Mui-disabled': {
        

        background: 'rgba(135, 135, 135, 0.5)',
      },
      "& input.Mui-disabled": {
        color: "white"
      }
      }}
      name = "bridge_select"
      disabled={getDisableValue("181")}
      value={currentBridgeType}
      label="Age"
      onChange={handleChangeBridgeType}
      variant="filled"
      hiddenLabel
      MenuProps={MenuProps}>
      {BriType.items.map(menuItem => {
            
            return (
              <MenuItem  sx={
                {
                
                color:"#fff",
                backgroundColor: "#2F323A",
                borderBottom: '.5px solid grey',
                fontFamily: "Lato",fontSize:'12px',
            }}
              value={menuItem.label}>
                {menuItem.label}
              </MenuItem>
            );
          })}
      </Select>
      <Tooltip title= {'A "Dedicated" bridge can only be assigned to a single LPR Device.  If the account has multiple LPR devices utilizing this same bridge, please select "Shared".'}>
      <IconButton  size="small" color="primary" >
        <InfoOutlinedIcon />
      </IconButton>
    </Tooltip>
      <LoadingButton sx={mainThemeStyle.normalButton}  loading={loading} onClick={handleAddBridge}  disabled = {getDisableValue("181") == false ? (recordsBridge.length > 0 ? true : false): true}>Add</LoadingButton>
      </Stack>
      </Grid>
      {/* </Stack>
      </Grid> */}
      <Grid item xs={1.7}>
    
      </Grid>
      <Grid item xs={4.8}>
        <Stack direction="row"  spacing={2}>
      <Typography sx={mainThemeStyle.popUpLable} height={40} > Add Camera </Typography>
      <Select
      sx={{ color: 'rgba(135, 135, 135, 0.5)',
      height:'33px',
      marginTop:'4px',
      marginBottom:'3px',
      borderRadius: '5px',
      backgroundColor: '#121212',
      boxShadow: 'inset 0px 4px 4px rgba(0, 0, 0, 0.25)',
      padding: '5px 0 5px 0',
      "& .MuiSvgIcon-root": {
        color: "rgba(135, 135, 135, 0.5)",
      },
      
      '&.Mui-disabled': {
        

        background: 'rgba(135, 135, 135, 0.5)',
      },
      "& input.Mui-disabled": {
        color: "white"
      }
      }}
      name = "bridge_select"
      disabled={getDisableValue("181")}
      value={currentCamera}
      label="Age"
      onChange={handleChangeCamera}
      variant="filled"
      hiddenLabel
      MenuProps={MenuProps}>
      {addCamera?.map(menuItem => {
            
            return (
              <MenuItem  sx={
                {
                
                color:"#fff",
                backgroundColor: "#2F323A",
                borderBottom: '.5px solid grey',
                fontFamily: "Lato",fontSize:'12px',
            }}
              value={menuItem.name}>
                {menuItem.name}
              </MenuItem>
            );
          })}
      </Select>
      <Select
      sx={{ color: 'rgba(135, 135, 135, 0.5)',
      height:'33px',
      marginTop:'4px',
      marginBottom:'3px',
      borderRadius: '5px',
      backgroundColor: '#121212',
      boxShadow: 'inset 0px 4px 4px rgba(0, 0, 0, 0.25)',
      padding: '5px 0 5px 0',
      "& .MuiSvgIcon-root": {
        color: "rgba(135, 135, 135, 0.5)",
      },
      
      '&.Mui-disabled': {
        

        background: 'rgba(135, 135, 135, 0.5)',
      },
      "& input.Mui-disabled": {
        color: "white"
      }
      }}
      name = "bridge_select"
      disabled={getDisableValue("181")}
      value={currentCameraType}
      label="Age"
      onChange={handleChangeCameraType}
      variant="filled"
      hiddenLabel
      MenuProps={MenuProps}>
      {CamType.items.map(menuItem => {
            
            return (
              <MenuItem  sx={
                {
                
                color:"#fff",
                backgroundColor: "#2F323A",
                borderBottom: '.5px solid grey',
                fontFamily: "Lato",fontSize:'12px',
            }}
              value={menuItem.label}>
                {menuItem.label}
              </MenuItem>
            );
          })}
      </Select>
             <LoadingButton sx={mainThemeStyle.normalButton} onClick={handleAddCamera}  disabled={getDisableValue("181")} loading={loading}>Add</LoadingButton>
          
      </Stack>
      </Grid>
      
      
      </Grid>
     
      <Grid container  padding= '32px'>
       
       <Grid item xs={5}>
       <TblContainerBridge>
                     <TblHeadBridge />
         <TableBody>
          {recordsAfterPagingAndSortingBridge().map((row) => (
             <TableRow sx={{ borderBottom: "1.5px solid black",
           }}>
               
               <TableCell align="left"  sx={mainThemeStyle.cellRow}> {row.name}</TableCell>
              <TableCell align="left"  sx={mainThemeStyle.cellRow}> {row.type == "0" ? "Dedicated" :"Shared"} </TableCell>
              
               <TableCell align="left">
              
                 <LoadingButton loading={loading}  sx={mainThemeStyle.viewLogButtonRed}  disabled={getDisableValue("181")} onClick={ () => handleBridgeDelete(row.id,row.name)}>Delete</LoadingButton>
                 
                 </TableCell>
             </TableRow>
           ))}
         </TableBody>
       
     </TblContainerBridge>
     
     </Grid>
     <Grid item xs={2}>
     
     </Grid>
     <Grid item xs={5}>
     <TblContainerCamera>
                     <TblHeadCamera />
         <TableBody>
          {recordsAfterPagingAndSortingCamera().map((row) => (
             <TableRow sx={{ borderBottom: "1.5px solid black",
           }}>
               
               <TableCell align="left"  sx={mainThemeStyle.cellRow}> {row.name}</TableCell>
              <TableCell align="left"  sx={mainThemeStyle.cellRow}> {row.type == "0" ? "Entry" : "Exit"} </TableCell>
              
             <TableCell align="left">
                <LoadingButton loading={loading}  sx={mainThemeStyle.viewLogButtonRed}  disabled={getDisableValue("181")} onClick={ () => handleCameraDelete(row.id,row.name)}>Delete</LoadingButton>
                    </TableCell>
             </TableRow>
           ))}
         </TableBody>
       
     </TblContainerCamera>
    
     </Grid>
       </Grid>
       
     
       <Grid container spacing={3}>
       <Grid item xs={5}>
       {/* <BelowTblPaginationBridge></BelowTblPaginationBridge> */}</Grid>
       <Grid item xs={1}>
       </Grid>
       <Grid item xs={6}>
       <BelowTblPaginationCamera></BelowTblPaginationCamera></Grid></Grid>
      
      
      
      
      </Box>}
      
      <Box sx={{display: { xs: "block", md: "none" }}}>
      <Grid container spacing={2} padding= '10px' paddingBottom='32px' paddingTop='32px'> 
      <Grid item xs={5}>
        <Stack direction="column"  spacing={2}>
      
      <Typography  sx={mainThemeStyle.popUpLable} height={40}>LPR Device </Typography>
      {/* <Typography sx={mainThemeStyle.popUpLable} height={40}>LPR Camera 1 Name </Typography>
      <Typography sx={mainThemeStyle.popUpLable} height={40}>LPR Camera 2 Name </Typography> */}
      
      </Stack>
      </Grid>
      
      <Grid item xs={7}>
      <Stack direction="column"  spacing={2}>
      
      
      <FormControlLabel  sx={mainThemeStyle.popUpLable}
        control={<IOSSwitch sx={{ m: 1 }} name="lpr_enable" onChange={handlecheckboxCheck}  disabled={getDisableValue("181")} checked={dataOfLocation.lpr_enable == "1" ? true : false } />}
        label=""
      />
            {/* <Checkbox sx={mainThemeStyle.formCheckBox} name="lpr_enable" onChange={handlecheckboxCheck} checked={dataOfLocation.lpr_enable == "1" ? true : false}/> */}
           {/*  <TextField
                        id="input-lpr-camera1"
                        height={40}
                        type= 'text'
                        name="lpr_cam1"
                        value={dataOfLocation.lpr_cam1}
                        onChange={handleInputChange}
                        sx={mainThemeStyle.InputMenuItem}
                        InputLabelProps={{ shrink: true }
                      }
                      variant="filled"
                        InputProps={{
                           
                            sx: {
                                
                                color:'rgba(135, 135, 135, 0.5)',
                                height:'33px',
                                borderRadius: '5px',
                                backgroundColor: '#121212',
                                boxShadow: 'inset 0px 4px 4px rgba(0, 0, 0, 0.25)',
                                padding: '0 0 15px 0',
                                textIndent: '15px !important',
                              
                               
                                
                            },
                        }}
                       
                    />
                    <TextField
                        id="input-location"
                        height={40}
                        type= 'text'
                        name="lpr_cam2"
                        value={dataOfLocation.lpr_cam2}
                        onChange={handleInputChange}
                        sx={mainThemeStyle.InputMenuItem}
                        InputLabelProps={{ shrink: true }
                      }
                      variant="filled"
                        InputProps={{
                           
                            sx: {
                                
                                color:'rgba(135, 135, 135, 0.5)',
                                height:'33px',
                                borderRadius: '5px',
                                backgroundColor: '#121212',
                                boxShadow: 'inset 0px 4px 4px rgba(0, 0, 0, 0.25)',
                                padding: '0 0 15px 0',
                                textIndent: '15px !important',
                              
                               
                                
                            },
                        }}
                       
                    /> */}
     
          
      </Stack>
      </Grid>
     
      
      
      </Grid>
      {dataOfLocation.lpr_enable == "1" &&<Box sx={{display: { xs: "block", md: "none" }}}>
      <Grid container spacing={2} padding= '40px' paddingBottom='32px' paddingTop='32px'> 
      <Grid item xs={12}>
        <Stack direction="column"  spacing={2} >
      <Typography sx={mainThemeStyle.popUpLable} height={40}> Add Bridge </Typography>
      
      </Stack>
      </Grid>
      
      <Grid item xs={12}>
      <Stack direction="column"  spacing={2}>
      <Stack direction="row"  spacing={2}>
      <Select
      sx={{ color: 'rgba(135, 135, 135, 0.5)',
      height:'33px',
      marginTop:'4px',
      marginBottom:'3px',
      borderRadius: '5px',
      backgroundColor: '#121212',
      boxShadow: 'inset 0px 4px 4px rgba(0, 0, 0, 0.25)',
      padding: '5px 0 5px 0',
      "& .MuiSvgIcon-root": {
        color: "rgba(135, 135, 135, 0.5)",
      },
      
      '&.Mui-disabled': {
        

        background: 'rgba(135, 135, 135, 0.5)',
      },
      "& input.Mui-disabled": {
        color: "white"
      }
      }}
      name = "bridge_select"
      disabled={getDisableValue("181")}
      value={currentBridge}
      label="Age"
      onChange={handleChangeBridge}
      variant="filled"
      hiddenLabel
      MenuProps={MenuProps}>
      {addBridge?.map(menuItem => {
            
            return (
              <MenuItem  sx={
                {
                
                color:"#fff",
                backgroundColor: "#2F323A",
                borderBottom: '.5px solid grey',
                fontFamily: "Lato",fontSize:'12px',
            }}
              value={menuItem.name}>
                {menuItem.name}
              </MenuItem>
            );
          })}
      </Select>
      <Select
      sx={{ color: 'rgba(135, 135, 135, 0.5)',
      height:'33px',
      marginTop:'4px',
      marginBottom:'3px',
      borderRadius: '5px',
      backgroundColor: '#121212',
      boxShadow: 'inset 0px 4px 4px rgba(0, 0, 0, 0.25)',
      padding: '5px 0 5px 0',
      "& .MuiSvgIcon-root": {
        color: "rgba(135, 135, 135, 0.5)",
      },
      
      '&.Mui-disabled': {
        

        background: 'rgba(135, 135, 135, 0.5)',
      },
      "& input.Mui-disabled": {
        color: "white"
      }
      }}
      name = "bridge_select"
      disabled={getDisableValue("181")}
      value={currentBridgeType}
      label="Age"
      onChange={handleChangeBridgeType}
      variant="filled"
      hiddenLabel
      MenuProps={MenuProps}>
      {BriType.items.map(menuItem => {
            
            return (
              <MenuItem  sx={
                {
                
                color:"#fff",
                backgroundColor: "#2F323A",
                borderBottom: '.5px solid grey',
                fontFamily: "Lato",fontSize:'12px',
            }}
              value={menuItem.label}>
                {menuItem.label}
              </MenuItem>
            );
          })}
      </Select>
      <LoadingButton sx={mainThemeStyle.normalButton}  loading={loading} onClick={handleAddBridge}   disabled = { getDisableValue("181") == false ? (recordsBridge.length > 0 ? true : false): true}>Add</LoadingButton>
     </Stack>
      </Stack>
      </Grid>

      
     
      
      
      </Grid>
      
      
      
      <TblContainerBridge>
                     <TblHeadBridge />
         <TableBody>
          {recordsAfterPagingAndSortingBridge().map((row) => (
             <TableRow sx={{ borderBottom: "1.5px solid black",
           }}>
               
               <TableCell align="left"  sx={mainThemeStyle.cellRow}> {row.name}</TableCell>
              <TableCell align="left"  sx={mainThemeStyle.cellRow}> {row.type == "0" ? "Dedicated" :"Shared"} </TableCell>
              
               <TableCell align="left">
              
                 <LoadingButton loading={loading}  sx={mainThemeStyle.viewLogButtonRed} disabled={getDisableValue("181")}  onClick={ () => handleBridgeDelete(row.id,row.name)}>Delete</LoadingButton>
                 
                 </TableCell>
             </TableRow>
           ))}
         </TableBody>
       
     </TblContainerBridge>
    
    
    
    <Grid container spacing={2} padding= '40px' paddingBottom='32px' paddingTop='100px' > 
    <Grid item xs={12} >
        <Stack direction="column"  spacing={2}>
      <Typography sx={mainThemeStyle.popUpLable}> Add Camera </Typography>
      </Stack>
      </Grid>
      <Grid item xs={12}>
        <Stack direction="column"  spacing={2}>
          <Stack direction="row" spacing={2}>
          <Select
      sx={{ color: 'rgba(135, 135, 135, 0.5)',
      height:'33px',
      marginTop:'4px',
      marginBottom:'3px',
      borderRadius: '5px',
      backgroundColor: '#121212',
      boxShadow: 'inset 0px 4px 4px rgba(0, 0, 0, 0.25)',
      padding: '5px 0 5px 0',
      "& .MuiSvgIcon-root": {
        color: "rgba(135, 135, 135, 0.5)",
      },
      
      '&.Mui-disabled': {
        

        background: 'rgba(135, 135, 135, 0.5)',
      },
      "& input.Mui-disabled": {
        color: "white"
      }
      }}
      name = "bridge_select"
      disabled={getDisableValue("181")} 
      value={currentCamera}
      label="Age"
      onChange={handleChangeCamera}
      variant="filled"
      hiddenLabel
      MenuProps={MenuProps}>
      {addCamera?.map(menuItem => {
            
            return (
              <MenuItem  sx={
                {
                
                color:"#fff",
                backgroundColor: "#2F323A",
                borderBottom: '.5px solid grey',
                fontFamily: "Lato",fontSize:'12px',
            }}
              value={menuItem.name}>
                {menuItem.name}
              </MenuItem>
            );
          })}
      </Select>
      <Select
      sx={{ color: 'rgba(135, 135, 135, 0.5)',
      height:'33px',
      marginTop:'4px',
      marginBottom:'3px',
      borderRadius: '5px',
      backgroundColor: '#121212',
      boxShadow: 'inset 0px 4px 4px rgba(0, 0, 0, 0.25)',
      padding: '5px 0 5px 0',
      "& .MuiSvgIcon-root": {
        color: "rgba(135, 135, 135, 0.5)",
      },
      
      '&.Mui-disabled': {
        

        background: 'rgba(135, 135, 135, 0.5)',
      },
      "& input.Mui-disabled": {
        color: "white"
      }
      }}
      name = "bridge_select"
      disabled={getDisableValue("181")} 
      value={currentCameraType}
      label="Age"
      onChange={handleChangeCameraType}
      variant="filled"
      hiddenLabel
      MenuProps={MenuProps}>
      {CamType.items.map(menuItem => {
            
            return (
              <MenuItem  sx={
                {
                
                color:"#fff",
                backgroundColor: "#2F323A",
                borderBottom: '.5px solid grey',
                fontFamily: "Lato",fontSize:'12px',
            }}
              value={menuItem.label}>
                {menuItem.label}
              </MenuItem>
            );
          })}
      </Select>
             <LoadingButton sx={mainThemeStyle.normalButton} onClick={handleAddCamera} disabled={getDisableValue("181")}  loading={loading}>Add</LoadingButton>
          </Stack>
      </Stack>
      </Grid>
      
     
      
      
      </Grid>
      
      <TblContainerCamera>
                     <TblHeadCamera />
         <TableBody>
          {recordsAfterPagingAndSortingCamera().map((row) => (
             <TableRow sx={{ borderBottom: "1.5px solid black",
           }}>
               
               <TableCell align="left"  sx={mainThemeStyle.cellRow}> {row.name}</TableCell>
              <TableCell align="left"  sx={mainThemeStyle.cellRow}> {row.type == "0" ? "Entry" : "Exit"} </TableCell>
              
             <TableCell align="left">
                <LoadingButton loading={loading}  sx={mainThemeStyle.viewLogButtonRed} disabled={getDisableValue("181")}  onClick={ () => handleCameraDelete(row.id,row.name)}>Delete</LoadingButton>
                    </TableCell>
             </TableRow>
           ))}
         </TableBody>
       
     </TblContainerCamera>
    
    <BelowTblPaginationCamera></BelowTblPaginationCamera>
      </Box>}
      </Box>

</>)}
{(currentTab == 4 && dataOfLocation.cam_brand == "Virtual Panel" && <>
<div  sx={{
       right: '54px' ,left: '54px',borderRadius:'0px'}}>
               <div style={{ display: 'flex' }}>
                   <Typography variant="h6" component="div" style={{  paddingLeft: '16px',paddingTop: '16px',flexGrow: 1,color:'#fff' }}>
                       LPR Settings
                   </Typography>
                   
               </div>
              
           </div>
           <Divider sx={{backgroundColor:'white'}} />
           <Box sx={{display: { xs: "none", md: "block" }}}>
           <Grid container spacing={2} padding= '32px'> 
      <Grid item xs={2}>
        <Stack direction="column"  spacing={2}>
      
      <Typography variant="h6" component="div" style={{ flexGrow: 1,color:'#fff' }}>LPR Device </Typography>
     {/*  <Typography sx={mainThemeStyle.popUpLable} height={40}>LPR Camera 1 Name </Typography>
      <Typography sx={mainThemeStyle.popUpLable} height={40}>LPR Camera 2 Name </Typography> */}
      
      </Stack>
      </Grid>
      
      <Grid item xs={2}>
      <Stack direction="column"  spacing={2}>
      
      
      <FormControlLabel  sx={mainThemeStyle.popUpLable}
        control={<IOSSwitch sx={{ m: 1 }} name="lpr_enable" onChange={handlecheckboxCheck} disabled={getDisableValue("181")}  checked={dataOfLocation.lpr_enable == "1" ? true : false } />}
        label=""
      />
            {/* <Checkbox sx={mainThemeStyle.formCheckBox} name="lpr_enable" onChange={handlecheckboxCheck} checked={dataOfLocation.lpr_enable == "1" ? true : false}/> */}
           {/*  <TextField
                        id="input-lpr-camera1"
                        height={40}
                        type= 'text'
                        name="lpr_cam1"
                        value={dataOfLocation.lpr_cam1}
                        onChange={handleInputChange}
                        sx={mainThemeStyle.InputMenuItem}
                        InputLabelProps={{ shrink: true }
                      }
                      variant="filled"
                        InputProps={{
                           
                            sx: {
                                
                                color:'rgba(135, 135, 135, 0.5)',
                                height:'33px',
                                borderRadius: '5px',
                                backgroundColor: '#121212',
                                boxShadow: 'inset 0px 4px 4px rgba(0, 0, 0, 0.25)',
                                padding: '0 0 15px 0',
                                textIndent: '15px !important',
                              
                               
                                
                            },
                        }}
                       
                    />
                    <TextField
                        id="input-location"
                        height={40}
                        type= 'text'
                        name="lpr_cam2"
                        value={dataOfLocation.lpr_cam2}
                        onChange={handleInputChange}
                        sx={mainThemeStyle.InputMenuItem}
                        InputLabelProps={{ shrink: true }
                      }
                      variant="filled"
                        InputProps={{
                           
                            sx: {
                                
                                color:'rgba(135, 135, 135, 0.5)',
                                height:'33px',
                                borderRadius: '5px',
                                backgroundColor: '#121212',
                                boxShadow: 'inset 0px 4px 4px rgba(0, 0, 0, 0.25)',
                                padding: '0 0 15px 0',
                                textIndent: '15px !important',
                              
                               
                                
                            },
                        }}
                       
                    /> */}
     
          
      </Stack>
      </Grid>
     
      
      
      </Grid>
      
      
      </Box>
      {dataOfLocation.lpr_enable == "1" && <Box sx={{display: { xs: "none", md: "block" }}}>
        <Grid container spacing={2} padding= '32px'> 
        <Grid item xs={0.3}></Grid>
      <Grid item xs={5.2}>
        <Stack direction="row"  spacing={2}>
      <Typography sx={mainThemeStyle.popUpLable} height={40} > Add Bridge </Typography>
      
      <Select
      sx={{ color: 'rgba(135, 135, 135, 0.5)',
      height:'33px',
      marginTop:'4px',
      marginBottom:'3px',
      borderRadius: '5px',
      backgroundColor: '#121212',
      boxShadow: 'inset 0px 4px 4px rgba(0, 0, 0, 0.25)',
      padding: '5px 0 5px 0',
      "& .MuiSvgIcon-root": {
        color: "rgba(135, 135, 135, 0.5)",
      },
      
      '&.Mui-disabled': {
        

        background: 'rgba(135, 135, 135, 0.5)',
      },
      "& input.Mui-disabled": {
        color: "white"
      }
      }}
      name = "bridge_select"
      disabled={getDisableValue("181")} 
      value={currentBridge}
      label="Age"
      onChange={handleChangeBridge}
      variant="filled"
      hiddenLabel
      MenuProps={MenuProps}>
      {addBridge?.map(menuItem => {
            
            return (
              <MenuItem  sx={
                {
                
                color:"#fff",
                backgroundColor: "#2F323A",
                borderBottom: '.5px solid grey',
                fontFamily: "Lato",fontSize:'12px',
            }}
              value={menuItem.name}>
                {menuItem.name}
              </MenuItem>
            );
          })}
      </Select>
      <Select
      sx={{ color: 'rgba(135, 135, 135, 0.5)',
      height:'33px',
      marginTop:'4px',
      marginBottom:'3px',
      borderRadius: '5px',
      backgroundColor: '#121212',
      boxShadow: 'inset 0px 4px 4px rgba(0, 0, 0, 0.25)',
      padding: '5px 0 5px 0',
      "& .MuiSvgIcon-root": {
        color: "rgba(135, 135, 135, 0.5)",
      },
      
      '&.Mui-disabled': {
        

        background: 'rgba(135, 135, 135, 0.5)',
      },
      "& input.Mui-disabled": {
        color: "white"
      }
      }}
      name = "bridge_select"
      disabled={getDisableValue("181")} 
      value={currentBridgeType}
      label="Age"
      onChange={handleChangeBridgeType}
      variant="filled"
      hiddenLabel
      MenuProps={MenuProps}>
      {BriType.items.map(menuItem => {
            
            return (
              <MenuItem  sx={
                {
                
                color:"#fff",
                backgroundColor: "#2F323A",
                borderBottom: '.5px solid grey',
                fontFamily: "Lato",fontSize:'12px',
            }}
              value={menuItem.label}>
                {menuItem.label}
              </MenuItem>
            );
          })}
      </Select>
      <LoadingButton sx={mainThemeStyle.normalButton}  loading={loading} onClick={handleAddBridge}  disabled = { getDisableValue("181") == false ?(recordsBridge.length > 0 ? true : false): true}>Add</LoadingButton>
      </Stack>
      </Grid>
      {/* </Stack>
      </Grid> */}
      <Grid item xs={1.7}>
    
      </Grid>
      <Grid item xs={4.8}>
        <Stack direction="row"  spacing={2}>
      <Typography sx={mainThemeStyle.popUpLable} height={40} > Add Camera </Typography>
      <Select
      sx={{ color: 'rgba(135, 135, 135, 0.5)',
      height:'33px',
      marginTop:'4px',
      marginBottom:'3px',
      borderRadius: '5px',
      backgroundColor: '#121212',
      boxShadow: 'inset 0px 4px 4px rgba(0, 0, 0, 0.25)',
      padding: '5px 0 5px 0',
      "& .MuiSvgIcon-root": {
        color: "rgba(135, 135, 135, 0.5)",
      },
      
      '&.Mui-disabled': {
        

        background: 'rgba(135, 135, 135, 0.5)',
      },
      "& input.Mui-disabled": {
        color: "white"
      }
      }}
      name = "bridge_select"
      disabled={getDisableValue("181")} 
      value={currentCamera}
      label="Age"
      onChange={handleChangeCamera}
      variant="filled"
      hiddenLabel
      MenuProps={MenuProps}>
      {addCamera?.map(menuItem => {
            
            return (
              <MenuItem  sx={
                {
                
                color:"#fff",
                backgroundColor: "#2F323A",
                borderBottom: '.5px solid grey',
                fontFamily: "Lato",fontSize:'12px',
            }}
              value={menuItem.name}>
                {menuItem.name}
              </MenuItem>
            );
          })}
      </Select>
      <Select
      sx={{ color: 'rgba(135, 135, 135, 0.5)',
      height:'33px',
      marginTop:'4px',
      marginBottom:'3px',
      borderRadius: '5px',
      backgroundColor: '#121212',
      boxShadow: 'inset 0px 4px 4px rgba(0, 0, 0, 0.25)',
      padding: '5px 0 5px 0',
      "& .MuiSvgIcon-root": {
        color: "rgba(135, 135, 135, 0.5)",
      },
      
      '&.Mui-disabled': {
        

        background: 'rgba(135, 135, 135, 0.5)',
      },
      "& input.Mui-disabled": {
        color: "white"
      }
      }}
      name = "bridge_select"
      disabled={getDisableValue("181")} 
      value={currentCameraType}
      label="Age"
      onChange={handleChangeCameraType}
      variant="filled"
      hiddenLabel
      MenuProps={MenuProps}>
      {CamType.items.map(menuItem => {
            
            return (
              <MenuItem  sx={
                {
                
                color:"#fff",
                backgroundColor: "#2F323A",
                borderBottom: '.5px solid grey',
                fontFamily: "Lato",fontSize:'12px',
            }}
              value={menuItem.label}>
                {menuItem.label}
              </MenuItem>
            );
          })}
      </Select>
             <LoadingButton sx={mainThemeStyle.normalButton} onClick={handleAddCamera} disabled={getDisableValue("181")}  loading={loading}>Add</LoadingButton>
          
      </Stack>
      </Grid>
      
      
      </Grid>
     
      <Grid container  padding= '32px'>
       
       <Grid item xs={5}>
       <TblContainerBridge>
                     <TblHeadBridge />
         <TableBody>
          {recordsAfterPagingAndSortingBridge().map((row) => (
             <TableRow sx={{ borderBottom: "1.5px solid black",
           }}>
               
               <TableCell align="left"  sx={mainThemeStyle.cellRow}> {row.name}</TableCell>
              <TableCell align="left"  sx={mainThemeStyle.cellRow}> {row.type == "0" ? "Dedicated" :"Shared"} </TableCell>
              
               <TableCell align="left">
              
                 <LoadingButton loading={loading}  sx={mainThemeStyle.viewLogButtonRed} disabled={getDisableValue("181")}  onClick={ () => handleBridgeDelete(row.id,row.name)}>Delete</LoadingButton>
                 
                 </TableCell>
             </TableRow>
           ))}
         </TableBody>
       
     </TblContainerBridge>
     
     </Grid>
     <Grid item xs={2}>
     
     </Grid>
     <Grid item xs={5}>
     <TblContainerCamera>
                     <TblHeadCamera />
         <TableBody>
          {recordsAfterPagingAndSortingCamera().map((row) => (
             <TableRow sx={{ borderBottom: "1.5px solid black",
           }}>
               
               <TableCell align="left"  sx={mainThemeStyle.cellRow}> {row.name}</TableCell>
              <TableCell align="left"  sx={mainThemeStyle.cellRow}> {row.type == "0" ? "Entry" : "Exit"} </TableCell>
              
             <TableCell align="left">
                <LoadingButton loading={loading}  sx={mainThemeStyle.viewLogButtonRed} disabled={getDisableValue("181")}  onClick={ () => handleCameraDelete(row.id,row.name)}>Delete</LoadingButton>
                    </TableCell>
             </TableRow>
           ))}
         </TableBody>
       
     </TblContainerCamera>
    
     </Grid>
       </Grid>
       
     
       <Grid container spacing={3}>
       <Grid item xs={5}>
       {/* <BelowTblPaginationBridge></BelowTblPaginationBridge> */}</Grid>
       <Grid item xs={1}>
       </Grid>
       <Grid item xs={6}>
       <BelowTblPaginationCamera></BelowTblPaginationCamera></Grid></Grid>
      
      
      
      
      </Box>}
      
      <Box sx={{display: { xs: "block", md: "none" }}}>
      <Grid container spacing={2} padding= '10px' paddingBottom='32px' paddingTop='32px'> 
      <Grid item xs={5}>
        <Stack direction="column"  spacing={2}>
      
      <Typography  sx={mainThemeStyle.popUpLable} height={40}>LPR Device </Typography>
      {/* <Typography sx={mainThemeStyle.popUpLable} height={40}>LPR Camera 1 Name </Typography>
      <Typography sx={mainThemeStyle.popUpLable} height={40}>LPR Camera 2 Name </Typography> */}
      
      </Stack>
      </Grid>
      
      <Grid item xs={7}>
      <Stack direction="column"  spacing={2}>
      
      
      <FormControlLabel  sx={mainThemeStyle.popUpLable}
        control={<IOSSwitch sx={{ m: 1 }} name="lpr_enable" onChange={handlecheckboxCheck} disabled={getDisableValue("181")}  checked={dataOfLocation.lpr_enable == "1" ? true : false } />}
        label=""
      />
            {/* <Checkbox sx={mainThemeStyle.formCheckBox} name="lpr_enable" onChange={handlecheckboxCheck} checked={dataOfLocation.lpr_enable == "1" ? true : false}/> */}
           {/*  <TextField
                        id="input-lpr-camera1"
                        height={40}
                        type= 'text'
                        name="lpr_cam1"
                        value={dataOfLocation.lpr_cam1}
                        onChange={handleInputChange}
                        sx={mainThemeStyle.InputMenuItem}
                        InputLabelProps={{ shrink: true }
                      }
                      variant="filled"
                        InputProps={{
                           
                            sx: {
                                
                                color:'rgba(135, 135, 135, 0.5)',
                                height:'33px',
                                borderRadius: '5px',
                                backgroundColor: '#121212',
                                boxShadow: 'inset 0px 4px 4px rgba(0, 0, 0, 0.25)',
                                padding: '0 0 15px 0',
                                textIndent: '15px !important',
                              
                               
                                
                            },
                        }}
                       
                    />
                    <TextField
                        id="input-location"
                        height={40}
                        type= 'text'
                        name="lpr_cam2"
                        value={dataOfLocation.lpr_cam2}
                        onChange={handleInputChange}
                        sx={mainThemeStyle.InputMenuItem}
                        InputLabelProps={{ shrink: true }
                      }
                      variant="filled"
                        InputProps={{
                           
                            sx: {
                                
                                color:'rgba(135, 135, 135, 0.5)',
                                height:'33px',
                                borderRadius: '5px',
                                backgroundColor: '#121212',
                                boxShadow: 'inset 0px 4px 4px rgba(0, 0, 0, 0.25)',
                                padding: '0 0 15px 0',
                                textIndent: '15px !important',
                              
                               
                                
                            },
                        }}
                       
                    /> */}
     
          
      </Stack>
      </Grid>
     
      
      
      </Grid>
      {dataOfLocation.lpr_enable == "1" &&<Box sx={{display: { xs: "block", md: "none" }}}>
      <Grid container spacing={2} padding= '40px' paddingBottom='32px' paddingTop='32px'> 
      <Grid item xs={12}>
        <Stack direction="column"  spacing={2} >
      <Typography sx={mainThemeStyle.popUpLable} height={40}> Add Bridge </Typography>
      
      </Stack>
      </Grid>
      
      <Grid item xs={12}>
      <Stack direction="column"  spacing={2}>
      <Stack direction="row"  spacing={2}>
      <Select
      sx={{ color: 'rgba(135, 135, 135, 0.5)',
      height:'33px',
      marginTop:'4px',
      marginBottom:'3px',
      borderRadius: '5px',
      backgroundColor: '#121212',
      boxShadow: 'inset 0px 4px 4px rgba(0, 0, 0, 0.25)',
      padding: '5px 0 5px 0',
      "& .MuiSvgIcon-root": {
        color: "rgba(135, 135, 135, 0.5)",
      },
      
      '&.Mui-disabled': {
        

        background: 'rgba(135, 135, 135, 0.5)',
      },
      "& input.Mui-disabled": {
        color: "white"
      }
      }}
      name = "bridge_select"
      disabled={getDisableValue("181")} 
      value={currentBridge}
      label="Age"
      onChange={handleChangeBridge}
      variant="filled"
      hiddenLabel
      MenuProps={MenuProps}>
      {addBridge?.map(menuItem => {
            
            return (
              <MenuItem  sx={
                {
                
                color:"#fff",
                backgroundColor: "#2F323A",
                borderBottom: '.5px solid grey',
                fontFamily: "Lato",fontSize:'12px',
            }}
              value={menuItem.name}>
                {menuItem.name}
              </MenuItem>
            );
          })}
      </Select>
      <Select
      sx={{ color: 'rgba(135, 135, 135, 0.5)',
      height:'33px',
      marginTop:'4px',
      marginBottom:'3px',
      borderRadius: '5px',
      backgroundColor: '#121212',
      boxShadow: 'inset 0px 4px 4px rgba(0, 0, 0, 0.25)',
      padding: '5px 0 5px 0',
      "& .MuiSvgIcon-root": {
        color: "rgba(135, 135, 135, 0.5)",
      },
      
      '&.Mui-disabled': {
        

        background: 'rgba(135, 135, 135, 0.5)',
      },
      "& input.Mui-disabled": {
        color: "white"
      }
      }}
      name = "bridge_select"
      disabled={getDisableValue("181")} 
      value={currentBridgeType}
      label="Age"
      onChange={handleChangeBridgeType}
      variant="filled"
      hiddenLabel
      MenuProps={MenuProps}>
      {BriType.items.map(menuItem => {
            
            return (
              <MenuItem  sx={
                {
                
                color:"#fff",
                backgroundColor: "#2F323A",
                borderBottom: '.5px solid grey',
                fontFamily: "Lato",fontSize:'12px',
            }}
              value={menuItem.label}>
                {menuItem.label}
              </MenuItem>
            );
          })}
      </Select>
      <LoadingButton sx={mainThemeStyle.normalButton}  loading={loading} onClick={handleAddBridge}  disabled = { getDisableValue("181") == false ? (recordsBridge.length > 0 ? true : false): true}>Add</LoadingButton>
     </Stack>
      </Stack>
      </Grid>

      
     
      
      
      </Grid>
      
      
      
      <TblContainerBridge>
                     <TblHeadBridge />
         <TableBody>
          {recordsAfterPagingAndSortingBridge().map((row) => (
             <TableRow sx={{ borderBottom: "1.5px solid black",
           }}>
               
               <TableCell align="left"  sx={mainThemeStyle.cellRow}> {row.name}</TableCell>
              <TableCell align="left"  sx={mainThemeStyle.cellRow}> {row.type == "0" ? "Dedicated" :"Shared"} </TableCell>
              
               <TableCell align="left">
              
                 <LoadingButton loading={loading}  sx={mainThemeStyle.viewLogButtonRed}  disabled={getDisableValue("181")}  onClick={ () => handleBridgeDelete(row.id,row.name)}>Delete</LoadingButton>
                 
                 </TableCell>
             </TableRow>
           ))}
         </TableBody>
       
     </TblContainerBridge>
    
    
    
    <Grid container spacing={2} padding= '40px' paddingBottom='32px' paddingTop='100px' > 
    <Grid item xs={12} >
        <Stack direction="column"  spacing={2}>
      <Typography sx={mainThemeStyle.popUpLable}> Add Camera </Typography>
      </Stack>
      </Grid>
      <Grid item xs={12}>
        <Stack direction="column"  spacing={2}>
          <Stack direction="row" spacing={2}>
          <Select
      sx={{ color: 'rgba(135, 135, 135, 0.5)',
      height:'33px',
      marginTop:'4px',
      marginBottom:'3px',
      borderRadius: '5px',
      backgroundColor: '#121212',
      boxShadow: 'inset 0px 4px 4px rgba(0, 0, 0, 0.25)',
      padding: '5px 0 5px 0',
      "& .MuiSvgIcon-root": {
        color: "rgba(135, 135, 135, 0.5)",
      },
      
      '&.Mui-disabled': {
        

        background: 'rgba(135, 135, 135, 0.5)',
      },
      "& input.Mui-disabled": {
        color: "white"
      }
      }}
      name = "bridge_select"
      disabled={getDisableValue("181")} 
      value={currentCamera}
      label="Age"
      onChange={handleChangeCamera}
      variant="filled"
      hiddenLabel
      MenuProps={MenuProps}>
      {addCamera?.map(menuItem => {
            
            return (
              <MenuItem  sx={
                {
                
                color:"#fff",
                backgroundColor: "#2F323A",
                borderBottom: '.5px solid grey',
                fontFamily: "Lato",fontSize:'12px',
            }}
              value={menuItem.name}>
                {menuItem.name}
              </MenuItem>
            );
          })}
      </Select>
      <Select
      sx={{ color: 'rgba(135, 135, 135, 0.5)',
      height:'33px',
      marginTop:'4px',
      marginBottom:'3px',
      borderRadius: '5px',
      backgroundColor: '#121212',
      boxShadow: 'inset 0px 4px 4px rgba(0, 0, 0, 0.25)',
      padding: '5px 0 5px 0',
      "& .MuiSvgIcon-root": {
        color: "rgba(135, 135, 135, 0.5)",
      },
      
      '&.Mui-disabled': {
        

        background: 'rgba(135, 135, 135, 0.5)',
      },
      "& input.Mui-disabled": {
        color: "white"
      }
      }}
      name = "bridge_select"
      disabled={getDisableValue("181")} 
      value={currentCameraType}
      label="Age"
      onChange={handleChangeCameraType}
      variant="filled"
      hiddenLabel
      MenuProps={MenuProps}>
      {CamType.items.map(menuItem => {
            
            return (
              <MenuItem  sx={
                {
                
                color:"#fff",
                backgroundColor: "#2F323A",
                borderBottom: '.5px solid grey',
                fontFamily: "Lato",fontSize:'12px',
            }}
              value={menuItem.label}>
                {menuItem.label}
              </MenuItem>
            );
          })}
      </Select>
             <LoadingButton sx={mainThemeStyle.normalButton} onClick={handleAddCamera} disabled={getDisableValue("181")}  loading={loading}>Add</LoadingButton>
          </Stack>
      </Stack>
      </Grid>
      
     
      
      
      </Grid>
      
      <TblContainerCamera>
                     <TblHeadCamera />
         <TableBody>
          {recordsAfterPagingAndSortingCamera().map((row) => (
             <TableRow sx={{ borderBottom: "1.5px solid black",
           }}>
               
               <TableCell align="left"  sx={mainThemeStyle.cellRow}> {row.name}</TableCell>
              <TableCell align="left"  sx={mainThemeStyle.cellRow}> {row.type == "0" ? "Entry" : "Exit"} </TableCell>
              
             <TableCell align="left">
                <LoadingButton loading={loading}  sx={mainThemeStyle.viewLogButtonRed} disabled={getDisableValue("181")}  onClick={ () => handleCameraDelete(row.id,row.name)}>Delete</LoadingButton>
                    </TableCell>
             </TableRow>
           ))}
         </TableBody>
       
     </TblContainerCamera>
    
    <BelowTblPaginationCamera></BelowTblPaginationCamera>
      </Box>}
      </Box>

</>)}
{(currentTab == 4 && dataOfLocation.cam_brand == "ResidentLink" && <>
<div  sx={{
       right: '54px' ,left: '54px',borderRadius:'0px'}}>
               <div style={{ display: 'flex' }}>
                   <Typography variant="h6" component="div" style={{  paddingLeft: '16px',paddingTop: '16px',flexGrow: 1,color:'#fff' }}>
                       Hold Open Settings
                   </Typography>
                   
               </div>
              
           </div>
           <Divider sx={{backgroundColor:'white'}} />
           <Box sx={{display: { xs: "none", md: "block" }}}>
      <Grid container spacing={2} padding= '32px'> 
      <Grid item xs={2.5}>
      <Stack direction="column"  spacing={1}>
      <Typography  variant="h6" component="div" style={{ flexGrow: 1,color:'#fff' }}>Enable Hold Open </Typography>
      </Stack>
      </Grid>
      <Grid item xs={2.5}>
      <Stack direction="column"  spacing={2}>
      <FormControlLabel  sx={mainThemeStyle.popUpLable}
        control={<IOSSwitch sx={{ m: 1 }}   onChange={handleChangeEventOfHoldOpen} disabled={getDisableValue("182")}  checked={dataOfLocation.hold_open.holdopen == "1" ? true : false }/>}
        
      />
      </Stack>
      </Grid>
      </Grid>
       {dataOfLocation.hold_open.holdopen == "1" && <Grid container spacing={2} padding= '32px'> 
      <Grid item xs={10}>
      <Stack direction="row"  spacing={4}>
      <Typography  sx={mainThemeStyle.popUpLable} height={40}>Indefinite Mode </Typography>
      
      <Checkbox sx={mainThemeStyle.formCheckBoxLow} name="indefinite" onChange={handleChangeValueOfHoldOpen} disabled={getDisableValue("182")} checked={dataOfLocation.hold_open.indefinite == "1" ? true : false}/>
      
      <Typography  sx={mainThemeStyle.popUpLable} height={40}>Recurring Schedule Mode </Typography>
     
      
      <Checkbox sx={mainThemeStyle.formCheckBoxLow} name="definite" onChange={handleChangeValueOfHoldOpen} disabled={getDisableValue("182")} checked={dataOfLocation.hold_open.definite == "1" ? true : false}/>
      
      <Typography  sx={mainThemeStyle.popUpLable} height={40}>One-Time Schedule Mode </Typography>
      
      
      <Checkbox sx={mainThemeStyle.formCheckBoxLow} name="finite" onChange={handleChangeValueOfHoldOpen} disabled={getDisableValue("182")} checked={dataOfLocation.hold_open.finite == "1" ? true : false}/>
      </Stack>
      </Grid>
      </Grid>}
      
      {dataOfLocation.hold_open.holdopen == "1" && dataOfLocation.hold_open.indefinite == "1" &&
      <Grid container spacing={2} padding= '32px'> 
      <Grid item xs={1}>
      <Stack direction="column"  spacing={2}>
      <Typography  sx={mainThemeStyle.popUpLable} height={40}>Relay 1 </Typography>
      <Typography  sx={mainThemeStyle.popUpLable} height={40}>Relay 2 </Typography>
      </Stack>
      </Grid>
      <Grid item xs={1}>
      <Stack direction="column"  spacing={2}>
      <Checkbox sx={mainThemeStyle.formCheckBox} name="relay1" onChange={handleIndefiniteRelay} disabled={getDisableValue("182")}  checked={dataOfLocation.hold_open.relay1 == "1" ? true : false }/>
      <Checkbox sx={mainThemeStyle.formCheckBox} name="relay2" onChange={handleIndefiniteRelay} disabled={getDisableValue("182")} checked={dataOfLocation.hold_open.relay2 == "1" ? true : false }/>
      </Stack>
      </Grid>
      </Grid>}
      
      {dataOfLocation.hold_open.holdopen == "1" && dataOfLocation.hold_open.definite == "1" &&
      <TableContainer component={Paper} sx={{
        width:'auto',
        height: 'auto',
        marginLeft: '38px',
        marginRight:'38px',
        marginBottom:'38px',
        backgroundColor:'#171E29',
       
        }}>
      <Table sx={{ minWidth: 650 }} >
        
        <TableHead sx={{ borderBottom: "1.5px solid black",
        }}>
          <TableRow>
            
          <TableCell align="center" sx={mainThemeStyle.popUpLable} colSpan={3}>Relay Hold Open Times</TableCell>
            <TableCell align="center" sx={mainThemeStyle.popUpLable} colSpan={7}>Weekdays</TableCell>
            <TableCell align="center" sx={mainThemeStyle.popUpLable} colSpan={2}>Relay Number</TableCell>
          </TableRow>
         
        </TableHead>

        
        <TableHead sx={{ borderBottom: "1.5px solid black",
        }}>
          <TableRow>
          <TableCell align="right" sx={mainThemeStyle.cellHeader}></TableCell>
            <TableCell align="right" sx={mainThemeStyle.cellHeader}>Begin Time</TableCell>
            <TableCell align="right" sx={mainThemeStyle.cellHeader}>End Time</TableCell>
            <TableCell align="right" sx={mainThemeStyle.cellHeader}>S</TableCell>
            <TableCell align="right" sx={mainThemeStyle.cellHeader}>M</TableCell>
            <TableCell align="right" sx={mainThemeStyle.cellHeader}>T</TableCell>
            <TableCell align="right"sx={mainThemeStyle.cellHeader}>W</TableCell>
            <TableCell align="right" sx={mainThemeStyle.cellHeader}>T</TableCell>
            <TableCell align="right" sx={mainThemeStyle.cellHeader}>F</TableCell>
            <TableCell align="right"sx={mainThemeStyle.cellHeader}>S</TableCell>
            <TableCell align="right"sx={mainThemeStyle.cellHeader}>1</TableCell>
            <TableCell align="right"sx={mainThemeStyle.cellHeader}>2</TableCell>
          </TableRow></TableHead>
        <TableBody>
         {rowsHoleOpen.map((row) => (
            <TableRow sx={{ borderBottom: "1.5px solid black",
          }}>
              <TableCell sx={mainThemeStyle.cellRow}>
              {row}
              </TableCell>
              <TableCell align="right">
          <Select
            sx={{ color: 'rgba(135, 135, 135, 0.5)',
            height:'25px',
            marginTop:'0px',
            marginBottom:'0px',
            borderRadius: '5px',
            backgroundColor: '#121212',
            boxShadow: 'inset 0px 4px 4px rgba(0, 0, 0, 0.25)',
            padding: '5px 0 5px 0',
            "& .MuiSvgIcon-root": {
              color: "rgba(135, 135, 135, 0.5)",
            },
            
            '&.Mui-disabled': {
             

              background: 'rgba(135, 135, 135, 0.5)',
            },
            "& input.Mui-disabled": {
              color: "white"
            }
            }}
            disabled={getDisableValue("182")}
            name = {parseInt(row)-1}
            value={dataOfLocation.hold_open.table[parseInt(row)-1]?dataOfLocation.hold_open.table[parseInt(row)-1].st:'12:00 AM'}
          onChange={handleChangeOpenStart}
          variant="filled"
          hiddenLabel
          MenuProps={MenuProps}>
            {BootTime.items.map(menuItem => {
                  
                  return (
                    <MenuItem  sx={
                      {
                      
                      color:"#fff",
                      backgroundColor: "#2F323A",
                      borderBottom: '.5px solid grey',
                      fontFamily: "Lato",fontSize:'12px',
                  }}
                   value={menuItem.label}>
                      {menuItem.label}
                    </MenuItem>
                  );
                })}
          </Select>
          </TableCell>
           <TableCell align="right">  <Select
            sx={{ color: 'rgba(135, 135, 135, 0.5)',
            height:'25px',
            marginTop:'0px',
            marginBottom:'0px',
            borderRadius: '5px',
            backgroundColor: '#121212',
            boxShadow: 'inset 0px 4px 4px rgba(0, 0, 0, 0.25)',
            padding: '5px 0 5px 0',
            "& .MuiSvgIcon-root": {
              color: "rgba(135, 135, 135, 0.5)",
            },
            
            '&.Mui-disabled': {
             

              background: 'rgba(135, 135, 135, 0.5)',
            },
            "& input.Mui-disabled": {
              color: "white"
            }
            }}
            disabled={getDisableValue("182")}
            name = {parseInt(row)-1}
            value={dataOfLocation.hold_open.table[parseInt(row)-1] ? dataOfLocation.hold_open.table[parseInt(row)-1].en : '12:00 AM'}
          onChange={handleChangEndStart}
          variant="filled"
          hiddenLabel
          MenuProps={MenuProps}>
            {BootTime.items.map(menuItem => {
                  
                  return (
                    <MenuItem  sx={
                      {
                      
                      color:"#fff",
                      backgroundColor: "#2F323A",
                      borderBottom: '.5px solid grey',
                      fontFamily: "Lato",fontSize:'12px',
                  }}
                   value={menuItem.label}>
                      {menuItem.label}
                    </MenuItem>
                  );
                })}
          </Select> </TableCell>
              <TableCell align="right"><Checkbox sx={mainThemeStyle.formCheckBoxLow} disabled={getDisableValue("182")}  name = {parseInt(row)-1} onChange={handleSundayRelay} checked={dataOfLocation.hold_open.table[parseInt(row)-1]?(dataOfLocation.hold_open.table[parseInt(row)-1].s == "1" ? true : false):false}/></TableCell>
              <TableCell align="right"><Checkbox sx={mainThemeStyle.formCheckBoxLow}  disabled={getDisableValue("182")} name = {parseInt(row)-1} onChange={handleMondayRelay} checked={dataOfLocation.hold_open.table[parseInt(row)-1]?(dataOfLocation.hold_open.table[parseInt(row)-1].m == "1" ? true : false):false}/></TableCell>
              <TableCell align="right"><Checkbox sx={mainThemeStyle.formCheckBoxLow} disabled={getDisableValue("182")} name = {parseInt(row)-1} onChange={handleTuesdayRelay}  checked={dataOfLocation.hold_open.table[parseInt(row)-1]?(dataOfLocation.hold_open.table[parseInt(row)-1].t == "1" ? true : false):false}/></TableCell>
              <TableCell align="right"><Checkbox sx={mainThemeStyle.formCheckBoxLow} disabled={getDisableValue("182")} name = {parseInt(row)-1} onChange={handleWednesdayRelay} checked={dataOfLocation.hold_open.table[parseInt(row)-1]?(dataOfLocation.hold_open.table[parseInt(row)-1].w == "1" ? true : false):false}/></TableCell>
              <TableCell align="right"><Checkbox sx={mainThemeStyle.formCheckBoxLow} disabled={getDisableValue("182")} name = {parseInt(row)-1} onChange={handleThursdayRelay} checked={dataOfLocation.hold_open.table[parseInt(row)-1]?(dataOfLocation.hold_open.table[parseInt(row)-1].th == "1" ? true : false):false}/></TableCell>
              <TableCell align="right"><Checkbox sx={mainThemeStyle.formCheckBoxLow} disabled={getDisableValue("182")} name = {parseInt(row)-1} onChange={handleFridayRelay} checked={dataOfLocation.hold_open.table[parseInt(row)-1]?(dataOfLocation.hold_open.table[parseInt(row)-1].f == "1" ? true : false):false}/></TableCell>
              <TableCell align="right"><Checkbox sx={mainThemeStyle.formCheckBoxLow} disabled={getDisableValue("182")} name = {parseInt(row)-1} onChange={handleSaturdayRelay} checked={dataOfLocation.hold_open.table[parseInt(row)-1]?(dataOfLocation.hold_open.table[parseInt(row)-1].sa == "1" ? true : false):false}/></TableCell>
              <TableCell align="right"><Checkbox sx={mainThemeStyle.formCheckBoxLow} disabled={getDisableValue("182")} name = {parseInt(row)-1} onChange={handleHoldRelayOne} checked={dataOfLocation.hold_open.table[parseInt(row)-1]?(dataOfLocation.hold_open.table[parseInt(row)-1].r1 == "1" ? true : false):false}/></TableCell>
              <TableCell align="right"><Checkbox sx={mainThemeStyle.formCheckBoxLow} disabled={getDisableValue("182")} name = {parseInt(row)-1} onChange={handleHoldRelayTwo} checked={dataOfLocation.hold_open.table[parseInt(row)-1]?(dataOfLocation.hold_open.table[parseInt(row)-1].r2 == "1" ? true : false):false}/></TableCell>
            </TableRow>
          ))}
        </TableBody>
      </Table>
    </TableContainer>}

    {dataOfLocation.hold_open.holdopen == "1" && dataOfLocation.hold_open.finite == "1" &&
    <Grid container spacing={2} padding= '32px'> 
      <Grid item xs={2.5}>
      <Stack direction="column"  spacing={2}>
      <Typography  sx={mainThemeStyle.popUpLable} height={40}>Date</Typography>
      <Typography  sx={mainThemeStyle.popUpLable} height={40}>Start Time</Typography>
      <Typography  sx={mainThemeStyle.popUpLable} height={40}>End Time</Typography>
      <Typography  sx={mainThemeStyle.popUpLable} height={40}>Relay 1</Typography>
      <Typography  sx={mainThemeStyle.popUpLable} height={40}>Relay 2</Typography>
      </Stack>
      </Grid>
      <Grid item xs={2.5}>
      <Stack direction="column"  spacing={2}>
      <TextField
        id="date"
        type="date"
        name="expiry"
        value={dataOfLocation.hold_open.expiry}
        onChange={handleOneTimeValue}
        sx={mainThemeStyle.InputMenuItem}
                        InputLabelProps={{ shrink: true }
                      }
                      disabled={getDisableValue("182")}
                      variant="filled" 
                        InputProps={{
                           
                            sx: {
                                
                                color:'rgba(135, 135, 135, 0.5)',
                                height:'33px',
                                borderRadius: '5px',
                                backgroundColor: '#121212',
                                boxShadow: 'inset 0px 4px 4px rgba(0, 0, 0, 0.25)',
                                padding: '0 0 15px 0',
                                textIndent: '15px !important',
                                '&.Mui-disabled': {
             

                                  background: 'rgba(135, 135, 135, 0.5)',
                                },
                                
                                "& input.Mui-disabled": {
                                  color: "white"
                                }
                               
                                
                            },
                        }}
                       
      />
      <Select
            sx={{ color: 'rgba(135, 135, 135, 0.5)',
            height:'33px',
            marginTop:'4px',
            marginBottom:'3px',
            borderRadius: '5px',
            backgroundColor: '#121212',
            boxShadow: 'inset 0px 4px 4px rgba(0, 0, 0, 0.25)',
            padding: '5px 0 5px 0',
            "& .MuiSvgIcon-root": {
              color: "rgba(135, 135, 135, 0.5)",
            },
            
            '&.Mui-disabled': {
             

              background: 'rgba(135, 135, 135, 0.5)',
            },
            "& input.Mui-disabled": {
              color: "white"
            }
            }}
            name = "time_on"
            disabled={getDisableValue("182")}
            value={dataOfLocation.hold_open.time_on}
          onChange={handleOneTimeValue}
          variant="filled"
          hiddenLabel
          MenuProps={MenuProps}>
            {BootTime.items.map(menuItem => {
                  
                  return (
                    <MenuItem  sx={
                      {
                      
                      color:"#fff",
                      backgroundColor: "#2F323A",
                      borderBottom: '.5px solid grey',
                      fontFamily: "Lato",fontSize:'12px',
                  }}
                   value={menuItem.label}>
                      {menuItem.label}
                    </MenuItem>
                  );
                })}
          </Select>
          <Select
            sx={{ color: 'rgba(135, 135, 135, 0.5)',
            height:'33px',
            marginTop:'4px',
            marginBottom:'3px',
            borderRadius: '5px',
            backgroundColor: '#121212',
            boxShadow: 'inset 0px 4px 4px rgba(0, 0, 0, 0.25)',
            padding: '5px 0 5px 0',
            "& .MuiSvgIcon-root": {
              color: "rgba(135, 135, 135, 0.5)",
            },
            
            '&.Mui-disabled': {
             

              background: 'rgba(135, 135, 135, 0.5)',
            },
            "& input.Mui-disabled": {
              color: "white"
            }
            }}
            disabled={getDisableValue("182")}
            name = "time_off"
            value={dataOfLocation.hold_open.time_off}
          onChange={handleOneTimeValue}
          variant="filled"
          hiddenLabel
          MenuProps={MenuProps}>
            {BootTime.items.map(menuItem => {
                  
                  return (
                    <MenuItem  sx={
                      {
                      
                      color:"#fff",
                      backgroundColor: "#2F323A",
                      borderBottom: '.5px solid grey',
                      fontFamily: "Lato",fontSize:'12px',
                  }}
                   value={menuItem.label}>
                      {menuItem.label}
                    </MenuItem>
                  );
                })}
          </Select>
      <Checkbox sx={mainThemeStyle.formCheckBox} name="relay1" disabled={getDisableValue("182")} onChange={handleIndefiniteRelay} checked={dataOfLocation.hold_open.relay1 == "1" ? true : false }/>
      <Checkbox sx={mainThemeStyle.formCheckBox} name="relay2" disabled={getDisableValue("182")} onChange={handleIndefiniteRelay} checked={dataOfLocation.hold_open.relay2 == "1" ? true : false}/>
      </Stack>
      </Grid>
      </Grid>} 
      </Box>
      <Box sx={{display: { xs: "block", md: "none" }}}>
      <Grid container spacing={2} padding= '10px' paddingBottom='32px' paddingTop='32px'> 
      <Grid item xs={8}>
      <Stack direction="column"  spacing={1}>
      <Typography  variant="h6" component="div" style={{ flexGrow: 1,color:'#fff' }}>Enable Hold Open </Typography>
      </Stack>
      </Grid>
      <Grid item xs={2}>
      <Stack direction="column"  spacing={2}>
      <FormControlLabel  sx={mainThemeStyle.popUpLable}
        control={<IOSSwitch sx={{ m: 1 }}   onChange={handleChangeEventOfHoldOpen}  disabled={getDisableValue("182")} checked={dataOfLocation.hold_open.holdopen == "1" ? true : false }/>}
        
      />
      </Stack>
      </Grid>
      </Grid>
       {dataOfLocation.hold_open.holdopen == "1" && <Grid container spacing={2} padding= '27px' paddingBottom='32px' paddingTop='32px'> 
       <Stack direction="column"  spacing={2}>
       <Stack direction="row"  spacing={2}>
      <Typography  sx={mainThemeStyle.popUpLable} height={40}>Indefinite Mode </Typography>
      
      <Checkbox sx={mainThemeStyle.formCheckBoxLow} name="indefinite" disabled={getDisableValue("182")} onChange={handleChangeValueOfHoldOpen} checked={dataOfLocation.hold_open.indefinite == "1" ? true : false}/>
      </Stack>
      <Stack direction="row"  spacing={2}>
      <Typography  sx={mainThemeStyle.popUpLable} height={40}>Recurring Schedule Mode </Typography>
     
      
      <Checkbox sx={mainThemeStyle.formCheckBoxLow} name="definite" disabled={getDisableValue("182")} onChange={handleChangeValueOfHoldOpen} checked={dataOfLocation.hold_open.definite == "1" ? true : false}/>
      </Stack>
      <Stack direction="row"  spacing={2}>
      <Typography  sx={mainThemeStyle.popUpLable} height={40}>One-Time Schedule Mode </Typography>
      
      
      <Checkbox sx={mainThemeStyle.formCheckBoxLow} name="finite" disabled={getDisableValue("182")} onChange={handleChangeValueOfHoldOpen} checked={dataOfLocation.hold_open.finite == "1" ? true : false}/>
      </Stack>
      </Stack>
      </Grid>}
      
      {dataOfLocation.hold_open.holdopen == "1" && dataOfLocation.hold_open.indefinite == "1" &&
      <Grid container spacing={2} padding= '10px' paddingBottom='32px' paddingTop='10px'> 
      <Grid item xs={7}>
      <Stack direction="column"  spacing={2}>
      <Typography  sx={mainThemeStyle.popUpLable} height={40}>Relay 1 </Typography>
      <Typography  sx={mainThemeStyle.popUpLable} height={40}>Relay 2 </Typography>
      </Stack>
      </Grid>
      <Grid item xs={5}>
      <Stack direction="column"  spacing={2}>
      <Checkbox sx={mainThemeStyle.formCheckBox} name="relay1" disabled={getDisableValue("182")} onChange={handleIndefiniteRelay} checked={dataOfLocation.hold_open.relay1 == "1" ? true : false }/>
      <Checkbox sx={mainThemeStyle.formCheckBox} name="relay2" disabled={getDisableValue("182")} onChange={handleIndefiniteRelay} checked={dataOfLocation.hold_open.relay2 == "1" ? true : false }/>
      </Stack>
      </Grid>
      </Grid>}
      
      {dataOfLocation.hold_open.holdopen == "1" && dataOfLocation.hold_open.definite == "1" &&
      <TableContainer component={Paper} sx={{
        width:'auto',
        height: 'auto',
        marginLeft: '10px',
        marginRight:'10px',
        marginBottom:'38px',
        backgroundColor:'#171E29',
       
        }}>
      <Table sx={{ minWidth: 650 }} >
        
        <TableHead sx={{ borderBottom: "1.5px solid black",
        }}>
          <TableRow>
            
          <TableCell align="center" sx={mainThemeStyle.popUpLable} colSpan={3}>Relay Hold Open Times</TableCell>
            <TableCell align="center" sx={mainThemeStyle.popUpLable} colSpan={7}>Weekdays</TableCell>
            <TableCell align="center" sx={mainThemeStyle.popUpLable} colSpan={2}>Relay Number</TableCell>
          </TableRow>
         
        </TableHead>

        
        <TableHead sx={{ borderBottom: "1.5px solid black",
        }}>
          <TableRow>
          <TableCell align="right" sx={mainThemeStyle.cellHeader}></TableCell>
            <TableCell align="right" sx={mainThemeStyle.cellHeader}>Begin Time</TableCell>
            <TableCell align="right" sx={mainThemeStyle.cellHeader}>End Time</TableCell>
            <TableCell align="right" sx={mainThemeStyle.cellHeader}>S</TableCell>
            <TableCell align="right" sx={mainThemeStyle.cellHeader}>M</TableCell>
            <TableCell align="right" sx={mainThemeStyle.cellHeader}>T</TableCell>
            <TableCell align="right"sx={mainThemeStyle.cellHeader}>W</TableCell>
            <TableCell align="right" sx={mainThemeStyle.cellHeader}>T</TableCell>
            <TableCell align="right" sx={mainThemeStyle.cellHeader}>F</TableCell>
            <TableCell align="right"sx={mainThemeStyle.cellHeader}>S</TableCell>
            <TableCell align="right"sx={mainThemeStyle.cellHeader}>1</TableCell>
            <TableCell align="right"sx={mainThemeStyle.cellHeader}>2</TableCell>
          </TableRow></TableHead>
        <TableBody>
         {rowsHoleOpen.map((row) => (
            <TableRow sx={{ borderBottom: "1.5px solid black",
          }}>
              <TableCell sx={mainThemeStyle.cellRow}>
              {row}
              </TableCell>
              <TableCell align="right">
          <Select
            sx={{ color: 'rgba(135, 135, 135, 0.5)',
            height:'25px',
            marginTop:'0px',
            marginBottom:'0px',
            borderRadius: '5px',
            backgroundColor: '#121212',
            boxShadow: 'inset 0px 4px 4px rgba(0, 0, 0, 0.25)',
            padding: '5px 0 5px 0',
            "& .MuiSvgIcon-root": {
              color: "rgba(135, 135, 135, 0.5)",
            },
            
            '&.Mui-disabled': {
             

              background: 'rgba(135, 135, 135, 0.5)',
            },
            "& input.Mui-disabled": {
              color: "white"
            }
            }}
            disabled={getDisableValue("182")}
            name = {parseInt(row)-1}
            value={dataOfLocation.hold_open.table[parseInt(row)-1]?dataOfLocation.hold_open.table[parseInt(row)-1].st:'12:00 AM'}
          onChange={handleChangeOpenStart}
          variant="filled"
          hiddenLabel
          MenuProps={MenuProps}>
            {BootTime.items.map(menuItem => {
                  
                  return (
                    <MenuItem  sx={
                      {
                      
                      color:"#fff",
                      backgroundColor: "#2F323A",
                      borderBottom: '.5px solid grey',
                      fontFamily: "Lato",fontSize:'12px',
                  }}
                   value={menuItem.label}>
                      {menuItem.label}
                    </MenuItem>
                  );
                })}
          </Select>
          </TableCell>
           <TableCell align="right">  <Select
            sx={{ color: 'rgba(135, 135, 135, 0.5)',
            height:'25px',
            marginTop:'0px',
            marginBottom:'0px',
            borderRadius: '5px',
            backgroundColor: '#121212',
            boxShadow: 'inset 0px 4px 4px rgba(0, 0, 0, 0.25)',
            padding: '5px 0 5px 0',
            "& .MuiSvgIcon-root": {
              color: "rgba(135, 135, 135, 0.5)",
            },
            
            '&.Mui-disabled': {
             

              background: 'rgba(135, 135, 135, 0.5)',
            },
            "& input.Mui-disabled": {
              color: "white"
            }
            }}
            disabled={getDisableValue("182")}
            name = {parseInt(row)-1}
            value={dataOfLocation.hold_open.table[parseInt(row)-1]?dataOfLocation.hold_open.table[parseInt(row)-1].en:'12:00 AM'}
          onChange={handleChangEndStart}
          variant="filled"
          hiddenLabel
          MenuProps={MenuProps}>
            {BootTime.items.map(menuItem => {
                  
                  return (
                    <MenuItem  sx={
                      {
                      
                      color:"#fff",
                      backgroundColor: "#2F323A",
                      borderBottom: '.5px solid grey',
                      fontFamily: "Lato",fontSize:'12px',
                  }}
                   value={menuItem.label}>
                      {menuItem.label}
                    </MenuItem>
                  );
                })}
          </Select> </TableCell>
              <TableCell align="right"><Checkbox sx={mainThemeStyle.formCheckBoxLow} disabled={getDisableValue("182")} name = {parseInt(row)-1} onChange={handleSundayRelay} checked={dataOfLocation.hold_open.table[parseInt(row)-1]?(dataOfLocation.hold_open.table[parseInt(row)-1].s == "1" ? true : false):false}/></TableCell>
              <TableCell align="right"><Checkbox sx={mainThemeStyle.formCheckBoxLow} disabled={getDisableValue("182")} name = {parseInt(row)-1} onChange={handleMondayRelay} checked={dataOfLocation.hold_open.table[parseInt(row)-1]?(dataOfLocation.hold_open.table[parseInt(row)-1].m == "1" ? true : false):false}/></TableCell>
              <TableCell align="right"><Checkbox sx={mainThemeStyle.formCheckBoxLow} disabled={getDisableValue("182")} name = {parseInt(row)-1} onChange={handleTuesdayRelay}  checked={dataOfLocation.hold_open.table[parseInt(row)-1]?(dataOfLocation.hold_open.table[parseInt(row)-1].t == "1" ? true : false):false}/></TableCell>
              <TableCell align="right"><Checkbox sx={mainThemeStyle.formCheckBoxLow} disabled={getDisableValue("182")} name = {parseInt(row)-1} onChange={handleWednesdayRelay} checked={dataOfLocation.hold_open.table[parseInt(row)-1]?(dataOfLocation.hold_open.table[parseInt(row)-1].w == "1" ? true : false):false}/></TableCell>
              <TableCell align="right"><Checkbox sx={mainThemeStyle.formCheckBoxLow} disabled={getDisableValue("182")} name = {parseInt(row)-1} onChange={handleThursdayRelay} checked={dataOfLocation.hold_open.table[parseInt(row)-1]?(dataOfLocation.hold_open.table[parseInt(row)-1].th == "1" ? true : false):false}/></TableCell>
              <TableCell align="right"><Checkbox sx={mainThemeStyle.formCheckBoxLow} disabled={getDisableValue("182")} name = {parseInt(row)-1} onChange={handleFridayRelay} checked={dataOfLocation.hold_open.table[parseInt(row)-1]?(dataOfLocation.hold_open.table[parseInt(row)-1].f == "1" ? true : false):false}/></TableCell>
              <TableCell align="right"><Checkbox sx={mainThemeStyle.formCheckBoxLow} disabled={getDisableValue("182")} name = {parseInt(row)-1} onChange={handleSaturdayRelay} checked={dataOfLocation.hold_open.table[parseInt(row)-1]?(dataOfLocation.hold_open.table[parseInt(row)-1].sa == "1" ? true : false):false}/></TableCell>
              <TableCell align="right"><Checkbox sx={mainThemeStyle.formCheckBoxLow} disabled={getDisableValue("182")} name = {parseInt(row)-1} onChange={handleHoldRelayOne} checked={dataOfLocation.hold_open.table[parseInt(row)-1]?(dataOfLocation.hold_open.table[parseInt(row)-1].r1 == "1" ? true : false):false}/></TableCell>
              <TableCell align="right"><Checkbox sx={mainThemeStyle.formCheckBoxLow} disabled={getDisableValue("182")} name = {parseInt(row)-1} onChange={handleHoldRelayTwo} checked={dataOfLocation.hold_open.table[parseInt(row)-1]?(dataOfLocation.hold_open.table[parseInt(row)-1].r2 == "1" ? true : false):false}/></TableCell>
            </TableRow>
          ))}
        </TableBody>
      </Table>
    </TableContainer>}

    {dataOfLocation.hold_open.holdopen == "1" && dataOfLocation.hold_open.finite == "1" &&
    <Grid container spacing={2} padding= '10px' paddingBottom='32px' paddingTop='10px'> 
    <Grid item xs={5}>
      <Stack direction="column"  spacing={2}>
      <Typography  sx={mainThemeStyle.popUpLable} height={40}>Date</Typography>
      <Typography  sx={mainThemeStyle.popUpLable} height={40}>Start Time</Typography>
      <Typography  sx={mainThemeStyle.popUpLable} height={40}>End Time</Typography>
      <Typography  sx={mainThemeStyle.popUpLable} height={40}>Relay 1</Typography>
      <Typography  sx={mainThemeStyle.popUpLable} height={40}>Relay 2</Typography>
      </Stack>
      </Grid>
      <Grid item xs={7}>
      <Stack direction="column"  spacing={2}>
      <TextField
        id="date"
        type="date"
        name="expiry"
        value={dataOfLocation.hold_open.expiry}
        onChange={handleOneTimeValue}
        sx={mainThemeStyle.InputMenuItem}
                        InputLabelProps={{ shrink: true }
                      }
                      disabled={getDisableValue("182")}
                      variant="filled" 
                        InputProps={{
                           
                            sx: {
                                
                                color:'rgba(135, 135, 135, 0.5)',
                                height:'33px',
                                borderRadius: '5px',
                                backgroundColor: '#121212',
                                boxShadow: 'inset 0px 4px 4px rgba(0, 0, 0, 0.25)',
                                padding: '0 0 15px 0',
                                textIndent: '15px !important',
                              
                                '&.Mui-disabled': {
             

                                  background: 'rgba(135, 135, 135, 0.5)',
                                },
                                
                                "& input.Mui-disabled": {
                                  color: "white"
                                }
                                
                            },
                        }}
                       
      />
      <Select
            sx={{ color: 'rgba(135, 135, 135, 0.5)',
            height:'33px',
            marginTop:'4px',
            marginBottom:'3px',
            borderRadius: '5px',
            backgroundColor: '#121212',
            boxShadow: 'inset 0px 4px 4px rgba(0, 0, 0, 0.25)',
            padding: '5px 0 5px 0',
            "& .MuiSvgIcon-root": {
              color: "rgba(135, 135, 135, 0.5)",
            },
            
            '&.Mui-disabled': {
             

              background: 'rgba(135, 135, 135, 0.5)',
            },
            "& input.Mui-disabled": {
              color: "white"
            }
            }}
            name = "time_on"
            disabled={getDisableValue("182")}
            value={dataOfLocation.hold_open.time_on}
          onChange={handleOneTimeValue}
          variant="filled"
          hiddenLabel
          MenuProps={MenuProps}>
            {BootTime.items.map(menuItem => {
                  
                  return (
                    <MenuItem  sx={
                      {
                      
                      color:"#fff",
                      backgroundColor: "#2F323A",
                      borderBottom: '.5px solid grey',
                      fontFamily: "Lato",fontSize:'12px',
                  }}
                   value={menuItem.label}>
                      {menuItem.label}
                    </MenuItem>
                  );
                })}
          </Select>
          <Select
            sx={{ color: 'rgba(135, 135, 135, 0.5)',
            height:'33px',
            marginTop:'4px',
            marginBottom:'3px',
            borderRadius: '5px',
            backgroundColor: '#121212',
            boxShadow: 'inset 0px 4px 4px rgba(0, 0, 0, 0.25)',
            padding: '5px 0 5px 0',
            "& .MuiSvgIcon-root": {
              color: "rgba(135, 135, 135, 0.5)",
            },
            
            '&.Mui-disabled': {
             

              background: 'rgba(135, 135, 135, 0.5)',
            },
            "& input.Mui-disabled": {
              color: "white"
            }
            }}
            disabled={getDisableValue("182")}
            name = "time_off"
            value={dataOfLocation.hold_open.time_off}
          onChange={handleOneTimeValue}
          variant="filled"
          hiddenLabel
          MenuProps={MenuProps}>
            {BootTime.items.map(menuItem => {
                  
                  return (
                    <MenuItem  sx={
                      {
                      
                      color:"#fff",
                      backgroundColor: "#2F323A",
                      borderBottom: '.5px solid grey',
                      fontFamily: "Lato",fontSize:'12px',
                  }}
                   value={menuItem.label}>
                      {menuItem.label}
                    </MenuItem>
                  );
                })}
          </Select>
      <Checkbox sx={mainThemeStyle.formCheckBox} name="relay1" disabled={getDisableValue("182")} onChange={handleIndefiniteRelay} checked={dataOfLocation.hold_open.relay1 == "1" ? true : false }/>
      <Checkbox sx={mainThemeStyle.formCheckBox} name="relay2" disabled={getDisableValue("182")} onChange={handleIndefiniteRelay} checked={dataOfLocation.hold_open.relay2 == "1" ? true : false}/>
      </Stack>
      </Grid>
      </Grid>} 
      </Box>
</>)}
{(currentTab == 5 && dataOfLocation.cam_brand == "Virtual Panel" && token.issub === true && token.name === 'SUPER ADMIN' && <>
<div  sx={{
       right: '54px' ,left: '54px',borderRadius:'0px'}}>
               <div style={{ display: 'flex' }}>
                   <Typography variant="h6" component="div" style={{  paddingLeft: '16px',paddingTop: '16px',flexGrow: 1,color:'#fff' }}>
                       QR Code
                   </Typography>
                   
               </div>
              
           </div>
           <Divider sx={{backgroundColor:'white'}} />

           <img style={{
            display :'block',
            width : '50%',
            height : '50%',
            margin : '2rem auto'
           }} src={qr}>
           </img>
           <Button variant="contained"  style={{
            display :'block',
            width : '50%',
            height : '50%',
            margin : '2rem auto',
            textAlign : 'center',
            marginTop:'0rem',
            button: {
                textTransform: "none"
              },
            fontWeight: '700',
            fontSize: '0.93rem',
            
            
            alignItems: 'center',
            color: '#FFFFFF',
            fontFamily: "Lato",
            borderRadius:'0.31rem',
           '&:hover': {
            backgroundColor: 'rgba(109, 195, 252, 1)',
           }
           }} href={qr} download="qrcode.png">Download</Button>
</>)}


{(currentTab == 5 && dataOfLocation.cam_brand == "ResidentLink" && <>
<div  sx={{
       right: '54px' ,left: '54px',borderRadius:'0px'}}>
               <div style={{ display: 'flex' }}>
                   <Typography variant="h6" component="div" style={{  paddingLeft: '16px',paddingTop: '16px',flexGrow: 1,color:'#fff' }}>
                       Elevator Control Settings
                   </Typography>
                   
               </div>
              
           </div>
           <Divider sx={{backgroundColor:'white'}} />
           <Box sx={{display: { xs: "none", md: "block" }}}>
      <Grid container spacing={2} padding= '16px'> 
      <Grid item xs={3}>
      <Stack direction="column"  spacing={1}>
      <Typography  variant="h6" component="div" style={{ flexGrow: 1,color:'#fff' }}>Elevator Control Device </Typography>
      </Stack>
      </Grid>
      <Grid item xs={2}>
      <Stack direction="column"  spacing={2}>
      <FormControlLabel  sx={mainThemeStyle.popUpLable}
        control={<IOSSwitch sx={{ m: 1 }} onChange={handleChangeEventOfElevatorControl} disabled={getDisableValue("183")}  checked={dataOfLocation.elevator_access.elevatorcontrol == "1" ? true : false}/>}
        
      />
      </Stack>
      </Grid>
      </Grid>
      
      {dataOfLocation.elevator_access.elevatorcontrol == 1 && 
      <Grid container spacing={2} padding= '16px'> 
      <Grid item xs={2.5}>
      <Stack direction="column"  spacing={2}>
      <Typography  sx={mainThemeStyle.popUpLable} height={40}>Elevator Controller IP Address</Typography>
      <Typography  sx={mainThemeStyle.popUpLable} height={40}># of System Relays</Typography>
      <Typography  sx={mainThemeStyle.popUpLable} height={40}>Call Elevator Relay Duration</Typography>
      <Typography  sx={mainThemeStyle.popUpLable} height={40}>Floor Release Relay Duration</Typography>
      </Stack>
      </Grid>
      <Grid item xs={2.5}>
      <Stack direction="column"  spacing={2}>
      <TextField
        id="elevtaor-ip-address"
        type="text"
        value={dataOfLocation.elevator_access.ipaddress}
        sx={mainThemeStyle.InputMenuItem}
                        InputLabelProps={{ shrink: true }
                      }
                      disabled={getDisableValue("183")}
                      name="ipaddress"
                      onChange={handleChangeEleValue}
                      variant="filled" 
                        InputProps={{
                           
                            sx: {
                                
                                color:'rgba(135, 135, 135, 0.5)',
                                height:'33px',
                                borderRadius: '5px',
                                backgroundColor: '#121212',
                                boxShadow: 'inset 0px 4px 4px rgba(0, 0, 0, 0.25)',
                                padding: '0 0 15px 0',
                                textIndent: '15px !important',
                              
                                '&.Mui-disabled': {
             

                                  background: 'rgba(135, 135, 135, 0.5)',
                                },
                                
                                "& input.Mui-disabled": {
                                  color: "white"
                                }
                                
                            },
                        }}
                       
      />
     <TextField
        id="total-relays"
        type="number"
        value={dataOfLocation.elevator_access.totalrelays}
        sx={mainThemeStyle.InputMenuItem}
                        InputLabelProps={{ shrink: true }
                      }
                      disabled={getDisableValue("183")}
                      name="totalrelays"
                      onChange={handleChangeEleValue}
                      variant="filled"
                        InputProps={{
                          inputProps: { 
                            max: 66, min: 0 
                        },
                            sx: {
                              
                                color:'rgba(135, 135, 135, 0.5)',
                                height:'33px',
                                borderRadius: '5px',
                                backgroundColor: '#121212',
                                boxShadow: 'inset 0px 4px 4px rgba(0, 0, 0, 0.25)',
                                padding: '0 0 15px 0',
                                textIndent: '15px !important',
                                '&.Mui-disabled': {
             

                                  background: 'rgba(135, 135, 135, 0.5)',
                                },
                                
                                "& input.Mui-disabled": {
                                  color: "white"
                                }
                               
                                
                            },
                        }}
                       
      />
      <TextField
        id="call-elevator-duration"
        type="number"
        value={dataOfLocation.elevator_access.elduration}
        name="elduration"
        onChange={handleChangeEleValue}
        sx={mainThemeStyle.InputMenuItem}
                        InputLabelProps={{ shrink: true }
                      }
                      disabled={getDisableValue("183")}
                      variant="filled" 
                        InputProps={{
                           
                            sx: {
                                
                                color:'rgba(135, 135, 135, 0.5)',
                                height:'33px',
                                borderRadius: '5px',
                                backgroundColor: '#121212',
                                boxShadow: 'inset 0px 4px 4px rgba(0, 0, 0, 0.25)',
                                padding: '0 0 15px 0',
                                textIndent: '15px !important',
                              
                                '&.Mui-disabled': {
             

                                  background: 'rgba(135, 135, 135, 0.5)',
                                },
                                
                                "& input.Mui-disabled": {
                                  color: "white"
                                }
                                
                            },
                        }}
                       
      />
      <TextField
        id="text"
        type="number"
        value={dataOfLocation.elevator_access.flduration}
        name="flduration"
        disabled={getDisableValue("183")}
        onChange={handleChangeEleValue}
        sx={mainThemeStyle.InputMenuItem}
                        InputLabelProps={{ shrink: true }
                      }
                      variant="filled"       
                        InputProps={{
                           
                            sx: {
                                
                                color:'rgba(135, 135, 135, 0.5)',
                                height:'33px',
                                borderRadius: '5px',
                                backgroundColor: '#121212',
                                boxShadow: 'inset 0px 4px 4px rgba(0, 0, 0, 0.25)',
                                padding: '0 0 15px 0',
                                textIndent: '15px !important',
                              
                                '&.Mui-disabled': {
             

                                  background: 'rgba(135, 135, 135, 0.5)',
                                },
                                
                                "& input.Mui-disabled": {
                                  color: "white"
                                }
                                
                            },
                        }}
                       
      />
        
      </Stack>
      </Grid>
      <Grid item xs={2}></Grid>
      <Grid item xs={3}>
      <Stack direction="column"  spacing={2}>
      <Typography></Typography>
      <Typography  sx={mainThemeStyle.popUpLable} height={40}>Disable Call Elevator</Typography>
      <Typography  sx={mainThemeStyle.popUpLable} height={40}>Disable Floor Release</Typography>
      <Typography  sx={mainThemeStyle.popUpLable} height={40}>Disable Elevator Control For App Unlock</Typography>
      </Stack>
      </Grid>
      <Grid item xs={2}>
      <Stack direction="column"  spacing={2}>
      <Typography></Typography>
      <FormControlLabel  sx={mainThemeStyle.popUpLable}
        control={<IOSSwitch sx={{ m: 1 }} name="diselevator"  disabled={getDisableValue("183")} onChange={handleChangeEleCheckValue} checked={dataOfLocation.elevator_access.diselevator == "1" ? true : false} />}
        label=""
      />
       <FormControlLabel  sx={mainThemeStyle.popUpLable}
        control={<IOSSwitch sx={{ m: 1 }} name="disrelease" disabled={getDisableValue("183")} onChange={handleChangeEleCheckValue} checked={dataOfLocation.elevator_access.disrelease == "1" ? true : false} />}
        label=""
      />
       <FormControlLabel  sx={mainThemeStyle.popUpLable}
        control={<IOSSwitch sx={{ m: 1 }} name="diselapp" disabled={getDisableValue("183")} onChange={handleChangeEleCheckValue} checked={dataOfLocation.elevator_access.diselapp == "1" ? true : false} />}
        label=""
      />
    {/*   <Checkbox  sx={mainThemeStyle.formCheckBox} name="diselevator" onChange={handleChangeEleCheckValue} checked={dataOfLocation.elevator_access.diselevator == "1" ? true : false}/>
      <Checkbox  sx={mainThemeStyle.formCheckBox} name="disrelease" onChange={handleChangeEleCheckValue} checked={dataOfLocation.elevator_access.disrelease == "1" ? true : false}/>
      <Checkbox  sx={mainThemeStyle.formCheckBox} name="diselapp" onChange={handleChangeEleCheckValue} checked={dataOfLocation.elevator_access.diselapp == "1" ? true : false}/>
       */}
     
        
      </Stack>
      </Grid>
      </Grid>}
      {dataOfLocation.elevator_access.elevatorcontrol == 1 && 
      <TableContainer component={Paper} sx={{
        width:'auto',
        height: 'auto',
        marginLeft: '38px',
        marginRight:'38px',
        marginBottom:'38px',
        backgroundColor:'#171E29',
       
        }}>
      <Table sx={{ minWidth: 650 }} >
        
        <TableHead sx={{ borderBottom: "1.5px solid black",
        }}>
          <TableRow>
            
            <TableCell align="center" sx={mainThemeStyle.popUpLable} colSpan={16}>Elevator Relay Programming</TableCell>
            
          </TableRow>
         
        </TableHead>

        
        <TableHead sx={{ borderBottom: "1.5px solid black",
        }}>
          <TableRow>
          <TableCell align="right" sx={mainThemeStyle.cellHeader}> Relay</TableCell>
            <TableCell align="right" sx={mainThemeStyle.cellHeader}>Floor</TableCell>
            <TableCell align="right" sx={mainThemeStyle.cellHeader}> Relay</TableCell>
            <TableCell align="right" sx={mainThemeStyle.cellHeader}>Floor</TableCell>
            <TableCell align="right" sx={mainThemeStyle.cellHeader}> Relay</TableCell>
            <TableCell align="right" sx={mainThemeStyle.cellHeader}>Floor</TableCell>
            <TableCell align="right" sx={mainThemeStyle.cellHeader}> Relay</TableCell>
            <TableCell align="right" sx={mainThemeStyle.cellHeader}>Floor</TableCell>
            <TableCell align="right" sx={mainThemeStyle.cellHeader}> Relay</TableCell>
            <TableCell align="right" sx={mainThemeStyle.cellHeader}>Floor</TableCell>
            <TableCell align="right" sx={mainThemeStyle.cellHeader}> Relay</TableCell>
            <TableCell align="right" sx={mainThemeStyle.cellHeader}>Floor</TableCell>
            <TableCell align="right" sx={mainThemeStyle.cellHeader}> Relay</TableCell>
            <TableCell align="right" sx={mainThemeStyle.cellHeader}>Floor</TableCell>
            <TableCell align="right" sx={mainThemeStyle.cellHeader}> Relay</TableCell>
            <TableCell align="right" sx={mainThemeStyle.cellHeader}>Floor</TableCell>
          </TableRow></TableHead>
        <TableBody>
         {rowsElevatorControl.map((row) => (
            <TableRow sx={{ borderBottom: "1.5px solid black",
          }}>
              <TableCell sx={mainThemeStyle.cellRow}>
              {parseInt(row*8)+1}
              </TableCell>
              <TableCell align="right"> <TextField
        id="text"
        type="text"
        value={dataOfLocation.elevator_access.program[parseInt(row*8)]}
        name={parseInt(row*8)}
        onChange={handleChangeEleInnerRelay}
       
        disabled={getDisableValue("183") == false ? (dataOfLocation.elevator_access.totalrelays < (parseInt(row*8)+1) ? true : false) : true}
                        InputLabelProps={{ shrink: true }
                      }
                      variant="filled"   
                        InputProps={{
                          inputProps: { 
                            maxLength:2,
                        },
                            sx: {
                                
                                color:'rgba(135, 135, 135, 0.5)',
                                height:'25px',
                                borderRadius: '5px',
                                backgroundColor: '#121212',
                                boxShadow: 'inset 0px 4px 4px rgba(0, 0, 0, 0.25)',
                                padding: '0 0 15px 0',
                                textIndent: '15px !important',
                                '&.Mui-disabled': {
             

                                  background: 'rgba(135, 135, 135, 0.5)',
                                },
                                
                                "& input.Mui-disabled": {
                                  color: "white"
                                }
                               
                                
                            },
                        }}
                       
      /></TableCell>
              <TableCell sx={mainThemeStyle.cellRow}>
              {parseInt(row*8)+2}
              </TableCell>
              <TableCell align="right"> <TextField
        id="text"
        type="text"
        value={dataOfLocation.elevator_access.program[(row*8)+1]}
        name={(row*8)+1}
        onChange={handleChangeEleInnerRelay}
        disabled={getDisableValue("183") == false ? (dataOfLocation.elevator_access.totalrelays < (parseInt(row*8)+2) ? true : false) : true}
        variant="filled"   
                        InputLabelProps={{ shrink: true }
                      }
                          
                        InputProps={{
                          inputProps: { 
                            maxLength:2,
                        },
                            sx: {
                                
                                color:'rgba(135, 135, 135, 0.5)',
                                height:'25px',
                                borderRadius: '5px',
                                backgroundColor: '#121212',
                                boxShadow: 'inset 0px 4px 4px rgba(0, 0, 0, 0.25)',
                                padding: '0 0 15px 0',
                                textIndent: '15px !important',
                              
                                '&.Mui-disabled': {
             

                                  background: 'rgba(135, 135, 135, 0.5)',
                                },
                                
                                "& input.Mui-disabled": {
                                  color: "white"
                                }
                                
                            },
                        }}
                       
      /></TableCell>
      <TableCell sx={mainThemeStyle.cellRow}>
              {parseInt(row*8)+3}
              </TableCell>
              <TableCell align="right"> <TextField
        id="text"
        type="text"
        variant="filled"   
        value={dataOfLocation.elevator_access.program[(row*8)+2]}
        name={(row*8)+2}
        onChange={handleChangeEleInnerRelay}
        disabled={getDisableValue("183") == false ? (dataOfLocation.elevator_access.totalrelays < (parseInt(row*8)+3) ? true : false) : true}
                        InputLabelProps={{ shrink: true }
                      }
                          
                        InputProps={{
                          inputProps: { 
                            maxLength:2,
                        },
                            sx: {
                                
                                color:'rgba(135, 135, 135, 0.5)',
                                height:'25px',
                                borderRadius: '5px',
                                backgroundColor: '#121212',
                                boxShadow: 'inset 0px 4px 4px rgba(0, 0, 0, 0.25)',
                                padding: '0 0 15px 0',
                                textIndent: '15px !important',
                              
                               
                                '&.Mui-disabled': {
             

                                  background: 'rgba(135, 135, 135, 0.5)',
                                },
                                
                                "& input.Mui-disabled": {
                                  color: "white"
                                }
                            },
                        }}
                       
      /></TableCell>
      <TableCell sx={mainThemeStyle.cellRow}>
              {(row*8)+4}
              </TableCell>
              <TableCell align="right"> <TextField
        id="text"
        type="text"
        variant="filled"   
        value={dataOfLocation.elevator_access.program[(row*8)+3]}
        name={(row*8)+3}
        onChange={handleChangeEleInnerRelay}                
        disabled={getDisableValue("183") == false ? (dataOfLocation.elevator_access.totalrelays < (parseInt(row*8)+4) ? true : false) : true}
        InputLabelProps={{ shrink: true }
                      }
                          
                        InputProps={{
                          inputProps: { 
                            maxLength:2,
                        },
                            sx: {
                                
                                color:'rgba(135, 135, 135, 0.5)',
                                height:'25px',
                                borderRadius: '5px',
                                backgroundColor: '#121212',
                                boxShadow: 'inset 0px 4px 4px rgba(0, 0, 0, 0.25)',
                                padding: '0 0 15px 0',
                                textIndent: '15px !important',
                              
                                '&.Mui-disabled': {
             

                                  background: 'rgba(135, 135, 135, 0.5)',
                                },
                                
                                "& input.Mui-disabled": {
                                  color: "white"
                                }
                                
                            },
                        }}
                       
      /></TableCell>
      <TableCell sx={mainThemeStyle.cellRow}>
              {(row*8)+5}
              </TableCell>
              <TableCell align="right"> <TextField
        id="text"
        type="text"
        variant="filled"   
        value={dataOfLocation.elevator_access.program[(row*8)+4]}
        name={(row*8)+4}
        disabled={getDisableValue("183") == false ? (dataOfLocation.elevator_access.totalrelays < (parseInt(row*8)+5) ? true : false) : true}
        onChange={handleChangeEleInnerRelay}   
                        InputLabelProps={{ shrink: true }
                      }
                          
                        InputProps={{
                          inputProps: { 
                            maxLength:2,
                        },
                            sx: {
                                
                                color:'rgba(135, 135, 135, 0.5)',
                                height:'25px',
                                borderRadius: '5px',
                                backgroundColor: '#121212',
                                boxShadow: 'inset 0px 4px 4px rgba(0, 0, 0, 0.25)',
                                padding: '0 0 15px 0',
                                textIndent: '15px !important',
                              
                                '&.Mui-disabled': {
             

                                  background: 'rgba(135, 135, 135, 0.5)',
                                },
                                
                                "& input.Mui-disabled": {
                                  color: "white"
                                }
                                
                            },
                        }}
                       
      /></TableCell>
      <TableCell sx={mainThemeStyle.cellRow}>
              {(row*8)+6}
              </TableCell>
              <TableCell align="right"> <TextField
        id="text"
        type="text"
        value={dataOfLocation.elevator_access.program[(row*8)+5]}
        name={(row*8)+5}
        disabled={getDisableValue("183") == false ? (dataOfLocation.elevator_access.totalrelays < (parseInt(row*8)+6) ? true : false) : true}
        onChange={handleChangeEleInnerRelay}   
        variant="filled" 
                        InputLabelProps={{ shrink: true }
                      }
                          
                        InputProps={{
                          inputProps: { 
                            maxLength:2,
                        },
                            sx: {
                                
                                color:'rgba(135, 135, 135, 0.5)',
                                height:'25px',
                                borderRadius: '5px',
                                backgroundColor: '#121212',
                                boxShadow: 'inset 0px 4px 4px rgba(0, 0, 0, 0.25)',
                                padding: '0 0 15px 0',
                                textIndent: '15px !important',
                                '&.Mui-disabled': {
             

                                  background: 'rgba(135, 135, 135, 0.5)',
                                },
                                
                                "& input.Mui-disabled": {
                                  color: "white"
                                }
                               
                                
                            },
                        }}
                       
      /></TableCell>
      <TableCell sx={mainThemeStyle.cellRow}>
              {(row*8)+7}
              </TableCell>
              <TableCell align="right"> <TextField
        id="text"
        type="text"
        variant="filled" 
        value={ dataOfLocation.elevator_access.program[(row*8)+6]}
        name={(row*8)+6}
        disabled={getDisableValue("183") == false ? (dataOfLocation.elevator_access.totalrelays < (parseInt(row*8)+7) ? true : false) : true}
        onChange={handleChangeEleInnerRelay}  
                        InputLabelProps={{ shrink: true }
                      }
                          
                        InputProps={{
                          inputProps: { 
                            maxLength:2,
                        },
                            sx: {
                                
                                color:'rgba(135, 135, 135, 0.5)',
                                height:'25px',
                                borderRadius: '5px',
                                backgroundColor: '#121212',
                                boxShadow: 'inset 0px 4px 4px rgba(0, 0, 0, 0.25)',
                                padding: '0 0 15px 0',
                                textIndent: '15px !important',
                                '&.Mui-disabled': {
             

                                  background: 'rgba(135, 135, 135, 0.5)',
                                },
                                
                                "& input.Mui-disabled": {
                                  color: "white"
                                }
                               
                                
                            },
                        }}
                       
      /></TableCell>
      <TableCell sx={mainThemeStyle.cellRow}>
              {(row*8)+8}
              </TableCell>
              <TableCell align="right"> <TextField
        id="text"
        type="text"
        variant="filled" 
        value={dataOfLocation.elevator_access.program[(row*8)+7]}
        name={(row*8)+7}
        onChange={handleChangeEleInnerRelay}  
        disabled={getDisableValue("183") == false ? (dataOfLocation.elevator_access.totalrelays < (parseInt(row*8)+8) ? true : false) : true}
                        InputLabelProps={{ shrink: true }
                      }
                          
                        InputProps={{
                          inputProps: { 
                            maxLength:2,
                        },
                         
                            sx: {
                                
                                color:'rgba(135, 135, 135, 0.5)',
                                height:'25px',
                                borderRadius: '5px',
                                backgroundColor: '#121212',
                                boxShadow: 'inset 0px 4px 4px rgba(0, 0, 0, 0.25)',
                                padding: '0 0 15px 0',
                                textIndent: '15px !important',
                                '&.Mui-disabled': {
             

                                  background: 'rgba(135, 135, 135, 0.5)',
                                },
                                
                                "& input.Mui-disabled": {
                                  color: "white"
                                }
                               
                                
                            },
                        }}
                       
      /></TableCell>
            </TableRow>
          ))}
        </TableBody>
      </Table>
    </TableContainer>}
    </Box>
    <Box sx={{display: { xs: "block", md: "none" }}}>
    <Grid container spacing={2} padding= '10px' paddingBottom='32px' paddingTop='32px'> 
      <Grid item xs={8}>
      <Stack direction="column"  spacing={1}>
      <Typography  variant="h6" component="div" style={{ flexGrow: 1,color:'#fff' }}>Elevator Control Device </Typography>
      </Stack>
      </Grid>
      <Grid item xs={2}>
      <Stack direction="column"  spacing={2}>
      <FormControlLabel  sx={mainThemeStyle.popUpLable}
        control={<IOSSwitch sx={{ m: 1 }} onChange={handleChangeEventOfElevatorControl} disabled={getDisableValue("183")} checked={dataOfLocation.elevator_access.elevatorcontrol == "1" ? true : false}/>}
        
      />
      </Stack>
      </Grid>
      </Grid>
      
      {dataOfLocation.elevator_access.elevatorcontrol == 1 && 
     <Grid container spacing={2} padding= '10px' paddingBottom='16px' paddingTop='16px'> 
     <Grid item xs={6}>
      <Stack direction="column"  spacing={2}>
      <Typography  sx={mainThemeStyle.popUpLable} height={40}>Elevator IP Address</Typography>
      <Typography  sx={mainThemeStyle.popUpLable} height={40}># of System Relays</Typography>
      <Typography  sx={mainThemeStyle.popUpLable} height={40}>Call Elevator Relay Duration</Typography>
      <Typography  sx={mainThemeStyle.popUpLable} height={40}>Floor Release Relay Duration</Typography>
      <Typography  sx={mainThemeStyle.popUpLable} height={40}>Disable Call Elevator</Typography>
      <Typography  sx={mainThemeStyle.popUpLable} height={40}>Disable Floor Release</Typography>
      <Typography  sx={mainThemeStyle.popUpLable} height={40}>Disable Elevator Control For App Unlock</Typography>
      </Stack>
      </Grid>
      <Grid item xs={6}>
      <Stack direction="column"  spacing={2}>
      <TextField
        id="elevtaor-ip-address"
        type="text"
        value={dataOfLocation.elevator_access.ipaddress}
        sx={mainThemeStyle.InputMenuItem}
                        InputLabelProps={{ shrink: true }
                      }
                      name="ipaddress"
                      onChange={handleChangeEleValue}
                      variant="filled" 
                      disabled={getDisableValue("183")}
                        InputProps={{
                           
                            sx: {
                                
                                color:'rgba(135, 135, 135, 0.5)',
                                height:'33px',
                                borderRadius: '5px',
                                backgroundColor: '#121212',
                                boxShadow: 'inset 0px 4px 4px rgba(0, 0, 0, 0.25)',
                                padding: '0 0 15px 0',
                                textIndent: '15px !important',
                                '&.Mui-disabled': {
             

                                  background: 'rgba(135, 135, 135, 0.5)',
                                },
                                
                                "& input.Mui-disabled": {
                                  color: "white"
                                }
                               
                                
                            },
                        }}
                       
      />
     <TextField
        id="total-relays"
        type="number"
        value={dataOfLocation.elevator_access.totalrelays}
        sx={mainThemeStyle.InputMenuItem}
                        InputLabelProps={{ shrink: true }
                      }
                      name="totalrelays"
                      disabled={getDisableValue("183")}
                      onChange={handleChangeEleValue}
                      variant="filled"
                        InputProps={{
                          inputProps: { 
                            max: 66, min: 0 
                        },
                            sx: {
                              
                                color:'rgba(135, 135, 135, 0.5)',
                                height:'33px',
                                borderRadius: '5px',
                                backgroundColor: '#121212',
                                boxShadow: 'inset 0px 4px 4px rgba(0, 0, 0, 0.25)',
                                padding: '0 0 15px 0',
                                textIndent: '15px !important',
                              
                                '&.Mui-disabled': {
             

                                  background: 'rgba(135, 135, 135, 0.5)',
                                },
                                
                                "& input.Mui-disabled": {
                                  color: "white"
                                }
                                
                            },
                        }}
                       
      />
      <TextField
        id="call-elevator-duration"
        type="number"
        value={dataOfLocation.elevator_access.elduration}
        name="elduration"
        onChange={handleChangeEleValue}
        sx={mainThemeStyle.InputMenuItem}
                        InputLabelProps={{ shrink: true }
                      }
                      disabled={getDisableValue("183")}
                      variant="filled" 
                        InputProps={{
                           
                            sx: {
                                
                                color:'rgba(135, 135, 135, 0.5)',
                                height:'33px',
                                borderRadius: '5px',
                                backgroundColor: '#121212',
                                boxShadow: 'inset 0px 4px 4px rgba(0, 0, 0, 0.25)',
                                padding: '0 0 15px 0',
                                textIndent: '15px !important',
                                '&.Mui-disabled': {
             

                                  background: 'rgba(135, 135, 135, 0.5)',
                                },
                                
                                "& input.Mui-disabled": {
                                  color: "white"
                                }
                               
                                
                            },
                        }}
                       
      />
      <TextField
        id="text"
        type="number"
        value={dataOfLocation.elevator_access.flduration}
        name="flduration"
        onChange={handleChangeEleValue}
        sx={mainThemeStyle.InputMenuItem}
                        InputLabelProps={{ shrink: true }
                      }
                      disabled={getDisableValue("183")}
                      variant="filled"       
                        InputProps={{
                           
                            sx: {
                                
                                color:'rgba(135, 135, 135, 0.5)',
                                height:'33px',
                                borderRadius: '5px',
                                backgroundColor: '#121212',
                                boxShadow: 'inset 0px 4px 4px rgba(0, 0, 0, 0.25)',
                                padding: '0 0 15px 0',
                                textIndent: '15px !important',
                                '&.Mui-disabled': {
             

                                  background: 'rgba(135, 135, 135, 0.5)',
                                },
                                
                                "& input.Mui-disabled": {
                                  color: "white"
                                }
                               
                                
                            },
                        }}
                       
      />
        <FormControlLabel  sx={mainThemeStyle.popUpLable}
        control={<IOSSwitch sx={{ m: 1 }} name="diselevator" disabled={getDisableValue("183")} onChange={handleChangeEleCheckValue} checked={dataOfLocation.elevator_access.diselevator == "1" ? true : false} />}
        label=""
      />
       <FormControlLabel  sx={mainThemeStyle.popUpLable}
        control={<IOSSwitch sx={{ m: 1 }} name="disrelease" disabled={getDisableValue("183")} onChange={handleChangeEleCheckValue} checked={dataOfLocation.elevator_access.disrelease == "1" ? true : false} />}
        label=""
      />
       <FormControlLabel  sx={mainThemeStyle.popUpLable}
        control={<IOSSwitch sx={{ m: 1 }} name="diselapp" disabled={getDisableValue("183")} onChange={handleChangeEleCheckValue} checked={dataOfLocation.elevator_access.diselapp == "1" ? true : false} />}
        label=""
      />
      </Stack>
      </Grid>
      
      </Grid>}
      {dataOfLocation.elevator_access.elevatorcontrol == 1 && 
      <TableContainer component={Paper} sx={{
        width:'auto',
        height: 'auto',
        marginLeft: '10px',
        marginRight:'10px',
        marginBottom:'38px',
        backgroundColor:'#171E29',
       
        }}>
      <Table sx={{ minWidth: 650 }} >
        
        <TableHead sx={{ borderBottom: "1.5px solid black",
        }}>
          <TableRow>
            
            <TableCell align="center" sx={mainThemeStyle.popUpLable} colSpan={16}>Elevator Relay Programming</TableCell>
            
          </TableRow>
         
        </TableHead>

        
        <TableHead sx={{ borderBottom: "1.5px solid black",
        }}>
          <TableRow>
          <TableCell align="right" sx={mainThemeStyle.cellHeader}> Relay</TableCell>
            <TableCell align="right" sx={mainThemeStyle.cellHeader}>Floor</TableCell>
            <TableCell align="right" sx={mainThemeStyle.cellHeader}> Relay</TableCell>
            <TableCell align="right" sx={mainThemeStyle.cellHeader}>Floor</TableCell>
            <TableCell align="right" sx={mainThemeStyle.cellHeader}> Relay</TableCell>
            <TableCell align="right" sx={mainThemeStyle.cellHeader}>Floor</TableCell>
            <TableCell align="right" sx={mainThemeStyle.cellHeader}> Relay</TableCell>
            <TableCell align="right" sx={mainThemeStyle.cellHeader}>Floor</TableCell>
            <TableCell align="right" sx={mainThemeStyle.cellHeader}> Relay</TableCell>
            <TableCell align="right" sx={mainThemeStyle.cellHeader}>Floor</TableCell>
            <TableCell align="right" sx={mainThemeStyle.cellHeader}> Relay</TableCell>
            <TableCell align="right" sx={mainThemeStyle.cellHeader}>Floor</TableCell>
            <TableCell align="right" sx={mainThemeStyle.cellHeader}> Relay</TableCell>
            <TableCell align="right" sx={mainThemeStyle.cellHeader}>Floor</TableCell>
            <TableCell align="right" sx={mainThemeStyle.cellHeader}> Relay</TableCell>
            <TableCell align="right" sx={mainThemeStyle.cellHeader}>Floor</TableCell>
          </TableRow></TableHead>
        <TableBody>
         {rowsElevatorControl.map((row) => (
            <TableRow sx={{ borderBottom: "1.5px solid black",
          }}>
              <TableCell sx={mainThemeStyle.cellRow}>
              {parseInt(row*8)+1}
              </TableCell>
              <TableCell align="right"> <TextField
        id="text"
        type="text"
        value={dataOfLocation.elevator_access.program[parseInt(row*8)]}
        name={parseInt(row*8)}
        onChange={handleChangeEleInnerRelay}
        
        disabled={getDisableValue("183") == false ? (dataOfLocation.elevator_access.totalrelays < (parseInt(row*8)+1) ? true : false) : true}
                        InputLabelProps={{ shrink: true }
                      }
                      variant="filled"   
                        InputProps={{
                          inputProps: { 
                            maxLength:2,
                        },
                            sx: {
                                
                                color:'rgba(135, 135, 135, 0.5)',
                                height:'25px',
                                borderRadius: '5px',
                                backgroundColor: '#121212',
                                boxShadow: 'inset 0px 4px 4px rgba(0, 0, 0, 0.25)',
                                padding: '0 0 15px 0',
                                textIndent: '15px !important',
                              
                                '&.Mui-disabled': {
             

                                  background: 'rgba(135, 135, 135, 0.5)',
                                },
                                
                                "& input.Mui-disabled": {
                                  color: "white"
                                }
                                
                            },
                        }}
                       
      /></TableCell>
              <TableCell sx={mainThemeStyle.cellRow}>
              {parseInt(row*8)+2}
              </TableCell>
              <TableCell align="right"> <TextField
        id="text"
        type="text"
        value={dataOfLocation.elevator_access.program[(row*8)+1]}
        name={(row*8)+1}
        onChange={handleChangeEleInnerRelay}
        disabled={getDisableValue("183") == false ? (dataOfLocation.elevator_access.totalrelays < (parseInt(row*8)+2) ? true : false) : true}
        variant="filled"   
                        InputLabelProps={{ shrink: true }
                      }
                          
                        InputProps={{
                          inputProps: { 
                            maxLength:2,
                        },
                            sx: {
                                
                                color:'rgba(135, 135, 135, 0.5)',
                                height:'25px',
                                borderRadius: '5px',
                                backgroundColor: '#121212',
                                boxShadow: 'inset 0px 4px 4px rgba(0, 0, 0, 0.25)',
                                padding: '0 0 15px 0',
                                textIndent: '15px !important',
                              
                                '&.Mui-disabled': {
             

                                  background: 'rgba(135, 135, 135, 0.5)',
                                },
                                
                                "& input.Mui-disabled": {
                                  color: "white"
                                }
                                
                            },
                        }}
                       
      /></TableCell>
      <TableCell sx={mainThemeStyle.cellRow}>
              {parseInt(row*8)+3}
              </TableCell>
              <TableCell align="right"> <TextField
        id="text"
        type="text"
        variant="filled"   
        value={dataOfLocation.elevator_access.program[(row*8)+2]}
        name={(row*8)+2}
        onChange={handleChangeEleInnerRelay}
        disabled={getDisableValue("183") == false ? (dataOfLocation.elevator_access.totalrelays < (parseInt(row*8)+3) ? true : false) : true}
                        InputLabelProps={{ shrink: true }
                      }
                          
                        InputProps={{
                          inputProps: { 
                            maxLength:2,
                        },
                            sx: {
                                
                                color:'rgba(135, 135, 135, 0.5)',
                                height:'25px',
                                borderRadius: '5px',
                                backgroundColor: '#121212',
                                boxShadow: 'inset 0px 4px 4px rgba(0, 0, 0, 0.25)',
                                padding: '0 0 15px 0',
                                textIndent: '15px !important',
                              
                                '&.Mui-disabled': {
             

                                  background: 'rgba(135, 135, 135, 0.5)',
                                },
                                
                                "& input.Mui-disabled": {
                                  color: "white"
                                }
                                
                            },
                        }}
                       
      /></TableCell>
      <TableCell sx={mainThemeStyle.cellRow}>
              {(row*8)+4}
              </TableCell>
              <TableCell align="right"> <TextField
        id="text"
        type="text"
        variant="filled"   
        value={dataOfLocation.elevator_access.program[(row*8)+3]}
        name={(row*8)+3}
        onChange={handleChangeEleInnerRelay}                
        disabled={getDisableValue("183") == false ? (dataOfLocation.elevator_access.totalrelays < (parseInt(row*8)+4) ? true : false) : true}
        InputLabelProps={{ shrink: true }
                      }
                          
                        InputProps={{
                          inputProps: { 
                            maxLength:2,
                        },
                            sx: {
                                
                                color:'rgba(135, 135, 135, 0.5)',
                                height:'25px',
                                borderRadius: '5px',
                                backgroundColor: '#121212',
                                boxShadow: 'inset 0px 4px 4px rgba(0, 0, 0, 0.25)',
                                padding: '0 0 15px 0',
                                textIndent: '15px !important',
                                '&.Mui-disabled': {
             

                                  background: 'rgba(135, 135, 135, 0.5)',
                                },
                                
                                "& input.Mui-disabled": {
                                  color: "white"
                                }
                               
                                
                            },
                        }}
                       
      /></TableCell>
      <TableCell sx={mainThemeStyle.cellRow}>
              {(row*8)+5}
              </TableCell>
              <TableCell align="right"> <TextField
        id="text"
        type="text"
        variant="filled"   
        value={dataOfLocation.elevator_access.program[(row*8)+4]}
        name={(row*8)+4}
        disabled={getDisableValue("183") == false ? (dataOfLocation.elevator_access.totalrelays < (parseInt(row*8)+5) ? true : false) : true}
        onChange={handleChangeEleInnerRelay}   
                        InputLabelProps={{ shrink: true }
                      }
                          
                        InputProps={{
                          inputProps: { 
                            maxLength:2,
                        },
                            sx: {
                                
                                color:'rgba(135, 135, 135, 0.5)',
                                height:'25px',
                                borderRadius: '5px',
                                backgroundColor: '#121212',
                                boxShadow: 'inset 0px 4px 4px rgba(0, 0, 0, 0.25)',
                                padding: '0 0 15px 0',
                                textIndent: '15px !important',
                              
                               
                                '&.Mui-disabled': {
             

                                  background: 'rgba(135, 135, 135, 0.5)',
                                },
                                
                                "& input.Mui-disabled": {
                                  color: "white"
                                }
                            },
                        }}
                       
      /></TableCell>
      <TableCell sx={mainThemeStyle.cellRow}>
              {(row*8)+6}
              </TableCell>
              <TableCell align="right"> <TextField
        id="text"
        type="text"
        value={dataOfLocation.elevator_access.program[(row*8)+5]}
        name={(row*8)+5}
        disabled={getDisableValue("183") == false ? (dataOfLocation.elevator_access.totalrelays < (parseInt(row*8)+6) ? true : false) : true}
        onChange={handleChangeEleInnerRelay}   
        variant="filled" 
                        InputLabelProps={{ shrink: true }
                      }
                          
                        InputProps={{
                          inputProps: { 
                            maxLength:2,
                        },
                            sx: {
                                
                                color:'rgba(135, 135, 135, 0.5)',
                                height:'25px',
                                borderRadius: '5px',
                                backgroundColor: '#121212',
                                boxShadow: 'inset 0px 4px 4px rgba(0, 0, 0, 0.25)',
                                padding: '0 0 15px 0',
                                textIndent: '15px !important',
                                '&.Mui-disabled': {
             

                                  background: 'rgba(135, 135, 135, 0.5)',
                                },
                                
                                "& input.Mui-disabled": {
                                  color: "white"
                                }
                               
                                
                            },
                        }}
                       
      /></TableCell>
      <TableCell sx={mainThemeStyle.cellRow}>
              {(row*8)+7}
              </TableCell>
              <TableCell align="right"> <TextField
        id="text"
        type="text"
        variant="filled" 
        value={ dataOfLocation.elevator_access.program[(row*8)+6]}
        name={(row*8)+6}
        disabled={getDisableValue("183") == false ? (dataOfLocation.elevator_access.totalrelays < (parseInt(row*8)+7) ? true : false) : true}
        onChange={handleChangeEleInnerRelay}  
                        InputLabelProps={{ shrink: true }
                      }
                          
                        InputProps={{
                          inputProps: { 
                            maxLength:2,
                        },
                            sx: {
                                
                                color:'rgba(135, 135, 135, 0.5)',
                                height:'25px',
                                borderRadius: '5px',
                                backgroundColor: '#121212',
                                boxShadow: 'inset 0px 4px 4px rgba(0, 0, 0, 0.25)',
                                padding: '0 0 15px 0',
                                textIndent: '15px !important',
                              
                               
                                '&.Mui-disabled': {
             

                                  background: 'rgba(135, 135, 135, 0.5)',
                                },
                                
                                "& input.Mui-disabled": {
                                  color: "white"
                                }
                            },
                        }}
                       
      /></TableCell>
      <TableCell sx={mainThemeStyle.cellRow}>
              {(row*8)+8}
              </TableCell>
              <TableCell align="right"> <TextField
        id="text"
        type="text"
        variant="filled" 
        value={dataOfLocation.elevator_access.program[(row*8)+7]}
        name={(row*8)+7}
        onChange={handleChangeEleInnerRelay}  
        disabled={getDisableValue("183") == false ? (dataOfLocation.elevator_access.totalrelays < (parseInt(row*8)+8) ? true : false) : true}
                        InputLabelProps={{ shrink: true }
                      }
                          
                        InputProps={{
                          inputProps: { 
                            maxLength:2,
                        },
                         
                            sx: {
                                
                                color:'rgba(135, 135, 135, 0.5)',
                                height:'25px',
                                borderRadius: '5px',
                                backgroundColor: '#121212',
                                boxShadow: 'inset 0px 4px 4px rgba(0, 0, 0, 0.25)',
                                padding: '0 0 15px 0',
                                textIndent: '15px !important',
                              
                                '&.Mui-disabled': {
             

                                  background: 'rgba(135, 135, 135, 0.5)',
                                },
                                
                                "& input.Mui-disabled": {
                                  color: "white"
                                }
                                
                            },
                        }}
                       
      /></TableCell>
            </TableRow>
          ))}
        </TableBody>
      </Table>
    </TableContainer>}
    </Box>
    
</>)}

{(currentTab == 6 && dataOfLocation.cam_brand == "ResidentLink" && <>
<div  sx={{
       right: '54px' ,left: '54px',borderRadius:'0px'}}>
               <div style={{ display: 'flex' }}>
                   <Typography variant="h6" component="div" style={{  paddingLeft: '16px',paddingTop: '16px',flexGrow: 1,color:'#fff' }}>
                       Brivo Control Settings
                   </Typography>
                   
               </div>
              
           </div>
           <Divider sx={{backgroundColor:'white'}} />
      
           <Box sx={{display: { xs: "none", md: "block" }}}>
      
      <Grid container spacing={2} padding= '16px'> 
      <Grid item xs={2.5}>
      <Stack direction="column"  spacing={2}>
      <Typography  sx={mainThemeStyle.popUpLable} height={40}>Brivo Primary Door Id</Typography>
      <Typography  sx={mainThemeStyle.popUpLable} height={40}># of Brivo Floors</Typography>
      <Typography  sx={mainThemeStyle.popUpLable} height={40}>In-Call Relay Triggers</Typography>
     
      </Stack>
      </Grid>
      <Grid item xs={2.5}>
      <Stack direction="column"  spacing={2}>

       <Autocomplete
            style={{marginTop: 0,minWidth: 250}}
            sx={{  "& .MuiAutocomplete-inputRoot": {
              color: "rgba(170, 170, 170,1)" },"& .MuiSvgIcon-root": {
                color: "rgba(170, 170, 170, 1)",
              },
             "& .Mui-disabled": {
               color: 'rgba(135, 135, 135, 0.5) ',
               "& .MuiSvgIcon-root": {
                 color: "rgba(135, 135, 135, 0.5)"
               }
             },
             "& .MuiInputBase-root.Mui-disabled": {
               backgroundColor: 'rgba(135, 135, 135, 0.5)',
               color: 'white',
             },
             
             }}
            disablePortal
            disabled = {getDisableValue("184")}
            id="brivoprimeid"
            name = 'brivoprimeid'
            value={options.find(option => option.value === getBrivoValueMain())}
          onChange={handleChangeBrivoDoorValue}
          variant="filled"
          
          options={options}
          getOptionLabel={(option) => option.label}
          PaperComponent={({ children }) => (
            <Paper style={{ backgroundColor: "#1E1E1E" ,color:"#AFAFAF", "&:hover":{backgroundColor: "#fff"}}}>{children}</Paper>
          )}
          renderInput={(params) => <TextField {...params} 
           variant="outlined" size="small" style={{ backgroundColor: "#121212",borderRadius: '5px' ,color:"#fff",
        
            
        }} />}
        renderOption={(props, option) => (
          <MenuItem
            {...props}
            key={option.id}
            sx={{
              color: "#fff",
              backgroundColor: "#2F323A",
              borderBottom: '.5px solid grey',
              fontFamily: "Lato",
              fontSize: '12px',
            }}
            disabled={option.disabled}
          >
            <Stack direction="row" justifyContent="left" alignItems="center">
              {option.label === "None" && (
                <ListItemIcon sx={{ color: "red" }}>
                  <DoNotDisturbIcon fontSize="small" />
                </ListItemIcon>
              )}
              {option.label}
            </Stack>
          </MenuItem>
        )}
          ></Autocomplete> 

      {/* <Autocomplete
      sx={{
        color: 'rgba(135, 135, 135, 0.5)',
        height: '33px',
        marginTop: '4px',
        marginBottom: '3px',
        borderRadius: '5px',
        backgroundColor: '#121212',
        boxShadow: 'inset 0px 4px 4px rgba(0, 0, 0, 0.25)',
        padding: '5px 0 5px 0',
        "& .MuiSvgIcon-root": {
          color: "rgba(135, 135, 135, 0.5)",
        },
        '&.Mui-disabled': {
          background: 'rgba(135, 135, 135, 0.5)',
        },
        "& input.Mui-disabled": {
          color: "white"
        }
      }}
      options={options}
      getOptionLabel={(option) => option.label}
      renderInput={(params) => (
        <TextField
          {...params}
          variant="filled"
          hiddenLabel
          name="brivoprimeid"
          disabled={getDisableValue("184")}
        />
      )}
      renderOption={(props, option) => (
        <MenuItem
          {...props}
          sx={{
            color: "#fff",
            backgroundColor: "#2F323A",
            borderBottom: '.5px solid grey',
            fontFamily: "Lato",
            fontSize: '12px',
          }}
          disabled={option.disabled}
        >
          <Stack direction="row" justifyContent="left" alignItems="center">
            {option.label === "None" && (
              <ListItemIcon sx={{ color: "red" }}>
                <DoNotDisturbIcon fontSize="small" />
              </ListItemIcon>
            )}
            {option.label}
          </Stack>
        </MenuItem>
      )}
      value={options.find(option => option.value === getBrivoValueMain())}
      onChange={handleChangeBrivoDoorValue}
      disablePortal
    /> */}

        {/* <Select
            sx={{ color: 'rgba(135, 135, 135, 0.5)',
            height:'33px',
            marginTop:'4px',
            marginBottom:'3px',
            borderRadius: '5px',
            backgroundColor: '#121212',
            boxShadow: 'inset 0px 4px 4px rgba(0, 0, 0, 0.25)',
            padding: '5px 0 5px 0',
            "& .MuiSvgIcon-root": {
              color: "rgba(135, 135, 135, 0.5)",
            },
            
            '&.Mui-disabled': {
             

              background: 'rgba(135, 135, 135, 0.5)',
            },
            "& input.Mui-disabled": {
              color: "white"
            }
            }}
            name = 'brivoprimeid'
            disabled={getDisableValue("184")}
            value={getBrivoValueMain()}
          onChange={handleChangeBrivoDoorValue}
          variant="filled"
          hiddenLabel
          MenuProps={MenuProps}>
           {brivoDoorArray?.map(menuItem => {
                  
                  return (
                    <MenuItem  sx={
                      {
                      
                      color:"#fff",
                      backgroundColor: "#2F323A",
                      borderBottom: '.5px solid grey',
                      fontFamily: "Lato",fontSize:'12px',
                  }}
                  disabled={menuItem.disabled}
                   value={menuItem.name}>
                       <Stack direction="row" justifyContent="left"
  alignItems="center">
                  { menuItem.name == "None" && <ListItemIcon sx={{color: "red"}}>
            <DoNotDisturbIcon fontSize="small" />
          </ListItemIcon>} 
            {menuItem.name}</Stack>
                    </MenuItem>
                  );
                })}
          </Select>   */}


          
          <TextField
                        id="brivofloors"
                        height={40}
                        type= 'number'
                        name="brivofloors"
                        value={dataOfLocation.brivo_access.brivofloors}
                        onChange={handleChangeBrivoFloorValue}
                        sx={mainThemeStyle.InputMenuItem}
                        InputLabelProps={{ shrink: true }
                      }
                      disabled={getDisableValue("184")}
                      variant="filled"
                        InputProps={{
                          inputProps: { 
                            max: (dataOfLocation.brivo_array.length), min: 0 
                        },
                            sx: {
                                
                                color:'rgba(135, 135, 135, 0.5)',
                                height:'33px',
                                borderRadius: '5px',
                                backgroundColor: '#121212',
                                boxShadow: 'inset 0px 4px 4px rgba(0, 0, 0, 0.25)',
                                padding: '0 0 15px 0',
                                textIndent: '15px !important',
                              
                                '&.Mui-disabled': {
             

                                  background: 'rgba(135, 135, 135, 0.5)',
                                },
                                
                                "& input.Mui-disabled": {
                                  color: "white"
                                }
                                
                            },
                        }}
                       
                    />
      <FormControlLabel   sx={mainThemeStyle.popUpLableNewone}
        control={<IOSSwitch sx={{ m: 1 }} name="icrt"  disabled = {getDisableValue("184")} onChange={handleBrivoInCallRelayTriggerCheck}  checked={dataOfLocation.brivo_access.icrt == null ? false : (dataOfLocation.brivo_access.icrt == "1" ? true : false)} />}
        label={dataOfLocation.brivo_access.icrt == null ? "Disabled" : (dataOfLocation.brivo_access.icrt == "1" ? "Enabled" : "Disabled")}
      />
      
        
      </Stack>
      </Grid>
      <Grid item xs={1}></Grid>
      { dataOfLocation.brivo_access.icrt == 1 && 
      <Grid item xs={6}>
      <TableContainer component={Paper} sx={{
        width:'auto',
        height: 'auto',
        marginLeft: '38px',
        marginRight:'38px',
        marginBottom:'38px',
        backgroundColor:'#171E29',
       
        }}>
      <Table sx={{ minWidth: 350 }} >
        
        <TableHead sx={{ borderBottom: "1.5px solid black",
        }}>
          <TableRow>
            
            <TableCell align="center" sx={mainThemeStyle.popUpLable} colSpan={16}>In-Call Relay Trigger Settings</TableCell>
            
          </TableRow>
         
        </TableHead>

        
        <TableHead sx={{ borderBottom: "1.5px solid black",
        }}>
          </TableHead>
        <TableBody>
       
            <TableRow sx={{ borderBottom: "1.5px solid black",
          }}>
              <TableCell  sx={mainThemeStyle.cellHeader}>
              Custom Name 1 
              </TableCell>
             
                <TableCell align="right">
                <TextField
                        id="0"
                       
                        type= 'text'
                        name="0"
                        value={getCustomNameValue(0)}
                        onChange={handleChangeCustomNameValue}
                       
                        sx={{
                          ...mainThemeStyle.InputMenuItem,
                          marginTop:'10px',
                        }}
                        InputLabelProps={{ shrink: true }
                      }
                      disabled = {getDisableValue("184")}
                      variant="filled"
                        InputProps={{
                          
                            sx: {
                                
                                color:'rgba(135, 135, 135, 0.5)',
                                height:'33px',
                                borderRadius: '5px',
                                backgroundColor: '#121212',
                                boxShadow: 'inset 0px 4px 4px rgba(0, 0, 0, 0.25)',
                                padding: '0 0 15px 0',
                                textIndent: '15px !important',
                                '&.Mui-disabled': {
             

                                  background: 'rgba(135, 135, 135, 0.5)',
                                },
                                
                                "& input.Mui-disabled": {
                                  color: "white"
                                }
                               
                                
                            },
                        }}
                       
                    />
                    
                </TableCell>
                <TableCell align="right">
                
                <Select
              sx={{ color: 'rgba(135, 135, 135, 0.5)',
              height:'33px',
              marginTop:'8px',
              marginBottom:'3px',
              borderRadius: '5px',
              backgroundColor: '#121212',
              boxShadow: 'inset 0px 4px 4px rgba(0, 0, 0, 0.25)',
              padding: '5px 0 5px 0',
              "& .MuiSvgIcon-root": {
                color: "rgba(135, 135, 135, 0.5)",
              },
              
              '&.Mui-disabled': {
               
  
                background: 'rgba(135, 135, 135, 0.5)',
              },
              "& input.Mui-disabled": {
                color: "white"
              }
              }}
              name = "0"
              value={getCustomDoorValue(0)}
            onChange={handleChangeCustomDoorValue}
            variant="filled"
            disabled={getDisableValue("184")}
            hiddenLabel
            MenuProps={MenuProps}>
              {brivoCustomArray?.map(menuItem => {
                    
                    return (
                      <MenuItem  sx={
                        {
                        
                        color:"#fff",
                        backgroundColor: "#2F323A",
                        borderBottom: '.5px solid grey',
                        fontFamily: "Lato",fontSize:'12px',
                    }}
                    disabled={menuItem.disabled}
                     value={menuItem.name}>
                         <Stack direction="row" justifyContent="left"
    alignItems="center">
                    { menuItem.name == "None" && <ListItemIcon sx={{color: "red"}}>
              <DoNotDisturbIcon fontSize="small" />
            </ListItemIcon>} 
              {menuItem.name}</Stack>
                      </MenuItem>
                    );
                  })}
            </Select>
                  </TableCell>
            </TableRow>
            <TableRow sx={{ borderBottom: "1.5px solid black",
          }}>
              <TableCell  sx={mainThemeStyle.cellHeader}>
              Custom Name 2 
              </TableCell>
             
                <TableCell align="right">
                <TextField
                        id="1"
                       
                        type= 'text'
                        name="1"
                        value={getCustomNameValue(1)}
                        onChange={handleChangeCustomNameValue}
                       
                        sx={{
                          ...mainThemeStyle.InputMenuItem,
                          marginTop:'10px',
                        }}
                        InputLabelProps={{ shrink: true }
                      }
                      disabled = {getDisableValue("184")}
                      variant="filled"
                        InputProps={{
                          
                            sx: {
                                
                                color:'rgba(135, 135, 135, 0.5)',
                                height:'33px',
                                borderRadius: '5px',
                                backgroundColor: '#121212',
                                boxShadow: 'inset 0px 4px 4px rgba(0, 0, 0, 0.25)',
                                padding: '0 0 15px 0',
                                textIndent: '15px !important',
                                '&.Mui-disabled': {
             

                                  background: 'rgba(135, 135, 135, 0.5)',
                                },
                                
                                "& input.Mui-disabled": {
                                  color: "white"
                                }
                               
                                
                            },
                        }}
                       
                    />
                    
                </TableCell>
                <TableCell align="right">
                
                <Select
              sx={{ color: 'rgba(135, 135, 135, 0.5)',
              height:'33px',
              marginTop:'8px',
              marginBottom:'3px',
              borderRadius: '5px',
              backgroundColor: '#121212',
              boxShadow: 'inset 0px 4px 4px rgba(0, 0, 0, 0.25)',
              padding: '5px 0 5px 0',
              "& .MuiSvgIcon-root": {
                color: "rgba(135, 135, 135, 0.5)",
              },
              
              '&.Mui-disabled': {
               
  
                background: 'rgba(135, 135, 135, 0.5)',
              },
              "& input.Mui-disabled": {
                color: "white"
              }
              }}
              name = "1"
              value={getCustomDoorValue(1)}
            onChange={handleChangeCustomDoorValue}
            variant="filled"
            disabled={getDisableValue("184")}
            hiddenLabel
            MenuProps={MenuProps}>
              {brivoCustomArray?.map(menuItem => {
                    
                    return (
                      <MenuItem  sx={
                        {
                        
                        color:"#fff",
                        backgroundColor: "#2F323A",
                        borderBottom: '.5px solid grey',
                        fontFamily: "Lato",fontSize:'12px',
                    }}
                    disabled={menuItem.disabled}
                     value={menuItem.name}>
                         <Stack direction="row" justifyContent="left"
    alignItems="center">
                    { menuItem.name == "None" && <ListItemIcon sx={{color: "red"}}>
              <DoNotDisturbIcon fontSize="small" />
            </ListItemIcon>} 
              {menuItem.name}</Stack>
                      </MenuItem>
                    );
                  })}
            </Select>
                  </TableCell>
            </TableRow>
            <TableRow sx={{ borderBottom: "1.5px solid black",
          }}>
              <TableCell  sx={mainThemeStyle.cellHeader}>
              Custom Name 3 
              </TableCell>
             
                <TableCell align="right">
                <TextField
                        id="2"
                       
                        type= 'text'
                        name="2"
                        value={getCustomNameValue(2)}
                        onChange={handleChangeCustomNameValue}
                       
                        sx={{
                          ...mainThemeStyle.InputMenuItem,
                          marginTop:'10px',
                        }}
                        InputLabelProps={{ shrink: true }
                      }
                      disabled = {getDisableValue("184")}
                      variant="filled"
                        InputProps={{
                          
                            sx: {
                                
                                color:'rgba(135, 135, 135, 0.5)',
                                height:'33px',
                                borderRadius: '5px',
                                backgroundColor: '#121212',
                                boxShadow: 'inset 0px 4px 4px rgba(0, 0, 0, 0.25)',
                                padding: '0 0 15px 0',
                                textIndent: '15px !important',
                                '&.Mui-disabled': {
             

                                  background: 'rgba(135, 135, 135, 0.5)',
                                },
                                
                                "& input.Mui-disabled": {
                                  color: "white"
                                }
                               
                                
                            },
                        }}
                       
                    />
                    
                </TableCell>
                <TableCell align="right">
                
                <Select
              sx={{ color: 'rgba(135, 135, 135, 0.5)',
              height:'33px',
              marginTop:'8px',
              marginBottom:'3px',
              borderRadius: '5px',
              backgroundColor: '#121212',
              boxShadow: 'inset 0px 4px 4px rgba(0, 0, 0, 0.25)',
              padding: '5px 0 5px 0',
              "& .MuiSvgIcon-root": {
                color: "rgba(135, 135, 135, 0.5)",
              },
              
              '&.Mui-disabled': {
               
  
                background: 'rgba(135, 135, 135, 0.5)',
              },
              "& input.Mui-disabled": {
                color: "white"
              }
              }}
              name = "2"
              value={getCustomDoorValue(2)}
            onChange={handleChangeCustomDoorValue}
            variant="filled"
            disabled={getDisableValue("184")}
            hiddenLabel
            MenuProps={MenuProps}>
              {brivoCustomArray?.map(menuItem => {
                    
                    return (
                      <MenuItem  sx={
                        {
                        
                        color:"#fff",
                        backgroundColor: "#2F323A",
                        borderBottom: '.5px solid grey',
                        fontFamily: "Lato",fontSize:'12px',
                    }}
                    disabled={menuItem.disabled}
                     value={menuItem.name}>
                         <Stack direction="row" justifyContent="left"
    alignItems="center">
                    { menuItem.name == "None" && <ListItemIcon sx={{color: "red"}}>
              <DoNotDisturbIcon fontSize="small" />
            </ListItemIcon>} 
              {menuItem.name}</Stack>
                      </MenuItem>
                    );
                  })}
            </Select>
                  </TableCell>
            </TableRow>
        </TableBody>
      </Table>
    </TableContainer></Grid>
    }

     
      </Grid>
     
      <TableContainer component={Paper} sx={{
        width:'auto',
        height: 'auto',
        marginLeft: '38px',
        marginRight:'38px',
        marginBottom:'38px',
        backgroundColor:'#171E29',
       
        }}>
      <Table sx={{ minWidth: 650 }} >
        
        <TableHead sx={{ borderBottom: "1.5px solid black",
        }}>
          <TableRow>
            
            <TableCell align="center" sx={mainThemeStyle.popUpLable} colSpan={16}>Brivo Relays</TableCell>
            
          </TableRow>
         
        </TableHead>

        
        <TableHead sx={{ borderBottom: "1.5px solid black",
        }}>
          <TableRow>
          <TableCell align="right" sx={mainThemeStyle.cellHeader}> Floor</TableCell>
            <TableCell align="right" sx={mainThemeStyle.cellHeader}>	Door Id</TableCell>
            <TableCell align="right" sx={mainThemeStyle.cellHeader}> Floor</TableCell>
            <TableCell align="right" sx={mainThemeStyle.cellHeader}>Door Id</TableCell>
            <TableCell align="right" sx={mainThemeStyle.cellHeader}> Floor</TableCell>
            <TableCell align="right" sx={mainThemeStyle.cellHeader}>Door Id</TableCell>
            <TableCell align="right" sx={mainThemeStyle.cellHeader}> Floor</TableCell>
            <TableCell align="right" sx={mainThemeStyle.cellHeader}>Door Id</TableCell>
            <TableCell align="right" sx={mainThemeStyle.cellHeader}> Floor</TableCell>
            <TableCell align="right" sx={mainThemeStyle.cellHeader}>Door Id</TableCell>
            <TableCell align="right" sx={mainThemeStyle.cellHeader}> Floor</TableCell>
            <TableCell align="right" sx={mainThemeStyle.cellHeader}>Door Id</TableCell>
            <TableCell align="right" sx={mainThemeStyle.cellHeader}> Floor</TableCell>
            <TableCell align="right" sx={mainThemeStyle.cellHeader}>Door Id</TableCell>
            <TableCell align="right" sx={mainThemeStyle.cellHeader}> Floor</TableCell>
            <TableCell align="right" sx={mainThemeStyle.cellHeader}>Door Id</TableCell>
          </TableRow></TableHead>
        <TableBody>
         {rowsElevatorControl.map((row) => (
            <TableRow sx={{ borderBottom: "1.5px solid black",
          }}>
              <TableCell sx={mainThemeStyle.cellRow}>
              {parseInt(row*8)+1}
              </TableCell>
              <TableCell align="right">
              <Autocomplete
       style={{marginTop: 0,minWidth: 150}}
        sx={{  "& .MuiAutocomplete-inputRoot": {
         color: "rgba(170, 170, 170,1)" },"& .MuiSvgIcon-root": {
           color: "rgba(170, 170, 170, 1)",
         },
         "& .Mui-disabled": {
          color: 'rgba(135, 135, 135, 0.5) ',
          "& .MuiSvgIcon-root": {
           color: "rgba(135, 135, 135, 0.5)"
          }
        },
        "& .MuiInputBase-root.Mui-disabled": {
          backgroundColor: 'rgba(135, 135, 135, 0.5)',
          color: 'white',
        },
        
        }}
       disablePortal
       disabled = {getDisableValue("184") == false ? (dataOfLocation.brivo_access.brivofloors < (parseInt(row*8)+1) ? true : false) : true}
       name = {parseInt(row*8)}
       value={options.find(option => option.value === getBrivoValue(parseInt(row*8)))}
     onChange={handleChangeBrivoRelay(parseInt(row*8))}
     variant="filled"
     
     options={options}
     getOptionLabel={(option) => option.label}
     PaperComponent={({ children }) => (
       <Paper style={{ backgroundColor: "#1E1E1E" ,color:"#AFAFAF", "&:hover":{backgroundColor: "#fff"}}}>{children}</Paper>
     )}
     renderInput={(params) => <TextField {...params} 
      variant="outlined" size="small" style={{ backgroundColor: "#121212",borderRadius: '5px' ,color:"#fff",
   
       
   }} />}
   renderOption={(props, option) => (
     <MenuItem
       {...props}
       key={option.id}
       sx={{
         color: "#fff",
         backgroundColor: "#2F323A",
         borderBottom: '.5px solid grey',
         fontFamily: "Lato",
         fontSize: '12px',
       }}
       disabled={option.disabled}
     >
       <Stack direction="row" justifyContent="left" alignItems="center">
         {option.label === "None" && (
           <ListItemIcon sx={{ color: "red" }}>
             <DoNotDisturbIcon fontSize="small" />
           </ListItemIcon>
         )}
         {option.label}
       </Stack>
     </MenuItem>
   )}
     ></Autocomplete>
            {/*   <Select
            sx={{ color: 'rgba(135, 135, 135, 0.5)',
            height:'33px',
            marginTop:'4px',
            marginBottom:'3px',
            borderRadius: '5px',
            backgroundColor: '#121212',
            boxShadow: 'inset 0px 4px 4px rgba(0, 0, 0, 0.25)',
            padding: '5px 0 5px 0',
            "& .MuiSvgIcon-root": {
              color: "rgba(135, 135, 135, 0.5)",
            },
            
            '&.Mui-disabled': {
             

              background: 'rgba(135, 135, 135, 0.5)',
            },
            "& input.Mui-disabled": {
              color: "white"
            }
            }}
            name = {parseInt(row*8)}
            value={getBrivoValue(parseInt(row*8))}
          onChange={handleChangeBrivoRelay}
          variant="filled"
          disabled={getDisableValue("184") == false ? (dataOfLocation.brivo_access.brivofloors < (parseInt(row*8)+1) ? true : false) : true}
          hiddenLabel
          MenuProps={MenuProps}>
           {brivoDoorArray?.map(menuItem => {
                  
                  return (
                    <MenuItem  sx={
                      {
                      
                      color:"#fff",
                      backgroundColor: "#2F323A",
                      borderBottom: '.5px solid grey',
                      fontFamily: "Lato",fontSize:'12px',
                  }}
                  disabled={menuItem.disabled}
                   value={menuItem.name}>
                       <Stack direction="row" justifyContent="left"
  alignItems="center">
                  { menuItem.name == "None" && <ListItemIcon sx={{color: "red"}}>
            <DoNotDisturbIcon fontSize="small" />
          </ListItemIcon>} 
            {menuItem.name}</Stack>
                    </MenuItem>
                  );
                })}
          </Select> */}
                </TableCell>
              <TableCell sx={mainThemeStyle.cellRow}>
              {parseInt(row*8)+2}
              </TableCell>
              <TableCell align="right">

              <Autocomplete
       style={{marginTop: 0,minWidth: 150}}
        sx={{  "& .MuiAutocomplete-inputRoot": {
         color: "rgba(170, 170, 170,1)" },"& .MuiSvgIcon-root": {
           color: "rgba(170, 170, 170, 1)",
         },
         "& .Mui-disabled": {
          color: 'rgba(135, 135, 135, 0.5) ',
          "& .MuiSvgIcon-root": {
           color: "rgba(135, 135, 135, 0.5)"
          }
        },
        "& .MuiInputBase-root.Mui-disabled": {
          backgroundColor: 'rgba(135, 135, 135, 0.5)',
          color: 'white',
        },
        
        }}
       disablePortal
       disabled = {getDisableValue("184") == false ? (dataOfLocation.brivo_access.brivofloors < (parseInt(row*8)+2) ? true : false) : true}
       name = {parseInt(row*8)+1}
       value={options.find(option => option.value === getBrivoValue(parseInt(row*8)+1))}
     onChange={handleChangeBrivoRelay(parseInt(row*8)+1)}
     variant="filled"
     
     options={options}
     getOptionLabel={(option) => option.label}
     PaperComponent={({ children }) => (
       <Paper style={{ backgroundColor: "#1E1E1E" ,color:"#AFAFAF", "&:hover":{backgroundColor: "#fff"}}}>{children}</Paper>
     )}
     renderInput={(params) => <TextField {...params} 
      variant="outlined" size="small" style={{ backgroundColor: "#121212",borderRadius: '5px' ,color:"#fff",
   
       
   }} />}
   renderOption={(props, option) => (
     <MenuItem
       {...props}
       key={option.id}
       sx={{
         color: "#fff",
         backgroundColor: "#2F323A",
         borderBottom: '.5px solid grey',
         fontFamily: "Lato",
         fontSize: '12px',
       }}
       disabled={option.disabled}
     >
       <Stack direction="row" justifyContent="left" alignItems="center">
         {option.label === "None" && (
           <ListItemIcon sx={{ color: "red" }}>
             <DoNotDisturbIcon fontSize="small" />
           </ListItemIcon>
         )}
         {option.label}
       </Stack>
     </MenuItem>
   )}
     ></Autocomplete>
     
             {/*  <Select
            sx={{ color: 'rgba(135, 135, 135, 0.5)',
            height:'33px',
            marginTop:'4px',
            marginBottom:'3px',
            borderRadius: '5px',
            backgroundColor: '#121212',
            boxShadow: 'inset 0px 4px 4px rgba(0, 0, 0, 0.25)',
            padding: '5px 0 5px 0',
            "& .MuiSvgIcon-root": {
              color: "rgba(135, 135, 135, 0.5)",
            },
            
            '&.Mui-disabled': {
             

              background: 'rgba(135, 135, 135, 0.5)',
            },
            "& input.Mui-disabled": {
              color: "white"
            }
            }}
            name = {(row*8)+1}
            value={getBrivoValue(parseInt(row*8)+1)}
          onChange={handleChangeBrivoRelay}
          variant="filled"
          disabled={getDisableValue("184") == false ? (dataOfLocation.brivo_access.brivofloors < (parseInt(row*8)+2) ? true : false) : true}
          hiddenLabel
          MenuProps={MenuProps}>
            {brivoDoorArray?.map(menuItem => {
                  
                  return (
                    <MenuItem  sx={
                      {
                      
                      color:"#fff",
                      backgroundColor: "#2F323A",
                      borderBottom: '.5px solid grey',
                      fontFamily: "Lato",fontSize:'12px',
                  }}
                  disabled={menuItem.disabled}
                   value={menuItem.name}>
                       <Stack direction="row" justifyContent="left"
  alignItems="center">
                  { menuItem.name == "None" && <ListItemIcon sx={{color: "red"}}>
            <DoNotDisturbIcon fontSize="small" />
          </ListItemIcon>} 
            {menuItem.name}</Stack>
                    </MenuItem>
                  );
                })}
          </Select> */}
                
                
               </TableCell>
      <TableCell sx={mainThemeStyle.cellRow}>
              {parseInt(row*8)+3}
              </TableCell>
              <TableCell align="right"> 
              <Autocomplete
       style={{marginTop: 0,minWidth: 150}}
        sx={{  "& .MuiAutocomplete-inputRoot": {
         color: "rgba(170, 170, 170,1)" },"& .MuiSvgIcon-root": {
           color: "rgba(170, 170, 170, 1)",
         },
         "& .Mui-disabled": {
          color: 'rgba(135, 135, 135, 0.5) ',
          "& .MuiSvgIcon-root": {
           color: "rgba(135, 135, 135, 0.5)"
          }
        },
        "& .MuiInputBase-root.Mui-disabled": {
          backgroundColor: 'rgba(135, 135, 135, 0.5)',
          color: 'white',
        },
        
        }}
       disablePortal
       disabled = {getDisableValue("184") == false ? (dataOfLocation.brivo_access.brivofloors < (parseInt(row*8)+3) ? true : false) : true}
       name = {parseInt(row*8)+2}
       value={options.find(option => option.value === getBrivoValue(parseInt(row*8)+2))}
     onChange={handleChangeBrivoRelay(parseInt(row*8)+2)}
     variant="filled"
     
     options={options}
     getOptionLabel={(option) => option.label}
     PaperComponent={({ children }) => (
       <Paper style={{ backgroundColor: "#1E1E1E" ,color:"#AFAFAF", "&:hover":{backgroundColor: "#fff"}}}>{children}</Paper>
     )}
     renderInput={(params) => <TextField {...params} 
      variant="outlined" size="small" style={{ backgroundColor: "#121212",borderRadius: '5px' ,color:"#fff",
   
       
   }} />}
   renderOption={(props, option) => (
     <MenuItem
       {...props}
       key={option.id}
       sx={{
         color: "#fff",
         backgroundColor: "#2F323A",
         borderBottom: '.5px solid grey',
         fontFamily: "Lato",
         fontSize: '12px',
       }}
       disabled={option.disabled}
     >
       <Stack direction="row" justifyContent="left" alignItems="center">
         {option.label === "None" && (
           <ListItemIcon sx={{ color: "red" }}>
             <DoNotDisturbIcon fontSize="small" />
           </ListItemIcon>
         )}
         {option.label}
       </Stack>
     </MenuItem>
   )}
     ></Autocomplete>
             {/*  <Select
            sx={{ color: 'rgba(135, 135, 135, 0.5)',
            height:'33px',
            marginTop:'4px',
            marginBottom:'3px',
            borderRadius: '5px',
            backgroundColor: '#121212',
            boxShadow: 'inset 0px 4px 4px rgba(0, 0, 0, 0.25)',
            padding: '5px 0 5px 0',
            "& .MuiSvgIcon-root": {
              color: "rgba(135, 135, 135, 0.5)",
            },
            
            '&.Mui-disabled': {
             

              background: 'rgba(135, 135, 135, 0.5)',
            },
            "& input.Mui-disabled": {
              color: "white"
            }
            }}
            name = {(row*8)+2}
            value={getBrivoValue((row*8)+2)}
          onChange={handleChangeBrivoRelay}
          variant="filled"
          disabled={getDisableValue("184") == false ? (dataOfLocation.brivo_access.brivofloors < (parseInt(row*8)+3) ? true : false) : true}
          hiddenLabel
          MenuProps={MenuProps}>
            {brivoDoorArray?.map(menuItem => {
                  
                  return (
                    <MenuItem  sx={
                      {
                      
                      color:"#fff",
                      backgroundColor: "#2F323A",
                      borderBottom: '.5px solid grey',
                      fontFamily: "Lato",fontSize:'12px',
                  }}
                  disabled={menuItem.disabled}
                   value={menuItem.name}>
                       <Stack direction="row" justifyContent="left"
  alignItems="center">
                  { menuItem.name == "None" && <ListItemIcon sx={{color: "red"}}>
            <DoNotDisturbIcon fontSize="small" />
          </ListItemIcon>} 
            {menuItem.name}</Stack>
                    </MenuItem>
                  );
                })}
          </Select> */}
              
              </TableCell>
      <TableCell sx={mainThemeStyle.cellRow}>
              {(row*8)+4}
              </TableCell>
              <TableCell align="right"> 
              <Autocomplete
       style={{marginTop: 0,minWidth: 150}}
        sx={{  "& .MuiAutocomplete-inputRoot": {
         color: "rgba(170, 170, 170,1)" },"& .MuiSvgIcon-root": {
           color: "rgba(170, 170, 170, 1)",
         },
         "& .Mui-disabled": {
          color: 'rgba(135, 135, 135, 0.5) ',
          "& .MuiSvgIcon-root": {
           color: "rgba(135, 135, 135, 0.5)"
          }
        },
        "& .MuiInputBase-root.Mui-disabled": {
          backgroundColor: 'rgba(135, 135, 135, 0.5)',
          color: 'white',
        },
        
        }}
       disablePortal
       disabled = {getDisableValue("184") == false ? (dataOfLocation.brivo_access.brivofloors < (parseInt(row*8)+4) ? true : false) : true}
       name = {parseInt(row*8)+3}
       value={options.find(option => option.value === getBrivoValue(parseInt(row*8)+3))}
     onChange={handleChangeBrivoRelay(parseInt(row*8)+3)}
     variant="filled"
     
     options={options}
     getOptionLabel={(option) => option.label}
     PaperComponent={({ children }) => (
       <Paper style={{ backgroundColor: "#1E1E1E" ,color:"#AFAFAF", "&:hover":{backgroundColor: "#fff"}}}>{children}</Paper>
     )}
     renderInput={(params) => <TextField {...params} 
      variant="outlined" size="small" style={{ backgroundColor: "#121212",borderRadius: '5px' ,color:"#fff",
   
       
   }} />}
   renderOption={(props, option) => (
     <MenuItem
       {...props}
       key={option.id}
       sx={{
         color: "#fff",
         backgroundColor: "#2F323A",
         borderBottom: '.5px solid grey',
         fontFamily: "Lato",
         fontSize: '12px',
       }}
       disabled={option.disabled}
     >
       <Stack direction="row" justifyContent="left" alignItems="center">
         {option.label === "None" && (
           <ListItemIcon sx={{ color: "red" }}>
             <DoNotDisturbIcon fontSize="small" />
           </ListItemIcon>
         )}
         {option.label}
       </Stack>
     </MenuItem>
   )}
     ></Autocomplete>
             {/*  <Select
            sx={{ color: 'rgba(135, 135, 135, 0.5)',
            height:'33px',
            marginTop:'4px',
            marginBottom:'3px',
            borderRadius: '5px',
            backgroundColor: '#121212',
            boxShadow: 'inset 0px 4px 4px rgba(0, 0, 0, 0.25)',
            padding: '5px 0 5px 0',
            "& .MuiSvgIcon-root": {
              color: "rgba(135, 135, 135, 0.5)",
            },
            
            '&.Mui-disabled': {
             

              background: 'rgba(135, 135, 135, 0.5)',
            },
            "& input.Mui-disabled": {
              color: "white"
            }
            }}
            name = {(row*8)+3}
            value={getBrivoValue((row*8)+3)}
          onChange={handleChangeBrivoRelay}
          variant="filled"
          disabled={getDisableValue("184") == false ? (dataOfLocation.brivo_access.brivofloors < (parseInt(row*8)+4) ? true : false) : true}
          hiddenLabel
          MenuProps={MenuProps}>
            {brivoDoorArray?.map(menuItem => {
                  
                  return (
                    <MenuItem  sx={
                      {
                      
                      color:"#fff",
                      backgroundColor: "#2F323A",
                      borderBottom: '.5px solid grey',
                      fontFamily: "Lato",fontSize:'12px',
                  }}
                  disabled={menuItem.disabled}
                   value={menuItem.name}>
                        <Stack direction="row" justifyContent="left"
  alignItems="center">
                  { menuItem.name == "None" && <ListItemIcon sx={{color: "red"}}>
            <DoNotDisturbIcon fontSize="small" />
          </ListItemIcon>} 
            {menuItem.name}</Stack>
                    </MenuItem>
                  );
                })}
          </Select> */}
              </TableCell>
      <TableCell sx={mainThemeStyle.cellRow}>
              {(row*8)+5}
              </TableCell>
              <TableCell align="right">
              <Autocomplete
       style={{marginTop: 0,minWidth: 150}}
        sx={{  "& .MuiAutocomplete-inputRoot": {
         color: "rgba(170, 170, 170,1)" },"& .MuiSvgIcon-root": {
           color: "rgba(170, 170, 170, 1)",
         },
         "& .Mui-disabled": {
          color: 'rgba(135, 135, 135, 0.5) ',
          "& .MuiSvgIcon-root": {
           color: "rgba(135, 135, 135, 0.5)"
          }
        },
        "& .MuiInputBase-root.Mui-disabled": {
          backgroundColor: 'rgba(135, 135, 135, 0.5)',
          color: 'white',
        },
        
        }}
       disablePortal
       disabled = {getDisableValue("184") == false ? (dataOfLocation.brivo_access.brivofloors < (parseInt(row*8)+5) ? true : false) : true}
       name = {parseInt(row*8)+4}
       value={options.find(option => option.value === getBrivoValue(parseInt(row*8)+4))}
     onChange={handleChangeBrivoRelay(parseInt(row*8)+4)}
     variant="filled"
     
     options={options}
     getOptionLabel={(option) => option.label}
     PaperComponent={({ children }) => (
       <Paper style={{ backgroundColor: "#1E1E1E" ,color:"#AFAFAF", "&:hover":{backgroundColor: "#fff"}}}>{children}</Paper>
     )}
     renderInput={(params) => <TextField {...params} 
      variant="outlined" size="small" style={{ backgroundColor: "#121212",borderRadius: '5px' ,color:"#fff",
   
       
   }} />}
   renderOption={(props, option) => (
     <MenuItem
       {...props}
       key={option.id}
       sx={{
         color: "#fff",
         backgroundColor: "#2F323A",
         borderBottom: '.5px solid grey',
         fontFamily: "Lato",
         fontSize: '12px',
       }}
       disabled={option.disabled}
     >
       <Stack direction="row" justifyContent="left" alignItems="center">
         {option.label === "None" && (
           <ListItemIcon sx={{ color: "red" }}>
             <DoNotDisturbIcon fontSize="small" />
           </ListItemIcon>
         )}
         {option.label}
       </Stack>
     </MenuItem>
   )}
     ></Autocomplete>
                      {/* <Select
            sx={{ color: 'rgba(135, 135, 135, 0.5)',
            height:'33px',
            marginTop:'4px',
            marginBottom:'3px',
            borderRadius: '5px',
            backgroundColor: '#121212',
            boxShadow: 'inset 0px 4px 4px rgba(0, 0, 0, 0.25)',
            padding: '5px 0 5px 0',
            "& .MuiSvgIcon-root": {
              color: "rgba(135, 135, 135, 0.5)",
            },
            
            '&.Mui-disabled': {
             

              background: 'rgba(135, 135, 135, 0.5)',
            },
            "& input.Mui-disabled": {
              color: "white"
            }
            }}
            name = {(row*8)+4}
            value={getBrivoValue((row*8)+4)}
          onChange={handleChangeBrivoRelay}
          variant="filled"
          disabled={getDisableValue("184") == false ? (dataOfLocation.brivo_access.brivofloors < (parseInt(row*8)+5) ? true : false) : true}
          hiddenLabel
          MenuProps={MenuProps}>
            {brivoDoorArray?.map(menuItem => {
                  
                  return (
                    <MenuItem  sx={
                      {
                      
                      color:"#fff",
                      backgroundColor: "#2F323A",
                      borderBottom: '.5px solid grey',
                      fontFamily: "Lato",fontSize:'12px',
                  }}
                  disabled={menuItem.disabled}
                   value={menuItem.name}>
                      <Stack direction="row" justifyContent="left"
  alignItems="center">
                  { menuItem.name == "None" && <ListItemIcon sx={{color: "red"}}>
            <DoNotDisturbIcon fontSize="small" />
          </ListItemIcon>} 
            {menuItem.name}</Stack>
                    </MenuItem>
                  );
                })}
          </Select>  */}
                </TableCell>
      <TableCell sx={mainThemeStyle.cellRow}>
              {(row*8)+6}
              </TableCell>
              <TableCell align="right"> 
              <Autocomplete
       style={{marginTop: 0,minWidth: 150}}
        sx={{  "& .MuiAutocomplete-inputRoot": {
         color: "rgba(170, 170, 170,1)" },"& .MuiSvgIcon-root": {
           color: "rgba(170, 170, 170, 1)",
         },
         "& .Mui-disabled": {
          color: 'rgba(135, 135, 135, 0.5) ',
          "& .MuiSvgIcon-root": {
           color: "rgba(135, 135, 135, 0.5)"
          }
        },
        "& .MuiInputBase-root.Mui-disabled": {
          backgroundColor: 'rgba(135, 135, 135, 0.5)',
          color: 'white',
        },
        
        }}
       disablePortal
       disabled = {getDisableValue("184") == false ? (dataOfLocation.brivo_access.brivofloors < (parseInt(row*8)+6) ? true : false) : true}
       name = {parseInt(row*8)+5}
       value={options.find(option => option.value === getBrivoValue(parseInt(row*8)+5))}
     onChange={handleChangeBrivoRelay(parseInt(row*8)+5)}
     variant="filled"
     
     options={options}
     getOptionLabel={(option) => option.label}
     PaperComponent={({ children }) => (
       <Paper style={{ backgroundColor: "#1E1E1E" ,color:"#AFAFAF", "&:hover":{backgroundColor: "#fff"}}}>{children}</Paper>
     )}
     renderInput={(params) => <TextField {...params} 
      variant="outlined" size="small" style={{ backgroundColor: "#121212",borderRadius: '5px' ,color:"#fff",
   
       
   }} />}
   renderOption={(props, option) => (
     <MenuItem
       {...props}
       key={option.id}
       sx={{
         color: "#fff",
         backgroundColor: "#2F323A",
         borderBottom: '.5px solid grey',
         fontFamily: "Lato",
         fontSize: '12px',
       }}
       disabled={option.disabled}
     >
       <Stack direction="row" justifyContent="left" alignItems="center">
         {option.label === "None" && (
           <ListItemIcon sx={{ color: "red" }}>
             <DoNotDisturbIcon fontSize="small" />
           </ListItemIcon>
         )}
         {option.label}
       </Stack>
     </MenuItem>
   )}
     ></Autocomplete>
              {/* <Select
            sx={{ color: 'rgba(135, 135, 135, 0.5)',
            height:'33px',
            marginTop:'4px',
            marginBottom:'3px',
            borderRadius: '5px',
            backgroundColor: '#121212',
            boxShadow: 'inset 0px 4px 4px rgba(0, 0, 0, 0.25)',
            padding: '5px 0 5px 0',
            "& .MuiSvgIcon-root": {
              color: "rgba(135, 135, 135, 0.5)",
            },
            
            '&.Mui-disabled': {
             

              background: 'rgba(135, 135, 135, 0.5)',
            },
            "& input.Mui-disabled": {
              color: "white"
            }
            }}
            name = {(row*8)+5}
            value={getBrivoValue((row*8)+5)}
          onChange={handleChangeBrivoRelay}
          variant="filled"
          disabled={getDisableValue("184") == false ? (dataOfLocation.brivo_access.brivofloors < (parseInt(row*8)+6) ? true : false) : true}
          hiddenLabel
          MenuProps={MenuProps}>
           {brivoDoorArray?.map(menuItem => {
                  
                  return (
                    <MenuItem  sx={
                      {
                      
                      color:"#fff",
                      backgroundColor: "#2F323A",
                      borderBottom: '.5px solid grey',
                      fontFamily: "Lato",fontSize:'12px',
                  }}
                  disabled={menuItem.disabled}
                   value={menuItem.name}>
                       <Stack direction="row" justifyContent="left"
  alignItems="center">
                  { menuItem.name == "None" && <ListItemIcon sx={{color: "red"}}>
            <DoNotDisturbIcon fontSize="small" />
          </ListItemIcon>} 
            {menuItem.name}</Stack>
                    </MenuItem>
                  );
                })}
          </Select>  */}
              </TableCell>
      <TableCell sx={mainThemeStyle.cellRow}>
              {(row*8)+7}
              </TableCell>
              <TableCell align="right"> 
              <Autocomplete
       style={{marginTop: 0,minWidth: 150}}
        sx={{  "& .MuiAutocomplete-inputRoot": {
         color: "rgba(170, 170, 170,1)" },"& .MuiSvgIcon-root": {
           color: "rgba(170, 170, 170, 1)",
         },
         "& .Mui-disabled": {
          color: 'rgba(135, 135, 135, 0.5) ',
          "& .MuiSvgIcon-root": {
           color: "rgba(135, 135, 135, 0.5)"
          }
        },
        "& .MuiInputBase-root.Mui-disabled": {
          backgroundColor: 'rgba(135, 135, 135, 0.5)',
          color: 'white',
        },
        
        }}
       disablePortal
       disabled = {getDisableValue("184") == false ? (dataOfLocation.brivo_access.brivofloors < (parseInt(row*8)+7) ? true : false) : true}
       name = {parseInt(row*8)+6}
       value={options.find(option => option.value === getBrivoValue(parseInt(row*8)+6))}
     onChange={handleChangeBrivoRelay(parseInt(row*8)+6)}
     variant="filled"
     
     options={options}
     getOptionLabel={(option) => option.label}
     PaperComponent={({ children }) => (
       <Paper style={{ backgroundColor: "#1E1E1E" ,color:"#AFAFAF", "&:hover":{backgroundColor: "#fff"}}}>{children}</Paper>
     )}
     renderInput={(params) => <TextField {...params} 
      variant="outlined" size="small" style={{ backgroundColor: "#121212",borderRadius: '5px' ,color:"#fff",
   
       
   }} />}
   renderOption={(props, option) => (
     <MenuItem
       {...props}
       key={option.id}
       sx={{
         color: "#fff",
         backgroundColor: "#2F323A",
         borderBottom: '.5px solid grey',
         fontFamily: "Lato",
         fontSize: '12px',
       }}
       disabled={option.disabled}
     >
       <Stack direction="row" justifyContent="left" alignItems="center">
         {option.label === "None" && (
           <ListItemIcon sx={{ color: "red" }}>
             <DoNotDisturbIcon fontSize="small" />
           </ListItemIcon>
         )}
         {option.label}
       </Stack>
     </MenuItem>
   )}
     ></Autocomplete>
              {/* <Select
            sx={{ color: 'rgba(135, 135, 135, 0.5)',
            height:'33px',
            marginTop:'4px',
            marginBottom:'3px',
            borderRadius: '5px',
            backgroundColor: '#121212',
            boxShadow: 'inset 0px 4px 4px rgba(0, 0, 0, 0.25)',
            padding: '5px 0 5px 0',
            "& .MuiSvgIcon-root": {
              color: "rgba(135, 135, 135, 0.5)",
            },
            
            '&.Mui-disabled': {
             

              background: 'rgba(135, 135, 135, 0.5)',
            },
            "& input.Mui-disabled": {
              color: "white"
            }
            }}
            name = {(row*8)+6}
            value={getBrivoValue((row*8)+6)}
          onChange={handleChangeBrivoRelay}
          variant="filled"
          disabled={getDisableValue("184") == false ? (dataOfLocation.brivo_access.brivofloors < (parseInt(row*8)+7) ? true : false) : true}
          hiddenLabel
          MenuProps={MenuProps}>
            {brivoDoorArray?.map(menuItem => {
                  
                  return (
                    <MenuItem  sx={
                      {
                      
                      color:"#fff",
                      backgroundColor: "#2F323A",
                      borderBottom: '.5px solid grey',
                      fontFamily: "Lato",fontSize:'12px',
                  }}
                  disabled={menuItem.disabled}
                   value={menuItem.name}>
                       <Stack direction="row" justifyContent="left"
  alignItems="center">
                  { menuItem.name == "None" && <ListItemIcon sx={{color: "red"}}>
            <DoNotDisturbIcon fontSize="small" />
          </ListItemIcon>} 
            {menuItem.name}</Stack>
                    </MenuItem>
                  );
                })}
          </Select> */} 
              </TableCell>
      <TableCell sx={mainThemeStyle.cellRow}>
              {(row*8)+8}
              </TableCell>
              <TableCell align="right"> 
              <Autocomplete
       style={{marginTop: 0,minWidth: 150}}
        sx={{  "& .MuiAutocomplete-inputRoot": {
         color: "rgba(170, 170, 170,1)" },"& .MuiSvgIcon-root": {
           color: "rgba(170, 170, 170, 1)",
         },
         "& .Mui-disabled": {
          color: 'rgba(135, 135, 135, 0.5) ',
          "& .MuiSvgIcon-root": {
           color: "rgba(135, 135, 135, 0.5)"
          }
        },
        "& .MuiInputBase-root.Mui-disabled": {
          backgroundColor: 'rgba(135, 135, 135, 0.5)',
          color: 'white',
        },
        
        }}
       disablePortal
       disabled = {getDisableValue("184") == false ? (dataOfLocation.brivo_access.brivofloors < (parseInt(row*8)+8) ? true : false) : true}
       name = {parseInt(row*8)+7}
       value={options.find(option => option.value === getBrivoValue(parseInt(row*8)+7))}
     onChange={handleChangeBrivoRelay(parseInt(row*8)+7)}
     variant="filled"
     
     options={options}
     getOptionLabel={(option) => option.label}
     PaperComponent={({ children }) => (
       <Paper style={{ backgroundColor: "#1E1E1E" ,color:"#AFAFAF", "&:hover":{backgroundColor: "#fff"}}}>{children}</Paper>
     )}
     renderInput={(params) => <TextField {...params} 
      variant="outlined" size="small" style={{ backgroundColor: "#121212",borderRadius: '5px' ,color:"#fff",
   
       
   }} />}
   renderOption={(props, option) => (
     <MenuItem
       {...props}
       key={option.id}
       sx={{
         color: "#fff",
         backgroundColor: "#2F323A",
         borderBottom: '.5px solid grey',
         fontFamily: "Lato",
         fontSize: '12px',
       }}
       disabled={option.disabled}
     >
       <Stack direction="row" justifyContent="left" alignItems="center">
         {option.label === "None" && (
           <ListItemIcon sx={{ color: "red" }}>
             <DoNotDisturbIcon fontSize="small" />
           </ListItemIcon>
         )}
         {option.label}
       </Stack>
     </MenuItem>
   )}
     ></Autocomplete>
             {/*  <Select
            sx={{ color: 'rgba(135, 135, 135, 0.5)',
            height:'33px',
            marginTop:'4px',
            marginBottom:'3px',
            borderRadius: '5px',
            backgroundColor: '#121212',
            boxShadow: 'inset 0px 4px 4px rgba(0, 0, 0, 0.25)',
            padding: '5px 0 5px 0',
            "& .MuiSvgIcon-root": {
              color: "rgba(135, 135, 135, 0.5)",
            },
            
            '&.Mui-disabled': {
             

              background: 'rgba(135, 135, 135, 0.5)',
            },
            "& input.Mui-disabled": {
              color: "white"
            }
            }}
            name = {(row*8)+7}
            value={getBrivoValue((row*8)+7)}
          onChange={handleChangeBrivoRelay}
          variant="filled"
          disabled={getDisableValue("184") == false ? (dataOfLocation.brivo_access.brivofloors < (parseInt(row*8)+8) ? true : false) : true}
          hiddenLabel
          MenuProps={MenuProps}>
            {brivoDoorArray?.map(menuItem => {
                  
                  return (
                    <MenuItem  sx={
                      {
                      
                      color:"#fff",
                      backgroundColor: "#2F323A",
                      borderBottom: '.5px solid grey',
                      fontFamily: "Lato",fontSize:'12px',
                  }}
                  disabled={menuItem.disabled}
                   value={menuItem.name}>
                      <Stack direction="row" justifyContent="left"
  alignItems="center">
                  { menuItem.name == "None" && <ListItemIcon sx={{color: "red"}}>
            <DoNotDisturbIcon fontSize="small" />
          </ListItemIcon>} 
            {menuItem.name}</Stack>
                    </MenuItem>
                  );
                })}
          </Select> */} </TableCell>
            </TableRow>
          ))}
        </TableBody>
      </Table>
    </TableContainer>
    </Box>
    <Box sx={{display: { xs: "block", md: "none" }}}>
      
    <Grid container spacing={2} padding= '10px' paddingBottom='5px' paddingTop='32px'>
    <Grid item xs={7}>
      <Stack direction="column"  spacing={2}>
      <Typography  sx={mainThemeStyle.popUpLable} height={40}>Brivo Primary Door Id</Typography>
      <Typography  sx={mainThemeStyle.popUpLable} height={40}># of Brivo Floors</Typography>
      <Typography  sx={mainThemeStyle.popUpLable} height={40}>In-Call Relay Triggers</Typography>
      </Stack>
      </Grid>
      <Grid item xs={5}>
      <Stack direction="column"  spacing={2}>

      <Autocomplete
            style={{marginTop: 0,minWidth: 250}}
            sx={{  "& .MuiAutocomplete-inputRoot": {
              color: "rgba(170, 170, 170,1)" },"& .MuiSvgIcon-root": {
                color: "rgba(170, 170, 170, 1)",
              },
             "& .Mui-disabled": {
               color: 'rgba(135, 135, 135, 0.5) ',
               "& .MuiSvgIcon-root": {
                 color: "rgba(135, 135, 135, 0.5)"
               }
             },
             "& .MuiInputBase-root.Mui-disabled": {
               backgroundColor: 'rgba(135, 135, 135, 0.5)',
               color: 'white',
             },
             
             }}
            disablePortal
            disabled = {getDisableValue("184")}
            id="brivoprimeid"
            name = 'brivoprimeid'
            value={options.find(option => option.value === getBrivoValueMain())}
          onChange={handleChangeBrivoDoorValue}
          variant="filled"
          
          options={options}
          getOptionLabel={(option) => option.label}
          PaperComponent={({ children }) => (
            <Paper style={{ backgroundColor: "#1E1E1E" ,color:"#AFAFAF", "&:hover":{backgroundColor: "#fff"}}}>{children}</Paper>
          )}
          renderInput={(params) => <TextField {...params} 
           variant="outlined" size="small" style={{ backgroundColor: "#121212",borderRadius: '5px' ,color:"#fff",
        
            
        }} />}
        renderOption={(props, option) => (
          <MenuItem
            {...props}
            key={option.id}
            sx={{
              color: "#fff",
              backgroundColor: "#2F323A",
              borderBottom: '.5px solid grey',
              fontFamily: "Lato",
              fontSize: '12px',
            }}
            disabled={option.disabled}
          >
            <Stack direction="row" justifyContent="left" alignItems="center">
              {option.label === "None" && (
                <ListItemIcon sx={{ color: "red" }}>
                  <DoNotDisturbIcon fontSize="small" />
                </ListItemIcon>
              )}
              {option.label}
            </Stack>
          </MenuItem>
        )}
          ></Autocomplete> 
      {/* <Select
            sx={{ color: 'rgba(135, 135, 135, 0.5)',
            height:'33px',
            marginTop:'4px',
            marginBottom:'3px',
            borderRadius: '5px',
            backgroundColor: '#121212',
            boxShadow: 'inset 0px 4px 4px rgba(0, 0, 0, 0.25)',
            padding: '5px 0 5px 0',
            "& .MuiSvgIcon-root": {
              color: "rgba(135, 135, 135, 0.5)",
            },
            
            '&.Mui-disabled': {
             

              background: 'rgba(135, 135, 135, 0.5)',
            },
            "& input.Mui-disabled": {
              color: "white"
            }
            }}
            disabled={getDisableValue("184")}
            name = 'brivoprimeid'
            value={getBrivoValueMain()}
          onChange={handleChangeBrivoDoorValue}
          variant="filled"
          hiddenLabel
          MenuProps={MenuProps}>
           {brivoDoorArray?.map(menuItem => {
                  
                  return (
                    <MenuItem  sx={
                      {
                      
                      color:"#fff",
                      backgroundColor: "#2F323A",
                      borderBottom: '.5px solid grey',
                      fontFamily: "Lato",fontSize:'12px',
                  }}
                  disabled={menuItem.disabled}
                   value={menuItem.name}>
                      <Stack direction="row" justifyContent="left"
  alignItems="center">
                  { menuItem.name == "None" && <ListItemIcon sx={{color: "red"}}>
            <DoNotDisturbIcon fontSize="small" />
          </ListItemIcon>} 
            {menuItem.name}</Stack>
                    </MenuItem>
                  );
                })}
          </Select> */}
          <TextField
                        id="brivofloors"
                        height={40}
                        type= 'number'
                        name="brivofloors"
                        value={dataOfLocation.brivo_access.brivofloors}
                        onChange={handleChangeBrivoFloorValue}
                        sx={mainThemeStyle.InputMenuItem}
                        InputLabelProps={{ shrink: true }
                      }
                      disabled={getDisableValue("184")}
                      variant="filled"
                        InputProps={{
                          inputProps: { 
                            max: (dataOfLocation.brivo_array.length), min: 0 
                        },
                            sx: {
                                
                                color:'rgba(135, 135, 135, 0.5)',
                                height:'33px',
                                borderRadius: '5px',
                                backgroundColor: '#121212',
                                boxShadow: 'inset 0px 4px 4px rgba(0, 0, 0, 0.25)',
                                padding: '0 0 15px 0',
                                textIndent: '15px !important',
                                '&.Mui-disabled': {
             

                                  background: 'rgba(135, 135, 135, 0.5)',
                                },
                                
                                "& input.Mui-disabled": {
                                  color: "white"
                                }
                               
                                
                            },
                        }}
                       
                    />
        <FormControlLabel   sx={mainThemeStyle.popUpLableNewone}
        control={<IOSSwitch sx={{ m: 1 }} name="icrt"  disabled = {getDisableValue("184")} onChange={handleBrivoInCallRelayTriggerCheck}  checked={dataOfLocation.brivo_access.icrt == null ? false : (dataOfLocation.brivo_access.icrt == "1" ? true : false)} />}
        label={dataOfLocation.brivo_access.icrt == null ? "Disabled" : (dataOfLocation.brivo_access.icrt == "1" ? "Enabled" : "Disabled")}
      />
      
        
      </Stack>
      </Grid>
      <Grid item xs={2}></Grid>
     
      </Grid>
      { dataOfLocation.brivo_access.icrt == 1 &&  <Grid container spacing={2} padding= '10px' paddingBottom='5px' paddingTop='32px'>
      <Grid item >
      <TableContainer component={Paper} sx={{
        width:'auto',
        height: 'auto',
        marginLeft: '38px',
        marginRight:'38px',
        marginBottom:'38px',
        backgroundColor:'#171E29',
       
        }}>
      <Table sx={{ minWidth: 350 }} >
        
        <TableHead sx={{ borderBottom: "1.5px solid black",
        }}>
          <TableRow>
            
            <TableCell align="center" sx={mainThemeStyle.popUpLable} colSpan={16}>In-Call Relay Trigger Settings</TableCell>
            
          </TableRow>
         
        </TableHead>

        
        <TableHead sx={{ borderBottom: "1.5px solid black",
        }}>
          </TableHead>
        <TableBody>
       
            <TableRow sx={{ borderBottom: "1.5px solid black",
          }}>
              <TableCell  sx={mainThemeStyle.cellHeader}>
              Custom Name 1 
              </TableCell>
             
                <TableCell align="right">
                <TextField
                        id="0"
                       
                        type= 'text'
                        name="0"
                        value={getCustomNameValue(0)}
                        onChange={handleChangeCustomNameValue}
                       
                        sx={{
                          ...mainThemeStyle.InputMenuItem,
                          marginTop:'10px',
                        }}
                        InputLabelProps={{ shrink: true }
                      }
                      disabled = {getDisableValue("184")}
                      variant="filled"
                        InputProps={{
                          
                            sx: {
                                
                                color:'rgba(135, 135, 135, 0.5)',
                                height:'33px',
                                borderRadius: '5px',
                                backgroundColor: '#121212',
                                boxShadow: 'inset 0px 4px 4px rgba(0, 0, 0, 0.25)',
                                padding: '0 0 15px 0',
                                textIndent: '15px !important',
                                '&.Mui-disabled': {
             

                                  background: 'rgba(135, 135, 135, 0.5)',
                                },
                                
                                "& input.Mui-disabled": {
                                  color: "white"
                                }
                               
                                
                            },
                        }}
                       
                    />
                    
                </TableCell>
                <TableCell align="right">
                
                <Select
              sx={{ color: 'rgba(135, 135, 135, 0.5)',
              height:'33px',
              marginTop:'8px',
              marginBottom:'3px',
              borderRadius: '5px',
              backgroundColor: '#121212',
              boxShadow: 'inset 0px 4px 4px rgba(0, 0, 0, 0.25)',
              padding: '5px 0 5px 0',
              "& .MuiSvgIcon-root": {
                color: "rgba(135, 135, 135, 0.5)",
              },
              
              '&.Mui-disabled': {
               
  
                background: 'rgba(135, 135, 135, 0.5)',
              },
              "& input.Mui-disabled": {
                color: "white"
              }
              }}
              name = "0"
              value={getCustomDoorValue(0)}
            onChange={handleChangeCustomDoorValue}
            variant="filled"
            disabled={getDisableValue("184")}
            hiddenLabel
            MenuProps={MenuProps}>
              {brivoCustomArray?.map(menuItem => {
                    
                    return (
                      <MenuItem  sx={
                        {
                        
                        color:"#fff",
                        backgroundColor: "#2F323A",
                        borderBottom: '.5px solid grey',
                        fontFamily: "Lato",fontSize:'12px',
                    }}
                    disabled={menuItem.disabled}
                     value={menuItem.name}>
                         <Stack direction="row" justifyContent="left"
    alignItems="center">
                    { menuItem.name == "None" && <ListItemIcon sx={{color: "red"}}>
              <DoNotDisturbIcon fontSize="small" />
            </ListItemIcon>} 
              {menuItem.name}</Stack>
                      </MenuItem>
                    );
                  })}
            </Select>
                  </TableCell>
            </TableRow>
            <TableRow sx={{ borderBottom: "1.5px solid black",
          }}>
              <TableCell  sx={mainThemeStyle.cellHeader}>
              Custom Name 2 
              </TableCell>
             
                <TableCell align="right">
                <TextField
                        id="1"
                       
                        type= 'text'
                        name="1"
                        value={getCustomNameValue(1)}
                        onChange={handleChangeCustomNameValue}
                       
                        sx={{
                          ...mainThemeStyle.InputMenuItem,
                          marginTop:'10px',
                        }}
                        InputLabelProps={{ shrink: true }
                      }
                      disabled = {getDisableValue("184")}
                      variant="filled"
                        InputProps={{
                          
                            sx: {
                                
                                color:'rgba(135, 135, 135, 0.5)',
                                height:'33px',
                                borderRadius: '5px',
                                backgroundColor: '#121212',
                                boxShadow: 'inset 0px 4px 4px rgba(0, 0, 0, 0.25)',
                                padding: '0 0 15px 0',
                                textIndent: '15px !important',
                                '&.Mui-disabled': {
             

                                  background: 'rgba(135, 135, 135, 0.5)',
                                },
                                
                                "& input.Mui-disabled": {
                                  color: "white"
                                }
                               
                                
                            },
                        }}
                       
                    />
                    
                </TableCell>
                <TableCell align="right">
                
                <Select
              sx={{ color: 'rgba(135, 135, 135, 0.5)',
              height:'33px',
              marginTop:'8px',
              marginBottom:'3px',
              borderRadius: '5px',
              backgroundColor: '#121212',
              boxShadow: 'inset 0px 4px 4px rgba(0, 0, 0, 0.25)',
              padding: '5px 0 5px 0',
              "& .MuiSvgIcon-root": {
                color: "rgba(135, 135, 135, 0.5)",
              },
              
              '&.Mui-disabled': {
               
  
                background: 'rgba(135, 135, 135, 0.5)',
              },
              "& input.Mui-disabled": {
                color: "white"
              }
              }}
              name = "1"
              value={getCustomDoorValue(1)}
            onChange={handleChangeCustomDoorValue}
            variant="filled"
            disabled={getDisableValue("184")}
            hiddenLabel
            MenuProps={MenuProps}>
              {brivoCustomArray?.map(menuItem => {
                    
                    return (
                      <MenuItem  sx={
                        {
                        
                        color:"#fff",
                        backgroundColor: "#2F323A",
                        borderBottom: '.5px solid grey',
                        fontFamily: "Lato",fontSize:'12px',
                    }}
                    disabled={menuItem.disabled}
                     value={menuItem.name}>
                         <Stack direction="row" justifyContent="left"
    alignItems="center">
                    { menuItem.name == "None" && <ListItemIcon sx={{color: "red"}}>
              <DoNotDisturbIcon fontSize="small" />
            </ListItemIcon>} 
              {menuItem.name}</Stack>
                      </MenuItem>
                    );
                  })}
            </Select>
                  </TableCell>
            </TableRow>
            <TableRow sx={{ borderBottom: "1.5px solid black",
          }}>
              <TableCell  sx={mainThemeStyle.cellHeader}>
              Custom Name 3 
              </TableCell>
             
                <TableCell align="right">
                <TextField
                        id="2"
                       
                        type= 'text'
                        name="2"
                        value={getCustomNameValue(2)}
                        onChange={handleChangeCustomNameValue}
                       
                        sx={{
                          ...mainThemeStyle.InputMenuItem,
                          marginTop:'10px',
                        }}
                        InputLabelProps={{ shrink: true }
                      }
                      disabled = {getDisableValue("184")}
                      variant="filled"
                        InputProps={{
                          
                            sx: {
                                
                                color:'rgba(135, 135, 135, 0.5)',
                                height:'33px',
                                borderRadius: '5px',
                                backgroundColor: '#121212',
                                boxShadow: 'inset 0px 4px 4px rgba(0, 0, 0, 0.25)',
                                padding: '0 0 15px 0',
                                textIndent: '15px !important',
                                '&.Mui-disabled': {
             

                                  background: 'rgba(135, 135, 135, 0.5)',
                                },
                                
                                "& input.Mui-disabled": {
                                  color: "white"
                                }
                               
                                
                            },
                        }}
                       
                    />
                    
                </TableCell>
                <TableCell align="right">
                
                <Select
              sx={{ color: 'rgba(135, 135, 135, 0.5)',
              height:'33px',
              marginTop:'8px',
              marginBottom:'3px',
              borderRadius: '5px',
              backgroundColor: '#121212',
              boxShadow: 'inset 0px 4px 4px rgba(0, 0, 0, 0.25)',
              padding: '5px 0 5px 0',
              "& .MuiSvgIcon-root": {
                color: "rgba(135, 135, 135, 0.5)",
              },
              
              '&.Mui-disabled': {
               
  
                background: 'rgba(135, 135, 135, 0.5)',
              },
              "& input.Mui-disabled": {
                color: "white"
              }
              }}
              name = "2"
              value={getCustomDoorValue(2)}
            onChange={handleChangeCustomDoorValue}
            variant="filled"
            disabled={getDisableValue("184")}
            hiddenLabel
            MenuProps={MenuProps}>
              {brivoCustomArray?.map(menuItem => {
                    
                    return (
                      <MenuItem  sx={
                        {
                        
                        color:"#fff",
                        backgroundColor: "#2F323A",
                        borderBottom: '.5px solid grey',
                        fontFamily: "Lato",fontSize:'12px',
                    }}
                    disabled={menuItem.disabled}
                     value={menuItem.name}>
                         <Stack direction="row" justifyContent="left"
    alignItems="center">
                    { menuItem.name == "None" && <ListItemIcon sx={{color: "red"}}>
              <DoNotDisturbIcon fontSize="small" />
            </ListItemIcon>} 
              {menuItem.name}</Stack>
                      </MenuItem>
                    );
                  })}
            </Select>
                  </TableCell>
            </TableRow>
        </TableBody>
      </Table>
    </TableContainer></Grid></Grid>
    }
      <TableContainer component={Paper} sx={{
        width:'auto',
        height: 'auto',
        marginLeft: '10px',
        marginRight:'10px',
        marginBottom:'38px',
        backgroundColor:'#171E29',
       
        }}>
      <Table sx={{ minWidth: 650 }} >
        
        <TableHead sx={{ borderBottom: "1.5px solid black",
        }}>
          <TableRow>
            
            <TableCell align="center" sx={mainThemeStyle.popUpLable} colSpan={16}>Brivo Relays</TableCell>
            
          </TableRow>
         
        </TableHead>

        
        <TableHead sx={{ borderBottom: "1.5px solid black",
        }}>
          <TableRow>
          <TableCell align="right" sx={mainThemeStyle.cellHeader}> Floor</TableCell>
            <TableCell align="right" sx={mainThemeStyle.cellHeader}>	Door Id</TableCell>
            <TableCell align="right" sx={mainThemeStyle.cellHeader}> Floor</TableCell>
            <TableCell align="right" sx={mainThemeStyle.cellHeader}>Door Id</TableCell>
            <TableCell align="right" sx={mainThemeStyle.cellHeader}> Floor</TableCell>
            <TableCell align="right" sx={mainThemeStyle.cellHeader}>Door Id</TableCell>
            <TableCell align="right" sx={mainThemeStyle.cellHeader}> Floor</TableCell>
            <TableCell align="right" sx={mainThemeStyle.cellHeader}>Door Id</TableCell>
            <TableCell align="right" sx={mainThemeStyle.cellHeader}> Floor</TableCell>
            <TableCell align="right" sx={mainThemeStyle.cellHeader}>Door Id</TableCell>
            <TableCell align="right" sx={mainThemeStyle.cellHeader}> Floor</TableCell>
            <TableCell align="right" sx={mainThemeStyle.cellHeader}>Door Id</TableCell>
            <TableCell align="right" sx={mainThemeStyle.cellHeader}> Floor</TableCell>
            <TableCell align="right" sx={mainThemeStyle.cellHeader}>Door Id</TableCell>
            <TableCell align="right" sx={mainThemeStyle.cellHeader}> Floor</TableCell>
            <TableCell align="right" sx={mainThemeStyle.cellHeader}>Door Id</TableCell>
          </TableRow></TableHead>
        <TableBody>
         {rowsElevatorControl.map((row) => (
            <TableRow sx={{ borderBottom: "1.5px solid black",
          }}>
              <TableCell sx={mainThemeStyle.cellRow}>
              {parseInt(row*8)+1}
              </TableCell>
              <TableCell align="right">
                 <Autocomplete
       style={{marginTop: 0,minWidth: 150}}
        sx={{  "& .MuiAutocomplete-inputRoot": {
         color: "rgba(170, 170, 170,1)" },"& .MuiSvgIcon-root": {
           color: "rgba(170, 170, 170, 1)",
         },
         "& .Mui-disabled": {
          color: 'rgba(135, 135, 135, 0.5) ',
          "& .MuiSvgIcon-root": {
           color: "rgba(135, 135, 135, 0.5)"
          }
        },
        "& .MuiInputBase-root.Mui-disabled": {
          backgroundColor: 'rgba(135, 135, 135, 0.5)',
          color: 'white',
        },
        
        }}
       disablePortal
       disabled = {getDisableValue("184") == false ? (dataOfLocation.brivo_access.brivofloors < (parseInt(row*8)+1) ? true : false) : true}
       name = {parseInt(row*8)}
       value={options.find(option => option.value === getBrivoValue(parseInt(row*8)))}
     onChange={handleChangeBrivoRelay(parseInt(row*8))}
     variant="filled"
     
     options={options}
     getOptionLabel={(option) => option.label}
     PaperComponent={({ children }) => (
       <Paper style={{ backgroundColor: "#1E1E1E" ,color:"#AFAFAF", "&:hover":{backgroundColor: "#fff"}}}>{children}</Paper>
     )}
     renderInput={(params) => <TextField {...params} 
      variant="outlined" size="small" style={{ backgroundColor: "#121212",borderRadius: '5px' ,color:"#fff",
   
       
   }} />}
   renderOption={(props, option) => (
     <MenuItem
       {...props}
       key={option.id}
       sx={{
         color: "#fff",
         backgroundColor: "#2F323A",
         borderBottom: '.5px solid grey',
         fontFamily: "Lato",
         fontSize: '12px',
       }}
       disabled={option.disabled}
     >
       <Stack direction="row" justifyContent="left" alignItems="center">
         {option.label === "None" && (
           <ListItemIcon sx={{ color: "red" }}>
             <DoNotDisturbIcon fontSize="small" />
           </ListItemIcon>
         )}
         {option.label}
       </Stack>
     </MenuItem>
   )}
     ></Autocomplete>
              {/* <Select
            sx={{ color: 'rgba(135, 135, 135, 0.5)',
            height:'33px',
            marginTop:'4px',
            marginBottom:'3px',
            borderRadius: '5px',
            backgroundColor: '#121212',
            boxShadow: 'inset 0px 4px 4px rgba(0, 0, 0, 0.25)',
            padding: '5px 0 5px 0',
            "& .MuiSvgIcon-root": {
              color: "rgba(135, 135, 135, 0.5)",
            },
            
            '&.Mui-disabled': {
             

              background: 'rgba(135, 135, 135, 0.5)',
            },
            "& input.Mui-disabled": {
              color: "white"
            }
            }}
            name = {parseInt(row*8)}
            value={getBrivoValue(parseInt(row*8))}
          onChange={handleChangeBrivoRelay}
          variant="filled"
          disabled={getDisableValue("184") == false ? (dataOfLocation.brivo_access.brivofloors < (parseInt(row*8)+1) ? true : false) : true}
          hiddenLabel
          MenuProps={MenuProps}>
            {brivoDoorArray?.map(menuItem => {
                  
                  return (
                    <MenuItem  sx={
                      {
                      
                      color:"#fff",
                      backgroundColor: "#2F323A",
                      borderBottom: '.5px solid grey',
                      fontFamily: "Lato",fontSize:'12px',
                  }}
                  disabled={menuItem.disabled}
                   value={menuItem.name}>
                       <Stack direction="row" justifyContent="left"
  alignItems="center">
                  { menuItem.name == "None" && <ListItemIcon sx={{color: "red"}}>
            <DoNotDisturbIcon fontSize="small" />
          </ListItemIcon>} 
            {menuItem.name}</Stack>
                    </MenuItem>
                  );
                })}
          </Select> */}
                </TableCell>
              <TableCell sx={mainThemeStyle.cellRow}>
              {parseInt(row*8)+2}
              </TableCell>
              <TableCell align="right">
              <Autocomplete
       style={{marginTop: 0,minWidth: 150}}
        sx={{  "& .MuiAutocomplete-inputRoot": {
         color: "rgba(170, 170, 170,1)" },"& .MuiSvgIcon-root": {
           color: "rgba(170, 170, 170, 1)",
         },
         "& .Mui-disabled": {
          color: 'rgba(135, 135, 135, 0.5) ',
          "& .MuiSvgIcon-root": {
           color: "rgba(135, 135, 135, 0.5)"
          }
        },
        "& .MuiInputBase-root.Mui-disabled": {
          backgroundColor: 'rgba(135, 135, 135, 0.5)',
          color: 'white',
        },
        
        }}
       disablePortal
       disabled = {getDisableValue("184") == false ? (dataOfLocation.brivo_access.brivofloors < (parseInt(row*8)+2) ? true : false) : true}
       name = {parseInt(row*8)+1}
       value={options.find(option => option.value === getBrivoValue(parseInt(row*8)+1))}
     onChange={handleChangeBrivoRelay(parseInt(row*8)+1)}
     variant="filled"
     
     options={options}
     getOptionLabel={(option) => option.label}
     PaperComponent={({ children }) => (
       <Paper style={{ backgroundColor: "#1E1E1E" ,color:"#AFAFAF", "&:hover":{backgroundColor: "#fff"}}}>{children}</Paper>
     )}
     renderInput={(params) => <TextField {...params} 
      variant="outlined" size="small" style={{ backgroundColor: "#121212",borderRadius: '5px' ,color:"#fff",
   
       
   }} />}
   renderOption={(props, option) => (
     <MenuItem
       {...props}
       key={option.id}
       sx={{
         color: "#fff",
         backgroundColor: "#2F323A",
         borderBottom: '.5px solid grey',
         fontFamily: "Lato",
         fontSize: '12px',
       }}
       disabled={option.disabled}
     >
       <Stack direction="row" justifyContent="left" alignItems="center">
         {option.label === "None" && (
           <ListItemIcon sx={{ color: "red" }}>
             <DoNotDisturbIcon fontSize="small" />
           </ListItemIcon>
         )}
         {option.label}
       </Stack>
     </MenuItem>
   )}
     ></Autocomplete>
              {/* <Select
            sx={{ color: 'rgba(135, 135, 135, 0.5)',
            height:'33px',
            marginTop:'4px',
            marginBottom:'3px',
            borderRadius: '5px',
            backgroundColor: '#121212',
            boxShadow: 'inset 0px 4px 4px rgba(0, 0, 0, 0.25)',
            padding: '5px 0 5px 0',
            "& .MuiSvgIcon-root": {
              color: "rgba(135, 135, 135, 0.5)",
            },
            
            '&.Mui-disabled': {
             

              background: 'rgba(135, 135, 135, 0.5)',
            },
            "& input.Mui-disabled": {
              color: "white"
            }
            }}
            name = {(row*8)+1}
            value={getBrivoValue(parseInt(row*8)+1)}
          onChange={handleChangeBrivoRelay}
          variant="filled"
          disabled={getDisableValue("184") == false ? (dataOfLocation.brivo_access.brivofloors < (parseInt(row*8)+2) ? true : false) : true}
          hiddenLabel
          MenuProps={MenuProps}>
           {brivoDoorArray?.map(menuItem => {
                  
                  return (
                    <MenuItem  sx={
                      {
                      
                      color:"#fff",
                      backgroundColor: "#2F323A",
                      borderBottom: '.5px solid grey',
                      fontFamily: "Lato",fontSize:'12px',
                  }}
                  disabled={menuItem.disabled}
                   value={menuItem.name}>
                        <Stack direction="row" justifyContent="left"
  alignItems="center">
                  { menuItem.name == "None" && <ListItemIcon sx={{color: "red"}}>
            <DoNotDisturbIcon fontSize="small" />
          </ListItemIcon>} 
            {menuItem.name}</Stack>
                    </MenuItem>
                  );
                })}
          </Select> */}
                
                
               </TableCell>
      <TableCell sx={mainThemeStyle.cellRow}>
              {parseInt(row*8)+3}
              </TableCell>
              <TableCell align="right"> 
              <Autocomplete
       style={{marginTop: 0,minWidth: 150}}
        sx={{  "& .MuiAutocomplete-inputRoot": {
         color: "rgba(170, 170, 170,1)" },"& .MuiSvgIcon-root": {
           color: "rgba(170, 170, 170, 1)",
         },
         "& .Mui-disabled": {
          color: 'rgba(135, 135, 135, 0.5) ',
          "& .MuiSvgIcon-root": {
           color: "rgba(135, 135, 135, 0.5)"
          }
        },
        "& .MuiInputBase-root.Mui-disabled": {
          backgroundColor: 'rgba(135, 135, 135, 0.5)',
          color: 'white',
        },
        
        }}
       disablePortal
       disabled = {getDisableValue("184") == false ? (dataOfLocation.brivo_access.brivofloors < (parseInt(row*8)+3) ? true : false) : true}
       name = {parseInt(row*8)+2}
       value={options.find(option => option.value === getBrivoValue(parseInt(row*8)+2))}
     onChange={handleChangeBrivoRelay(parseInt(row*8)+2)}
     variant="filled"
     
     options={options}
     getOptionLabel={(option) => option.label}
     PaperComponent={({ children }) => (
       <Paper style={{ backgroundColor: "#1E1E1E" ,color:"#AFAFAF", "&:hover":{backgroundColor: "#fff"}}}>{children}</Paper>
     )}
     renderInput={(params) => <TextField {...params} 
      variant="outlined" size="small" style={{ backgroundColor: "#121212",borderRadius: '5px' ,color:"#fff",
   
       
   }} />}
   renderOption={(props, option) => (
     <MenuItem
       {...props}
       key={option.id}
       sx={{
         color: "#fff",
         backgroundColor: "#2F323A",
         borderBottom: '.5px solid grey',
         fontFamily: "Lato",
         fontSize: '12px',
       }}
       disabled={option.disabled}
     >
       <Stack direction="row" justifyContent="left" alignItems="center">
         {option.label === "None" && (
           <ListItemIcon sx={{ color: "red" }}>
             <DoNotDisturbIcon fontSize="small" />
           </ListItemIcon>
         )}
         {option.label}
       </Stack>
     </MenuItem>
   )}
     ></Autocomplete>
              {/* <Select
            sx={{ color: 'rgba(135, 135, 135, 0.5)',
            height:'33px',
            marginTop:'4px',
            marginBottom:'3px',
            borderRadius: '5px',
            backgroundColor: '#121212',
            boxShadow: 'inset 0px 4px 4px rgba(0, 0, 0, 0.25)',
            padding: '5px 0 5px 0',
            "& .MuiSvgIcon-root": {
              color: "rgba(135, 135, 135, 0.5)",
            },
            
            '&.Mui-disabled': {
             

              background: 'rgba(135, 135, 135, 0.5)',
            },
            "& input.Mui-disabled": {
              color: "white"
            }
            }}
            name = {(row*8)+2}
            value={getBrivoValue((row*8)+2)}
          onChange={handleChangeBrivoRelay}
          variant="filled"
          disabled={getDisableValue("184") == false ? (dataOfLocation.brivo_access.brivofloors < (parseInt(row*8)+3) ? true : false) : true}
          hiddenLabel
          MenuProps={MenuProps}>
           {brivoDoorArray?.map(menuItem => {
                  
                  return (
                    <MenuItem  sx={
                      {
                      
                      color:"#fff",
                      backgroundColor: "#2F323A",
                      borderBottom: '.5px solid grey',
                      fontFamily: "Lato",fontSize:'12px',
                  }}
                  disabled={menuItem.disabled}
                   value={menuItem.name}>
                       <Stack direction="row" justifyContent="left"
  alignItems="center">
                  { menuItem.name == "None" && <ListItemIcon sx={{color: "red"}}>
            <DoNotDisturbIcon fontSize="small" />
          </ListItemIcon>} 
            {menuItem.name}</Stack>
                    </MenuItem>
                  );
                })}
          </Select> */}
              
              </TableCell>
      <TableCell sx={mainThemeStyle.cellRow}>
              {(row*8)+4}
              </TableCell>
              <TableCell align="right"> 
              <Autocomplete
       style={{marginTop: 0,minWidth: 150}}
        sx={{  "& .MuiAutocomplete-inputRoot": {
         color: "rgba(170, 170, 170,1)" },"& .MuiSvgIcon-root": {
           color: "rgba(170, 170, 170, 1)",
         },
         "& .Mui-disabled": {
          color: 'rgba(135, 135, 135, 0.5) ',
          "& .MuiSvgIcon-root": {
           color: "rgba(135, 135, 135, 0.5)"
          }
        },
        "& .MuiInputBase-root.Mui-disabled": {
          backgroundColor: 'rgba(135, 135, 135, 0.5)',
          color: 'white',
        },
        
        }}
       disablePortal
       disabled = {getDisableValue("184") == false ? (dataOfLocation.brivo_access.brivofloors < (parseInt(row*8)+4) ? true : false) : true}
       name = {parseInt(row*8)+3}
       value={options.find(option => option.value === getBrivoValue(parseInt(row*8)+3))}
     onChange={handleChangeBrivoRelay(parseInt(row*8)+3)}
     variant="filled"
     
     options={options}
     getOptionLabel={(option) => option.label}
     PaperComponent={({ children }) => (
       <Paper style={{ backgroundColor: "#1E1E1E" ,color:"#AFAFAF", "&:hover":{backgroundColor: "#fff"}}}>{children}</Paper>
     )}
     renderInput={(params) => <TextField {...params} 
      variant="outlined" size="small" style={{ backgroundColor: "#121212",borderRadius: '5px' ,color:"#fff",
   
       
   }} />}
   renderOption={(props, option) => (
     <MenuItem
       {...props}
       key={option.id}
       sx={{
         color: "#fff",
         backgroundColor: "#2F323A",
         borderBottom: '.5px solid grey',
         fontFamily: "Lato",
         fontSize: '12px',
       }}
       disabled={option.disabled}
     >
       <Stack direction="row" justifyContent="left" alignItems="center">
         {option.label === "None" && (
           <ListItemIcon sx={{ color: "red" }}>
             <DoNotDisturbIcon fontSize="small" />
           </ListItemIcon>
         )}
         {option.label}
       </Stack>
     </MenuItem>
   )}
     ></Autocomplete>
              {/* <Select
            sx={{ color: 'rgba(135, 135, 135, 0.5)',
            height:'33px',
            marginTop:'4px',
            marginBottom:'3px',
            borderRadius: '5px',
            backgroundColor: '#121212',
            boxShadow: 'inset 0px 4px 4px rgba(0, 0, 0, 0.25)',
            padding: '5px 0 5px 0',
            "& .MuiSvgIcon-root": {
              color: "rgba(135, 135, 135, 0.5)",
            },
            
            '&.Mui-disabled': {
             

              background: 'rgba(135, 135, 135, 0.5)',
            },
            "& input.Mui-disabled": {
              color: "white"
            }
            }}
            name = {(row*8)+3}
            value={getBrivoValue((row*8)+3)}
          onChange={handleChangeBrivoRelay}
          variant="filled"
          disabled={getDisableValue("184") == false ? (dataOfLocation.brivo_access.brivofloors < (parseInt(row*8)+4) ? true : false) : true}
          hiddenLabel
          MenuProps={MenuProps}>
            {brivoDoorArray?.map(menuItem => {
                  
                  return (
                    <MenuItem  sx={
                      {
                      
                      color:"#fff",
                      backgroundColor: "#2F323A",
                      borderBottom: '.5px solid grey',
                      fontFamily: "Lato",fontSize:'12px',
                  }}
                  disabled={menuItem.disabled}
                   value={menuItem.name}>
                     <Stack direction="row" justifyContent="left"
  alignItems="center">
                  { menuItem.name == "None" && <ListItemIcon sx={{color: "red"}}>
            <DoNotDisturbIcon fontSize="small" />
          </ListItemIcon>} 
            {menuItem.name}</Stack>
                    </MenuItem>
                  );
                })}
          </Select> */}
              </TableCell>
      <TableCell sx={mainThemeStyle.cellRow}>
              {(row*8)+5}
              </TableCell>
              <TableCell align="right">
                     {/*  <Select
            sx={{ color: 'rgba(135, 135, 135, 0.5)',
            height:'33px',
            marginTop:'4px',
            marginBottom:'3px',
            borderRadius: '5px',
            backgroundColor: '#121212',
            boxShadow: 'inset 0px 4px 4px rgba(0, 0, 0, 0.25)',
            padding: '5px 0 5px 0',
            "& .MuiSvgIcon-root": {
              color: "rgba(135, 135, 135, 0.5)",
            },
            
            '&.Mui-disabled': {
             

              background: 'rgba(135, 135, 135, 0.5)',
            },
            "& input.Mui-disabled": {
              color: "white"
            }
            }}
            name = {(row*8)+4}
            value={getBrivoValue((row*8)+4)}
          onChange={handleChangeBrivoRelay}
          variant="filled"
          disabled={getDisableValue("184") == false ? (dataOfLocation.brivo_access.brivofloors < (parseInt(row*8)+5) ? true : false) : true}
          hiddenLabel
          MenuProps={MenuProps}>
           {brivoDoorArray?.map(menuItem => {
                  
                  return (
                    <MenuItem  sx={
                      {
                      
                      color:"#fff",
                      backgroundColor: "#2F323A",
                      borderBottom: '.5px solid grey',
                      fontFamily: "Lato",fontSize:'12px',
                  }}
                  disabled={menuItem.disabled}
                   value={menuItem.name}>
                       <Stack direction="row" justifyContent="left"
  alignItems="center">
                  { menuItem.name == "None" && <ListItemIcon sx={{color: "red"}}>
            <DoNotDisturbIcon fontSize="small" />
          </ListItemIcon>} 
            {menuItem.name}</Stack>
                    </MenuItem>
                  );
                })}
          </Select>  */}
          <Autocomplete
       style={{marginTop: 0,minWidth: 150}}
        sx={{  "& .MuiAutocomplete-inputRoot": {
         color: "rgba(170, 170, 170,1)" },"& .MuiSvgIcon-root": {
           color: "rgba(170, 170, 170, 1)",
         },
         "& .Mui-disabled": {
          color: 'rgba(135, 135, 135, 0.5) ',
          "& .MuiSvgIcon-root": {
           color: "rgba(135, 135, 135, 0.5)"
          }
        },
        "& .MuiInputBase-root.Mui-disabled": {
          backgroundColor: 'rgba(135, 135, 135, 0.5)',
          color: 'white',
        },
        
        }}
       disablePortal
       disabled = {getDisableValue("184") == false ? (dataOfLocation.brivo_access.brivofloors < (parseInt(row*8)+5) ? true : false) : true}
       name = {parseInt(row*8)+4}
       value={options.find(option => option.value === getBrivoValue(parseInt(row*8)+4))}
     onChange={handleChangeBrivoRelay(parseInt(row*8)+4)}
     variant="filled"
     
     options={options}
     getOptionLabel={(option) => option.label}
     PaperComponent={({ children }) => (
       <Paper style={{ backgroundColor: "#1E1E1E" ,color:"#AFAFAF", "&:hover":{backgroundColor: "#fff"}}}>{children}</Paper>
     )}
     renderInput={(params) => <TextField {...params} 
      variant="outlined" size="small" style={{ backgroundColor: "#121212",borderRadius: '5px' ,color:"#fff",
   
       
   }} />}
   renderOption={(props, option) => (
     <MenuItem
       {...props}
       key={option.id}
       sx={{
         color: "#fff",
         backgroundColor: "#2F323A",
         borderBottom: '.5px solid grey',
         fontFamily: "Lato",
         fontSize: '12px',
       }}
       disabled={option.disabled}
     >
       <Stack direction="row" justifyContent="left" alignItems="center">
         {option.label === "None" && (
           <ListItemIcon sx={{ color: "red" }}>
             <DoNotDisturbIcon fontSize="small" />
           </ListItemIcon>
         )}
         {option.label}
       </Stack>
     </MenuItem>
   )}
     ></Autocomplete>
                </TableCell>
      <TableCell sx={mainThemeStyle.cellRow}>
              {(row*8)+6}
              </TableCell>
              <TableCell align="right"> 
              <Autocomplete
       style={{marginTop: 0,minWidth: 150}}
        sx={{  "& .MuiAutocomplete-inputRoot": {
         color: "rgba(170, 170, 170,1)" },"& .MuiSvgIcon-root": {
           color: "rgba(170, 170, 170, 1)",
         },
         "& .Mui-disabled": {
          color: 'rgba(135, 135, 135, 0.5) ',
          "& .MuiSvgIcon-root": {
           color: "rgba(135, 135, 135, 0.5)"
          }
        },
        "& .MuiInputBase-root.Mui-disabled": {
          backgroundColor: 'rgba(135, 135, 135, 0.5)',
          color: 'white',
        },
        
        }}
       disablePortal
       disabled = {getDisableValue("184") == false ? (dataOfLocation.brivo_access.brivofloors < (parseInt(row*8)+6) ? true : false) : true}
       name = {parseInt(row*8)+5}
       value={options.find(option => option.value === getBrivoValue(parseInt(row*8)+5))}
     onChange={handleChangeBrivoRelay(parseInt(row*8)+5)}
     variant="filled"
     
     options={options}
     getOptionLabel={(option) => option.label}
     PaperComponent={({ children }) => (
       <Paper style={{ backgroundColor: "#1E1E1E" ,color:"#AFAFAF", "&:hover":{backgroundColor: "#fff"}}}>{children}</Paper>
     )}
     renderInput={(params) => <TextField {...params} 
      variant="outlined" size="small" style={{ backgroundColor: "#121212",borderRadius: '5px' ,color:"#fff",
   
       
   }} />}
   renderOption={(props, option) => (
     <MenuItem
       {...props}
       key={option.id}
       sx={{
         color: "#fff",
         backgroundColor: "#2F323A",
         borderBottom: '.5px solid grey',
         fontFamily: "Lato",
         fontSize: '12px',
       }}
       disabled={option.disabled}
     >
       <Stack direction="row" justifyContent="left" alignItems="center">
         {option.label === "None" && (
           <ListItemIcon sx={{ color: "red" }}>
             <DoNotDisturbIcon fontSize="small" />
           </ListItemIcon>
         )}
         {option.label}
       </Stack>
     </MenuItem>
   )}
     ></Autocomplete>
              {/* <Select
            sx={{ color: 'rgba(135, 135, 135, 0.5)',
            height:'33px',
            marginTop:'4px',
            marginBottom:'3px',
            borderRadius: '5px',
            backgroundColor: '#121212',
            boxShadow: 'inset 0px 4px 4px rgba(0, 0, 0, 0.25)',
            padding: '5px 0 5px 0',
            "& .MuiSvgIcon-root": {
              color: "rgba(135, 135, 135, 0.5)",
            },
            
            '&.Mui-disabled': {
             

              background: 'rgba(135, 135, 135, 0.5)',
            },
            "& input.Mui-disabled": {
              color: "white"
            }
            }}
            name = {(row*8)+5}
            value={getBrivoValue((row*8)+5)}
          onChange={handleChangeBrivoRelay}
          variant="filled"
          disabled={getDisableValue("184") == false ? (dataOfLocation.brivo_access.brivofloors < (parseInt(row*8)+6) ? true : false) : true}
          hiddenLabel
          MenuProps={MenuProps}>
           {brivoDoorArray?.map(menuItem => {
                  
                  return (
                    <MenuItem  sx={
                      {
                      
                      color:"#fff",
                      backgroundColor: "#2F323A",
                      borderBottom: '.5px solid grey',
                      fontFamily: "Lato",fontSize:'12px',
                  }}
                  disabled={menuItem.disabled}
                   value={menuItem.name}>
                       <Stack direction="row" justifyContent="left"
  alignItems="center">
                  { menuItem.name == "None" && <ListItemIcon sx={{color: "red"}}>
            <DoNotDisturbIcon fontSize="small" />
          </ListItemIcon>} 
            {menuItem.name}</Stack>
                    </MenuItem>
                  );
                })}
          </Select>  */}
              </TableCell>
      <TableCell sx={mainThemeStyle.cellRow}>
              {(row*8)+7}
              </TableCell>
              <TableCell align="right"> 
              <Autocomplete
       style={{marginTop: 0,minWidth: 150}}
        sx={{  "& .MuiAutocomplete-inputRoot": {
         color: "rgba(170, 170, 170,1)" },"& .MuiSvgIcon-root": {
           color: "rgba(170, 170, 170, 1)",
         },
         "& .Mui-disabled": {
          color: 'rgba(135, 135, 135, 0.5) ',
          "& .MuiSvgIcon-root": {
           color: "rgba(135, 135, 135, 0.5)"
          }
        },
        "& .MuiInputBase-root.Mui-disabled": {
          backgroundColor: 'rgba(135, 135, 135, 0.5)',
          color: 'white',
        },
        
        }}
       disablePortal
       disabled = {getDisableValue("184") == false ? (dataOfLocation.brivo_access.brivofloors < (parseInt(row*8)+7) ? true : false) : true}
       name = {parseInt(row*8)+6}
       value={options.find(option => option.value === getBrivoValue(parseInt(row*8)+6))}
     onChange={handleChangeBrivoRelay(parseInt(row*8)+6)}
     variant="filled"
     
     options={options}
     getOptionLabel={(option) => option.label}
     PaperComponent={({ children }) => (
       <Paper style={{ backgroundColor: "#1E1E1E" ,color:"#AFAFAF", "&:hover":{backgroundColor: "#fff"}}}>{children}</Paper>
     )}
     renderInput={(params) => <TextField {...params} 
      variant="outlined" size="small" style={{ backgroundColor: "#121212",borderRadius: '5px' ,color:"#fff",
   
       
   }} />}
   renderOption={(props, option) => (
     <MenuItem
       {...props}
       key={option.id}
       sx={{
         color: "#fff",
         backgroundColor: "#2F323A",
         borderBottom: '.5px solid grey',
         fontFamily: "Lato",
         fontSize: '12px',
       }}
       disabled={option.disabled}
     >
       <Stack direction="row" justifyContent="left" alignItems="center">
         {option.label === "None" && (
           <ListItemIcon sx={{ color: "red" }}>
             <DoNotDisturbIcon fontSize="small" />
           </ListItemIcon>
         )}
         {option.label}
       </Stack>
     </MenuItem>
   )}
     ></Autocomplete>
              {/* <Select
            sx={{ color: 'rgba(135, 135, 135, 0.5)',
            height:'33px',
            marginTop:'4px',
            marginBottom:'3px',
            borderRadius: '5px',
            backgroundColor: '#121212',
            boxShadow: 'inset 0px 4px 4px rgba(0, 0, 0, 0.25)',
            padding: '5px 0 5px 0',
            "& .MuiSvgIcon-root": {
              color: "rgba(135, 135, 135, 0.5)",
            },
            
            '&.Mui-disabled': {
             

              background: 'rgba(135, 135, 135, 0.5)',
            },
            "& input.Mui-disabled": {
              color: "white"
            }
            }}
            name = {(row*8)+6}
            value={getBrivoValue((row*8)+6)}
          onChange={handleChangeBrivoRelay}
          variant="filled"
          disabled={getDisableValue("184") == false ? (dataOfLocation.brivo_access.brivofloors < (parseInt(row*8)+7) ? true : false) : true}
          hiddenLabel
          MenuProps={MenuProps}>
            {brivoDoorArray?.map(menuItem => {
                  
                  return (
                    <MenuItem  sx={
                      {
                      
                      color:"#fff",
                      backgroundColor: "#2F323A",
                      borderBottom: '.5px solid grey',
                      fontFamily: "Lato",fontSize:'12px',
                  }}
                  disabled={menuItem.disabled}
                   value={menuItem.name}>
                      <Stack direction="row" justifyContent="left"
  alignItems="center">
                  { menuItem.name == "None" && <ListItemIcon sx={{color: "red"}}>
            <DoNotDisturbIcon fontSize="small" />
          </ListItemIcon>} 
            {menuItem.name}</Stack>
                    </MenuItem>
                  );
                })}
          </Select>  */}
              </TableCell>
      <TableCell sx={mainThemeStyle.cellRow}>
              {(row*8)+8}
              </TableCell>
              <TableCell align="right"> 
              <Autocomplete
       style={{marginTop: 0,minWidth: 150}}
        sx={{  "& .MuiAutocomplete-inputRoot": {
         color: "rgba(170, 170, 170,1)" },"& .MuiSvgIcon-root": {
           color: "rgba(170, 170, 170, 1)",
         },
         "& .Mui-disabled": {
          color: 'rgba(135, 135, 135, 0.5) ',
          "& .MuiSvgIcon-root": {
           color: "rgba(135, 135, 135, 0.5)"
          }
        },
        "& .MuiInputBase-root.Mui-disabled": {
          backgroundColor: 'rgba(135, 135, 135, 0.5)',
          color: 'white',
        },
        
        }}
       disablePortal
       disabled = {getDisableValue("184") == false ? (dataOfLocation.brivo_access.brivofloors < (parseInt(row*8)+8) ? true : false) : true}
       name = {parseInt(row*8)+7}
       value={options.find(option => option.value === getBrivoValue(parseInt(row*8)+7))}
     onChange={handleChangeBrivoRelay(parseInt(row*8)+7)}
     variant="filled"
     
     options={options}
     getOptionLabel={(option) => option.label}
     PaperComponent={({ children }) => (
       <Paper style={{ backgroundColor: "#1E1E1E" ,color:"#AFAFAF", "&:hover":{backgroundColor: "#fff"}}}>{children}</Paper>
     )}
     renderInput={(params) => <TextField {...params} 
      variant="outlined" size="small" style={{ backgroundColor: "#121212",borderRadius: '5px' ,color:"#fff",
   
       
   }} />}
   renderOption={(props, option) => (
     <MenuItem
       {...props}
       key={option.id}
       sx={{
         color: "#fff",
         backgroundColor: "#2F323A",
         borderBottom: '.5px solid grey',
         fontFamily: "Lato",
         fontSize: '12px',
       }}
       disabled={option.disabled}
     >
       <Stack direction="row" justifyContent="left" alignItems="center">
         {option.label === "None" && (
           <ListItemIcon sx={{ color: "red" }}>
             <DoNotDisturbIcon fontSize="small" />
           </ListItemIcon>
         )}
         {option.label}
       </Stack>
     </MenuItem>
   )}
     ></Autocomplete>
              {/* <Select
            sx={{ color: 'rgba(135, 135, 135, 0.5)',
            height:'33px',
            marginTop:'4px',
            marginBottom:'3px',
            borderRadius: '5px',
            backgroundColor: '#121212',
            boxShadow: 'inset 0px 4px 4px rgba(0, 0, 0, 0.25)',
            padding: '5px 0 5px 0',
            "& .MuiSvgIcon-root": {
              color: "rgba(135, 135, 135, 0.5)",
            },
            
            '&.Mui-disabled': {
             

              background: 'rgba(135, 135, 135, 0.5)',
            },
            "& input.Mui-disabled": {
              color: "white"
            }
            }}
            name = {(row*8)+7}
            value={getBrivoValue((row*8)+7)}
          onChange={handleChangeBrivoRelay}
          variant="filled"
          disabled={getDisableValue("184") == false ? (dataOfLocation.brivo_access.brivofloors < (parseInt(row*8)+8) ? true : false) : true}
          hiddenLabel
          MenuProps={MenuProps}>
            {brivoDoorArray?.map(menuItem => {
                  
                  return (
                    <MenuItem  sx={
                      {
                      
                      color:"#fff",
                      backgroundColor: "#2F323A",
                      borderBottom: '.5px solid grey',
                      fontFamily: "Lato",fontSize:'12px',
                  }}
                  disabled={menuItem.disabled}
                   value={menuItem.name}>
                      <Stack direction="row" justifyContent="left"
  alignItems="center">
                  { menuItem.name == "None" && <ListItemIcon sx={{color: "red"}}>
            <DoNotDisturbIcon fontSize="small" />
          </ListItemIcon>} 
            {menuItem.name}</Stack>
                    </MenuItem>
                  );
                })}
          </Select> */} </TableCell>
            </TableRow>
          ))}
        </TableBody>
      </Table>
    </TableContainer>
    </Box>
   
    
</>)}

{(currentTab == 7 && dataOfLocation.cam_brand == "ResidentLink" && <>
<div  sx={{
       right: '54px' ,left: '54px',borderRadius:'0px'}}>
               <div style={{ display: 'flex' }}>
                   <Typography variant="h6" component="div" style={{  paddingLeft: '16px',paddingTop: '16px',flexGrow: 1,color:'#fff' }}>
                   Panel Device Information
                   </Typography>
                   
               </div>
              
           </div>
           <Divider sx={{backgroundColor:'white'}} /><Box sx={{display: { xs: "none", md: "block" }}}>
           <Grid container spacing={2} padding= '32px'> 
      <Grid item xs={2.5}>
        <Stack direction="column"  spacing={2}>
        {dataOfLocation.cam_brand == "ResidentLink" && 
     <Typography  sx={mainThemeStyle.popUpLable} height={40}>MDM Device Id </Typography>}
       {/*  <Typography sx={mainThemeStyle.popUpLable} height={40}> Serial Number </Typography> */}
       <Typography  sx={mainThemeStyle.popUpLable} height={40}> Device Serial Number </Typography>
       {idOflocation.mdm_type == "0" &&  <Typography  sx={mainThemeStyle.popUpLable} height={40}>Display Status </Typography> }
      {idOflocation.mdm_type == "0" && <Typography  sx={mainThemeStyle.popUpLable} height={40}>Kiosk Enabled</Typography>}
      {idOflocation.mdm_type == "1" && <Typography  sx={mainThemeStyle.popUpLable} height={40}>Device Status</Typography>}
     
      {/* <Typography  sx={mainThemeStyle.popUpLable} height={40}>Restart Device </Typography> */}
      </Stack>
      </Grid>
      
      <Grid item xs={2.5}>
      <Stack direction="column"  spacing={2}>
      {/* {dataOfLocation.cam_brand == "ResidentLink" && <TextField
        id="hexnode-id"
        type="text"
        name="device_id"
        value={dataOfLocation.device_id}
        onChange={handleInputChange}
        height={40}
        sx={mainThemeStyle.InputMenuItem}
        InputLabelProps={{
          shrink: true,
        }}
        variant="filled"
        InputProps={{
                           
          sx: {
              
              color: 'rgba(135, 135, 135, 0.5)',
              height:'33px',
              borderRadius: '5px',
              backgroundColor: '#121212',
              boxShadow: 'inset 0px 4px 4px rgba(0, 0, 0, 0.25)',
              padding: '0 0 15px 0',
              textIndent: '15px !important',
          
             
              
          },
      }}
      />} */}
      <Typography  sx={mainThemeStyle.popUpLableAnswer} height={40}>{  dataOfLocation.device_id != ''  ?  dataOfLocation.device_id :'N/A'} </Typography>
      {idOflocation.mdm_type == "0" &&<Typography  sx={mainThemeStyle.popUpLableAnswer} height={40}>{idOflocation.hexnode.device != null ? (idOflocation.hexnode.device.serial_number != null ? idOflocation.hexnode.device.serial_number : 'N/A'):'N/A'} </Typography>}
   
      {/* <Typography  sx={mainThemeStyle.popUpLableAnswer} height={40}>{  dataOfLocation.tablet_name} </Typography> */}
      {idOflocation.mdm_type == "0" &&<Typography  sx={mainThemeStyle.popUpLableAnswer} height={40}>{ idOflocation.hexnode.agent_active != null ? ( idOflocation.hexnode.agent_active ? 'True' : 'False'):'N/A'} </Typography>}
      {idOflocation.mdm_type == "0" &&<Typography  sx={mainThemeStyle.popUpLableAnswer} height={40}>{idOflocation.hexnode.device != null ? (idOflocation.hexnode.device.is_kiosk? 'True' : 'False'):'N/A'} </Typography>}
      {idOflocation.mdm_type == "1" && <Typography  sx={mainThemeStyle.popUpLableAnswer} height={40}>{idOflocation.esper != null ? (idOflocation.esper.hardwareInfo != null ? (idOflocation.esper.hardwareInfo.serialNumber != null ? idOflocation.esper.hardwareInfo.serialNumber : 'N/A'):'N/A'):'N/A'} </Typography>}
    
     {/*  {idOflocation.mdm_type == "1" &&<Typography  sx={mainThemeStyle.popUpLableAnswer} height={40}>{ idOflocation.esper != null ? ( idOflocation.esper.status  > 0? 'On' : 'Off'):'N/A'} </Typography>}
     */}  
     {idOflocation.mdm_type == "1" &&<Typography  sx={mainThemeStyle.popUpLableAnswer} height={40}>{idOflocation.esper != null ? (idOflocation.esper.status == '1'  ? 'Online' : 'Offline'):'N/A'} </Typography>}
      
    
      {idOflocation.mdm_type == "0" && <LoadingButton sx={mainThemeStyle.normalButton}  disabled = {getDisableValue("240")}  loading={loading} onClick={handleRestartDevice}>Click To Restart Panel</LoadingButton>}
      {idOflocation.mdm_type == "1" && <LoadingButton sx={mainThemeStyle.normalButton}   disabled = {getDisableValue("240")} loading={loading} onClick={handlePingDevice}>Click To Ping Panel</LoadingButton>}
     
      {idOflocation.mdm_type == "1" && <LoadingButton sx={mainThemeStyle.normalButton}  disabled = {getDisableValue("240")}  loading={loading} onClick={handleRebootDevice}>Click To Reboot Panel</LoadingButton>}
      
      </Stack>
      </Grid>
      <Grid item xs={1}>
    
      </Grid>
      <Grid item xs={2.5}>
        <Stack direction="column"  spacing={2}>
       
       {/*  <Typography  sx={mainThemeStyle.popUpLable} height={40}>Total Application </Typography>
      <Typography  sx={mainThemeStyle.popUpLable} height={40}>Total Policies </Typography> */}
      {idOflocation.mdm_type == "0" &&<Typography  sx={mainThemeStyle.popUpLable} height={40}>Last Active </Typography>}
      {idOflocation.mdm_type == "0" && <Typography  sx={mainThemeStyle.popUpLable} height={40}>Last Scanned </Typography>}
      {/* {idOflocation.mdm_type == "1" &&<Typography  sx={mainThemeStyle.popUpLable} height={40}>Last Seen </Typography>}
      */} {idOflocation.mdm_type == "1" &&<Typography  sx={mainThemeStyle.popUpLable} height={40}>Last Rebooted </Typography>}
      {idOflocation.mdm_type == "1" &&<Typography  sx={mainThemeStyle.popUpLable} height={40}>Device Timezone </Typography>}
  
      </Stack>
      </Grid>
      <Grid item xs={2.5}>
        <Stack direction="column"  spacing={2}>
          {/*   <Typography  sx={mainThemeStyle.popUpLableAnswer} height={40}>{idOflocation.hexnode.applications != null ? (idOflocation.hexnode.applications ? (idOflocation.hexnode.applications).length : 'N/A'):'N/A'} </Typography>
      <Typography  sx={mainThemeStyle.popUpLableAnswer} height={40}>{idOflocation.hexnode.policies != null ? (idOflocation.hexnode.policies ? (idOflocation.hexnode.policies).length : 'N/A'):'N/A'} </Typography>
      */} 
       {idOflocation.mdm_type == "0" &&<Typography  sx={mainThemeStyle.popUpLableAnswer} height={40}>{idOflocation.hexnode.lastreported != null ? (idOflocation.hexnode.lastreported? moment(idOflocation.hexnode.lastreported).format('YYYY-MM-DD HH:MM') : 'N/A'):'N/A'} </Typography>}
        {idOflocation.mdm_type == "0" &&<Typography  sx={mainThemeStyle.popUpLableAnswer} height={40}>{idOflocation.hexnode.lastscanned!= null ? (moment(idOflocation.hexnode.lastscanned).format('YYYY-MM-DD HH:MM')):'N/A'}</Typography>}
       {/*  {idOflocation.mdm_type == "1" &&<Typography  sx={mainThemeStyle.popUpLableAnswer} height={40}>{idOflocation.esper != null ? (idOflocation.esper.updated_on != null ? moment(idOflocation.esper.updated_on).format('YYYY-MM-DD HH:MM') : 'N/A'):'N/A'} </Typography>}
       */} {idOflocation.mdm_type == "1" &&<Typography  sx={mainThemeStyle.popUpLableAnswer} height={40}>{idOflocation.esper != null ? (idOflocation.esper.status == '1' ? (idOflocation.esper.boot_time!= null ? moment(idOflocation.esper.boot_time).format('YYYY-MM-DD HH:mm:ss') : 'N/A'):'N/A'):'N/A'} </Typography>}
       {idOflocation.mdm_type == "1" &&<Typography  sx={mainThemeStyle.popUpLableAnswer} height={40}>{idOflocation.esper!= null ? (idOflocation.esper.timezone_string != null ? idOflocation.esper.timezone_string : 'N/A'):'N/A'}</Typography>}
     
      </Stack>
      </Grid>
     
      
      </Grid></Box>
      <Box sx={{display: { xs: "block", md: "none" }}}>
           <Grid container spacing={2} padding= '10px' paddingBottom='32px' paddingTop='32px'> 
      <Grid item xs={7}>
        <Stack direction="column"  spacing={2}>
        {dataOfLocation.cam_brand == "ResidentLink" && 
     <Typography  sx={mainThemeStyle.popUpLable} height={40}>MDM Device Id </Typography>}
       
       <Typography  sx={mainThemeStyle.popUpLable} height={40}> Device Serial Number </Typography>
      {idOflocation.mdm_type == "0"  && <Typography  sx={mainThemeStyle.popUpLable} height={40}>Display Status </Typography>}
      {idOflocation.mdm_type == "0" && <Typography  sx={mainThemeStyle.popUpLable} height={40}>Kiosk Enabled</Typography>}
      {idOflocation.mdm_type == "1" && <Typography  sx={mainThemeStyle.popUpLable} height={40}>Device Status</Typography>}
      {idOflocation.mdm_type == "0" &&<Typography  sx={mainThemeStyle.popUpLable} height={40}>Last Active </Typography>}
      {idOflocation.mdm_type == "0" && <Typography  sx={mainThemeStyle.popUpLable} height={40}>Last Scanned </Typography>}
     {/*  {idOflocation.mdm_type == "1" &&<Typography  sx={mainThemeStyle.popUpLable} height={40}>Last Seen </Typography>}
       */}{idOflocation.mdm_type == "1" &&<Typography  sx={mainThemeStyle.popUpLable} height={40}>Last Rebooted </Typography>}
      {idOflocation.mdm_type == "1" &&<Typography  sx={mainThemeStyle.popUpLable} height={40}>Device Timezone </Typography>}
  
      {idOflocation.mdm_type == "0" && <LoadingButton sx={{
        width: 'auto',
        height: '2.06rem',
        marginTop:'0rem',
        button: {
            textTransform: "none"
          },
        fontWeight: '700',
        fontSize: '0.83rem',
        
        lineHeight:'0.93rem',
        alignItems: 'center',
        color: '#FFFFFF',
        fontFamily: "Lato",
        borderRadius:'0.31rem',
       '&:hover': {
        backgroundColor: 'rgba(109, 195, 252, 1)',
       }
       }} loading={loading} onClick={handleRestartDevice} disabled = {getDisableValue("240")} >Click To Restart Panel</LoadingButton>}
       


       {idOflocation.mdm_type == "1" && <LoadingButton sx={{
        width: 'auto',
        height: '2.06rem',
        marginTop:'0rem',
        button: {
            textTransform: "none"
          },
        fontWeight: '700',
        fontSize: '0.83rem',
        
        lineHeight:'0.93rem',
        alignItems: 'center',
        color: '#FFFFFF',
        fontFamily: "Lato",
        borderRadius:'0.31rem',
       '&:hover': {
        backgroundColor: 'rgba(109, 195, 252, 1)',
       }
       }} loading={loading} onClick={handlePingDevice}  disabled = {getDisableValue("240")} >Click To Ping Panel</LoadingButton>}

      {idOflocation.mdm_type == "1" && <LoadingButton sx={{
        width: 'auto',
        height: '2.06rem',
        marginTop:'0rem',
        button: {
            textTransform: "none"
          },
        fontWeight: '700',
        fontSize: '0.83rem',
        
        lineHeight:'0.93rem',
        alignItems: 'center',
        color: '#FFFFFF',
        fontFamily: "Lato",
        borderRadius:'0.31rem',
       '&:hover': {
        backgroundColor: 'rgba(109, 195, 252, 1)',
       }
       }} loading={loading} onClick={handleRebootDevice}  disabled = {getDisableValue("240")} >Click To Reboot Panel</LoadingButton>}
     
     
      </Stack>
      </Grid>
      
      <Grid item xs={5}>
      <Stack direction="column"  spacing={2}>
     
      <Typography  sx={mainThemeStyle.popUpLableAnswer} height={40}>{  dataOfLocation.device_id != ''  ?  dataOfLocation.device_id :'N/A'} </Typography>
      {idOflocation.mdm_type == "0" && <Typography  sx={mainThemeStyle.popUpLableAnswer} height={40}>{idOflocation.hexnode.device != null ? (idOflocation.hexnode.device.serial_number != null ? idOflocation.hexnode.device.serial_number : 'N/A'):'N/A'} </Typography>}
   
      {/* <Typography  sx={mainThemeStyle.popUpLableAnswer} height={40}>{  dataOfLocation.tablet_name} </Typography> */}
      {idOflocation.mdm_type == "0" && <Typography  sx={mainThemeStyle.popUpLableAnswer} height={40}>{ idOflocation.hexnode.agent_active != null ? ( idOflocation.hexnode.agent_active ? 'True' : 'False'):'N/A'} </Typography>}
      {idOflocation.mdm_type == "0" && <Typography  sx={mainThemeStyle.popUpLableAnswer} height={40}>{idOflocation.hexnode.device != null ? (idOflocation.hexnode.device.is_kiosk? 'True' : 'False'):'N/A'} </Typography>}
      {dataOfLocation.mdm_type == "1" && <Typography  sx={mainThemeStyle.popUpLableAnswer} height={40}>{idOflocation.esper != null ? (idOflocation.esper.hardwareInfo != null ? (idOflocation.esper.hardwareInfo.serialNumber != null ? idOflocation.esper.hardwareInfo.serialNumber : 'N/A'):'N/A'):'N/A'} </Typography>}
    
    
       {idOflocation.mdm_type == "1" &&<Typography  sx={mainThemeStyle.popUpLableAnswer} height={40}>{idOflocation.esper != null ? (idOflocation.esper.status == '1' ? 'Online' : 'Offline'):'N/A'} </Typography>}
        {idOflocation.mdm_type == "0" &&<Typography  sx={mainThemeStyle.popUpLableAnswer} height={40}>{idOflocation.hexnode.lastreported != null ? (idOflocation.hexnode.lastreported? moment(idOflocation.hexnode.lastreported).format('YYYY-MM-DD HH:MM') : 'N/A'):'N/A'} </Typography>}
        {idOflocation.mdm_type == "0" &&<Typography  sx={mainThemeStyle.popUpLableAnswer} height={40}>{idOflocation.hexnode.lastscanned!= null ? (moment(idOflocation.hexnode.lastscanned).format('YYYY-MM-DD HH:MM')):'N/A'}</Typography>}
        {/* {idOflocation.mdm_type == "1" &&<Typography  sx={mainThemeStyle.popUpLableAnswer} height={40}>{idOflocation.esper != null ? (idOflocation.esper.updated_on != null ? moment(idOflocation.esper.updated_on).format('YYYY-MM-DD HH:MM') : 'N/A'):'N/A'} </Typography>} */}
        {idOflocation.mdm_type == "1" &&<Typography  sx={mainThemeStyle.popUpLableAnswer} height={40}>{idOflocation.esper != null ? (idOflocation.esper.status == '1' ? (idOflocation.esper.boot_time!= null ? moment(idOflocation.esper.boot_time).format('YYYY-MM-DD HH:mm:ss') : 'N/A'):'N/A'):'N/A'} </Typography>}
      {idOflocation.mdm_type == "1" &&<Typography  sx={mainThemeStyle.popUpLableAnswer} height={40}>{idOflocation.esper!= null ? (idOflocation.esper.timezone_string != null ? idOflocation.esper.timezone_string : 'N/A'):'N/A'}</Typography>}
     
       
     
      </Stack>
      
      </Grid>
     
      
      
      </Grid></Box>
      </>
     
)}
{/* {showUpdateButton &&  <Box sx= {mainThemeStyle.boxDown}><LoadingButton
         
         sx={mainThemeStyle.normalButtonRelative}
         loading={loading}
         onClick={handleUpdateLocation}
         variant="outlined"
         startIcon={<SaveIcon />}
         loadingPosition="start"
       >
         Save Location Details
       </LoadingButton>
    <Typography></Typography>
      </Box>} */}
</Box>
<Snackbar open={openSnack} autoHideDuration={6000} onClose={handleCloseSnack}>
        <Alert onClose={handleCloseSnack} severity={severityData} sx={{ width: '100%' }}>
          {openSnackData}
        </Alert>
      </Snackbar>
 </> 
          
          
          
          
          ;
};

