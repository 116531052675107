import * as React from "react";
import { Box } from "@mui/system";
import { Button,Checkbox, Switch,Grid, InputLabel, List,Divider, FormControlLabel, Typography,ButtonGroup,Snackbar ,Alert ,IconButton, Icon,Dialog,DialogTitle,DialogContent,DialogActions,DialogContentText, cardMediaClasses } from "@mui/material";
import {mainThemeStyle} from './components/MainTheme';
import { NestedButtonDropdown } from "./components/header/NestedButtonDropdown";
import TextField from '@mui/material/TextField';
import Stack from '@mui/material/Stack';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Paper from '@mui/material/Paper';
import { faker } from '@faker-js/faker';
import { format } from 'date-fns';
import { useState,useRef,useEffect } from "react";
import useTable from "./components/useTable";
import SvgIcon from "@mui/material/SvgIcon";
import NoImage from './asset/noimage.png';
import Popup from "./components/Popup";
import _ from "lodash";
import moment from 'moment';
import DeleteIcon from '@mui/icons-material/Delete';
import EditIcon from '@mui/icons-material/Edit';
import RestartAltIcon from '@mui/icons-material/RestartAlt';
import { styled } from '@mui/material/styles';
import useToken from "./useToken";
import axios from "./axios";
import LoadingButton from '@mui/lab/LoadingButton';
import GlobalAlert from "./GlobalAlert";
import GlobalRoleAccess from "./GlobalRoleAccess";
import GlobalRoleAccessInner from "./GlobalRoleAccessInner";
import PuffLoader from "react-spinners/PuffLoader"; 
import TimezoneSelect from 'react-timezone-select'
const IOSSwitch = styled((props) => (
  <Switch focusVisibleClassName=".Mui-focusVisible" disableRipple {...props} />
))(({ theme }) => ({
  width: 42,
  height: 26,
  padding: 0,
  '& .MuiSwitch-switchBase': {
    padding: 0,
    margin: 2,
    transitionDuration: '300ms',
    '&.Mui-checked': {
      transform: 'translateX(16px)',
      color: '#fff',
      '& + .MuiSwitch-track': {
        backgroundColor: theme.palette.mode === 'dark' ? '#2ECA45' : '#65C466',
        opacity: 1,
        border: 0,
      },
      '&.Mui-disabled + .MuiSwitch-track': {
        opacity: 0.5,
      },
    },
    '&.Mui-focusVisible .MuiSwitch-thumb': {
      color: '#33cf4d',
      border: '6px solid #fff',
    },
    '&.Mui-disabled .MuiSwitch-thumb': {
      color:
        theme.palette.mode === 'light'
          ? theme.palette.grey[100]
          : theme.palette.grey[600],
    },
    '&.Mui-disabled + .MuiSwitch-track': {
      opacity: theme.palette.mode === 'light' ? 0.7 : 0.3,
    },
  },
  '& .MuiSwitch-thumb': {
    boxSizing: 'border-box',
    width: 22,
    height: 22,
  },
  '& .MuiSwitch-track': {
    borderRadius: 26 / 2,
    backgroundColor: theme.palette.mode === 'light' ? '#E9E9EA' : '#39393D',
    opacity: 1,
    transition: theme.transitions.create(['background-color'], {
      duration: 500,
    }),
  },
}));


function Profile ( props){
  const handleCloseSnack= (event, reason) => {
    if (reason === 'clickaway') {
      return;
    }
    setOpenSnack(false);
  };
  const {token} = useToken();
  const {RefreshToken} = props;
  const { getAlertTitle} = GlobalAlert();
  const [imageUrl, setImageUrl] = useState();
  const [openSnack, setOpenSnack] = React.useState(false);
  const [openSnackData, setOpenSnackData] = React.useState("This is Success Data");
  const [severityData, setSeverityData] = React.useState("success");
  const [EnLog,setEnLog] = useState([]);
  const [showUpdateButton, setshowUpdateButton] = React.useState(false);
  const [apiValue, setAPIValue] = useState({id:"",name:"",email:"",address:"",city:"",state:"",zip_code:"",copmpany:"",office:"",website_url:"",total_unit:"",image_upload_data:"",issub:token.issub,subId:token.subid});
  const [dealerDetails, setDealerDetails] = useState('');
  
  const {getRoleAccessVal} = GlobalRoleAccess();
  const {getRoleAccessInnerVal} = GlobalRoleAccessInner();
  let [loading, setLoading] = useState(true);


  const getDisableValueEdit = () => {
    if(token.role == 'superadmin' || token.name === 'SUPER ADMIN'){
      if(token.sub_type == "1"){
        let valOfDisable = getRoleAccessVal("62").administrator_control;
          if(valOfDisable == "1"){
            return false;
          }
          else{
            return true;
          }
      }
      else{
        let valOfDisable = getRoleAccessVal("62").administrator_sub_control;
        if(valOfDisable == "1"){
          return false;
        }
        else{
          return true;
        }
      }
    }
    else if(token.role == 'distributor' || token.name === 'SUPER DISTRIBUTOR'){
      if(token.sub_type == "1"){
        let valOfDisable = getRoleAccessVal("62").distributor_control;
          if(valOfDisable == "1"){
            return false;
          }
          else{
            return true;
          }
      }
      else{
        let valOfDisable = getRoleAccessVal("62").distributor_sub_control;
        if(valOfDisable == "1"){
          return false;
        }
        else{
          return true;
        }
      }
    }
    else if(token.role == 'dealer' || token.name === 'SUPER DEALER'){
      if(token.sub_type == "1"){
        let valOfDisable = getRoleAccessVal("62").dealer_control;
          if(valOfDisable == "1"){
            return false;
          }
          else{
            return true;
          }
      }
      else{
        let valOfDisable = getRoleAccessVal("62").dealer_sub_control;
        if(valOfDisable == "1"){
          return false;
        }
        else{
          return true;
        }
      }
    }
    else if(token.role == 'admin'){
      if(token.sub_type == "1"){
        let valOfDisable = getRoleAccessVal("62").admin_control;
          if(valOfDisable == "1"){
            return false;
          }
          else{
            return true;
          }
      }
      else{
        let valOfDisable = getRoleAccessVal("62").admin_sub_control;
        if(valOfDisable == "1"){
          return false;
        }
        else{
          return true;
        }
      }
    }
    else{
      return true;
    }
    
    
  }
  const changeTimeZone  =  (date, timeZone)=> {
    const laDate = moment.utc(date).format();
    //if (typeof date === 'string') {
      const ldated =  new Date(
        new Date(laDate).toLocaleString('en-US', {
          timeZone,
        }),
      );
      const mystr = moment(ldated).format('MM/DD/YYYY');
      return mystr;
   /* }
  
    return new Date(
      date.toLocaleString('en-US', {
        timeZone,
      }),
    );*/
  }
  const getDisableValue = (val) => {
    if(getDisableValueEdit() == false){
    if(token.role == 'superadmin' || token.name === 'SUPER ADMIN'){
      if(token.sub_type == "1"){
        let idData ;
        let valOfDisableData = getRoleAccessInnerVal("62");
       // console.log("value is "+ JSON.stringify(valOfDisableData));
        for(let i = 0; i < valOfDisableData.length ; i ++){
          if(valOfDisableData[i].id == val ){
            idData = i;
            break;
          }
        }
        //console.log("value is "+ JSON.stringify(valOfDisableData[idData]));
        let valOfDisable = valOfDisableData[idData].administrator_control;
        if(valOfDisable == "1"){
          return false;
        }
        else{
          return true;
        }
      }
      else{
        let idData ;
        let valOfDisableData = getRoleAccessInnerVal("62");
        for(let i = 0; i < valOfDisableData.length ; i ++){
          if(valOfDisableData[i].id == val ){
            idData = i;
            break;
          }
        }
        let valOfDisable = valOfDisableData[idData].administrator_sub_control;
        if(valOfDisable == "1"){
          return false;
        }
        else{
          return true;
        }
      }
    }
    else if(token.role == 'distributor' || token.name === 'SUPER DISTRIBUTOR'){
      if(token.sub_type == "1"){
        let idData ;
        let valOfDisableData = getRoleAccessInnerVal("62");
       // console.log("value is "+ JSON.stringify(valOfDisableData));
        for(let i = 0; i < valOfDisableData.length ; i ++){
          if(valOfDisableData[i].id == val ){
            idData = i;
            break;
          }
        }
       // console.log("value is "+ JSON.stringify(valOfDisableData[idData]));
        let valOfDisable = valOfDisableData[idData].distributor_control;
        if(valOfDisable == "1"){
          return false;
        }
        else{
          return true;
        }
      }
      else{
        let idData ;
        let valOfDisableData = getRoleAccessInnerVal("62");
        for(let i = 0; i < valOfDisableData.length ; i ++){
          if(valOfDisableData[i].id == val ){
            idData = i;
            break;
          }
        }
        let valOfDisable = valOfDisableData[idData].distributor_sub_control;
        if(valOfDisable == "1"){
          return false;
        }
        else{
          return true;
        }
      }
    }
    else if(token.role == 'dealer' || token.name === 'SUPER DEALER'){
      if(token.sub_type == "1"){
        let idData ;
        let valOfDisableData = getRoleAccessInnerVal("62");
       // console.log("value is "+ JSON.stringify(valOfDisableData));
        for(let i = 0; i < valOfDisableData.length ; i ++){
          if(valOfDisableData[i].id == val ){
            idData = i;
            break;
          }
        }
       // console.log("value is "+ JSON.stringify(valOfDisableData[idData]));
        let valOfDisable = valOfDisableData[idData].dealer_control;
        if(valOfDisable == "1"){
          return false;
        }
        else{
          return true;
        }
      }
      else{
        let idData ;
        let valOfDisableData = getRoleAccessInnerVal("62");
        for(let i = 0; i < valOfDisableData.length ; i ++){
          if(valOfDisableData[i].id == val ){
            idData = i;
            break;
          }
        }
        let valOfDisable = valOfDisableData[idData].dealer_sub_control;
        if(valOfDisable == "1"){
          return false;
        }
        else{
          return true;
        }
      }
    }
    else if(token.role == 'admin'){
      if(token.sub_type == "1"){
        let idData ;
        let valOfDisableData = getRoleAccessInnerVal("62");
       // console.log("value is "+ JSON.stringify(valOfDisableData));
        for(let i = 0; i < valOfDisableData.length ; i ++){
          if(valOfDisableData[i].id == val ){
            idData = i;
            break;
          }
        }
       // console.log("value is "+ JSON.stringify(valOfDisableData[idData]));
        let valOfDisable = valOfDisableData[idData].admin_control;
        if(valOfDisable == "1"){
          return false;
        }
        else{
          return true;
        }
      }
      else{
        let idData ;
        let valOfDisableData = getRoleAccessInnerVal("62");
        for(let i = 0; i < valOfDisableData.length ; i ++){
          if(valOfDisableData[i].id == val ){
            idData = i;
            break;
          }
        }
        let valOfDisable = valOfDisableData[idData].admin_sub_control;
        if(valOfDisable == "1"){
          return false;
        }
        else{
          return true;
        }
      }
    }
    else{
      return true;
    }
  }else{
    return true;
  }
    
  }
  const getMyPostData = async () => {
    setLoading(true);
    try {
      const res = await axios.get("/admin/getprofile/"+token.id,{
        headers: { 'Content-Type':  'application/json' ,
        'token' : token.token,
        'tokenid' : token.email},
      });
      console.log("data format"+res.data);
      setEnLog(res.data[0]);
      let ad  = apiValue;
      ad.id = res.data[0].id;
      ad.name = res.data[0].name;
      ad.email = res.data[0].email;
      ad.address = res.data[0].address;
      ad.address_two = res.data[0].address_two;
      ad.city = res.data[0].city;
      ad.state = res.data[0].state;
      ad.zip_code =res.data[0].zip_code;
      ad.copmpany =res.data[0].copmpany;
      ad.office =res.data[0].office;
      ad.website_url =res.data[0].website_url;
      ad.total_unit =res.data[0].total_unit;
      setDealerDetails(res.data[0].dealer);
      console.log(res.data[0].dealer);
      console.log(ad);
      setAPIValue(ad);
      setImageUrl( res.data[0].logo_url != null ? "https://mdvaccess.com/"+res.data[0].logo_url : NoImage)
      setLoading(false);
    } catch (error) {
      console.log(error.message);
      setLoading(false);
      if(error.response.data.message == 'UnAuthorized'){
        RefreshToken();
			}
    }
  };
  useEffect(() => {
    getMyPostData();
  }, []);
  const [selectedImage, setSelectedImage] = useState(null);
  useEffect(() => {
    console.log("readr image calling");
    if (selectedImage) {
      setImageUrl(URL.createObjectURL(selectedImage));
      console.log("sle"+selectedImage.name);
    }
  }, [selectedImage]);
  const updateMyPostData = async () => {
    setLoading(true);
    try {
    /*  const res = await axios.patch("/admin/getprofile/28",apiValue,{
        headers: { 'Content-Type':  'application/x-www-form-urlencoded; charset=UTF-8' },
      });*/
      const res = await axios.patch("/admin/getprofile/"+token.id,apiValue, {headers: { 'Content-Type':  'application/x-www-form-urlencoded; charset=UTF-8' ,
      'token' : token.token,
      'tokenid' : token.email}});
      console.log( res.data);
      console.log(apiValue);
      setOpenSnackData(getAlertTitle('PUS'));
      setSeverityData("success");
      setOpenSnack(true);
      setLoading(false);
      setshowUpdateButton(false);
    } catch (error) {
      console.log(error.response);
      console.log(apiValue);
      setLoading(false);
      setOpenSnackData(getAlertTitle('PUF'));
      setSeverityData("error");
      setOpenSnack(true);
      if(error.response.data.message == 'UnAuthorized'){
        RefreshToken();
			}
    }
  };
  const handleInputChange=(event) =>{
    console.log(apiValue);
    setshowUpdateButton(true);
    let ds = event.target.name;
    apiValue[ds] = event.target.value;
    setAPIValue({id:apiValue.id,name:apiValue.name,email:apiValue.email,address:apiValue.address,address_two:apiValue.address_two,city:apiValue.city,state:apiValue.state,zip_code:apiValue.zip_code,copmpany:apiValue.copmpany,office:apiValue.office,website_url:apiValue.website_url,total_unit:apiValue.total_unit,image_upload_data:apiValue.image_upload_data,issub:token.issub,subId:token.subid});
  }
  
  const handleSaveCardFormat = () => {
    /*if(token.issub == true){
      if(token.name != 'SUPER ADMIN'){
      setOpenSnackData("You don't have access to perform any action on this page");
      setSeverityData("error");
      setOpenSnack(true);
      return;
      }
    }*/
    if(apiValue.name == ""){
      setOpenSnackData("Name Can't Be Null, Please Enter Name");
      setSeverityData("error");
      setOpenSnack(true);
    }else{
    updateMyPostData();
    }
  }
  return <> 
  <Box sx={mainThemeStyle.boxTopHeader}>
  
</Box>
<Box sx={mainThemeStyle.boxHeaderBelow}>
<div  sx={{
       right: '54px' ,left: '54px',borderRadius:'0px'}}>
               <div style={{ display: 'flex' }}>
              
                   <Typography variant="h6" component="div" style={{  paddingLeft: '16px',paddingTop: '16px',flexGrow: 1,color:'#fff' }}>
                       Your Dealer Details
                   </Typography>
                   
               </div>
              
           </div>
           <Divider sx={{backgroundColor:'white'}} />

           <Box sx={{display: { xs: "none", md: "flex" }}}>
           <Grid container spacing={2} padding= '32px'>

           <Grid item xs={2.5}>
        <Stack direction="column"  spacing={2}>
      <Typography sx={mainThemeStyle.popUpLable} height={40}> Name</Typography>
      <Typography sx={mainThemeStyle.popUpLable} height={40}> Company</Typography>
      <Typography sx={mainThemeStyle.popUpLable} height={40}> Address </Typography>
      <Typography sx={mainThemeStyle.popUpLable} height={40}> City</Typography>
      </Stack>
      </Grid>

      <Grid item xs={2.5}>
        <Stack direction="column"  spacing={2}>
      <Typography sx={mainThemeStyle.popUpLableAnswer} height={40}> {dealerDetails.contact_name != null ? dealerDetails.contact_name : ''}</Typography>
      <Typography sx={mainThemeStyle.popUpLableAnswer} height={40}> {dealerDetails.company_name != null  ? dealerDetails.company_name : ''}</Typography>
      <Typography sx={mainThemeStyle.popUpLableAnswer} height={40}> {dealerDetails.street_add != null  ? dealerDetails.street_add : ''} </Typography>
      <Typography sx={mainThemeStyle.popUpLableAnswer} height={40}>  {dealerDetails.city != null  ? dealerDetails.city : ''}</Typography>
      </Stack>
      </Grid>

      <Grid item xs={2}>
     
     </Grid>

     <Grid item xs={2.5}>
        <Stack direction="column"  spacing={2}>
      <Typography sx={mainThemeStyle.popUpLable} height={40}> State </Typography>
      <Typography sx={mainThemeStyle.popUpLable} height={40}> Zip</Typography>
      <Typography sx={mainThemeStyle.popUpLable} height={40}> Telephone </Typography>
      <Typography sx={mainThemeStyle.popUpLable} height={40}> Email </Typography>
      </Stack>
      </Grid>

      <Grid item xs={2.5}>
        <Stack direction="column"  spacing={2}>
      <Typography sx={mainThemeStyle.popUpLableAnswer} height={40}>  {dealerDetails.state}</Typography>
      <Typography sx={mainThemeStyle.popUpLableAnswer} height={40}>  {dealerDetails.zip_code}</Typography>
      <Typography sx={mainThemeStyle.popUpLableAnswer} height={40}>  {dealerDetails.telephone} </Typography>
      <Typography sx={mainThemeStyle.popUpLableAnswer} height={40}>  {dealerDetails.email} </Typography>
      </Stack>
      </Grid>

      </Grid>
      </Box>
      <Box sx={{display: { xs: "flex", md: "none" }}}>
           <Grid container spacing={2} padding= '32px'>

           <Grid item xs={5}>
        <Stack direction="column"  spacing={2}>
      <Typography sx={mainThemeStyle.popUpLable} height={40}> Name</Typography>
      <Typography sx={mainThemeStyle.popUpLable} height={40}> Company</Typography>
      <Typography sx={mainThemeStyle.popUpLable} height={40}> Address </Typography>
      <Typography sx={mainThemeStyle.popUpLable} height={40}> City</Typography>
      <Typography sx={mainThemeStyle.popUpLable} height={40}> State </Typography>
      <Typography sx={mainThemeStyle.popUpLable} height={40}> Zip</Typography>
      <Typography sx={mainThemeStyle.popUpLable} height={40}> Telephone </Typography>
      <Typography sx={mainThemeStyle.popUpLable} height={40}> Email </Typography>
      </Stack>
      </Grid>

      <Grid item xs={7}>
        <Stack direction="column"  spacing={2}>
      <Typography sx={mainThemeStyle.popUpLableAnswer} height={40}> {dealerDetails.contact_name != null ? dealerDetails.contact_name : ''}</Typography>
      <Typography sx={mainThemeStyle.popUpLableAnswer} height={40}> {dealerDetails.company_name != null  ? dealerDetails.company_name : ''}</Typography>
      <Typography sx={mainThemeStyle.popUpLableAnswer} height={40}> {dealerDetails.street_add != null  ? dealerDetails.street_add : ''} </Typography>
      <Typography sx={mainThemeStyle.popUpLableAnswer} height={40}>  {dealerDetails.city != null  ? dealerDetails.city : ''}</Typography>
      <Typography sx={mainThemeStyle.popUpLableAnswer} height={40}>  {dealerDetails.state}</Typography>
      <Typography sx={mainThemeStyle.popUpLableAnswer} height={40}>  {dealerDetails.zip_code}</Typography>
      <Typography sx={mainThemeStyle.popUpLableAnswer} height={40}>  {dealerDetails.telephone} </Typography>
      <Typography sx={mainThemeStyle.popUpLableAnswer} height={40}>  {dealerDetails.email} </Typography>
      </Stack>
      </Grid>

     

   

     

      </Grid>
      </Box>
<div  sx={{
       right: '54px' ,left: '54px',borderRadius:'0px'}}>
               <div style={{ display: 'flex' }}>
              
                   <Typography variant="h6" component="div" style={{  paddingLeft: '16px',paddingTop: '16px',flexGrow: 1,color:'#fff' }}>
                        Your Profile
                   </Typography>
                   
               </div>
              
           </div>
           <Divider sx={{backgroundColor:'white'}} />
           <Box sx={{display: { xs: "none", md: "flex" }}}>
           <Grid container spacing={2} padding= '32px'> 
      <Grid item xs={2.5}>
        <Stack direction="column"  spacing={2}>
      {/* <Typography sx={mainThemeStyle.popUpLable} height={40}> ID</Typography> */}
      <Typography sx={mainThemeStyle.popUpLable} height={40}> Contact Name</Typography>
      <Typography sx={mainThemeStyle.popUpLable} height={40}> Email</Typography>
      <Typography sx={mainThemeStyle.popUpLable} height={40}> Address 1</Typography>
      <Typography sx={mainThemeStyle.popUpLable} height={40}> Address 2</Typography>
      <Typography sx={mainThemeStyle.popUpLable} height={40}> City</Typography>
      <Typography sx={mainThemeStyle.popUpLable} height={40}> State</Typography>
      <Typography sx={mainThemeStyle.popUpLable} height={40}> Zip</Typography>
      <Typography sx={mainThemeStyle.popUpLable} height={40}> Company</Typography>
      {showUpdateButton &&
       <LoadingButton sx={mainThemeStyle.normalButton} loading={loading} onClick={handleSaveCardFormat} disabled = {getDisableValueEdit}>Update Details</LoadingButton>}
      </Stack>
      </Grid>
      
      <Grid item xs={2.5}>
      <Stack direction="column"  spacing={2}>
      
      {/* <Typography sx={mainThemeStyle.popUpLableAnswer} height={40}> {apiValue.id} </Typography> */}

      {getDisableValueEdit() == false &&  <Typography sx={mainThemeStyle.popUpLableAnswer} height={40}> {apiValue.name}</Typography>}
      {getDisableValueEdit() == true &&   <TextField
                       
                        height={40}
                        type= 'text'
                        name="name"
                        value={apiValue.name}
                        sx={mainThemeStyle.InputMenuItem}
                        onChange={handleInputChange}
                        InputLabelProps={{ shrink: true }
                        
                      }
                      variant="filled"
                      disabled = {getDisableValue("223")}
                        InputProps={{
                           
                            sx: {
                                
                                color:'rgba(135, 135, 135, 0.5)',
                                height:'33px',
                                borderRadius: '5px',
                                backgroundColor: '#121212',
                                boxShadow: 'inset 0px 4px 4px rgba(0, 0, 0, 0.25)',
                                padding: '0 0 15px 0',
                                textIndent: '15px !important',
                                '&.Mui-disabled': {
             

                                  background: 'rgba(135, 135, 135, 0.5)',
                                },
                                
                                "& input.Mui-disabled": {
                                  color: "white"
                                }
                               
                                
                            },
                        }}
                       
                    />}
                    <Typography sx={mainThemeStyle.popUpLableAnswer} height={40}> {apiValue.email}</Typography>
                   {/*  <TextField
                       
                       height={40}
                       type= 'text'
                       name="email"
                       value={apiValue.email}
                       sx={mainThemeStyle.InputMenuItem}
                       onChange={handleInputChange}
                       InputLabelProps={{ shrink: true }}
                     variant="filled"
                       InputProps={{
                          
                           sx: {
                               
                               color:'rgba(135, 135, 135, 0.5)',
                               height:'33px',
                               borderRadius: '5px',
                               backgroundColor: '#121212',
                               boxShadow: 'inset 0px 4px 4px rgba(0, 0, 0, 0.25)',
                               padding: '0 0 15px 0',
                               textIndent: '15px !important',
                             
                              
                               
                           },
                       }}
                      
                   /> */}
                    {getDisableValueEdit() == false &&  <Typography sx={mainThemeStyle.popUpLableAnswer} height={40}> {apiValue.address}</Typography>}
                    {getDisableValueEdit() == true &&  <TextField
                       
                       height={40}
                       type= 'text'
                       name="address"
                       value={apiValue.address}
                       onChange={handleInputChange}
                       sx={mainThemeStyle.InputMenuItem}
                       InputLabelProps={{ shrink: true }
                     }
                     variant="filled"
                     disabled = {getDisableValue("224")}
                       InputProps={{
                          
                           sx: {
                               
                               color:'rgba(135, 135, 135, 0.5)',
                               height:'33px',
                               borderRadius: '5px',
                               backgroundColor: '#121212',
                               boxShadow: 'inset 0px 4px 4px rgba(0, 0, 0, 0.25)',
                               padding: '0 0 15px 0',
                               textIndent: '15px !important',
                               '&.Mui-disabled': {
             

                                background: 'rgba(135, 135, 135, 0.5)',
                              },
                              
                              "& input.Mui-disabled": {
                                color: "white"
                              }
                              
                               
                           },
                       }}
                      
                   /> }
                    {getDisableValueEdit() == false &&  <Typography sx={mainThemeStyle.popUpLableAnswer} height={40}> {apiValue.address_two}</Typography>}
                    {getDisableValueEdit() == true && <TextField
                       
                       height={40}
                       type= 'text'
                       name="address_two"
                       value={apiValue.address_two}
                       onChange={handleInputChange}
                       sx={mainThemeStyle.InputMenuItem}
                       InputLabelProps={{ shrink: true }
                     }
                     variant="filled"
                     disabled = {getDisableValue("225")}
                       InputProps={{
                          
                           sx: {
                               
                               color:'rgba(135, 135, 135, 0.5)',
                               height:'33px',
                               borderRadius: '5px',
                               backgroundColor: '#121212',
                               boxShadow: 'inset 0px 4px 4px rgba(0, 0, 0, 0.25)',
                               padding: '0 0 15px 0',
                               textIndent: '15px !important',
                             
                               '&.Mui-disabled': {
             

                                background: 'rgba(135, 135, 135, 0.5)',
                              },
                              
                              "& input.Mui-disabled": {
                                color: "white"
                              }
                               
                           },
                       }}
                      
                   />}
                   {getDisableValueEdit() == false &&  <Typography sx={mainThemeStyle.popUpLableAnswer} height={40}> {apiValue.city}</Typography>}
                   {getDisableValueEdit() == true &&  <TextField
                       
                       height={40}
                       type= 'text'
                       name="city"
                       value={apiValue.city }
                       onChange={handleInputChange}
                       sx={mainThemeStyle.InputMenuItem}
                       InputLabelProps={{ shrink: true }
                     }
                     disabled = {getDisableValue("226")}
                     variant="filled"
                       InputProps={{
                          
                           sx: {
                               
                               color:'rgba(135, 135, 135, 0.5)',
                               height:'33px',
                               borderRadius: '5px',
                               backgroundColor: '#121212',
                               boxShadow: 'inset 0px 4px 4px rgba(0, 0, 0, 0.25)',
                               padding: '0 0 15px 0',
                               textIndent: '15px !important',
                             
                               '&.Mui-disabled': {
             

                                background: 'rgba(135, 135, 135, 0.5)',
                              },
                              
                              "& input.Mui-disabled": {
                                color: "white"
                              }
                               
                           },
                       }}
                      
                   />}
                   {getDisableValueEdit() == false &&  <Typography sx={mainThemeStyle.popUpLableAnswer} height={40}> {apiValue.state}</Typography>}
                   {getDisableValueEdit() == true &&<TextField
                       
                       height={40}
                       type= 'text'
                       name="state"
                       value={apiValue.state}
                       onChange={handleInputChange}
                       sx={mainThemeStyle.InputMenuItem}
                       InputLabelProps={{ shrink: true }
                     }
                     variant="filled"
                     disabled = {getDisableValue("227")}
                       InputProps={{
                          
                           sx: {
                               
                               color:'rgba(135, 135, 135, 0.5)',
                               height:'33px',
                               borderRadius: '5px',
                               backgroundColor: '#121212',
                               boxShadow: 'inset 0px 4px 4px rgba(0, 0, 0, 0.25)',
                               padding: '0 0 15px 0',
                               textIndent: '15px !important',
                             
                               '&.Mui-disabled': {
             

                                background: 'rgba(135, 135, 135, 0.5)',
                              },
                              
                              "& input.Mui-disabled": {
                                color: "white"
                              }
                               
                           },
                       }}
                      
                   />}
                    {getDisableValueEdit() == false &&  <Typography sx={mainThemeStyle.popUpLableAnswer} height={40}> {apiValue.zip_code}</Typography>}
                    {getDisableValueEdit() == true &&<TextField
                        
                        height={40}
                        type= 'text'
                        name="zip_code"
                        value={apiValue.zip_code }
                        onChange={handleInputChange}
                        sx={mainThemeStyle.InputMenuItem}
                        InputLabelProps={{ shrink: true }
                      }
                      variant="filled"
                      disabled = {getDisableValue("228")}
                        InputProps={{
                           
                            sx: {
                                
                                color:'rgba(135, 135, 135, 0.5)',
                                height:'33px',
                                borderRadius: '5px',
                                backgroundColor: '#121212',
                                boxShadow: 'inset 0px 4px 4px rgba(0, 0, 0, 0.25)',
                                padding: '0 0 15px 0',
                                textIndent: '15px !important',
                                '&.Mui-disabled': {
             

                                  background: 'rgba(135, 135, 135, 0.5)',
                                },
                                
                                "& input.Mui-disabled": {
                                  color: "white"
                                }
                               
                                
                            },
                        }}
                       
                    />}
                     {getDisableValueEdit() == false &&  <Typography sx={mainThemeStyle.popUpLableAnswer} height={40}> {apiValue.copmpany}</Typography>}
                     {getDisableValueEdit() == true && <TextField
                       
                        height={40}
                        type= 'text'
                        name="copmpany"
                        value={apiValue.copmpany}
                        onChange={handleInputChange}
                        sx={mainThemeStyle.InputMenuItem}
                        InputLabelProps={{ shrink: true }
                      }
                      variant="filled"
                      disabled = {getDisableValue("229")}
                        InputProps={{
                           
                            sx: {
                                
                                color:'rgba(135, 135, 135, 0.5)',
                                height:'33px',
                                borderRadius: '5px',
                                backgroundColor: '#121212',
                                boxShadow: 'inset 0px 4px 4px rgba(0, 0, 0, 0.25)',
                                padding: '0 0 15px 0',
                                textIndent: '15px !important',
                              
                                '&.Mui-disabled': {
             

                                  background: 'rgba(135, 135, 135, 0.5)',
                                },
                                
                                "& input.Mui-disabled": {
                                  color: "white"
                                }
                                
                            },
                        }}
                       
                    />}
      </Stack>
    
      
      </Grid>
      <Grid item xs={2}>
     
      </Grid>
      <Grid item xs={2.5}>
        <Stack direction="column"  spacing={2}>
        <Typography sx={mainThemeStyle.popUpLable} height={200}> Logo </Typography>
      <Typography sx={mainThemeStyle.popUpLable} height={40}> Office Number</Typography>
      <Typography sx={mainThemeStyle.popUpLable} height={40}> Website URL</Typography>
      <Typography sx={mainThemeStyle.popUpLable} height={40}> Customer Since</Typography>
      {/* <Typography sx={mainThemeStyle.popUpLable} height={40}> Total Units</Typography> */}
      </Stack>
      </Grid>
      
      <Grid item xs={2.5}>
      <Stack direction="column"  spacing={2}>
      <Stack direction="row" spacing={2} >
        <img  src={imageUrl} height={200} width={200}></img>
        {getDisableValueEdit() == true &&  <Button  sx={mainThemeStyle.normalButton} variant="contained" component="label"  disabled = {getDisableValue("230")}>Upload <input hidden accept="image/png, image/jpeg" multiple type="file" 
        onChange={ 
          e => {
            if (e.target.files[0].size > 2000000) {
              console.log("File too large");
              return;
            }
            
            var reader = new FileReader();
            reader.readAsDataURL(e.target.files[0]);
        
            reader.onload = () => {
              console.log(reader.result);
              setshowUpdateButton(true);
              setSelectedImage(e.target.files[0]);
              apiValue.image_upload_data = reader.result;
              setAPIValue({id:apiValue.id,name:apiValue.name,email:apiValue.email,address:apiValue.address,address_two:apiValue.address_two,city:apiValue.city,state:apiValue.state,zip_code:apiValue.zip_code,copmpany:apiValue.copmpany,office:apiValue.office,website_url:apiValue.website_url,total_unit:apiValue.total_unit,image_upload_data:apiValue.image_upload_data,issub:token.issub,subId:token.subid});
            };
            reader.onerror = error => {
              console.log("Error: ", error);
            };


          
          }
        }/></Button>}
        </Stack>
        {getDisableValueEdit() == false &&  <Typography sx={mainThemeStyle.popUpLableAnswer} height={40}> {apiValue.office}</Typography>}
        {getDisableValueEdit() == true &&    <TextField
                       
                       height={40}
                       type= 'text'
                       name="office"
                       value={apiValue.office }
                       onChange={handleInputChange}
                       sx={mainThemeStyle.InputMenuItem}
                       InputLabelProps={{ shrink: true }
                     }
                     variant="filled"
                     disabled = {getDisableValue("231")}
                       InputProps={{
                          
                           sx: {
                               
                               color:'rgba(135, 135, 135, 0.5)',
                               height:'33px',
                               borderRadius: '5px',
                               backgroundColor: '#121212',
                               boxShadow: 'inset 0px 4px 4px rgba(0, 0, 0, 0.25)',
                               padding: '0 0 15px 0',
                               textIndent: '15px !important',
                             
                               '&.Mui-disabled': {
             

                                background: 'rgba(135, 135, 135, 0.5)',
                              },
                              
                              "& input.Mui-disabled": {
                                color: "white"
                              }
                               
                           },
                       }}
                      
                   />}
                    {getDisableValueEdit() == false &&  <Typography sx={mainThemeStyle.popUpLableAnswer} height={40}> {apiValue.website_url}</Typography>}
                    {getDisableValueEdit() == true &&  <TextField
                       
                       height={40}
                       type= 'text'
                       name="website_url"
                       value={apiValue.website_url}
                       onChange={handleInputChange}
                       sx={mainThemeStyle.InputMenuItem}
                       InputLabelProps={{ shrink: true }
                     }
                     variant="filled"
                     disabled = {getDisableValue("232")}
                       InputProps={{
                          
                           sx: {
                               
                               color:'rgba(135, 135, 135, 0.5)',
                               height:'33px',
                               borderRadius: '5px',
                               backgroundColor: '#121212',
                               boxShadow: 'inset 0px 4px 4px rgba(0, 0, 0, 0.25)',
                               padding: '0 0 15px 0',
                               textIndent: '15px !important',
                               '&.Mui-disabled': {
             

                                background: 'rgba(135, 135, 135, 0.5)',
                              },
                              
                              "& input.Mui-disabled": {
                                color: "white"
                              }
                              
                               
                           },
                       }}
                      
                   />}
                   <Typography sx={mainThemeStyle.popUpLableAnswer} height={40}>{changeTimeZone(apiValue.start_date, 'America/New_York')}</Typography>
              {/*      <Typography sx={mainThemeStyle.popUpLableAnswer} height={40}> {apiValue.total_unit} </Typography> */}
                  
      </Stack>
    
      
      </Grid>
      </Grid>
      </Box>
      <Box sx={{display: { xs: "flex", md: "none" }}}>
      <Grid container spacing={2} padding= '32px'> 
      <Grid item xs={5}>
        <Stack direction="column"  spacing={2}>
      {/* <Typography sx={mainThemeStyle.popUpLable} height={40}> ID</Typography> */}
      <Typography sx={mainThemeStyle.popUpLable} height={40}> Contact Name</Typography>
      <Typography sx={mainThemeStyle.popUpLable} height={40}> Email</Typography>
      <Typography sx={mainThemeStyle.popUpLable} height={40}> Address 1</Typography>
      <Typography sx={mainThemeStyle.popUpLable} height={40}> Address 2</Typography>
      <Typography sx={mainThemeStyle.popUpLable} height={40}> City</Typography>
      <Typography sx={mainThemeStyle.popUpLable} height={40}> State</Typography>
      <Typography sx={mainThemeStyle.popUpLable} height={40}> Zip</Typography>
      <Typography sx={mainThemeStyle.popUpLable} height={40}> Company</Typography>
      <Typography sx={mainThemeStyle.popUpLable} height={150}> Logo </Typography>
      <Typography sx={mainThemeStyle.popUpLable} height={40}> Office Number</Typography>
      <Typography sx={mainThemeStyle.popUpLable} height={40}> Website URL</Typography>
      <Typography sx={mainThemeStyle.popUpLable} height={40}> Customer Since</Typography>
      </Stack>
      </Grid>
      
      <Grid item xs={7}>
      <Stack direction="column"  spacing={2}>
      
      {/* <Typography sx={mainThemeStyle.popUpLableAnswer} height={40}> {apiValue.id} </Typography> */}
      {getDisableValueEdit() == false &&  <Typography sx={mainThemeStyle.popUpLableAnswer} height={40}> {apiValue.name}</Typography>}
      {getDisableValueEdit() == true &&     <TextField
                       
                        height={40}
                        type= 'text'
                        name="name"
                        value={apiValue.name}
                        sx={mainThemeStyle.InputMenuItem}
                        onChange={handleInputChange}
                        InputLabelProps={{ shrink: true }
                        
                      }
                      variant="filled"
                      disabled = {getDisableValue("223")}
                        InputProps={{
                           
                            sx: {
                                
                                color:'rgba(135, 135, 135, 0.5)',
                                height:'33px',
                                borderRadius: '5px',
                                backgroundColor: '#121212',
                                boxShadow: 'inset 0px 4px 4px rgba(0, 0, 0, 0.25)',
                                padding: '0 0 15px 0',
                                textIndent: '15px !important',
                                '&.Mui-disabled': {
             

                                  background: 'rgba(135, 135, 135, 0.5)',
                                },
                                
                                "& input.Mui-disabled": {
                                  color: "white"
                                }
                               
                                
                            },
                        }}
                       
                    />}
                    <Typography sx={mainThemeStyle.popUpLableAnswer} height={40}> {apiValue.email}</Typography>
                    {getDisableValueEdit() == false &&  <Typography sx={mainThemeStyle.popUpLableAnswer} height={40}> {apiValue.address}</Typography>}     
                    {getDisableValueEdit() == true &&  <TextField
                       
                       height={40}
                       type= 'text'
                       name="address"
                       value={apiValue.address}
                       onChange={handleInputChange}
                       sx={mainThemeStyle.InputMenuItem}
                       InputLabelProps={{ shrink: true }
                     }
                     disabled = {getDisableValue("224")}
                     variant="filled"
                       InputProps={{
                          
                           sx: {
                               
                               color:'rgba(135, 135, 135, 0.5)',
                               height:'33px',
                               borderRadius: '5px',
                               backgroundColor: '#121212',
                               boxShadow: 'inset 0px 4px 4px rgba(0, 0, 0, 0.25)',
                               padding: '0 0 15px 0',
                               textIndent: '15px !important',
                               '&.Mui-disabled': {
             

                                background: 'rgba(135, 135, 135, 0.5)',
                              },
                              
                              "& input.Mui-disabled": {
                                color: "white"
                              }
                              
                               
                           },
                       }}
                      
                   />}
                    {getDisableValueEdit() == false &&  <Typography sx={mainThemeStyle.popUpLableAnswer} height={40}> {apiValue.address_two}</Typography>}     
                    {getDisableValueEdit() == true &&<TextField
                       
                       height={40}
                       type= 'text'
                       name="address_two"
                       value={apiValue.address_two}
                       onChange={handleInputChange}
                       sx={mainThemeStyle.InputMenuItem}
                       InputLabelProps={{ shrink: true }
                     }
                     disabled = {getDisableValue("225")}
                     variant="filled"
                       InputProps={{
                          
                           sx: {
                               
                               color:'rgba(135, 135, 135, 0.5)',
                               height:'33px',
                               borderRadius: '5px',
                               backgroundColor: '#121212',
                               boxShadow: 'inset 0px 4px 4px rgba(0, 0, 0, 0.25)',
                               padding: '0 0 15px 0',
                               textIndent: '15px !important',
                             
                               '&.Mui-disabled': {
             

                                background: 'rgba(135, 135, 135, 0.5)',
                              },
                              
                              "& input.Mui-disabled": {
                                color: "white"
                              }
                               
                           },
                       }}
                      
                   />}
                   {getDisableValueEdit() == false &&  <Typography sx={mainThemeStyle.popUpLableAnswer} height={40}> {apiValue.city}</Typography>}     
                   {getDisableValueEdit() == true &&<TextField
                       
                       height={40}
                       type= 'text'
                       name="city"
                       value={apiValue.city }
                       onChange={handleInputChange}
                       sx={mainThemeStyle.InputMenuItem}
                       InputLabelProps={{ shrink: true }
                     }
                     variant="filled"
                     disabled = {getDisableValue("226")}
                       InputProps={{
                          
                           sx: {
                               
                               color:'rgba(135, 135, 135, 0.5)',
                               height:'33px',
                               borderRadius: '5px',
                               backgroundColor: '#121212',
                               boxShadow: 'inset 0px 4px 4px rgba(0, 0, 0, 0.25)',
                               padding: '0 0 15px 0',
                               textIndent: '15px !important',
                             
                               '&.Mui-disabled': {
             

                                background: 'rgba(135, 135, 135, 0.5)',
                              },
                              
                              "& input.Mui-disabled": {
                                color: "white"
                              }
                               
                           },
                       }}
                      
                   />}
                   {getDisableValueEdit() == false &&  <Typography sx={mainThemeStyle.popUpLableAnswer} height={40}> {apiValue.state}</Typography>}     
                   {getDisableValueEdit() == true && <TextField
                       
                       height={40}
                       type= 'text'
                       name="state"
                       value={apiValue.state}
                       onChange={handleInputChange}
                       sx={mainThemeStyle.InputMenuItem}
                       InputLabelProps={{ shrink: true }
                     }
                     variant="filled"
                     disabled = {getDisableValue("227")}
                       InputProps={{
                          
                           sx: {
                               
                               color:'rgba(135, 135, 135, 0.5)',
                               height:'33px',
                               borderRadius: '5px',
                               backgroundColor: '#121212',
                               boxShadow: 'inset 0px 4px 4px rgba(0, 0, 0, 0.25)',
                               padding: '0 0 15px 0',
                               textIndent: '15px !important',
                             
                              
                               '&.Mui-disabled': {
             

                                background: 'rgba(135, 135, 135, 0.5)',
                              },
                              
                              "& input.Mui-disabled": {
                                color: "white"
                              }
                           },
                       }}
                      
                   />}
                   {getDisableValueEdit() == false &&  <Typography sx={mainThemeStyle.popUpLableAnswer} height={40}> {apiValue.zip_code}</Typography>} 
                   {getDisableValueEdit() == true && <TextField
                        
                        height={40}
                        type= 'text'
                        name="zip_code"
                        value={apiValue.zip_code }
                        onChange={handleInputChange}
                        sx={mainThemeStyle.InputMenuItem}
                        InputLabelProps={{ shrink: true }
                      }
                      variant="filled"
                      disabled = {getDisableValue("228")}
                        InputProps={{
                           
                            sx: {
                                
                                color:'rgba(135, 135, 135, 0.5)',
                                height:'33px',
                                borderRadius: '5px',
                                backgroundColor: '#121212',
                                boxShadow: 'inset 0px 4px 4px rgba(0, 0, 0, 0.25)',
                                padding: '0 0 15px 0',
                                textIndent: '15px !important',
                              
                               
                                '&.Mui-disabled': {
             

                                  background: 'rgba(135, 135, 135, 0.5)',
                                },
                                
                                "& input.Mui-disabled": {
                                  color: "white"
                                }
                            },
                        }}
                       
                    />}
                     {getDisableValueEdit() == false &&  <Typography sx={mainThemeStyle.popUpLableAnswer} height={40}> {apiValue.copmpany}</Typography>} 
                     {getDisableValueEdit() == true && <TextField
                       
                        height={40}
                        type= 'text'
                        name="copmpany"
                        value={apiValue.copmpany}
                        onChange={handleInputChange}
                        sx={mainThemeStyle.InputMenuItem}
                        InputLabelProps={{ shrink: true }
                      }
                      variant="filled"
                      disabled = {getDisableValue("229")}
                        InputProps={{
                           
                            sx: {
                                
                                color:'rgba(135, 135, 135, 0.5)',
                                height:'33px',
                                borderRadius: '5px',
                                backgroundColor: '#121212',
                                boxShadow: 'inset 0px 4px 4px rgba(0, 0, 0, 0.25)',
                                padding: '0 0 15px 0',
                                textIndent: '15px !important',
                              
                                '&.Mui-disabled': {
             

                                  background: 'rgba(135, 135, 135, 0.5)',
                                },
                                
                                "& input.Mui-disabled": {
                                  color: "white"
                                }
                                
                            },
                        }}
                       
                    />}
                    <Stack direction="column" spacing={2} >
        <img  src={imageUrl} height={100} width={100}></img>
        {getDisableValueEdit() == true &&<Button  sx={mainThemeStyle.normalButton} variant="contained" component="label"  disabled = {getDisableValue("230")}>Upload <input hidden accept="image/png, image/jpeg" multiple type="file" 
        onChange={ 
          e => {
            if (e.target.files[0].size > 2000000) {
              console.log("File too large");
              return;
            }
            
            var reader = new FileReader();
            reader.readAsDataURL(e.target.files[0]);
        
            reader.onload = () => {
              console.log(reader.result);
              setshowUpdateButton(true);
              setSelectedImage(e.target.files[0]);
              apiValue.image_upload_data = reader.result;
              setAPIValue({id:apiValue.id,name:apiValue.name,email:apiValue.email,address:apiValue.address,address_two:apiValue.address_two,city:apiValue.city,state:apiValue.state,zip_code:apiValue.zip_code,copmpany:apiValue.copmpany,office:apiValue.office,website_url:apiValue.website_url,total_unit:apiValue.total_unit,image_upload_data:apiValue.image_upload_data,issub:token.issub,subId:token.subid});
            };
            reader.onerror = error => {
              console.log("Error: ", error);
            };


          
          }
        }/></Button> }
         {getDisableValueEdit() == false &&<Typography sx={mainThemeStyle.popUpLableAnswer} height={30}> </Typography> }
        </Stack>
        {getDisableValueEdit() == false &&  <Typography sx={mainThemeStyle.popUpLableAnswer}  height={40}> {apiValue.office}</Typography>}
        {getDisableValueEdit() == true &&    <TextField
                       
                       height={40}
                       type= 'text'
                       name="office"
                       value={apiValue.office }
                       onChange={handleInputChange}
                       sx={mainThemeStyle.InputMenuItem}
                       InputLabelProps={{ shrink: true }
                     }
                     variant="filled"
                     disabled = {getDisableValue("231")}
                       InputProps={{
                          
                           sx: {
                               
                               color:'rgba(135, 135, 135, 0.5)',
                               height:'33px',
                               borderRadius: '5px',
                               backgroundColor: '#121212',
                               boxShadow: 'inset 0px 4px 4px rgba(0, 0, 0, 0.25)',
                               padding: '0 0 15px 0',
                               textIndent: '15px !important',
                               '&.Mui-disabled': {
             

                                background: 'rgba(135, 135, 135, 0.5)',
                              },
                              
                              "& input.Mui-disabled": {
                                color: "white"
                              }
                              
                               
                           },
                       }}
                      
                   />}
                    {getDisableValueEdit() == false &&  <Typography sx={mainThemeStyle.popUpLableAnswer} height={40}> {apiValue.website_url}</Typography>}
                   {getDisableValueEdit() == true && <TextField
                       
                       height={40}
                       type= 'text'
                       name="website_url"
                       value={apiValue.website_url}
                       onChange={handleInputChange}
                       sx={mainThemeStyle.InputMenuItem}
                       InputLabelProps={{ shrink: true }
                     }
                     variant="filled"
                     disabled = {getDisableValue("232")}
                       InputProps={{
                          
                           sx: {
                               
                               color:'rgba(135, 135, 135, 0.5)',
                               height:'33px',
                               borderRadius: '5px',
                               backgroundColor: '#121212',
                               boxShadow: 'inset 0px 4px 4px rgba(0, 0, 0, 0.25)',
                               padding: '0 0 15px 0',
                               textIndent: '15px !important',
                             
                               '&.Mui-disabled': {
             

                                background: 'rgba(135, 135, 135, 0.5)',
                              },
                              
                              "& input.Mui-disabled": {
                                color: "white"
                              }
                               
                           },
                       }}
                      
                   />}
                     <Typography sx={mainThemeStyle.popUpLableAnswer} height={40}>{changeTimeZone(apiValue.start_date, 'America/New_York')}</Typography>
        
      </Stack>
    
      
      </Grid>
     
      
      {showUpdateButton &&
       <LoadingButton sx={{
        width: 'auto',
        height: '2.06rem',
        
        
        fontWeight: '700',
        fontSize: '0.93rem',
        lineHeight:'0.93rem',
        
        alignItems: 'center',
        color: '#FFFFFF',
        fontFamily: "Lato",
        borderRadius:'0.31rem',
       '&:hover': {
        backgroundColor: 'rgba(109, 195, 252, 1)',
       }
    }}  loading={loading} onClick={handleSaveCardFormat}  disabled = {getDisableValueEdit}>Update Details</LoadingButton>}
    
      </Grid>
      
      </Box>

</Box>
<Snackbar open={openSnack} autoHideDuration={6000} onClose={handleCloseSnack}>
        <Alert onClose={handleCloseSnack} severity={severityData} sx={{ width: '100%' }}>
          {openSnackData}
        </Alert>
      </Snackbar>
 </> 
          
          
          
          
          ;
};

export default Profile;